import { createContext, useCallback, useContext, useMemo } from 'react';

import { BuilderCollection, useContent, useLocalAppProperties } from 'providers';
import { COMMUNITY_TEMPLATE } from 'api';

interface ContextProps {
  activeTabs: BuilderCollection[] | undefined;
  inactiveTabs: BuilderCollection[] | undefined;
}

interface ProviderProps {
  children: React.ReactNode;
}

const MainTabContext = createContext<ContextProps>({
  activeTabs: undefined,
  inactiveTabs: undefined,
});

const MainTabProvider = ({ children }: ProviderProps) => {
  const { collections } = useContent();
  const { properties } = useLocalAppProperties();

  const tabIsVisible = useCallback(
    (collection: BuilderCollection) => {
      if (collection.TemplateId === 33) {
        return properties.DisplayMyLibrary === '1';
      } else if (collection.TemplateId === COMMUNITY_TEMPLATE) {
        return properties.RolloutCommunity === '1';
      }
      return true;
    },
    [properties],
  );

  const tabs = useMemo(() => {
    const active: BuilderCollection[] = [];
    const inactive: BuilderCollection[] = [];

    Object.values(collections)
      .filter((collection) => collection.Position && collection.Position !== 0)
      .forEach((collection) => {
        if (tabIsVisible(collection)) {
          if (collection.IsMainTab === 1) {
            active.push(collection);
          } else {
            inactive.push(collection);
          }
        }
      });

    return {
      active: active.sort((a, b) => (a.Position as number) - (b.Position as number)),
      inactive: inactive.sort((a, b) => (a.Position as number) - (b.Position as number)),
    };
  }, [collections, tabIsVisible]);

  return (
    <MainTabContext.Provider
      value={{
        activeTabs: tabs.active,
        inactiveTabs: tabs.inactive,
      }}
    >
      {children}
    </MainTabContext.Provider>
  );
};

const useMainTabs = () => {
  const context = useContext(MainTabContext);
  if (context === undefined) {
    throw new Error('useMainTabs must be used within a MainTabProvider');
  }
  return context;
};

export { MainTabProvider, useMainTabs };
