import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';
import { XIcon } from 'icons';
import { MODAL_OVERLAY_COLOUR, NEUTRAL_5_COLOUR } from 'theme';
import { FONT_16PX_SEMIBOLD } from 'font';
import { ReactNode } from 'react';
import { CustomButton, CustomButtonProps } from 'components/Buttons/CustomButton/CustomButton';

interface ContentProps {
  $cols: ColumnModalProps['cols'];
  $maxHeight?: string;
}

const COLUMN_WIDTH = 187;

const StyledContent = styled(Dialog.Content)<ContentProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  background-color: white;
  border-radius: 12px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  width: ${({ $cols }) => `${$cols * COLUMN_WIDTH}px`};
  max-height: ${({ $maxHeight }) => $maxHeight || '700px'};
  padding-top: 20px;

  z-index: 11;
`;

const StyledTitle = styled(Dialog.Title)`
  width: 100%;
  height: 44px;
  padding: 0 24px 20px 24px;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  ${FONT_16PX_SEMIBOLD};
`;
const StyledTitleText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 48px);
  ${FONT_16PX_SEMIBOLD};
`;

const StyledClose = styled(Dialog.Close)`
  font-size: 18px;
  position: absolute;
  top: 24px;
  right: 24px;

  :hover {
    background-color: ${NEUTRAL_5_COLOUR};
  }

  line-height: 1;
`;

const ContentWrapper = styled.div<{ $padding?: string }>`
  padding: ${({ $padding }) => $padding || '0 24px'};
`;

const CustomOverlay = styled.div`
  background-color: ${MODAL_OVERLAY_COLOUR};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9;
`;

interface DialogActionsProps {
  $align: ColumnModalProps['align'];
  $hideDialogActionsBorder: ColumnModalProps['hideDialogActionsBorder'];
}

const getJustifyContent = (align: DialogActionsProps['$align']) => {
  if (align === 'left') {
    return 'flex-start';
  } else if (align === 'right') {
    return 'flex-end';
  }
  return 'center';
};
const DialogActions = styled.div<DialogActionsProps>`
  display: flex;
  justify-content: ${({ $align }) => getJustifyContent($align)};
  padding: 24px;
  border-top: ${({ $hideDialogActionsBorder }) =>
    $hideDialogActionsBorder ? 'none' : `1px solid ${NEUTRAL_5_COLOUR}`};
`;

const SecondaryButton = styled(CustomButton)`
  &&&& {
    margin-right: 12px;
  }
`;
const StyledOverlay = styled(Dialog.Overlay)`
  background-color: rgba(2, 7, 20, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

interface ColumnModalProps {
  title?: string;
  cols: number; // Number of columns, determines modal width
  triggerButton?: ReactNode;
  hideTrigger?: boolean;
  portalId?: string;
  children?: ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;

  // Use default actions
  onConfirm?: () => void; // Complete the modal
  confirmDisabled?: boolean; // Block proceeding on conditions
  confirmIsLoading?: boolean; // Manage loading state of confirm
  onCancel?: () => void; // Cancel the modal
  hideSecondary?: boolean; // Hide cancel button
  hideClose?: boolean; // Hide X button
  primaryText?: string;
  secondaryText?: string;
  primaryProps?: Partial<CustomButtonProps>;
  secondaryProps?: Partial<CustomButtonProps>;
  danger?: boolean;

  // Custom actions
  customActions?: ReactNode | ReactNode[];

  align?: 'left' | 'right' | 'full'; // Align buttons or make them full width
  contentPadding?: string; // Use custom padding for content section of modal
  maxHeight?: string;
  hideDialogActionsBorder?: boolean; // Hide top border of DialogActions
  hasTinyMCE?: boolean; // Use non-modal mode and custom overlay to allow TinyMCE's modals to be interactive
}

export const ColumnModal = ({
  cols,
  title,
  triggerButton,
  hideTrigger,
  portalId,
  align,
  contentPadding,
  maxHeight,
  hideSecondary,
  hideClose,
  hideDialogActionsBorder,
  primaryText,
  secondaryText,
  children,
  onConfirm,
  onCancel,
  confirmDisabled,
  confirmIsLoading,
  open,
  onOpenChange,
  hasTinyMCE,
  primaryProps,
  secondaryProps,
  customActions,
}: ColumnModalProps) => {
  const primaryButtonProps: Partial<CustomButtonProps> = {
    onClick: onConfirm,
    fullWidth: align === 'full',
    disabled: confirmDisabled,
    loading: confirmIsLoading,
    ...primaryProps,
  };
  const PrimaryButtonText = primaryText ?? 'Confirm';

  const secondaryButtonProps: Partial<CustomButtonProps> = {
    secondary: true,
    onClick: onCancel,
    fullWidth: align === 'full',
    ...secondaryProps,
  };
  const secondaryButtonText = secondaryText ?? 'Cancel';

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} modal={!hasTinyMCE}>
      {!hideTrigger && <Dialog.Trigger asChild={!!triggerButton}>{triggerButton ?? 'Open Modal'}</Dialog.Trigger>}
      {!hasTinyMCE && <StyledOverlay />}
      <Dialog.Portal container={document.getElementById(portalId ?? 'react-app')}>
        <StyledContent
          id="ColumnModal"
          $cols={cols ?? 5}
          $maxHeight={maxHeight}
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          {title && (
            <StyledTitle>
              <StyledTitleText>{title}</StyledTitleText>
              {!hideClose && (
                <StyledClose asChild>
                  <XIcon />
                </StyledClose>
              )}
            </StyledTitle>
          )}
          <ContentWrapper $padding={contentPadding}>{children}</ContentWrapper>
          <DialogActions $align={align ?? 'right'} $hideDialogActionsBorder={hideDialogActionsBorder}>
            {customActions ? (
              <>{customActions}</>
            ) : (
              <>
                {!hideSecondary && (
                  <>
                    {/* Controlled vs Uncontrolled */}
                    {open ? (
                      <SecondaryButton medium {...secondaryButtonProps}>
                        {secondaryButtonText}
                      </SecondaryButton>
                    ) : (
                      <Dialog.Close asChild>
                        <SecondaryButton medium {...secondaryButtonProps}>
                          {secondaryButtonText}
                        </SecondaryButton>
                      </Dialog.Close>
                    )}
                  </>
                )}
                {/* When using open we are a controlled modal, need to setOpen(false) during onConfirm */}
                {open ? (
                  <CustomButton medium {...primaryButtonProps}>
                    {PrimaryButtonText}
                  </CustomButton>
                ) : (
                  <Dialog.Close asChild>
                    {/* Automatically closes modal immediately on confirm, use controlled modal if you want to close after a delay */}
                    <CustomButton medium {...primaryButtonProps}>
                      {PrimaryButtonText}
                    </CustomButton>
                  </Dialog.Close>
                )}
              </>
            )}
          </DialogActions>
        </StyledContent>
      </Dialog.Portal>
      {hasTinyMCE && open && <CustomOverlay />}
    </Dialog.Root>
  );
};
