import { useMemo } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

import { AppProperties, Collection, hasHeroImage, isHorizontalScroll, ITEM_TYPE_COLLECTION, ITEM_TYPE_TAB } from 'api';
import { getCollectionHTMLDescription, getContentType } from 'utils';
import { FONT_16PX_REGULAR } from 'font';
import { useAppProperties } from 'hooks';

import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { BuilderCollection } from 'app/modules/build-dragdrop/Builder/providers';

import { CollectionResource, HomogeneousView, MixedView, SubNavDisplay } from '..';

interface CollectionDetailsProps {
  collection: Collection | BuilderCollection;
}

const HeroImage = styled(ContentThumbnail)`
  width: 100%;
  height: unset;
  aspect-ratio: 16/9;
`;

const About = styled.div<{ $color: string }>`
  padding: 16px;
  ${FONT_16PX_REGULAR};
  color: ${({ $color }) => $color};
  cursor: default;
`;
const Resources = styled.div`
  padding: 16px;
`;

/**
 * Render the Collection Details Page for the provided collection
 */
export const CollectionDetails = ({ collection }: CollectionDetailsProps) => {
  const { getDesignProperty } = useAppTheme();
  const { data: appProperties } = useAppProperties();

  const { CustomCollectionAboutLabel, CustomCollectionVideosLabel, CustomCollectionCollectionsLabel } =
    appProperties ?? ({} as AppProperties);

  const getCustomItemsLabel = (itemsName: string) => {
    if (itemsName === 'Videos') {
      return CustomCollectionVideosLabel || itemsName;
    } else if (itemsName === 'Collections') {
      return CustomCollectionCollectionsLabel || itemsName;
    }
    return itemsName;
  };

  const hasCollections = collection?.Items?.some((i) => i.Type === ITEM_TYPE_COLLECTION || i.Type === ITEM_TYPE_TAB);
  const templateId = !hasCollections && isHorizontalScroll(collection.TemplateId) ? 10 : collection.TemplateId;

  const items = useMemo(() => {
    const output = [];
    const desc = getCollectionHTMLDescription(collection);
    const $color = getDesignProperty('primaryItemHEX') as string;
    const itemsName = `${getContentType(collection.Items)}s`;

    // Horizontal scroll will become List Large Hero if the children are not collections
    output.push({
      name: getCustomItemsLabel(itemsName),
      component: <HomogeneousView collection={{ ...collection, TemplateId: templateId }} />,
      id: `${collection.TabId}|content`,
    });
    if (desc) {
      const purifiedDesc = DOMPurify.sanitize(desc);

      output.push({
        name: CustomCollectionAboutLabel || 'About',
        component: <About $color={$color} dangerouslySetInnerHTML={{ __html: purifiedDesc }} />,
        id: `${collection.TabId}|about`,
      });
    }
    if (collection.Resources && collection.Resources.length > 0) {
      output.push({
        name: 'Resources',
        component: (
          <Resources>
            {collection.Resources.map((resource) => {
              return <CollectionResource {...resource} key={resource.ResourceId} />;
            })}
          </Resources>
        ),
        id: `${collection.TabId}|resources`,
      });
    }

    return output;
  }, [collection, templateId]);

  return (
    <>
      {hasHeroImage(templateId) ? (
        <>
          <HeroImage thumbnail={collection} defaultBorder="2px solid whitesmoke" overrideRadius="0px" />
          {items.length > 1 ? <SubNavDisplay items={items} /> : items[0].component}
        </>
      ) : [30].includes(collection.TemplateId) ? (
        <MixedView collection={collection} />
      ) : (
        <HomogeneousView collection={{ ...collection, TemplateId: templateId }} />
      )}
    </>
  );
};
