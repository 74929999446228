import styled from 'styled-components';

import { ErrorBoundary, LoadingSpinner } from 'components';

import { AppNavBar, AppTabBar, AppContent, ErrorView, AppSideMenu } from './components';
import { useMockupContext } from './providers';
import { useAppTheme } from './hooks';

interface ScreenProps {
  $bgColor: string;
  $height?: string;
  $disableMargin?: boolean;
  $disableMaxHeight?: boolean;
}

const Screen = styled.div<ScreenProps>`
  height: ${({ $height }) => $height || '100vh'};
  max-height: ${({ $disableMaxHeight }) => ($disableMaxHeight ? 'unset' : 'calc(100vh - 28px)')};
  flex-shrink: 0;
  width: 410px;
  max-width: 410px;

  padding: 0;
  margin-top: ${({ $disableMargin }) => ($disableMargin ? '0' : '40px')};

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: 40px;

  overflow: visible;
  position: relative;
`;

const LoadingScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type AppMockupProps = { disableMargin?: boolean; disableMaxHeight?: boolean; isLoading?: boolean };
/**
 * The Simulator of VidApp's app
 * Must be used within a MockupProvider and ContentProvider
 * @param disableMargin - Disables the margin surrounding the mockup
 * @param disableMaxHeight - Disables the max height of the screen
 * @param isLoading - Show loading spinner
 * @param props - Any other props, styles from styled components
 */
export const AppMockup = ({ disableMargin, disableMaxHeight, isLoading, ...props }: AppMockupProps) => {
  const { getDesignProperty } = useAppTheme();
  const { screenHeight, currentTabId, sideMenuVisible, navBarHidden } = useMockupContext();

  return (
    <Screen
      id="Builder__Screen"
      $bgColor={getDesignProperty('backgroundHEX') as string}
      $height={screenHeight}
      $disableMargin={disableMargin}
      $disableMaxHeight={disableMaxHeight}
      {...props}
    >
      <ErrorBoundary fallback={<ErrorView />}>
        {isLoading ? (
          <LoadingScreen>
            <LoadingSpinner />
          </LoadingScreen>
        ) : (
          <>
            {!navBarHidden && <AppNavBar />}
            <AppContent />
            {!!currentTabId && <AppTabBar />}
            {sideMenuVisible && <AppSideMenu />}
          </>
        )}
      </ErrorBoundary>
    </Screen>
  );
};
