import { AxiosInstance } from 'axios';
import { getUserEmail } from 'utils';
import { builderAPI } from 'api/axios';

export const syncWordPress = (client: AxiosInstance, appId: string) => {
  const payload: {
    appId: string;
    emailAddressToUpdate: string;
  } = {
    appId: appId,
    emailAddressToUpdate: getUserEmail(),
  };

  return client.post(`${builderAPI.phpBackendURL}/manage_your_apps_dev/api2_sync_wordpress.php`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
