import styled from 'styled-components';
import { AppBackIcon, EllipsisIcon } from 'icons';
import { OVERFLOW_ELLIPSIS } from 'font';
import { APP_FONT_18PX_SEMIBOLD } from 'mockup-font';

export const BackIcon = styled(AppBackIcon)<{ $color?: string }>`
  font-size: 26px;
  line-height: 1;
  position: absolute;
  left: 16px;
  stroke: ${(props) => props.$color};
`;

export const Ellipsis = styled(EllipsisIcon)<{ $color?: string }>`
  font-size: 26px;
  line-height: 1;
  position: absolute;
  right: 16px;
  stroke: ${(props) => props.$color};
`;

export const NavBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 26px;

  // Ensure the overflow doesnt eat into the margin/padding
  border-left: 48px solid transparent;
  border-right: 48px solid transparent;
`;

export const NavBarText = styled.div<{ $color?: string }>`
  ${OVERFLOW_ELLIPSIS};
  ${APP_FONT_18PX_SEMIBOLD};
  color: ${(props) => props.$color};
  cursor: default;
`;
