import { useCallback } from 'react';

import { useSaveContext, useLocalProducts, BuilderProduct } from 'providers';
import { SettingsSelectInput, SettingsSegment } from 'components';

const options = [
  {
    name: 'No free trial',
    value: '0',
  },
  {
    name: '1 week',
    value: '1w',
  },
  {
    name: '2 weeks',
    value: '2w',
  },
  {
    name: '1 month',
    value: '1m',
  },
];

export const FreeTrialPeriod = ({ Id, SubscriptionTrial }: Pick<BuilderProduct, 'Id' | 'SubscriptionTrial'>) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();

  const handleSelectChange = useCallback(
    (value: string) => {
      setProductValue(Id, 'SubscriptionTrial', value);
      setProductValueToSave(Id, 'SubscriptionTrial', value);
    },
    [setProductValue, setProductValueToSave, Id],
  );

  return (
    <SettingsSegment title="Free Trial Period">
      <SettingsSelectInput options={options} defaultVal={SubscriptionTrial} onChange={handleSelectChange} />
    </SettingsSegment>
  );
};
