import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

interface ContextValue {
  contentRef: RefObject<HTMLDivElement>;
  screenRect?: DOMRect;
  contentRect?: DOMRect;
  selected?: boolean;
  isScrolling: boolean; // Whether the app content is scrolling, 250ms delay on stopping
  isMouseOver: boolean;
  updateRects: () => void;
}

const ContentPositionContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps
  extends Omit<ContextValue, 'contentRect' | 'screenRect' | 'isScrolling' | 'isMouseOver' | 'updateRects'> {
  templateId?: number;
  children?: ReactNode;
  idx: number;
}

const ContentPositionProvider = ({ children, contentRef, templateId, selected, idx }: ProviderProps) => {
  const [screenRect, setScreenRect] = useState<DOMRect | undefined>();
  const [contentRect, setContentRect] = useState<DOMRect | undefined>();

  // Update the known positions of the Screen and the content section
  const updateRects = useCallback(() => {
    const rect = contentRef?.current?.getBoundingClientRect();
    const screenRect = document.getElementById('Builder__Screen')?.getBoundingClientRect();

    if (rect) {
      setContentRect(rect);
    }
    if (screenRect) {
      setScreenRect(screenRect);
    }
  }, [contentRef, setContentRect, setScreenRect]);

  // Track whether the app content is scrolling
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const scrollRef = useRef<number>();
  const checkIfScrolling = useCallback(() => {
    setIsScrolling(true);
    if (scrollRef.current) {
      window.clearTimeout(scrollRef.current);
    }
    scrollRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, 100);
  }, [scrollRef]);

  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const mouseEnterHandler = useCallback(() => setIsMouseOver(true), [setIsMouseOver]);
  const mouseLeaveHandler = useCallback(() => setIsMouseOver(false), [setIsMouseOver]);

  useEffect(() => {
    document.getElementById('Builder__Content')?.addEventListener('scroll', checkIfScrolling);
    if (contentRef) {
      contentRef.current?.addEventListener('mouseenter', mouseEnterHandler);
      contentRef.current?.addEventListener('mouseleave', mouseLeaveHandler);
    }
    return () => {
      document.getElementById('Builder__Content')?.removeEventListener('scroll', checkIfScrolling);
      if (contentRef) {
        contentRef.current?.removeEventListener('mouseenter', mouseEnterHandler);
        contentRef.current?.removeEventListener('mouseleave', mouseLeaveHandler);
      }
      if (scrollRef.current) {
        clearTimeout(scrollRef.current);
      }
    };
  }, [checkIfScrolling, contentRef]);

  // On changing the display type, selecting, dragging or scrolling update the known position and size of the content
  useLayoutEffect(updateRects, [contentRef.current, templateId, selected, isScrolling, isMouseOver, idx]);

  return (
    <ContentPositionContext.Provider
      value={{ screenRect, contentRect, contentRef, isScrolling, selected, isMouseOver, updateRects }}
    >
      {children}
    </ContentPositionContext.Provider>
  );
};

const useContentPositionContext = () => {
  const context = useContext(ContentPositionContext);
  if (context === undefined) {
    throw new Error('useContentPositionContext must be used within a ContentPositionProvider');
  }
  return context;
};

export { ContentPositionProvider, useContentPositionContext };
