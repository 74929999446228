import { Collection } from 'api';
import { getCollectionName } from 'utils';

import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { BuilderCollection } from 'app/modules/build-dragdrop/Builder/providers';

import { BackIcon, Ellipsis, NavBarText, NavBarWrapper } from './NavBarComponents';

export const CollectionNavBar = ({ collection }: { collection: Collection | BuilderCollection }) => {
  const { goBack } = useMockupContext();
  const { getDesignProperty } = useAppTheme();

  const NavBarTitleText = getDesignProperty('navBarTitleText') as string;

  return (
    <NavBarWrapper>
      <BackIcon onClick={goBack} $color={NavBarTitleText} />
      <NavBarText $color={NavBarTitleText}>{getCollectionName(collection)}</NavBarText>
      <Ellipsis $color={NavBarTitleText} />
    </NavBarWrapper>
  );
};
