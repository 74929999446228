import { SegmentProps, SettingsCollectionThumbnail } from 'components';

import { BuilderCollection } from 'app/modules/build-dragdrop/Builder/providers';

interface CollectionThumbnailProps extends SegmentProps {
  collection: BuilderCollection;
}

export const CollectionThumbnail = ({ collection, ...props }: CollectionThumbnailProps) => {
  return (
    <SettingsCollectionThumbnail
      collection={collection}
      alternateOrientation="column"
      width="172px"
      height="92px"
      squareWidth="123px"
      portraitWidth="123px"
      portraitHeight="184px"
      {...props}
    />
  );
};
