import { useMemo, useState } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

import { BuilderCollection, BuilderCollectionItem, useContent } from 'providers';
import { ITEM_TYPE_COLLECTION, ITEM_TYPE_VIDEO } from 'api';
import { CustomColor, getCollectionHTMLDescription, getContentName, isSectionHeaderItem, useCustomColor } from 'utils';
import { AppChevronUpIcon } from 'icons';
import { useAppProperties } from 'hooks';

import { useAppCollectionContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { APP_FONT_16PX_REGULAR, APP_FONT_18PX_MEDIUM } from 'mockup-font';

import { Lesson } from './Lesson';
import { SimpleModule, SubCategory } from './SubCategory';

const Container = styled.div`
  width: 100%;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const HeroImage = styled(ContentThumbnail)`
  width: 100%;
  height: unset;
  aspect-ratio: 16/9;
`;
const About = styled.div<CustomColor>`
  padding: 0 16px 0 16px;
  margin-bottom: 24px;
  ${APP_FONT_16PX_REGULAR};
  color: ${useCustomColor};
  position: relative;
  cursor: pointer;
`;
const DescText = styled.div<{ $expanded: boolean }>`
  max-height: ${({ $expanded }) => !$expanded && '54px'};
  height: fit-content;
  min-height: 36px;
  overflow: hidden;
`;
const TranslucentContainer = styled.div<{ isDark?: boolean }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 18px;
  max-height: 30%;
  background-color: ${({ isDark }) => (isDark ? 'rgba(0,0,0,0.7)' : 'rgba(255, 255, 255, 0.7)')};
`;

const CategoryTitle = styled.div<{ $disabledColor: string } & CustomColor>`
  width: calc(100% - 32px);
  border-top: ${({ $disabledColor }) => `1px solid ${$disabledColor}`};
  margin: 0 16px 20px 16px;
  padding-top: 24px;
  ${APP_FONT_18PX_MEDIUM};
  color: ${useCustomColor};
  display: flex;
  justify-content: space-between;
`;

const StyledChevronUp = styled(AppChevronUpIcon)<CustomColor>`
  color: ${useCustomColor};
  font-size: 24px;

  svg {
    fill: none !important;
  }
`;

interface Module {
  id: string;
  title: string;
  subcategories: SimpleModule[];
  lessons: BuilderCollectionItem[];
}

interface Course {
  categories: Module[];
  hasSubcategories: boolean; // If Just Submodules+Lessons
  lessons: BuilderCollectionItem[];
}

export const CourseView = () => {
  const { getContentForItem } = useContent();
  const { getDesignProperty } = useAppTheme();
  const { collection } = useAppCollectionContext();
  const { data: properties } = useAppProperties();

  const [descExpanded, setDescExpanded] = useState<boolean>(false);

  const course: Course = useMemo(() => {
    const categories: Module[] = [];
    const lessons: BuilderCollectionItem[] = [];
    let hasSubcategories = false;
    const items = collection?.Items || [];

    for (const item of items) {
      if (item.Type === ITEM_TYPE_VIDEO || isSectionHeaderItem(item)) {
        lessons.push(item);
      } else if (item.Type === ITEM_TYPE_COLLECTION) {
        const collection = getContentForItem(item) as BuilderCollection;
        const name = collection ? getContentName(collection, item.Type) : 'Missing';
        const category: Module = { id: collection.TabId.toString(), title: name, subcategories: [], lessons: [] };
        for (const childItem of collection.Items) {
          if (childItem.Type === ITEM_TYPE_VIDEO) {
            category.lessons.push(childItem);
          } else if (childItem.Type === ITEM_TYPE_COLLECTION) {
            const childCollection = getContentForItem(childItem) as BuilderCollection;
            const name = childCollection ? getContentName(childCollection, childItem.Type) : 'Missing';
            const subcategory: SimpleModule = { id: childCollection.TabId.toString(), title: name, lessons: [] };
            for (const grandchildItem of childCollection.Items) {
              if (grandchildItem.Type === ITEM_TYPE_VIDEO) {
                subcategory.lessons.push(grandchildItem);
              } else {
                console.debug('Unexpected group in Kajabi Subcategory');
              }
            }
            category.subcategories.push(subcategory);
          }
        }
        categories.push(category);

        if (category.subcategories.length > 0) {
          hasSubcategories = true;
        }
      }
    }

    return { categories, lessons, hasSubcategories };
  }, [collection]);

  const $color = getDesignProperty('primaryItemHEX') as string;
  const $disabledColor = getDesignProperty('disabledItemHEX') as string;

  const purifiedDesc = useMemo(() => {
    const desc = collection ? getCollectionHTMLDescription(collection) : '';
    return DOMPurify.sanitize(desc);
  }, [collection]);

  if (!collection) {
    console.error('Missing collection for Course View');
    return null;
  }

  return (
    <>
      <HeroImage thumbnail={collection} defaultBorder="2px solid whitesmoke" overrideRadius="0px" />
      <Container>
        {purifiedDesc && (
          <About
            $color={$color}
            onClick={() => {
              setDescExpanded((prev) => !prev);
            }}
          >
            {!descExpanded && <TranslucentContainer isDark={properties?.ColorScheme === 'Dark'} />}
            <DescText dangerouslySetInnerHTML={{ __html: purifiedDesc }} $expanded={descExpanded} />
          </About>
        )}

        {course.hasSubcategories ? (
          course.categories.map((category, idx) => {
            if (idx === 0) {
              return (
                <>
                  <CategoryTitle $color={$color} $disabledColor={$disabledColor}>
                    <div>{category.title}</div>
                    <StyledChevronUp $color={$color} />
                  </CategoryTitle>
                  {/* Render the subcategories and lessons in the modules*/}
                  {category.subcategories.map((subcategory) => {
                    return <SubCategory subcategory={subcategory} key={subcategory.id} />;
                  })}
                  {category.lessons.map((lesson) => {
                    return <Lesson lesson={lesson} key={lesson.TabItemId} />;
                  })}
                </>
              );
            }
            // We currently only render the first module
            return (
              <CategoryTitle $color={$color} $disabledColor={$disabledColor}>
                <div>{category.title}</div>
                <StyledChevronUp $color={$color} />
              </CategoryTitle>
            );
          })
        ) : (
          <>
            {course.categories.map((subcategory) => {
              return <SubCategory subcategory={subcategory} key={subcategory.id} />;
            })}
          </>
        )}

        {course.lessons.map((lesson) => {
          return <Lesson lesson={lesson} key={lesson.TabItemId} />;
        })}
      </Container>
    </>
  );
};
