import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosInstance, getTaggedVideos } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useTaggedVideos = (
  staleTime?: UseQueryOptions['staleTime'],
  refetchOnMount?: UseQueryOptions['refetchOnMount'],
) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const query = useQuery(['tagged_videos', appId], () => getTaggedVideos(client, appId), {
    staleTime: staleTime ?? 30000,
    refetchOnMount: refetchOnMount ?? true,
  });
  const { data } = query;

  const getFilterOptionsByVideoId = useCallback(
    (id: number) => {
      return data?.filter_options_by_video_id[id] ?? [];
    },
    [data],
  );

  return {
    ...query,
    getFilterOptionsByVideoId,
  };
};
