import styled from 'styled-components';
import { FONT_20PX_NORMAL } from '../../font';
import { NEUTRAL_9_COLOUR } from '../../../../../../theme';
import { Dot } from '../Common/Dot';

export const FlexRow = styled.div`
  display: flex;
`;

export const InstructionText = styled.div`
  margin-top: 24px;
  ${FONT_20PX_NORMAL};
  color: ${NEUTRAL_9_COLOUR};
`;
export const WrappedDot = styled(Dot)`
  margin-right: 16px;
`;
