import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { useAxiosInstance, publishApp, Stage } from 'api';
import { useRecordAction } from 'hooks';

export const usePublishApp = () => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, Stage>(
    (stage) => {
      return publishApp(client, appId, stage);
    },
    {
      onSuccess: (_response, stage) => {
        recordAction.mutate({ action: `Published to ${stage}` });
      },
    },
  );
};
