import { CollectionDiff } from 'api';
import { useContent } from 'providers';
import { getCollectionName } from 'utils';

import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { Card, Title, List, Subtitle } from './Misc';

const getSubtitles = (diff: CollectionDiff) => {
  const subtitles = [];
  if (diff.NewResources && diff.NewResources.length > 0) {
    subtitles.push(`${diff.NewResources.length} New Resources`);
  }
  if (diff.DeletedResources && diff.DeletedResources.length > 0) {
    subtitles.push(`${diff.DeletedResources.length} Deleted Resources`);
  }
  if (diff.ModifiedResources && diff.ModifiedResources.length > 0) {
    subtitles.push(`${diff.ModifiedResources.length} Modified Resources`);
  }
  if (diff.NewItems && diff.NewItems.length > 0) {
    subtitles.push(`${diff.NewItems.length} New Items`);
  }
  if (diff.DeletedItems && diff.DeletedItems.length > 0) {
    subtitles.push(`${diff.DeletedItems.length} Deleted Items`);
  }
  if (diff.ModifiedItems && diff.ModifiedItems.length > 0) {
    subtitles.push(`${diff.ModifiedItems.length} Modified Items`);
  }
  if (diff.FieldDiffs && diff.FieldDiffs.length > 0) {
    subtitles.push(`${diff.FieldDiffs.length} Fields Changed`);
  }
  return subtitles;
};

export const CollectionDiffDisplay = (diff: CollectionDiff) => {
  const { collections } = useContent();
  const collection = collections[diff.TabId];
  const title = getCollectionName(collection);
  const subtitles = getSubtitles(diff);

  return (
    <Card key={diff.TabId}>
      <ContentThumbnail thumbnail={collection} size="small" />
      <Title>{title}</Title>
      <List>
        {subtitles.map((sub) => (
          <Subtitle key={sub}>{sub}</Subtitle>
        ))}
      </List>
    </Card>
  );
};
