import { useCallback } from 'react';
import { Form } from 'antd';
import {
  ContentAdvancedSettings,
  Segment,
  SegmentTitle,
  SettingsCheckboxInput,
  SettingsColorInput,
  SettingsFormItem,
  SettingsSelectInput,
  SettingsTextArea,
} from 'components';

import { useTabSettings } from 'app/modules/build-screens/hooks';

import { NavigationHeader, TabBar } from '../components';
import { getPageName } from '../utils';
import { DrawerSeparator } from './components/DrawerSeparator';
import { useContent, useLocalAppProperties } from 'providers';
import { TabDrawer } from '../components/Common/TabDrawer';

const initialViewOptions = {
  options: [
    { name: 'Week', value: 'Week' },
    { name: 'Month', value: 'Month' },
  ],
  default: 'Week',
};

interface CalendarTabDrawerProps {
  tabId: number;
}

export const CalendarTabDrawer = ({ tabId }: CalendarTabDrawerProps) => {
  const [form] = Form.useForm();
  const { collections } = useContent();
  const tab = collections[tabId];
  const { properties } = useLocalAppProperties();

  // The declared variables are managed in useTabSettings
  const { onValuesChange } = useTabSettings(
    {
      NavBarTitleText: { type: 'collection', valueType: 'string' },
      Name: { type: 'collection', valueType: 'string' },
      LockedUntilSignIn: { type: 'collectionProperty', valueType: 'binary' },
      Icon: { type: 'collection', valueType: 'string' },
      CalendarInitialViewOnLoad: { type: 'app', valueType: 'string' },
      CalendarHideMarkCompletePreviously: { type: 'app', valueType: 'inverseBinary' },
      CustomWatchedVideoAlertText: { type: 'app', valueType: 'string' },
      WatchedHEX: { type: 'app', valueType: 'fullHex' },
      ScheduledHEX: { type: 'app', valueType: 'fullHex' },
      MissedHEX: { type: 'app', valueType: 'fullHex' },
      JournalHEX: { type: 'app', valueType: 'fullHex', defaultVal: '#ffffff' },
    },
    tabId,
    form,
  );

  const onColorChange = useCallback(
    (color, property) => {
      onValuesChange({ [property]: color });
    },
    [onValuesChange],
  );

  const onTabIconFilenameChange = useCallback(
    (_filename) => {
      onValuesChange({ Icon: _filename }, {}, true);
    },
    [onValuesChange],
  );

  return (
    <TabDrawer tabId={tabId}>
      <Form
        name="settings-form"
        onValuesChange={(changedValues) => {
          onValuesChange(changedValues);
        }}
        autoComplete="off"
        form={form}
      >
        <NavigationHeader tabId={tabId} placeholder={getPageName(tab)} />
        <DrawerSeparator />
        <Segment header="Default Calendar View">
          <SettingsFormItem name="CalendarInitialViewOnLoad">
            <SettingsSelectInput
              size="middle"
              defaultVal={initialViewOptions.default}
              options={initialViewOptions.options}
            />
          </SettingsFormItem>
        </Segment>
        <DrawerSeparator />
        <Segment header="Calendar Icons">
          <SegmentTitle title="Completed" />
          <SettingsFormItem name="WatchedHEX">
            <SettingsColorInput onChange={(color) => onColorChange(color, 'WatchedHEX')} margin="0 0 32px" />
          </SettingsFormItem>
          <SegmentTitle title="Scheduled" />
          <SettingsFormItem name="ScheduledHEX">
            <SettingsColorInput onChange={(color) => onColorChange(color, 'ScheduledHEX')} margin="0 0 32px" />
          </SettingsFormItem>
          <SegmentTitle title="Missed" />
          <SettingsFormItem name="MissedHEX">
            <SettingsColorInput
              onChange={(color) => onColorChange(color, 'MissedHEX')}
              margin={properties.ShowJournal === '1' ? '0 0 32px' : '0'}
            />
          </SettingsFormItem>
          {properties.ShowJournal === '1' && (
            <>
              <SegmentTitle title="Journal" />
              <SettingsFormItem name="JournalHEX">
                <SettingsColorInput onChange={(color) => onColorChange(color, 'JournalHEX')} margin="0" />
              </SettingsFormItem>
            </>
          )}
        </Segment>
        <DrawerSeparator />
        <Segment
          header="Post or Video Completed Message"
          subHeader="The message a user sees when they complete a post or watch a video. "
        >
          <SettingsFormItem name="CustomWatchedVideoAlertText">
            <SettingsTextArea
              size="middle"
              maxLength={60}
              placeholder="Well done! Nice work on completing this today."
            />
          </SettingsFormItem>
        </Segment>
        <DrawerSeparator />
        <ContentAdvancedSettings collection={tab} isDrawer isTab />
        <SettingsCheckboxInput
          label="Users can retroactively mark posts or videos as complete"
          name="CalendarHideMarkCompletePreviously"
          margin="5px 0 0 0"
        />
        <TabBar filename={tab.Icon} placeholder={getPageName(tab)} onFilenameChange={onTabIconFilenameChange} />
      </Form>
    </TabDrawer>
  );
};
