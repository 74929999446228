import { AxiosInstance } from 'axios';
import { ETL_URL, SOURCE_KAJABI } from './const';
import { getUserEmail } from 'utils';

export interface OnboardingAssets {
  AppIconHex: string;
  BrandHex: string;
  AppIconFilename: string; // filename for S3 uploaded app icon PNG
}

export const uploadBrandAssets = (axiosInstance: AxiosInstance, appId: string, payload: OnboardingAssets) => {
  return axiosInstance.post(`onboarding/${appId}/branding`, payload).catch((err) => {
    console.error(err);
  });
};

export const sendOnboardingEmail = (client: AxiosInstance) => {
  return client.post('onboarding/preview_email');
};

interface KajabiCredential {
  Username: string;
  Password: string;
}

interface VimeoOTTCredential {
  APIKey: string;
}

export type Credentials = KajabiCredential | VimeoOTTCredential;

export const validateAccount = (client: AxiosInstance, appId: string, dataSource: string, payload: Credentials) => {
  return client.post(`onboarding/${dataSource}/${appId}/validate-account`, payload);
};

export interface CollectionBasicInfo {
  Title: string;
  Image: string;
  Position: number;
  URI: string;
}

export const listCollections = (client: AxiosInstance, appId: string, dataSource: string, limit?: number) => {
  return client
    .get(`onboarding/${dataSource}/${appId}/list-collections`, { params: { limit: limit } })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export interface SyncOptions {
  themeId?: number;
  syncToApp?: boolean;
  isOnboarding: boolean;
  productId?: string;
}

export const runSync = (
  client: AxiosInstance,
  appId: string,
  dataSource: string,
  { syncToApp, isOnboarding, productId, themeId }: SyncOptions,
) => {
  const payload: {
    AppId: string;
    DataSource: string;
    Initiator: string;
    TaskID?: string;
    SourceType?: string;
    SourceId?: string;
    SyncToApp: boolean;
    IsOnboarding: boolean;
    KajabiDataThemeId?: number;
  } = {
    AppId: appId,
    DataSource: dataSource,
    Initiator: getUserEmail(),
    SyncToApp: syncToApp ?? true,
    IsOnboarding: isOnboarding ?? false,
  };

  return client.get(`sync-tracing/${appId}/uuid`).then((response) => {
    const taskID = response.data;
    if (taskID) {
      payload.TaskID = taskID;
    }
    if (dataSource === SOURCE_KAJABI && productId) {
      payload.SourceType = 'Product';
      payload.SourceId = productId;
      if (themeId) {
        payload.KajabiDataThemeId = themeId;
      }
    } else {
      payload.SourceId = 'all';
    }

    return client.post(`${ETL_URL}/extract-data-for-app`, payload);
  });
};
