import { useQuery } from 'react-query';
import { getVideoFiles, SOURCE_JWPLAYER, SOURCE_VHX, useAxiosInstance, VideoFile } from 'api';
import { useAppBeingEdited } from 'app-context';
import { AxiosError } from 'axios';
import { useContent } from 'providers';
import { getContentResourceType, getResourceUrl, isContentResource } from 'utils';

const V2_FILES_DATASOURCES = [SOURCE_VHX, SOURCE_JWPLAYER];

export const useVideoFiles = (videoId: string | number) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const { videos } = useContent();

  const video = videos[videoId];

  const isFilesV2 = V2_FILES_DATASOURCES.includes(video?.DataSource);

  const query = useQuery<VideoFile[] | { error: string }, AxiosError>(
    ['videofiles', video?.DataSource, video?.SourceId],
    () => getVideoFiles(client, appId, video?.SourceId, video?.DataSource),
    {
      enabled: isFilesV2 && !!video?.DataSource && !!video?.SourceId,
      staleTime: 60000,
      retry: false,
    },
  );

  if (!video) {
    return { error: { message: 'Video not found' } };
  }
  if (isContentResource(video, 'video')) {
    return {
      data: [
        {
          Type: getContentResourceType(video, 'video'),
          URL: getResourceUrl(appId, { Url: video.OriginalFilename }),
        },
      ],
    };
  }

  if (isFilesV2) {
    if ('error' in (query?.data || {})) {
      // This is the format the files/v2 endpoint returns its errors in
      return { error: { message: (query.data as { error: string }).error } };
    }
    return { ...query, data: query.data as VideoFile[] | undefined };
  }

  // Get Files straight from video
  return { data: video['Files'] };
};
