import { useCallback } from 'react';
import { Form } from 'antd';

import { PageContainer, SettingsGroup, SettingsImageInput, CustomButton, SettingsSegmentDivider } from 'components';

import { useAppStore, PropertyType } from '../../hooks/useAppStore';
import { ScreenshotInput, ScreensContainer, AddScreenshotButton } from '../../components';

// Map of all properties being edited on this screen
const PROPERTIES: Record<string, PropertyType> = {
  RokuNavBarImage: { type: 'appProperty', isFile: true },
  TVAppIconBack: { type: 'appProperty', isFile: true },
  TVAppIconMiddle: { type: 'appProperty', isFile: true },
  TVAppIconFront: { type: 'appProperty', isFile: true },
  TVAppLaunchImage: { type: 'appProperty', isFile: true },
  TVAppTopShelfImage: { type: 'appProperty', isFile: true },
  TVAppTopShelfWideImage: { type: 'appProperty', isFile: true },
};

interface ImageField {
  property: string;
  heading: string;
  tooltip: string;
  width: string;
  height: string;
  requiredWidth: number;
  requiredHeight: number;
}

const IMAGE_FIELDS: ImageField[] = [
  {
    property: 'RokuNavBarImage',
    heading: 'TV Logo Image',
    tooltip: 'Logo image for your TV app.',
    width: '450px',
    height: '97px',
    requiredWidth: 1280,
    requiredHeight: 275,
  },
  {
    property: 'TVAppIconBack',
    heading: 'App Icon (Back)',
    tooltip: 'The back layer of your TV app icon.',
    width: '400px',
    height: '240px',
    requiredWidth: 1280,
    requiredHeight: 768,
  },
  {
    property: 'TVAppIconMiddle',
    heading: 'App Icon (Middle)',
    tooltip: 'The middle layer of your TV app icon.',
    width: '400px',
    height: '240px',
    requiredWidth: 1280,
    requiredHeight: 768,
  },
  {
    property: 'TVAppIconFront',
    heading: 'App Icon (Front)',
    tooltip: 'The front layer of your TV app icon.',
    width: '400px',
    height: '240px',
    requiredWidth: 1280,
    requiredHeight: 768,
  },
  {
    property: 'TVAppLaunchImage',
    heading: 'Launch Image',
    tooltip: 'Displayed while your app is loading.',
    width: '500px',
    height: '282px',
    requiredWidth: 3840,
    requiredHeight: 2160,
  },
  {
    property: 'TVAppTopShelfImage',
    heading: 'Top Shelf Image (Normal)',
    tooltip: 'Shown on the Apple TV home screen (when your app is in focus).',
    width: '500px',
    height: '188px',
    requiredWidth: 3840,
    requiredHeight: 1440,
  },
  {
    property: 'TVAppTopShelfWideImage',
    heading: 'Top Shelf Image (Wide)',
    tooltip: 'Shown on the Apple TV home screen (when your app is in focus).',
    width: '604px',
    height: '188px',
    requiredWidth: 4640,
    requiredHeight: 1440,
  },
];

export const AppStoreAppleTVSettings = () => {
  const {
    form,
    unsavedChanges,
    isSaving,
    isLoading,
    isError,
    filenames,
    screenshots,
    addScreenshot,
    removeScreenshot,
    onValuesChange,
    onFilenameChange,
    onScreenshotChange,
    saveForm,
  } = useAppStore(PROPERTIES, 'AppleTV');

  const getImageFields = useCallback(
    (fieldArr: ImageField[]) => {
      return fieldArr.map(({ property, requiredWidth, requiredHeight, ...props }) => (
        <SettingsGroup>
          <SettingsImageInput
            key={property}
            requiredDimensions={{ requiredWidth: requiredWidth, requiredHeight: requiredHeight }}
            requirementsLabel={`PNG. ${requiredWidth} x ${requiredHeight}`}
            acceptedFileTypes="image/png"
            hideDelete
            downloadable
            filename={filenames[property]?.value}
            onFilenameChange={(_filename) => onFilenameChange(property, _filename)}
            {...props}
          />
        </SettingsGroup>
      ));
    },
    [filenames],
  );

  return (
    <PageContainer
      heading="Apple TV App Store Details"
      subheading={
        <>
          Update information presented in the Apple App Store. For more information on each element please visit our{' '}
          <a href="https://vidapp.com/apple-tv-app-store-product-page-vidapp" target="_blank" rel="noopener noreferrer">
            Apple TV App Store Product page here.
          </a>{' '}
          Please note any changes here will require a new version of your app to be submitted through the Apple TV
          review process.
        </>
      }
      headingButton={
        <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
          Save
        </CustomButton>
      }
      isLoading={isLoading}
      isError={isError}
    >
      <Form name="settings-form" onValuesChange={onValuesChange} onFinish={saveForm} autoComplete="off" form={form}>
        {getImageFields(IMAGE_FIELDS)}
        <SettingsSegmentDivider />
        <SettingsGroup title="Apple TV Screenshots">
          <ScreensContainer>
            {screenshots.appleTV?.map((screenshot, idx) => (
              <ScreenshotInput
                key={screenshot.id}
                heading={`Screenshot ${idx + 1}`}
                filename={screenshot.filename}
                id={screenshot.id}
                width="366px"
                height="206px"
                requiredDimensions={{ requiredWidth: 3840, requiredHeight: 2160 }}
                device="appleTV"
                handleRemove={() => removeScreenshot(screenshot.id, 'appleTV')}
                handleFilenameChange={onScreenshotChange}
              />
            ))}
          </ScreensContainer>
          <AddScreenshotButton
            text="Add an Apple TV screenshot"
            disabled={screenshots.appleTV?.length > 9}
            device="appleTV"
            handleClick={addScreenshot}
          />
        </SettingsGroup>
      </Form>
    </PageContainer>
  );
};
