import { AxiosInstance } from 'axios';

export interface Template {
  TemplateId: number;
  Name: string;
  AvailableInBuilder: number;
  AvailableOnMobile: number;
  AvailableOnTV: number;
  AvailableOnTemplatedApp: number;
  RestrictedTypes?: string;
  IsVideosTab: number;
  CanHaveItems: number;
  DisplayName: string;
  Position: number;
}

export const getTemplates = (client: AxiosInstance) => {
  return client.get<Template[]>(`misc/templates`).then(({ data }) => {
    const templateById: Record<number, Template> = {};
    for (const template of data) {
      templateById[template.TemplateId] = template;
    }
    return { templates: data, templateById };
  });
};
