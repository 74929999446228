import styled from 'styled-components';

import { CustomButton } from 'components';
import { NEUTRAL_10_COLOUR } from 'theme';

// Used to return from a Sub Panel
export const DrawerBackButton = styled(CustomButton)`
  &&& {
    margin-top: 8px;
    margin-bottom: 32px;
    padding: 4px 5px 4px 4px;
    color: ${NEUTRAL_10_COLOUR};

    .anticon,
    span {
      margin-left: 0 !important;
      margin-right: 2px !important;
    }
  }
`;
