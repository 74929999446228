import styled from 'styled-components';

import { CustomButton } from 'components';

export const SaveButton = styled(CustomButton)`
  &&&& {
    position: absolute;
    right: 0;
  }
`;
