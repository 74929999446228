import styled from 'styled-components';

import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { useAppBasicInfo } from 'hooks';
import { S3_BUCKET } from 'api';

const Phone = styled.div`
  width: 428px;
  height: 891px;
  background-image: url(${BUILDER_IMAGES_DOMAIN}/mockup/pushnotificationpreviewbg.webp);
  background-size: cover;
  padding: 122px 34px 10px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScheduledTime = styled.div`
  font-size: 81px;
  font-weight: 300;
  color: #fff;
  line-height: 1;
`;

const ScheduledDate = styled(ScheduledTime)`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 24px;
`;

const Notification = styled.div`
  background-color: rgba(245, 245, 245, 0.6);
  border-radius: 12px;
  width: 100%;
  padding: 17px 15px;
`;

const Icon = styled.img`
  width: 24px;
  border-radius: 7px;
  margin-right: 7px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 7px;
`;

const AppTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #838383;
`;

const NowLabel = styled(AppTitle)`
  margin-left: auto;
`;

const Title = styled.p`
  &&&& {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 7px;
    overflow-wrap: break-word;
  }
`;

const Message = styled.p`
  &&&& {
    white-space: pre-wrap;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
    overflow-wrap: break-word;
  }
`;

export interface PushNotificationPreviewProps {
  scheduledTime: string;
  scheduledDate: string;
  title: string;
  message: string;
  helpText?: string;
  marginLeft?: string;
}

export const PushNotificationPreview = ({
  scheduledTime,
  scheduledDate,
  title,
  message,
}: PushNotificationPreviewProps) => {
  const { data: appBasicInfo } = useAppBasicInfo(Infinity, 'always');

  return (
    <Phone>
      <ScheduledTime>{scheduledTime}</ScheduledTime>
      <ScheduledDate>{scheduledDate}</ScheduledDate>
      <Notification>
        <Header>
          <Icon src={`${S3_BUCKET}${appBasicInfo?.AppId}/images/${appBasicInfo?.AppIconImage}`} alt="app icon" />
          <AppTitle>{appBasicInfo?.AppleTitle ?? 'App Title'}</AppTitle>
          <NowLabel>Now</NowLabel>
        </Header>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </Notification>
    </Phone>
  );
};
