import { CSSProperties, useCallback, useRef } from 'react';
import styled from 'styled-components';

import { FONT_10PX_REGULAR } from 'font';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { CustomMargin, DEFAULT_PORTRAIT_THUMBNAIL, DEFAULT_SQUARE_THUMBNAIL, useCustomMarginTop } from 'utils';
import { UploadIcon } from 'icons';
import { CustomButton, ImageUploader, Segment, SegmentProps } from 'components';
import { useAppProperties } from 'hooks';

const Description = styled.div`
  margin-top: 12px;
  ${FONT_10PX_REGULAR};
  color: ${NEUTRAL_7_COLOUR};
`;

const UploadButton = styled(CustomButton)`
  #react-app && {
    color: ${HIGHLIGHT_PRIMARY_COLOUR};
    margin-left: -4px;
    font-weight: 500;
  }
`;

const Container = styled.div<{ $direction: CSSProperties['flexDirection'] }>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
`;

const RightColumn = styled.div<CustomMargin>`
  margin-top: ${useCustomMarginTop};
  margin-left: 8px;
`;
const Medium = styled.div`
  font-weight: 500;
`;
const Spacer = styled.div`
  width: 24px;
  height: 24px;
`;

interface SettingsAlternateThumbnailInputProps extends SegmentProps {
  squareFilename?: string;
  portraitFilename?: string;

  isSquareRevertable?: boolean | '';
  handleSquareThumbnailChange: (filename: string) => void;
  handleRevertSquareThumbnail?: () => void;

  isPortraitRevertable?: boolean | '';
  handlePortraitThumbnailChange: (filename: string) => void;
  handleRevertPortraitThumbnail?: () => void;

  squareWidth?: string; //width=height
  portraitWidth?: string;
  portraitHeight?: string;

  orientation?: 'column' | 'row'; //how to display this segment
}

export const SettingsAlternateThumbnailInput = ({
  // Square
  squareFilename,
  squareWidth,
  isSquareRevertable,
  handleSquareThumbnailChange,
  handleRevertSquareThumbnail,
  // Portrait
  portraitFilename,
  portraitHeight,
  portraitWidth,
  isPortraitRevertable,
  handlePortraitThumbnailChange,
  handleRevertPortraitThumbnail,
  // Common
  orientation,
  ...props
}: SettingsAlternateThumbnailInputProps) => {
  const { data } = useAppProperties();
  const overrideSquareRef = useRef<HTMLInputElement>(null);
  const overridePortraitRef = useRef<HTMLInputElement>(null);

  const handleSquareUploadClick = useCallback(() => {
    overrideSquareRef.current?.click(); //Forward click to the file input
  }, [overrideSquareRef]);
  const handlePortraitUploadClick = useCallback(() => {
    overridePortraitRef.current?.click(); //Forward click to the file input
  }, [overridePortraitRef]);

  const innerDirection = orientation === 'row' ? 'column' : 'row';
  const columnTopMargin = orientation === 'row' ? '8px' : undefined;

  if (data?.RolloutAlternateThumbnails !== '1') {
    return null;
  }

  return (
    <Segment header="Additional Thumbnails" isBeta {...props}>
      <Container $direction={orientation}>
        <Container $direction={innerDirection}>
          <ImageUploader
            overrideRef={overrideSquareRef}
            backgroundImage={DEFAULT_SQUARE_THUMBNAIL}
            borderRadius="4px"
            width={squareWidth || '190px'}
            height={squareWidth || '190px'}
            filename={squareFilename}
            onFilenameChange={handleSquareThumbnailChange}
            hideUploadButton
            borderColor={!squareFilename || squareFilename === '' ? NEUTRAL_5_COLOUR : undefined}
            scaleSpinner={75}
            showCopyButton
            showRevertButton={!!isSquareRevertable}
            onRevert={handleRevertSquareThumbnail}
          />
          <RightColumn $marginTop={columnTopMargin}>
            <UploadButton tertiary small icon={<UploadIcon />} onClick={handleSquareUploadClick}>
              {squareFilename !== '' ? 'Replace' : 'Upload'}
            </UploadButton>
            <Description>
              <Medium>Square</Medium>
              1:1 ratio image <br />
              1080 x 1080
            </Description>
          </RightColumn>
        </Container>
        <Spacer />
        <Container $direction={innerDirection}>
          <ImageUploader
            overrideRef={overridePortraitRef}
            backgroundImage={DEFAULT_PORTRAIT_THUMBNAIL}
            borderRadius="4px"
            width={portraitWidth || '126px'}
            height={portraitHeight || '190px'}
            filename={portraitFilename}
            onFilenameChange={handlePortraitThumbnailChange}
            hideUploadButton
            borderColor={!portraitFilename || portraitFilename === '' ? NEUTRAL_5_COLOUR : undefined}
            scaleSpinner={75}
            showCopyButton
            showRevertButton={!!isPortraitRevertable}
            onRevert={handleRevertPortraitThumbnail}
          />
          <RightColumn $marginTop={columnTopMargin}>
            <UploadButton tertiary small icon={<UploadIcon />} onClick={handlePortraitUploadClick}>
              {portraitFilename !== '' ? 'Replace' : 'Upload'}
            </UploadButton>
            <Description>
              <Medium>Portrait</Medium>
              2:3 ratio image <br />
              1080 x 1620
            </Description>
          </RightColumn>
        </Container>
      </Container>
    </Segment>
  );
};
