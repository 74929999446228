import { AxiosInstance } from 'axios';

export const getAppleCategories = (client: AxiosInstance) => {
  return client.get<string[]>(`misc/categories-apple`).then((response) => {
    const results = response.data.map((category) => ({
      name: category.replaceAll('Apps.', '').replaceAll('_', ' & '),
      value: category,
    }));
    return results;
  });
};
