import { useQuery, useQueryClient } from 'react-query';

import { BuilderCollection, useContent } from 'providers';
import { useAppBeingEdited } from 'app-context';
import { useCallback } from 'react';

// This hook is dependent on useContent and therefore must be used inside a ContentProvider
export const useContentBySourceId = () => {
  const appId = useAppBeingEdited();
  const { collections } = useContent();

  const query = useQuery([appId, 'collections_by_source_id'], () => {
    // Return one to many collections for a single source id (due to multiple possible data sources)
    const col_by_source_id: Record<string, BuilderCollection[]> = {};
    const collectionsArray = Object.values(collections);

    for (const c of collectionsArray) {
      const { SourceId } = c;
      const existing = col_by_source_id[SourceId];
      if (existing === undefined) {
        col_by_source_id[SourceId] = [];
      }
      col_by_source_id[SourceId].push(c);
    }

    return col_by_source_id;
  });

  const getCollectionBySourceId = useCallback(
    (sourceId: string, dataSource: string) => {
      if (query.data) {
        const col_by_source_id = query.data;
        const collections = col_by_source_id[sourceId];
        return collections?.find((c) => c.DataSource === dataSource);
      }
      return undefined;
    },
    [query.data],
  );

  return { ...query, getCollectionBySourceId };
};

export const useInvalidateContentBySourceId = () => {
  const appId = useAppBeingEdited();
  const queryClient = useQueryClient();
  return () => {
    return queryClient.invalidateQueries([appId, 'collections_by_source_id']);
  };
};
