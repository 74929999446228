import styled from 'styled-components';

import { NEUTRAL_10_COLOUR } from 'theme';

interface HighlightTextProps {
  text: string;
  highlight?: string;
}

const Highlighted = styled.span`
  font-weight: 700 !important;
  color: ${NEUTRAL_10_COLOUR} !important;
`;

export const HighlightText = ({ text, highlight }: HighlightTextProps) => {
  if (!highlight || highlight === '') {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? <Highlighted key={i}>{part}</Highlighted> : <span key={i}>{part}</span>;
      })}
    </span>
  );
};
