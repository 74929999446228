import { BuilderVideo } from 'providers';
import { getContentResourceType, getFileUrl } from 'utils';
import { ImgView, PDFView } from '..';

interface FileViewProps {
  file: BuilderVideo;
}

export const FileView = ({ file }: FileViewProps) => {
  const type = getContentResourceType(file, 'video');
  const url = getFileUrl(file);
  console.log('FileView', url, file);

  if (type === 'pdf') {
    return <PDFView url={url} />;
  }
  if (['png', 'jpg', 'jpeg']) {
    return <ImgView url={url} />;
  }
  return null;
};
