import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { BuilderCollection, BuilderCollections, BuilderVideos, ContentProvider } from 'providers/content-provider';
import { LoadingSpinner } from 'components';
import styled from 'styled-components';
import { useCollections, useVideos } from 'hooks';
import { useHistory } from 'react-router-dom';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface DefaultContentProviderProps {
  refetchOnMount?: 'always' | boolean;
  disableVideos?: boolean; // Save on API calls if unused
  disableCollections?: boolean; // Save on API calls if unused
  customLoader?: ReactNode;
  updateOnRefetch?: boolean; // If true, videos and collections states will update when data is refetched
  children: ReactNode | ReactNode[];
}

export const DefaultContentProvider = ({
  refetchOnMount,
  disableCollections,
  disableVideos,
  customLoader,
  updateOnRefetch,
  children,
}: DefaultContentProviderProps) => {
  const [collections, setCollections] = useState<BuilderCollections>();
  const [videos, setVideos] = useState<BuilderVideos>();
  const history = useHistory();
  // refetchOnMount:true, assume not used in conjunction with Build Advanced
  const { data: collectionData, isRefetching: collectionsRefetching } = useCollections(
    Infinity,
    refetchOnMount ?? true,
    { enabled: !disableCollections },
  );
  const { data: videoData, isRefetching: videosRefetching } = useVideos(Infinity, refetchOnMount ?? true, {
    enabled: !disableVideos,
  });

  const revertLocalChanges = () => {
    console.debug('DefaultContentProvider', 'Reverting local changes');
    setVideos(videoData?.video_by_id);
    setCollections(collectionData?.collection_by_id as Record<number, BuilderCollection>);
  };

  // When the user navigates to a new page the local changes will be reverted
  history.listen((location, action) => {
    if (action === 'PUSH' || action === 'POP') {
      revertLocalChanges();
    }
  });

  useEffect(() => {
    // Setup state once on initial data load
    if (videos === undefined && !!videoData) {
      setVideos(videoData.video_by_id);
    } else if (disableVideos) {
      setVideos({});
    }

    if (collections === undefined && !!collectionData) {
      const dragDropCollections = collectionData.collection_by_id as Record<number, BuilderCollection>;
      setCollections(dragDropCollections);
    } else if (disableCollections) {
      setCollections({});
    }
  }, [videoData, collectionData]);

  useEffect(() => {
    if (updateOnRefetch) {
      if (!videosRefetching && !!videoData) {
        setVideos(videoData.video_by_id);
      } else if (disableVideos) {
        setVideos({});
      }

      if (!collectionsRefetching && !!collectionData) {
        const dragDropCollections = collectionData.collection_by_id as Record<number, BuilderCollection>;
        setCollections(dragDropCollections);
      } else if (disableCollections) {
        setCollections({});
      }
    }
  }, [videosRefetching, videoData, collectionsRefetching, collectionData]);

  const hasLoaded = (disableVideos || !!videos) && (disableCollections || !!collections);

  return !hasLoaded ? (
    <>
      {!!customLoader ? (
        customLoader
      ) : (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )}
    </>
  ) : (
    <ContentProvider
      collections={collections as BuilderCollections}
      setCollections={setCollections as Dispatch<SetStateAction<Record<string | number, BuilderCollection>>>}
      videos={videos as BuilderVideos}
      setVideos={setVideos as Dispatch<SetStateAction<BuilderVideos>>}
    >
      {children}
    </ContentProvider>
  );
};
