import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
  CICUIT_TYPE_TIME_BASED,
  CIRCUIT_TEMPLATE_ID,
  DEFAULT_COLLECTION,
  DEFAULT_COLLECTION_ITEM,
  ITEM_TYPE_COLLECTION,
  SOURCE_TYPE_CIRCUIT,
} from 'api';
import { useAppBeingEdited } from 'app-context';
import { CustomButton, Dropdown } from 'components';
import { useAppProperties } from 'hooks';
import { PlusIcon, UISyncIcon } from 'icons';
import { useContent, useSaveContext } from 'providers';
import { getCollectionValuesFromNewCollection } from 'utils';
import { useModalContext } from '../../cms-modal/providers/modal-provider';

const DEFAULT_CIRCUIT_PROPERTIES = [
  { key: 'CircuitRounds', value: '1' },
  { key: 'CircuitType', value: CICUIT_TYPE_TIME_BASED },
];

const HeaderButton = styled(CustomButton)`
  &&&& {
    margin-left: 4px;
  }
`;

interface AddCircuitButtonProps {
  collectionId: string | number;
}

export const AddCircuitButton = ({ collectionId }: AddCircuitButtonProps) => {
  const { setNewCollection, setCollectionProperty, setCollectionItems, collections } = useContent();
  const { getTempId, setCollectionToSave, setCollectionPropertyToSave, setCollectionItemsToSave } = useSaveContext();
  const { setModalCollectionId } = useModalContext();
  const appId = useAppBeingEdited();
  const { data: appProperties } = useAppProperties();

  const interRoundRestDefault = appProperties?.InterRoundRestDefault ?? '30';

  const collection = useMemo(() => collections[collectionId], [collections, collectionId]);

  const createNewCircuit = useCallback(() => {
    const tempId = `TempTab${getTempId()}`;
    const newCollection = {
      AppId: appId,
      ...DEFAULT_COLLECTION,
      TabId: tempId,
      TemplateId: CIRCUIT_TEMPLATE_ID,
      Position: 0,
      Name: `New ${SOURCE_TYPE_CIRCUIT}`,
      Thumbnails: {},
      SourceType: SOURCE_TYPE_CIRCUIT,
      Items: [],
      Resources: [],
      IncludedInAppData: 0 as const,
    };
    setNewCollection(tempId, newCollection);
    setCollectionToSave(tempId, getCollectionValuesFromNewCollection(newCollection));

    setCollectionProperty(tempId, 'InterRoundRestDuration', interRoundRestDefault);
    setCollectionPropertyToSave(tempId, 'InterRoundRestDuration', interRoundRestDefault);

    DEFAULT_CIRCUIT_PROPERTIES.forEach(({ key, value }) => {
      setCollectionProperty(tempId, key, value);
      setCollectionPropertyToSave(tempId, key, value);
    });

    setCollectionItems(collection.TabId, [
      ...collection.Items,
      {
        ...DEFAULT_COLLECTION_ITEM,
        AppId: appId,
        TabId: collection.TabId,
        ChildId: tempId,
        Type: ITEM_TYPE_COLLECTION,
        SubType: ITEM_TYPE_COLLECTION,
        Position: collection.Items.length + 1,
        TabItemId: `TempTabItem${getTempId()}`,
      },
    ]);
    setCollectionItemsToSave(collection.TabId); // Set to be saved
  }, [
    collection,
    appId,
    interRoundRestDefault,
    getTempId,
    setNewCollection,
    setCollectionToSave,
    setCollectionProperty,
    setCollectionPropertyToSave,
    setCollectionItems,
    setCollectionItemsToSave,
  ]);

  return (
    <Dropdown
      trigger={
        <HeaderButton icon={<UISyncIcon />} medium tertiaryHighlight>
          Add Circuit
        </HeaderButton>
      }
      options={[
        { label: 'Create New Circuit', icon: <UISyncIcon />, onClick: createNewCircuit, keepMenuOpen: true },
        { label: 'Add Existing Circuit', icon: <PlusIcon />, onClick: () => setModalCollectionId(collectionId) },
      ]}
      menuMinWidth="198px"
    />
  );
};
