import { BuilderVideo } from 'providers';
import {
  BackIcon,
  Ellipsis,
  NavBarText,
  NavBarWrapper,
} from 'app/modules/build-dragdrop/Builder/mockup/components/NavBarViews/NavBarComponents';
import { getVideoName } from 'utils';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';

export const FileNavBar = ({ file }: { file: BuilderVideo }) => {
  const { goBack } = useMockupContext();
  const { getDesignProperty } = useAppTheme();
  const NavBarTitleText = getDesignProperty('navBarTitleText') as string;

  return (
    <NavBarWrapper>
      <BackIcon onClick={goBack} $color={NavBarTitleText} />
      <NavBarText $color={NavBarTitleText}>{getVideoName(file)}</NavBarText>
      <Ellipsis $color={NavBarTitleText} />
    </NavBarWrapper>
  );
};
