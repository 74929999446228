import styled from 'styled-components';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR } from 'theme';

const Picker = styled(generatePicker<Date>(dateFnsGenerateConfig))`
  #react-app && {
    margin: 18px 0 16px 0;
    & td,
    & th {
      border: none;
    }

    &.ant-picker {
      border-radius: 6px;
      border-color: ${NEUTRAL_5_COLOUR};

      .ant-picker-suffix {
        transition: 0.2s ease color;
      }
    }

    &.ant-picker:not(.ant-picker-disabled) {
      .ant-picker-suffix,
      input {
        color: ${NEUTRAL_10_COLOUR};
      }
    }

    & .ant-picker-panel-container {
      border-radius: 8px;
    }

    & .ant-picker-cell-today .ant-picker-cell-inner {
      border: 1px solid ${HIGHLIGHT_PRIMARY_COLOUR};
    }

    & .ant-picker-cell-selected .ant-picker-cell-inner,
    & .ant-picker-cell-selected .ant-picker-cell-inner:hover {
      background-color: ${HIGHLIGHT_PRIMARY_COLOUR} !important;
      color: #fff;
    }
  }
`;

export const DatePicker = ({ ...props }: PickerProps<Date>) => (
  <Picker getPopupContainer={(triggerNode) => triggerNode} {...props} />
);
