import { useCallback } from 'react';
import { Form } from 'antd';

import {
  PageContainer,
  SettingsGroup,
  SettingsFormItem,
  SettingsTextInput,
  SettingsTextArea,
  SettingsImageInput,
  CustomButton,
  SettingsSegmentDivider,
} from 'components';
import { SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';

import { useAppStore, PropertyType } from '../../hooks/useAppStore';
import { ScreenshotInput, ScreensContainer, AddScreenshotButton } from '../../components';

// Map of all properties being edited on this screen
const PROPERTIES: Record<string, PropertyType> = {
  RokuNavBarImage: { type: 'appProperty', isFile: true },
  RokuAppIconFHD: { type: 'appProperty', isFile: true },
  RokuAppLaunchImage: { type: 'appProperty', isFile: true },
  RokuName: { type: 'appProperty' },
  RokuDescription: { type: 'appProperty' },
  RokuWebDescription: { type: 'appProperty' },
};

interface Field {
  name: string;
  label: string;
  type: 'text' | 'textArea';
  maxLength?: number;
  tooltip?: string;
  rows?: number;
  width?: '100%';
}

interface ImageField {
  property: string;
  heading: string;
  tooltip: string;
  width: string;
  height: string;
  requiredWidth: number;
  requiredHeight: number;
}

const FIELDS: Field[] = [
  {
    name: 'RokuName',
    label: 'Channel Name',
    type: 'text',
    maxLength: 30,
    tooltip: 'The name of your Roku channel.',
  },
  {
    name: 'RokuDescription',
    label: 'App Store Description',
    type: 'textArea',
    maxLength: 300,
    tooltip: 'This description will appear in the Roku store description of your channel.',
    rows: 3,
    width: '100%',
  },
  {
    name: 'RokuWebDescription',
    label: 'Web Description',
    type: 'textArea',
    maxLength: 1500,
    tooltip:
      'This channel description may be used for U.S. web based marketing of your channel. It will not display on the device.',
    width: '100%',
  },
];

const IMAGE_FIELDS: ImageField[] = [
  {
    property: 'RokuNavBarImage',
    heading: 'TV Logo Image',
    tooltip: 'Logo image for your TV app.',
    width: '450px',
    height: '97px',
    requiredWidth: 1280,
    requiredHeight: 275,
  },
  {
    property: 'RokuAppIconFHD',
    heading: 'App Icon (FHD)',
    tooltip: 'Your full HD app icon.',
    width: '200px',
    height: '150px',
    requiredWidth: 540,
    requiredHeight: 405,
  },
  {
    property: 'RokuAppLaunchImage',
    heading: 'Launch Image',
    tooltip: 'Displayed while your app is loading.',
    width: '450px',
    height: '254px',
    requiredWidth: 3840,
    requiredHeight: 2160,
  },
];

export const AppStoreRokuSettings = () => {
  const {
    appProperties,
    form,
    unsavedChanges,
    isSaving,
    isLoading,
    isError,
    filenames,
    screenshots,
    maxScreenshots,
    addScreenshot,
    removeScreenshot,
    onValuesChange,
    onFilenameChange,
    onScreenshotChange,
    saveForm,
  } = useAppStore(PROPERTIES, 'Roku');

  const getImageFields = useCallback(
    (fieldArr: ImageField[]) => {
      return fieldArr.map(({ property, requiredWidth, requiredHeight, ...props }) => (
        <SettingsGroup>
          <SettingsImageInput
            key={property}
            requiredDimensions={{ requiredWidth: requiredWidth, requiredHeight: requiredHeight }}
            requirementsLabel={`PNG. ${requiredWidth} x ${requiredHeight}`}
            acceptedFileTypes="image/png"
            hideDelete
            downloadable
            filename={filenames[property]?.value}
            onFilenameChange={(_filename) => onFilenameChange(property, _filename)}
            {...props}
          />
        </SettingsGroup>
      ));
    },
    [filenames],
  );

  return (
    <PageContainer
      heading="Roku App Store Details"
      subheading="Update information presented in the Roku app store."
      headingButton={
        <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
          Save
        </CustomButton>
      }
      isLoading={isLoading}
      isError={isError}
    >
      <Form name="settings-form" onValuesChange={onValuesChange} onFinish={saveForm} autoComplete="off" form={form}>
        {getImageFields(IMAGE_FIELDS)}
        {appProperties &&
          FIELDS.map((field) => {
            return (
              <SettingsFormItem
                key={field.name}
                name={field.name}
                width={field.width || '311px'}
                $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}
              >
                {field.type === 'textArea' ? (
                  <SettingsTextArea
                    defaultValue={appProperties[field.name]}
                    label={field.label}
                    maxLength={field.maxLength}
                    tooltip={field.tooltip}
                    rows={field.rows ?? 6}
                  />
                ) : (
                  <SettingsTextInput
                    defaultValue={appProperties[field.name]}
                    label={field.label}
                    maxLength={field.maxLength}
                    tooltip={field.tooltip}
                  />
                )}
              </SettingsFormItem>
            );
          })}
        <SettingsSegmentDivider />
        <SettingsGroup title="Roku Screenshots">
          <ScreensContainer>
            {screenshots.roku?.map((screenshot, idx) => (
              <ScreenshotInput
                key={screenshot.id}
                heading={`Screenshot ${idx + 1}`}
                filename={screenshot.filename}
                id={screenshot.id}
                width="366px"
                height="206px"
                requiredDimensions={{ requiredWidth: 1920, requiredHeight: 1080 }}
                device="roku"
                handleRemove={() => removeScreenshot(screenshot.id, 'roku')}
                handleFilenameChange={onScreenshotChange}
              />
            ))}
          </ScreensContainer>
          <AddScreenshotButton
            text="Add Roku screenshot"
            disabled={screenshots.roku?.length >= maxScreenshots}
            device="roku"
            handleClick={addScreenshot}
          />
        </SettingsGroup>
      </Form>
    </PageContainer>
  );
};
