import styled from 'styled-components';
import { Form } from 'antd';

import {
  PageContainer,
  SettingsFormItem,
  SettingsTextInput,
  SettingsNumberInput,
  SettingsSelectInput,
  SettingsImageInput,
  SettingsFileInput,
  CustomButton,
  SettingsGroup,
  SettingsSegmentDivider,
} from 'components';

import { useCircuitConfiguration } from '../hooks/useCircuitConfiguration';
import {
  FIELD_WIDTH,
  PROPERTIES,
  Field,
  LABEL_FIELDS,
  FINISH_WORKOUT_FIELDS,
  REST_TIME_FIELDS,
  IMAGE_WIDTH,
} from './const';
import { SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';

const StyledSettingsNumberInput = styled(SettingsNumberInput)`
  // Turn Prefix into a suffix
  #react-app && .ant-input-number-prefix {
    position: absolute;
    top: 9px;
    left: 44px;
    z-index: 1;
  }
`;

const ImageWrapper = styled.div`
  width: ${IMAGE_WIDTH};
`;

const getFields = (fieldArr: Field[]) =>
  fieldArr.map(({ type, name, label, maxLength, placeholder, tooltip, width, max }) => {
    if (type === 'timeSeconds') {
      return (
        <SettingsFormItem key={name} name={name} width={FIELD_WIDTH} $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}>
          <StyledSettingsNumberInput
            label={label}
            autoComplete="off"
            placeholder={placeholder}
            tooltip={tooltip}
            min={0}
            max={max}
            prefix="sec"
          />
        </SettingsFormItem>
      );
    }
    return (
      <SettingsFormItem key={name} name={name} width={width || FIELD_WIDTH} $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}>
        <SettingsTextInput
          label={label}
          autoComplete="off"
          maxLength={maxLength}
          placeholder={placeholder}
          tooltip={tooltip}
        />
      </SettingsFormItem>
    );
  });

export const CircuitConfiguration = () => {
  const {
    form,
    unsavedChanges,
    isSaving,
    isLoading,
    isError,
    initialValues,
    filterOptions,
    filenames,
    appId,
    onValuesChange,
    onFilenameChange,
    saveForm,
  } = useCircuitConfiguration(PROPERTIES);

  const { CircuitReadyAndFinishImage, CircuitReadyAudio, CircuitGoAudio } = filenames;

  return (
    <PageContainer
      heading="Circuit Workout Configuration"
      subheading="Modifying a property on this page will affect the same property throughout all your workouts unless stated otherwise."
      isLoading={isLoading}
      isError={isError}
      headingButton={
        <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
          Save
        </CustomButton>
      }
    >
      <Form
        name="settings-form"
        onValuesChange={onValuesChange}
        onFinish={saveForm}
        autoComplete="off"
        form={form}
        initialValues={initialValues}
      >
        {filterOptions && filterOptions.length > 2 && (
          <>
            <SettingsGroup
              title="Workout Overview Categories"
              description="These are the categories that will appear at the top of your workout overview screen"
              wrapRow
              $marginBottom="0"
            >
              <SettingsFormItem
                name="FilterCategoryOne"
                width={FIELD_WIDTH}
                $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}
              >
                <SettingsSelectInput
                  disabled
                  label="Category 1"
                  options={[{ name: 'Avg. Time', value: 'Avg. Time' }]}
                  defaultVal={'Avg. Time'}
                />
              </SettingsFormItem>
              <SettingsFormItem
                name="FilterCategoryTwo"
                width={FIELD_WIDTH}
                $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}
              >
                <SettingsSelectInput label="Category 2" options={filterOptions} />
              </SettingsFormItem>
              <SettingsFormItem
                name="FilterCategoryThree"
                width={FIELD_WIDTH}
                $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}
              >
                <SettingsSelectInput label="Category 3" options={filterOptions} />
              </SettingsFormItem>
              <SettingsFormItem
                name="FilterCategoryFour"
                width={FIELD_WIDTH}
                $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}
              >
                <SettingsSelectInput label="Category 4" options={filterOptions} />
              </SettingsFormItem>
            </SettingsGroup>
            <SettingsSegmentDivider />
          </>
        )}
        <SettingsGroup title="Labels" wrapRow $marginBottom="0">
          {getFields(LABEL_FIELDS)}
        </SettingsGroup>
        <SettingsSegmentDivider />
        <SettingsGroup title="Workout Imagery">
          <ImageWrapper>
            <SettingsImageInput
              heading="'Get ready' & ‘Finish Workout' Image"
              subheading="JPG/PNG. 2200px by 2200px"
              tooltip="Each workout is bookended by the 'Get Ready' screen (portrait) and the Workout Complete screen (landscape). We use the same image for both of these so try to use an image that's primary content is in the center so no awkward cropping occurs."
              hideUpload
              hideDelete
              downloadable
              filePath={`${appId}/workouts/images/`}
              width={IMAGE_WIDTH}
              height={IMAGE_WIDTH}
              requiredDimensions={{
                requiredWidth: 2200,
                requiredHeight: 2200,
              }}
              verticalGuide={`${parseInt(IMAGE_WIDTH) * 0.45}px`}
              horizontalGuide={`${parseInt(IMAGE_WIDTH) * 0.45}px`}
              acceptedFileTypes="image/png, image/jpeg"
              filename={CircuitReadyAndFinishImage?.value}
              onFilenameChange={(filename) => onFilenameChange('CircuitReadyAndFinishImage', filename)}
              scaleSpinner={20}
            />
          </ImageWrapper>
        </SettingsGroup>
        <SettingsSegmentDivider />
        <SettingsGroup title="Finish Workout" $marginBottom="0">
          {getFields(FINISH_WORKOUT_FIELDS)}
        </SettingsGroup>
        <SettingsSegmentDivider />
        <SettingsGroup
          title="Audio Cues"
          description="These will be played when queueing a user into an exercise and the audio length should be 1 second max"
        >
          <SettingsFileInput
            heading="'Ready' Audio File (.mp3)"
            filename={CircuitReadyAudio?.value}
            useOriginalFilename
            filePath={`${appId}/workouts/audio/`}
            acceptedFileTypes="audio/mpeg3"
            onFilenameChange={(filename) => onFilenameChange('CircuitReadyAudio', filename)}
          />
          <SettingsFileInput
            heading="'Go' Audio File (.mp3)"
            filename={CircuitGoAudio?.value}
            useOriginalFilename
            filePath={`${appId}/workouts/audio/`}
            acceptedFileTypes="audio/mpeg3"
            onFilenameChange={(filename) => onFilenameChange('CircuitGoAudio', filename)}
          />
        </SettingsGroup>
        <SettingsSegmentDivider />
        <SettingsGroup
          title="Default Rest Times"
          description="Updating these will not edit any of your previously made workouts. It will only change the defaults on workouts you make going forward"
          wrapRow
          $marginBottom="0"
        >
          {getFields(REST_TIME_FIELDS)}
        </SettingsGroup>
      </Form>
    </PageContainer>
  );
};
