import { useHistory } from 'react-router-dom';
import { getNextPath } from 'app/modules/self-onboarding/OnboardingWizard/const';
import { useDataSource } from 'providers';

export const useWizardNavigation = () => {
  const history = useHistory();
  const dataSource = useDataSource();

  const navigateForward = () => {
    const nextPath = getNextPath(dataSource, history.location.pathname);
    if (nextPath) {
      history.push(nextPath);
    }
  };
  const navigateBackward = () => {
    history.goBack();
  };

  return { navigateForward, navigateBackward };
};
