import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { Segment, SettingsTextArea } from 'components';

const StyledTextArea = styled(SettingsTextArea)`
  &&&& {
    min-height: 100px;
    padding-top: 8px;
  }
`;

interface WorkoutDescriptionProps {
  isDrawer?: boolean;
}

export const WorkoutDescription = ({
  TabId,
  Description,
  isDrawer,
}: Pick<BuilderCollection, 'TabId' | 'Description'> & WorkoutDescriptionProps) => {
  const { setCollectionValue } = useContent();
  const { setCollectionValueToSave } = useSaveContext();

  const handleDescChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;
    setCollectionValue(TabId, 'Description', description);
    setCollectionValueToSave(TabId, 'Description', description);
  };

  return (
    <Segment header="Description" $marginTop={isDrawer ? undefined : '40px'}>
      <StyledTextArea
        value={Description}
        onChange={handleDescChange}
        rows={isDrawer ? 8 : 5}
        placeholder="Enter your description"
      />
    </Segment>
  );
};
