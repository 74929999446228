import styled from 'styled-components';
import { ChevronDownIcon, ChevronUpIcon } from 'icons';

const Spacer = styled.span`
  font-size: 18px;
  width: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface SortIconProps {
  active: boolean;
  order?: 'ascend' | 'descend';
}

export const ColumnSortIcon = ({ active, order }: SortIconProps) => {
  const sortIcon = order === 'descend' ? <ChevronDownIcon /> : <ChevronUpIcon />;
  return <Spacer>{active && sortIcon}</Spacer>;
};
