import { ReactNode } from 'react';
import styled from 'styled-components';

import { FONT_10PX_MEDIUM, FONT_10PX_REGULAR, OVERFLOW_ELLIPSIS } from 'font';
import { DANGER_COLOUR, NEUTRAL_10_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { XIcon } from 'icons';
import { SettingsTextInput } from 'components';
import { getResourceUrl, usePointerOnClick } from 'utils';
import { useAppBeingEdited } from 'app-context';

const TopRow = styled.div`
  display: flex;
  align-items: center;
`;
const BottomRow = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
  margin-top: 6px;
  padding: 0 30px 0 8px;
`;

const Label = styled.div`
  ${FONT_10PX_MEDIUM};
  color: ${NEUTRAL_7_COLOUR};
`;
const Error = styled(Label)`
  ${FONT_10PX_REGULAR};
  color: ${DANGER_COLOUR};
`;
const TypeLabel = styled(Label)`
  text-transform: uppercase;
  margin-right: 12px;
`;
const DeleteIcon = styled(XIcon)`
  #react-app && {
    color: ${NEUTRAL_10_COLOUR};
    font-size: 16px;
    margin-left: 8px;
    cursor: pointer;
  }
`;
const Wrapper = styled.div<{ $marginBottom?: string }>`
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '16px'};
`;
const FilenameLabel = styled(Label)`
  ${OVERFLOW_ELLIPSIS};
  cursor: ${usePointerOnClick};
`;

interface FileProps {
  id: string | number;
  title?: string;
  type?: string;
  url?: string;
  onTitleChange?: (title: string, id: FileProps['id']) => void;
  onDelete?: (id: FileProps['id']) => void;
  icon: ReactNode;
  isError?: boolean; // Errored during uploading state
  marginBottom?: string;
}

export const FileBlock = ({
  id,
  title,
  type,
  url,
  onTitleChange,
  onDelete,
  icon,
  isError,
  marginBottom,
}: FileProps) => {
  const appId = useAppBeingEdited();
  return (
    <Wrapper key={id} $marginBottom={marginBottom}>
      <TopRow>
        <SettingsTextInput
          size="small"
          value={title}
          onChange={(e) => (onTitleChange ? onTitleChange(e.target.value, id) : undefined)}
          disabled={!onTitleChange}
        />
        {onDelete && <DeleteIcon onClick={() => (onDelete ? onDelete(id) : undefined)} />}
      </TopRow>
      <BottomRow>
        {isError ? (
          <Error>Failed to upload. Please remove and try again.</Error>
        ) : (
          <>
            {icon}
            {type && <TypeLabel>{type}</TypeLabel>}
            <FilenameLabel
              onClick={
                url
                  ? () => {
                      const openUrl = getResourceUrl(appId, { Url: url });
                      window.open(openUrl, '_blank');
                    }
                  : undefined
              }
            >
              {url}
            </FilenameLabel>
          </>
        )}
      </BottomRow>
    </Wrapper>
  );
};
