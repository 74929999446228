import styled from 'styled-components';
import { Tooltip as AntdTooltip } from 'antd';
import { NEUTRAL_7_COLOUR, HIGHLIGHT_PRIMARY_COLOUR } from 'theme';
import { TooltipPropsWithTitle } from 'antd/es/tooltip';
import { ReactNode } from 'react';
import { InfoCircleIcon } from 'icons';

interface TooltipIconProps {
  $fontSize?: string;
  $marginLeft?: string;
}

const TooltipIcon = styled(InfoCircleIcon)<TooltipIconProps>`
  #react-app && {
    font-size: ${({ $fontSize }) => $fontSize || '16px'};
    color: ${NEUTRAL_7_COLOUR};
    margin-left: ${({ $marginLeft }) => $marginLeft || 'auto'};
    transition: 0.3s ease color;

    :hover {
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
      transition: 0.5s ease color;
    }
  }
`;

interface TooltipProps extends TooltipPropsWithTitle {
  $fontSize?: string;
  $marginLeft?: string;
  icon?: ReactNode;
}

export const Tooltip = ({ $fontSize, $marginLeft, icon, ...props }: TooltipProps) => (
  <AntdTooltip
    getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
    arrowPointAtCenter
    {...props}
  >
    {icon ? icon : <TooltipIcon $fontSize={$fontSize} $marginLeft={$marginLeft} />}
  </AntdTooltip>
);
