import { Typography } from 'antd';
import styled from 'styled-components';

import { APP_FONT_20PX_MEDIUM } from 'mockup-font';

import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { BuilderVideo } from 'app/modules/build-dragdrop/Builder/providers';

interface ItemProps {
  $color: string;
}

const Item = styled.div<ItemProps>`
  width: 100%;
  padding-top: 10px;
  color: ${({ $color }) => $color};
`;

const Description = styled(Typography.Paragraph)<{ $marginBottom?: string }>`
  &&&& {
    width: 100%;
    height: 28px;
    ${APP_FONT_20PX_MEDIUM};
    color: inherit;
    margin-bottom: ${({ $marginBottom }) => $marginBottom || '15px'};
  }
`;

interface SectionHeaderItemProps {
  content: BuilderVideo;
  $marginBottom?: string;
}

export const SectionHeaderItem = ({ content, $marginBottom }: SectionHeaderItemProps) => {
  const { getDesignProperty } = useAppTheme();

  const $color = getDesignProperty('primaryItemHEX') as string;

  return (
    <Item $color={$color}>
      {content.Description && (
        <Description ellipsis $marginBottom={$marginBottom}>
          {content.Description}
        </Description>
      )}
    </Item>
  );
};
