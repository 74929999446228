import { useCallback, useState } from 'react';
import { Form, FormInstance, Input } from 'antd';
import styled, { css } from 'styled-components';
import { login, useAxiosInstance } from 'api';

import { GradientButton } from '../../GradientButton/GradientButton';

const LoginFormWrapper = styled.div`
  #login-panel && {
    margin-top: 48px;
    margin-bottom: 48px;
  }
`;

const inputStyles = css`
  width: 100%;
  height: 64px;

  #login-panel && {
    border-radius: 8px;
    font-size: 20px;
    line-height: 24px;
  }

  :focus-within,
  :hover {
    border-color: black !important;
    box-shadow: none !important;
  }
`;
const EmailInput = styled(Input)`
  ${inputStyles}
  #login-panel && {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const PasswordInput = styled(Input.Password)`
  ${inputStyles}
  #login-panel && {
    padding: 0px;
    padding-right: 25px;
  }

  #login-password.ant-input {
    font-size: 20px;
    line-height: 24px;
    padding-left: 20px !important;
    padding-right: 10px;

    // Shapes the field when highlighted
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
`;
const FormItem = styled(Form.Item)`
  #login-panel && {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 24px;
  }

  .ant-form-item-explain-error {
    color: red !important;
    font-weight: 500 !important;
  }

  .ant-form-item-explain-connected {
    opacity: 1 !important;
    height: unset !important;
  }
`;

const LoginButton = styled(GradientButton)`
  .ant-btn-loading-icon {
    margin-left: 16px !important;
  }

  #login-panel && {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export interface LoginFormProps {
  form: FormInstance;
}

export interface LoginFormValues {
  email: string;
  password: string;
}

export const LoginForm = ({ form }: LoginFormProps) => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const axiosInstance = useAxiosInstance();

  // Enable login button when fields are filled
  const onValuesChange = useCallback(
    (_: unknown, values: LoginFormValues) => {
      if (values.email && values.password) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    [setDisabled],
  );

  const onFinish = useCallback(
    async (values: LoginFormValues) => {
      const { email, password } = values;
      setLoading(true);
      login(axiosInstance, email, password)
        .catch((err) => {
          console.warn('Failed to login', err.response.data);
          form.setFields([
            {
              name: 'password',
              errors: [`Failed to login: ${err.response.data?.detail || 'Internal server error'}`],
            },
          ]);
        })
        .then(() => {
          setLoading(false);
        });
    },
    [axiosInstance, form, setLoading],
  );

  return (
    <Form name="login-form" onFinish={onFinish} onValuesChange={onValuesChange} autoComplete="off" form={form}>
      <LoginFormWrapper>
        <FormItem name="email">
          <EmailInput type="email" id="login-email" placeholder="Email" />
        </FormItem>
        <FormItem name="password">
          <PasswordInput type="password" id="login-password" placeholder="Password" />
        </FormItem>
      </LoginFormWrapper>
      <LoginButton
        htmlType="submit"
        disabled={disabled}
        loading={loading}
        $height="68px"
        $width="100%"
        $fontSize="24px"
      >
        Log In
      </LoginButton>
    </Form>
  );
};
