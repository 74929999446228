import { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { CustomButton, FileUploader, Segment, SegmentTitle, Tooltip } from 'components';
import { FileBlankIcon, InfoCircleIcon, PlusIcon, UploadIcon } from 'icons';
import { ITEM_TYPE_COLLECTION, ITEM_TYPE_VIDEO, Resource } from 'api';
import {
  DANGER_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_8_COLOUR,
} from 'theme';
import { FONT_10PX_MEDIUM, FONT_10PX_REGULAR, FONT_14PX_REGULAR } from 'font';
import { useSaveContext } from 'providers';
import { DEFAULT_MAX_FILE_SIZE } from 'utils';

import { ViewSectionHeading } from 'app/modules/build-dragdrop/Builder/customise-items/components';
import { getFileTitle, getFileType } from 'app/modules/build-dragdrop/Builder/util';
import { FileBlock as ViewFile } from 'app/modules/build-dragdrop/Builder/drawer/components';

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInfoCircleIcon = styled(InfoCircleIcon)<{ $marginBottom?: string }>`
  #react-app && {
    font-size: 16px;
    color: ${NEUTRAL_10_COLOUR};
    margin-left: 4px;
    margin-bottom: ${({ $marginBottom }) => $marginBottom || 0};
  }
`;

const Label = styled.div`
  ${FONT_10PX_MEDIUM};
  color: ${NEUTRAL_7_COLOUR};
`;

const Error = styled(Label)`
  ${FONT_10PX_REGULAR};
  color: ${DANGER_COLOUR};
`;

const SizeError = styled(Error)`
  line-height: 18px;
  margin-top: 6px;
  text-align: center;
`;

const ICON_STYLE = css`
  color: ${NEUTRAL_7_COLOUR};
  font-size: 12px;
  margin-right: 2px;
`;

const StyledFileBlankIcon = styled(FileBlankIcon)`
  #react-app && {
    ${ICON_STYLE};
  }
`;

const StyledUploadIcon = styled(UploadIcon)`
  #react-app && {
    ${ICON_STYLE};
  }
`;

const UploadButton = styled(CustomButton)`
  #react-app && {
    width: 100%;
  }
`;
const HiddenFileUploader = styled(FileUploader)`
  display: none;
`;

const Empty = styled.div`
  width: 100%;
  background-color: ${NEUTRAL_3_COLOUR};
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
  text-align: center;
  padding: 20px;
`;

interface PendingResource {
  filename: string;
  type: string;
  title: string;
  isError?: boolean;
}

interface ContentResourcesProps {
  existingResources: Resource[];
  onAddResource: (resource: Resource) => void;
  onRemoveResource: (resourceId: string | number) => void;
  onTitleChange: (title: string, resourceId: string | number) => void;
  parentType: typeof ITEM_TYPE_VIDEO | typeof ITEM_TYPE_COLLECTION;
  parentId?: string;
  isInDrawer?: boolean;
  isInCMS?: boolean;
  onFileSelected?: () => void;
}

export const ContentResources = ({
  existingResources,
  onAddResource,
  onRemoveResource,
  onTitleChange,
  parentType,
  parentId,
  isInDrawer,
  isInCMS,
  onFileSelected,
}: ContentResourcesProps) => {
  const [pendingResource, setPendingResource] = useState<PendingResource | undefined>(undefined);
  const [isFileSizeError, setIsFileSizeError] = useState(false);
  const overrideRef = useRef<HTMLInputElement>(null);
  const { getTempId } = useSaveContext();

  const handleUploadClick = useCallback(() => {
    overrideRef.current?.click(); //Forward click to the file input
  }, [overrideRef]);

  const handleFileSelected = useCallback(
    (file: File) => {
      setIsFileSizeError(false);
      onFileSelected && onFileSelected();
      // When a file is selected in the file browser, add it as a pendingResource so it will display in the list with an uploading state
      setPendingResource({
        filename: file.name,
        type: getFileType(file.name),
        title: getFileTitle(file.name),
      });
    },
    [setIsFileSizeError, setPendingResource, onFileSelected],
  );

  const handleFileUploaded = useCallback(
    (filename: string) => {
      // When a file has finished uploading, call the onAddResource function which will add it to the existingResources prop
      onAddResource({
        OriginalUrl: null,
        Title: getFileTitle(filename),
        ParentId: parentId ?? '', // If parent is a new post that hasn't been saved yet, ParentId will be added by BE during save
        Type: getFileType(filename),
        Url: filename,
        ParentType: parentType,
        ResourceId: `TempResourceId${getTempId()}`,
      });
      // Remove pending resource
      setPendingResource(undefined);
    },
    [pendingResource, onAddResource, setPendingResource, getTempId],
  );

  const handleError = useCallback(() => {
    setPendingResource((existing) => (existing ? { ...existing, isError: true } : undefined));
  }, [setPendingResource]);

  return (
    <Segment $marginBottom={isInCMS ? '40px' : undefined}>
      {isInDrawer || isInCMS ? (
        <HeaderRow>
          <Title>
            <SegmentTitle title="Resources" />
            <Tooltip
              title={'Accepted file types: PDF, PNG, JPEG, JPG'}
              icon={<StyledInfoCircleIcon $marginBottom="8px" />}
              placement="top"
            />
          </Title>
          {isInCMS && (
            <CustomButton
              medium
              tertiaryHighlight
              icon={<PlusIcon />}
              onClick={handleUploadClick}
              disabled={!!pendingResource}
            >
              Add
            </CustomButton>
          )}
        </HeaderRow>
      ) : (
        <ViewSectionHeading>
          Resources
          <Tooltip title={'Accepted file types: PDF, PNG, JPEG, JPG'} icon={<StyledInfoCircleIcon />} placement="top" />
        </ViewSectionHeading>
      )}
      <>
        {existingResources.map(({ Url, Title, Type, ResourceId }) => (
          <ViewFile
            id={ResourceId}
            title={Title}
            onDelete={onRemoveResource}
            onTitleChange={onTitleChange}
            key={ResourceId}
            type={Type}
            url={Url}
            icon={<StyledFileBlankIcon />}
          />
        ))}
        {pendingResource && (
          <ViewFile
            id="pending"
            title={pendingResource.title}
            onDelete={() => setPendingResource(undefined)}
            url="Uploading ..."
            icon={<StyledUploadIcon />}
          />
        )}
      </>
      {isInCMS && existingResources.length === 0 && !pendingResource && <Empty>No resources</Empty>}
      {!isInCMS && (
        <UploadButton secondary small icon={<UploadIcon />} onClick={handleUploadClick} disabled={!!pendingResource}>
          Upload a file
        </UploadButton>
      )}
      {isFileSizeError && (
        <SizeError>{`The file you are trying to upload exceeds our ${DEFAULT_MAX_FILE_SIZE}MB limit.`}</SizeError>
      )}
      <HiddenFileUploader
        overrideRef={overrideRef}
        onFileSelected={handleFileSelected}
        onFilenameChange={handleFileUploaded}
        useOriginalFilename
        acceptedFileTypes="application/pdf, image/png, image/jpeg"
        handleError={handleError}
        handleMaxSizeExceeded={() => setIsFileSizeError(true)}
      />
    </Segment>
  );
};
