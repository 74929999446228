import styled from 'styled-components';
import { ReactNode } from 'react';

const Footer = styled.div`
  padding: 13px 28px 28px 28px;
  flex-grow: 0;
  min-height: 82px;
`;

interface DrawerFooterProps {
  children?: ReactNode | ReactNode[];
}

export const DrawerFooter = ({ children }: DrawerFooterProps) => {
  return <Footer>{children}</Footer>;
};
