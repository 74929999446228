import { ReactNode } from 'react';
import { Header, WizardIcon } from '../index';
import styled from 'styled-components';
import { FONT_20PX_NORMAL } from '../../font';

interface StepPanelProps {
  header: string | ReactNode;
  body?: string | ReactNode;
  children?: JSX.Element | string;
  icon: JSX.Element;
}

const LeftBody = styled.div`
  margin-top: 38px;
  padding-right: 10px;
  ${FONT_20PX_NORMAL};
`;

export const StepPanel = ({ children, header, body, icon }: StepPanelProps) => {
  return (
    <>
      <WizardIcon icon={icon} />
      <Header>{header}</Header>
      {body && <LeftBody>{body}</LeftBody>}
      {children}
    </>
  );
};
