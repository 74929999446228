import styled from 'styled-components';

import { SettingsFormItem, SettingsImageInput, SettingsTextInput, Segment, SegmentTitle } from 'components';

import { DrawerSeparator } from '../TabDrawers/components/DrawerSeparator';
import { TAB_TITLE_MAX, TAB_ICON_WIDTH, TAB_ICON_HEIGHT } from '../const';

const Spacer = styled.div`
  height: 12px;
`;

const LargeSpacer = styled.div`
  height: 32px;
`;

const RequirementsLabel = styled.div`
  width: 230px;
`;

interface TabBarProps {
  placeholder: string;
  filename: string | undefined;
  onFilenameChange: (filename: string) => void;
}

export const TabBar = ({ placeholder, ...props }: TabBarProps) => (
  <>
    <DrawerSeparator />
    <Segment header="Tab Bar">
      <Spacer />
      <SegmentTitle title="Icon" />
      <SettingsImageInput
        requirementsLabel={
          <RequirementsLabel>
            {`File must be black PNG with transparent background. ${TAB_ICON_WIDTH}px by ${TAB_ICON_HEIGHT}px.`}
          </RequirementsLabel>
        }
        filePath="images/icons/"
        width="50px"
        height="50px"
        scale={80}
        requiredDimensions={{ requiredWidth: TAB_ICON_WIDTH, requiredHeight: TAB_ICON_HEIGHT }}
        acceptedFileTypes="image/png"
        hideDelete
        {...props}
      />
      <LargeSpacer />
      <SegmentTitle title="Title" />
      <SettingsFormItem name="Name">
        <SettingsTextInput size="middle" maxLength={TAB_TITLE_MAX} placeholder={placeholder.toUpperCase()} />
      </SettingsFormItem>
    </Segment>
  </>
);
