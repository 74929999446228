import styled, { css } from 'styled-components';
import { Button, ButtonProps } from 'antd';
import { NEUTRAL_6_COLOUR, VIDAPP_GRADIENT } from 'theme';

interface GradientButtonProps extends ButtonProps {
  $height?: string;
  $width?: string;
  $fontSize?: string;
}

const UnstyledButton = (props: GradientButtonProps) => {
  return (
    <Button type="primary" shape="round" {...props}>
      {props?.children}
    </Button>
  );
};

export const LoadingStyles = css`
  #login-panel &&& {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: row-reverse !important;
  }
`;

export const GradientButton = styled(UnstyledButton)`
  width: ${(props) => props.$width || '100%'};

  ${LoadingStyles}
  #login-panel &&& {
    height: ${(props) => props.$height || '68px'};

    background: ${(props) => (!props.disabled ? VIDAPP_GRADIENT : NEUTRAL_6_COLOUR)};
    border: none;

    font-size: ${(props) => props.$fontSize || '24px'};
    font-weight: 500;
    color: white;
  }
`;
