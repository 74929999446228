import { AxiosInstance } from 'axios';
import { builderAPI } from 'api/axios';

interface StringAppProperties {
  [key: string]: string;

  DisableUscreenSync: string;
  CourseDetailsDesign: string;
  TabBarTintHEX: string;
  TabIconNotSelectedHEX: string;
  TabBarBackgroundHEX: string;
  NavBarTitleHEX: string;
  NavBarBackgroundHEX: string;
  HideTimestampOnVideoThumbnails: string;
  HideItemCountOnCollectionThumbnails: string;
}

interface SpecialAppProperties {
  OnboardingScreens: OnboardingScreen[];
}

export interface OnboardingScreen {
  ImageName: string;
  Position: number;
  Type: string;
}

export const COURSE_DD_THUMBNAILS = 'Thumbnails';
export const COURSE_DD_NO_THUMBNAILS = 'NoThumbnails';

// Default values that are used in placeholders/in-app when properties are undefined
export const APP_PROPERTY_DEFAULTS = {
  CustomFavoritesLabel: 'Favorites',
  CustomWatchlistLabel: 'Watch Later',
  CustomDownloadsLabel: 'Downloads',
  CustomAudioDownloadsLabel: 'Audio Downloads',
  CustomRecentlyWatchedLabel: 'Recently Watched',
  CustomMyPlaylistsLabel: 'My Playlists',
};

export type AppPropertyDefaultKey = keyof typeof APP_PROPERTY_DEFAULTS;

export type AppProperties = StringAppProperties & SpecialAppProperties;

export interface AppPropertyToSave {
  Name: string;
  Value: string | Record<string, string | number>[] | OnboardingScreen[];
}

export const getAppProperties = (client: AxiosInstance, appId: string) => {
  return client
    .get(`${builderAPI.contentApiURL}/data/${appId}/builder/properties?update_cache=false&no_emoji=false`, {
      headers: {
        accept: 'application/json',
      },
    })
    .then(({ data }) => data);
};

export const saveAppProperties = async (
  client: AxiosInstance,
  appId: string,
  appPropertiesToSave: AppPropertyToSave[],
) => {
  return client.put(`/content/property/${appId}`, JSON.stringify(appPropertiesToSave));
};
