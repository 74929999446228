import { useCallback } from 'react';
import styled from 'styled-components';

import { SettingsNumberInput } from 'components';
import { FONT_14PX_REGULAR } from 'font';
import { useContent, useSaveContext } from 'providers';
import { useAppProperties } from 'hooks';
import { NEUTRAL_7_COLOUR } from 'theme';

const StyledNumberInput = styled(SettingsNumberInput)`
  &&&& {
    width: 100%;
    height: 36px;
    margin: 0;
    padding: 0;
    ${FONT_14PX_REGULAR};

    .ant-input-number {
      margin: 0px 11px;
      height: 100%;
    }
  }

  // Turn Prefix into a suffix
  &&&& .ant-input-number-prefix {
    position: absolute;
    top: 7px;
    left: 38px;
    z-index: 1;
    color: ${NEUTRAL_7_COLOUR};
  }
`;

interface InterRoundRestInputProps {
  tabId: string | number;
}

export const InterRoundRestInput = ({ tabId }: InterRoundRestInputProps) => {
  const { collections, setCollectionProperty } = useContent();
  const { setCollectionPropertyToSave } = useSaveContext();
  const { data: appProperties } = useAppProperties();

  const defaultDuration = appProperties?.InterRoundRestDefault ?? '30';

  const circuit = collections[tabId];

  const changeDuration = useCallback(
    (value: number) => {
      if (!!value || value === 0) {
        setCollectionProperty(tabId, 'InterRoundRestDuration', value.toString());
        setCollectionPropertyToSave(tabId, 'InterRoundRestDuration', value.toString());
      }
    },
    [setCollectionProperty, setCollectionPropertyToSave],
  );

  return (
    <StyledNumberInput
      autoComplete="off"
      value={circuit.Properties?.InterRoundRestDuration}
      placeholder={defaultDuration}
      onChange={(value) => changeDuration(value as number)}
      onFocus={(event) => event.target.select()}
      min={0}
      max={999}
      maxLength={3}
      prefix="sec"
    />
  );
};
