import { useState, useCallback, useEffect } from 'react';
import { Form } from 'antd';
import { customAlphabet } from 'nanoid';

import { InfoModal } from 'components';
import { useUnsavedChanges } from 'providers';

import { AppPropertyToSave, AppBasicInfoToSave, AppBasicInfoKey } from 'api';
import {
  useAppBasicInfo,
  useAppProperties,
  useAppleCategories,
  useSaveAppBasicInfo,
  useSaveAppProperties,
} from 'hooks';

const MAX_SCREENSHOTS = {
  IOS: 10,
  Android: 8,
  AppleTV: 10,
  Roku: 6,
};

const nanoid = customAlphabet('123456789', 16);

export interface PropertyType {
  type: 'appProperty' | 'appBasicInfo';
  isFile?: boolean;
}

export interface Filename {
  value: string | undefined;
  touched: boolean;
}

interface Screenshot {
  filename: string;
  id: number;
}

type Platform = 'IOS' | 'Android' | 'AppleTV' | 'Roku';
export type Device =
  | 'iPhone8'
  | 'iPhoneX'
  | 'iPad'
  | 'androidPhone'
  | 'androidTablet'
  | 'androidTV'
  | 'appleTV'
  | 'roku';

export const useAppStore = (PROPERTIES: Record<string, PropertyType>, platform: Platform) => {
  const { data: appBasicInfo, isError: appBasicInfoIsError } = useAppBasicInfo(Infinity, 'always');
  const { data: appProperties, isError: appPropertiesIsError } = useAppProperties({
    staleTime: Infinity,
    refetchOnMount: 'always',
  });
  const { data: appleCategories, isError: appleCategoriesIsError } = useAppleCategories();
  const saveAppBasicInfo = useSaveAppBasicInfo();
  const saveAppProperties = useSaveAppProperties();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const [filenames, setFilenames] = useState<Record<string, Filename | undefined>>({});
  const [screenshots, setScreenshots] = useState<Record<string, Screenshot[]>>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();

  const addScreenshot = useCallback(
    (device: Device) => {
      setScreenshots((oldState) => ({
        ...oldState,
        [device]: [...oldState[device], { filename: '', id: parseInt(nanoid()) }],
      }));
      setUnsavedChanges(true);
    },
    [setScreenshots, setUnsavedChanges],
  );

  const removeScreenshot = useCallback(
    (id: number, device: Device) => {
      setScreenshots((oldState) => ({
        ...oldState,
        [device]: [...oldState[device].filter((screenshot) => screenshot.id !== id)],
      }));
      setUnsavedChanges(true);
    },
    [setScreenshots, setUnsavedChanges],
  );

  const initiateScreenshots = useCallback(
    (platform: Platform, device: Device, propertyPrefix: string) => {
      const screenshots: Screenshot[] = [];

      if (appProperties) {
        for (let i = 1; i <= MAX_SCREENSHOTS[platform]; i++) {
          const property = `${propertyPrefix}${i}`;
          if (appProperties[property]?.length > 0) {
            screenshots.push({
              filename: appProperties[property],
              id: parseInt(nanoid()),
            });
          }
        }
      }

      setScreenshots((oldState) => ({
        ...oldState,
        [device]: screenshots,
      }));
    },
    [appProperties, setScreenshots],
  );

  useEffect(() => {
    if (appBasicInfo && appProperties) {
      const files: Record<string, Filename | undefined> = {};

      for (const [key, value] of Object.entries(PROPERTIES)) {
        if (value.isFile) {
          if (value.type === 'appBasicInfo') {
            files[key] = {
              value: (appBasicInfo[key as AppBasicInfoKey] as string) ?? '',
              touched: false,
            };
          } else {
            files[key] = { value: appProperties[key], touched: false };
          }
        }
      }

      setFilenames((oldState) => ({
        ...oldState,
        ...files,
      }));

      if (platform === 'IOS') {
        initiateScreenshots(platform, 'iPhone8', 'AppStoreIPhoneScreenshot');
        initiateScreenshots(platform, 'iPhoneX', 'AppStoreIPhonexsmaxScreenshot');
        initiateScreenshots(platform, 'iPad', 'AppStoreIPadScreenshot');
      } else if (platform === 'Android') {
        initiateScreenshots(platform, 'androidPhone', 'AppStoreAndroidPhoneScreenshot');
        initiateScreenshots(platform, 'androidTablet', 'AppStoreAndroid10InchTabletScreenshot');
        initiateScreenshots(platform, 'androidTV', 'AppStoreAndroidTVScreenshot');
      } else if (platform === 'AppleTV') {
        initiateScreenshots(platform, 'appleTV', 'AppStoreAppleTVScreenshot');
      } else if (platform === 'Roku') {
        initiateScreenshots(platform, 'roku', 'AppStoreRokuScreenshot');
      }
    }
  }, []);

  const onValuesChange = useCallback(() => {
    if (!unsavedChanges) {
      setUnsavedChanges(true);
    }
  }, [unsavedChanges, setUnsavedChanges]);

  const onFilenameChange = useCallback(
    (property: string, _filename) => {
      setFilenames((oldState) => ({ ...oldState, [property]: { value: _filename, touched: true } }));
      setUnsavedChanges(true);
    },
    [setFilenames, setUnsavedChanges],
  );

  const onScreenshotChange = useCallback(
    (id: number, filename: string, device: Device) => {
      setScreenshots((oldState) => ({
        ...oldState,
        [device]: [
          ...oldState[device].map((screenshot) => {
            if (screenshot.id === id) {
              return { ...screenshot, filename: filename };
            }
            return screenshot;
          }),
        ],
      }));
      setUnsavedChanges(true);
    },
    [setScreenshots, setUnsavedChanges],
  );

  const getScreenshotsToSave = useCallback(
    (platform: Platform, device: Device, propertyPrefix: string) => {
      const screenshotsToSave: AppPropertyToSave[] = [];
      if (appProperties) {
        for (let i = 0; i < MAX_SCREENSHOTS[platform]; i++) {
          const property = `${propertyPrefix}${i + 1}`;
          if (screenshots[device][i] || appProperties[property]) {
            screenshotsToSave.push({
              Name: property,
              Value: screenshots[device][i]?.filename ?? '',
            });
          }
        }
      }
      return screenshotsToSave;
    },
    [screenshots, appProperties],
  );

  const saveFailed = useCallback(
    (error) => {
      console.error(error);
      InfoModal('Unable To Save', 'An error occurred while saving. Please try again.', 'error');
      setUnsavedChanges(true);
      setIsSaving(false);
    },
    [InfoModal, setUnsavedChanges, setIsSaving],
  );

  const saveForm = useCallback(async () => {
    setIsSaving(true);
    const fieldsToSave = form.getFieldsValue(true, (meta) => meta.touched);

    const appBasicInfoToSave: AppBasicInfoToSave = {};
    const appPropertiesToSave: AppPropertyToSave[] = [];

    for (const field in fieldsToSave) {
      const value = form.getFieldValue(field);
      if (PROPERTIES[field].type === 'appBasicInfo') {
        appBasicInfoToSave[field] = value;
      } else if (PROPERTIES[field].type === 'appProperty') {
        appPropertiesToSave.push({ Name: field, Value: value });
      }
    }

    // Add filenames to be saved if they have been touched
    for (const [key, value] of Object.entries(filenames)) {
      if (value?.touched) {
        if (key === 'AppIconImage') {
          appBasicInfoToSave[key] = value.value ?? '';
        } else {
          appPropertiesToSave.push({ Name: key, Value: value.value ?? '' });
        }
      }
    }

    if (platform === 'IOS') {
      appPropertiesToSave.push(...getScreenshotsToSave(platform, 'iPhone8', 'AppStoreIPhoneScreenshot'));
      appPropertiesToSave.push(...getScreenshotsToSave(platform, 'iPhoneX', 'AppStoreIPhonexsmaxScreenshot'));
      appPropertiesToSave.push(...getScreenshotsToSave(platform, 'iPad', 'AppStoreIPadScreenshot'));
    } else if (platform === 'Android') {
      appPropertiesToSave.push(...getScreenshotsToSave(platform, 'androidPhone', 'AppStoreAndroidPhoneScreenshot'));
      appPropertiesToSave.push(
        ...getScreenshotsToSave(platform, 'androidTablet', 'AppStoreAndroid10InchTabletScreenshot'),
      );
      appPropertiesToSave.push(...getScreenshotsToSave(platform, 'androidTV', 'AppStoreAndroidTVScreenshot'));
    } else if (platform === 'AppleTV' && appProperties) {
      appPropertiesToSave.push(...getScreenshotsToSave(platform, 'appleTV', 'AppStoreAppleTVScreenshot'));
    } else if (platform === 'Roku' && appProperties) {
      appPropertiesToSave.push(...getScreenshotsToSave(platform, 'roku', 'AppStoreRokuScreenshot'));
    }

    if (Object.keys(appBasicInfoToSave).length > 0) {
      try {
        await saveAppBasicInfo.mutateAsync(appBasicInfoToSave);
      } catch (error) {
        saveFailed(error);
      }
    }

    if (appPropertiesToSave.length > 0) {
      try {
        await saveAppProperties.mutateAsync(appPropertiesToSave);
      } catch (error) {
        saveFailed(error);
      }
    }

    setUnsavedChanges(false);
    setIsSaving(false);
    form.resetFields();
  }, [getScreenshotsToSave, setIsSaving, setUnsavedChanges, form, appProperties, saveAppProperties]);

  return {
    appBasicInfo,
    appProperties,
    form,
    unsavedChanges,
    isSaving,
    isLoading: !appProperties || !appBasicInfo || !appleCategories,
    isError: appPropertiesIsError || appBasicInfoIsError || appleCategoriesIsError,
    appleCategories: appleCategories || [{ name: '', value: '' }],
    filenames,
    screenshots,
    maxScreenshots: MAX_SCREENSHOTS[platform],
    addScreenshot,
    removeScreenshot,
    onValuesChange,
    onFilenameChange,
    onScreenshotChange,
    saveForm,
  };
};
