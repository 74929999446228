import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppCollectionContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { BuilderCollection } from 'app/modules/build-dragdrop/Builder/providers';

/**
 * Manages the items available for a collection that doesn't immediately show all items
 * For now default to first 6 items, in future we may use an app property
 * @param collection - The collection the items are from
 */
export const useCappedCollectionItems = (collection?: BuilderCollection) => {
  const { isDetailsView } = useAppCollectionContext();
  const [canShowMore, setCanShowMore] = useState<boolean>(false);
  const [showLimit, setShowLimit] = useState<number>(6);

  const showMoreApplies = collection?.Items && collection.Items.length > 6 && !isDetailsView;

  useEffect(() => {
    if (showMoreApplies) {
      if (collection?.Items.length && showLimit > collection?.Items.length) {
        setCanShowMore(false);
      } else {
        setCanShowMore(true);
      }
    } else {
      setCanShowMore(false);
    }
  }, [collection, collection?.Items, showLimit]);

  const items = useMemo(() => {
    const sortedItems = collection?.Items.sort((a, b) => a.Position - b.Position) ?? [];
    if (!showMoreApplies) {
      return sortedItems;
    }
    return sortedItems.slice(0, showLimit);
  }, [collection, collection?.Items, showMoreApplies, showLimit]);

  const showMore = useCallback(() => {
    setShowLimit((current) => current + 6);
  }, [setShowLimit]);

  return { items, canShowMore, showMore, showMoreApplies };
};
