export interface PropertyType {
  type: 'appProperty' | 'secureAppProperty';
  isFile?: boolean;
  stripWhitespace?: boolean;
}

export interface Field {
  name: string;
  label: string;
  placeholder?: string;
  maxLength?: number;
  max?: number;
  tooltip?: string;
  width?: string;
  type?: 'text' | 'timeSeconds';
}

export const FIELD_WIDTH = '49%';
export const IMAGE_WIDTH = '311px';

// Map of all properties editable in the Circuit Configuration form
export const PROPERTIES: Record<string, PropertyType> = {
  // Labels
  CircuitRoundLabel: { type: 'appProperty' },
  CircuitNextLabel: { type: 'appProperty' },
  CircuitSkipLabel: { type: 'appProperty' },
  CircuitDoneLabel: { type: 'appProperty' },
  // Workout Imagery
  CircuitReadyAndFinishImage: { type: 'appProperty', isFile: true },
  // Finish Workout
  CircuitFinishWorkoutPrimaryMessage: { type: 'appProperty' },
  CircuitFinishWorkoutSecondaryMessage: { type: 'appProperty' },
  // Audio Cues
  CircuitReadyAudio: { type: 'appProperty', isFile: true },
  CircuitGoAudio: { type: 'appProperty', isFile: true },
  // Default Rest Times
  PreExerciseCountInDefault: { type: 'appProperty' },
  InterRoundRestDefault: { type: 'appProperty' },
  InterCircuitRestDefault: { type: 'appProperty' },
};

export const LABEL_FIELDS: Field[] = [
  {
    name: 'CircuitRoundLabel',
    label: 'Round Label',
    placeholder: 'Round',
    maxLength: 15,
    tooltip:
      'This label will appear in your app next to the name of a circuit. It communicates to a user how many times they will repeating said circuit.',
  },
  {
    name: 'CircuitNextLabel',
    label: 'Next Label',
    placeholder: 'Next',
    maxLength: 12,
    tooltip:
      'This label appears while a user is performing an exercise, giving them the ability to progress to the next exercise or rest.',
  },
  {
    name: 'CircuitSkipLabel',
    label: 'Skip Label',
    placeholder: 'Skip',
    maxLength: 12,
    tooltip:
      'This label appears while a user is resting, giving them the ability to skip a pre-exercise rest, round rest or circuit rest.',
  },
  {
    name: 'CircuitDoneLabel',
    label: 'Done Label',
    placeholder: 'Done',
    maxLength: 12,
    tooltip:
      "This label appears on the final exercise of the workout. When tapped it will transition them to the 'Finished Workout' screen.",
  },
];

export const FINISH_WORKOUT_FIELDS: Field[] = [
  {
    name: 'CircuitFinishWorkoutPrimaryMessage',
    label: 'Primary Message',
    placeholder: 'Congratulations!',
    maxLength: 25,
    width: '100%',
  },
  {
    name: 'CircuitFinishWorkoutSecondaryMessage',
    label: 'Secondary Message',
    placeholder: 'You smashed it!',
    maxLength: 40,
    width: '100%',
  },
];

export const REST_TIME_FIELDS: Field[] = [
  {
    name: 'PreExerciseCountInDefault',
    label: 'Pre Exercise Count-In Time Default',
    placeholder: '10',
    max: 99,
    tooltip:
      'The pre-exercise rest is the transition time between two exercises. To provide the ideal user experience and user interface we have capped this amount at 99sec.',
    type: 'timeSeconds',
  },
  {
    name: 'InterRoundRestDefault',
    label: 'Inter-Round Rest Default',
    placeholder: '30',
    max: 999,
    tooltip:
      'The round rest is the transition time between repetitions of a circuit i.e. rounds.To provide the ideal user experience and user interface we have capped this amount at 999sec.',
    type: 'timeSeconds',
  },
  {
    name: 'InterCircuitRestDefault',
    label: 'Inter Circuit Rest Default',
    placeholder: '120',
    max: 999,
    tooltip:
      'The circuit rest is the transition time between two individual circuits. To provide the ideal user experience and user interface we have capped this amount at 999sec.',
    type: 'timeSeconds',
  },
];
