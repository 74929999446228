import { Segment, SegmentProps, SettingsTextInput } from 'components';
import styled from 'styled-components';
import { FONT_12PX_SEMIBOLD } from 'font';
import { NEUTRAL_10_COLOUR } from 'theme';

const InputWrapper = styled.div<{ $marginBottom?: string }>`
  width: 100%;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '24px'};
`;
const InputLabel = styled.div`
  ${FONT_12PX_SEMIBOLD};
  color: ${NEUTRAL_10_COLOUR};
  white-space: nowrap;
  margin-bottom: 8px;
`;

interface WebsiteURLProps extends SegmentProps {
  URL: string;
  onChange: (URL: string) => void;
}

export const WebsiteURL = ({ URL, onChange, ...props }: WebsiteURLProps) => {
  return (
    <Segment {...props}>
      <InputWrapper $marginBottom="0">
        <InputLabel>URL</InputLabel>
        <SettingsTextInput
          size="small"
          value={URL}
          placeholder="Enter a URL"
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </InputWrapper>
    </Segment>
  );
};
