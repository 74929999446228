import styled from 'styled-components';

import { BuilderCollection } from 'providers';
import { getDataSourceDisplayName } from 'utils';
import { CustomButton, Segment, SegmentProps, SegmentTitle, SettingsTextInput } from 'components';

import { SOURCE_VIDAPP } from 'api';
import { InputSize } from 'theme';
import { LinkIcon } from 'icons';
import { useDeepLink } from 'hooks/useDeepLink';

const InputWrapper = styled.div<{ marginBottom?: string }>`
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface ContentSourceTitleProps extends SegmentProps {
  itemId?: string | number; // Needed for deeplink
  itemType?: 'collection' | 'video'; // Needed for deeplink
  size?: InputSize;
  showDeepLink?: boolean;
}

export const ContentSourceTitle = ({
  DataSource,
  SourceName,
  size = 'small',
  showDeepLink,
  itemId,
  itemType,
  ...props
}: Pick<BuilderCollection, 'DataSource' | 'SourceName'> & ContentSourceTitleProps) => {
  const { domain, copyDomainToClipboard } = useDeepLink({ itemType, itemId });

  return DataSource !== SOURCE_VIDAPP ? (
    <Segment
      header={
        <Row>
          <SegmentTitle title={`${getDataSourceDisplayName(DataSource)} Title`} />
          {!!domain && showDeepLink && (
            <CustomButton
              medium
              tertiaryHighlight
              icon={<LinkIcon />}
              iconPosition="right"
              onClick={copyDomainToClipboard}
            >
              Copy Deep Link
            </CustomButton>
          )}
        </Row>
      }
      {...props}
    >
      <InputWrapper>
        <SettingsTextInput
          size={size}
          disabled
          value={SourceName || 'None'}
          $marginTop={size === 'large' ? '0' : undefined}
        />
      </InputWrapper>
    </Segment>
  ) : null;
};
