import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { Comment, getComments, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useComments = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();

  const { data, fetchNextPage, isLoading, isError } = useInfiniteQuery(
    ['comments'],
    ({ pageParam }) => getComments(client, appId, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.page + 1,
    },
  );

  const comments = useMemo(() => {
    const commentsArr: Comment[] = [];
    if (data && !isLoading) {
      data.pages.forEach((page) => commentsArr.push(...page.comments));
    }
    return commentsArr;
  }, [data, isLoading]);

  return { comments, fetchNextPage, isLoading, isError };
};
