import { useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import { usePrefetchCollections, usePrefetchVideos } from 'hooks';

/**
 * This must be called from a component within the Guided Tour context
 */
export const useTourPrefetch = () => {
  const tour = useContext(ShepherdTourContext);
  const isActive = tour?.isActive();
  usePrefetchCollections({ enabled: isActive || false });
  usePrefetchVideos({ enabled: isActive || false });
};
