import { useState, useCallback } from 'react';

export const useMockupImages = () => {
  const [imagesToLoad, setImagesToLoad] = useState<number>(0);
  const [imagesLoaded, setImagesLoaded] = useState<number>(0);

  const handleImageLoaded = useCallback(() => {
    setImagesLoaded((oldValue) => oldValue + 1);
  }, [setImagesLoaded]);

  return {
    isLoading: imagesToLoad > imagesLoaded,
    imagesToLoad,
    setImagesToLoad,
    handleImageLoaded,
  };
};
