import styled from 'styled-components';
import { NEUTRAL_10_COLOUR } from 'theme';
import { FONT_12PX_SEMIBOLD } from 'font';

const Title = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_12PX_SEMIBOLD};
  height: 22px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const PanelSegmentTitle = ({ title }: { title: string }) => <Title>{title}</Title>;
