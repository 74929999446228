import { ReactNode } from 'react';
import styled from 'styled-components';

import { SegmentTitle } from 'components/Text/SegmentTitle';

export interface SegmentProps {
  header?: string | ReactNode;
  tooltip?: string;
  subHeader?: string;
  children?: ReactNode;
  isBeta?: boolean;
  $marginTop?: string;
  $marginBottom?: string;
}

// Represents the spacing between different segments of the drawer
const SegmentContainer = styled.div<SegmentProps>`
  margin-top: ${({ $marginTop }) => $marginTop || '20px'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '20px'};
`;

const SegmentInner = styled.div`
  padding-right: 4px;
`;

export const Segment = ({ header, tooltip, subHeader, children, isBeta, ...props }: SegmentProps) => {
  return (
    <SegmentContainer {...props}>
      {header && (
        <>
          {typeof header === 'string' ? (
            <SegmentTitle title={header} description={subHeader} isBeta={isBeta} tooltip={tooltip} />
          ) : (
            header
          )}
        </>
      )}
      {children && <SegmentInner className="segment-inner">{children}</SegmentInner>}
    </SegmentContainer>
  );
};
