import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';

import {
  ContentDescription,
  ContentResources,
  CustomButton,
  SettingsAlternateThumbnailInput,
  SettingsThumbnailInput,
} from 'components';
import { PlusIcon } from 'icons';
import { ITEM_TYPE_VIDEO, Resource } from 'api';

import { useModalContext } from '../providers/modal-provider';
import {
  BlueButton,
  ScrollView,
  ViewCheckboxInput,
  ViewContainer,
  ViewFormItem,
  ViewHeaderRow,
  ViewHeading,
  ViewRadioInput,
  ViewSection,
  ViewSectionHeading,
  ViewTextInput,
} from './ViewComponents';

const accessOptions = {
  options: [
    { name: 'Free for everyone', value: '0' },
    { name: 'Requires access', value: '-1' },
  ],
  default: '-1',
};

const { useForm } = Form;

const Flex = styled.div`
  display: flex;
`;

interface Post {
  title: string;
  thumbnail: string;
  squareThumbnail: string;
  portraitThumbnail: string;
  description: string;
  access: '-1' | '0';
  hideFromSearch: boolean;
  resources?: Resource[];
}

interface PostViewProps {
  onCreate: (post: Post) => void;
}

export const PostView = ({ onCreate }: PostViewProps) => {
  const [post, setPost] = useState<Post>({
    title: '',
    thumbnail: '',
    squareThumbnail: '',
    portraitThumbnail: '',
    description: '',
    access: '-1',
    hideFromSearch: false,
  });
  const [editing, setEditing] = useState<boolean>(false);
  const { setConfirmDisabled } = useModalContext();
  const [form] = useForm();

  useEffect(() => {
    // While editing, you can't quit the modal, you would have to clear it first
    setConfirmDisabled(editing);
  }, [editing]);

  const handleEdit = () => {
    setEditing(true);
  };
  const handleClear = () => {
    form.resetFields();
    setPost((existing) => ({
      ...existing,
      description: '',
      thumbnail: '',
      squareThumbnail: '',
      portraitThumbnail: '',
      resources: [],
    }));
    setEditing(false);
  };
  const validateForm = (post: Post): boolean => {
    form.setFields([{ name: 'Title', errors: [] }]);
    if (!post.title) {
      form.setFields([
        {
          name: 'Title',
          errors: ['Please enter a title'],
        },
      ]);
      return false;
    }

    return true;
  };

  const handleAddResource = useCallback(
    (resource: Resource) => {
      setPost((existing) => {
        const newState = { ...existing };
        const newResources = newState.resources ?? [];
        newResources.push(resource);
        return { ...newState, resources: newResources };
      });
      setEditing(true);
    },
    [setPost, setEditing],
  );

  const handleRemoveResource = useCallback(
    (resourceId: string | number) => {
      setPost((existing) => {
        const newState = { ...existing };
        const newResources = newState.resources ? newState.resources.filter((r) => r.ResourceId !== resourceId) : [];
        return { ...newState, resources: newResources };
      });
    },
    [setPost],
  );

  const handleResourcesTitleChange = useCallback(
    (title: string, resourceId: string | number) => {
      setPost((existing) => {
        const newState = { ...existing };
        const newResources = newState.resources ?? [];
        return {
          ...newState,
          resources: newResources.map((r) => (r.ResourceId === resourceId ? { ...r, Title: title } : r)),
        };
      });
    },
    [setPost],
  );

  return (
    <ViewContainer>
      <ViewHeaderRow>
        <ViewHeading>Create Post</ViewHeading>
        <Flex>
          <CustomButton medium tertiary onClick={handleClear} disabled={!editing}>
            Clear
          </CustomButton>
          <BlueButton
            medium
            tertiary
            icon={<PlusIcon />}
            disabled={!editing}
            onClick={() => {
              if (validateForm(post)) {
                onCreate(post);
                handleClear(); // Clear form after adding to the collection
              }
            }}
          >
            Add
          </BlueButton>
        </Flex>
      </ViewHeaderRow>
      <ScrollView>
        <Form form={form}>
          <ViewSection>
            <ViewSectionHeading>Title</ViewSectionHeading>
            <ViewFormItem name="Title">
              <ViewTextInput
                value={post.title}
                placeholder="Enter a title"
                onChange={(e) => {
                  handleEdit();
                  setPost((p) => {
                    return { ...p, title: e.target.value };
                  });
                }}
              />
            </ViewFormItem>
          </ViewSection>
          <ViewSection>
            <ViewSection>
              <SettingsThumbnailInput
                $marginTop="0px"
                filename={post.thumbnail ?? ''}
                isRevertable={false}
                handleThumbnailChange={(filename) => {
                  handleEdit();
                  setPost((p) => {
                    return { ...p, thumbnail: filename };
                  });
                }}
                width="172px"
                height="96px"
                uploaderProps={{
                  maxDimensions: { maxWidth: 1920, maxHeight: 1080 },
                  acceptedFileTypes: 'image/png, image/jpeg',
                  scaleSpinner: 80,
                }}
              />
              <SettingsAlternateThumbnailInput
                squareFilename={post?.squareThumbnail ?? ''}
                portraitFilename={post?.portraitThumbnail ?? ''}
                handleSquareThumbnailChange={(filename) => {
                  handleEdit();
                  setPost((p) => {
                    return { ...p, squareThumbnail: filename };
                  });
                }}
                handlePortraitThumbnailChange={(filename) => {
                  handleEdit();
                  setPost((p) => {
                    return { ...p, portraitThumbnail: filename };
                  });
                }}
                squareWidth="123px"
                portraitWidth="123px"
                portraitHeight="184px"
                orientation="column"
              />
            </ViewSection>
          </ViewSection>
          <ViewSection>
            <ViewSectionHeading>Description</ViewSectionHeading>
            <ContentDescription
              controlledDesc={post.description}
              onDescChange={(description) => {
                if (description) {
                  handleEdit();
                }
                setPost((oldPost) => {
                  return { ...oldPost, description };
                });
              }}
              hideTitle
              height={177}
              $marginBottom="0"
              $marginTop="0"
            />
          </ViewSection>
          <ViewSection>
            <ContentResources
              existingResources={post.resources ?? []}
              onAddResource={handleAddResource}
              onRemoveResource={handleRemoveResource}
              onTitleChange={handleResourcesTitleChange}
              parentType={ITEM_TYPE_VIDEO}
              onFileSelected={() => setEditing(false)}
            />
          </ViewSection>
          <ViewSection>
            <ViewSectionHeading>Content Access</ViewSectionHeading>
            <ViewFormItem name="access">
              <ViewRadioInput
                value={post.access}
                defaultVal={accessOptions.default}
                options={accessOptions.options}
                onChange={(e) => {
                  handleEdit();
                  setPost((p) => {
                    return { ...p, access: e.target.value };
                  });
                }}
              />
            </ViewFormItem>
          </ViewSection>
          <ViewSection>
            <ViewSectionHeading>Other</ViewSectionHeading>
            <ViewCheckboxInput
              name="hideFromSearch"
              label="Exclude from search results in the app"
              defaultChecked={post.hideFromSearch}
              onChange={(e) => {
                handleEdit();
                setPost((p) => {
                  return { ...p, hideFromSearch: e.target.checked };
                });
              }}
            />
          </ViewSection>
        </Form>
      </ScrollView>
    </ViewContainer>
  );
};
