import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';

export const handleErrorWithSentry = (err: unknown, tags: Record<string, string> = {}) => {
  const axiosError = err as AxiosError;

  if (axiosError?.isAxiosError) {
    const {
      config: { method, baseURL, url },
    } = axiosError;
    let requestUrl = '';
    if (url?.startsWith('http') || url?.startsWith('https')) {
      requestUrl = url;
    } else {
      requestUrl = `${baseURL}${url}`.replace('//', '/');
    }

    if (axiosError.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const {
        response: { data, status },
      } = axiosError;

      if (status === 404 && url?.includes('pricing-plan-search')) {
        // This error is expected
        return;
      }

      Sentry.captureMessage(`${method?.toUpperCase()}|${requestUrl}|${status}`, {
        tags: { ...tags, crash: 'error_response' },
        extra: {
          data: JSON.stringify(data),
        },
      });
    } else if (axiosError.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest
      Sentry.captureMessage(`${method?.toUpperCase()}|${requestUrl}|unresponsive`, {
        tags: { ...tags, crash: 'server_unresponsive' },
        extra: {
          data: JSON.stringify(axiosError.request?.data),
        },
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest
      Sentry.captureMessage(`${method?.toUpperCase()}|${requestUrl}|norequest`, {
        tags: { ...tags, crash: 'client_crash' },
      });
    }
  } else {
    Sentry.captureException(err);
  }
};
