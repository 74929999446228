import { useCallback } from 'react';
import { ColorResult } from 'react-color';
import styled from 'styled-components';

import { SegmentTitle, SettingsColorInput } from 'components';
import { useLocalAppProperties, useSaveContext } from 'providers';

const Wrapper = styled.div<{ $marginRight?: string }>`
  width: 32%;
  margin-right: ${({ $marginRight }) => $marginRight || 'unset'};
`;

export const AppPropertyColorInput = ({
  label,
  property,
  tooltip,
  $marginRight,
}: {
  label?: string;
  property: string;
  tooltip?: string;
  $marginRight?: string;
}) => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();

  const value = properties && properties[property];
  const formattedValue = value ? { hex: `#${value}` } : undefined;

  const handleColorChange = useCallback(
    (color: ColorResult) => {
      const value = color.hex.substring(1);
      setAppProperty(property, value);
      setAppPropertyToSave(property, value);
    },
    [property, setAppProperty, setAppPropertyToSave],
  );

  return (
    <Wrapper $marginRight={$marginRight}>
      {label && <SegmentTitle title={label} />}
      <SettingsColorInput value={formattedValue} onChange={(color) => handleColorChange(color)} tooltip={tooltip} />
    </Wrapper>
  );
};
