import { useCallback } from 'react';

import { useSaveContext, useLocalProducts, BuilderProduct } from 'providers';
import { SOURCE_KAJABI } from 'api';
import { ContentTitle } from 'components';

export const InternalTitle = ({
  Id,
  DisplayName,
  DataSource,
  AppId,
}: Pick<BuilderProduct, 'Id' | 'DisplayName' | 'DataSource' | 'AppId'>) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();
  const handleTitleChange = useCallback(
    (title: string) => {
      setProductValue(Id, 'DisplayName', title);
      setProductValue(Id, 'ReferenceName', `${AppId} - ${title}`);
      setProductValue(Id, 'Description', `${AppId} - ${title}`);
      setProductValueToSave(Id, 'DisplayName', title);
      setProductValueToSave(Id, 'ReferenceName', `${AppId} - ${title}`);
      setProductValueToSave(Id, 'Description', `${AppId} - ${title}`);
    },
    [setProductValue, setProductValueToSave, Id, AppId],
  );

  return (
    <ContentTitle
      disabled={DataSource === SOURCE_KAJABI}
      label="Internal Title"
      size="large"
      onChange={handleTitleChange}
      name={DisplayName}
    />
  );
};
