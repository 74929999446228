import styled from 'styled-components';

import { Resource } from 'api';
import { FONT_12PX_MEDIUM, FONT_14PX_REGULAR, OVERFLOW_ELLIPSIS } from 'font';
import { NEUTRAL_3_COLOUR, NEUTRAL_4_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { getResourceUrl } from 'utils';
import { useAppBeingEdited } from 'app-context';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${NEUTRAL_3_COLOUR};
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
`;

const Row = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  :hover {
    background-color: ${NEUTRAL_4_COLOUR};
  }

  :not(:last-child) {
    border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
  }
`;

const TextWrapper = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_7_COLOUR};
  height: 100%;
`;

const TitleWrapper = styled(TextWrapper)`
  padding: 10px 12px;
  max-width: 550px;
  ${OVERFLOW_ELLIPSIS};
`;

const TypeWrapper = styled(TextWrapper)`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
`;

const Empty = styled.div`
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
  width: 100%;
  text-align: center;
  padding: 50px;
`;

interface ItemResourcesProps {
  resources: Resource[];
}

export const ItemResources = ({ resources }: ItemResourcesProps) => {
  const appId = useAppBeingEdited();
  return (
    <Container>
      {resources.length === 0 ? (
        <Empty>No resources</Empty>
      ) : (
        resources.map(({ ResourceId, Title, Type, Url }) => (
          <Row
            key={ResourceId}
            onClick={() => {
              const url = getResourceUrl(appId, { Url });
              window.open(url, '_blank');
            }}
          >
            <TitleWrapper>{Title}</TitleWrapper>
            <TypeWrapper>{Type}</TypeWrapper>
          </Row>
        ))
      )}
    </Container>
  );
};
