import styled from 'styled-components';

const Flex = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 100px;
`;
const StyledImage = styled.img`
  max-width: 100%;
`;
export const ImgView = ({ url }: { url: string }) => {
  return (
    <Flex>
      <div>
        <StyledImage src={url} />
      </div>
    </Flex>
  );
};
