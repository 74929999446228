import { useMutation, useQueryClient, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { saveAppProperties, useAxiosInstance } from 'api';
import { useAppProperties, useRecordAction } from 'hooks';

export const useTheme = (
  appPropertiesStaleTime?: UseQueryOptions['staleTime'],
  appPropertiesRefetchOnMount?: UseQueryOptions['refetchOnMount'],
) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  const { data: properties } = useAppProperties({
    staleTime: appPropertiesStaleTime,
    refetchOnMount: appPropertiesRefetchOnMount,
  });

  type ColorScheme = 'Light' | 'Dark';

  interface Payload {
    ColorScheme: ColorScheme;
  }

  const THEME_PROPERTY_MAP = {
    Light: [
      { Name: 'ColorScheme', Value: 'Light' },
      { Name: 'NavBarBackgroundHEX', Value: 'FFFFFF' },
      { Name: 'NavBarTitleHEX', Value: '000000' },
      { Name: 'TabBarBackgroundHEX', Value: 'FFFFFF' },
      { Name: 'TabIconNotSelectedHEX', Value: '484848' },
    ],
    Dark: [
      { Name: 'ColorScheme', Value: 'Dark' },
      { Name: 'NavBarBackgroundHEX', Value: '000000' },
      { Name: 'NavBarTitleHEX', Value: 'FFFFFF' },
      { Name: 'TabBarBackgroundHEX', Value: '000000' },
      { Name: 'TabIconNotSelectedHEX', Value: 'DFDFDF' },
    ],
  };

  const mutator = useMutation<unknown, AxiosError, Payload>(
    (payload) => {
      return saveAppProperties(client, appId, THEME_PROPERTY_MAP[payload?.ColorScheme]);
    },
    {
      onSuccess: async (response, payload) => {
        // Updates the query cache to have the new properties without refetching
        const cachedProps: Record<string, string> = queryClient.getQueryData(['appProperties', appId]) ?? {};
        const newProperties = THEME_PROPERTY_MAP[payload?.ColorScheme];
        newProperties.forEach((property) => {
          cachedProps[property['Name']] = property['Value'];
        });
        queryClient.setQueryData(['appProperties', appId], cachedProps);
        recordAction.mutate({ action: 'Saved Color Scheme', detail: { ...payload } });
      },
    },
  );

  const getThemeDefaults = (colorScheme: ColorScheme) => THEME_PROPERTY_MAP[colorScheme].slice(1);

  return {
    mutateTheme: mutator,
    themeProperties: {
      NavBarBackgroundHEX: properties?.NavBarBackgroundHEX,
      NavBarTitleHEX: properties?.NavBarTitleHEX,
      TabBarBackgroundHEX: properties?.TabBarBackgroundHEX,
      TabIconNotSelectedHEX: properties?.TabIconNotSelectedHEX,
    },
    getThemeDefaults,
  };
};
