import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { DataSource, runSync, SyncOptions, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useDataSource } from 'providers';

export const useSyncApp = (options: SyncOptions) => {
  const appId = useAppBeingEdited();
  const dataSource = useDataSource();
  const client = useAxiosInstance();

  return useMutation<unknown, AxiosError, { overrideDataSource?: DataSource; productId?: string; themeId?: number }>(
    async ({ overrideDataSource, productId, themeId }) => {
      if (productId) {
        options.productId = productId;
      }
      if (themeId) {
        options.themeId = themeId;
      }
      const response = await runSync(client, appId, overrideDataSource ?? dataSource, options);
      return response?.data;
    },
  );
};
