import styled from 'styled-components';
import { NEUTRAL_7_COLOUR } from 'theme';
import { FONT_14PX_NORMAL } from '../../font';
import { VIDAPP_HELP_URL } from 'api';

interface NeedHelpProps {
  $color?: string;
  overrideText?: string;
  overrideUrl?: string;
}

const UnstyledNeedHelp = ({ overrideText, overrideUrl, ...props }: NeedHelpProps) => {
  return (
    <a target="_blank" rel="noreferrer" href={overrideUrl ?? VIDAPP_HELP_URL} {...props}>
      {overrideText ?? 'Need help?'}
    </a>
  );
};

export const NeedHelp = styled(UnstyledNeedHelp)`
  #react-app & {
    ${FONT_14PX_NORMAL};
    text-decoration: underline;
    color: ${(props) => props.$color || NEUTRAL_7_COLOUR};
  }
`;
