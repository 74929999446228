import { FormInstance } from 'antd';

import {
  AppProperties,
  BuilderIndexedCollections,
  CALENDAR_TEMPLATE,
  Collection,
  CollectionKey,
  COMMUNITY_TEMPLATE,
  FILTER_TEMPLATE,
  MIXED_TEMPLATE,
  MY_LISTS_TEMPLATE,
  MY_PLAYLISTS_TEMPLATE,
  PROFILE_TEMPLATE,
  SOURCE_VHX,
  SUBNAV_TEMPLATE,
  WEBSITE_TEMPLATE,
} from 'api';
import { BuilderCollection, BuilderCollectionItem } from 'providers';
import { isContent1Tab } from 'utils';

import { CONTENT_1_SORT } from 'app/modules/build-screens/const';
import { CONTENT_TEMPLATES } from '../build-dragdrop/Builder/mockup/const';

//? VALUE TYPES:
//* binary:
//* Returned by API as a string value, either '1' or '0'. Function will return boolean value for use in checkboxes

//* inverseBinary:
//* Same as above, but to be used when checkbox should show the opposite of the value, e.g. property is for hiding a label, but the checkbox is for displaying the label

//* fullHex:
//* Returned by API as a hex value, including #. Function will return value inside an object, for use in SettingsColorInput component

//* hex:
//* Same as above, but returned by API without #

//* string:
//* Returned by API as a string. Function will return string as is, for use in text inputs, select inputs etc.

export interface PropertyMap {
  type: 'app' | 'collection' | 'collectionProperty';
  valueType: 'binary' | 'inverseBinary' | 'hex' | 'fullHex' | 'string';
  defaultVal?: string;
  touched?: boolean;
}

export const getAppPropertyValue = (propertyMap: PropertyMap, obj: AppProperties, key: string) => {
  if (propertyMap.valueType === 'binary') {
    return obj[key] === '1';
  } else if (propertyMap.valueType === 'inverseBinary') {
    return obj[key] !== '1';
  } else if (propertyMap.valueType === 'fullHex') {
    // Should already have a hash, but autofix old apps
    const value = obj[key] ?? propertyMap.defaultVal;
    return { hex: value && !value.startsWith('#') ? `#${obj[key]}` : value };
  } else if (propertyMap.valueType === 'hex') {
    return { hex: `#${obj[key]}` };
  } else {
    return obj[key] ?? propertyMap.defaultVal;
  }
};

export const getCollectionValue = (propertyMap: PropertyMap, obj: Collection, key: CollectionKey | string) => {
  if (propertyMap.valueType === 'binary') {
    if (propertyMap.type === 'collectionProperty' && obj.Properties) {
      return obj.Properties[key as string] === '1';
    } else {
      return obj[key as CollectionKey] === 1;
    }
  } else if (propertyMap.valueType === 'inverseBinary') {
    if (propertyMap.type === 'collectionProperty' && obj.Properties) {
      return obj.Properties[key as string] !== '1';
    } else {
      return obj[key as CollectionKey] !== 1;
    }
  } else if (propertyMap.valueType === 'fullHex') {
    if (propertyMap.type === 'collectionProperty' && obj.Properties) {
      return { hex: obj.Properties[key as string] };
    } else {
      return { hex: obj[key as CollectionKey] };
    }
  } else if (propertyMap.valueType === 'hex') {
    if (propertyMap.type === 'collectionProperty' && obj.Properties) {
      return { hex: `#${obj.Properties[key as string]}` };
    } else {
      return { hex: `#${obj[key as CollectionKey]}` };
    }
  } else {
    if (propertyMap.type === 'collectionProperty' && obj.Properties) {
      return obj.Properties[key as string] ?? propertyMap.defaultVal;
    } else {
      return obj[key as CollectionKey] ?? propertyMap.defaultVal;
    }
  }
};

export const setInitialFormField = (
  form: FormInstance,
  name: string,
  value: string | number | boolean | Record<'hex', string> | unknown,
) => {
  if (!form.getFieldValue(name)) {
    form.setFields([{ name: name, value: value }]);
  }
};

export const sortContent1Items = (items: BuilderCollectionItem[], collections: BuilderIndexedCollections) => {
  items.sort((a, b) => {
    const colA = collections[a.ChildId] ?? {};
    const colB = collections[b.ChildId] ?? {};
    return CONTENT_1_SORT.indexOf(colA.SourceId) - CONTENT_1_SORT.indexOf(colB.SourceId);
  });
};

export const getPageName = (tab: BuilderCollection, dataSource?: string, overrideContentName?: string) => {
  const contentName = isContent1Tab(tab) ? (dataSource === SOURCE_VHX ? 'Browse' : 'Library') : 'Content';

  const nameMap: Record<number, string> = {
    [MIXED_TEMPLATE]: overrideContentName || contentName,
    [SUBNAV_TEMPLATE]: overrideContentName || contentName,
    [FILTER_TEMPLATE]: 'Search',
    [CALENDAR_TEMPLATE]: 'Calendar',
    [MY_LISTS_TEMPLATE]: 'My Lists',
    [MY_PLAYLISTS_TEMPLATE]: 'My Playlists',
    [PROFILE_TEMPLATE]: 'Profile',
    [WEBSITE_TEMPLATE]: 'Website',
    [COMMUNITY_TEMPLATE]: 'Community',
  };

  return CONTENT_TEMPLATES.includes(tab.TemplateId) ? 'Content' : nameMap[tab.TemplateId] ?? 'Other';
};

export const isNetflixTemplate = (collection: BuilderCollection, appDataSource: string) =>
  collection.SourceId === 'Content1' && appDataSource === SOURCE_VHX;

// Protect the Netflix template and Profile Tab from deletion
export const tabIsProtected = (collection: BuilderCollection, appDataSource: string, protectProfileTab: boolean) => {
  if (isNetflixTemplate(collection, appDataSource)) {
    return true;
  }
  if (protectProfileTab && collection.TemplateId === 31) {
    return true;
  }
  return false;
};
