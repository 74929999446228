import { AxiosInstance } from 'axios';
import { subMinutes } from 'date-fns';

export const getSyncTracing = (client: AxiosInstance, appId: string, initiator: string) => {
  const date = new Date();
  const pastTime = subMinutes(date, 30);
  const isoDate = pastTime.toISOString();

  const formattedTime = `${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`;

  return client
    .get(`/sync-tracing/${appId}`, {
      params: {
        initiator: initiator,
        created_after: formattedTime,
      },
    })
    .then(({ data }) => {
      return data;
    });
};
