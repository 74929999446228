import styled from 'styled-components';
import { FileBlankIcon, LinkIcon } from 'icons';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { FONT_14PX_MEDIUM, FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';

const Flex = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${NEUTRAL_7_COLOUR};

  padding-bottom: 100px;
`;
const StyledIcon = styled(FileBlankIcon)`
  font-size: 33px;
`;
const Head = styled.div`
  margin-top: 12px;
  ${FONT_16PX_MEDIUM};
`;
const Sub = styled.div`
  margin-top: 4px;
  ${FONT_14PX_REGULAR};
  padding-left: 100px;
  padding-right: 100px;
`;
const BlueLink = styled.a`
  margin-top: 25px;
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  ${FONT_14PX_MEDIUM};

  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledLink = styled(LinkIcon)`
  font-size: 16px;
  margin-right: 2px;
`;

export const PDFView = ({ url }: { url: string }) => {
  return (
    <Flex>
      <div>
        <StyledIcon />
        <Head>PDF</Head>
        <Sub>Your PDF will display here in your live app.</Sub>
        <BlueLink href={url}>
          <StyledLink />
          Open PDF in browser
        </BlueLink>
      </div>
    </Flex>
  );
};
