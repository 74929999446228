import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { Filter, FilterToSave, GetFiltersResponse, saveFilter, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks/useRecordAction';

export const useSaveFilter = (invalidateQuery = true) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse<Filter>, AxiosError, FilterToSave>(
    (filter) => {
      return saveFilter(client, appId, filter);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          const filters = queryClient.getQueryData<GetFiltersResponse>(['filters', appId]);
          const updatedArray = filters?.filters.filter((filter) => filter.Id !== payload.Id) as Filter[];
          queryClient.setQueryData(['filters', appId], { ...filters, filters: [...updatedArray, _response.data] });
          await queryClient.invalidateQueries(['tagged_collections', appId]);
          await queryClient.invalidateQueries(['tagged_videos', appId]);
        }
        recordAction.mutate({ action: 'Save Filters', detail: { filter: payload } });
      },
    },
  );
};
