import styled, { css } from 'styled-components';

import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { NEUTRAL_6_COLOUR, NEUTRAL_8_COLOUR, NEUTRAL_10_COLOUR, VIDAPP_GRADIENT } from 'theme';
import { FONT_16PX_REGULAR, FONT_16PX_MEDIUM, FONT_20PX_REGULAR, FONT_24PX_SEMIBOLD, FONT_30PX_SEMIBOLD } from 'font';

const MOBILE_WIDTH = '710px';

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(70.3deg, #ffd874 0%, #f4845f 29.3%, #f16a75 62.39%, #f16aab 97.64%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 680px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${MOBILE_WIDTH}) {
    width: 400px;
    padding: 20px;
  }
`;

const TextWrapper = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (max-width: ${MOBILE_WIDTH}) {
    margin: 20px 0;
  }
`;

const Heading = styled.div`
  margin-bottom: 16px;
  ${FONT_30PX_SEMIBOLD};
  line-height: 36px;
  color: ${NEUTRAL_10_COLOUR};

  @media (max-width: ${MOBILE_WIDTH}) {
    font-size: 25px;
    ${FONT_24PX_SEMIBOLD};
  }
`;

const Description = styled.div`
  ${FONT_20PX_REGULAR};
  line-height: 24px;
  color: ${NEUTRAL_8_COLOUR};

  @media (max-width: ${MOBILE_WIDTH}) {
    ${FONT_16PX_REGULAR}
    line-height: 20px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
    align-items: center;
    height: 105px;
  }
`;

const BUTTON_STYLES = css`
  ${FONT_16PX_MEDIUM};
  line-height: 19px;
  width: 284px;
  height: 48px;
  border-radius: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${MOBILE_WIDTH}) {
    width: 100%;
  }
`;

const ProceedButton = styled.button`
  ${BUTTON_STYLES};
  color: ${NEUTRAL_10_COLOUR};
  background: #fff;
  border: 2px solid ${NEUTRAL_6_COLOUR};
  outline: none !important;
`;

const ChromeLink = styled.a`
  ${BUTTON_STYLES};
  color: #fff !important;
  background: ${VIDAPP_GRADIENT};
`;

export const UnsupportedBrowser = ({ handleProceed }: { handleProceed: () => void }) => {
  return (
    <Background>
      <Container>
        <img src={`${BUILDER_IMAGES_DOMAIN}/branding/vidapp_logo.webp`} width="146" alt="VidApp logo" />
        <TextWrapper>
          <Heading>
            Use Google Chrome for the <br />
            best app-building experience.
          </Heading>
          <Description>
            You can download Google Chrome via the button below or proceed with your current browser. Other browsers may
            encounter scaling and functional issues.
          </Description>
        </TextWrapper>
        <ButtonWrapper>
          <ProceedButton onClick={handleProceed}>Proceed with current browser</ProceedButton>
          <ChromeLink href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
            Download Google Chrome
          </ChromeLink>
        </ButtonWrapper>
      </Container>
    </Background>
  );
};
