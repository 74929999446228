import { AppProperties, ITEM_TYPE_VIDEO, VIDEO_DETAILS_EXPANDABLE_DESC, VIDEO_DETAILS_RESOURCES } from 'api';
import { useEffect, useMemo } from 'react';
import { UpNext } from 'app/modules/build-dragdrop/Builder/mockup/components/UpNext';
import { CollectionResource } from 'app/modules/build-dragdrop/Builder/mockup/components/CollectionViews/CollectionResource';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { useAppProperties } from 'hooks';
import styled from 'styled-components';
import { FONT_16PX_REGULAR } from 'font';
import { BuilderCollection, BuilderVideo } from 'providers';
import { getVideoHTMLDescription } from 'utils';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { SubNavDisplay } from 'app/modules/build-dragdrop/Builder/mockup/components/SubNavDisplay';
import DOMPurify from 'dompurify';

// VideoDetails Subnav
const About = styled.div<{ $color: string }>`
  padding: 16px;
  ${FONT_16PX_REGULAR};
  color: ${({ $color }) => $color};

  cursor: default;
  background-color: transparent;
`;

const Resources = styled.div`
  padding: 16px;
`;

interface VideoSubNavProps {
  video: BuilderVideo;
  parentCollection?: BuilderCollection;
  onNoItems: () => void;
}

export const VideoSubNav = ({ video, onNoItems, parentCollection }: VideoSubNavProps) => {
  const { currentPage } = useMockupContext();
  const { data: appProperties } = useAppProperties();
  const { getDesignProperty } = useAppTheme();

  const desc = getVideoHTMLDescription(video);
  const $color = getDesignProperty('primaryItemHEX') as string;

  const isExpandableDesc = appProperties?.ItemDetailsDesign === VIDEO_DETAILS_EXPANDABLE_DESC;
  const isResourcesBelow = appProperties?.ItemDetailsDesign === VIDEO_DETAILS_RESOURCES;

  const { CustomVideoAboutLabel, CustomVideoResourcesLabel, CustomVideoUpNextLabel } =
    appProperties ?? ({} as AppProperties);

  const nextItems = useMemo(() => {
    if (!parentCollection) {
      return [];
    }
    const index = parentCollection.Items.findIndex((item) => item.TabItemId === currentPage?.tabItemId);
    if (index < 0) {
      console.warn('VideoDetails', 'Failed to setup UpNext unable to find currentItem', {
        tabId: parentCollection.TabId,
        tabItemId: currentPage?.tabItemId,
      });
      return [];
    }
    return parentCollection.Items.slice(index + 1).filter((i) => i.Type === ITEM_TYPE_VIDEO);
  }, [currentPage, parentCollection, parentCollection?.Items]);

  const items = useMemo(() => {
    const output = [];

    if (!isExpandableDesc && !isResourcesBelow && desc) {
      const purifiedDesc = DOMPurify.sanitize(desc);
      output.push({
        name: CustomVideoAboutLabel || 'About',
        component: <About $color={$color} dangerouslySetInnerHTML={{ __html: purifiedDesc }} />,
        id: `${video.VideoId}|about`,
      });
    }
    if (parentCollection && nextItems && nextItems.length > 0) {
      output.push({
        name: CustomVideoUpNextLabel || 'Up Next',
        component: <UpNext parentCollection={parentCollection} nextItems={nextItems} />,
        id: `${video.VideoId}|${parentCollection.TabId}|upnext`,
      });
    }
    if (!isResourcesBelow && video.Resources && video.Resources.length > 0) {
      output.push({
        name: CustomVideoResourcesLabel || 'Resources',
        component: (
          <Resources>
            {video.Resources.map((resource) => {
              return <CollectionResource {...resource} key={resource.ResourceId} />;
            })}
          </Resources>
        ),
        id: `${video.VideoId}|resources`,
      });
    }
    return output;
  }, [video, getDesignProperty, nextItems, CustomVideoAboutLabel, CustomVideoResourcesLabel, CustomVideoUpNextLabel]);

  useEffect(() => {
    // When there are no subnav items, auto-expand the description
    if (items.length == 0 && onNoItems) {
      onNoItems();
    }
  }, []);

  return items.length > 0 ? <SubNavDisplay items={items} hideShadow /> : null;
};
