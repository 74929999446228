import { runSync, SyncOptions, useAxiosInstance, SOURCE_KAJABI } from '../api';
import { useAppBeingEdited } from '../app-context';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

export const useSyncKajabiProduct = (options: SyncOptions) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();

  return useMutation<unknown, AxiosError, { productId?: string; themeId?: number }>(async ({ productId, themeId }) => {
    if (productId) {
      options.productId = productId;
    }
    if (themeId) {
      options.themeId = themeId;
    }
    const response = await runSync(client, appId, SOURCE_KAJABI, options);
    return response?.data;
  });
};
