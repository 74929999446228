import styled, { css } from 'styled-components';

import {
  contentHasPreviewVideo,
  getCollectionName,
  getContentName,
  getContentOverlay,
  isSectionHeader,
  usePointerOnClick,
} from 'utils';
import { APP_FONT_12PX_MEDIUM } from 'mockup-font';
import { OVERFLOW_ELLIPSIS } from 'font';
import { NEUTRAL_1_COLOUR, NEUTRAL_4_COLOUR } from 'theme';

import { THUMBNAIL_RADIUS, UNTITLED_BLOCK_NAME, UNTITLED_CONTENT_NAME } from 'app/modules/build-dragdrop/Builder/const';
import { useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';

import { ShowMore, CollectionTitle } from 'app/modules/build-dragdrop/Builder/mockup/components';
import { useAppCollectionContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { useAppTheme, useCappedCollectionItems } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { useAppProperties } from '../../../../../../../hooks';
import { isItemClickable } from 'app/modules/build-dragdrop/Builder/util';

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Item = styled.div`
  width: calc(50% - 8px);
  height: fit-content;
  margin-bottom: 16px;
  position: relative;
`;

/* Thumbnails */

interface GridItemProps {
  $square?: boolean;
  $overlay?: boolean;
  $color: string;
  $secondaryElevationHEX: string;
  $hideTitle: boolean;
}

const getShapeStyles = (props: GridItemProps) => {
  if (props.$square) {
    if (props.$hideTitle) {
      return css`
        border-radius: ${THUMBNAIL_RADIUS};
        aspect-ratio: 1;
        overflow: hidden;
      `;
    }
    return css`
      border-radius: ${THUMBNAIL_RADIUS} ${THUMBNAIL_RADIUS} 0 0;
      aspect-ratio: 1;
      overflow: hidden;
    `;
  }
  return css`
    border-radius: ${THUMBNAIL_RADIUS};
    aspect-ratio: 16/9;
  `;
};

// This container will reshape based on the TemplateId
// Square Thumbnails - Square shape, shows the center of the image
// Regular Thumbnails - Rectangular 16:9,m full image
const ThumbnailContainer = styled.div<GridItemProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  ${(props) => getShapeStyles(props)};
`;

const StyledThumbnail = styled(ContentThumbnail)`
  width: inherit;
  height: 100%;
  overflow: hidden;
  cursor: ${usePointerOnClick};
`;
const DummyThumbnail = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${NEUTRAL_4_COLOUR};
  border-radius: ${THUMBNAIL_RADIUS};
`;

const TWO_LINE_TITLE = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;

/* Titles */
const getTitleStyle = ({ $overlay, $hideTitle, $secondaryElevationHEX, $square, $color }: GridItemProps) => {
  if ($hideTitle) {
    return css`
      display: none;
    `;
  }
  if ($overlay) {
    return css`
      display: flex;
      align-items: center;
      width: 100%;
      height: 25px;
      padding: 0 8px;
      ${OVERFLOW_ELLIPSIS};
      color: ${NEUTRAL_1_COLOUR};
    `;
  }
  if ($square) {
    return css`
      // Always space two lines of text
      height: 46px;
      color: ${$color};
      box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
      background-color: ${$secondaryElevationHEX};
      border-radius: 0 0 6px 6px;

      // Prevents overflowing text from eating into our padding
      border: 8px transparent solid;
      ${TWO_LINE_TITLE};
    `;
  }
  return css`
    // Always space two lines of text
    ${TWO_LINE_TITLE};
    min-height: 30px;
    max-height: 30px;
    margin-top: 6px;
    color: ${$color};
  `;
};

const ItemText = styled.div<GridItemProps>`
  max-width: 100%;
  ${APP_FONT_12PX_MEDIUM};

  // Manage differences between different template styles
  ${(props) => getTitleStyle(props)};
`;
const ItemOverlay = styled.div`
  position: absolute;
  bottom: 0;
  backdrop-filter: blur(4px);
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: ${THUMBNAIL_RADIUS};
  width: 100%;
  max-width: 100%;
`;

const ItemTitle = ({ title, ...props }: GridItemProps & { title?: string }) => {
  const { $overlay } = props;
  const actualTitle = title ?? UNTITLED_CONTENT_NAME;
  return $overlay ? (
    <ItemOverlay>
      <ItemText {...props}>{actualTitle}</ItemText>
    </ItemOverlay>
  ) : (
    <ItemText {...props}>{actualTitle}</ItemText>
  );
};

const DummyItem = (props: GridItemProps) => {
  return (
    <Item>
      <ThumbnailContainer {...props}>
        <DummyThumbnail />
      </ThumbnailContainer>
      <ItemTitle {...props} />
    </Item>
  );
};

/* Expected template ids
   26 - Titles are overlaid
   42 - Titles are underneath the thumbnail
   13, 28 - Square Tiles
*/
export const CollectionGridView = () => {
  const { getContentForItem } = useContent();
  const { collection, templateId, onItemClick } = useAppCollectionContext();
  const { items, showMore, canShowMore, showMoreApplies } = useCappedCollectionItems(collection);
  const { getDesignProperty } = useAppTheme();
  const { data: appProperties } = useAppProperties();

  // A grid of square items
  const $square = templateId === 13 || templateId === 28;
  // Titles are displayed on top of thumbnails
  const $overlay = templateId === 26;
  const $hideTitle = templateId === 13;

  const $secondaryElevationHEX = getDesignProperty('primaryElevationHEX') as string;
  const $color = getDesignProperty('primaryItemHEX') as string;
  const gridItemProps = { $square, $overlay, $hideTitle, $secondaryElevationHEX, $color };

  return (
    <Container>
      <CollectionTitle title={collection ? getCollectionName(collection) : UNTITLED_BLOCK_NAME} />
      {items?.length
        ? items?.map((item) => {
            const content = getContentForItem(item);
            if (!content || isSectionHeader(content)) {
              return null;
            }

            return (
              <Item key={`${item.TabItemId}|${item.Position}`}>
                <ThumbnailContainer {...gridItemProps}>
                  <StyledThumbnail
                    thumbnail={content}
                    onClick={onItemClick && isItemClickable(content, item) ? () => onItemClick(item) : undefined}
                    overlayText={$square ? getContentOverlay(content, appProperties) : undefined}
                    showPreviewTag={contentHasPreviewVideo(content)}
                  />
                </ThumbnailContainer>

                <ItemTitle {...gridItemProps} title={getContentName(content, item.Type)} />
              </Item>
            );
          })
        : [1, 2, 3, 4].map((_, idx) => <DummyItem key={idx} {...gridItemProps} />)}
      {showMoreApplies && canShowMore && <ShowMore onClick={showMore} />}
    </Container>
  );
};
