import styled from 'styled-components';

import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';

import { VIDAPP_HELP_URL, listCollections, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { DANGER_COLOUR, PRIMARY_ORANGE_COLOUR } from 'theme';
import { useSecureAppProperties } from 'hooks';
import { SettingsFormItem, SettingsTextInput } from 'components';
import { useDataSource } from 'providers';
import { CheckIcon } from 'icons';

import { getButtonText, getButtonType } from 'app/modules/self-onboarding/OnboardingWizard/util';

import { SplitPanel, StepPanel } from '../Panels';
import { BackButton, Footer, NeedHelp } from '../Common';
import { BlackLink, FlexRow, InstructionText, OrangeLink, ValidateButton, WrappedDot, Wrapper } from './Components';
import { useValidate } from '../../hooks';
import { FONT_12PX_SEMIBOLD } from '../../font';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

const { useForm } = Form;

const RightFooter = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: end;
`;
const FooterButtons = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const RightBody = styled.div`
  padding-top: 60px;
`;

const ErrorText = styled.div`
  width: 100%;
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 40px;

  color: ${DANGER_COLOUR};
  ${FONT_12PX_SEMIBOLD};
`;

export const VimeoOTTValidateStep = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const validate = useValidate();

  const appBeingEdited = useAppBeingEdited();
  const dataSource = useDataSource();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { data: properties } = useSecureAppProperties();
  const [form] = useForm<{ apiKey: string }>();
  const { navigateForward } = useWizardNavigation();

  useEffect(() => {
    if (properties?.VHXAPI) {
      form.setFieldsValue({ apiKey: properties.VHXAPI });
      setIsReady(true);
    }
  }, [properties]);

  const handleValidate = useCallback(() => {
    validate.mutate(
      { APIKey: form.getFieldValue('apiKey') },
      {
        onSuccess: async () => {
          const prefetch = queryClient.prefetchQuery(['list_collections', appBeingEdited, dataSource], () =>
            listCollections(client, appBeingEdited, dataSource, 100),
          );

          setTimeout(async () => {
            await prefetch;
            navigateForward();
          }, 2000);
        },
      },
    );
  }, [validate, form, queryClient, appBeingEdited, dataSource, client, history]);

  return (
    <SplitPanel
      leftContent={
        <>
          <StepPanel
            header={
              <>
                Create a new <br />
                API key.
              </>
            }
            icon={<img src={`${BUILDER_IMAGES_DOMAIN}/onboarding/wizard_icons/key.webp`} alt="key icon" />}
          >
            <Wrapper>
              <FlexRow>
                <WrappedDot>1</WrappedDot>
                <InstructionText>
                  Sign in to your{' '}
                  <OrangeLink target="_blank" rel="noreferrer" href="https://vhx.tv/admin/platforms/apikeys">
                    Vimeo OTT
                  </OrangeLink>
                </InstructionText>
              </FlexRow>
              <FlexRow>
                <WrappedDot>2</WrappedDot>
                <InstructionText>{"Enter the Application Name 'VidApp' and then click 'Create Key'"}</InstructionText>
              </FlexRow>
              <FlexRow>
                <WrappedDot>3</WrappedDot>
                <InstructionText> Paste the new API key to the right 👉🏾</InstructionText>
              </FlexRow>
            </Wrapper>
          </StepPanel>
          <Footer>
            <NeedHelp
              $color={PRIMARY_ORANGE_COLOUR}
              overrideText="Vimeo OTT Help Article - Generate an API key"
              overrideUrl="https://vimeoott.zendesk.com/hc/en-us/articles/360022896454-Generate-an-API-key"
            />
          </Footer>
        </>
      }
      rightContent={
        <>
          <RightBody>
            <Form
              form={form}
              onValuesChange={(values) => {
                setIsReady(!!values.apiKey);
              }}
            >
              <SettingsFormItem name="apiKey">
                <SettingsTextInput
                  label="API Key"
                  placeholder="Enter your new API key"
                  onChange={(e) => {
                    form.setFieldsValue({ apiKey: e.target.value.trim() });
                  }}
                />
              </SettingsFormItem>
            </Form>
          </RightBody>

          <RightFooter>
            {validate.error && (
              <ErrorText>
                Something went wrong. Please try again or{' '}
                <BlackLink target="_blank" rel="noreferrer" href={VIDAPP_HELP_URL}>
                  Get in Touch.
                </BlackLink>
              </ErrorText>
            )}
            <FooterButtons>
              <BackButton />
              <ValidateButton
                onClick={handleValidate}
                loading={validate.isLoading}
                disabled={!isReady || validate.isSuccess}
                buttonType={getButtonType(validate.status)}
                icon={validate.isSuccess && <CheckIcon />}
              >
                {getButtonText(validate.status)}
              </ValidateButton>
            </FooterButtons>
          </RightFooter>
        </>
      }
    />
  );
};
