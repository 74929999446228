import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CustomButton, Modal, ModalBodyNavigationGroup, ModalCollectionOptions, ModalVideoOptions } from 'components';
import { BuilderCollection, BuilderVideo, useContent, useSaveContext } from 'providers';
import { FONT_16PX_MEDIUM } from 'font';
import { INPUT_BORDER_RADIUS, NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { getDataSourceDisplayName } from 'utils';
import { DEFAULT_COLLECTION_ITEM, ITEM_TYPE_COLLECTION, ITEM_TYPE_VIDEO } from 'api';
import { useAppBeingEdited } from 'app-context';

import { ContentPreview, FileView, PostView, TextImageView, WebsiteView } from './components';
import { useModalContext } from './providers/modal-provider';
import { useCreateItem } from './hooks/useCreateItem';
import { useModalOptions } from './hooks/useModalOptions';

const ModalHeading = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_9_COLOUR};
  padding: 20px;
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
`;
const ModalBody = styled.div`
  display: flex;
  color: ${NEUTRAL_10_COLOUR};
  height: 516px;
`;
const ModalActions = styled.div`
  display: flex;
  justify-content: right;
  padding: 20px 24px;
  border-top: 1px solid ${NEUTRAL_5_COLOUR};
`;

const CancelButton = styled(CustomButton)`
  &&&& {
    margin-right: 8px;
  }
`;

const SaveButton = styled(CustomButton)`
  &&&& {
    font-weight: 500;
    border-radius: ${INPUT_BORDER_RADIUS};
  }
`;

const BodyNavigation = styled.div`
  padding: 20px;
  width: 186px;
  height: 100%;
  border-right: 1px solid ${NEUTRAL_5_COLOUR};
  overflow-y: auto;
`;

const LeftPanel = styled.div`
  width: 379px;
  height: 100%;
`;

export interface NavigationOption {
  Title: string;
  Icon: ReactNode;
  ViewType: 'collections' | 'videos' | 'website' | 'text' | 'post' | 'file'; // Which view to show
  /* Collections or Videos for collections/videos array */
  CollectionOptions?: BuilderCollection[];
  VideoOptions?: BuilderVideo[];
}

type ActiveContext = NavigationOption & { key: string };

interface CustomiseContentProps {
  // The collection the items are being customised in
  collection: BuilderCollection;
  // Control of the visibility of this modal is injected
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const CustomiseContentV2 = ({ collection, modalVisible, setModalVisible }: CustomiseContentProps) => {
  const appId = useAppBeingEdited();
  const { setCollectionItems } = useContent();
  const { setCollectionItemsToSave, getTempId } = useSaveContext();
  const { confirmDisabled, runPendingSaves, addItem, editedItems } = useModalContext();
  const { createWebsite, createTextImage, createFile, createPost } = useCreateItem(collection.TabId);

  // This determines which page is rendered, the key is the identifier that knows which page is highlighted
  const [activeContext, setActiveContext] = useState<ActiveContext>();
  // This determines which collections/videos are available to add in this modal
  const { navigationOptions } = useModalOptions({ collectionId: collection.TabId });

  // The content that already exists in the current collection
  const includedVideos = useMemo(() => {
    return editedItems.filter((item) => item.Type === ITEM_TYPE_VIDEO).map((item) => item.ChildId);
  }, [editedItems]);
  const includedCollections = useMemo(() => {
    return editedItems.filter((item) => item.Type === ITEM_TYPE_COLLECTION).map((item) => item.ChildId);
  }, [editedItems]);

  // Save the working state of the collection
  const handleSave = useCallback(async () => {
    // save any pending newly created videos/collections
    runPendingSaves();
    // Update the dragdrop state to the new items
    setCollectionItems(collection.TabId, editedItems);
    setCollectionItemsToSave(collection.TabId); // Set to be saved
    setModalVisible(false);
  }, [editedItems, setModalVisible, runPendingSaves]);

  // Set the default selected page
  useEffect(() => {
    if (Object.entries(navigationOptions).length > 0 && !activeContext) {
      const entries = Object.entries(navigationOptions);
      const [heading, opts] = entries[0];
      setActiveContext({ ...opts[0], key: `${heading}_${0}` });
    }
  }, [navigationOptions, activeContext, setActiveContext]);

  const videoOptionsView = (availableVideos: BuilderVideo[]) => (
    <ModalVideoOptions
      availableVideos={availableVideos}
      includedVideos={includedVideos}
      onVideoClick={(videoId: BuilderVideo['VideoId']) => {
        addItem({
          ...DEFAULT_COLLECTION_ITEM,
          AppId: appId,
          TabId: collection.TabId,
          ChildId: videoId,
          Type: ITEM_TYPE_VIDEO,
          SubType: ITEM_TYPE_VIDEO,
          Position: editedItems.length + 1,
          TabItemId: `TempTabItem${getTempId()}`,
        });
      }}
    />
  );

  const collectionOptionsView = (availableCollections: BuilderCollection[]) => (
    <ModalCollectionOptions
      availableCollections={availableCollections}
      includedCollections={includedCollections}
      onCollectionClick={(id) => {
        addItem({
          ...DEFAULT_COLLECTION_ITEM,
          AppId: appId,
          TabId: collection.TabId,
          ChildId: id,
          Type: ITEM_TYPE_COLLECTION,
          SubType: ITEM_TYPE_COLLECTION,
          Position: editedItems.length + 1,
          TabItemId: `TempTabItem${getTempId()}`,
        });
      }}
    />
  );

  const switchViews = (activeContext: ActiveContext) => {
    switch (activeContext.ViewType) {
      case 'collections':
        return collectionOptionsView(activeContext?.CollectionOptions ?? []);
      case 'videos':
        return videoOptionsView(activeContext?.VideoOptions ?? []);
      case 'website':
        return <WebsiteView onCreate={createWebsite} />;
      case 'text':
        return <TextImageView onCreate={createTextImage} />;
      case 'file':
        return <FileView onCreate={createFile} />;
      case 'post':
        return <PostView onCreate={createPost} />;
    }
    return null;
  };

  return (
    <Modal open={modalVisible} $height="100%" $contentWidth="100%" $padding="0" forceRender>
      <ModalHeading>Add</ModalHeading>
      <ModalBody>
        <BodyNavigation>
          {Object.entries(navigationOptions).map(([heading, options]) => {
            const formattedHeading = getDataSourceDisplayName(heading);
            // Details of Nav
            const navOptions = options?.map((opt, idx) => ({
              name: opt.Title,
              icon: opt.Icon,
              id: `${heading}_${idx}`,
            }));
            return (
              <ModalBodyNavigationGroup
                key={heading}
                heading={formattedHeading}
                options={navOptions}
                activeKey={activeContext?.key}
                onOptionClick={(key) => setActiveContext({ ...options[parseInt(key.split('_')[1])], key })}
              />
            );
          })}
        </BodyNavigation>
        <LeftPanel>{activeContext && switchViews(activeContext)}</LeftPanel>
        <ContentPreview items={editedItems} tabId={collection.TabId.toString()} />
      </ModalBody>
      <ModalActions>
        <CancelButton
          medium
          tertiary
          disabled={confirmDisabled}
          onClick={() => {
            setModalVisible(false);
          }}
        >
          Cancel
        </CancelButton>
        <SaveButton onClick={handleSave} disabled={confirmDisabled} medium>
          Confirm
        </SaveButton>
      </ModalActions>
    </Modal>
  );
};
