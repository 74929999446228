import { useCallback } from 'react';
import styled from 'styled-components';

import { SettingsNumberInput } from 'components';
import { useContent, useSaveContext } from 'providers';
import { FONT_12PX_REGULAR } from 'font';
import { NEUTRAL_7_COLOUR } from 'theme';
import { useAppProperties } from 'hooks';
import { StopwatchIcon } from 'icons';

const StyledStopwatchIcon = styled(StopwatchIcon)`
  font-size: 20px;
  margin-left: 6px;
`;

const TypeLabel = styled.div`
  margin: 0 8px;
`;

const StyledNumberInput = styled(SettingsNumberInput)`
  &&&& {
    width: 62px;
    height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    ${FONT_12PX_REGULAR};

    .ant-input-number {
      margin: 1px 7px 0;
      height: 26px;
    }
  }

  // Turn Prefix into a suffix
  &&&& .ant-input-number-prefix {
    position: absolute;
    top: 5px;
    left: 33px;
    z-index: 1;
    color: ${NEUTRAL_7_COLOUR};
  }
`;

interface RestRowConfigProps {
  videoId: string | number;
}

export const RestRowConfig = ({ videoId }: RestRowConfigProps) => {
  const { videos, setVideoValue } = useContent();
  const { setVideoValueToSave } = useSaveContext();
  const { data: appProperties } = useAppProperties();

  const defaultDuration = appProperties?.InterCircuitRestDefault ?? '120';

  const rest = videos[videoId];

  const handleChange = useCallback(
    (value: number) => {
      if (!!value) {
        setVideoValue(videoId, 'DurationSeconds', value.toString());
        setVideoValueToSave(videoId, 'DurationSeconds', value.toString());
      }
    },
    [setVideoValue, setVideoValueToSave],
  );

  return (
    <>
      <StyledStopwatchIcon />
      <TypeLabel>Rest</TypeLabel>
      <StyledNumberInput
        autoComplete="off"
        value={rest.DurationSeconds}
        placeholder={defaultDuration}
        onChange={(value) => handleChange(value as number)}
        onFocus={(event) => event.target.select()}
        min={1}
        max={999}
        maxLength={3}
        prefix="sec"
      />
    </>
  );
};
