import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppBeingEdited } from 'app-context';
import { useAxiosInstance } from 'api';
import { createCommunityTab } from 'api/community';
import { useRecordAction } from 'hooks';

export const useCreateCommunityTab = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError>(
    () => {
      return createCommunityTab(client, appId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['collections', appId]);
        recordAction.mutate({ action: 'Create Community Tab' });
      },
    },
  );
};
