import styled from 'styled-components';
import { FONT_16PX_REGULAR } from 'font';
import { useAppCollectionContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { COLLECTION_PROPERTY_HTMLPAGE_DESC } from 'api';
import { useMemo } from 'react';
import DOMPurify from 'dompurify';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';

const About = styled.div<{ $color: string }>`
  ${FONT_16PX_REGULAR};
  color: ${({ $color }) => $color};
  cursor: default;
`;
export const HtmlPageView = () => {
  const { collection } = useAppCollectionContext();
  const { getDesignProperty } = useAppTheme();
  const $color = getDesignProperty('primaryItemHEX') as string;

  const purifiedDesc = useMemo(() => {
    const desc = (collection?.Properties ?? {})[COLLECTION_PROPERTY_HTMLPAGE_DESC];
    return DOMPurify.sanitize(desc);
  }, [collection]);

  return <About dangerouslySetInnerHTML={{ __html: purifiedDesc }} $color={$color} />;
};
