import {
  CSSProperties,
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';
import { animated, useSpring } from '@react-spring/web';
import styled from 'styled-components';

import { useContentPositionContext, useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { PenIcon, TrashIcon } from 'icons';
import { NAVBAR_HEIGHT, SUBNAV_HEIGHT } from 'app/modules/build-dragdrop/Builder/mockup/const';
import { DANGER_COLOUR, NEUTRAL_1_COLOUR, NEUTRAL_4_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { updateCollectionPositions } from 'app/modules/build-dragdrop/Builder/util';
import { BuilderCollection, BuilderCollectionItem, useContent, useSaveContext } from 'providers';
import { scrollToContent } from 'app/modules/build-dragdrop/Builder/mockup/util';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { SOURCE_TYPE_BUILDMYAPP, SOURCE_VIDAPP } from 'api';
import { ConfirmModal } from 'components';
import { createPortal } from 'react-dom';

// Relative position to the top of the collection
// Minimum TOP absolute position;
const LOWER_BOUND_TOP = NAVBAR_HEIGHT;
const LOWER_BOUND_SUBNAV_TOP = LOWER_BOUND_TOP + SUBNAV_HEIGHT;

const MenuContainer = styled.div`
  position: absolute !important;
  top: 0;
  right: 33px;
  cursor: pointer;

  background-color: ${NEUTRAL_1_COLOUR};
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
  padding: 5px 6px;
`;

const StyledActionIcon = styled.div<{ danger?: boolean }>`
  font-size: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ danger }) => (danger ? DANGER_COLOUR : NEUTRAL_9_COLOUR)};
  border-radius: 4px;
  margin: 1px 0;
  cursor: pointer;

  :hover {
    background-color: ${NEUTRAL_4_COLOUR};
  }

  :active {
    background-color: ${NEUTRAL_5_COLOUR};
  }
`;

const HoverDiv = styled.div<{ height?: CSSProperties['height'] }>`
  width: 570px;
  height: ${({ height }) => (height ? `${height}px` : undefined)};
  position: absolute;
  left: -80px;
  z-index: -1;
  cursor: auto;
`;

interface PencilProps {
  forceVisible?: boolean;
  onClick: MouseEventHandler;
  item: BuilderCollectionItem;
  collection?: BuilderCollection;
  idx: number;
  hideDelete?: boolean;
  setIsHovering?: Dispatch<SetStateAction<boolean>>;
}

export const EditCollectionMenu = ({ onClick, idx, item, hideDelete, setIsHovering }: PencilProps) => {
  // Positions of the Phone screen, relevant collection and the handle
  const { isScrolling, contentRect, screenRect, contentRef, isMouseOver, updateRects, selected } =
    useContentPositionContext();
  const { currentPage } = useMockupContext();
  const { setCollectionItemsToSave, setCollectionToDelete } = useSaveContext();
  const { collections, setCollections, deleteCollection } = useContent();
  const { visiblePageId, setDrawerContext } = useBuilderContext();

  // On the outer hover zone
  const [isMouseOverOutside, setIsMouseOverOutside] = useState<boolean>(false);
  const [top, setTop] = useState<number>(0);
  const [height, setHeight] = useState<number>(1);
  const [modalVisible, setModalVisible] = useState(false);

  useLayoutEffect(() => {
    const { y, height } = contentRect || {};
    const { y: screenY } = screenRect || {};

    if (screenY && y) {
      const diff = y - screenY; // Distance between top of screen and top of handle
      setTop(diff);
    }
    if (height) {
      setHeight(height - 2);
    }
  }, [contentRef, screenRect, contentRect, setTop, setHeight]);

  const tooHigh = currentPage?.subItemId ? top < LOWER_BOUND_SUBNAV_TOP : top < LOWER_BOUND_TOP;
  const mouseOverEither = isMouseOver || isMouseOverOutside;
  const isVisible = !isScrolling && mouseOverEither && !tooHigh;
  const fadeIn = useSpring({
    opacity: isVisible ? 1 : 0,
    config: { tension: isVisible ? 100 : 500 },
  });

  const handleMouseEnter = useCallback(() => {
    updateRects();
    setIsMouseOverOutside(true);
    if (setIsHovering) {
      setIsHovering(true);
    }
  }, [updateRects, setIsMouseOverOutside, setIsHovering]);
  const handleMouseLeave = useCallback(() => {
    setIsMouseOverOutside(false);
    if (setIsHovering) {
      setIsHovering(false);
    }
  }, [setIsMouseOverOutside, setIsHovering]);

  const handleDelete = () => {
    // For sections we delete from the visible page, for subtabs we delete from the visible tab
    const parentCollectionId = visiblePageId;
    if (parentCollectionId) {
      const newCollections = { ...collections };
      const newCollection = newCollections[parentCollectionId];

      // Delete the Section (CollectionItem)
      const idx = newCollection.Items.findIndex((i) => i.TabItemId === item.TabItemId);
      if (idx > -1) {
        newCollection.Items.splice(idx, 1);
        updateCollectionPositions(newCollection);
        setCollections(newCollections);
        setCollectionItemsToSave(parentCollectionId);
      } else {
        console.error('Unable to delete item with details', { tabId: visiblePageId, tabItemId: item.TabItemId });
      }

      if (item.ChildId) {
        const childCollection = collections[item.ChildId];
        // Delete the collection as well if it was a BuildMyApp collection
        if (childCollection.DataSource === SOURCE_VIDAPP && childCollection.SourceType === SOURCE_TYPE_BUILDMYAPP) {
          setCollectionToDelete(item.ChildId);
          deleteCollection(item.ChildId);
        }
      }
      setDrawerContext((prev) => {
        if (prev?.tabItemId === item.TabItemId) {
          // If this item is being deleted remove the selection
          return undefined;
        }
        return prev;
      });
    }
  };

  // If selected, when the index is updated scroll to this collection
  useLayoutEffect(() => {
    if (selected) {
      scrollToContent(contentRef);
    }
  }, [idx]);

  return (
    <>
      <HoverDiv
        as={animated.div}
        style={{ top, ...fadeIn }}
        height={height}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MenuContainer as={animated.div} style={{ ...fadeIn }}>
          <StyledActionIcon onClick={onClick}>
            <PenIcon />
          </StyledActionIcon>
          {!hideDelete && (
            <StyledActionIcon danger onClick={() => setModalVisible(true)}>
              <TrashIcon />
            </StyledActionIcon>
          )}
        </MenuContainer>
      </HoverDiv>
      {createPortal(
        <ConfirmModal
          heading={`Delete Section`}
          type="warning"
          content="Are you sure you want to delete this section? This cannot be undone."
          danger
          primaryText="Delete"
          onConfirm={handleDelete}
          open={modalVisible}
          setOpen={setModalVisible}
        />,
        document.getElementById('modal-portal') as HTMLElement,
      )}
    </>
  );
};
