// convert array of strings to a string literal
import { AxiosInstance } from 'axios';
import {
  builderAPI,
  ContentDescription,
  ContentFlags,
  ContentThumbnails,
  IncludedInAppData,
  Resource,
  SOURCE_CUSTOMAPI,
  SOURCE_JWPLAYER,
  SOURCE_KAJABI,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
  SOURCE_YOUTUBE,
  VIDEO_TYPE_VIDEO,
} from 'api';
import { VIDEO_TYPE_POST } from './const';

const VIDEO_DATA_SOURCES = [
  SOURCE_VIDAPP,
  SOURCE_USCREEN,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
  SOURCE_KAJABI,
  SOURCE_VHX,
  SOURCE_YOUTUBE,
  SOURCE_JWPLAYER,
  SOURCE_CUSTOMAPI,
] as const;

// type representing a union of the string array above e.g) a | b | c
type VideoDataSource = (typeof VIDEO_DATA_SOURCES)[number];

export interface GetVideosResponse {
  videos: Video[];
  video_by_id: Record<number, Video>;
  video_by_source_id: Record<string, Video>;
}

export interface Video extends ContentThumbnails, ContentFlags, ContentDescription {
  AppId: string;
  DataSource: VideoDataSource;
  DurationSeconds: string;
  OriginalFilename: string;
  PreviewVideoId?: string;
  InstructionalVideoId?: string;
  SourceId: string;
  SourceTitle: string;
  Tag?: string;
  Resources?: Resource[];
  Title: string;
  Type: string;
  VideoId: number;
  ProductId?: string;
  Properties?: Record<string, string>;
  Files?: VideoFile[];
  CreatedAt?: string;
}

export const DEFAULT_FILE = {
  ThumbnailSource: '',
  DataSource: SOURCE_VIDAPP as VideoDataSource,
  IncludedInAppData: 0 as IncludedInAppData,
  DurationSeconds: '',
  SourceDescription: '',
  SourceThumbnailSource: '',
  SourceTitle: '',
  Type: VIDEO_TYPE_VIDEO,
};
export const DEFAULT_POST = {
  Title: 'Untitled',
  ThumbnailSource: '',
  DataSource: SOURCE_VIDAPP as VideoDataSource,
  IncludedInAppData: 0 as IncludedInAppData,
  DurationSeconds: '',
  OriginalFilename: 'Custom Post',
  SourceDescription: '',
  SourceThumbnailSource: '',
  SourceTitle: '',
  Type: VIDEO_TYPE_POST,
};

export interface VideoOption {
  title: string;
  videoId: number;
  sourceId: string | number;
}

export interface VideoToUpdate {
  videoId: number;
  properties: Record<string, string | number | Partial<Resource>[]>;
}

export type VideoToSave = Partial<Omit<Video, 'Resources'>> & { Resources: Partial<Resource>[] };

export interface VideoPropertiesToSave {
  videoId: number;
  properties: VideoPropertyToSave[];
}

interface VideoPropertyToSave {
  Name: string;
  Value: string;
}

export interface VideoFile {
  URL: string;
  Type: string;
  Size: number;
  SizeFormatted?: string;
}

export const getVideos = (client: AxiosInstance, appBeingEdited: string) => {
  return client
    .get(`${builderAPI.contentApiURL}/data/${appBeingEdited}/builder/videos?update_cache=false&no_emoji=false`, {
      headers: {
        accept: 'application/json',
      },
    })
    .then(({ data }) => {
      const VIDEO_BY_ID: Record<number, Video> = {};
      const VIDEO_BY_SOURCE_ID: Record<string, Video> = {};
      for (const video of data) {
        if (typeof video.VideoId === 'number') {
          VIDEO_BY_ID[video.VideoId] = video;
        }
        VIDEO_BY_SOURCE_ID[video.SourceId] = video;
      }
      return { videos: data, video_by_id: VIDEO_BY_ID, video_by_source_id: VIDEO_BY_SOURCE_ID };
    });
};

export const createVideo = async (client: AxiosInstance, appId: string, video: VideoToSave, refresh: boolean) => {
  return client.post(`/content/video/${appId}?refresh=${refresh}`, JSON.stringify(video));
};

export const updateVideo = async (
  client: AxiosInstance,
  appId: string,
  videoToUpdate: VideoToUpdate,
  refresh: boolean,
) => {
  return client.put(
    `/content/video/${appId}/${videoToUpdate.videoId}?refresh=${refresh}`,
    JSON.stringify(videoToUpdate.properties),
  );
};

export const saveVideoProperties = async (
  client: AxiosInstance,
  appId: string,
  videoPropertiesToSave: VideoPropertiesToSave,
  refresh: boolean,
) => {
  return client.put(
    `/content/video/${appId}/${videoPropertiesToSave.videoId}/properties?refresh=${refresh}`,
    JSON.stringify(videoPropertiesToSave.properties),
  );
};

export const deleteVideo = async (
  client: AxiosInstance,
  appId: string,
  videoId: Video['VideoId'],
  refresh: boolean,
) => {
  return client.delete(`/content/video/${appId}/${videoId}?refresh=${refresh}`);
};

export const getVideoFiles = async (
  client: AxiosInstance,
  appId: string,
  videoSourceId: Video['SourceId'],
  dataSource: Video['DataSource'],
) => {
  return client
    .post<VideoFile[]>(`/tunnel/${appId}`, {
      HttpPath: `app/${appId}/videos/${videoSourceId}/files/v2?dataSource=${dataSource}`,
      HttpMethod: 'GET',
      HttpPayload: {},
      ServiceName: 'app_api',
    })
    .then(({ data }) => data);
};
