import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { deleteFilter, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useDeleteFilter = (invalidateQuery = true) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, number>(
    (filterId) => {
      return deleteFilter(client, appId, filterId);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['filters', appId]);
          await queryClient.invalidateQueries(['tagged_collections', appId]);
          await queryClient.invalidateQueries(['tagged_videos', appId]);
        }
        recordAction.mutate({ action: 'Delete Filters', detail: { filterId: payload } });
      },
    },
  );
};
