import styled from 'styled-components';
import { ReactNode } from 'react';

const Container = styled.div<ContentContainerProps>`
  width: ${({ $width }) => $width || '100%'};
  max-width: ${({ $maxWidth }) => $maxWidth};
  margin: ${({ $margin }) => $margin || '0 0 28px 0'};
`;

export interface ContentContainerProps {
  children?: ReactNode;
  $margin?: string;
  $width?: string;
  $maxWidth?: string;
}

export const ContentContainer = ({ children, ...props }: ContentContainerProps) => (
  <Container {...props}>{children}</Container>
);
