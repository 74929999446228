export interface CustomColor {
  $color: string;
}

export interface AnotherCustomColor {
  $anotherColor: string;
}

export interface CustomMargin {
  $marginTop?: string;
  $marginBottom?: string;
  $marginLeft?: string;
  $marginRight?: string;
}

export const useCustomColor = ({ $color }: CustomColor) => $color;
export const useAnotherCustomColor = ({ $anotherColor }: AnotherCustomColor) => $anotherColor;
export const useCustomMarginTop = ({ $marginTop }: CustomMargin) => $marginTop;
export const useCustomMarginBottom = ({ $marginBottom }: CustomMargin) => $marginBottom;
export const useCustomMarginLeft = ({ $marginLeft }: CustomMargin) => $marginLeft;
export const useCustomMarginRight = ({ $marginRight }: CustomMargin) => $marginRight;

export interface CustomActiveStyle {
  $active?: boolean;
}

export const setCustomActiveStyle = (activeValue: string, defaultValue: string) => {
  return ({ $active }: CustomActiveStyle) => ($active ? activeValue : defaultValue);
};

export interface OnClick {
  onClick?: () => void;
}

export const usePointerOnClick = ({ onClick }: OnClick) => (onClick ? 'pointer' : 'unset');
