import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers/mockup-provider';
import { useContent } from 'providers';
import { MIXED_TEMPLATE, SOURCE_VIDAPP } from 'api';
import { DRAWER_TYPE_BMA, TOOLBOX_PAGE_TREE } from 'app/modules/build-dragdrop/Builder/const';

interface DrawerContext {
  itemId?: string;
  tabItemId?: string;
  drawerType?: 'section' | 'linkSubTab' | 'add' | 'build-my-app' | 'sectionHeader';
  showItemsView?: boolean; // Override to just show collection items
  showDisplayView?: boolean; // Override to just show display types
  showCollectionDisplayView?: boolean; // Override to just show display types for a collection (instead of a collection item)
  hideDrawer?: boolean; // Override for unsupported drawer types, e.g) Video
  index?: number; // Index to insert to for add panel
}

interface ContextValue {
  // Determine what is displayed in the drawer
  drawerContext?: DrawerContext;
  setDrawerContext: Dispatch<SetStateAction<DrawerContext | undefined>>;
  toolboxPage: string;
  setToolboxPage: (page: string) => void;

  // Utilities
  setSectionDrawer: (tabItemId: string, itemId?: string) => void;
  visiblePageId?: number | string; // What mixed page are selected sections from
  isDrawerOpen: boolean; // The drawer is visible
  selectedItem?: { tabItemId?: string; itemId?: string };
  isAddPanelEnabled: boolean;
}

const defaultFunction = () => {
  console.warn('Unexpected function call builder-provider');
};

const BuilderContext = createContext<ContextValue>({
  drawerContext: undefined,
  setDrawerContext: defaultFunction,
  toolboxPage: TOOLBOX_PAGE_TREE,
  setToolboxPage: defaultFunction,
  isDrawerOpen: false,
  isAddPanelEnabled: false,
  setSectionDrawer: defaultFunction,
});

interface ProviderProps {
  children: ReactNode[] | ReactNode;
}

const BuilderContextProvider = ({ children }: ProviderProps) => {
  /* Modifiable state of what the DragDropBuilder is focussed on  */
  const { currentPage, currentTabId } = useMockupContext();
  const { collections } = useContent();

  // What toolbox screen is shown on the left side of the builder
  const [toolboxPage, setToolboxPage] = useState<string>(TOOLBOX_PAGE_TREE);

  // What item is selected in the right drawer, undefined will close the drawer
  const [drawerContext, setDrawerContext] = useState<DrawerContext | undefined>({ drawerType: DRAWER_TYPE_BMA });

  // A derivative of CurrentPage + CurrentTabId
  // The collectionId that has its children rendered as content on the display
  // Used when finding the CollectionItem for something that is selected
  const visiblePageId = useMemo(() => {
    if (currentPage) {
      if (currentPage?.subItemId) {
        return currentPage?.subItemId;
      }
      if (currentPage?.itemId) {
        return currentPage?.itemId;
      }
    }
    return currentTabId;
  }, [currentPage, currentTabId]);

  const isDrawerOpen = !drawerContext?.hideDrawer;

  const selectedItem = useMemo(() => {
    if (drawerContext?.drawerType === DRAWER_TYPE_BMA) {
      return { tabItemId: 'BMA' };
    }
    if (drawerContext?.tabItemId) {
      return { tabItemId: drawerContext.tabItemId.toString() };
    }
    if (currentPage?.subTabItemId) {
      return { tabItemId: currentPage.subTabItemId.toString() };
    }
    if (currentPage?.itemId) {
      return { itemId: currentPage.itemId.toString() };
    }
    return undefined;
  }, [drawerContext, currentPage]);

  // Only usable on mixed pages
  const isAddPanelEnabled = useMemo(() => {
    if (!visiblePageId) {
      return false;
    }
    const visibleCollection = collections[visiblePageId];
    if (visibleCollection) {
      return visibleCollection.TemplateId === MIXED_TEMPLATE && visibleCollection.DataSource === SOURCE_VIDAPP;
    }
    return false;
  }, [visiblePageId, collections]);

  const setSectionDrawer = (tabItemId: string, itemId?: string) => {
    setDrawerContext({ tabItemId, itemId, drawerType: 'section' });
  };

  return (
    <BuilderContext.Provider
      value={{
        drawerContext,
        setDrawerContext,
        toolboxPage,
        setToolboxPage,
        visiblePageId,
        isDrawerOpen,
        selectedItem,
        isAddPanelEnabled,
        setSectionDrawer,
      }}
    >
      {children}
    </BuilderContext.Provider>
  );
};

const useBuilderContext = () => {
  const context = useContext(BuilderContext);
  if (context === undefined) {
    throw new Error('useBuilderContext must be used within an BuilderContextProvider');
  }
  return context;
};

export { BuilderContextProvider, useBuilderContext };
