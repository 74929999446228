import styled from 'styled-components';

import { APP_FONT_12PX_MEDIUM, APP_FONT_16PX_MEDIUM } from 'mockup-font';
import { ITEM_TYPE_VIDEO, Video, VIDEO_DETAILS_RESOURCES } from 'api';
import { getContentName, getContentOverlay, getVideoMinutes } from 'utils';
import { BuilderCollection, BuilderCollectionItem, useContent } from 'providers';
import { useAppProperties } from 'hooks';

import { ContentThumbnail } from '../../components';
import { useMockupContext } from '../providers';
import { useAppTheme } from '../hooks';
import { AppVideoIcon } from 'icons';
import { isItemClickable } from 'app/modules/build-dragdrop/Builder/util';

const ItemsWrapper = styled.div`
  padding: 16px;
`;

const ItemContainer = styled.div<{ onClick?: () => void }>`
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : undefined)};
`;

const StyledThumbnail = styled(ContentThumbnail)`
  width: 142px;
  height: unset;
  margin-right: 16px;
`;
const ItemTitle = styled.div<{ $color: string }>`
  ${APP_FONT_16PX_MEDIUM};
  color: ${({ $color }) => $color};
`;
const ItemSubtitle = styled.div<{ $color: string }>`
  ${APP_FONT_12PX_MEDIUM};
  color: ${({ $color }) => $color};
  margin-top: 8px;
`;

const StyledIcon = styled(AppVideoIcon)`
  font-size: 14px;
  margin-right: 4px;

  svg {
    fill: none;
  }
`;

interface UpNextItemProps {
  item: BuilderCollectionItem;
  onClick?: (item: BuilderCollectionItem) => void;
  showSubtitle?: boolean;
}

export const UpNextItem = ({ item, onClick, showSubtitle }: UpNextItemProps) => {
  const { data: appProperties } = useAppProperties();
  const { getDesignProperty } = useAppTheme();
  const { getContentForItem } = useContent();

  const content = getContentForItem(item) as Video; // Should all be videos
  const $color = getDesignProperty('primaryItemHEX') as string;

  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  if (item.Type !== ITEM_TYPE_VIDEO) {
    return null;
  }

  const videoMinutes = getVideoMinutes(content?.DurationSeconds);
  return content ? (
    <ItemContainer onClick={onClick ? handleClick : undefined}>
      <StyledThumbnail
        thumbnail={content}
        size="small"
        overlayText={!showSubtitle ? getContentOverlay(content, appProperties) : ''}
      />
      <div>
        <ItemTitle $color={$color}>{getContentName(content, item.Type)}</ItemTitle>
        {showSubtitle && videoMinutes && (
          <ItemSubtitle $color={$color}>
            <StyledIcon />
            {videoMinutes}
          </ItemSubtitle>
        )}
      </div>
    </ItemContainer>
  ) : null;
};

interface UpNextProps {
  nextItems: BuilderCollectionItem[];
  parentCollection: BuilderCollection;
}

export const UpNext = ({ nextItems, parentCollection }: UpNextProps) => {
  const { getContentForItem } = useContent();
  const { setCurrentPage, navigationEnabled } = useMockupContext();
  const { data: appProperties } = useAppProperties();

  const handleItemClick = (item: BuilderCollectionItem) => {
    const video = getContentForItem(item) as Video;
    if (video) {
      setCurrentPage(
        {
          itemType: ITEM_TYPE_VIDEO,
          tabItemId: item.TabItemId,
          itemId: item.ChildId,
          parentCollectionId: parentCollection.TabId,
        },
        { skipStack: true },
      );
    }
  };

  return nextItems.length > 0 ? (
    <ItemsWrapper>
      {nextItems.map((item) => {
        const video = getContentForItem(item) as Video;
        return (
          <UpNextItem
            item={item}
            onClick={
              item.Type === ITEM_TYPE_VIDEO && isItemClickable(video, item) && navigationEnabled
                ? handleItemClick
                : undefined
            }
            showSubtitle={appProperties?.ItemDetailsDesign === VIDEO_DETAILS_RESOURCES}
          />
        );
      })}
    </ItemsWrapper>
  ) : null;
};
