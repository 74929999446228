import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChromePicker, ColorChangeHandler, ColorResult } from 'react-color';
import { GetLumaFromHex } from 'utils';
import { SettingsInputWrapper } from 'components';
import { INPUT_STYLES_LARGE, INPUT_HEIGHT_LARGE, NEUTRAL_5_COLOUR, NEUTRAL_10_COLOUR } from 'theme';
import { EditIcon } from 'icons';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';
import { FONT_16PX_REGULAR } from 'font';

interface ColorInputProps {
  $width?: string;
  $margin?: string;
}

const ColorInputContainer = styled.div<ColorInputProps>`
  #react-app && {
    ${INPUT_STYLES_LARGE}
    width: ${({ $width }) => $width || '100%'};
    margin: ${({ $margin }) => $margin || 0};
    position: relative;
    display: flex;
    align-items: center;
    padding: 7px 12px;
    cursor: pointer;
  }
`;

const PickerWrapper = styled.div`
  position: absolute;
  top: ${INPUT_HEIGHT_LARGE};
  right: 0;
  margin-top: 1px;
  z-index: 2;
  .chrome-picker {
    width: 206px !important;
  }
`;

interface ColorPreviewProps {
  color?: string;
  borderColor: string;
}

const ColorPreview = styled.div<ColorPreviewProps>`
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.borderColor};
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

const HexLabel = styled.div`
  ${FONT_16PX_REGULAR};
  color: ${NEUTRAL_10_COLOUR};
  margin-left: 4px;
  text-transform: uppercase;
`;

const StyledEditIcon = styled(EditIcon)`
  margin-left: auto;
`;

interface SettingsColorInputProps {
  label?: string;
  tooltip?: string;
  value?: Partial<ColorResult>;
  width?: string;
  margin?: string;
  onChange: ColorChangeHandler;
}

export const SettingsColorInput = ({ label, tooltip, value, width, margin, onChange }: SettingsColorInputProps) => {
  const [showPicker, setShowPicker] = useState(false);
  const wrapperRef = useRef(null);
  const [previewBorderColor, setPreviewBorderColor] = useState<string>('#ffffff');

  useEffect(() => {
    if (value && value.hex) {
      setPreviewBorderColor(GetLumaFromHex(value.hex) > 230 ? NEUTRAL_5_COLOUR : value.hex); // If color is very light, use dark border color on ColorPreview
    }
  }, [value, setPreviewBorderColor]);

  const handleClickOutside = () => {
    setShowPicker(false);
  };

  const handleClick = () => {
    setShowPicker(true);
  };

  useOutsideAlerter(wrapperRef, handleClickOutside);

  return (
    <SettingsInputWrapper label={label} tooltip={tooltip}>
      <ColorInputContainer $width={width} $margin={margin} onClick={handleClick}>
        <ColorPreview color={value?.hex} borderColor={previewBorderColor} />
        <HexLabel>{value?.hex}</HexLabel>
        <StyledEditIcon />
        {showPicker && (
          <PickerWrapper ref={wrapperRef}>
            <ChromePicker color={value?.hex} onChange={onChange} disableAlpha />
          </PickerWrapper>
        )}
      </ColorInputContainer>
    </SettingsInputWrapper>
  );
};
