import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { NEUTRAL_10_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { FONT_14PX_REGULAR, FONT_14PX_SEMIBOLD, FONT_16PX_MEDIUM } from 'font';
import { ExclamationCircleIcon, XIcon } from 'icons';

import { ProductItem } from 'api';
import { OfferDetailedItem } from './OfferDetailedItem';

const Container = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  overflow-y: auto;
  flex-grow: 1;
  background-color: ${NEUTRAL_3_COLOUR};
`;

const Heading = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
  margin-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
`;
const ItemsScrollView = styled.div`
  overflow-y: auto;
`;

const DeleteIcon = styled(XIcon)`
  color: ${NEUTRAL_10_COLOUR};
  font-size: 16px;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${NEUTRAL_7_COLOUR};
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 33px;
`;
const EmptyTitle = styled.div`
  ${FONT_16PX_MEDIUM};
  margin-top: 8px;
`;
const EmptySubtitle = styled.div`
  ${FONT_14PX_REGULAR};
  margin-top: 4px;
  max-width: 150px;
  overflow: hidden;
`;

interface OfferContentPreviewProps {
  items: ProductItem[];
  setItems: Dispatch<SetStateAction<OfferContentPreviewProps['items']>>;
}

export const OfferContentPreview = ({ items, setItems }: OfferContentPreviewProps) => {
  const handleDeleteItem = useCallback(
    (idx: number) => {
      setItems((currentItems) => {
        const updatedItems = [...currentItems];
        updatedItems.splice(idx, 1);
        return [...updatedItems];
      });
    },
    [setItems],
  );

  const itemsToDisplay = useMemo(() => items.filter((item) => item.Type !== 'all'), [items]);

  return (
    <Container>
      <Heading>Included Content</Heading>
      {itemsToDisplay?.length > 0 ? (
        <ItemsScrollView>
          {itemsToDisplay.map((item, idx) => {
            return (
              <OfferDetailedItem item={item} key={item.ChildId}>
                {item.AddedBy === 'builder' && <DeleteIcon onClick={() => handleDeleteItem(idx)} />}
              </OfferDetailedItem>
            );
          })}
        </ItemsScrollView>
      ) : (
        <EmptyContainer>
          <ExclamationCircleIcon />
          <EmptyTitle>No Content</EmptyTitle>
          <EmptySubtitle>You haven't added any content yet</EmptySubtitle>
        </EmptyContainer>
      )}
    </Container>
  );
};
