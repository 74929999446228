import { useCallback } from 'react';
import styled from 'styled-components';

import { Segment, SettingsTextInput } from 'components';
import { useContent, useSaveContext } from 'providers';
import { FONT_10PX_REGULAR } from 'font';
import { NEUTRAL_7_COLOUR } from 'theme';

const HelpText = styled.div`
  ${FONT_10PX_REGULAR};
  color: ${NEUTRAL_7_COLOUR};
  margin-top: 4px;
`;

interface ExerciseSpecificsInputProps {
  videoId: string | number;
}

export const ExerciseSpecificsInput = ({ videoId }: ExerciseSpecificsInputProps) => {
  const { videos, setVideoProperty } = useContent();
  const { setVideoPropertyToSave } = useSaveContext();

  const video = videos[videoId];

  const changeExerciseSpecifics = useCallback(
    (value: string) => {
      setVideoProperty(videoId, 'ExerciseSpecifics', value.toString());
      setVideoPropertyToSave(videoId, 'ExerciseSpecifics', value.toString());
    },
    [setVideoProperty, setVideoPropertyToSave],
  );

  return (
    <Segment header="Exercise Specifics" $marginTop="0" $marginBottom="28px">
      <SettingsTextInput
        size="middle"
        value={video.Properties?.ExerciseSpecifics}
        placeholder="Write your specification here"
        onChange={(e) => changeExerciseSpecifics(e.target.value)}
      />
      <HelpText>For example: Alternating, Left-Only, Right-Only</HelpText>
    </Segment>
  );
};
