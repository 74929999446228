import styled from 'styled-components';

import { FONT_12PX_MEDIUM } from 'font';
import { CircuitRoundsInput } from './CircuitRoundsInput';
import { CircuitTypeRadio } from './CircuitTypeRadio';
import { CircuitRowButtons } from './CircuitRowButtons';
import { TreeItem } from '../ContentCollectionTree';
import { Dispatch, SetStateAction } from 'react';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 6px;
`;

const Label = styled.div`
  ${FONT_12PX_MEDIUM};
`;

interface CircuitRowConfigProps {
  item: TreeItem;
  parentTabId: string | number;
  setActiveKeys: Dispatch<SetStateAction<(string | number)[]>>;
}

export const CircuitRowConfig = ({ item, parentTabId, setActiveKeys }: CircuitRowConfigProps) => (
  <Container>
    <Label>Rounds</Label>
    <CircuitRoundsInput tabId={item.itemId} />
    <CircuitTypeRadio tabId={item.itemId} />
    <CircuitRowButtons item={item} parentTabId={parentTabId} setActiveKeys={setActiveKeys} />
  </Container>
);
