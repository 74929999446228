import React, { useRef, useState } from 'react';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import styled from 'styled-components';

import { PRIMARY_ORANGE_COLOUR } from 'theme';
import { EditIcon } from 'icons';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';

interface FillProps {
  $backgroundColor?: string;
  children?: React.ReactNode;
}

interface PickerProps {
  children?: React.ReactNode;
  color?: string;
  onColorChange?: ColorChangeHandler;
}

const UnstyledBox = (props: FillProps) => {
  return <div {...props}>{props?.children}</div>;
};

const FillBox = styled(UnstyledBox)`
  position: relative;
  height: 120px;
  width: 120px;
  border-radius: 5px;
  background-color: ${(props) => props.$backgroundColor || '#EEEEEE'};
`;
const EditButton = styled.div`
  position: absolute;
  bottom: -15px;
  right: -6px;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: white;
  box-shadow: 3px 3px 10px 0px #0000001a;

  // Center the content
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${PRIMARY_ORANGE_COLOUR};
  cursor: pointer;
`;
const StyledEditIcon = styled(EditIcon)`
  height: 20px;
`;
const PickerWrapper = styled.div`
  margin-right: 100px;
  position: absolute;
  bottom: 40px;
  left: 35px;
  width: 220px;
  height: 240px;
  z-index: 2;
`;

export const SquareColorPicker = ({ children, color, onColorChange }: PickerProps) => {
  const [showPicker, setShowPicker] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = () => {
    setShowPicker(false);
  };

  const handleClick = () => {
    setShowPicker(true);
  };

  useOutsideAlerter(wrapperRef, handleClickOutside);

  return (
    <FillBox $backgroundColor={color}>
      {children}
      <EditButton>
        <StyledEditIcon onClick={handleClick} />
        {showPicker && (
          <PickerWrapper ref={wrapperRef}>
            <ChromePicker color={color} onChange={onColorChange} disableAlpha />
          </PickerWrapper>
        )}
      </EditButton>
    </FillBox>
  );
};
