import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { useAxiosInstance, buildOrSubmitApp, BuildOrSubmitOptions } from 'api';
import { useRecordAction } from 'hooks';

export const useBuildOrSubmitApp = () => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, BuildOrSubmitOptions>(
    (options) => {
      return buildOrSubmitApp(client, appId, options);
    },
    {
      onSuccess: (_response, { platform, stage, branch }) => {
        const action = stage === 'Preview' ? 'Building' : 'Submitting';
        const branchInfo = branch ? ` on branch: ${branch}` : '';
        recordAction.mutate({
          action: `${action} ${platform} app${branchInfo}`,
        });
      },
    },
  );
};
