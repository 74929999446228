import { useEffect, useState } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';

import { ContentDescription, CustomButton, SettingsAlternateThumbnailInput, SettingsThumbnailInput } from 'components';
import { PlusIcon } from 'icons';

import {
  BlueButton,
  ScrollView,
  ViewContainer,
  ViewFormItem,
  ViewHeaderRow,
  ViewHeading,
  ViewSection,
  ViewSectionHeading,
  ViewTextInput,
} from './ViewComponents';
import { useModalContext } from '../providers/modal-provider';

const { useForm } = Form;

const Flex = styled.div`
  display: flex;
`;

interface TextImage {
  title: string;
  thumbnail: string;
  description: string;
  squareThumbnail?: string;
  portraitThumbnail?: string;
}

interface TextImageViewProps {
  onCreate: (ti: TextImage) => void;
}

export const TextImageView = ({ onCreate }: TextImageViewProps) => {
  const [form] = useForm<TextImage>();
  const [editing, setEditing] = useState<boolean>(false);
  const [ti, setTi] = useState<TextImage>({
    title: '',
    thumbnail: '',
    squareThumbnail: '',
    portraitThumbnail: '',
    description: '',
  });
  const { setConfirmDisabled } = useModalContext();

  useEffect(() => {
    // While editing, you can't quit the modal, you would have to clear it first
    setConfirmDisabled(editing);
  }, [editing]);

  const handleEdit = () => {
    setEditing(true);
  };
  const handleClear = () => {
    form.resetFields();
    setTi((existing) => ({ ...existing, description: '', thumbnail: '', squareThumbnail: '', portraitThumbnail: '' }));
    setEditing(false);
  };

  const validateForm = (): boolean => {
    return true;
  };

  return (
    <ViewContainer>
      <ViewHeaderRow>
        <ViewHeading>Create Page (HTML)</ViewHeading>
        <Flex>
          <CustomButton medium tertiary onClick={handleClear} disabled={!editing}>
            Clear
          </CustomButton>
          <BlueButton
            medium
            tertiary
            icon={<PlusIcon />}
            disabled={!editing}
            onClick={() => {
              if (validateForm()) {
                onCreate(ti);
                handleClear(); // Clear form after adding to the collection
              }
            }}
          >
            Add
          </BlueButton>
        </Flex>
      </ViewHeaderRow>
      <ScrollView>
        <Form form={form}>
          <ViewSection>
            <ViewSectionHeading>Title</ViewSectionHeading>
            <ViewFormItem name="Title">
              <ViewTextInput
                placeholder="Enter a title"
                onChange={(e) => {
                  handleEdit();
                  setTi((oldTi) => {
                    return { ...oldTi, title: e.target.value };
                  });
                }}
              />
            </ViewFormItem>
          </ViewSection>
          <ViewSection>
            <ViewSection>
              <SettingsThumbnailInput
                $marginTop="0px"
                filename={ti.thumbnail ?? ''}
                isRevertable={false}
                handleThumbnailChange={(filename) => {
                  handleEdit();
                  setTi((oldTi) => {
                    return { ...oldTi, thumbnail: filename };
                  });
                }}
                width="172px"
                height="96px"
                uploaderProps={{
                  maxDimensions: { maxWidth: 1920, maxHeight: 1080 },
                  acceptedFileTypes: 'image/png, image/jpeg',
                  scaleSpinner: 80,
                }}
              />
              <SettingsAlternateThumbnailInput
                squareFilename={ti?.squareThumbnail ?? ''}
                portraitFilename={ti?.portraitThumbnail ?? ''}
                handleSquareThumbnailChange={(filename) => {
                  handleEdit();
                  setTi((ti) => {
                    return { ...ti, squareThumbnail: filename };
                  });
                }}
                handlePortraitThumbnailChange={(filename) => {
                  handleEdit();
                  setTi((ti) => {
                    return { ...ti, portraitThumbnail: filename };
                  });
                }}
                squareWidth="123px"
                portraitWidth="123px"
                portraitHeight="184px"
                orientation="column"
              />
            </ViewSection>
          </ViewSection>
          <ViewSection>
            <ViewSectionHeading>Description</ViewSectionHeading>
            <ContentDescription
              controlledDesc={ti.description}
              onDescChange={(description) => {
                if (description) {
                  handleEdit();
                }
                setTi((oldTi) => {
                  return { ...oldTi, description };
                });
              }}
              hideTitle
              height={400}
              $marginBottom="0"
              $marginTop="0"
            />
          </ViewSection>
        </Form>
      </ScrollView>
    </ViewContainer>
  );
};
