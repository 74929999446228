import styled from 'styled-components';

import { FONT_14PX_MEDIUM } from 'font';

export const EllipsisText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${FONT_14PX_MEDIUM}
`;
