import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';

import { FONT_14PX_REGULAR } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { usePrefetchTemplates } from 'hooks';

import { AddContent, AddSection, AddSwitch, DrawerHeading } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { useDataSource } from 'providers';

import { SOURCE_KAJABI, SOURCE_USCREEN, SOURCE_VHX, SOURCE_WORDPRESS } from 'api';
import { XIcon } from 'icons';

const DrawerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const AddHeader = styled.div`
  padding-top: 21px;
  padding-bottom: 24px;
`;

const StyledAlert = styled(Alert)`
  &&&& {
    color: ${NEUTRAL_10_COLOUR};
    ${FONT_14PX_REGULAR};
    border-radius: 6px;
    margin-bottom: 24px;
  }
`;

const CloseButton = styled(XIcon)`
  &&&& {
    position: absolute;
    top: 28px;
    left: 28px;

    font-size: 21px;
    color: ${NEUTRAL_7_COLOUR};
    cursor: pointer;

    :hover {
      color: ${NEUTRAL_9_COLOUR};
    }
  }
`;

export const AddDrawer = () => {
  const [isContentScreen, setIsContentScreen] = useState(true);
  usePrefetchTemplates(); // Ensure templates are retrieved before even required
  const { isAddPanelEnabled, setDrawerContext } = useBuilderContext();
  const dataSource = useDataSource();
  const disabledMessage = useMemo(() => {
    if (dataSource === SOURCE_KAJABI) {
      return 'Content and sections can not be added to linked sub-tabs, Kajabi Courses, Modules or Sub-Modules.';
    } else if (dataSource === SOURCE_VHX) {
      return 'Content and sections can not be added to linked sub-tabs, Vimeo OTT Categories or Collections.';
    } else if (dataSource === SOURCE_USCREEN) {
      return 'Content and sections can not be added to linked sub-tabs, Uscreen Categories or Playlists.';
    } else if (dataSource === SOURCE_WORDPRESS) {
      return 'Content and sections can not be added to linked sub-tabs and WordPress Collections';
    }
    return 'Content and sections can not be added to linked sub-tab and source content';
  }, [dataSource]);

  return (
    <DrawerContainer>
      <CloseButton onClick={() => setDrawerContext(undefined)} />
      <DrawerHeading heading="Add">
        <AddHeader>
          {!isAddPanelEnabled && <StyledAlert type="warning" message={disabledMessage} />}
          <AddSwitch isContent={isContentScreen} onChange={() => setIsContentScreen((prev) => !prev)} />
        </AddHeader>
      </DrawerHeading>

      {isContentScreen ? <AddContent /> : <AddSection />}
    </DrawerContainer>
  );
};
