import { ReactNode } from 'react';
import styled from 'styled-components';

import { useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { ContentCard } from 'app/modules/build-dragdrop/Builder/components';
import { ProductItem } from 'api';

const ItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  min-width: 100%;
  height: 57px;
  overflow: hidden;

  padding: 4px 20px;
  margin-bottom: 9px;
`;

interface OfferDetailedItemProps {
  item: ProductItem;
  children?: ReactNode | ReactNode[];
}

export const OfferDetailedItem = ({ item, children }: OfferDetailedItemProps) => {
  const { getContentForItem } = useContent();

  const content = getContentForItem(item);
  if (!content) {
    console.warn('OfferDetailedItem', 'Hidden, cant get content for item', item);
    return null;
  }

  return (
    <ItemContainer>
      <ContentCard content={content} type={item.Type} />
      {children}
    </ItemContainer>
  );
};
