import { AxiosInstance } from 'axios';

export const securelyUploadFile = async (client: AxiosInstance, filepath: string, file: File) => {
  const presignedPostData: { data: { url: string; fields: Record<string, string> } } = await client.post(
    `storage/resource/${filepath}`,
  );
  const { url, fields } = presignedPostData.data;

  const formData = new FormData();
  Object.entries(fields).forEach(([key, val]) => formData.append(key, val));
  formData.append('file', file);

  await client.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
