import { ReactNode } from 'react';
import styled from 'styled-components';

import { SegmentTitle, SegmentTitleProps } from 'components/Text/SegmentTitle';
import { NEUTRAL_5_COLOUR, SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';

const SegmentContainer = styled.div<SettingsSegmentProps>`
  margin-top: ${({ $marginTop }) => $marginTop || '0'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || SETTINGS_ITEM_MARGIN_BOTTOM};
`;

export interface SettingsSegmentProps {
  children?: ReactNode;
  $marginTop?: string;
  $marginBottom?: string;
  title?: string;
}

export const SettingsSegment = ({
  children,
  $marginTop,
  $marginBottom,
  title,
  ...props
}: SettingsSegmentProps & Omit<SegmentTitleProps, 'title'>) => {
  return (
    <SegmentContainer $marginTop={$marginTop} $marginBottom={$marginBottom}>
      {title && <SegmentTitle title={title} {...props} />}
      {children}
    </SegmentContainer>
  );
};

export const SettingsSegmentDivider = styled.div`
  margin-bottom: ${SETTINGS_ITEM_MARGIN_BOTTOM};
  width: 100%;
  height: 1px;
  background-color: ${NEUTRAL_5_COLOUR};
`;
