import { useCallback } from 'react';
import { Form } from 'antd';

import { useTabSettings } from 'app/modules/build-screens/hooks';

import { NavigationHeader, TabBar } from '../components';
import { getPageName } from '../utils';
import { ContentAdvancedSettings, Segment, SettingsFormItem, SettingsTextInput } from 'components';
import { DrawerSeparator } from './components/DrawerSeparator';
import { useContent } from 'providers';
import { TabDrawer } from '../components/Common/TabDrawer';

interface WebsiteTabDrawerProps {
  tabId: number;
}

export const WebsiteTabDrawer = ({ tabId }: WebsiteTabDrawerProps) => {
  const [form] = Form.useForm();

  const { collections } = useContent();
  const tab = collections[tabId];

  // The declared variables are managed in useTabSettings
  const { onValuesChange } = useTabSettings(
    {
      NavBarTitleText: { type: 'collection', valueType: 'string' },
      Name: { type: 'collection', valueType: 'string' },
      LockedUntilSignIn: { type: 'collectionProperty', valueType: 'binary' },
      Website: { type: 'collectionProperty', valueType: 'string' },
      Icon: { type: 'collection', valueType: 'string' },
    },
    tabId,
    form,
  );

  const onTabIconFilenameChange = useCallback(
    (_filename) => {
      onValuesChange({ Icon: _filename }, {}, true);
    },
    [onValuesChange],
  );

  return (
    <TabDrawer tabId={tabId}>
      <Form
        name="settings-form"
        onValuesChange={(changedValues) => {
          onValuesChange(changedValues);
        }}
        autoComplete="off"
        form={form}
      >
        <NavigationHeader tabId={tabId} placeholder={getPageName(tab)} />
        <DrawerSeparator />
        <Segment header="Website URL">
          <SettingsFormItem name="Website">
            <SettingsTextInput size="middle" placeholder="Paste website URL here" />
          </SettingsFormItem>
        </Segment>
        <DrawerSeparator />
        <ContentAdvancedSettings collection={tab} isDrawer isTab />
        <TabBar filename={tab.Icon} placeholder={getPageName(tab)} onFilenameChange={onTabIconFilenameChange} />
      </Form>
    </TabDrawer>
  );
};
