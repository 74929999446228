// convert array of strings to a string literal
import { AxiosInstance } from 'axios';
import { DataSource } from './const';

export interface TaggedItem {
  ItemId: number;
  ItemType: 'video' | 'collection';
  FilterOptionIds: number[];
  DataSource: DataSource;
}

export type TaggedItemToSave = Omit<TaggedItem, 'DataSource'>;

export interface IndexedFilterOptionIds {
  [key: number]: number[];
}

export const getTaggedVideos = (client: AxiosInstance, appId: string) => {
  return client.get<{ data: TaggedItem[] }>(`filter/itemtag/${appId}?item_type=video`).then(({ data }) => {
    const FILTER_OPTIONS_BY_VIDEO_ID: IndexedFilterOptionIds = {};
    data.data.forEach((item) => {
      FILTER_OPTIONS_BY_VIDEO_ID[item.ItemId] = item.FilterOptionIds;
    });
    return { taggedVideos: data.data, filter_options_by_video_id: FILTER_OPTIONS_BY_VIDEO_ID };
  });
};

export const getTaggedCollections = (client: AxiosInstance, appId: string) => {
  return client.get<{ data: TaggedItem[] }>(`filter/itemtag/${appId}?item_type=collection`).then(({ data }) => {
    const FILTER_OPTIONS_BY_TAB_ID: IndexedFilterOptionIds = {};
    data.data.forEach((item) => {
      FILTER_OPTIONS_BY_TAB_ID[item.ItemId] = item.FilterOptionIds;
    });
    return { taggedCollections: data.data, filter_options_by_collection_id: FILTER_OPTIONS_BY_TAB_ID };
  });
};

export const saveTaggedItems = async (client: AxiosInstance, appId: string, taggedItems: TaggedItemToSave[]) => {
  return client.put(`filter/itemtag/${appId}`, JSON.stringify(taggedItems));
};
