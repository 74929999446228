import { AxiosInstance } from 'axios';

export const updateAppleIAPs = (client: AxiosInstance, appId: string) => {
  return client
    .post(
      `https://app.bitrise.io/app/45182e9e9c450f5b/build/start.json`,
      JSON.stringify({
        hook_info: { type: 'bitrise', build_trigger_token: 'v2cdshWlT40oAQMqaavPnA' },
        build_params: {
          branch: 'master',
          workflow_id: 'iap',
          commit_message: appId,
          environments: [{ mapped_to: 'APPID', value: appId, is_expand: true }],
        },
        triggered_by: 'curl',
      }),
    )
    .then(({ data }) => {
      console.log(`App successfully added to iOS update IAP queue`);
      console.log(data);
    })
    .catch((err) => {
      throw err;
    });
};
