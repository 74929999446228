import { useState, useCallback, useEffect, useMemo } from 'react';
import { Form } from 'antd';

import { SaveFailed, SelectOption, InfoModal } from 'components';
import { useUnsavedChanges } from 'providers';
import { AppPropertyToSave } from 'api';
import { useAppProperties, useFilters, useSaveAppProperties } from 'hooks';
import { useAppBeingEdited } from 'app-context';

import { PropertyType } from '../CircuitConfiguration/const';

interface Filename {
  value: string | undefined;
  touched: boolean;
}

export const useCircuitConfiguration = (PROPERTIES: Record<string, PropertyType>) => {
  const appId = useAppBeingEdited();
  const { data: appProperties, isLoading: appPropertiesIsLoading, isError: appPropertiesIsError } = useAppProperties();
  const { data: filtersData, isLoading: filtersIsLoading, isError: filtersIsError } = useFilters(Infinity, 'always');
  const saveAppProperties = useSaveAppProperties();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<Record<string, unknown>>();
  const [filenames, setFilenames] = useState<Record<string, Filename>>({});

  const filterOptions: SelectOption[] | undefined = useMemo(() => {
    const options = filtersData?.filters
      .sort((a, b) => a.Filter.localeCompare(b.Filter))
      .map(({ Filter, Id }) => ({ name: Filter, value: Id.toString() }));
    options && options.unshift({ name: 'Select a category', value: '' });
    return options;
  }, [filtersData]);

  useEffect(() => {
    if (appProperties && filtersData) {
      const values: Record<string, unknown> = {};

      for (const key in PROPERTIES) {
        if (PROPERTIES[key].isFile) {
          setFilenames((prev) => ({
            ...prev,
            [key]: {
              value: appProperties[key],
              touched: false,
            },
          }));
        } else {
          values[key] = appProperties[key];
        }
      }
      if (appProperties.WorkoutFilterCategories) {
        const filterValues = JSON.parse(appProperties.WorkoutFilterCategories);
        values.FilterCategoryTwo = filterValues[0] ? filterValues[0].CategoryId.toString() : '';
        values.FilterCategoryThree = filterValues[1] ? filterValues[1].CategoryId.toString() : '';
        values.FilterCategoryFour = filterValues[2] ? filterValues[2].CategoryId.toString() : '';
      }
      setInitialValues(values);
    }
  }, [appProperties, filtersData, setFilenames]);

  useEffect(() => {
    !isSaving && form.resetFields();
  }, [initialValues, isSaving]);

  const onValuesChange = useCallback(() => {
    if (!unsavedChanges) {
      setUnsavedChanges(true);
    }
  }, [unsavedChanges, setUnsavedChanges]);

  const onFilenameChange = useCallback(
    (key: string, _filename: string) => {
      setFilenames((prev) => ({ ...prev, [key]: { value: _filename, touched: true } }));
      onValuesChange();
    },
    [setFilenames, onValuesChange],
  );

  const getValueToSave = useCallback(
    (field: string, stripWhitespace?: boolean) => {
      const value = form.getFieldValue(field).toString();
      return stripWhitespace ? value.replace(/\s+/g, '') : value;
    },
    [form],
  );

  const saveForm = useCallback(async () => {
    const filterValues: string[] = [];

    if (filterOptions && filterOptions.length > 2) {
      if (form.getFieldValue('FilterCategoryTwo') && form.getFieldValue('FilterCategoryTwo') !== '') {
        filterValues.push(form.getFieldValue('FilterCategoryTwo'));
      }
      if (form.getFieldValue('FilterCategoryThree') && form.getFieldValue('FilterCategoryThree') !== '') {
        filterValues.push(form.getFieldValue('FilterCategoryThree'));
      }
      if (form.getFieldValue('FilterCategoryFour') && form.getFieldValue('FilterCategoryFour') !== '') {
        filterValues.push(form.getFieldValue('FilterCategoryFour'));
      }

      if (filterValues.length < 3) {
        // Block saving if any of the 'Workout Overview Categories' inputs don't have values selected
        InfoModal('Oh no!', 'Please select all of your Workout Overview Categories before saving', 'warning');
        return;
      }
    }

    setIsSaving(true);
    setUnsavedChanges(false);
    const fieldsToSave = form.getFieldsValue(true, (meta) => meta.touched);

    const appPropertiesToSave: AppPropertyToSave[] = [];

    for (const field in fieldsToSave) {
      if (!field.startsWith('FilterCategory')) {
        const value = getValueToSave(field, PROPERTIES[field].stripWhitespace);
        appPropertiesToSave.push({ Name: field, Value: value });
      }
    }

    if (filterValues.length === 3) {
      appPropertiesToSave.push({
        Name: 'WorkoutFilterCategories',
        Value: JSON.stringify([
          {
            CategoryId: parseInt(filterValues[0]),
            SelectType: 'Single',
          },
          {
            CategoryId: parseInt(filterValues[1]),
            SelectType: 'Single',
          },
          {
            CategoryId: parseInt(filterValues[2]),
            SelectType: 'Multiple',
          },
        ]),
      });
    }

    // Add filenames to be saved if they have been touched
    for (const [key, { touched, value }] of Object.entries(filenames)) {
      if (touched) {
        appPropertiesToSave.push({ Name: key, Value: value ?? '' });
      }
    }

    if (appPropertiesToSave.length > 0) {
      try {
        await saveAppProperties.mutateAsync(appPropertiesToSave);
      } catch (error) {
        SaveFailed(error as Error);
        setUnsavedChanges(true);
        setIsSaving(false);
      }
    }

    setIsSaving(false);
    form.resetFields();
  }, [setIsSaving, setUnsavedChanges, form, appProperties, saveAppProperties, filterOptions]);

  return {
    filterOptions,
    form,
    unsavedChanges,
    isSaving,
    isLoading: appPropertiesIsLoading || filtersIsLoading,
    isError: appPropertiesIsError || filtersIsError,
    initialValues,
    filenames,
    appId,
    onFilenameChange,
    onValuesChange,
    saveForm,
  };
};
