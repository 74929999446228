import { PageContainer, Tabs } from 'components';
import { PropertyDiffTable } from 'app/modules/publish-diffs/PublishDiff/components/PropertyDiffTable';
import { CollectionDiffs } from 'app/modules/publish-diffs/PublishDiff/components/CollectionDiffs';
import { ContentProvider, BuilderCollection, BuilderVideos } from 'providers';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useCollections } from 'hooks';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

const { Root, List, Trigger, Content } = Tabs;

export const PublishDiff = () => {
  const [collections, setCollections] = useState<Record<string | number, BuilderCollection>>();
  const [videos, setVideos] = useState<BuilderVideos>({});
  const { data: collectionData } = useCollections(Infinity, true);

  useEffect(() => {
    // Setup state once on initial data load
    if (collections === undefined && !!collectionData) {
      const dragDropCollections = collectionData.collection_by_id as Record<number, BuilderCollection>;
      setCollections(dragDropCollections);
    }
  }, [!!collectionData]);

  return (
    <PageContainer
      heading="Publish Differences"
      subheading="Differences between properties in different stages"
      isLoading={!collections || !videos}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <>
        {!!collections && !!videos && (
          <ContentProvider
            collections={collections}
            setCollections={setCollections as Dispatch<SetStateAction<Record<string | number, BuilderCollection>>>}
            videos={videos}
            setVideos={setVideos as Dispatch<SetStateAction<BuilderVideos>>}
          >
            <Root defaultValue="1">
              <List>
                <Trigger value="1">Publishable to Preview</Trigger>
                <Trigger value="2">Publishable to Live</Trigger>
              </List>
              <Content value="1">
                <>
                  <PropertyDiffTable stage="Preview" />
                  <CollectionDiffs stage="Preview" />
                </>
              </Content>
              <Content value="2">
                <>
                  <PropertyDiffTable stage="Live" />
                  <CollectionDiffs stage="Live" />
                </>
              </Content>
            </Root>
          </ContentProvider>
        )}
      </>
    </PageContainer>
  );
};
