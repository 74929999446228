import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { useAxiosInstance, CalendarType, getCalendar } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useCalendar = () => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const query = useQuery<CalendarType>(['calendar', appId], () => getCalendar(client, appId));

  const getCalendarDay = (date: Date) => {
    const monthKey = format(date, 'yyyy-MM');
    const dayKey = format(date, 'dd');
    if (query.data && monthKey in query.data && dayKey in query.data[monthKey]) {
      return query.data[monthKey][dayKey];
    }
    return null;
  };

  const getCalendarMonthPublished = (month: string) => {
    if (query.data && month in query.data) {
      const days = Object.values(query.data[month]);
      return days.some((day) => day.Published === 1);
    }
    return false;
  };

  return { ...query, getCalendarDay, getCalendarMonthPublished };
};
