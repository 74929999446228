import styled, { css } from 'styled-components';

import { NEUTRAL_3_COLOUR } from 'theme';

import {
  BuilderCollection,
  BuilderCollectionItem,
  useBuilderContext,
  useContent,
} from 'app/modules/build-dragdrop/Builder/providers';
import { ContentCard } from 'app/modules/build-dragdrop/Builder/components';
import { ITEM_TYPE_COLLECTION } from 'api';
import { CSSProperties, useCallback } from 'react';
import { BUILDMYAPP_COLLECTION_ITEM } from 'app/modules/build-dragdrop/Builder/const';
import { useSaveContext } from 'providers';
import { calculateCollectionItemPositions } from 'utils';

const CollectionWrapper = styled.div<{ $disabled: boolean }>`
  width: calc(100% - 4px);
  border-radius: 12px;

  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;

  padding: 8px;

  :hover {
    background-color: ${NEUTRAL_3_COLOUR};
  }

  :active {
    background-color: #fff;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 50%;
      user-select: none;
      cursor: not-allowed;
    `}
`;

interface AddCollectionProps {
  collection: BuilderCollection;
  style?: CSSProperties;
  index: number;
}

export const AddCollection = ({ collection, style }: AddCollectionProps) => {
  const { isAddPanelEnabled, visiblePageId, drawerContext, setSectionDrawer } = useBuilderContext();
  const { getTempId, setCollectionItemsToSave } = useSaveContext();
  const { setCollectionItems, collections } = useContent();

  const addToCollection = useCallback(() => {
    if (!visiblePageId) {
      console.warn('AddCollection', 'Failed to insert content unable to determine collection in Simulator');
      return;
    }
    const updateCollection = collections[visiblePageId];
    if (!updateCollection) {
      console.warn(`Failed to insert content couldn't find the Tab with id: ${visiblePageId}`);
      return;
    }
    const index = drawerContext?.index ?? updateCollection.Items.length;

    const tempTabItemId = `TempTabItem${getTempId()}`;
    const newItem: BuilderCollectionItem = {
      ...BUILDMYAPP_COLLECTION_ITEM,
      AppId: collection.AppId,
      TabId: visiblePageId,
      ChildId: collection.TabId,
      TabItemId: tempTabItemId,
      TemplateId: 3,
    };

    console.debug('AddCollection', 'Inserting new content', newItem, updateCollection.TabId);
    // Update the subtab to contain the new section
    updateCollection.Items.splice(index, 0, newItem);
    setSectionDrawer(tempTabItemId, collection.TabId.toString());
    calculateCollectionItemPositions(updateCollection.Items);
    updateCollection.Items = [...updateCollection.Items]; // Trigger dependent updates
    setCollectionItems(visiblePageId, updateCollection.Items);
    setCollectionItemsToSave(visiblePageId);
  }, [
    getTempId,
    collection,
    visiblePageId,
    drawerContext,
    setSectionDrawer,
    setCollectionItems,
    setCollectionItemsToSave,
  ]);

  return (
    <CollectionWrapper $disabled={!isAddPanelEnabled} style={style} onClick={addToCollection}>
      <ContentCard content={collection} type={ITEM_TYPE_COLLECTION} hideType />
    </CollectionWrapper>
  );
};
