import { useCallback, useEffect, useState } from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';

import { useAppProperties } from 'hooks';
import { OldRightArrowIcon } from 'icons';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { useDataSource } from 'providers';

import { BackButton, Footer, NextButton, SplitPanel, StepPanel } from './index';
import { FONT_14PX_SEMIBOLD } from '../font';
import { useTheme } from '../hooks/useTheme';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

const RightFooter = styled(Footer)`
  justify-content: space-between;
`;
const RightBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const Options = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  justify-content: space-between;
`;

const StyledRadio = styled(Radio)`
  #react-app && span {
    ${FONT_14PX_SEMIBOLD};
  }
`;

type Mode = 'Light' | 'Dark';

const StyledImage = styled.img`
  height: 360px;
  object-fit: contain;
`;

const getBodyText = (dataSource: string) => {
  return (
    {
      Kajabi: "Select the mode that matches the color scheme of your Kajabi member's area.",
      VHX: 'Select the color mode that matches your Vimeo OTT channel and brand.',
    }[dataSource] ?? 'Select the mode that matches the color scheme of your brand.'
  );
};

export const ModeStep = () => {
  const { mutateTheme } = useTheme();
  const { data: properties } = useAppProperties();
  const [mode, setMode] = useState<Mode | undefined>();
  const dataSource = useDataSource();
  const { navigateForward } = useWizardNavigation();

  // Pull in initial value if set previously
  useEffect(() => {
    if (!mode && properties?.ColorScheme) {
      setMode(properties.ColorScheme as Mode);
    }
  }, [properties]);

  const handleChange = useCallback(
    (e) => {
      setMode(e.target.value);
    },
    [setMode],
  );

  const handleContinue = useCallback(async () => {
    if (mode) {
      await mutateTheme.mutateAsync({ ColorScheme: mode });
      navigateForward();
    }
  }, [navigateForward, mode]);

  return (
    <SplitPanel
      leftContent={
        <StepPanel
          header="Select the light or dark color mode."
          icon={<img src={`${BUILDER_IMAGES_DOMAIN}/onboarding/wizard_icons/palette.webp`} alt="user icon" />}
          body={getBodyText(dataSource)}
        />
      }
      rightContent={
        <>
          <Radio.Group value={mode} onChange={handleChange}>
            <RightBody>
              <StyledImage src={`${BUILDER_IMAGES_DOMAIN}/onboarding/lightmode.webp`} />
              <StyledImage src={`${BUILDER_IMAGES_DOMAIN}/onboarding/darkmode.webp`} />
              <Options>
                <StyledRadio value="Light">Light mode</StyledRadio>
                <StyledRadio value="Dark">Dark mode</StyledRadio>
              </Options>
            </RightBody>
          </Radio.Group>
          <RightFooter>
            <BackButton />
            <NextButton
              disabled={!mode}
              onClick={handleContinue}
              loading={mutateTheme.isLoading}
              icon={mode && !mutateTheme.isLoading && <OldRightArrowIcon />}
            >
              Continue
            </NextButton>
          </RightFooter>
        </>
      }
    />
  );
};
