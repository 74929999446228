import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { Segment, SettingsNumberInput, SettingsSelectInput } from 'components';
import { NEUTRAL_7_COLOUR, SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';
import { useAppProperties, useFilters } from 'hooks';
import { FONT_12PX_REGULAR, FONT_16PX_REGULAR } from 'font';
import { WorkoutTagInput } from './WorkoutTagInput';

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RowSegment = styled(Segment)`
  width: 33.3%;
  margin-top: 0;
  margin-bottom: 40px;

  .segment-inner {
    padding: 0;
  }

  :first-child {
    margin-right: 10px;
  }

  :last-child:not(:first-child) {
    margin-left: 10px;
  }
`;

const StyledSettingsNumberInput = styled(SettingsNumberInput)`
  &&&& {
    ${FONT_16PX_REGULAR};
  }
  // Turn Prefix into a suffix
  &&&& .ant-input-number-prefix {
    position: absolute;
    top: 9px;
    left: 44px;
    z-index: 1;
    color: ${NEUTRAL_7_COLOUR};
  }
`;

const HelpText = styled.div`
  ${FONT_12PX_REGULAR};
  color: ${NEUTRAL_7_COLOUR};
  margin-bottom: 40px;
`;

interface WorkoutFilterCategory {
  CategoryId: number;
  SelectType: 'Single' | 'multiple';
}

interface WorkoutSettingsProps {
  collection: BuilderCollection;
}

export const WorkoutSettings = ({ collection }: WorkoutSettingsProps) => {
  const { setCollectionProperty } = useContent();
  const { setCollectionPropertyToSave } = useSaveContext();
  const { data: appProperties } = useAppProperties();
  const { data: filtersData } = useFilters();

  const categoriesProperty = appProperties?.WorkoutFilterCategories
    ? (JSON.parse(appProperties.WorkoutFilterCategories) as WorkoutFilterCategory[])
    : undefined;

  const categories = useMemo(() => {
    if (filtersData && categoriesProperty && categoriesProperty.length > 2) {
      return categoriesProperty.map((cat) => {
        const filter = filtersData.filters.find((filter) => filter.Id === cat.CategoryId);
        const collectionProperty = collection.Properties?.[`Filter${filter?.Id}`];
        return {
          name: filter?.Filter,
          options: filter?.FilterOptions
            ? filter.FilterOptions.map((option) => ({ name: option.Option, value: option.Option }))
            : [],
          value: collectionProperty ? (JSON.parse(collectionProperty) as string) : undefined,
          id: filter?.Id,
        };
      });
    }
    return undefined;
  }, [collection, filtersData, categoriesProperty]);

  const handleAverageTimeChange = useCallback(
    (value: number) => {
      if (collection) {
        setCollectionProperty(collection.TabId, 'WorkoutAverageTime', value.toString());
        setCollectionPropertyToSave(collection.TabId, 'WorkoutAverageTime', value.toString());
      }
    },
    [collection, setCollectionProperty, setCollectionPropertyToSave],
  );

  const handleCategoryChange = useCallback(
    (value: string, id?: number) => {
      if (collection && id) {
        const property = `Filter${id}`;
        const valueToSave = JSON.stringify(value);
        setCollectionProperty(collection.TabId, property, valueToSave);
        setCollectionPropertyToSave(collection.TabId, property, valueToSave);
      }
    },
    [collection, setCollectionProperty, setCollectionPropertyToSave],
  );

  return (
    <>
      <FlexRow>
        <RowSegment header="Avg. Time">
          <StyledSettingsNumberInput
            autoComplete="off"
            value={collection.Properties?.WorkoutAverageTime}
            onChange={(value) => handleAverageTimeChange(value as number)}
            placeholder="60"
            min={0}
            max={999}
            maxLength={3}
            prefix="min"
          />
        </RowSegment>
        {categories && categories[0] && (
          <RowSegment header={categories[0].name}>
            <SettingsSelectInput
              placeholder={`Select the ${categories[0].name}`}
              options={categories[0].options}
              value={categories[0].value}
              onChange={(value) => handleCategoryChange(value, categories[0].id)}
            />
          </RowSegment>
        )}
        {categories && categories[1] && (
          <RowSegment header={categories[1].name}>
            <SettingsSelectInput
              placeholder={`Select the ${categories[1].name}`}
              options={categories[1].options}
              value={categories[1].value}
              onChange={(value) => handleCategoryChange(value, categories[1].id)}
            />
          </RowSegment>
        )}
      </FlexRow>
      {categories && categories[2] ? (
        <Segment header={categories[2].name} $marginTop="0" $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}>
          <WorkoutTagInput
            collection={collection}
            id={categories[2].id}
            options={categories[2].options}
            value={categories[2].value}
          />
        </Segment>
      ) : !categoriesProperty ? (
        <HelpText>Please contact VidApp Support to set up Workout Tags</HelpText>
      ) : null}
    </>
  );
};
