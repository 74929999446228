import { ColumnModal, CustomButton } from 'components';
import styled from 'styled-components';
import { FONT_16PX_REGULAR } from 'font';
import { AmityPost } from 'api';
import { useCommunityPostContext } from '../providers/CommunityPostProvider';
import { useDeletePost } from '../hooks/useDeletePost';

const PostBody = styled.div`
  ${FONT_16PX_REGULAR};
  min-height: 214px;
  height: fit-content;
  max-height: 642px;

  @media (max-height: 900px) {
    max-height: 50vh;
  }

  margin-right: -12px; // So the scrollbar can sit outside for images
  overflow: auto;
`;

const StyledImg = styled.img`
  margin-top: 20px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

interface CommunityPostModalProps {
  data: AmityPost;
}

export const CommunityPostModal = ({ data }: CommunityPostModalProps) => {
  const { childPosts, files, users, modalPostId, setModalPostId } = useCommunityPostContext();
  const deletePost = useDeletePost();

  const getImageUrl = (fileId: string) => files[fileId].fileUrl + '?size=medium';
  const onClose = setModalPostId ? () => setModalPostId('') : undefined;

  return (
    <ColumnModal
      title={`Post by ${users[data.postedUserId].displayName}`}
      cols={3}
      align="right"
      open={!!modalPostId}
      onOpenChange={onClose}
      hideTrigger
      onCancel={onClose}
      maxHeight="786px"
      customActions={
        <Row>
          <CustomButton
            danger
            medium
            secondary
            loading={deletePost.isLoading}
            disabled={deletePost.isLoading}
            onClick={() => deletePost.mutate(data.postId, { onSuccess: onClose })}
          >
            Delete Post
          </CustomButton>
          <CustomButton medium onClick={onClose}>
            Done
          </CustomButton>
        </Row>
      }
    >
      <PostBody>
        <div>{data.data.text}</div>
        {data.children?.map((child) => {
          if (!childPosts[child]?.data.fileId) {
            return null;
          }

          return <StyledImg src={getImageUrl(childPosts[child]?.data.fileId)} width={512} key={child} />;
        })}
      </PostBody>
    </ColumnModal>
  );
};
