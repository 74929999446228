import { useCallback, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { BuilderCollectionItem, BuilderVideo } from 'providers';
import { getContentName, isContentResource, isSectionHeader } from 'utils';
import { ITEM_TYPE_COLLECTION, ITEM_TYPE_VIDEO } from 'api';
import { ContentIcon } from 'components';

import { useBuilderContext, useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { ContentTitle, HoverIconContainer, Icon, Row, StyledDraggableIcon } from './TreeCollection';
import { PageContext, useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup';

import { TreeRowContent } from './TreeRowContent';

const Spacer = styled.div`
  width: 26px;
  flex-grow: 0;
  flex-shrink: 0;
`;

interface TreeVideoProps {
  video: BuilderVideo;
  item?: BuilderCollectionItem;
  isDragging?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  parentItem?: BuilderCollectionItem;
}

export const TreeVideo = ({ isDragging, item, video, dragHandleProps, parentItem }: TreeVideoProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const { setDrawerContext, selectedItem } = useBuilderContext();
  const { setCurrentPage } = useMockupContext();
  const { collections } = useContent();

  // Match on TabItemId, or auto-match on visible page id
  const isSelected = selectedItem?.tabItemId === item?.TabItemId?.toString();

  const handleTreeMenuNavigation = useCallback(
    (newPage: PageContext | undefined) => {
      setCurrentPage(newPage, { skipStack: true, resetScrollPosition: true });
    },
    [setCurrentPage],
  );

  const handleItemClick = useCallback(() => {
    if (isDragging) {
      return;
    }

    if (isSectionHeader(video)) {
      const newDrawerContext = {
        itemId: item?.ChildId.toString(),
        tabItemId: item?.TabItemId?.toString() ?? 'unknown',
        drawerType: 'sectionHeader' as const,
      };

      const parentCollection = parentItem ? collections[parentItem.ChildId] : undefined;

      handleTreeMenuNavigation({
        itemId: parentCollection?.TabId,
        itemType: ITEM_TYPE_COLLECTION,
      });

      const timeout = setTimeout(() => {
        setDrawerContext(newDrawerContext);
      }, 200);

      return () => clearTimeout(timeout);
    } else {
      const newDrawerContext = {
        itemId: item?.ChildId.toString(),
        tabItemId: item?.TabItemId?.toString() ?? 'unknown',
      };
      handleTreeMenuNavigation({
        itemId: video?.VideoId,
        tabItemId: item?.TabItemId,
        parentCollectionId: item?.TabId,
        itemType: ITEM_TYPE_VIDEO,
      });
      // Give the new page a chance to load before updating the selection and expecting it to scroll
      const timeout = setTimeout(() => {
        setDrawerContext(newDrawerContext);
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [isDragging, video, item, collections, parentItem]);

  const icon = item ? (
    <ContentIcon
      itemType={item.Type}
      contentType={video.Type}
      dataSource={video.DataSource ?? ''}
      isFile={isContentResource(video, item?.Type)}
    />
  ) : null;

  return (
    <Row
      onClick={handleItemClick}
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      {...dragHandleProps}
    >
      <HoverIconContainer>
        {!!dragHandleProps && (isHovering || isDragging) && <StyledDraggableIcon />}
      </HoverIconContainer>
      <TreeRowContent $selected={isSelected} $hover={isHovering || !!isDragging}>
        <Spacer />
        <Icon>{icon}</Icon>
        <ContentTitle>{getContentName(video, ITEM_TYPE_VIDEO)}</ContentTitle>
      </TreeRowContent>
    </Row>
  );
};
