import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

import { getFilters, useAxiosInstance, GetFiltersResponse } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useFilters = (
  staleTime?: UseQueryOptions['staleTime'],
  refetchOnMount?: UseQueryOptions['refetchOnMount'],
  options?: UseQueryOptions<GetFiltersResponse>,
) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const query = useQuery<GetFiltersResponse>(['filters', appId], () => getFilters(client, appId), {
    staleTime: staleTime ?? 30000,
    refetchOnMount: refetchOnMount ?? true,
    enabled: !!appId, // Don't call fetcher with empty AppId
    ...options,
  });

  const { data } = query;

  const getFilterByFilterOptionId = useCallback(
    (filterOptionId: number) => {
      const indexedFilter = data?.filter_by_filter_option_id[filterOptionId];
      if (indexedFilter) {
        return indexedFilter;
      }
      return null;
    },
    [data],
  );

  const getFilterOptionById = useCallback(
    (filterOptionId: number) => {
      const indexedFilterOption = data?.filter_option_by_id[filterOptionId];
      if (indexedFilterOption) {
        return indexedFilterOption;
      }
      return null;
    },
    [data],
  );

  const getFilterOptionIdByName = useCallback(
    (filter: string, name: string) => {
      const indexedFilterOptionId = data?.filter_option_id_by_name[filter][name];
      if (indexedFilterOptionId) {
        return indexedFilterOptionId;
      }
      return null;
    },
    [data],
  );

  return { ...query, getFilterByFilterOptionId, getFilterOptionById, getFilterOptionIdByName };
};
