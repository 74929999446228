import styled from 'styled-components';
import { SadDizzyIcon } from 'icons';
import { NEUTRAL_7_COLOUR } from 'theme';
import { FONT_16PX_MEDIUM } from 'font';

const Flex = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${NEUTRAL_7_COLOUR};

  padding-bottom: 100px;
`;
const Icon = styled(SadDizzyIcon)`
  font-size: 60px;
`;
const Text = styled.div`
  margin-top: 12px;
  ${FONT_16PX_MEDIUM};
`;

export const ErrorView = () => {
  return (
    <Flex>
      <Icon />
      <Text>Something went wrong</Text>
    </Flex>
  );
};
