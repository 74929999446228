import { useCallback, useMemo } from 'react';

import { CATALOG_SOURCE_ID, IN_PROGRESS_TEMPLATE, MY_PRODUCTS_SOURCE_ID, Template } from 'api';

import { DisplayPreview } from 'app/modules/build-dragdrop/Builder/components/DisplayPreview';
import { getFormattedTemplateName } from 'app/modules/build-dragdrop/Builder/util';
import {
  BUILDMYAPP_COLLECTION,
  BUILDMYAPP_COLLECTION_ITEM,
  FEATURE_TEMPLATE_ALLCOURSES,
  FEATURE_TEMPLATE_MYCOURSES,
  FEATURE_TEMPLATE_TVOD,
} from 'app/modules/build-dragdrop/Builder/const';
import {
  BuilderCollection,
  BuilderCollectionItem,
  useBuilderContext,
  useContent,
} from 'app/modules/build-dragdrop/Builder/providers';
import { useSaveContext } from 'providers';
import { useAppBeingEdited } from 'app-context';
import { calculateCollectionItemPositions, getCollectionValuesFromNewCollection } from 'utils';

export const AddTemplate = ({ template }: { idx: number; template: Template; prefix?: string }) => {
  const { visiblePageId, drawerContext, setSectionDrawer } = useBuilderContext();
  const { collections, setNewCollection, setCollectionItems } = useContent();
  const { getTempId, setCollectionItemsToSave, setCollectionToSave } = useSaveContext();
  const appId = useAppBeingEdited();

  const displayName = useMemo(() => {
    const nameObj = getFormattedTemplateName(template);
    if (nameObj.variant) {
      return `${nameObj.name} (${nameObj.variant})`;
    }
    return nameObj.name;
  }, [template]);

  const addToCollection = useCallback(() => {
    if (!visiblePageId) {
      console.warn('AddTemplate', 'Failed to insert content unable to determine collection in Simulator');
      return;
    }
    const updateCollection = collections[visiblePageId];
    if (!updateCollection) {
      console.warn('AddTemplate', `Failed to insert content couldn't find the Tab with id: ${visiblePageId}`);
      return;
    }
    const index = drawerContext?.index ?? updateCollection.Items.length;

    /* Dragging in a new unlinked section from the Add Panel of the toolbox */
    const blockTemplateId = template.TemplateId;
    const tempId = getTempId();
    const tempTabId = `TempTab${tempId}`;
    const tempTabItemId = `TempTabItem${tempId}`;

    let newCollection: BuilderCollection | undefined = undefined;
    let newItem: BuilderCollectionItem = {
      ...BUILDMYAPP_COLLECTION_ITEM,
      AppId: appId,
      TabId: visiblePageId,
      TabItemId: tempTabItemId,
    };

    if (blockTemplateId === FEATURE_TEMPLATE_MYCOURSES || blockTemplateId === FEATURE_TEMPLATE_TVOD) {
      const myProductsCollection = Object.values(collections).find((c) => c.SourceId == MY_PRODUCTS_SOURCE_ID);
      newItem = { ...newItem, ChildId: myProductsCollection?.TabId as number, TemplateId: 3 };
      console.debug('BuilderInternal', 'Dragging in new feature section', { newItem });
    } else if (blockTemplateId === FEATURE_TEMPLATE_ALLCOURSES) {
      const allCoursesCollection = Object.values(collections).find((c) => c.SourceId == CATALOG_SOURCE_ID);
      newItem = { ...newItem, ChildId: allCoursesCollection?.TabId as number, TemplateId: 42 };
      console.debug('BuilderInternal', 'Dragging in new feature section', { newItem });
    } else {
      newItem = {
        ...newItem,
        ChildId: tempTabId,
        TemplateId: blockTemplateId,
      };
      // Insert a new collection
      newCollection = {
        ...BUILDMYAPP_COLLECTION,
        AppId: appId,
        TabId: tempTabId,
        TemplateId: blockTemplateId,
      };
      if (blockTemplateId === IN_PROGRESS_TEMPLATE) {
        newCollection.Name = 'In Progress';
      }
      setNewCollection(tempTabId, newCollection);
      setCollectionToSave(tempTabId, getCollectionValuesFromNewCollection(newCollection));
    }

    // Update the subtab to contain the new section
    console.debug('AddTemplate', 'Inserting new section', { newCollection, newItem });
    updateCollection.Items.splice(index, 0, newItem);
    calculateCollectionItemPositions(updateCollection.Items);
    updateCollection.Items = [...updateCollection.Items]; // Trigger dependent updates
    setCollectionItems(visiblePageId, updateCollection.Items);
    setCollectionItemsToSave(visiblePageId);
    setSectionDrawer(tempTabItemId, newItem?.ChildId.toString());
  }, [
    visiblePageId,
    collections,
    getTempId,
    setNewCollection,
    setCollectionToSave,
    setSectionDrawer,
    setCollectionItems,
    setCollectionItemsToSave,
  ]);

  return <DisplayPreview name={displayName} template={template} onClick={addToCollection} />;
};
