import styled from 'styled-components';

import { LoadingDots, SegmentTitle, SettingsGroup } from 'components';
import { DefaultAppPropertiesProvider, DefaultContentProvider, MainTabProvider } from 'providers';
import { TAB_HEIGHT, TabNavigationInner } from './TabNavigationInner';

const CustomLoader = () => (
  <SettingsGroup>
    <SegmentTitle title="Tab Navigation" $marginBottom="12px" />
    <LoadingContainer>
      <LoadingDots />
    </LoadingContainer>
  </SettingsGroup>
);

const LoadingContainer = styled.div`
  width: 100%;
  height: ${TAB_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const TabNavigation = () => (
  <DefaultAppPropertiesProvider customLoader={<CustomLoader />}>
    <DefaultContentProvider customLoader={<CustomLoader />} disableVideos updateOnRefetch>
      <MainTabProvider>
        <TabNavigationInner />
      </MainTabProvider>
    </DefaultContentProvider>
  </DefaultAppPropertiesProvider>
);
