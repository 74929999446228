import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

export type PurchaseScreenView = 'Subscription1' | 'Subscription2' | 'Purchase1' | 'Purchase2';

const defaultFunction = () => {
  console.warn('Unexpected function call mockup-provider');
};

/**
 * Different page views based on CurrentPage
 * CurrentTabId is the Main Tab we are within. If undefined, the tab bar will be hidden
 * CurrentTemplateId can be used in lieu of CurrentTabId. A tab of that template ID will be rendered without a real tab necessarily existing 
 * e.g. Profile tab can be rendered regardless of whether the app has a Profile tab

 * Render the content of the tab
 *  CurrentPage: undefined
 * Render the content of a collection
 *  CurrentPage: {itemId: number, itemType: 'collection'}
 * Render the content of a SubNav tab
 *  CurrentPage: {subItemId: number}
 * Render the content of a SubNav collection
 *  CurrentPage: {subNavItemId: number, itemId: number, itemType: 'collection'}
 */
export interface PageContext {
  itemId?: number | string;
  tabItemId?: number | string;
  itemType?: string; // Video/Collection
  subItemId?: number | string; // Which SubNav item is focussed
  subTabItemId?: number | string; // Necessary context to distinguish subtabs with the same content
  parentCollectionId?: number | string; // Required in VideoDetails screens
  scrollPosition?: number;
}

interface SetPageOptions {
  skipStack?: boolean; // When you go back ignore the current page (dont add it to the page stack)
  resetScrollPosition?: boolean;
}

interface ContextValue {
  disableDragDrop: boolean;
  disableTabNavigation: boolean;
  sideMenuVisible: boolean;
  navBarHidden: boolean;
  currentTabId?: number | string;
  setCurrentTabId: Dispatch<SetStateAction<number | string>>;
  currentTemplateId?: number;
  currentPage?: PageContext;
  setCurrentPage: (newPage: PageContext | undefined, options?: SetPageOptions) => void;
  goBack: () => void;
  screenHeight?: string;
  disableMargin?: boolean;
  navigationEnabled?: boolean;
  scrollPositionHistory: Record<string, number>;
  purchaseScreenView?: PurchaseScreenView;
}

const MockupContext = createContext<ContextValue>({
  disableDragDrop: false,
  disableTabNavigation: false,
  sideMenuVisible: false,
  navBarHidden: false,
  currentTabId: 0,
  setCurrentPage: defaultFunction,
  setCurrentTabId: defaultFunction,
  goBack: defaultFunction,
  scrollPositionHistory: {},
});

const getScrollHistoryKey = (page?: PageContext) => {
  if (!page) {
    return 'maintab';
  }
  return `${page?.tabItemId}_${page?.subTabItemId}`;
};

export interface MockupProviderProps {
  disableDragDrop?: ContextValue['disableDragDrop'];
  disableTabNavigation?: ContextValue['disableTabNavigation'];
  sideMenuVisible?: ContextValue['sideMenuVisible'];
  navBarHidden?: ContextValue['navBarHidden'];
  screenHeight?: ContextValue['screenHeight'];
  navigationEnabled?: ContextValue['navigationEnabled'];

  currentTabId?: number | string;
  setCurrentTabId: Dispatch<SetStateAction<number | string>>;
  currentTemplateId?: number;
  currentPage?: PageContext;
  // Doesn't currently handle all variations of SetStateAction
  setCurrentPage: (newPage: PageContext | undefined, options?: SetPageOptions) => void;
  purchaseScreenView?: PurchaseScreenView;
  children?: ReactNode;
}

const MockupContextProvider = ({
  children,
  disableDragDrop = false,
  disableTabNavigation = false,
  sideMenuVisible = false,
  navBarHidden = false,
  currentPage,
  setCurrentPage,
  navigationEnabled,
  purchaseScreenView,
  ...props
}: MockupProviderProps) => {
  const [pageStack, setPageStack] = useState<PageContext[]>([]);
  const [scrollPositionHistory, setScrollPositionHistory] = useState<Record<string, number>>({});

  // Wrapper around setCurrentPage to handle Back Button functionality
  const handlePage = (newPage: PageContext | undefined, options?: SetPageOptions) => {
    // Going back to tab level clears the stack
    if (!newPage) {
      setPageStack([]);
      setCurrentPage(undefined);
    } else {
      const position = options?.resetScrollPosition ? 0 : document.getElementById('Builder__Content')?.scrollTop;
      setScrollPositionHistory((existing) => ({
        ...existing,
        [getScrollHistoryKey(currentPage)]: position ?? 0,
      }));
      // Append existing page to stack
      // Browse to new page
      if (currentPage && !options?.skipStack) {
        setPageStack((prevStack) => {
          const stack = [...prevStack];
          stack.push(currentPage);
          return stack;
        });
      }
      setCurrentPage({ ...newPage });
    }
  };

  const goBack = () => {
    setScrollPositionHistory((existing) => ({
      ...existing,
      [getScrollHistoryKey(currentPage)]: 0,
    }));
    if (pageStack.length > 0) {
      const newPageStack = [...pageStack];
      // Browse back to the most recent value from the stack
      const nextPage = newPageStack.pop();
      if (nextPage) {
        setCurrentPage(nextPage);
        setPageStack(newPageStack);
        return;
      }
    }
    // Browse back to the tab level
    setCurrentPage(undefined);
  };

  return (
    <MockupContext.Provider
      value={{
        disableDragDrop,
        disableTabNavigation,
        sideMenuVisible,
        navBarHidden,
        currentPage,
        setCurrentPage: handlePage,
        goBack,
        scrollPositionHistory,
        navigationEnabled: navigationEnabled ?? true,
        purchaseScreenView,
        ...props,
      }}
    >
      {children}
    </MockupContext.Provider>
  );
};

const useMockupContext = () => {
  const context = useContext(MockupContext);
  if (context === undefined) {
    throw new Error('useMockupContext must be used within a MockupContextProvider');
  }
  return context;
};

export { MockupContextProvider, useMockupContext, getScrollHistoryKey };
