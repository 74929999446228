import { useMemo } from 'react';
import styled from 'styled-components';

import { NEUTRAL_5_COLOUR, NEUTRAL_8_COLOUR, NEUTRAL_10_COLOUR, HIGHLIGHT_PRIMARY_COLOUR } from 'theme';
import { useTemplates } from 'hooks';
import { FONT_10PX_SEMIBOLD, FONT_12PX_MEDIUM } from 'font';
import { ForwardIcon } from 'icons';
import { SegmentTitle } from 'components';

import { TemplateThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import {
  BuilderCollection,
  BuilderCollectionItem,
  useBuilderContext,
} from 'app/modules/build-dragdrop/Builder/providers';
import { getFormattedTemplateName } from 'app/modules/build-dragdrop/Builder/util';

/* Block - Template Selector */
const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TemplatePreview = styled.div`
  width: 100%;
  height: 71px;
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 8px;

  display: flex;
  align-items: center;
  padding: 12px 10px 12px 12px;
  margin-right: 4px;
  cursor: pointer;

  :hover {
    border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
  }
`;

const StyledTemplateThumbnail = styled(TemplateThumbnail)`
  margin-right: 12px;
  height: 47px;
`;

const Group = styled.div`
  ${FONT_10PX_SEMIBOLD};
  color: ${NEUTRAL_8_COLOUR};
`;

const Name = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
`;

const StyledForwardIcon = styled(ForwardIcon)`
  font-size: 16px;
  margin-left: auto;
`;

interface BlockDisplayTypeProps {
  collection?: BuilderCollection;
  item: BuilderCollectionItem;
}

export const SectionDisplayType = ({ collection, item }: BlockDisplayTypeProps) => {
  const { setDrawerContext } = useBuilderContext();
  const { getTemplateById } = useTemplates();
  const templateId = item.TemplateId || collection?.TemplateId;

  const templateName = useMemo(() => {
    if (templateId) {
      const template = getTemplateById(templateId);

      if (template) {
        return getFormattedTemplateName(template);
      }
    }
    return { group: '', name: 'No Template', variant: '' };
  }, [getTemplateById, templateId]);
  return (
    <Block>
      <SegmentTitle title="Section Display" />
      <TemplatePreview
        onClick={() => {
          setDrawerContext((prev) => ({ ...prev, showDisplayView: true }));
        }}
      >
        <StyledTemplateThumbnail templateId={templateId} height={47} width={60} />
        <div>
          <Group>{templateName.group}</Group>
          <Name>
            {templateName.variant.length > 0 ? `${templateName.name} - ${templateName.variant}` : templateName.name}
          </Name>
        </div>
        <StyledForwardIcon />
      </TemplatePreview>
    </Block>
  );
};
