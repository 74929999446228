import { ReactNode } from 'react';
import styled from 'styled-components';

import { FolderIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';

const EmptyWrapper = styled.div`
  height: 344px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledFolderIcon = styled(FolderIcon)`
  &&& {
    font-size: 33px;
    color: ${NEUTRAL_10_COLOUR};
  }
`;

const Heading = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_16PX_MEDIUM};
  margin: 12px 0 4px;
`;

const Subheading = styled.div`
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_14PX_REGULAR};
  max-width: 185px;
`;

interface ContentContainerProps {
  isEmpty?: boolean;
  children: ReactNode;
}

export const ContentContainer = ({ isEmpty, children }: ContentContainerProps) => {
  return (
    <>
      {isEmpty ? (
        <EmptyWrapper>
          <StyledFolderIcon />
          <Heading>No Content</Heading>
          <Subheading>You do not have any content yet.</Subheading>
        </EmptyWrapper>
      ) : (
        children
      )}
    </>
  );
};
