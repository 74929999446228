import styled from 'styled-components';

import { FONT_12PX_SEMIBOLD } from 'font';
import { NEUTRAL_10_COLOUR } from 'theme';
import { AppBackIcon } from 'icons';

import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { DrawerBackButton } from 'app/modules/build-dragdrop/Builder/drawer/components';

const Heading = styled.h2`
  &&&& {
    ${FONT_12PX_SEMIBOLD}
    color: ${NEUTRAL_10_COLOUR};
    margin-bottom: 20px;
    padding-bottom: 0;
  }
`;

const HeaderRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-shrink: 0;

  height: 82px;
`;

const StyledDrawerBackButton = styled(DrawerBackButton)`
  &&& {
    position: absolute !important;
    top: 30px;
    left: 20px;

    &&& .anticon {
      font-size: 22px;
    }
  }
`;

interface DrawerHeadingProps {
  heading?: string;
}

export const DrawerNavHeading = ({ heading }: DrawerHeadingProps) => {
  const { setDrawerContext } = useBuilderContext();

  const goBack = () => {
    setDrawerContext((context) => ({
      ...context,
      showDisplayView: false,
      showCollectionDisplayView: false,
      showItemsView: false,
    }));
  };

  return (
    <HeaderRow>
      <StyledDrawerBackButton medium tertiary icon={<AppBackIcon />} onClick={goBack} />
      {heading && <Heading>{heading}</Heading>}
    </HeaderRow>
  );
};
