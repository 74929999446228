import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

import { Modal, CustomButton } from 'components';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { DANGER_COLOUR } from 'theme';
import { useCollections } from 'hooks';
import { Collection } from 'api';

const EXAMPLE_URL = 'https://app.kajabi.com/admin/products/123456';

const Content = styled.div`
  width: 450px;
  padding: 32px 32px 0;
`;

const UrlInput = styled(Input)`
  #react-app && {
    margin-bottom: 20px;
  }
`;

const Header = styled.div`
  ${FONT_16PX_MEDIUM};
  margin-bottom: 10px;
`;

const Description = styled.div`
  ${FONT_14PX_REGULAR};
  margin-bottom: 20px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ConfirmButton = styled(CustomButton)`
  &&&& {
    margin-left: 10px;
    width: 70px;
  }
`;

const Error = styled.div`
  ${FONT_14PX_REGULAR};
  color: ${DANGER_COLOUR};
`;

interface AddKajabiProductModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  syncKajabiProduct: (productId: string) => void;
}

export const AddKajabiProductModal = ({ visible, setVisible, syncKajabiProduct }: AddKajabiProductModalProps) => {
  const { data: collectionsData } = useCollections(Infinity);
  const [url, setUrl] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);

  const resetAndClose = useCallback(() => {
    setUrl('');
    setError('');
    setVisible(false);
  }, [setVisible, setUrl, setError]);

  const handleConfirm = useCallback(() => {
    if (url) {
      const trimmedUrl = url.trim();
      if (trimmedUrl === EXAMPLE_URL) {
        setError('Please enter your own course URL');
      } else {
        const productID = trimmedUrl.split('products/').pop() as string;
        if (isNaN(parseInt(productID))) {
          setError('Invalid URL format');
        } else {
          const sameSourceID = (collection: Collection) => collection.SourceId === productID;
          if (collectionsData && collectionsData.collections.some(sameSourceID)) {
            setError('This course has already been added');
          } else {
            syncKajabiProduct(productID);
            resetAndClose();
          }
        }
      }
    }
  }, [url, collectionsData, syncKajabiProduct, setVisible, setUrl, setError, resetAndClose]);

  return (
    <Modal
      open={visible}
      onCancel={resetAndClose}
      width="auto"
      $height="100%"
      $radius="2px"
      destroyOnClose
      footer={[
        <Footer key="modal-footer">
          <CustomButton secondary onClick={resetAndClose}>
            Cancel
          </CustomButton>
          <ConfirmButton onClick={handleConfirm} disabled={url.length === 0}>
            OK
          </ConfirmButton>
        </Footer>,
      ]}
    >
      <Content>
        <Header>Add a new Kajabi course</Header>
        <Description>{`Please enter the Kajabi Course URL you'd like to sync. Example: ${EXAMPLE_URL}`}</Description>
        <UrlInput value={url} onChange={(e) => setUrl(e.target.value)} />
        {error && <Error>{error}</Error>}
      </Content>
    </Modal>
  );
};
