import { Menu } from 'antd';
import styled from 'styled-components';

import { FONT_14PX_MEDIUM } from 'font';
import { NEUTRAL_11_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_8_COLOUR, NEUTRAL_9_COLOUR } from 'theme';

const ITEM_HEIGHT = '32px';
const SUB_ITEM_HEIGHT = '26px';
const ITEM_COLOUR = '#fff';

export const NavMenu = styled(Menu)`
  &&&& .ant-menu {
    box-shadow: none;
    background-color: ${NEUTRAL_11_COLOUR};
  }

  &&&&.ant-menu {
    background-color: ${NEUTRAL_11_COLOUR};
    margin: auto;
    color: ${ITEM_COLOUR};
    box-shadow: none;
    border: none;
  }

  &&&& .ant-menu-inline {
    border: none;
    border-left: 2px solid ${NEUTRAL_5_COLOUR};
    margin: 12px 0 4px 16px;
    border-radius: 0;

    .ant-menu-item {
      border-radius: 4px;
      width: fit-content;
      height: ${SUB_ITEM_HEIGHT} !important;
      margin: 0 0 8px 10px;

      :last-child {
        margin-bottom: 0;
      }
    }

    .ant-menu-item:not(.ant-menu-item-selected) a {
      ${FONT_14PX_MEDIUM};
    }
  }

  &&&& .ant-menu-title-content,
  &&&& .ant-menu-title-content a {
    ${FONT_14PX_MEDIUM};
  }

  &&&&& .ant-menu-item-selected a,
  &&&&& .ant-menu-submenu-selected .ant-menu-title-content,
  &&&&& .ant-menu-submenu-open .ant-menu-title-content,
  &&&&& .ant-menu-item:hover .ant-menu-title-content a,
  &&&&& .ant-menu-submenu-title:hover .ant-menu-title-content {
    color: #fff;
  }

  svg {
    font-size: 20px;
    color: #fff;
  }

  &&&& .ant-menu-submenu-title {
    height: ${ITEM_HEIGHT} !important;
    border-radius: 6px;
    user-select: none;
    padding: 0 6px !important;
    margin-top: 16px;
    margin-bottom: 0 !important;
  }

  &&&& .ant-menu-item {
    height: ${ITEM_HEIGHT} !important;
    margin-top: 16px;
    border-radius: 6px;
    padding: 0 6px !important;

    a {
      color: ${ITEM_COLOUR};
    }

    ::after {
      border-right: none;
    }
  }

  &&&& .ant-menu-item-selected,
  &&&& .ant-menu-submenu-selected > .ant-menu-submenu-title:not(.ant-menu-submenu-title) {
    background-color: ${NEUTRAL_8_COLOUR};
    a {
      cursor: default !important;
    }
  }

  &&&& .ant-menu-submenu-title:hover,
  &&&& .ant-menu-submenu-title:focus-visible,
  &&&& .ant-menu-item:hover:not(.ant-menu-item-selected),
  &&&& .ant-menu-item-active:not(.ant-menu-item-selected) {
    color: ${ITEM_COLOUR};
    background-color: ${NEUTRAL_9_COLOUR};
    box-shadow: none;
  }

  &&&& .ant-menu-submenu-active {
    color: unset;
  }
`;
