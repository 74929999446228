import { useState, useCallback, useEffect, useMemo } from 'react';
import { Form } from 'antd';

import { SaveFailed } from 'components';
import { useUnsavedChanges } from 'providers';

import {
  AppPropertyToSave,
  AppBasicInfoToSave,
  SecureAppPropertyToSave,
  AppBasicInfoKey,
  SOURCE_KAJABI,
  SOURCE_VHX,
} from 'api';
import {
  useAppBasicInfo,
  useAppProperties,
  useSecureAppProperties,
  useSaveAppBasicInfo,
  useSaveAppProperties,
  useSaveSecureAppProperties,
  useTemplates,
} from 'hooks';

import { PropertyType, Field } from '../AdminSettings/const';

const EXCLUDED_TEMPLATES = [44];

export interface Filename {
  value: string | undefined;
  touched: boolean;
}

const getPropertyValue = (value: unknown, valueType?: 'string' | 'binary', defaultOn?: boolean) => {
  if (valueType === 'binary') {
    if (defaultOn) {
      return value !== '0';
    }
    return value === '1';
  }
  return value;
};

export const useAdminSettings = (PROPERTIES: Record<string, PropertyType>, dataSource: string) => {
  const { data: appBasicInfo, isLoading: appBasicInfoIsLoading, isError: appBasicInfoIsError } = useAppBasicInfo();
  const { data: appProperties, isLoading: appPropertiesIsLoading, isError: appPropertiesIsError } = useAppProperties();
  const {
    data: secureAppProperties,
    isLoading: secureAppPropertiesIsLoading,
    isError: secureAppPropertiesIsError,
  } = useSecureAppProperties();
  const saveAppBasicInfo = useSaveAppBasicInfo();
  const saveAppProperties = useSaveAppProperties();
  const saveSecureAppProperties = useSaveSecureAppProperties();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const { getTemplateOptionsByType } = useTemplates();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<Record<string, unknown>>();
  const [filenames, setFilenames] = useState<Record<string, Filename>>({});

  const TEMPLATE_FIELDS: Record<string, Field[]> = useMemo(() => {
    const templates = getTemplateOptionsByType('collection');
    const templateOptions = templates
      ? templates
          .filter((t) => !EXCLUDED_TEMPLATES.includes(t.TemplateId))
          .map((t) => ({ name: t.DisplayName, value: t.TemplateId }))
      : [];
    return {
      Kajabi: [
        {
          name: 'Product',
          label: 'Products',
          type: 'select',
          defaultVal: '',
          propertyType: 'template',
          options: templateOptions,
        },
        {
          name: 'Default',
          label: 'Collections',
          type: 'select',
          defaultVal: '',
          propertyType: 'template',
          options: templateOptions,
        },
      ],
      VHX: [
        {
          name: 'category',
          label: 'Categories',
          type: 'select',
          defaultVal: '',
          propertyType: 'template',
          options: templateOptions,
        },
        {
          name: 'movie',
          label: 'Movies',
          type: 'select',
          defaultVal: '',
          propertyType: 'template',
          options: templateOptions,
        },
        {
          name: 'playlist',
          label: 'Playlists',
          type: 'select',
          defaultVal: '',
          propertyType: 'template',
          options: templateOptions,
        },
        {
          name: 'series',
          label: 'Series',
          type: 'select',
          defaultVal: '',
          propertyType: 'template',
          options: templateOptions,
        },
        {
          name: 'season',
          label: 'Seasons',
          type: 'select',
          defaultVal: '',
          propertyType: 'template',
          options: templateOptions,
        },
      ],
    };
  }, [getTemplateOptionsByType]);

  useEffect(() => {
    if (appBasicInfo && appProperties && secureAppProperties) {
      const values: Record<string, unknown> = {};

      for (const key in PROPERTIES) {
        const { type, valueType, defaultOn, isFile } = PROPERTIES[key];
        if (type === 'template') {
          values[key] = appProperties.TemplatesForCollections
            ? JSON.parse(appProperties.TemplatesForCollections)[key]
            : undefined;
        } else if (isFile) {
          setFilenames((prev) => ({
            ...prev,
            [key]: {
              value:
                type === 'appBasicInfo'
                  ? (appBasicInfo[key as AppBasicInfoKey] as string)
                  : type === 'secureAppProperty'
                  ? secureAppProperties[key]
                  : appProperties[key],
              touched: false,
            },
          }));
        } else if (type === 'appBasicInfo') {
          values[key] = getPropertyValue(appBasicInfo[key as AppBasicInfoKey], valueType, defaultOn);
        } else if (type === 'appProperty') {
          values[key] = getPropertyValue(appProperties[key], valueType, defaultOn);
        } else {
          values[key] = getPropertyValue(secureAppProperties[key], valueType, defaultOn);
        }
      }
      setInitialValues(values);
    }
  }, [appBasicInfo, appProperties, secureAppProperties, setFilenames]);

  useEffect(() => {
    !isSaving && form.resetFields();
  }, [initialValues, isSaving]);

  const onValuesChange = useCallback(() => {
    if (!unsavedChanges) {
      setUnsavedChanges(true);
    }
  }, [unsavedChanges, setUnsavedChanges]);

  const onFilenameChange = useCallback(
    (key: string, _filename: string) => {
      setFilenames((prev) => ({ ...prev, [key]: { value: _filename, touched: true } }));
      onValuesChange();
    },
    [setFilenames, onValuesChange],
  );

  const getValueToSave = useCallback(
    (field: string, stripWhitespace?: boolean) => {
      const valueType = PROPERTIES[field].valueType;
      if (valueType === 'binary') {
        return form.getFieldValue(field) ? '1' : '0';
      } else {
        const value = form.getFieldValue(field);
        return stripWhitespace ? value.replace(/\s+/g, '') : value;
      }
    },
    [form],
  );

  const saveForm = useCallback(async () => {
    setIsSaving(true);
    setUnsavedChanges(false);
    const fieldsToSave = form.getFieldsValue(true, (meta) => meta.touched);

    const appBasicInfoToSave: AppBasicInfoToSave = {};
    const appPropertiesToSave: AppPropertyToSave[] = [];
    const secureAppPropertiesToSave: SecureAppPropertyToSave[] = [];

    for (const field in fieldsToSave) {
      const value = getValueToSave(field, PROPERTIES[field].stripWhitespace);
      if (PROPERTIES[field].type === 'appBasicInfo') {
        appBasicInfoToSave[field] = value;
      } else if (PROPERTIES[field].type === 'appProperty') {
        appPropertiesToSave.push({ Name: field, Value: value });
      } else if (PROPERTIES[field].type === 'secureAppProperty') {
        secureAppPropertiesToSave.push({ name: field, value: value });
      }
    }

    // Save TemplatesForCollections appProperty if any of the 'template' select inputs have been touched
    if (dataSource === SOURCE_KAJABI && (fieldsToSave.Product || fieldsToSave.Default)) {
      const fields = ['Product', 'Default'];
      const templateValues: Record<string, number> = {};
      fields.forEach((field) => {
        const selectedValue = getValueToSave(field);
        if (selectedValue) {
          templateValues[field] = selectedValue;
        }
      });
      appPropertiesToSave.push({ Name: 'TemplatesForCollections', Value: JSON.stringify(templateValues) });
    } else if (
      dataSource === SOURCE_VHX &&
      (fieldsToSave.category ||
        fieldsToSave.movie ||
        fieldsToSave.playlist ||
        fieldsToSave.series ||
        fieldsToSave.season)
    ) {
      const fields = ['category', 'movie', 'playlist', 'series', 'season'];
      const templateValues: Record<string, number> = {};
      fields.forEach((field) => {
        const selectedValue = getValueToSave(field);
        if (selectedValue) {
          templateValues[field] = selectedValue;
        }
      });
      appPropertiesToSave.push({ Name: 'TemplatesForCollections', Value: JSON.stringify(templateValues) });
    }

    // Add filenames to be saved if they have been touched
    for (const [key, { touched, value }] of Object.entries(filenames)) {
      const type = PROPERTIES[key].type;
      if (touched) {
        if (type === 'appProperty') {
          appPropertiesToSave.push({ Name: key, Value: value ?? '' });

          // If a Font property is being updated, the equivalent Android property should also be updated
          if (key.includes('CustomFont')) {
            appPropertiesToSave.push({ Name: key.replace('Apple', 'Android'), Value: value ?? '' });
          }
        } else if (type === 'secureAppProperty') {
          secureAppPropertiesToSave.push({ name: key, value: value ?? '' });
        }
      }
    }

    if (Object.keys(appBasicInfoToSave).length > 0) {
      try {
        await saveAppBasicInfo.mutateAsync(appBasicInfoToSave);
      } catch (error) {
        SaveFailed(error as Error);
        setUnsavedChanges(true);
        setIsSaving(false);
      }
    }

    if (appPropertiesToSave.length > 0) {
      try {
        await saveAppProperties.mutateAsync(appPropertiesToSave);
      } catch (error) {
        SaveFailed(error as Error);
        setUnsavedChanges(true);
        setIsSaving(false);
      }
    }

    if (secureAppPropertiesToSave.length > 0) {
      try {
        await saveSecureAppProperties.mutateAsync(secureAppPropertiesToSave);
      } catch (error) {
        SaveFailed(error as Error);
        setUnsavedChanges(true);
        setIsSaving(false);
      }
    }

    setIsSaving(false);
    form.resetFields();
  }, [setIsSaving, setUnsavedChanges, form, appProperties, saveAppProperties, saveSecureAppProperties]);

  return {
    appBasicInfo,
    appProperties,
    form,
    unsavedChanges,
    isSaving,
    isLoading: appBasicInfoIsLoading || appPropertiesIsLoading || secureAppPropertiesIsLoading,
    isError: appBasicInfoIsError || appPropertiesIsError || secureAppPropertiesIsError,
    initialValues,
    filenames,
    TEMPLATE_FIELDS,
    onFilenameChange,
    onValuesChange,
    saveForm,
  };
};
