import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { CustomButton } from 'components';
import { NEUTRAL_10_COLOUR } from 'theme';
import { AppBackIcon } from 'icons';
import { getTabNameFromCollection } from 'utils';

import { useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';

const BackButton = styled(CustomButton)`
  &&& {
    margin-top: 8px;
    padding: 4px 5px 4px 4px;
    color: ${NEUTRAL_10_COLOUR};

    .anticon,
    span {
      margin-left: 0 !important;
      margin-right: 2px !important;
    }

    .anticon {
      font-size: 22px;
    }
  }
`;

const SemiBold = styled.span`
  font-weight: 600;
`;

export const ToolboxBackButton = () => {
  const { goBack } = useHistory();
  const { currentTabId } = useMockupContext();
  const { collections } = useContent();
  const currentTab = currentTabId ? collections[currentTabId] : undefined;

  return (
    <BackButton tertiary medium onClick={goBack} icon={<AppBackIcon />}>
      <SemiBold>{`Build My App / ${currentTab ? getTabNameFromCollection(currentTab) : 'Tab'}`}</SemiBold>
    </BackButton>
  );
};
