import { useEffect, useState } from 'react';

import { useAppProperties, useCollections, useVideos } from 'hooks';
import { Collection, Video } from 'api';
import { BuilderCollection } from 'providers';

export const useContentScreen = () => {
  const {
    data: collectionData,
    isRefetching: collectionsIsRefetching,
    isError: collectionsIsError,
  } = useCollections(Infinity);
  const { data: videoData, isRefetching: videosIsRefetching, isError: videosIsError } = useVideos(Infinity);
  const { data: appProperties, isError: appPropertiesIsError } = useAppProperties();
  const [collections, setCollections] = useState<Record<string | number, BuilderCollection>>();
  const [videos, setVideos] = useState<Record<string | number, Video>>();

  useEffect(() => {
    if (!!collectionData && !collectionsIsRefetching) {
      const localCollections = collectionData.collection_by_id as Record<number, Collection>;
      setCollections(localCollections);
    }
  }, [collectionData, collectionsIsRefetching]);

  useEffect(() => {
    if (!!videoData && !videosIsRefetching) {
      setVideos(videoData.video_by_id);
    }
  }, [videoData, videosIsRefetching]);

  return {
    collections,
    videos,
    setVideos,
    setCollections,
    isLoading: !collections || !videos || !appProperties,
    isError: collectionsIsError || videosIsError || appPropertiesIsError,
  };
};
