import { ReactNode } from 'react';
import styled from 'styled-components';

import { NEUTRAL_5_COLOUR } from 'theme';
import { GroupTitle } from 'components/Text/SegmentTitle';

const ChecklistContainer = styled.div<{ $border?: string; $padding?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${({ $border }) => $border || `1px solid ${NEUTRAL_5_COLOUR}`};
  border-radius: 8px;
  margin-bottom: 40px;
  padding: ${({ $padding }) => $padding || `0`};
`;

const List = styled.ul`
  #react-app && {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

interface ListContainerProps {
  heading?: string;
  children?: ReactNode;
  $border?: string;
  $padding?: string;
}

export const ListContainer = ({ heading, children, ...props }: ListContainerProps) => (
  <ChecklistContainer {...props}>
    {heading && <GroupTitle title={heading} />}
    <List>{children}</List>
  </ChecklistContainer>
);
