import { MouseEvent, MutableRefObject, PointerEvent, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDraggable } from 'react-use-draggable-scroll';

import { CustomColor, getCollectionName, getContentName, useCustomColor, usePointerOnClick } from 'utils';
import { useContent } from 'providers';
import { NEUTRAL_3_COLOUR } from 'theme';
import { APP_FONT_12PX_MEDIUM } from 'mockup-font';

import { CollectionTitle } from 'app/modules/build-dragdrop/Builder/mockup/components/CollectionViews/CollectionTitle';
import { useAppCollectionContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { useAppTheme, useInProgressItems } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { THUMBNAIL_RADIUS } from 'app/modules/build-dragdrop/Builder/const';
import { isItemClickable } from 'app/modules/build-dragdrop/Builder/util';
import { CarouselItem, CarouselWrapper, DotClickableWrapper, ItemTitle } from './CollectionCarousel';

export const StyledThumbnail = styled(ContentThumbnail)`
  cursor: ${usePointerOnClick};
  width: 100%;
  height: unset;
`;
const DummyThumbnail = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background-color: ${NEUTRAL_3_COLOUR};
  border-radius: ${THUMBNAIL_RADIUS} ${THUMBNAIL_RADIUS} 0 0;
`;
const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ElevationBg = styled.div<CustomColor>`
  background-color: ${useCustomColor};
  padding: 10px 16px 16px 16px;
  width: 100%;
  height: fit-content;
  border-radius: 0 0 4px 4px;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));
  margin-bottom: 8px;
`;

const ProgressBar = styled.div<CustomColor>`
  background-color: ${useCustomColor};
  border-radius: 10px;
  width: 30%;
  height: 8px;
`;
const ProgressContainer = styled.div`
  margin-top: 12px;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background-color: #ececec;
`;
const ProgressSubtitle = styled.div`
  margin-top: 8px;
  width: 100%;
  text-align: right;
  ${APP_FONT_12PX_MEDIUM};
  color: ${useCustomColor};
`;

const CarouselDots = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CarouselDot = styled.div<{ active?: boolean } & CustomColor>`
  width: 6px;
  height: 6px;
  background-color: ${useCustomColor};
  opacity: ${({ active }) => (active ? 1 : 0.45)};
  border-radius: 50%;
`;
const DummyCarouselItem = ({
  color,
  elevationColor,
  highlightColor,
}: {
  color: string;
  elevationColor: string;
  highlightColor: string;
}) => {
  return (
    <CarouselItem key="DummyCarouselItem">
      <DummyThumbnail id="DummyThumbnail" />
      <ElevationBg $color={elevationColor}>
        <ItemTitle color={color} />
        <ProgressContainer>
          <ProgressBar $color={highlightColor} />
        </ProgressContainer>
        <ProgressSubtitle $color={highlightColor}>30% Complete</ProgressSubtitle>
      </ElevationBg>
    </CarouselItem>
  );
};

export const CollectionInProgress = () => {
  const { collection, onItemClick } = useAppCollectionContext();
  const { getContentForItem } = useContent();
  const { getDesignProperty } = useAppTheme();
  const primaryElevationHEX = getDesignProperty('primaryElevationHEX') as string;
  const highlightHEX = getDesignProperty('highlightHEX') as string;

  // Determines which carousel dot is highlighted/active
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const { items: inProgressItems } = useInProgressItems(collection?.TabId);

  const items = useMemo(() => {
    return inProgressItems.slice(0, 2);
  }, [inProgressItems]);

  // Click to drag carousel
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref, { decayRate: 0.7 });

  // Allow dragging the carousel to be dropped outside the frame
  const handlePointerDown = (event: PointerEvent<HTMLDivElement>) => {
    // Detect when they let go of the drag-to-scroll outside of the current element
    (event.target as HTMLDivElement).setPointerCapture(event.pointerId);
  };

  // Stop phone from dragging while dragging the carousel
  const handleMouseDown = (event: MouseEvent<HTMLElement>) => {
    events.onMouseDown(event);
    event.stopPropagation();
  };

  // Scroll to a specific element in the carousel
  const scrollToIdx = (idx: number) => {
    ref.current.style.scrollBehavior = 'smooth';
    ref.current.children[idx].scrollIntoView({ block: 'center' });
    ref.current.style.scrollBehavior = 'unset';
  };

  // Scroll to nearest item when stopping the drag scroll
  const handlePointerUp = (event: PointerEvent<HTMLDivElement>) => {
    // They have stopped dragging to scroll
    (event.target as HTMLDivElement).releasePointerCapture(event.pointerId);
    setTimeout(() => {
      // Calculate based on the scroll position which is the closest element
      const numItems = items?.length ?? 3; // 3 dummy items
      const { scrollLeft, scrollWidth } = ref.current;
      const itemWidth = scrollWidth / numItems;
      const idx = Math.round(scrollLeft / itemWidth);
      scrollToIdx(idx);
      setSelectedIdx(idx);
    }, 200);
  };
  const handleDotClick = (position: number) => {
    const idx = position - 1;
    setSelectedIdx(idx);
    scrollToIdx(idx);
  };

  const titleColor = getDesignProperty('primaryItemHEX') as string;

  return (
    <CarouselContainer>
      <CollectionTitle
        title={collection ? getCollectionName(collection) : 'In Progress'}
        marginBottom="8px"
        padding="0 8px"
      />
      <CarouselWrapper
        ref={ref}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onMouseDown={handleMouseDown}
      >
        {items?.length
          ? items?.map((item, idx) => {
              const content = getContentForItem(item);
              return content ? (
                <CarouselItem key={item.TabItemId}>
                  <StyledThumbnail
                    thumbnail={content}
                    size="medium"
                    onClick={onItemClick && isItemClickable(content, item) ? () => onItemClick(item) : undefined}
                    overrideRadius={`${THUMBNAIL_RADIUS} ${THUMBNAIL_RADIUS} 0 0`}
                  />
                  <ElevationBg $color={primaryElevationHEX}>
                    <ItemTitle color={titleColor} text={getContentName(content, item.Type)} />
                    <ProgressContainer>
                      <ProgressBar $color={highlightHEX} />
                    </ProgressContainer>
                    {/* Mock some products with 30% progress */}
                    <ProgressSubtitle $color={highlightHEX}>30% Complete</ProgressSubtitle>
                  </ElevationBg>
                </CarouselItem>
              ) : (
                <DummyCarouselItem
                  color={titleColor}
                  elevationColor={primaryElevationHEX}
                  highlightColor={highlightHEX}
                  key={idx}
                />
              );
            })
          : [1, 2, 3].map((key) => (
              <DummyCarouselItem
                color={titleColor}
                elevationColor={primaryElevationHEX}
                highlightColor={highlightHEX}
                key={key}
              />
            ))}
      </CarouselWrapper>
      <CarouselDots>
        {items?.length
          ? items?.map((item) => {
              return (
                <DotClickableWrapper
                  onClick={() => handleDotClick(item.Position)}
                  key={`DotClickableWrapper_${item.TabItemId}_${item.Position}`}
                >
                  <CarouselDot active={selectedIdx + 1 === item.Position} $color={highlightHEX} />
                </DotClickableWrapper>
              );
            })
          : [1, 2, 3].map((key) => (
              <DotClickableWrapper onClick={() => handleDotClick(key)} key={`DummyClickable_${key}`}>
                {/*A single active dot to match the dummy item*/}
                <CarouselDot active={selectedIdx + 1 === key} $color={highlightHEX} />
              </DotClickableWrapper>
            ))}
      </CarouselDots>
    </CarouselContainer>
  );
};
