import { useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { secondsToTime } from 'utils/DateUtils';
import { DEFAULT_THUMBNAIL, DEFAULT_THUMBNAIL_SMALL } from 'utils';
import { NEUTRAL_5_COLOUR } from 'theme';

export interface ThumbnailProps {
  url: string | null;
  duration?: number | null;
  durationFontSize?: string;
  durationPosition?: DurationPosition;
  title?: string;
  width: string;
  height: string;
  border?: string;
  borderRadius?: string;
  handleImageLoaded?: () => void;
}

interface DurationPosition {
  bottom: string;
  right: string;
}

interface ThumbnailContainerProps {
  defaultThumbnail: string;
  width: string;
  height: string;
  border?: string;
  borderRadius?: string;
}

const ThumbnailContainer = styled.div<ThumbnailContainerProps>`
  background-image: ${({ defaultThumbnail }) => `url(${defaultThumbnail})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: ${({ border }) => border || `1px solid ${NEUTRAL_5_COLOUR}`};
  border-radius: ${({ borderRadius }) => borderRadius || `4px`};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  flex: 0 0 auto;
`;

interface ImageOverlayProps {
  backgroundImage: string;
  borderRadius?: string;
}

const ImageOverlay = styled.div<ImageOverlayProps>`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: ${({ borderRadius }) => borderRadius || `4px`};
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
`;

interface GradientOverlayProps {
  borderRadius?: string;
}

const GradientOverlay = styled.div<GradientOverlayProps>`
  width: 100%;
  height: 22px;
  height: 33%;
  background: linear-gradient(0, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: ${({ borderRadius }) => borderRadius || `4px`};
`;

const TitleLabel = styled(Typography.Paragraph)`
  #react-app && {
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: #fff;
    font-size: 11px;
    line-height: 13px;
    font-weight: 600;
    width: 75%;
    margin-bottom: 0;
  }
`;

interface DurationProps {
  durationFontSize?: string;
  durationPosition?: DurationPosition;
}

const Duration = styled.div<DurationProps>`
  position: absolute;
  bottom: ${({ durationPosition }) => durationPosition?.bottom ?? '10%'};
  right: ${({ durationPosition }) => durationPosition?.right ?? '6%'};
  padding: 2px 5px;
  color: #fefefe;
  font-size: ${({ durationFontSize }) => durationFontSize ?? '10px'};
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
`;

export const Thumbnail = ({
  url,
  duration,
  durationFontSize,
  durationPosition,
  title,
  width,
  borderRadius,
  handleImageLoaded,
  ...props
}: ThumbnailProps) => {
  const defaultUrl = parseInt(width) < 45 ? DEFAULT_THUMBNAIL_SMALL : DEFAULT_THUMBNAIL;
  const imageUrl = url ?? defaultUrl;

  useEffect(() => {
    if (handleImageLoaded) {
      const img = new Image();

      if (url) {
        img.src = url;
        const int = setInterval(() => {
          if (img.complete) {
            clearInterval(int);
            handleImageLoaded();
          }
        }, 50);
      } else {
        handleImageLoaded();
      }
    }
  }, [url, handleImageLoaded]);

  return (
    <ThumbnailContainer
      data-testid="thumbnail-container"
      borderRadius={borderRadius}
      defaultThumbnail={defaultUrl}
      width={width}
      {...props}
    >
      <ImageOverlay backgroundImage={imageUrl} data-testid="thumbnail-image-overlay" borderRadius={borderRadius}>
        {title && <TitleLabel ellipsis={{ rows: 2 }}>{title}</TitleLabel>}
        {duration && (
          <Duration durationFontSize={durationFontSize} durationPosition={durationPosition} data-testid="duration">
            {secondsToTime(duration)}
          </Duration>
        )}
        {title && <GradientOverlay borderRadius={borderRadius} />}
      </ImageOverlay>
    </ThumbnailContainer>
  );
};
