import { ReactNode } from 'react';
import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { getCollectionName } from 'utils';
import {
  CollectionThumbnail,
  DrawerHeading,
  DrawerScrollContainer,
  WebsiteURL,
} from 'app/modules/build-dragdrop/Builder/drawer/components';
import { CollectionTitle } from 'components';
import { COLLECTION_PROPERTY_WEBSITE_URL } from 'api';

interface WebsiteDrawerProps {
  collection: BuilderCollection;
  type?: ReactNode;
}

export const WebsiteDrawer = ({ collection, type }: WebsiteDrawerProps) => {
  const { setCollectionPropertyToSave } = useSaveContext();
  const { setCollectionProperty } = useContent();
  return (
    <>
      <DrawerHeading heading={getCollectionName(collection)} type={type ?? 'Website'} />
      <DrawerScrollContainer paddingBottom="0">
        <CollectionTitle size="middle" TabId={collection.TabId} Name={collection.Name} $marginTop="0" showDeepLink />
        <WebsiteURL
          URL={collection.Properties?.Website ?? ''}
          onChange={(URL) => {
            setCollectionProperty(collection.TabId, COLLECTION_PROPERTY_WEBSITE_URL, URL);
            setCollectionPropertyToSave(collection.TabId, COLLECTION_PROPERTY_WEBSITE_URL, URL);
          }}
        />
        <CollectionThumbnail collection={collection} />
      </DrawerScrollContainer>
    </>
  );
};
