import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { Credentials, useAxiosInstance, validateAccount } from 'api';
import { useDataSource } from 'providers';

import { useRecordAction } from 'hooks';

export const useValidate = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const recordAction = useRecordAction();
  const dataSource = useDataSource();

  return useMutation<unknown, AxiosError, Credentials>(
    (payload) => {
      return validateAccount(client, appId, dataSource, payload);
    },
    {
      onSuccess: () => {
        recordAction.mutate({ action: 'Validated account credentials during wizard' });
      },
      retry: false,
    },
  );
};
