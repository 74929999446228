import { Collection, TAB_ICON_URL } from 'api';

// Tabs are collections with 'IsMainTab' set to '1'
export interface Tab {
  title?: string;
  icon?: string;
  active: boolean;
  enabled: boolean;
}

export const getTabIconFilename = (tab: Tab) => {
  if (tab.icon) {
    return `${TAB_ICON_URL}${tab.icon}`;
  }
  return undefined;
};

export const getTabIconFromCollection = (col: Collection | Pick<Collection, 'Icon'>) => {
  const tab = { icon: col.Icon } as Tab;
  return getTabIconFilename(tab);
};

export const getTabNameFromCollection = (
  col: Collection | Pick<Collection, 'NavBarTitleText' | 'Name' | 'Properties'>,
) => {
  return col.Properties?.NavBarTitleText || col.NavBarTitleText || col.Name;
};

export const getTabNavBarTitleFromCollection = (
  col: Collection | Pick<Collection, 'NavBarTitleText' | 'Properties'>,
) => {
  return col.Properties?.NavBarTitleText || col.NavBarTitleText;
};
