import { PageContainer } from 'components';
import { useAppProperties } from 'hooks';
import { isUserAdmin } from 'utils';
import { PAGE_CONTAINER_MIDDLE_WIDTH, PAGE_CONTAINER_WIDE_WIDTH } from 'theme';
import { DefaultContentProvider, useLocalProducts } from 'providers';

import { useOffers } from '../hooks/useOffers';
import { OfferDetails, OffersTable } from './components';

export const OffersInternal = () => {
  const { isLoading, isError } = useOffers();
  const { data: appProperties, isLoading: appPropertiesIsLoading, isError: appPropertiesIsError } = useAppProperties();
  const { products, activeProductId, setActiveProductId } = useLocalProducts();

  const activeProduct = activeProductId ? products?.get(activeProductId) : undefined;

  const isReadOnly = appProperties?.RolloutOffersScreen !== '1' && !isUserAdmin();

  return (
    <PageContainer
      heading={activeProduct ? activeProduct.DisplayName : 'Offers'}
      subheading={activeProduct ? undefined : 'View and sync your offers'}
      isLoading={isLoading || appPropertiesIsLoading}
      isError={isError || appPropertiesIsError}
      headingButton={!isReadOnly && 'save'}
      contentMaxWidth={activeProduct ? PAGE_CONTAINER_MIDDLE_WIDTH : PAGE_CONTAINER_WIDE_WIDTH}
      breadcrumbs={
        activeProduct
          ? {
              items: [{ label: 'Offers', handleClick: () => setActiveProductId(undefined) }],
              currentScreen: activeProduct.DisplayName,
            }
          : undefined
      }
    >
      {activeProductId && products?.get(activeProductId) ? (
        <DefaultContentProvider>
          <OfferDetails productId={activeProductId} />
        </DefaultContentProvider>
      ) : (
        <OffersTable />
      )}
    </PageContainer>
  );
};
