import { useSpring, animated } from '@react-spring/web';
import styled from 'styled-components';

import { NEUTRAL_4_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { FONT_12PX_SEMIBOLD } from 'font';

const Wrapper = styled.div`
  width: 100%;
  height: 35px;

  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  border-radius: 9px;
  background-color: ${NEUTRAL_4_COLOUR};
  flex-shrink: 0;
`;
const FloatingTextWrapper = styled.div<{ focus: boolean }>`
  width: 50%;
  text-align: center;
  z-index: 20;
  pointer-events: none;
  color: ${({ focus }) => (focus ? NEUTRAL_9_COLOUR : NEUTRAL_7_COLOUR)};
  ${FONT_12PX_SEMIBOLD}
`;

const Pill = styled.div`
  background-color: white;
  border-radius: 4px;

  margin: 4px;
  width: calc(50% - 8px);
  height: calc(100% - 8px);
  box-sizing: border-box;

  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`;

interface BrowseSwitchProps {
  isContent: boolean;
  onChange: () => void;
}

export const AddSwitch = ({ isContent, onChange }: BrowseSwitchProps) => {
  const styles = useSpring({ right: isContent ? '50%' : '0' });

  return (
    <Wrapper onClick={onChange}>
      <FloatingTextWrapper focus={isContent}>Content</FloatingTextWrapper>
      <FloatingTextWrapper focus={!isContent}>Sections</FloatingTextWrapper>
      <Pill as={animated.div} style={styles} />
    </Wrapper>
  );
};
