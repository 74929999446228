import styled from 'styled-components';

import { AddToPlaylistIcon, CalendarAppIcon, CloudDownloadIcon, StarIcon, TickCompleteIcon } from 'icons';
import { CALENDAR_TEMPLATE, MY_PLAYLISTS_TEMPLATE } from 'api';
import { APP_FONT_12PX_MEDIUM } from 'mockup-font';
import { useContent } from 'providers';
import { ReactNode, useMemo } from 'react';

import { useAppTheme } from '../../hooks';
import { useAppProperties } from 'hooks';

const RowWrapper = styled.div`
  min-height: 66px; // Always reserve the space even if empty
  padding: 12px 0;
  display: flex;
  justify-content: left;
`;

const ActionBox = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 42px;
  margin-right: 13px;
`;
const ActionText = styled.div`
  ${APP_FONT_12PX_MEDIUM};
`;
const StyledIcon = styled.div`
  color: inherit;

  svg {
    width: 32px !important;
    height: 26px !important;
    fill: none !important;
  }
`;

const ActionIcon = ({ icon, text }: { icon: ReactNode; text: string }) => {
  const { getDesignProperty } = useAppTheme();

  return (
    <ActionBox $color={getDesignProperty('primaryItemHEX') as string}>
      <StyledIcon>{icon}</StyledIcon>
      <ActionText>{text}</ActionText>
    </ActionBox>
  );
};

export const ActionRow = () => {
  const { collections } = useContent();
  const { data: appProperties } = useAppProperties();

  // Check
  const { isCalendar, isMyPlaylists } = useMemo(() => {
    const mainTabs = Object.values(collections).filter((c) => c.IsMainTab == 1);
    const isCalendar = !!mainTabs.find((t) => t.TemplateId === CALENDAR_TEMPLATE);
    const isMyPlaylists = !!mainTabs.find((t) => t.TemplateId === MY_PLAYLISTS_TEMPLATE);
    return { isCalendar, isMyPlaylists };
  }, [collections]);

  return (
    <RowWrapper>
      {appProperties?.HideSidebarFavourites !== '1' && <ActionIcon icon={<StarIcon />} text="Favorite" />}
      {(isMyPlaylists || appProperties?.HideSidebarWatchlist !== '1') && (
        <ActionIcon icon={<AddToPlaylistIcon />} text="Add to" />
      )}
      {appProperties?.HideSidebarDownloads !== '1' && <ActionIcon icon={<CloudDownloadIcon />} text="Download" />}
      {isCalendar && <ActionIcon icon={<TickCompleteIcon />} text="Complete" />}
      {isCalendar && <ActionIcon icon={<CalendarAppIcon />} text="Schedule" />}
    </RowWrapper>
  );
};
