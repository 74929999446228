import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { OnboardingAssets, uploadBrandAssets, useAxiosInstance } from 'api';
import { useRecordAction } from 'hooks';

export const useUploadBrandAssets = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, OnboardingAssets>(
    (payload) => {
      return uploadBrandAssets(client, appId, payload);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['appProperties', appId]);
        recordAction.mutate({ action: 'Uploaded brand assets' });
      },
    },
  );
};
