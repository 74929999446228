import { RefObject, useEffect } from 'react';

// This Hook can be used to trigger a function when clicking outside of a function
// e.g) Close something when you click outside of it
export const useOutsideAlerter = (ref: RefObject<HTMLDivElement>, onClickOutside: () => void) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside: EventListener = (event) => {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
};
