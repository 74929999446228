import styled, { css } from 'styled-components';
import { NEUTRAL_1_COLOUR } from 'theme';
import { FONT_14PX_REGULAR } from 'font';
import { ReactNode } from 'react';

/**
 * This is styled dark tooltip for use with PopperJS
 * By using createPopper we can jump this tooltip onto any component based on their id
 * Need to
 */
const Tooltip = styled.div<{ $show?: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  position: relative;

  max-width: 250px;

  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  padding: 6px 8px;

  color: ${NEUTRAL_1_COLOUR};
  ${FONT_14PX_REGULAR};
`;

const StyledArrow = styled.div<{ $position?: string }>`
  width: 0;
  height: 0;
  position: absolute;

  top: calc(50% - 4px);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;

  ${({ $position }) => {
    if ($position === 'left') {
      return css`
        right: -8px;
        border-left: 8px solid rgba(0, 0, 0, 0.9);
      `;
    }
    return css`
      left: -8px;
      border-right: 8px solid rgba(0, 0, 0, 0.9);
    `;
  }}
`;

interface PopperTooltipProps {
  id?: string;
  children?: ReactNode | ReactNode[];
  show?: boolean;
  position?: string;
}

export const PopperTooltip = ({ show, children, position, ...props }: PopperTooltipProps) => {
  return (
    <Tooltip $show={show} {...props}>
      <StyledArrow $position={position} />
      {children}
    </Tooltip>
  );
};
