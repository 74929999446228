import styled from 'styled-components';
import { FONT_14PX_MEDIUM } from 'font';

export const CustomCell = styled.div`
  height: 56px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  ${FONT_14PX_MEDIUM};
`;
