import { BuilderVideo } from 'providers';
import { SegmentProps, SettingsVideoThumbnail } from 'components';

interface VideoThumbnailProps extends SegmentProps {
  video: BuilderVideo;
}

export const VideoThumbnail = ({ video, ...props }: VideoThumbnailProps) => {
  return (
    <SettingsVideoThumbnail
      video={video}
      alternateOrientation="column"
      width="172px"
      height="92px"
      squareWidth="123px"
      portraitWidth="123px"
      portraitHeight="184"
      {...props}
    />
  );
};
