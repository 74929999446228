import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { SecureAppPropertyToSave, saveSecureAppProperties, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useSaveSecureAppProperties = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, SecureAppPropertyToSave[]>(
    (secureAppProperties) => {
      return saveSecureAppProperties(client, appId, secureAppProperties);
    },
    {
      onSuccess: async (response, payload) => {
        await queryClient.invalidateQueries(['secureAppProperties', appId]);
        recordAction.mutate({
          action: 'Save Secure App Properties',
          detail: {
            propertiesToSave: payload.map(({ name, value }) => ({
              name,
              value: value.replace(/(?<!^).(?!$)/g, '*'), // Only show first and last character of value
            })),
          },
        });
      },
    },
  );
};
