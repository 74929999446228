import styled from 'styled-components';

import { BuilderCollectionItem, useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { ContentCard } from 'app/modules/build-dragdrop/Builder/components';

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const CollectionItemDisplay = ({ item: i }: { item: BuilderCollectionItem }) => {
  const { getContentForItem } = useContent();

  const content = getContentForItem(i);
  if (!content) {
    console.warn('Cant get content for item', i);
    return null;
  }

  return (
    <ItemWrapper key={i.Position}>
      <ContentCard content={content} type={i.Type} />
    </ItemWrapper>
  );
};
