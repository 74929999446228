import { Modal } from 'antd';
import { SuccessIcon, ErrorIcon, InfoIcon, WarningIcon } from 'icons';

interface IconMap {
  success: JSX.Element;
  error: JSX.Element;
  warning: JSX.Element;

  [key: string]: JSX.Element;
}

type ModalType = 'success' | 'error' | 'warning' | 'info';
const getModalIcon = (key: ModalType) => {
  const iconMap: IconMap = { success: <SuccessIcon />, error: <ErrorIcon />, warning: <WarningIcon /> };
  return iconMap[key] ?? <InfoIcon />;
};

export const InfoModal = (title: string, content: string | JSX.Element, type: ModalType, okText?: string) => {
  const icon = getModalIcon(type);

  Modal.info({
    title: title,
    content:
      typeof content === 'string' ? (
        <div>
          <p>{content}</p>
        </div>
      ) : (
        content
      ),
    icon: icon,
    okText: okText ?? 'Okay',
    getContainer: '#react-app',
    centered: true,
    className: 'info-modal',
  });
};
