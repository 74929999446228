import { useInfiniteQuery } from 'react-query';
import { AmityCommunity, AmityCommunityUser, AmityUser, getCommunityMembers, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useEffect, useState } from 'react';

interface UseCommunityMembersOptions {
  banned?: boolean;
  query?: string;
}

export const useCommunityMembers = (communityId: string, { banned, query }: UseCommunityMembersOptions) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();

  const [users, setUsers] = useState<Record<string, AmityUser>>({});
  const [communityUsers, setCommunityUsers] = useState<Record<string, AmityCommunityUser>>({});
  const [communities, setCommunities] = useState<Record<string, AmityCommunity>>({});
  const [pagesFetched, setPagesFetched] = useState<number>(0);

  const { data, fetchNextPage, isLoading, hasNextPage } = useInfiniteQuery(
    ['community', 'members', communityId, banned, query],
    ({ pageParam }) => getCommunityMembers(client, appId, communityId, { banned, page_token: pageParam, query }),
    {
      getNextPageParam: (lastPage) => lastPage?.data?.paging?.next,
      enabled: !!communityId,
    },
  );

  useEffect(() => {
    if (data && !isLoading) {
      // Need to reset otherwise when a user gets banned they're not removed
      setUsers({});
      setCommunityUsers({});
      for (const { data: page } of data.pages) {
        if (page?.users) {
          setUsers((existing) => {
            for (const u of page?.users) {
              existing[u.userId] = u;
            }
            return { ...existing };
          });
        }
        if (page?.communityUsers) {
          setCommunityUsers((existing) => {
            for (const u of page?.communityUsers) {
              existing[u.userId] = u;
            }
            return { ...existing };
          });
        }
        if (page?.communities) {
          setCommunities((existing) => {
            for (const c of page?.communities) {
              existing[c.communityId] = c;
            }
            return { ...existing };
          });
        }

        // Get up to 300 of the most recent posts
        if (hasNextPage && pagesFetched < 3) {
          setPagesFetched((a) => a + 1);
          fetchNextPage().then(() => {
            console.debug('Got next page of community members');
          });
        }
      }
    }
  }, [data, pagesFetched]);

  return { users, communityUsers, communities, fetchNextPage, hasNextPage, isLoading };
};
