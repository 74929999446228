import { useMemo } from 'react';

import { CONTENT_1_SOURCE_ID, KAJABI_LIBRARY_SOURCE_ID, PROFILE_TEMPLATE } from 'api';
import { useContent } from 'providers';

import { AppMockup, MockupContextProvider, PurchaseScreenView } from 'app/modules/build-dragdrop';
import { DesignScreenKey } from '../DesignScreensInternal';

const COLOR_SCHEME_KEY = 'color-scheme';
const GENERAL_KEY = 'general';
const SIDE_MENU_KEY = 'side-menu';
const ONBOARDING_SCREENS_KEY = 'onboarding-screens';
const PURCHASE_SCREENS_KEY = 'purchase-screens';
const PROFILE_KEY = 'profile';

interface DesignMockupProps {
  screenKey: DesignScreenKey | 'profile';
  purchaseScreenView?: PurchaseScreenView;
}

export const DesignMockup = ({ screenKey, purchaseScreenView }: DesignMockupProps) => {
  const { collections } = useContent();

  const mainTabs = useMemo(
    () => Object.values(collections).filter((c) => c.IsMainTab === 1 && c.Position && c.Position !== 0),
    [collections],
  );

  const currentTab = useMemo(() => {
    if (screenKey === PROFILE_KEY) {
      return mainTabs.find((c) => c.TemplateId === PROFILE_TEMPLATE);
    }
    if ([COLOR_SCHEME_KEY, SIDE_MENU_KEY].includes(screenKey)) {
      return (
        mainTabs.find((c) => c.SourceId === CONTENT_1_SOURCE_ID) ??
        mainTabs.find((c) => c.SourceId === KAJABI_LIBRARY_SOURCE_ID) ??
        // Fallback to first main tab if Content1 can't be found
        mainTabs.find((c) => c.Position === 1)
      );
    }

    return undefined;
  }, [screenKey, mainTabs]);

  if (!collections || screenKey === GENERAL_KEY) {
    return null;
  }

  return (
    <MockupContextProvider
      key={screenKey}
      currentTabId={currentTab?.TabId}
      currentPage={
        screenKey === ONBOARDING_SCREENS_KEY
          ? { itemType: 'OnboardingScreens' }
          : screenKey === PURCHASE_SCREENS_KEY
          ? { itemType: 'PurchaseScreens' }
          : undefined
      }
      currentTemplateId={
        screenKey === PROFILE_KEY && !currentTab // If on Profile screen but Profile tab can't be found, set currentTemplateId as PROFILE_TEMPLATE instead
          ? PROFILE_TEMPLATE
          : undefined
      }
      setCurrentTabId={() => {
        console.debug('Ignoring tab change in DesignScreens');
      }}
      setCurrentPage={() => {
        console.debug('Ignoring page change in DesignScreens');
      }}
      screenHeight="891px"
      disableDragDrop
      disableTabNavigation
      sideMenuVisible={screenKey === SIDE_MENU_KEY}
      navBarHidden={[PURCHASE_SCREENS_KEY, ONBOARDING_SCREENS_KEY].includes(screenKey)}
      navigationEnabled={false}
      purchaseScreenView={purchaseScreenView}
    >
      <AppMockup disableMargin disableMaxHeight />
    </MockupContextProvider>
  );
};
