import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';

import { useAppBeingEdited } from 'app-context';
import { isUrl } from 'utils';
import { ContentAdvancedSettings, Segment, SettingsFormItem, DrawerSettingsGroup, SettingsTextInput } from 'components';
import { useContent, useLocalAppProperties, useSaveContext } from 'providers';

import { useTabSettings } from 'app/modules/build-screens/hooks';
import { CoreStatsSettings, NavigationHeader, TabBar } from '../components';
import { getPageName, setInitialFormField } from '../utils';
import { DrawerSeparator } from './components/DrawerSeparator';
import { TabDrawer } from '../components/Common/TabDrawer';

const PROFILE_LINKS = [
  {
    heading: 'Manage My Subscription',
    name: 'WebsiteSubscriptionLink',
    disabled: true,
  },
  {
    heading: 'Term & Conditions',
    name: 'TermsOfService',
  },
  {
    heading: 'Privacy Policy',
    name: 'PrivacyPolicy',
  },
  {
    heading: 'Support',
    name: 'AppSupportURL',
    placeholder: 'www.vidapp.com/support',
    tooltip: 'Users will use this link to submit support tickets. We advise using the VidApp link',
  },
];

interface ProfileTabDrawerProps {
  tabId: number;
}

export const ProfileTabDrawer = ({ tabId }: ProfileTabDrawerProps) => {
  const [form] = Form.useForm();
  const { collections } = useContent();
  const tab = collections[tabId];

  // The declared variables are managed in useTabSettings
  const { onValuesChange } = useTabSettings(
    {
      NavBarTitleText: { type: 'collection', valueType: 'string' },
      Name: { type: 'collection', valueType: 'string' },
      LockedUntilSignIn: { type: 'collectionProperty', valueType: 'binary' },
      Icon: { type: 'collection', valueType: 'string' },
      WebsiteSubscriptionLink: { type: 'app', valueType: 'string' },
      TermsOfService: { type: 'app', valueType: 'string' },
      PrivacyPolicy: { type: 'app', valueType: 'string' },
      AppSupportURL: { type: 'app', valueType: 'string' },
    },
    tabId,
    form,
  );
  const { setAppPropertyToSave } = useSaveContext();
  const { properties, setAppProperty } = useLocalAppProperties();
  const appId = useAppBeingEdited();

  const [coreStatsFilenames, setCoreStatsFilenames] = useState<Record<string, string | undefined>>({});
  const [initialized, setInitialized] = useState(false);

  const filePath = `https://storage.vidapp.com/${appId}/icons/`;

  const onTabIconFilenameChange = useCallback(
    (_filename) => {
      onValuesChange({ Icon: _filename }, {}, true);
    },
    [onValuesChange],
  );

  // Initialize core stats fields to match local state
  useEffect(() => {
    if (properties.DisplayCoreStats === '1' && properties.CoreStats) {
      const coreStats = JSON.parse(properties.CoreStats);
      setInitialFormField(form, 'CoreStatsEnabled', coreStats.Enabled === '1');
      setInitialFormField(form, 'CoreStat1Title', coreStats.Stats[0].Title);
      setInitialFormField(form, 'CoreStat2Title', coreStats.Stats[1].Title);
      setInitialFormField(form, 'CoreStat3Title', coreStats.Stats[2].Title);

      setCoreStatsFilenames({
        CoreStat1Icon: coreStats.Stats[0].Icon,
        CoreStat2Icon: coreStats.Stats[1].Icon,
        CoreStat3Icon: coreStats.Stats[2].Icon,
      });
    }
  }, [tab.TabId]);

  // TEMPORARY
  // App is currently expecting a full URL for core stats icons
  const getIconUrlToSave = (icon: string | undefined) => {
    if (icon) {
      return isUrl(icon) ? icon : `${filePath}${icon}`;
    }

    return '';
  };

  const updateCoreStats = useCallback(() => {
    const value = JSON.stringify({
      Enabled: form.getFieldValue('CoreStatsEnabled') ? '1' : '0',
      Stats: [
        { Title: form.getFieldValue('CoreStat1Title') ?? '', Icon: getIconUrlToSave(coreStatsFilenames.CoreStat1Icon) },
        { Title: form.getFieldValue('CoreStat2Title') ?? '', Icon: getIconUrlToSave(coreStatsFilenames.CoreStat2Icon) },
        { Title: form.getFieldValue('CoreStat3Title') ?? '', Icon: getIconUrlToSave(coreStatsFilenames.CoreStat3Icon) },
      ],
    });

    setAppProperty('CoreStats', value);
    if (initialized) {
      setAppPropertyToSave('CoreStats', value);
    }
  }, [form, initialized, coreStatsFilenames, setAppProperty, setAppPropertyToSave]);

  useEffect(() => {
    if (Object.keys(coreStatsFilenames).length > 0) {
      updateCoreStats();
      setInitialized(true);
    }
  }, [coreStatsFilenames]);

  return (
    <TabDrawer tabId={tabId}>
      <Form
        name="settings-form"
        onValuesChange={(changedValues) => {
          onValuesChange(changedValues);
        }}
        autoComplete="off"
        form={form}
      >
        <NavigationHeader tabId={tabId} placeholder={getPageName(tab)} />
        {properties.DisplayCoreStats === '1' && (
          <>
            <DrawerSeparator />
            <Segment>
              <CoreStatsSettings
                filenames={coreStatsFilenames}
                onToggleChange={updateCoreStats}
                onInputChange={updateCoreStats}
                onFilenameChange={(key: string, filename: string) =>
                  setCoreStatsFilenames((oldState) => ({ ...oldState, [key]: filename }))
                }
                isDrawer
              />
            </Segment>
          </>
        )}
        <DrawerSeparator />
        <Segment header="Links">
          {PROFILE_LINKS.map((link) => (
            <DrawerSettingsGroup key={link.name} heading={link.heading} tooltip={link.tooltip}>
              <SettingsTextInput
                size="middle"
                placeholder={link.heading}
                value={link.heading}
                disabled={true}
                $marginBottom="4px"
              />
              <SettingsFormItem name={link.name}>
                <SettingsTextInput
                  size="middle"
                  placeholder={link.placeholder ?? 'www.example.com'}
                  disabled={link.disabled}
                />
              </SettingsFormItem>
            </DrawerSettingsGroup>
          ))}
        </Segment>
        <DrawerSeparator />
        <ContentAdvancedSettings collection={tab} isDrawer isTab />
        <TabBar filename={tab.Icon} placeholder={getPageName(tab)} onFilenameChange={onTabIconFilenameChange} />
      </Form>
    </TabDrawer>
  );
};
