import { useMemo, useState } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

import { useContent } from 'providers';
import { NEUTRAL_5_COLOUR, NEUTRAL_10_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_3_COLOUR } from 'theme';
import { SearchIcon, ChevronDownIcon } from 'icons';
import { FONT_12PX_REGULAR, FONT_14PX_REGULAR, OVERFLOW_ELLIPSIS } from 'font';

const { Option } = Select;

const StyledSelect = styled(Select)`
  &&&& {
    width: 100%;
    color: ${NEUTRAL_10_COLOUR};
    border: 1px solid ${NEUTRAL_5_COLOUR};
    border-radius: 6px;
    background-color: ${({ disabled }) => (disabled ? NEUTRAL_3_COLOUR : '#fff')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    input {
      border: none;
      background: none;
      cursor: pointer;
    }

    .ant-select-selection-item,
    .ant-select-selection-search,
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }
`;

const StyledOption = styled.div`
  ${FONT_14PX_REGULAR};
  display: flex;
  align-items: center;
`;

const NoneOption = styled(StyledOption)`
  color: ${NEUTRAL_7_COLOUR};
`;

const TitleLabel = styled.div`
  ${FONT_14PX_REGULAR};
  ${OVERFLOW_ELLIPSIS};
  color: ${NEUTRAL_10_COLOUR};
  max-width: 450px;
`;

const SourceIdLabel = styled.div`
  ${FONT_12PX_REGULAR};
  color: ${NEUTRAL_7_COLOUR};
  margin-left: 5px;
`;

const ChevronDown = styled(ChevronDownIcon)`
  &&&& {
    font-size: 16px;
    color: ${NEUTRAL_10_COLOUR};
  }
`;

interface ContentPreviewVideoProps {
  defaultValue: number | undefined;
  handleChange: (value: number) => void;
  disabled?: boolean;
}

export const ContentPreviewVideo = ({ defaultValue, handleChange, disabled }: ContentPreviewVideoProps) => {
  const { videos } = useContent();
  const [isOpen, setIsOpen] = useState(false);

  const videosArr = useMemo(
    () =>
      Object.values(videos)
        .filter((v) => v.Title && typeof v.VideoId === 'number')
        .sort((a, b) => a.Title.localeCompare(b.Title)),
    [videos],
  );

  return (
    <StyledSelect
      defaultValue={defaultValue}
      disabled={disabled}
      dropdownMatchSelectWidth
      showSearch
      suffixIcon={disabled ? false : isOpen ? <SearchIcon /> : <ChevronDown />}
      onDropdownVisibleChange={(open) => setIsOpen(open)}
      filterOption={(input, option) => option?.label.toLowerCase().includes(input.toLowerCase())}
      notFoundContent="No Results"
      onChange={(value) => handleChange(value as number)}
      getPopupContainer={(triggerNode) => triggerNode}
      size="middle"
    >
      {[{ VideoId: 0, Title: 'None', SourceId: undefined }, ...videosArr].map(({ VideoId, Title, SourceId }) => {
        return (
          <Option key={VideoId} value={VideoId} label={`${Title} ${SourceId}`}>
            {VideoId === 0 ? (
              <NoneOption>{Title}</NoneOption>
            ) : (
              <StyledOption>
                <TitleLabel>{Title}</TitleLabel>
                <SourceIdLabel>({SourceId})</SourceIdLabel>
              </StyledOption>
            )}
          </Option>
        );
      })}
    </StyledSelect>
  );
};
