import { useMemo } from 'react';
import styled from 'styled-components';

import { Dropdown } from 'components';
import { FONT_12PX_SEMIBOLD } from 'font';
import { VIDAPP_GRADIENT } from 'theme';

import { useAccountDetails } from 'app/modules/account/AccountDetails/hooks/useAccountDetails';
import { useLogout } from 'hooks/useLogout';

const AccountButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${VIDAPP_GRADIENT};
  border-radius: 50%;
  margin-left: 12px;
  ${FONT_12PX_SEMIBOLD};
  user-select: none;
  cursor: pointer;

  transition: 0.1s linear opacity;

  :hover {
    opacity: 0.9;
  }
`;

export const AccountDropdown = () => {
  const { data: account } = useAccountDetails();
  const logout = useLogout();

  const accountLabel = useMemo(() => {
    if (account) {
      const firstInitial = account.FirstName.charAt(0);
      const lastInitial = account.LastName.charAt(0);

      if (firstInitial) {
        return `${firstInitial}${lastInitial ?? ''}`;
      }
    }
    return '';
  }, [account]);

  return (
    <Dropdown
      trigger={<AccountButton>{accountLabel}</AccountButton>}
      options={[
        {
          label: 'Account',
          link: { to: '/account/details' },
        },
        {
          label: 'Manage Team',
          link: { to: '/account/team' },
        },
        {
          label: 'Log Out',
          onClick: () => logout.mutate(),
        },
      ]}
    />
  );
};
