import styled from 'styled-components';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM } from 'font';
import { CustomButton } from 'components/Buttons/CustomButton/CustomButton';
import { TrashIcon } from 'icons';
import { useState } from 'react';

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const ConfirmText = styled.div`
  ${FONT_12PX_MEDIUM};
  width: 100%;
  margin-bottom: 8px;
`;
const ButtonText = styled.span`
  ${FONT_14PX_MEDIUM};
`;
const CancelButton = styled(CustomButton)`
  margin-left: 12px !important;
`;

interface AreYouSureButtonProps {
  onConfirm: () => void;
  confirmText?: string;
  isLoading?: boolean;
  children: string;
  secondary?: boolean;
  disabled?: boolean;
}

/**
 * Button with built in confirmation check
 * Stolen from Drag&Drop for use in admin only deletion buttons
 */
export const AreYouSureButton = ({
  onConfirm,
  confirmText,
  isLoading = false,
  children,
  secondary,
  disabled,
}: AreYouSureButtonProps) => {
  const [confirmed, setConfirmed] = useState(false);

  return (
    <>
      {confirmed ? (
        <Row>
          <ConfirmText>{confirmText ?? 'Are you sure?'}</ConfirmText>
          <CustomButton medium danger onClick={onConfirm} loading={isLoading}>
            <ButtonText>Confirm</ButtonText>
          </CustomButton>
          <CancelButton medium secondary onClick={() => setConfirmed(false)}>
            <ButtonText>Cancel</ButtonText>
          </CancelButton>
        </Row>
      ) : (
        <CustomButton
          secondary={secondary}
          medium
          danger
          icon={<TrashIcon />}
          onClick={() => setConfirmed(true)}
          disabled={disabled}
        >
          {children}
        </CustomButton>
      )}
    </>
  );
};
