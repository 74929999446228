import axios from 'axios';
import { S3_BUCKET } from 'api';

export const DEFAULT_MAX_FILE_SIZE = 25;

export const makeFilenameSafeForAndroid = (originalFilename: string) => {
  // Removes all non-alphanumeric characters and additional full stops
  let filenameToReturn = originalFilename.replace(/[^a-z0-9._]/gi, '').toLowerCase();
  filenameToReturn = filenameToReturn.replace(new RegExp(/\.(?=.*?\.)/g), ''); // Remove all fullstops except for the last once
  return filenameToReturn;
};

export const makeFilenameSafe = (originalFilename: string) => {
  // Same as makeFilenameSafeForAndroid, but doesn't convert to lowercase and allows hyphens
  let filenameToReturn = originalFilename.replace(/[^a-z0-9._-]/gi, '');
  filenameToReturn = filenameToReturn.replace(new RegExp(/\.(?=.*?\.)/g), '_'); // Replace all fullstops with underscores except for the last once
  return filenameToReturn;
};

export interface UploadOptions {
  requiredDimensions?: { requiredWidth: number; requiredHeight: number };
  maxDimensions?: { maxWidth: number; maxHeight: number };
  filePath?: string;
  useOriginalFilename?: boolean;
}

export const downloadFile = (filePath: string, filename: string) => {
  axios({
    url: `${S3_BUCKET}${filePath}${filename}?timestamp=${new Date()}`, // Chrome has an issue where it uses a cached version without CORS headers, resulting in an error - Add timestamp to get around this
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
