import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface ContextValue {
  confirmDisabled: boolean;
  setConfirmDisabled: Dispatch<SetStateAction<boolean>>;
}

const ModalContext = createContext<ContextValue | undefined>(undefined);

interface ProviderProps {
  children?: ReactNode;
}

const ModalContextProvider = ({ children }: ProviderProps) => {
  const [confirmDisabled, setConfirmDisabled] = useState(false);

  return <ModalContext.Provider value={{ confirmDisabled, setConfirmDisabled }}>{children}</ModalContext.Provider>;
};

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContet must be used within a ModalContextProvider');
  }
  return context;
};

export { ModalContextProvider, useModalContext };
