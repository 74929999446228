import { DefaultAppPropertiesProvider, DefaultContentProvider, SaveProvider } from 'providers';

import { BuildMyAppInternalWrapper } from './BuildMyAppInternalWrapper';

export const BuildMyApp = () => (
  <DefaultContentProvider>
    <SaveProvider resetCacheOnSave>
      <DefaultAppPropertiesProvider staleTime={Infinity}>
        <BuildMyAppInternalWrapper />
      </DefaultAppPropertiesProvider>
    </SaveProvider>
  </DefaultContentProvider>
);
