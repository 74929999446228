import { useMemo } from 'react';
import { useAppProperties } from 'hooks/useAppProperties';
import { BuilderCollectionItem } from 'providers';

export const useDeepLink = ({
  itemType,
  itemId,
}: {
  itemType?: BuilderCollectionItem['Type'];
  itemId?: BuilderCollectionItem['ChildId'];
}) => {
  const { data } = useAppProperties();

  const domain = useMemo(() => {
    // Handle when content is loading or unavailable, just return no deep link
    if (!itemType || !itemId) {
      return undefined;
    }

    if (data?.DisplayDeepLinking === '1' && data?.DeepLinkingDefaultLinkDomain) {
      return `https://${data.DeepLinkingDefaultLinkDomain}/app?$type=${itemType}&$id=${itemId}`;
    }
    return undefined;
  }, [data]);
  const copyDomainToClipboard = () => {
    if (domain) {
      navigator.clipboard.writeText(domain);
    }
  };

  return {
    domain,
    copyDomainToClipboard,
  };
};
