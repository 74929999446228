export const getButtonText = (status: string) => {
  return status === 'loading'
    ? 'Validating'
    : status === 'error'
    ? 'Try again'
    : status === 'success'
    ? 'Validated'
    : 'Validate';
};

export const getButtonType = (status: string): string => {
  return status === 'error' ? 'outlined' : status;
};

export const generateSecurePassword = () => {
  // Pick n random characters from str
  const pick = (str: string, n: number) => {
    let output = '';
    for (let i = 0; i < n; i++) {
      output += str[Math.floor(Math.random() * str.length)];
    }
    return output;
  };

  const special = '!@#$%?~';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  return pick(uppercase, 1) + pick(lowercase, 8) + pick(numbers, 2) + pick(special, 1);
};
