import React from 'react';
import styled from 'styled-components';
import { StateProps, StyledInput } from '../TagsCsvUploadPage';
import { useAxiosInstance } from 'api';

interface UploadButtonProps {
  state: StateProps;
}

const StatusText = styled.span<{ color: string }>`
  color: ${(p) => p.color};
  font-weight: 500;
`;

export const UploadButton = ({ state: { appId, csvFileText } }: UploadButtonProps) => {
  const client = useAxiosInstance();
  const [uploadStatus, setUploadStatus] = React.useState({ text: 'none', color: 'unset' });

  async function upload() {
    setUploadStatus({ text: 'uploading...', color: 'orange' });
    try {
      const base64csv = btoa(unescape(encodeURIComponent(csvFileText)));
      // Piggy-back of existing axios auth-token functionality
      setUploadStatus({ text: 'uploading...', color: 'orange' });
      await client.post(`apps/csv-upload`, { appId, base64csv });
      setUploadStatus({ text: `success`, color: 'green' });
      alert('You will be sent an email once the CSV has been successfully processed!');
    } catch (err) {
      console.error(err);
      setUploadStatus({ text: 'failed', color: 'red' });
      return;
    } finally {
      setTimeout(() => setUploadStatus({ text: `none`, color: 'unset' }), 8000);
    }
  }

  return (
    <div>
      <StyledInput type="button" value="Upload" onClick={upload} />
      <span>
        {' -> '}Status: <StatusText color={uploadStatus.color}>{uploadStatus.text}</StatusText>
      </span>
    </div>
  );
};
