import { ReactNode } from 'react';

import { BuilderCollection } from 'providers';
import { getCollectionName } from 'utils';
import { CollectionTitle, ContentAdvancedSettings, WorkoutDescription } from 'components';

import {
  CollectionThumbnail,
  DrawerHeading,
  DrawerScrollContainer,
} from 'app/modules/build-dragdrop/Builder/drawer/components';

export const WorkoutDrawer = ({ collection, type }: { collection: BuilderCollection; type?: ReactNode }) => (
  <>
    <DrawerHeading heading={getCollectionName(collection)} type={type ?? 'Workout'} />
    <DrawerScrollContainer paddingBottom="50px">
      <CollectionTitle size="middle" TabId={collection.TabId} Name={collection.Name} $marginTop="0" showDeepLink />
      <CollectionThumbnail collection={collection} />
      <WorkoutDescription TabId={collection.TabId} Description={collection.Description} isDrawer />
      <ContentAdvancedSettings collection={collection} isDrawer isWorkout />
    </DrawerScrollContainer>
  </>
);
