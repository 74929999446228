import { Resource } from 'api';
import styled, { css } from 'styled-components';
import { FileIcon, MediaIcon, MusicIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_4_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { FONT_10PX_MEDIUM, FONT_10PX_SEMIBOLD } from 'font';
import { AUDIO_EXTENSIONS, getResourceUrl, usePointerOnClick } from 'utils';
import { useAppBeingEdited } from 'app-context';

const ResourceRow = styled.div`
  display: flex;
  width: calc(100% + 12px);
  padding: 6px;
  margin-left: -6px;
  border-radius: 6px;
  align-items: center;
  cursor: ${usePointerOnClick};

  :hover {
    background-color: ${NEUTRAL_4_COLOUR};
  }
`;

const IconCss = css`
  color: ${NEUTRAL_10_COLOUR};
  font-size: 16px;
  flex-grow: 0;
  margin-right: 4px;
`;

const ResourceIcon = styled(FileIcon)`
  ${IconCss};
`;
const StyledMediaIcon = styled(MediaIcon)`
  ${IconCss};
`;
const StyledMusicIcon = styled(MusicIcon)`
  ${IconCss};
`;

const ResourceText = styled.div`
  ${FONT_10PX_SEMIBOLD};
  flex-grow: 1;
`;
const ResourceExtension = styled.div`
  flex-grow: 0;
  ${FONT_10PX_MEDIUM};
  color: ${NEUTRAL_9_COLOUR};
  padding: 2px 4px;
  border-radius: 4px;
  background-color: ${NEUTRAL_4_COLOUR};
  text-transform: uppercase;
`;

const getIcon = (filetype?: string) => {
  if (filetype) {
    if (['mp4', 'mov', 'mkv', 'webm'].includes(filetype?.toLowerCase())) {
      return <StyledMediaIcon />;
    } else if (AUDIO_EXTENSIONS.includes(filetype?.toLowerCase())) {
      return <StyledMusicIcon />;
    }
  }
  return <ResourceIcon />;
};

export const ContentResource = ({ resource }: { resource: Resource }) => {
  const appId = useAppBeingEdited();
  return (
    <ResourceRow
      onClick={() => {
        const openUrl = getResourceUrl(appId, resource);
        window.open(openUrl, '_blank');
      }}
    >
      {getIcon(resource.Type)}
      <ResourceText>{resource.Title}</ResourceText>
      <ResourceExtension>{resource.Type}</ResourceExtension>
    </ResourceRow>
  );
};
