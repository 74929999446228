import styled from 'styled-components';

import { BuilderCollection } from 'providers';
import { SOURCE_VIDAPP } from 'api';
import { getDataSourceDisplayName, getSourceTypeDisplayName } from 'utils';
import { SettingsTextInput, Segment, SegmentProps } from 'components';

const InputWrapper = styled.div`
  width: 100%;
`;

export const CollectionSource = ({
  DataSource,
  SourceType,
  SourceId,
}: Pick<BuilderCollection, 'DataSource' | 'SourceType' | 'SourceId'> & SegmentProps) => {
  return DataSource !== SOURCE_VIDAPP ? (
    <Segment header="Source">
      <InputWrapper>
        <SettingsTextInput
          size="middle"
          disabled
          value={`${getDataSourceDisplayName(DataSource)} ${getSourceTypeDisplayName(SourceType, DataSource, {
            defaultName: 'Custom',
          })} - ${SourceId}`}
        />
      </InputWrapper>
    </Segment>
  ) : null;
};
