import styled from 'styled-components';
import { ColumnModal, CustomButton } from 'components';
import { HIGHLIGHT_PRIMARY_COLOUR, HIGHLIGHT_PRIMARY_HOVER_COLOUR } from 'theme';
import { FONT_16PX_REGULAR } from 'font';
import { AmityComment, AmityPost } from 'api';
import { useCallback, useState } from 'react';
import { useCommunityPostContext } from 'app/modules/community/Posts/providers/CommunityPostProvider';
import { useDeletePost } from 'app/modules/community/Posts/hooks/useDeletePost';
import { useClearPost } from 'app/modules/community/Moderation/hooks/useClearPost';
import { CheckIcon, XIcon } from 'icons';
import { useClearComment } from 'app/modules/community/Moderation/hooks/useClearComment';
import { useDeleteComment } from 'app/modules/community/Moderation/hooks/useDeleteComment';

const StyledButton = styled(CustomButton)`
  &&& {
    color: ${HIGHLIGHT_PRIMARY_COLOUR};

    :hover {
      color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
    }
  }
`;
const ContentBody = styled.div`
  ${FONT_16PX_REGULAR};
  min-height: 214px;
  height: fit-content;
  max-height: 642px;

  @media (max-height: 900px) {
    max-height: 50vh;
  }

  margin-right: -12px; // So the scrollbar can sit outside for images
  overflow: auto;
`;

const StyledImg = styled.img`
  margin-top: 20px;
`;

interface CommunityModerationModalProps {
  data: AmityPost | AmityComment;
}

export const CommunityModerationModal = ({ data }: CommunityModerationModalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { childPosts, files, users } = useCommunityPostContext();
  const deletePost = useDeletePost();
  const deleteComment = useDeleteComment();
  const clearPost = useClearPost();
  const clearComment = useClearComment();

  const isPost = 'postId' in data;
  const isComment = 'commentId' in data;

  const approve = useCallback(() => {
    const onSuccess = () => setOpen(false);
    if (isPost) {
      console.debug('Approving Post', data.postId);
      clearPost.mutate(data.postId, { onSuccess });
    }
    if (isComment) {
      console.debug('Approving Comment', data.commentId);
      clearComment.mutate(data.commentId, { onSuccess });
    }
  }, [clearPost, clearComment, data, setOpen]);
  const reject = useCallback(() => {
    const onSuccess = () => setOpen(false);

    if (isPost) {
      console.debug('Rejecting Post', data.postId);
      deletePost.mutate(data.postId, { onSuccess });
    }
    if (isComment) {
      console.debug('Rejecting Comment', data.commentId);
      deleteComment.mutate(data.commentId, { onSuccess });
    }
  }, [deletePost, deleteComment, data, setOpen]);

  const getImageUrl = (fileId: string) => files[fileId].fileUrl + '?size=medium';
  const userId = 'postedUserId' in data ? data?.postedUserId : data.userId;

  const isApproving = clearPost.isLoading || clearComment.isLoading;
  const isRejecting = deletePost.isLoading || deleteComment.isLoading;
  return (
    <ColumnModal
      title={`${isPost ? 'Post' : 'Comment'} by ${users[userId].displayName}`}
      cols={3}
      align="full"
      onConfirm={approve}
      onCancel={reject}
      primaryText={isPost ? 'Approve Post' : 'Approve Comment'}
      secondaryText={isPost ? 'Reject Post' : 'Reject Comment'}
      open={open}
      primaryProps={{
        secondary: true,
        icon: <CheckIcon />,
        loading: isApproving,
        disabled: isApproving,
      }}
      secondaryProps={{
        danger: true,
        icon: <XIcon />,
        loading: isRejecting,
        disabled: isRejecting,
      }}
      onOpenChange={setOpen}
      triggerButton={
        <StyledButton tertiary small>
          Review
        </StyledButton>
      }
    >
      <ContentBody>
        <div>{data.data.text}</div>
        {data.children?.map((child) => {
          if (!childPosts[child]?.data.fileId) {
            return null;
          }
          return <StyledImg src={getImageUrl(childPosts[child]?.data.fileId)} width={512} key={child} />;
        })}
        {(data as AmityComment).attachments?.map((attachment) => {
          // only support image attachments
          if (!attachment.fileId || !(attachment.type === 'image')) {
            return null;
          }
          return <StyledImg src={getImageUrl(attachment.fileId)} width={512} key={attachment.fileId} />;
        })}
      </ContentBody>
    </ColumnModal>
  );
};
