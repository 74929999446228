import { useAppBeingEdited } from 'app-context';
import { clearCommunityComment, useAxiosInstance } from 'api';
import { useMutation, useQueryClient } from 'react-query';
import { useRecordAction } from 'hooks';
import { AxiosError, AxiosResponse } from 'axios';

export const useClearComment = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, string>(
    (commentId) => {
      return clearCommunityComment(client, appId, commentId);
    },
    {
      onSuccess: async (_response, commentId) => {
        // Invalidate all queries for community comments, they appear on posts
        await queryClient.invalidateQueries(['community', 'posts']);
        recordAction.mutate({ action: 'Clear Flags on Community Comment', detail: { CommentId: commentId } });
      },
    },
  );
};
