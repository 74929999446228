import { memo, useEffect, useRef, useState } from 'react';

interface TintImageProps {
  src: string;
  color: string;
  dimensions?: Dimensions; // Optionally provide dimensions to scale image in canvas
}

interface Dimensions {
  width: number;
  height: number;
}

export const TintImage = memo(({ src, color, dimensions }: TintImageProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [size, setSize] = useState<{ height: number; width: number }>({ height: 100, width: 100 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const img = new Image();
    img.src = src;
    const tintCanvas = document.createElement('canvas');
    const tintCtx = tintCanvas.getContext('2d');
    if (canvas && tintCtx) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        img.onload = () => {
          const canvasWidth = dimensions?.width || img.width;
          const canvasHeight = dimensions?.height || img.height;
          setSize({ width: canvasWidth, height: canvasHeight });

          // Scale canvases based on devicePixelRatio to ensure they look good across devices (e.g. Retina display)
          const scaleFactor = window.devicePixelRatio * 2;
          canvas.width = canvasWidth * scaleFactor;
          canvas.height = canvasHeight * scaleFactor;
          tintCanvas.width = canvasWidth * scaleFactor;
          tintCanvas.height = canvasHeight * scaleFactor;

          // use css to bring them back to desired size
          if (dimensions) {
            canvas.style.width = `${canvasWidth}px`;
            canvas.style.height = `${canvasHeight}px`;
            tintCanvas.style.width = `${canvasWidth}px`;
            tintCanvas.style.height = `${canvasHeight}px`;
          }

          // set the scale of the context
          canvas.getContext('2d')?.scale(scaleFactor, scaleFactor);
          tintCanvas.getContext('2d')?.scale(scaleFactor, scaleFactor);

          tintCtx.fillStyle = color;
          tintCtx.fillRect(0, 0, canvasWidth, canvasHeight);
          tintCtx.globalCompositeOperation = 'destination-atop';
          tintCtx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
          ctx.globalAlpha = 1;
          ctx.drawImage(tintCanvas, 0, 0, canvasWidth, canvasHeight);
        };
      }
    }
  }, [src, color]);

  return <canvas width={size.width} height={size.height} ref={canvasRef} />;
});

TintImage.displayName = 'TintImage';

export const GetLumaFromHex = (hex: string) => {
  const c = hex.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma;
};
