import styled from 'styled-components';

import { BuilderCollection } from 'providers';
import { getDataSourceDisplayName } from 'utils';
import { InputSize } from 'theme';
import { SettingsTextInput, SegmentTitle, SegmentProps, Segment } from 'components';

const InputWrapper = styled.div<{ marginBottom?: string }>`
  width: 100%;
`;

interface ContentSourceIdProps extends SegmentProps {
  size?: InputSize;
}

export const ContentSourceId = ({
  DataSource,
  SourceId,
  size = 'small',
  ...props
}: Pick<BuilderCollection, 'DataSource' | 'SourceId'> & ContentSourceIdProps) => (
  <Segment header={<SegmentTitle title={`${getDataSourceDisplayName(DataSource)} ID`} />} {...props}>
    <InputWrapper>
      <SettingsTextInput
        size={size}
        disabled
        value={SourceId || 'None'}
        $marginTop={size === 'large' ? '0' : undefined}
      />
    </InputWrapper>
  </Segment>
);
