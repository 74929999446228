import styled from 'styled-components';
import { Form, FormItemProps } from 'antd';

interface CustomFormItemProps {
  width?: string;
  $marginBottom?: string;
}

const FormItem = styled(Form.Item)<CustomFormItemProps>`
  margin-bottom: ${(props) => props.$marginBottom || 0} !important;
  width: ${(props) => props.width || '100%'};
  min-height: unset;

  #react-app && .ant-form-item-control-input {
    min-height: unset;
  }

  #react-app && .ant-form-item-explain {
    font-size: 10px;
    opacity: 1;
    height: 0 !important;
    min-height: 0 !important;
  }
`;

interface SettingsFormItemProps extends FormItemProps, CustomFormItemProps {
  name: string;
  valuePropName?: string;
  children: JSX.Element;
  marginBottom?: string;
}

export const SettingsFormItem = ({ children, marginBottom, ...props }: SettingsFormItemProps) => (
  <FormItem $marginBottom={marginBottom} {...props}>
    {children}
  </FormItem>
);
