import { AmityChildPost, AmityComment, AmityCommunity, AmityFile, AmityPost, AmityUser } from 'api';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext } from 'react';
import { useCommunityPosts } from 'app/modules/community/Posts/hooks/useCommunityPosts';
import { LoadingSpinner } from 'components';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ProviderProps {
  communityId: string;
  flagged?: boolean;
  children: ReactNode;
  modalPostId?: string;
  setModalPostId?: Dispatch<SetStateAction<string>>;
}

interface ContextValue {
  posts: Record<string, AmityPost>;
  childPosts: Record<string, AmityChildPost>;
  comments: Record<string, AmityComment>;
  users: Record<string, AmityUser>;
  files: Record<string, AmityFile>;
  communities: Record<string, AmityCommunity>;
  modalPostId?: string;
  setModalPostId?: Dispatch<SetStateAction<string>>;
}

const CommunityPostsContext = createContext<ContextValue | undefined>(undefined);

const CommunityPostsProvider = ({ communityId, children, flagged, modalPostId, setModalPostId }: ProviderProps) => {
  const data = useCommunityPosts(communityId, { flagged });
  if (data.isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <CommunityPostsContext.Provider value={{ ...data, modalPostId, setModalPostId }}>
      {children}
    </CommunityPostsContext.Provider>
  );
};

const useCommunityPostContext = () => {
  const context = useContext(CommunityPostsContext);
  if (context === undefined) {
    throw new Error('useCommunityPostContext must be used with a CommunityPostsProvider');
  }
  return context;
};

export { CommunityPostsProvider, useCommunityPostContext };
