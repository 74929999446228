import { useState } from 'react';

import { CustomButton, ConfirmModal } from 'components';
import { TrashIcon } from 'icons';

interface DeleteWebhookButtonProps {
  handleDelete: () => void;
}

export const DeleteWebhookButton = ({ handleDelete }: DeleteWebhookButtonProps) => {
  const [open, setOpen] = useState(false);

  return (
    <ConfirmModal
      heading="Delete this Webhook?"
      type="warning"
      triggerButton={<CustomButton icon={<TrashIcon />} tertiary small />}
      content="Are you sure you want to delete this staff member? You will not be able to reverse this action."
      danger
      primaryText="Delete"
      onConfirm={handleDelete}
      open={open}
      setOpen={setOpen}
    />
  );
};
