import { Dispatch, ReactNode, SetStateAction } from 'react';
import styled from 'styled-components';

import { ColumnModal } from 'components/index';
import { FONT_14PX_REGULAR, FONT_16PX_SEMIBOLD } from 'font';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR } from '../../../theme';

interface ConfirmModalProps {
  heading: string;
  content?: ReactNode | string;
  primaryText?: string;
  danger?: boolean; // Set primary button to danger style
  triggerButton?: ReactNode;
  open: boolean;
  loading?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  // Either use type or icon to set the modal icon
  type?: ModalType;
  icon?: ReactNode;
}

interface IconMap {
  success: JSX.Element;
  error: JSX.Element;
  warning: JSX.Element;

  [key: string]: JSX.Element;
}

type ModalType = 'success' | 'error' | 'warning' | 'info';
const getModalIcon = (key: ModalType) => {
  const iconMap: IconMap = { success: <SuccessIcon />, error: <ErrorIcon />, warning: <WarningIcon /> };
  return iconMap[key] ?? <InfoIcon />;
};

const ContentContainer = styled.div`
  ${FONT_14PX_REGULAR};
  text-align: center;
  color: ${NEUTRAL_8_COLOUR};
  width: 100%;
`;

const Heading = styled.div`
  ${FONT_16PX_SEMIBOLD};
  color: ${NEUTRAL_10_COLOUR};
  margin: 16px 0 4px;
`;

const Icon = styled.div`
  font-size: 35px;
  line-height: 1;
  margin-top: 4px;
`;

export const ConfirmModal = ({
  heading,
  content,
  primaryText,
  danger,
  triggerButton,
  type,
  icon,
  open,
  loading,
  setOpen,
  onConfirm,
}: ConfirmModalProps) => (
  <ColumnModal
    align="full"
    cols={2}
    onConfirm={onConfirm}
    open={open}
    onOpenChange={setOpen}
    onCancel={() => setOpen(false)}
    primaryText={primaryText ?? 'Ok'}
    primaryProps={{ danger }}
    triggerButton={triggerButton}
    hideTrigger={!triggerButton}
    hideDialogActionsBorder
    confirmIsLoading={loading}
  >
    <ContentContainer>
      {type && <Icon>{icon ? icon : getModalIcon(type)}</Icon>}
      <Heading>{heading}</Heading>
      {content}
    </ContentContainer>
  </ColumnModal>
);
