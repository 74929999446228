import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAppBeingEdited } from 'app-context';

interface ContextValue {
  restartBanner: boolean;
  setRestartBanner: (b: boolean) => void;
}

type OptionalContextValue = ContextValue | undefined;

const BannerContext = createContext<OptionalContextValue>(undefined);

interface ProviderProps {
  children: ReactNode | ReactNode[];
}

const BannerProvider = ({ children }: ProviderProps) => {
  const [restartBanner, setRestartBanner] = useState(false);
  const queryClient = useQueryClient();
  const appId = useAppBeingEdited();

  const wrappedSetRestartBanner = useCallback(
    (_in: boolean) => {
      // Get newly started syncs on restart
      queryClient.setQueryData(['sync_tracing', appId], undefined);
      return queryClient.invalidateQueries(['sync_tracing', appId]).then(() => setRestartBanner(_in));
    },
    [setRestartBanner],
  );
  return (
    <BannerContext.Provider value={{ restartBanner, setRestartBanner: wrappedSetRestartBanner }}>
      {children}
    </BannerContext.Provider>
  );
};
const useBanner = () => {
  const context = useContext(BannerContext);
  if (context === undefined) {
    throw new Error('useBannerContext must be used within a BannerProvider');
  }
  return context;
};

export { BannerProvider, useBanner };
