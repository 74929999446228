import { AxiosInstance } from 'axios';

export interface PricingPlan {
  AndroidOptIn: 1 | 0;
  AndroidPrice: number | null;
  AppleTVOptIn: 1 | 0;
  AppleTVPrice: number | null;
  BillingCycle: string;
  CustomText: string;
  FreeTrialMonths: 1 | 0;
  IOSOptIn: 1 | 0;
  IOSPrice: number | null;
  MonthlyPerSubscriberCost: number;
  MonthlyPerSubscriberTierThreeCost: number;
  MonthlyPerSubscriberTierTwoCost: number;
  Note1: string;
  Note2: string;
  Note3: string;
  OneOffCosts: OneOffCost[];
  PaymentPlan: PaymentPlan;
  RokuOptIn: 1 | 0;
  RokuPrice: number | null;
  TVOD: number | null;
  TierOne: number;
  TierTwo: number;
  UUID: string;
}

interface OneOffCost {
  Title: string;
  Amount: number;
}

interface PaymentPlan {
  Id: number;
  Name: string;
}

export const getPricingPlan = (client: AxiosInstance, appId: string) => {
  return client.get<PricingPlan>(`/misc/pricing-plan-search?app_id=${appId}`).then(({ data }) => data);
};
