import { DrawerNavHeading } from 'app/modules/build-dragdrop/Builder/drawer/components/DrawerNavHeading';
import { DrawerScrollContainer } from 'app/modules/build-dragdrop/Builder/drawer/components/DrawerScrollContainer';
import { BuilderCollection } from 'providers';
import { CollectionItemDisplay } from 'app/modules/build-dragdrop/Builder/drawer/components/CollectionContent/CollectionItem';

interface CollectionItemsDrawerProps {
  collection: BuilderCollection;
}

export const CollectionItemsDrawer = ({ collection }: CollectionItemsDrawerProps) => {
  return (
    <>
      <DrawerNavHeading />
      <DrawerScrollContainer>
        {collection.Items.map((item) => (
          <CollectionItemDisplay item={item} key={item.TabItemId} />
        ))}
      </DrawerScrollContainer>
    </>
  );
};
