import styled from 'styled-components';

import { CollectionBasicInfo } from 'api';
import { NEUTRAL_9_COLOUR } from 'theme';

const PRODUCT_WIDTH = '104';
const PRODUCT_HEIGHT = '58';

const ProductWrapper = styled.div`
  margin-bottom: 24px;
`;
const Subtitle = styled.div`
  max-width: ${PRODUCT_WIDTH}px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  margin-top: 8px;
  color: ${NEUTRAL_9_COLOUR};

  max-height: 60px; // 3 line title
  overflow: hidden;
`;
const ImagePlaceholder = styled.div`
  width: ${PRODUCT_WIDTH}px;
  height: ${PRODUCT_HEIGHT}px;
`;

export const Product = ({ Image, Title }: CollectionBasicInfo) => {
  return (
    <ProductWrapper>
      {Image ? (
        <img src={Image} width={PRODUCT_WIDTH} height={PRODUCT_HEIGHT} alt="Synced product" />
      ) : (
        <ImagePlaceholder />
      )}
      <Subtitle>{Title ? Title : ''}</Subtitle>
    </ProductWrapper>
  );
};
