import { useState } from 'react';
import styled from 'styled-components';

import { UploadButton } from './components/UploadButton';
import { CsvUploadWrapper } from './components/CsvUploadWrapper';
import { PageContainer } from 'components';
import { useAppBeingEdited } from 'app-context';

export interface StateProps {
  appId: string;
  setAppId: (appId: string) => void;
  csvFileText: string;
  setCsvFileText: (appId: string) => void;
}

const GridContainer = styled.div`
  display: grid;
  grid-gap: 0.5em;
`;
export const StyledInput = styled.input`
  padding: 2px 4px;
  // increases specificity of the following styles
  &&& {
    opacity: 1;
  }
`;

export const TagsCsvUploadPage = () => {
  const appBeingEdited = useAppBeingEdited();
  const [appId, setAppId] = useState(appBeingEdited);
  const [csvFileText, setCsvFileText] = useState('');

  const state: StateProps = {
    appId,
    setAppId,
    csvFileText,
    setCsvFileText,
  };
  return (
    <PageContainer heading="Filter Tags CSV Upload" subheading="Upload a CSV to apply filter tags to your videos.">
      <GridContainer>
        {appId && <CsvUploadWrapper appBeingEdited={appBeingEdited} state={state} />}
        {csvFileText && <UploadButton state={state} />}
      </GridContainer>
    </PageContainer>
  );
};
