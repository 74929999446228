import { ReactNode } from 'react';
import styled from 'styled-components';

import { GroupTitle, SegmentTitle } from 'components/Text/SegmentTitle';
import { SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';

const Container = styled.div<{ wrapRow?: boolean; $marginBottom?: string }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ wrapRow }) => (wrapRow ? 'row' : 'column')};
  ${({ wrapRow }) => wrapRow && 'flex-wrap: wrap'};
  ${({ wrapRow }) => wrapRow && 'justify-content: space-between'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || SETTINGS_ITEM_MARGIN_BOTTOM};
`;

interface GroupWrapperProps {
  width?: string;
}

const GroupWrapper = styled.div<GroupWrapperProps>`
  display: flex;
  flex-direction: column;
  padding: 16px 0 0;
  width: ${(props) => props.width || '100%'};
`;

interface SettingsGroupProps {
  title?: string;
  description?: string;
  tooltip?: string;
  children?: ReactNode | ReactNode[];
  wrapRow?: boolean;
  $marginBottom?: string;
}

export const SettingsGroup = ({ title, description, tooltip, children, ...props }: SettingsGroupProps) => (
  <>
    {title && <GroupTitle title={title} description={description} tooltip={tooltip} />}
    <Container {...props}>{children}</Container>
  </>
);

interface DrawerSettingsGroupProps {
  heading: string;
  children?: ReactNode;
  tooltip?: string;
  width?: string;
}

export const DrawerSettingsGroup = ({ heading, children, tooltip, ...props }: DrawerSettingsGroupProps) => (
  <GroupWrapper {...props}>
    {heading && <SegmentTitle title={heading} tooltip={tooltip} />}
    {children}
  </GroupWrapper>
);
