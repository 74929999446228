import { ReactNode } from 'react';
import styled from 'styled-components';
import { FONT_10PX_SEMIBOLD, FONT_14PX_MEDIUM } from 'font';
import { HIGHLIGHT_PRIMARY_COLOUR, HIGHLIGHT_SECONDARY_COLOUR, NEUTRAL_10_COLOUR } from 'theme';
import { CustomActiveStyle, setCustomActiveStyle } from 'utils';

const Container = styled.div`
  margin-bottom: 24px;
`;
const Heading = styled.div`
  ${FONT_10PX_SEMIBOLD};
  color: ${NEUTRAL_10_COLOUR};
  margin-bottom: 8px;
`;

const OptionWrapper = styled.div<CustomActiveStyle>`
  display: flex;
  align-items: center;
  padding: 7px 8px;
  margin-bottom: 4px;
  background-color: ${setCustomActiveStyle(HIGHLIGHT_SECONDARY_COLOUR, 'transparent')};
  border-radius: 6px;
  cursor: pointer;
`;
const OptionIcon = styled.div<CustomActiveStyle>`
  font-size: 20px;
  height: 20px;
  display: flex;
  color: ${setCustomActiveStyle(HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR)};
  margin-right: 8px;
`;
const OptionText = styled.div<CustomActiveStyle>`
  ${FONT_14PX_MEDIUM};
  color: ${setCustomActiveStyle(HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR)};
`;

interface Option {
  id: string;
  name: string;
  icon: ReactNode;
}

interface BodyNavigationGroupProps {
  heading: string;
  options: (Option | undefined)[];
  onOptionClick?: (id: string) => void;
  activeKey?: string;
}

export const ModalBodyNavigationGroup = ({ activeKey, heading, options, onOptionClick }: BodyNavigationGroupProps) => {
  return (
    <Container>
      <Heading>{heading}</Heading>
      {(options.filter((option) => !!option) as Option[]).map(({ id, name, icon }) => {
        const active = activeKey === id;
        return (
          <OptionWrapper key={id} $active={active} onClick={onOptionClick ? () => onOptionClick(id) : undefined}>
            <OptionIcon $active={active}>{icon}</OptionIcon>
            <OptionText $active={active}>{name}</OptionText>
          </OptionWrapper>
        );
      })}
    </Container>
  );
};
