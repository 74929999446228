import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NEUTRAL_10_COLOUR, NEUTRAL_4_COLOUR, NEUTRAL_5_COLOUR } from 'theme';

import { TOOLBOX_PAGE_TREE } from 'app/modules/build-dragdrop/Builder/const';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { TreePage } from './components';
import { MainTabProvider } from 'providers';

const MIN_WIDTH = 320;
const INITIAL_WIDTH = 400;

const MenuWrapper = styled.div<{ width: number; isResizing: boolean }>`
  display: flex;
  justify-content: left;
  color: ${NEUTRAL_10_COLOUR};
  background-color: #fff;
  width: ${({ width }) => `${width}px`};
  max-width: 33.3vw;
  min-width: ${`${MIN_WIDTH}px`};
  height: 100%;
  user-select: ${({ isResizing }) => (isResizing ? 'none' : 'unset')};
  position: relative;
`;

const ResizeHandle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -8px;
  width: 16px;
  z-index: 10;
  display: flex;
  justify-content: center;
  cursor: ew-resize;

  :hover {
    div {
      display: block;
    }
  }
`;

const HandleBorder = styled.div`
  display: none;
  height: 100%;
  width: 2px;
  background-color: ${NEUTRAL_5_COLOUR};
`;

const ToolboxPage = styled.div`
  display: flex;
  border-left: 1px solid ${NEUTRAL_4_COLOUR};
  flex-direction: column;
  justify-content: left;
  width: 100%;
`;

const getPageComponent = (page: string, drawerWidth: number) => {
  switch (page) {
    case TOOLBOX_PAGE_TREE:
      return <TreePage drawerWidth={drawerWidth} />;
    default:
      return null;
  }
};

export const TreeMenu = () => {
  const max_width = window.innerWidth * 0.31;
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(Math.min(INITIAL_WIDTH, max_width));

  const onMouseDown = () => {
    setIsResizing(true);
  };

  const onMouseUp = () => {
    setIsResizing(false);
  };

  const onMouseMove = (e: MouseEvent) => {
    if (isResizing) {
      if (e.clientX > MIN_WIDTH && e.clientX < max_width) {
        setWidth(e.clientX);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  const { toolboxPage } = useBuilderContext();
  return (
    <MenuWrapper width={width} isResizing={isResizing}>
      <MainTabProvider>
        <ToolboxPage>{getPageComponent(toolboxPage, width)}</ToolboxPage>
      </MainTabProvider>
      <ResizeHandle onMouseDown={onMouseDown}>
        <HandleBorder />
      </ResizeHandle>
    </MenuWrapper>
  );
};
