import { customAlphabet } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';

import { OnboardingScreen } from 'api';
import { useLocalAppBasicInfo, useLocalAppProperties, useSaveContext } from 'providers';

export interface OnboardingScreenWithId extends OnboardingScreen {
  id: number;
}

const nanoid = customAlphabet('123456789', 16);

const updatedPositions = (arr: OnboardingScreen[]) => {
  const updatedArr = arr;
  for (let i = 0; i < updatedArr.length; i++) {
    updatedArr[i].Position = i + 1;
  }
  return updatedArr;
};

export const useOnboardingScreens = () => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setBasicInfoValue } = useLocalAppBasicInfo();
  const { setAppPropertyToSave, setAppBasicInfoValueToSave } = useSaveContext();
  const [onboardingScreens, setOnboardingScreens] = useState<OnboardingScreenWithId[]>([]);
  const [onboardingScreensEdited, setOnboardingScreensEdited] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const addOnboardingScreen = useCallback(() => {
    setOnboardingScreensEdited(true);
    setOnboardingScreens((oldState) => [
      ...oldState,
      { ImageName: '', Position: oldState.length + 1, Type: 'image', id: parseInt(nanoid()) },
    ]);
  }, [setOnboardingScreens, setOnboardingScreensEdited]);

  const removeOnboardingScreen = useCallback(
    (id: number) => {
      setOnboardingScreensEdited(true);
      setOnboardingScreens((oldState) => oldState.filter((screen) => screen.id !== id));
    },
    [setOnboardingScreens, setOnboardingScreensEdited],
  );

  useEffect(() => {
    if (properties && !initialized) {
      const sortedScreens = properties.OnboardingScreens?.sort((a, b) => a.Position - b.Position);
      if (sortedScreens) {
        const withIds: OnboardingScreenWithId[] = sortedScreens.map((screen) => ({
          ...screen,
          id: parseInt(nanoid()),
        })); // Assign random ids to use as key since ImageName isn't guaranteed to be unique
        setOnboardingScreens(withIds);
      }

      setInitialized(true);
    }
  }, [properties.OnboardingScreens]);

  const handleFilenameChange = useCallback(
    (id: string | number, filename: string) => {
      if (typeof id === 'number') {
        setOnboardingScreensEdited(true);
        setOnboardingScreens((oldState) => {
          const updatedScreens = oldState.map((screen) => {
            if (screen.id === id) {
              return { ...screen, ImageName: filename };
            }
            return screen;
          });

          return updatedScreens;
        });
      } else if (id === 'SplashScreenImage') {
        setBasicInfoValue(id, filename);
        setAppBasicInfoValueToSave(id, filename);
      } else {
        setAppProperty(id, filename);
        setAppPropertyToSave(id, filename);
      }
    },
    [
      setAppProperty,
      setBasicInfoValue,
      setAppPropertyToSave,
      setAppBasicInfoValueToSave,
      setOnboardingScreens,
      setOnboardingScreensEdited,
    ],
  );

  const setOnboardingScreensToSave = useCallback(
    (screens: OnboardingScreenWithId[]) => {
      if (!!onboardingScreensEdited) {
        const onboardingScreensToSave: OnboardingScreen[] = screens.map((screen) =>
          // Remove temporary id before saving
          ({
            ImageName: screen.ImageName,
            Position: screen.Position,
            Type: screen.Type,
          }),
        );

        const filteredScreens = onboardingScreensToSave.filter((screen) => screen.ImageName !== ''); // Remove empty screens
        const valueToSave = JSON.stringify(updatedPositions(filteredScreens));

        setAppProperty('OnboardingScreens', valueToSave);
        setAppPropertyToSave('OnboardingScreens', valueToSave);
      }
    },
    [setAppProperty, setAppPropertyToSave, onboardingScreensEdited],
  );

  useEffect(() => {
    setOnboardingScreensToSave(onboardingScreens);
  }, [onboardingScreens]);

  return {
    handleFilenameChange,
    onboardingScreens,
    addOnboardingScreen,
    removeOnboardingScreen,
  };
};
