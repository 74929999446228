import { Dispatch, SetStateAction } from 'react';

import { BuilderProducts, LocalProductsProvider, SaveProvider } from 'providers';

import { useOffers } from '../hooks/useOffers';
import { OffersInternal } from './OffersInternal';

export const Offers = () => {
  const { products, setProducts, activeProductId, setActiveProductId } = useOffers();

  return (
    <LocalProductsProvider
      products={products}
      setProducts={setProducts as Dispatch<SetStateAction<BuilderProducts>>}
      activeProductId={activeProductId}
      setActiveProductId={setActiveProductId}
    >
      <SaveProvider>
        <OffersInternal />
      </SaveProvider>
    </LocalProductsProvider>
  );
};
