import styled from 'styled-components';
import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { APP_FONT_12PX_MEDIUM, APP_FONT_16PX_MEDIUM } from 'mockup-font';
import { AppVideoIcon, FileBlankIcon, NoThumbnailsIcon } from 'icons';
import { BuilderCollection, BuilderCollectionItem, useContent } from 'providers';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { COURSE_DD_NO_THUMBNAILS, Video, VIDEO_TYPE_POST, VIDEO_TYPE_VIDEO } from 'api';
import {
  CustomColor,
  getContentName,
  getVideoMinutes,
  isSectionHeaderItem,
  useCustomColor,
  usePointerOnClick,
} from 'utils';
import { useAppProperties } from 'hooks';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { SectionHeaderItem } from '../SectionHeaderItem';

const StyledThumbnail = styled(ContentThumbnail)`
  width: 79px;
  height: unset;
  margin-right: 16px;
`;
const ItemTitle = styled.div<CustomColor>`
  ${APP_FONT_16PX_MEDIUM};
  color: ${useCustomColor};
`;
const ItemSubtitle = styled.div<CustomColor>`
  ${APP_FONT_12PX_MEDIUM};
  color: ${useCustomColor};
  margin-top: 8px;
`;

const ItemContainer = styled.div<{ onClick?: () => void }>`
  display: flex;
  align-items: center;
  cursor: ${usePointerOnClick};
`;

const StyledIcon = styled(AppVideoIcon)`
  font-size: 14px;
  margin-right: 4px;

  svg {
    fill: none;
  }
`;
const StyledFile = styled(FileBlankIcon)`
  font-size: 14px;
  margin-right: 4px;
`;

const StyledNoThumbnailIcon = styled(NoThumbnailsIcon)<CustomColor>`
  &&& {
    color: ${useCustomColor};
    font-size: 24px;
    margin-right: 16px;
  }
`;

const getSubtitle = (item: BuilderCollectionItem, content: Video | BuilderCollection) => {
  if (content.Type === VIDEO_TYPE_VIDEO) {
    return getVideoMinutes((content as Video)?.DurationSeconds);
  } else if (content.Type === VIDEO_TYPE_POST) {
    return 'Read';
  }
  return '';
};

interface CourseViewItemProps {
  item: BuilderCollectionItem;
  onClick?: (item: BuilderCollectionItem) => void;
}

export const CourseViewItem = ({ item, onClick }: CourseViewItemProps) => {
  const { getDesignProperty } = useAppTheme();
  const { getContentForItem } = useContent();
  const { data: properties } = useAppProperties();
  const { navigationEnabled } = useMockupContext();

  const content = getContentForItem(item) as Video; // Should all be videos
  const $color = getDesignProperty('primaryItemHEX') as string;
  const isThumbnails = !(properties?.CourseTemplateDesign === COURSE_DD_NO_THUMBNAILS);

  if (!content) {
    return null;
  }

  if (isSectionHeaderItem(item)) {
    return <SectionHeaderItem content={content} $marginBottom="0" />;
  }

  const subtitle = getSubtitle(item, content);

  return (
    <ItemContainer onClick={onClick && navigationEnabled ? () => onClick(item) : undefined}>
      {isThumbnails ? <StyledThumbnail thumbnail={content} size="small" /> : <StyledNoThumbnailIcon $color={$color} />}
      <div>
        <ItemTitle $color={$color}>{getContentName(content, item.Type)}</ItemTitle>
        {subtitle && (
          <ItemSubtitle $color={$color}>
            {content.Type === VIDEO_TYPE_POST ? <StyledFile /> : <StyledIcon />}
            {subtitle}
          </ItemSubtitle>
        )}
      </div>
    </ItemContainer>
  );
};
