import { useRef, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Carousel } from 'antd';

import { HIGHLIGHT_PRIMARY_COLOUR } from 'theme';
import { useLocalAppBasicInfo, useLocalAppProperties } from 'providers';
import { OnboardingScreen, S3_BUCKET } from 'api';
import { useAppBeingEdited } from 'app-context';
import { AppBackIcon, ForwardIcon } from 'icons';

interface CarouselRef {
  goTo: (slide: number, dontAnimate?: boolean) => void;
  next: () => void;
  prev: () => void;
  autoPlay: (playType?: 'update' | 'leave' | 'blur') => void;
  innerSlider: unknown;
}

interface WrapperProps {
  backgroundImage?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-size: cover;
  background-position: center;
  position: relative;

  .ant-carousel {
    height: 100%;
  }
`;

const ArrowButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const PrevButton = styled(ArrowButton)`
  left: -65px;
`;

const NextButton = styled(ArrowButton)`
  right: -65px;
`;

const StyledCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;

  .slick-dots li button {
    background-color: ${HIGHLIGHT_PRIMARY_COLOUR} !important;
  }
`;

const CarouselScreen = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    height: 894px;
    transform: translateX(-27%);
  }
`;

export const OnboardingScreensView = () => {
  const { basicInfo } = useLocalAppBasicInfo();
  const { properties } = useLocalAppProperties();
  const appId = useAppBeingEdited();

  const { SplashScreenImage } = basicInfo;
  const { OnboardingScreenBackground } = properties;

  const filePath = `${S3_BUCKET}${appId}/images/`;

  const carouselRef = useRef<CarouselRef>(null);

  const prevSlide = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  }, [carouselRef]);

  const nextSlide = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  }, [carouselRef]);

  const screenArr = useMemo(() => {
    if (properties.OnboardingScreens) {
      if (typeof properties.OnboardingScreens === 'string') {
        // If OnboardingScreens have been updated, this will be a JSON string
        return JSON.parse(properties.OnboardingScreens) as OnboardingScreen[];
      }
      return properties.OnboardingScreens;
    }
    return [];
  }, [properties.OnboardingScreens]);

  return !!screenArr && screenArr.length > 0 ? (
    <>
      <Wrapper backgroundImage={OnboardingScreenBackground && `${filePath}${OnboardingScreenBackground}`}>
        <StyledCarousel ref={carouselRef}>
          {screenArr
            .filter((screen) => screen.ImageName)
            .map((screen, idx) => {
              return (
                <CarouselScreen key={idx}>
                  <img src={`${filePath}${screen.ImageName}`} alt={`Onboarding Screen ${idx}`} />
                </CarouselScreen>
              );
            })}
        </StyledCarousel>
      </Wrapper>
      {screenArr.length > 1 && (
        <>
          <PrevButton onClick={prevSlide}>
            <AppBackIcon />
          </PrevButton>
          <NextButton onClick={nextSlide}>
            <ForwardIcon />
          </NextButton>
        </>
      )}
    </>
  ) : (
    <Wrapper backgroundImage={SplashScreenImage && `${filePath}${SplashScreenImage}`} />
  );
};
