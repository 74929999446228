import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { BuilderCollection, BuilderVideo, useContent, useLocalAppProperties } from 'providers';
import { CustomColor, useCustomColor } from 'utils';
import { DEFAULT_COLLECTION, DEFAULT_COLLECTION_ITEM } from 'api';
import { DownloadCloudIcon, HeadphonesIcon, WatchlistIcon } from 'icons';
import { APP_FONT_15PX_REGULAR, APP_FONT_20PX_MEDIUM } from 'mockup-font';

import { useAppTheme } from '../../hooks';
import { AppCollection } from '../AppCollection';
import { SubNavDisplay } from '../SubNavDisplay';

const Wrapper = styled.div<{ $backgroundColor: string } & CustomColor>`
  width: 100%;
  min-height: 100%;
  cursor: default;
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  display: flex;
  flex-direction: column;
`;

const EmptyTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 50px;

  svg {
    font-size: 67px;
    fill: none;
  }
`;

const EmptyTitle = styled.div`
  ${APP_FONT_20PX_MEDIUM};
  text-align: center;
  margin: 23px 0 9px;
`;

const EmptyText = styled.div<CustomColor>`
  ${APP_FONT_15PX_REGULAR};
  color: ${useCustomColor};
  text-align: center;
`;

export const MyListsView = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { videos } = useContent();
  const { properties, getPropertyOrDefault } = useLocalAppProperties();
  const { getDesignProperty } = useAppTheme();
  const PrimaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const SecondaryItemHEX = getDesignProperty('secondaryItemHEX') as string;
  const BackgroundHEX = getDesignProperty('backgroundHEX') as string;
  const showFavorites = properties.HideSidebarFavourites !== '1';
  const showWatchlist = properties.HideSidebarWatchlist !== '1';
  const showDownloads = properties.HideSidebarDownloads !== '1';
  const showAudio = properties.HideSidebarAudioDownloads !== '1';
  const showSubNav = showFavorites || showWatchlist || showDownloads || showAudio;

  useEffect(() => {
    setSelectedIndex(0);
  }, [showFavorites, showWatchlist, showDownloads, showAudio]);

  const randomVideos = useMemo(() => {
    if (videos) {
      const arr: BuilderVideo[] = [];

      const videoArray = Object.values(videos);
      const videosWithThumbnails = videoArray.filter((video) => video.Thumbnails.medium); // Only include videos with thumbnails
      const videosToDisplay = Math.min(videosWithThumbnails.length, 4); // Return 4 videos, unless there are less than 4 videos with thumbnails

      const indexes = new Set<number>(); // Generate up to 4 random indexes
      while (indexes.size !== videosToDisplay) {
        indexes.add(Math.floor(Math.random() * videosWithThumbnails.length));
      }

      indexes.forEach((i) => arr.push(videosWithThumbnails[i]));

      return arr;
    }
    return [];
  }, [videos]);

  const favoritesCollection: BuilderCollection = useMemo(
    () => ({
      ...DEFAULT_COLLECTION,
      Name: getPropertyOrDefault('CustomFavoritesLabel'),
      Items: randomVideos.map((v, idx) => ({
        ...DEFAULT_COLLECTION_ITEM,
        TabId: 1,
        ChildId: v.VideoId,
        AppId: '',
        Position: idx + 1,
        Type: 'video',
        SubType: 'video',
      })),
      TabId: 1,
      AppId: '',
      TemplateId: 3,
      Thumbnails: {},
    }),
    [randomVideos, getPropertyOrDefault],
  );

  const subNavItems = useMemo(() => {
    const items = [];

    if (showFavorites) {
      items.push({
        name: getPropertyOrDefault('CustomFavoritesLabel'),
        component: <AppCollection collection={favoritesCollection} templateId={2} hideHeader />,
        id: `favorites`,
      });
    }

    if (showWatchlist) {
      items.push({
        name: getPropertyOrDefault('CustomWatchlistLabel'),
        component: (
          <EmptyTab>
            <WatchlistIcon />
            <EmptyTitle>{getPropertyOrDefault('CustomWatchlistLabel')}</EmptyTitle>
            <EmptyText $color={SecondaryItemHEX}>
              Tap the list icon on a video to save content you want to watch later
            </EmptyText>
          </EmptyTab>
        ),
        id: `watchlist`,
      });
    }

    if (showDownloads) {
      items.push({
        name: getPropertyOrDefault('CustomDownloadsLabel'),
        component: (
          <EmptyTab>
            <DownloadCloudIcon />
            <EmptyTitle>{getPropertyOrDefault('CustomDownloadsLabel')}</EmptyTitle>
            <EmptyText $color={SecondaryItemHEX}>
              Tap the ‘Download’ icon on a video to play without a connection
            </EmptyText>
          </EmptyTab>
        ),
        id: `downloads`,
      });
    }

    if (showAudio) {
      items.push({
        name: getPropertyOrDefault('CustomAudioDownloadsLabel'),
        component: (
          <EmptyTab>
            <HeadphonesIcon />
            <EmptyTitle>{getPropertyOrDefault('CustomAudioDownloadsLabel')}</EmptyTitle>
            <EmptyText $color={SecondaryItemHEX}>
              Tap the ‘Download’ icon on a video to listen without a connection
            </EmptyText>
          </EmptyTab>
        ),
        id: `audio`,
      });
    }

    return items;
  }, [properties, favoritesCollection, SecondaryItemHEX]);

  return (
    <Wrapper $color={PrimaryItemHEX} $backgroundColor={BackgroundHEX}>
      {showSubNav && (
        <SubNavDisplay
          sticky
          overrideSelected={selectedIndex}
          onChange={(index) => setSelectedIndex(index)}
          items={subNavItems}
        />
      )}
    </Wrapper>
  );
};
