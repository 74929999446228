import { useQuery, useQueryClient } from 'react-query';
import { useAxiosInstance, getSecureAppProperties } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useSecureAppProperties = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  return useQuery(
    ['secureAppProperties', appId],
    () => {
      return getSecureAppProperties(client, appId);
    },
    {},
  );
};

export const usePrefetchSecureAppProperties = async () => {
  const queryClient = useQueryClient();
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  await queryClient.prefetchQuery(['secureAppProperties', appId], () => getSecureAppProperties(client, appId));
};
