import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Select } from 'antd';

import { NEUTRAL_5_COLOUR, HIGHLIGHT_SECONDARY_COLOUR, HIGHLIGHT_PRIMARY_COLOUR } from 'theme';
import { FONT_14PX_MEDIUM } from 'font';
import { BuilderCollection, useContent, useSaveContext } from 'providers';

const LABEL_STYLE = css`
  background-color: ${HIGHLIGHT_SECONDARY_COLOUR};
  border-radius: 3px;
  border: none;
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  ${FONT_14PX_MEDIUM};
`;

const SelectInput = styled(Select)`
  width: 100%;
  min-height: 42px;
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;

  #react-app && .ant-select-selector {
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding: 7px 12px 0px;
    border: none;
    background: none !important;
  }

  input {
    border: none;
  }

  #react-app && .ant-select-selection-item {
    ${LABEL_STYLE};
    svg {
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
    }
    height: 100%;
    margin: 2px 8px 6px 0;
    display: flex;
    align-items: center;
  }

  #react-app && .ant-select-selection-search-input {
    background: none;
  }
`;

const OptionLabel = styled.div`
  ${LABEL_STYLE};
  display: inline-block;
`;

interface WorkoutTagInputProps {
  collection: BuilderCollection;
  id?: number;
  options: {
    name: string;
    value: string;
  }[];
  value?: string;
}

export const WorkoutTagInput = ({ collection, id, options, value }: WorkoutTagInputProps) => {
  const { setCollectionProperty } = useContent();
  const { setCollectionPropertyToSave } = useSaveContext();

  const filteredOptions = useMemo(
    () => (value ? options.filter(({ name }) => !value.includes(name)) : options),
    [options, value],
  );

  const handleChange = useCallback(
    (value: string[]) => {
      if (collection && id) {
        const property = `Filter${id}`;
        const valueToSave = JSON.stringify(value);
        setCollectionProperty(collection.TabId, property, valueToSave);
        setCollectionPropertyToSave(collection.TabId, property, valueToSave);
      }
    },
    [id, setCollectionProperty, setCollectionPropertyToSave],
  );

  return (
    <SelectInput
      mode="multiple"
      placeholder="Add tags"
      value={value}
      onChange={(value) => handleChange(value as string[])}
      notFoundContent=""
      dropdownMatchSelectWidth={295}
      getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
    >
      {filteredOptions.map((option) => (
        <Select.Option key={option.value}>
          <OptionLabel>{option.value}</OptionLabel>
        </Select.Option>
      ))}
    </SelectInput>
  );
};
