import { useCallback } from 'react';
import styled from 'styled-components';

import { SettingsNumberInput } from 'components';
import { FONT_12PX_REGULAR, FONT_14PX_REGULAR } from 'font';
import { useContent, useSaveContext } from 'providers';

const StyledNumberInput = styled(SettingsNumberInput)<{ $inModal?: boolean }>`
  &&&& {
    width: ${({ $inModal }) => ($inModal ? '100%' : '31px')};
    height: ${({ $inModal }) => ($inModal ? '36px' : '30px')};
    margin: ${({ $inModal }) => ($inModal ? '0' : ' 0 12px 0 8px')};
    padding: 0;
    display: flex;
    align-items: center;
    ${({ $inModal }) => ($inModal ? FONT_14PX_REGULAR : FONT_12PX_REGULAR)};

    .ant-input-number {
      margin: ${({ $inModal }) => ($inModal ? '0px 11px' : ' 0px 7px')};
      height: 100%;
    }
  }
`;

interface CircuitRoundsInputProps {
  tabId: string | number;
  inModal?: boolean;
}

export const CircuitRoundsInput = ({ tabId, inModal }: CircuitRoundsInputProps) => {
  const { collections, setCollectionProperty } = useContent();
  const { setCollectionPropertyToSave } = useSaveContext();

  const circuit = collections[tabId];

  const changeCircuitRounds = useCallback(
    (value: number) => {
      if (!!value) {
        setCollectionProperty(tabId, 'CircuitRounds', value.toString());
        setCollectionPropertyToSave(tabId, 'CircuitRounds', value.toString());
      }
    },
    [setCollectionProperty, setCollectionPropertyToSave],
  );

  return (
    <StyledNumberInput
      autoComplete="off"
      value={circuit.Properties?.CircuitRounds}
      placeholder="1"
      onChange={(value) => changeCircuitRounds(value as number)}
      onFocus={(event) => event.target.select()}
      min={1}
      max={99}
      maxLength={2}
      prefix=""
      $inModal={inModal}
    />
  );
};
