import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';

import { BuilderCollectionItem } from 'providers';
import { NEUTRAL_10_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { FONT_14PX_REGULAR, FONT_14PX_SEMIBOLD, FONT_16PX_MEDIUM } from 'font';
import { ExclamationCircleIcon, XIcon } from 'icons';

import { CollectionDetailedItem } from 'app/modules/build-dragdrop/Builder/drawer/components';

const Container = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  overflow-y: auto;
  flex-grow: 1;
  background-color: ${NEUTRAL_3_COLOUR};
`;

const Heading = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
  margin-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
`;
const ItemsScrollView = styled.div`
  overflow-y: auto;
`;

const DeleteIcon = styled(XIcon)`
  color: ${NEUTRAL_10_COLOUR};
  font-size: 16px;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${NEUTRAL_7_COLOUR};
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 33px;
`;
const EmptyTitle = styled.div`
  ${FONT_16PX_MEDIUM};
  margin-top: 8px;
`;
const EmptySubtitle = styled.div`
  ${FONT_14PX_REGULAR};
  margin-top: 4px;
  max-width: 150px;
  overflow: hidden;
`;

interface ContentPreviewProps {
  tabId: string;
  items: BuilderCollectionItem[];
  setItems: Dispatch<SetStateAction<ContentPreviewProps['items']>>;
}

export const ContentPreview = ({ tabId, items, setItems }: ContentPreviewProps) => {
  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;
      if (!destination || destination.index === source.index) {
        return;
      }
      setItems((currentItems) => {
        const updatedItems = [...currentItems];
        const draggedItem = updatedItems.find((item) => item.ChildId.toString() === draggableId.split('|')[0]);
        updatedItems.splice(source.index, 1);
        if (draggedItem) {
          updatedItems.splice(destination.index, 0, draggedItem);
        }
        return updatedItems.map((item, idx) => ({ ...item, Position: idx + 1 }));
      });
    },
    [setItems],
  );

  const handleDeleteItem = useCallback(
    (idx: number) => {
      setItems((currentItems) => {
        const updatedItems = [...currentItems];
        updatedItems.splice(idx, 1);
        return [...updatedItems];
      });
    },
    [setItems],
  );

  return (
    <Container>
      <Heading>Included Content</Heading>
      {items?.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`${tabId}|CustomiseContent`} type="details">
            {(provided) => {
              return (
                <ItemsScrollView ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, idx) => {
                    return (
                      <CollectionDetailedItem context="CustomiseContent" item={item} idx={idx} key={item.TabItemId}>
                        <DeleteIcon onClick={() => handleDeleteItem(idx)} />
                      </CollectionDetailedItem>
                    );
                  })}
                  {provided.placeholder}
                </ItemsScrollView>
              );
            }}
          </Droppable>
        </DragDropContext>
      ) : (
        <EmptyContainer>
          <ExclamationCircleIcon />
          <EmptyTitle>No Content</EmptyTitle>
          <EmptySubtitle>You haven't added any content yet</EmptySubtitle>
        </EmptyContainer>
      )}
    </Container>
  );
};
