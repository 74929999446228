import { useModalContext } from '../providers/modal-provider';
import { Post, TextImage, VidAppFile, Website } from 'app/modules/build-dragdrop/Builder/customise-items/components';
import { BuilderCollection, BuilderVideo, useContent, useSaveContext } from 'providers';
import {
  COLLECTION_PROPERTY_HTMLPAGE_DESC,
  COLLECTION_PROPERTY_WEBSITE_URL,
  DEFAULT_COLLECTION,
  DEFAULT_COLLECTION_ITEM,
  DEFAULT_FILE,
  DEFAULT_POST,
  ITEM_TYPE_COLLECTION,
  ITEM_TYPE_VIDEO,
  SOURCE_TYPE_TEXT,
  SOURCE_TYPE_WEBSITE,
  TEXT_IMAGE_TEMPLATE,
  VIDEO_TYPE_POST,
  WEBSITE_TEMPLATE,
} from 'api';
import { getCollectionValuesFromNewCollection, getVideoValuesFromNewVideo } from 'utils';
import { useAppBeingEdited } from 'app-context';

const getThumbnails = (entity: { thumbnail?: string; squareThumbnail?: string; portraitThumbnail?: string }) => {
  return {
    Thumbnails: { source: entity.thumbnail ?? '' },
    SquareThumbnails: { source: entity.squareThumbnail ?? '' },
    PortraitThumbnails: { source: entity.portraitThumbnail ?? '' },
    ThumbnailSource: entity.thumbnail ?? '',
    SquareThumbnailSource: entity.squareThumbnail ?? '',
    PortraitThumbnailSource: entity.portraitThumbnail ?? '',
  };
};

export const useCreateItem = (collectionId: BuilderCollection['TabId']) => {
  const { getTempId, setCollectionToSave, setCollectionPropertyToSave, setVideoToSave } = useSaveContext();
  const { setNewCollection, setCollectionProperty, setNewVideo } = useContent();
  const { addItem, addPendingSave, editedItems } = useModalContext();
  const appId = useAppBeingEdited();

  const createWebsite = (website: Website) => {
    const tempTabId = `TempTab${getTempId()}`;
    const newCollection = {
      AppId: appId,

      ...DEFAULT_COLLECTION,
      TabId: tempTabId,
      TemplateId: WEBSITE_TEMPLATE,
      Position: 0,
      Name: website.title ?? '',
      SourceType: SOURCE_TYPE_WEBSITE,
      Items: [],
      ...getThumbnails(website),
    };
    setNewCollection(tempTabId, newCollection);
    addItem({
      ...DEFAULT_COLLECTION_ITEM,
      AppId: appId,
      TabId: collectionId,
      ChildId: tempTabId,
      Type: ITEM_TYPE_COLLECTION,
      SubType: ITEM_TYPE_COLLECTION,
      Position: editedItems.length + 1,
      TabItemId: `TempTabItem${getTempId()}`,
    });
    setCollectionProperty(tempTabId, COLLECTION_PROPERTY_WEBSITE_URL, website.url ?? '');
    // Website will be set to save if modal is confirmed
    addPendingSave(() => {
      setCollectionToSave(tempTabId, getCollectionValuesFromNewCollection(newCollection));
      setCollectionPropertyToSave(tempTabId, COLLECTION_PROPERTY_WEBSITE_URL, website.url ?? '');
    });
  };

  const createTextImage = (ti: TextImage) => {
    const tempTabId = `TempTab${getTempId()}`;
    const newCollection = {
      AppId: appId,
      ...DEFAULT_COLLECTION,
      ...getThumbnails(ti),
      TabId: tempTabId,
      TemplateId: TEXT_IMAGE_TEMPLATE,
      Position: 0,
      Name: ti.title ?? '',
      Description: '',
      SourceType: SOURCE_TYPE_TEXT,
      Items: [],
    };
    setNewCollection(tempTabId, newCollection);
    setCollectionProperty(tempTabId, COLLECTION_PROPERTY_HTMLPAGE_DESC, ti.description);
    addItem({
      ...DEFAULT_COLLECTION_ITEM,
      AppId: appId,
      TabId: collectionId,
      ChildId: tempTabId,
      Type: ITEM_TYPE_COLLECTION,
      SubType: ITEM_TYPE_COLLECTION,
      Position: editedItems.length + 1,
      TabItemId: `TempTabItem${getTempId()}`,
    });
    addPendingSave(() => {
      setCollectionToSave(tempTabId, getCollectionValuesFromNewCollection(newCollection));
      setCollectionPropertyToSave(tempTabId, COLLECTION_PROPERTY_HTMLPAGE_DESC, ti.description ?? '');
    });
  };

  const createFile = (file: VidAppFile) => {
    const tempVideoId = `TempVideo${getTempId()}`;
    const newVideo: BuilderVideo = {
      ...DEFAULT_FILE,
      AppId: appId,
      VideoId: tempVideoId,
      SourceId: '',
      Title: file.title ?? '',
      ...getThumbnails(file),
      Description: '',
      ProductId: '-1',
      HideFromSearchResults: file.hideFromSearch ? 1 : 0,
      OriginalFilename: file.filename ?? '',
    };
    setNewVideo(tempVideoId, newVideo);
    addItem({
      ...DEFAULT_COLLECTION_ITEM,
      AppId: appId,
      TabId: collectionId,
      ChildId: tempVideoId,
      Type: ITEM_TYPE_VIDEO,
      SubType: ITEM_TYPE_VIDEO,
      Position: editedItems.length + 1,
      TabItemId: `TempTabItem${getTempId()}`,
    });
    // Post will be set to save if modal is confirmed
    addPendingSave(() => {
      setVideoToSave(tempVideoId, getVideoValuesFromNewVideo(newVideo));
    });
  };

  const createPost = (post: Post) => {
    const tempVideoId = `TempVideo${getTempId()}`;
    const newVideo: BuilderVideo = {
      ...DEFAULT_POST,
      AppId: appId,
      VideoId: tempVideoId,
      SourceId: '',
      Title: post.title ?? '',
      ...getThumbnails(post),
      Description: post.description ?? '',
      ProductId: post.access ?? '-1',
      HideFromSearchResults: post.hideFromSearch ? 1 : 0,
      Resources: post.resources ?? [],
    };
    setNewVideo(tempVideoId, newVideo);
    addItem({
      ...DEFAULT_COLLECTION_ITEM,
      AppId: appId,
      TabId: collectionId,
      ChildId: tempVideoId,
      Type: ITEM_TYPE_VIDEO,
      SubType: VIDEO_TYPE_POST,
      Position: editedItems.length + 1,
      TabItemId: `TempTabItem${getTempId()}`,
    });
    // Post will be set to save if modal is confirmed
    addPendingSave(() => {
      setVideoToSave(tempVideoId, getVideoValuesFromNewVideo(newVideo));
    });
  };

  return { createWebsite, createTextImage, createFile, createPost };
};
