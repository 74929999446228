import { ReactNode, useEffect } from 'react';
import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd';
import styled from 'styled-components';

import { STYLING_ID } from 'theme';

export interface ModalProps extends AntdModalProps {
  children?: ReactNode | string | ReactNode[];
  $radius?: string;
  $padding?: string;
  $height?: string;
  $contentWidth?: string;
  closable?: boolean;
}

const UnstyledModal = ({ children, visible, width, closable, ...props }: ModalProps) => {
  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [visible]); // Disable background scroll when modal is open

  return (
    <AntdModal
      open={visible}
      width={width || 960}
      centered
      closable={closable ?? false}
      maskClosable={true}
      footer={null}
      getContainer={`#${STYLING_ID}`}
      {...props}
    >
      {children}
    </AntdModal>
  );
};

export const Modal = styled(UnstyledModal)`
  margin: 10px;

  #react-app && {
    .ant-modal-body {
      padding: ${(props) => props.$padding || '0 0 10px'};
      overflow-x: visible;
      overflow-y: visible;
      border-radius: ${(props) => props.$radius || '8px'};
      width: ${(props) => props.$contentWidth || 'fit-content'};
      height: 100%;
    }

    .ant-modal-content {
      border-radius: ${(props) => (props.$radius || '8px') + ' !important'};
      width: ${(props) => props.$contentWidth || 'fit-content'};
      height: ${(props) => props.$height || '650px'};
      border: none;
    }
  }
`;
