import styled, { css } from 'styled-components';

import { useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { NAVBAR_HEIGHT } from 'app/modules/build-dragdrop/Builder/mockup/const';
import { useMockupContext } from '../providers';
import { CollectionNavBar, TabNavBar } from '../components';
import { ITEM_TYPE_COLLECTION, ITEM_TYPE_VIDEO } from 'api';
import { useMemo } from 'react';
import { isResource } from 'utils';
import { FileNavBar } from 'app/modules/build-dragdrop/Builder/mockup/components/NavBarViews/FileNavBar';

interface NavBarProps {
  $backgroundColor: string;
  $collapsedBackgroundColor: string;
  $color: string;
  $borderColor: string;
  $collapsed?: boolean;
}

const NavBar = styled.div<NavBarProps>`
  width: 100%;
  ${({ $collapsed }) =>
    $collapsed
      ? css`
          height: 44px;
          padding-top: 44px;
        `
      : css`
          height: ${NAVBAR_HEIGHT}px;
          padding: 48px 0 12px;
        `}

  display: flex;
  align-items: center;

  background-color: ${(props) => (props.$collapsed ? props.$collapsedBackgroundColor : props.$backgroundColor)};
  color: ${(props) => props.$color};

  //TODO Determine why there is a border here
  border-color: ${(props) => props.$borderColor};

  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  z-index: 12; // On top of the AppContent
  position: relative;
`;

export const AppNavBar = () => {
  const { getDesignProperty } = useAppTheme();
  const { currentTabId, currentPage } = useMockupContext();
  const { collections, videos } = useContent();

  const hideNavBar = useMemo(() => {
    if (currentPage?.itemType && ['OnboardingScreens', 'PurchaseScreens'].includes(currentPage.itemType)) {
      return true;
    }
    if (currentPage?.itemType === ITEM_TYPE_VIDEO && currentPage.itemId) {
      const video = videos[currentPage.itemId];
      return !isResource(video);
    }
    return false;
  }, [currentPage, videos]);

  return (
    <NavBar
      $backgroundColor={getDesignProperty('navBarBackgroundHEX') as string}
      $color={getDesignProperty('navBarTitleHEX') as string}
      $borderColor={getDesignProperty('primaryElevationHEX') as string}
      $collapsed={currentPage?.itemType === ITEM_TYPE_VIDEO}
      $collapsedBackgroundColor={getDesignProperty('backgroundHEX') as string}
    >
      {hideNavBar ? null : currentPage?.itemId && currentPage?.itemType === ITEM_TYPE_COLLECTION ? (
        <CollectionNavBar collection={collections[currentPage.itemId]} />
      ) : currentPage?.itemId && currentPage?.itemType === ITEM_TYPE_VIDEO ? (
        <FileNavBar file={videos[currentPage.itemId]} />
      ) : currentTabId ? (
        <TabNavBar tab={collections[currentTabId]} />
      ) : null}
    </NavBar>
  );
};
