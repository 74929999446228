import styled, { css } from 'styled-components';
import { useMemo, useState } from 'react';
import DOMPurify from 'dompurify';

import { VIDEO_DETAILS_EXPANDABLE_DESC, VIDEO_DETAILS_RESOURCES } from 'api';
import { getVideoHTMLDescription, getVideoMinutes, getVideoName, isResource } from 'utils';
import { APP_FONT_10PX_SEMIBOLD, APP_FONT_18PX_MEDIUM } from 'mockup-font';
import { AppFileIcon, AppVideoIcon, FilledRightArrow } from 'icons';
import { FONT_16PX_REGULAR } from 'font';
import { BuilderCollection, BuilderVideo } from 'providers';
import { useAppProperties } from 'hooks';
import { Collapse } from 'components';

import { useAppTheme } from '../../hooks';
import { ActionRow, CollectionResource, FileView, VideoPlayer, VideoSubNav } from '..';

const BodyContainer = styled.div`
  padding: 16px;
`;

const TitleRow = styled.div<{ $color: string }>`
  display: flex;
  color: ${({ $color }) => $color};
`;
const StyledArrow = styled(FilledRightArrow)<{ expandedDesc?: boolean }>`
  &&&& {
    font-size: 24px;
    flex-grow: 0;
    transform: ${({ expandedDesc }) => `rotate(${expandedDesc ? '-90deg' : '90deg'})`};
  }
`;

const VideoTitle = styled.div`
  flex-grow: 1;
  ${APP_FONT_18PX_MEDIUM};
`;
const VideoSubtitle = styled.div<{ $color: string }>`
  display: flex;
  height: 20px;
  align-items: center;
  color: ${({ $color }) => $color};
  ${APP_FONT_10PX_SEMIBOLD};
  margin-top: 8px;
  margin-bottom: 4px;
`;

const Separator = styled.div<{ $color: string }>`
  border-top: 1px solid ${({ $color }) => $color};
  width: 100%;
`;

const CollapseDesc = styled.div<{ $color?: string }>`
  padding: 0 16px;
  ${FONT_16PX_REGULAR};
  color: ${({ $color }) => $color};

  height: fit-content;
  overflow: hidden;
`;
const Resources = styled.div`
  padding: 16px;
`;
const ResourcesTitle = styled.div`
  padding: 16px 16px 0;
  ${APP_FONT_18PX_MEDIUM}
`;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const IconStyles = css`
  font-size: 14px;
  margin-right: 4px;

  svg {
    fill: none;
  }
`;

const StyledIcon = styled(AppVideoIcon)`
  ${IconStyles}
`;
const StyledFileIcon = styled(AppFileIcon)`
  ${IconStyles}
`;

export interface VideoDetailsProps {
  video: BuilderVideo;
  parentCollection?: BuilderCollection;
}

export const VideoDetails = ({ video, parentCollection }: VideoDetailsProps) => {
  const { getDesignProperty } = useAppTheme();
  const { data: appProperties } = useAppProperties();

  const desc = getVideoHTMLDescription(video);
  const $color = getDesignProperty('primaryItemHEX') as string;
  const $secondaryColor = getDesignProperty('secondaryItemHEX') as string;

  // Only relevant for Expandable Description variant
  const [expandedDesc, setExpandedDesc] = useState(false);

  // Which variant of Video Details is this
  const isExpandableDesc = appProperties?.ItemDetailsDesign === VIDEO_DETAILS_EXPANDABLE_DESC;
  const isResourcesBelow = appProperties?.ItemDetailsDesign === VIDEO_DETAILS_RESOURCES;

  const handleToggle = () => setExpandedDesc((prev) => !prev);
  const minutes = getVideoMinutes(video.DurationSeconds);

  const purifiedDesc = useMemo(() => {
    return DOMPurify.sanitize(desc);
  }, [desc]);

  const isFile = useMemo(() => {
    return isResource(video);
  }, [video]);

  return isFile ? (
    <FileView file={video} />
  ) : (
    <>
      <VideoPlayer video={video} />
      <BodyContainer>
        <TitleRow $color={$color}>
          <VideoTitle>{getVideoName(video)}</VideoTitle>
          {isExpandableDesc && <StyledArrow onClick={handleToggle} expandedDesc={expandedDesc} />}
        </TitleRow>
        {isResourcesBelow && (
          <VideoSubtitle $color={$secondaryColor}>
            {video.Type === 'post' ? <StyledFileIcon /> : <StyledIcon />}
            <div>
              {capitalizeFirstLetter(video.Type)}
              {minutes && ` • ${minutes}`}
            </div>
          </VideoSubtitle>
        )}
        <ActionRow />
        <Separator $color={getDesignProperty('secondaryItemHEX') as string} />
      </BodyContainer>
      {isExpandableDesc && (
        <Collapse isOpen={expandedDesc} hideTrigger>
          <CollapseDesc $color={$color} dangerouslySetInnerHTML={{ __html: purifiedDesc }} />
        </Collapse>
      )}
      {isResourcesBelow && (
        <>
          <CollapseDesc $color={$color} dangerouslySetInnerHTML={{ __html: purifiedDesc }} />
          {video.Resources && video.Resources.length > 0 && (
            <>
              <ResourcesTitle>Resources</ResourcesTitle>
              <Resources>
                {video.Resources.map((resource) => {
                  return <CollectionResource {...resource} key={resource.ResourceId} />;
                })}
              </Resources>
            </>
          )}
        </>
      )}
      <VideoSubNav video={video} parentCollection={parentCollection} onNoItems={() => setExpandedDesc(true)} />
    </>
  );
};
