import { CollectionTitle, ColumnModal, Segment } from 'components';
import { useContent } from 'providers';
import styled from 'styled-components';

import { useModalContext } from './providers/modal-provider';
import { CircuitRoundsInput, CircuitTypeRadio, InterRoundRestInput } from '../content-tree/components';

const ScrollContainer = styled.div`
  max-height: 65vh;
  overflow: auto;
  padding: 0 22px 0 24px;
  margin-right: 2px;
`;

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

export const CircuitConfigModal = () => {
  const { modalCircuitId, setModalCircuitId } = useModalContext();
  const { collections } = useContent();

  const collection = modalCircuitId ? collections[modalCircuitId] : undefined;

  return (
    <ColumnModal
      title={!!collection?.Name ? collection?.Name : ' '}
      cols={2}
      align="right"
      onConfirm={() => setModalCircuitId(undefined)}
      open={!!collection}
      onOpenChange={(open) => {
        if (!open) {
          setModalCircuitId(undefined);
        }
      }}
      primaryText="Done"
      hideClose
      hideSecondary
      hideDialogActionsBorder
      contentPadding="0"
      maxHeight="100%"
      hideTrigger
    >
      {collection && (
        <ScrollContainer>
          {/* When modal is opened, first input is automatically selected. Using hidden input to remove that behaviour  */}
          <HiddenInput />
          <CollectionTitle
            TabId={collection.TabId}
            Name={collection.Name}
            placeholder="Circuit Title"
            label="Circuit Title"
            size="middle"
            $marginTop="0"
            $marginBottom="24px"
          />
          <Segment header="Circuit Type" $marginTop="0" $marginBottom="28px">
            <CircuitTypeRadio tabId={collection.TabId} inModal />
          </Segment>
          <Segment header="Rounds" $marginTop="0" $marginBottom="28px">
            <CircuitRoundsInput tabId={collection.TabId} inModal />
          </Segment>
          <Segment header="Inter-Round Rest" $marginTop="0" $marginBottom="20px">
            <InterRoundRestInput tabId={collection.TabId} />
          </Segment>
        </ScrollContainer>
      )}
    </ColumnModal>
  );
};
