import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { DEFAULT_COLLECTION_ITEM, ITEM_TYPE_VIDEO, SOURCE_VIDAPP, VIDEO_TYPE_REST } from 'api';
import { useAppBeingEdited } from 'app-context';
import { CustomButton, Dropdown } from 'components';
import { useAppProperties } from 'hooks';
import { StopwatchIcon } from 'icons';
import { BuilderVideo, useContent, useSaveContext } from 'providers';
import { getVideoValuesFromNewVideo } from 'utils';

const HeaderButton = styled(CustomButton)`
  &&&& {
    margin-left: 4px;
  }
`;

interface AddRestButtonProps {
  collectionId: string | number;
}

export const AddRestButton = ({ collectionId }: AddRestButtonProps) => {
  const { setNewVideo, setCollectionProperty, setCollectionItems, collections } = useContent();
  const { getTempId, setVideoToSave, setCollectionPropertyToSave, setCollectionItemsToSave } = useSaveContext();
  const appId = useAppBeingEdited();
  const { data: appProperties } = useAppProperties();

  const defaultDuration = appProperties?.InterCircuitRestDefault ?? '120';

  const collection = useMemo(() => collections[collectionId], [collections, collectionId]);

  const createNewRest = useCallback(
    (DurationSeconds: string) => {
      const tempId = `TempRest${getTempId()}`;
      const newRest: BuilderVideo = {
        AppId: appId,
        VideoId: tempId,
        Type: VIDEO_TYPE_REST,
        DurationSeconds,
        SourceId: '',
        Title: `Rest`,
        OriginalFilename: `Rest`,
        Description: '',
        ThumbnailSource: '',
        DataSource: SOURCE_VIDAPP,
        IncludedInAppData: 0,
        SourceDescription: '',
        SourceThumbnailSource: '',
        SourceTitle: '',
        Thumbnails: {},
        ProductId: '-1',
      };
      setNewVideo(tempId, newRest);
      setVideoToSave(tempId, getVideoValuesFromNewVideo(newRest));

      setCollectionItems(collection.TabId, [
        ...collection.Items,
        {
          ...DEFAULT_COLLECTION_ITEM,
          AppId: appId,
          TabId: collection.TabId,
          ChildId: tempId,
          Type: ITEM_TYPE_VIDEO,
          SubType: ITEM_TYPE_VIDEO,
          Position: collection.Items.length + 1,
          TabItemId: `TempTabItem${getTempId()}`,
        },
      ]);
      setCollectionItemsToSave(collection.TabId); // Set to be saved
    },
    [
      collection,
      appId,
      getTempId,
      setNewVideo,
      setVideoToSave,
      setCollectionProperty,
      setCollectionPropertyToSave,
      setCollectionItems,
      setCollectionItemsToSave,
    ],
  );

  const DEFAULT_DURATIONS = ['10', '20', '30', '40', '50', '60'];

  const restOptions = [
    ...DEFAULT_DURATIONS.map((duration) => ({
      label: `${duration} seconds`,
      onClick: () => createNewRest(duration),
      keepMenuOpen: true,
    })),
    { label: 'Custom', onClick: () => createNewRest(defaultDuration), keepMenuOpen: true },
  ];

  return (
    <Dropdown
      trigger={
        <HeaderButton icon={<StopwatchIcon />} medium tertiaryHighlight>
          Add Rest
        </HeaderButton>
      }
      options={restOptions}
      menuMinWidth="150px"
    />
  );
};
