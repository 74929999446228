import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import {
  Collection,
  CONTENT_1_SOURCE_ID,
  FEATURED_SOURCE_ID,
  getCollections,
  GetCollectionsResponse,
  KAJABI_LIBRARY_SOURCE_ID,
  MY_PLAYLISTS_TEMPLATE,
  PROFILE_TEMPLATE,
  S3_BUCKET,
  SOURCE_KAJABI,
  SOURCE_TYPE_ARCHIVED,
  SOURCE_TYPE_WORKOUT,
  SOURCE_VIDAPP,
  ThumbnailSize,
  useAxiosInstance,
  Video,
  WORKOUT_TEMPLATE_ID,
} from 'api';
import { useAppBeingEdited } from 'app-context';

const httpRegex = RegExp(/https?:\/\//);

export const useCollections = (
  staleTime?: UseQueryOptions['staleTime'],
  refetchOnMount?: UseQueryOptions['refetchOnMount'],
  options?: UseQueryOptions<GetCollectionsResponse>,
) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const query = useQuery<GetCollectionsResponse>(['collections', appId], () => getCollections(client, appId), {
    staleTime: staleTime ?? 30000,
    refetchOnMount: refetchOnMount ?? true,
    enabled: !!appId, // Don't call fetcher with empty AppId
    ...options,
  });
  const { data } = query;

  const getCollectionById = useCallback(
    (collectionId: number) => {
      const indexedCollection = data?.collection_by_id[collectionId];
      if (indexedCollection) {
        return indexedCollection;
      }
      console.error(`Can't retrieve collection with id ${indexedCollection}`);
      return null;
    },
    [data],
  );

  const getCollectionsByDataSource = useCallback(
    (dataSource: string) => {
      if (data?.collections) {
        return data.collections.filter((collection) => collection.DataSource === dataSource);
      }
      return [];
    },
    [data],
  );

  const getCollectionThumbnailUrl = useCallback(
    (collectionId: number, size: ThumbnailSize) => {
      const imageFolder = `${S3_BUCKET}${appId}/images/`;

      const sizeIsAvailable = (collection: Collection, size: ThumbnailSize) => {
        const thumbnail = collection.Thumbnails[size] ?? '';
        return thumbnail && thumbnail.length > 0 && !thumbnail.includes('Thumbnails are being processed');
      };

      const buildUrl = (thumbnailFile: string) => {
        if (httpRegex.test(thumbnailFile)) {
          return thumbnailFile;
        } else {
          return imageFolder + thumbnailFile;
        }
      };

      const collection = getCollectionById(collectionId);
      if (collection) {
        const sourceUrl =
          collection.ThumbnailSource && collection.ThumbnailSource.length > 0
            ? collection.ThumbnailSource
            : collection.SourceThumbnailSource;
        if (size === 'source') {
          return sourceUrl;
        }
        return sizeIsAvailable(collection, size) // Check if the requested size is available
          ? buildUrl(collection.Thumbnails[size]!)
          : sizeIsAvailable(collection, 'medium') // else check if medium size is available
          ? buildUrl(collection.Thumbnails.medium!)
          : sourceUrl
          ? buildUrl(sourceUrl) // else return source
          : null;
      }
      return null;
    },
    [getCollectionById],
  );

  const isWorkout = useCallback(
    (collectionId: number) => {
      const collection = getCollectionById(collectionId);
      return (
        collection?.SourceType === SOURCE_TYPE_WORKOUT ||
        (collection?.TemplateId === WORKOUT_TEMPLATE_ID && collection?.SourceType !== SOURCE_TYPE_ARCHIVED)
      );
    },
    [getCollectionById],
  );

  const getFeaturedCollection = useCallback(() => {
    const vidappCollections = getCollectionsByDataSource(SOURCE_VIDAPP);
    const vidappFeatured = vidappCollections.find((collection) => collection.SourceId === FEATURED_SOURCE_ID);
    if (vidappFeatured) {
      return vidappFeatured; // If the collection has been edited, a VidApp version will exist, so return this
    } else {
      const kajabiCollections = getCollectionsByDataSource(SOURCE_KAJABI);
      const kajabiFeatured = kajabiCollections.find((collection) => collection.SourceId === FEATURED_SOURCE_ID);
      return kajabiFeatured; // Otherwise, return the default Kajabi Featured collection
    }
  }, [getCollectionsByDataSource]);

  const getContent1Collection = useCallback(() => {
    const vidappCollections = getCollectionsByDataSource(SOURCE_VIDAPP);
    return (
      vidappCollections.find((collection) => collection.SourceId === CONTENT_1_SOURCE_ID) ??
      vidappCollections.find((collection) => collection.SourceId === KAJABI_LIBRARY_SOURCE_ID) // Fallback if Content1 doesn't exist (early Kajabi self-onboarding apps)
    );
  }, [getCollectionsByDataSource]);

  const getContent1SourceId = useCallback(() => {
    const vidappCollections = getCollectionsByDataSource(SOURCE_VIDAPP);
    if (vidappCollections.find((collection) => collection.SourceId === CONTENT_1_SOURCE_ID)) {
      return CONTENT_1_SOURCE_ID;
    } else if (vidappCollections.find((collection) => collection.SourceId === KAJABI_LIBRARY_SOURCE_ID)) {
      return KAJABI_LIBRARY_SOURCE_ID;
    }
    return undefined;
  }, [getCollectionsByDataSource]);

  const getMyPlaylistsTab = useCallback(() => {
    const vidappCollections = getCollectionsByDataSource(SOURCE_VIDAPP);
    return vidappCollections.find(
      (collection) => collection.IsMainTab === 1 && collection.TemplateId === MY_PLAYLISTS_TEMPLATE,
    );
  }, [getCollectionsByDataSource]);

  const getProfileTab = useCallback(() => {
    const vidappCollections = getCollectionsByDataSource(SOURCE_VIDAPP);
    return vidappCollections.find((collection) => collection.TemplateId === PROFILE_TEMPLATE);
  }, [getCollectionsByDataSource]);

  // Used to check whether a collection item is a Collection or a Video
  const isCollection = (itemToCheck: Collection | Video): itemToCheck is Collection =>
    (itemToCheck as Collection).TabId ? true : false;

  return {
    ...query,
    getCollectionById,
    getCollectionsByDataSource,
    getCollectionThumbnailUrl,
    isWorkout,
    getFeaturedCollection,
    getContent1Collection,
    getContent1SourceId,
    getMyPlaylistsTab,
    getProfileTab,
    isCollection,
  };
};

export const usePrefetchCollections = async ({ enabled = false }: { enabled?: boolean }) => {
  const queryClient = useQueryClient();
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  if (appId && enabled) {
    await queryClient.prefetchQuery(['collections', appId], () => getCollections(client, appId));
  }
};
