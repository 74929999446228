import styled from 'styled-components';

import { CustomButton } from 'components';

import { Device } from '../hooks/useAppStore';
import { PlusIcon } from 'icons';

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

interface AddScreenshotButtonProps {
  text: string;
  disabled: boolean;
  device: Device;
  handleClick: (device: Device) => void;
}

export const AddScreenshotButton = ({ text, disabled, device, handleClick }: AddScreenshotButtonProps) => (
  <ButtonWrapper>
    <CustomButton medium icon={<PlusIcon />} onClick={() => handleClick(device)} disabled={disabled}>
      {text}
    </CustomButton>
  </ButtonWrapper>
);
