import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { recordAction, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useLocation } from 'react-router-dom';

export const useRecordAction = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const location = useLocation();

  return useMutation<unknown, AxiosError, { action: string; detail?: Record<string, unknown> }>(
    ({ action, detail }) => {
      return recordAction(client, appId, action, { ...detail, pathname: location?.pathname });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([appId, 'actions']);
      },
    },
  );
};
