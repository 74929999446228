/**
 * The goal for this component is to provide useful admin only details
 * that help the users debug any issues with this content
 */
import { BuilderVideo } from 'providers';
import { AdminOnlyWrapper, Collapse, Segment } from 'components';
import styled from 'styled-components';
import { DANGER_COLOUR, NEUTRAL_10_COLOUR, NEUTRAL_4_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { FONT_12PX_MEDIUM, FONT_14PX_SEMIBOLD, OVERFLOW_ELLIPSIS } from 'font';
import { useVideoFiles } from 'hooks/useVideoFiles';
import { AxiosError } from 'axios';

interface VideoDebugProps {
  video: BuilderVideo;
}

const Container = styled.div`
  margin-top: 24px;
`;

const CollapseTitle = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
`;

const Row = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 6px;

  :hover {
    background-color: ${NEUTRAL_4_COLOUR};
  }

  :not(:last-child) {
    border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
  }
`;
const TextWrapper = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_7_COLOUR};
  height: 100%;
`;
const TitleWrapper = styled(TextWrapper)`
  padding: 10px 12px;
  max-width: 550px;
  ${OVERFLOW_ELLIPSIS};
`;

const TypeWrapper = styled(TextWrapper)`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;
const Error = styled.div`
  color: ${DANGER_COLOUR};
  ${FONT_12PX_MEDIUM};
`;

export const VideoDebug = ({ video }: VideoDebugProps) => {
  const { data, error } = useVideoFiles(video.VideoId);

  return (
    <AdminOnlyWrapper>
      <Container>
        <Collapse triggerContent={<CollapseTitle>Debug</CollapseTitle>} iconAtEnd>
          <Segment header="Video Files">
            {!!error && <Error>{(error as AxiosError)?.message || 'Unknown error'}</Error>}
            {!!data &&
              data?.map((file) => (
                <Row
                  key={`${video.VideoId}|${file.Type}`}
                  onClick={() => {
                    window.open(file.URL, '_blank');
                  }}
                >
                  <TitleWrapper>{file.Type}</TitleWrapper>
                  <TypeWrapper>{new URL(file.URL).hostname}</TypeWrapper>
                </Row>
              ))}
          </Segment>
        </Collapse>
      </Container>
    </AdminOnlyWrapper>
  );
};
