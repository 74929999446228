import styled from 'styled-components';
import { NEUTRAL_7_COLOUR } from 'theme';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { BuilderCollection } from 'providers';
import { useTemplates } from 'hooks';
import { useMemo } from 'react';

const Flex = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${NEUTRAL_7_COLOUR};

  padding-bottom: 100px;
`;
const Head = styled.div`
  margin-top: 12px;
  ${FONT_16PX_MEDIUM};
`;
const Sub = styled.div`
  margin-top: 4px;
  ${FONT_14PX_REGULAR};
  padding-left: 100px;
  padding-right: 100px;
`;

export const LegacyView = ({ tab }: { tab?: BuilderCollection }) => {
  const { getTemplateById } = useTemplates();
  const name = useMemo(() => {
    if (tab?.TemplateId) {
      const template = getTemplateById(tab.TemplateId);
      if (template) {
        return template.DisplayName;
      }
    }
    return 'Unknown Tab';
  }, [getTemplateById, tab]);

  return (
    <Flex>
      <div>
        <Head>{name}</Head>
        <Sub>
          This is a legacy tab type. <br />
          It will appear here in the app.
        </Sub>
      </div>
    </Flex>
  );
};
