import { useQuery } from 'react-query';
import { VideoAnalytics, getVideoAnalytics, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useVideoAnalytics = (startDate: Date, endDate: Date) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery<VideoAnalytics>(
    ['videoAnalytics', appId, startDate, endDate],
    () => getVideoAnalytics(client, appId, startDate, endDate),
    {
      enabled: !!appId, // Don't call fetcher with empty AppId
    },
  );
};
