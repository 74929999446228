import { MutableRefObject, useMemo, useRef, useState, PointerEvent, MouseEvent, useEffect } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import styled, { css } from 'styled-components';

import {
  contentHasPreviewVideo,
  getCollectionName,
  getContentName,
  getContentOverlay,
  isSectionHeaderItem,
  usePointerOnClick,
} from 'utils';
import { APP_FONT_16PX_MEDIUM } from 'mockup-font';
import { NEUTRAL_1_COLOUR, NEUTRAL_3_COLOUR } from 'theme';
import { useContent } from 'providers';
import { OVERFLOW_ELLIPSIS } from 'font';

import { THUMBNAIL_RADIUS, UNTITLED_BLOCK_NAME } from 'app/modules/build-dragdrop/Builder/const';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';

import { ContentThumbnail } from '../../../components';
import { useAppCollectionContext } from '../../providers';
import { CollectionTitle } from './CollectionTitle';
import { useAppProperties } from 'hooks';
import { isItemClickable } from 'app/modules/build-dragdrop/Builder/util';

export const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const getTitleStyle = (overlay?: boolean) => {
  if (overlay) {
    return css`
      max-width: calc(100% - 92px);
      width: 100%;
      padding: 8px;
      ${APP_FONT_16PX_MEDIUM};
      color: ${NEUTRAL_1_COLOUR};
      ${OVERFLOW_ELLIPSIS};
    `;
  }
  return css`
    max-width: 100%;
    ${APP_FONT_16PX_MEDIUM};
    // Always space two lines of text
    min-height: 40px;
    max-height: 40px;
    margin-top: 6px;
    overflow: hidden;
  `;
};

/* Carousel Item */
export const ItemOverlay = styled.div`
  position: absolute;
  left: 8px;
  bottom: 0;

  width: 100%;
  max-width: calc(100% - 16px);
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: ${THUMBNAIL_RADIUS};
`;
export const ItemText = styled.div<{ overlay?: boolean; $color: string }>`
  margin-top: 6px;
  z-index: 5;

  color: ${({ $color }) => $color};
  // Manage differences between different template styles
  ${({ overlay }) => getTitleStyle(overlay)};
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }
`;
export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 16px);
  padding-left: 8px;
  padding-right: 8px;
  flex: 0 0 100%;
  height: fit-content;
  position: relative;
`;

const StyledThumbnail = styled(ContentThumbnail)`
  width: 100%;
  height: unset;
  cursor: ${usePointerOnClick};
`;
const DummyThumbnail = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background-color: ${NEUTRAL_3_COLOUR};
  border-radius: ${THUMBNAIL_RADIUS};
`;

/* Carousel Dots */
const CarouselDots = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
`;

interface CarouselDotProps {
  size?: string;
  colorScheme?: string;
  active?: boolean;
  secondaryItemHEX: string;
  primaryItemHEX: string;
}

export const DotClickableWrapper = styled.div`
  padding: 4px; // Makes it easier to click the dot
  cursor: pointer;
`;
const CarouselDot = styled.div<CarouselDotProps>`
  width: ${(props) => props.size ?? '7px'};
  height: ${(props) => props.size ?? '7px'};
  background-color: ${(props) => {
    if (props.active) {
      return props.primaryItemHEX;
    } else {
      return props.secondaryItemHEX;
    }
  }};
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  border-radius: 50%;
`;

export const ItemTitle = ({ overlay, color, text }: { overlay?: boolean; color: string; text?: string }) => {
  const titleProps = { $color: color, overlay };
  const titleText = text ?? 'Heading';

  return overlay ? (
    <ItemOverlay>
      <ItemText {...titleProps}>{titleText}</ItemText>
    </ItemOverlay>
  ) : (
    <ItemText {...titleProps}>{titleText}</ItemText>
  );
};
const DummyCarouselItem = ({ overlay, color }: { overlay?: boolean; color: string }) => {
  return (
    <CarouselItem>
      <DummyThumbnail id="DummyThumbnail" />
      <ItemTitle color={color} overlay={overlay} />
    </CarouselItem>
  );
};

export const CollectionCarousel = () => {
  const { getContentForItem } = useContent();
  const { collection, onItemClick, templateId } = useAppCollectionContext();
  const { getDesignProperty } = useAppTheme();
  const { data: appProperties } = useAppProperties();
  const primaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const secondaryItemHEX = getDesignProperty('secondaryItemHEX') as string;

  // Determines which carousel dot is highlighted/active
  const [selectedIdx, setSelectedIdx] = useState<number>(0);

  // Reset the carousel selection if the items are updated
  useEffect(() => {
    setSelectedIdx(0);
    scrollToIdx(0);
  }, [collection?.Items]);

  const sortedItems = useMemo(() => {
    return collection?.Items.filter((item) => !isSectionHeaderItem(item)).sort((a, b) => a.Position - b.Position);
  }, [collection, collection?.Items]);

  // Click to drag carousel
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref, { decayRate: 0.7 });

  // Allow dragging the carousel to be dropped outside the frame
  const handlePointerDown = (event: PointerEvent<HTMLDivElement>) => {
    // Detect when they let go of the drag-to-scroll outside of the current element
    (event.target as HTMLDivElement).setPointerCapture(event.pointerId);
  };

  // Stop phone from dragging while dragging the carousel
  const handleMouseDown = (event: MouseEvent<HTMLElement>) => {
    events.onMouseDown(event);
    event.stopPropagation();
  };

  // Scroll to a specific element in the carousel
  const scrollToIdx = (idx: number) => {
    ref.current.style.scrollBehavior = 'smooth';
    ref.current.children[idx].scrollIntoView({ block: 'center' });
    ref.current.style.scrollBehavior = 'unset';
  };

  // Scroll to nearest item when stopping the drag scroll
  const handlePointerUp = (event: PointerEvent<HTMLDivElement>) => {
    // They have stopped dragging to scroll
    (event.target as HTMLDivElement).releasePointerCapture(event.pointerId);
    setTimeout(() => {
      // Calculate based on the scroll position which is the closest element
      const numItems = sortedItems?.length ?? 3; // 3 dummy items
      const { scrollLeft, scrollWidth } = ref.current;
      const itemWidth = scrollWidth / numItems;
      const idx = Math.round(scrollLeft / itemWidth);
      scrollToIdx(idx);
      setSelectedIdx(idx);
    }, 200);
  };
  const handleDotClick = (position: number) => {
    const idx = position - 1;
    setSelectedIdx(idx);
    scrollToIdx(idx);
  };

  const showOverlayTitle = templateId === 27;
  const titleColor = showOverlayTitle ? 'white' : (getDesignProperty('primaryItemHEX') as string);

  return (
    <CarouselContainer>
      <CollectionTitle
        title={collection ? getCollectionName(collection) : UNTITLED_BLOCK_NAME}
        marginBottom="8px"
        padding="0 8px"
      />
      <CarouselWrapper
        ref={ref}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onMouseDown={handleMouseDown}
      >
        {sortedItems && sortedItems.length > 0
          ? sortedItems?.map((item, idx) => {
              const content = getContentForItem(item);
              const title = content && getContentName(content, item.Type);
              return content ? (
                <CarouselItem key={item.TabItemId}>
                  <StyledThumbnail
                    thumbnail={content}
                    size="medium"
                    onClick={onItemClick && isItemClickable(content, item) ? () => onItemClick(item) : undefined}
                    overlayText={getContentOverlay(content, appProperties)}
                    showPreviewTag={contentHasPreviewVideo(content)}
                  />
                  <ItemTitle color={titleColor} text={title} overlay={showOverlayTitle} />
                </CarouselItem>
              ) : (
                <DummyCarouselItem color={titleColor} overlay={showOverlayTitle} key={idx} />
              );
            })
          : [1, 2, 3].map((key) => <DummyCarouselItem key={key} color={titleColor} overlay={showOverlayTitle} />)}
      </CarouselWrapper>

      <CarouselDots>
        {sortedItems?.length
          ? sortedItems?.map((item) => {
              return (
                <DotClickableWrapper
                  onClick={() => handleDotClick(item.Position)}
                  key={`DotClickableWrapper_${item.TabItemId}_${item.Position}`}
                >
                  <CarouselDot
                    active={selectedIdx + 1 === item.Position}
                    primaryItemHEX={primaryItemHEX}
                    secondaryItemHEX={secondaryItemHEX}
                  />
                </DotClickableWrapper>
              );
            })
          : [1, 2, 3].map((key) => (
              <DotClickableWrapper onClick={() => handleDotClick(key)} key={`DummyClickable_${key}`}>
                {/*A single active dot to match the dummy item*/}
                <CarouselDot
                  active={selectedIdx + 1 === key}
                  primaryItemHEX={primaryItemHEX}
                  secondaryItemHEX={secondaryItemHEX}
                />
              </DotClickableWrapper>
            ))}
      </CarouselDots>
    </CarouselContainer>
  );
};
