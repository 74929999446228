import { endOfDay, startOfDay, subDays } from 'date-fns';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import { VideoAnalytics } from 'api';

import { useVideoAnalytics } from '../hooks/useVideoAnalytics';

interface ProviderProps {
  children: ReactNode;
}

interface ContextValue {
  videoAnalyticsData?: VideoAnalytics;
  videoAnalyticsIsError?: boolean;
  startDate: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;
  endDate: Date;
  setEndDate: Dispatch<SetStateAction<Date>>;
}

const VideoAnalyticsContext = createContext<ContextValue | undefined>(undefined);

const VideoAnalyticsProvider = ({ children }: ProviderProps) => {
  const today: Date = new Date();
  const [startDate, setStartDate] = useState(startOfDay(subDays(today, 7)));
  const [endDate, setEndDate] = useState(endOfDay(subDays(today, 1)));

  const { data: videoAnalyticsData, isError: videoAnalyticsIsError } = useVideoAnalytics(startDate, endDate);

  return (
    <VideoAnalyticsContext.Provider
      value={{
        videoAnalyticsData,
        videoAnalyticsIsError,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
      }}
    >
      {children}
    </VideoAnalyticsContext.Provider>
  );
};

const useVideoAnalyticsContext = () => {
  const context = useContext(VideoAnalyticsContext);
  if (context === undefined) {
    throw new Error('useVideoAnalyticsContext must be used with an VideoAnalyticsProvider');
  }
  return context;
};

export { useVideoAnalyticsContext, VideoAnalyticsProvider };
