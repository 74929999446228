import styled from 'styled-components';

import { StyledToggle, ToggleWrapper } from 'components';
import { FONT_14PX_REGULAR } from 'font';
import { NEUTRAL_8_COLOUR } from 'theme';

const ToggleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Toggletext = styled.div`
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_14PX_REGULAR};
`;

export interface ToggleProps {
  text: string;
  enabled: boolean;
  onChange: () => void;
}

export const Toggle = ({ text, enabled, onChange }: ToggleProps) => (
  <ToggleContainer>
    <Toggletext>{text}</Toggletext>
    <ToggleWrapper>
      <StyledToggle checked={enabled} onChange={onChange} data-testid="toggle-switch" />
    </ToggleWrapper>
  </ToggleContainer>
);
