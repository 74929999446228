import { useCallback } from 'react';
import styled from 'styled-components';

import { CustomButton } from 'components';
import { BuilderProduct, useLocalProducts, useSaveContext } from 'providers';
import { TrashIcon } from 'icons';
import { NEUTRAL_5_COLOUR } from 'theme';

const StyledButton = styled(CustomButton)`
  &&&& {
    height: 20px;
    padding: 2px;

    :hover {
      background-color: ${NEUTRAL_5_COLOUR};
    }
  }
`;

export const DeleteOfferButton = ({ productId }: { productId: BuilderProduct['Id'] }) => {
  const { setProductToDelete } = useSaveContext();
  const { setProducts } = useLocalProducts();

  const handleDeleteProduct = useCallback(() => {
    setProductToDelete(productId);

    setProducts((prev) => {
      const newProducts = new Map(prev);
      newProducts.delete(productId);
      return newProducts;
    });
  }, [productId, setProductToDelete, setProducts]);

  return <StyledButton small tertiary icon={<TrashIcon />} onClick={handleDeleteProduct} />;
};
