import styled, { css } from 'styled-components';

import { CustomColor, useCustomColor } from 'utils';
import { SearchIcon, AppFilterIcon, XIcon } from 'icons';
import { APP_FONT_13PX_MEDIUM, APP_FONT_13PX_REGULAR, APP_FONT_16PX_MEDIUM } from 'mockup-font';

import { useAppTheme } from '../../hooks';

const Wrapper = styled.div<CustomColor>`
  width: 100%;
  padding: 18px 15px;
  min-height: 100%;
  cursor: default;
  color: ${useCustomColor};
`;

const Controls = styled.div`
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: space-between;
`;

const controlItemCSS = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Search = styled.div<{ $backgroundColor: string } & CustomColor>`
  ${controlItemCSS};
  flex-grow: 1;
  margin-right: 15px;
  border-radius: 8px;
  ${APP_FONT_13PX_REGULAR};
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

const Filter = styled.div<{ $backgroundColor: string } & CustomColor>`
  ${controlItemCSS};
  width: 88px;
  border-radius: 6px;
  ${APP_FONT_13PX_MEDIUM};
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-color: ${useCustomColor};
  border-width: 1px;
  border-style: solid;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 165px 100px 0;
`;

const SmallSearchIcon = styled(SearchIcon)`
  font-size: 22px;
  margin: 0 6px 0 10px;
`;

const LargeSearchIcon = styled(SearchIcon)`
  font-size: 60px;
`;

const StyledXIcon = styled(XIcon)`
  font-size: 26px;
  margin-left: auto;
  margin-right: 7px;
`;

const StyledFilterIcon = styled(AppFilterIcon)`
  font-size: 22px;
  margin: 0 11px 0 8px;
`;

const Heading = styled.div`
  text-align: center;
  ${APP_FONT_16PX_MEDIUM}
  margin: 18px 0 7px;
`;

const Description = styled.div`
  text-align: center;
  ${APP_FONT_13PX_REGULAR}
`;

export const FilterView = () => {
  const { getDesignProperty } = useAppTheme();
  const PrimaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const SecondaryElevationHEX = getDesignProperty('secondaryElevationHEX') as string;
  const SearchInputHEX = getDesignProperty('searchInputHEX') as string;
  const FilterButtonBackgroundHEX = getDesignProperty('filterButtonBackgroundHEX') as string;

  return (
    <Wrapper $color={PrimaryItemHEX}>
      <Controls>
        <Search $color={SearchInputHEX} $backgroundColor={SecondaryElevationHEX}>
          <SmallSearchIcon />
          Search
          <StyledXIcon />
        </Search>
        <Filter $color={SearchInputHEX} $backgroundColor={FilterButtonBackgroundHEX}>
          <StyledFilterIcon />
          Filter
        </Filter>
      </Controls>
      <Content>
        <LargeSearchIcon />
        <Heading>Search</Heading>
        <Description>Start typing keywords to search or tap "FILTER"</Description>
      </Content>
    </Wrapper>
  );
};
