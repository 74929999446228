import { AxiosInstance } from 'axios';
import { getUserEmail } from 'utils';

export type Stage = 'Preview' | 'Live';

export const publishApp = (client: AxiosInstance, appId: string, stage: Stage) => {
  return client
    .post(`publish/${appId}`, {
      EmailToUpdate: getUserEmail(),
      Stage: stage,
    })
    .then(({ data }) => {
      console.log(`Publish to ${stage} successful`);
      console.log(data);
    })
    .catch((err) => {
      throw err;
    });
};

export interface Diff {
  Name: string;
  IsJson?: string;
  Before?: string;
  After?: string;
}

export interface PropertyDiff extends Diff {
  KeyDiffs?: Diff[];
}

export interface CollectionItemDiff {
  Position: number;
  FieldDiffs: Diff[];
}

export interface CollectionSummary {
  TabId: number;
  SourceId: number;
  DataSource: string;
  Name: string;
}

interface CollectionItemSummary {
  Position: number;
  ChildId?: number;
  DataSource: string;
  Type: string;
}

interface CollectionResourceDiff {
  ResourceId: number;
  FieldDiffs: Diff[];
}

interface CollectionResourceSummary {
  ResourceId: number;
  Title: string;
  Type: string;
}

export interface CollectionDiff {
  TabId: number;
  FieldDiffs: Diff[];
  ModifiedItems: CollectionItemDiff[];
  NewItems: CollectionItemSummary[];
  DeletedItems: CollectionItemSummary[];
  ModifiedResources: CollectionResourceDiff[];
  NewResources: CollectionResourceSummary[];
  DeletedResources: CollectionResourceSummary[];
}

export interface CollectionDiffResponse {
  NewCollections: CollectionSummary[];
  DeletedCollections: CollectionSummary[];
  ModifiedCollections: CollectionDiff[];
}

export interface PropertyDiffResponse {
  PropertyDifferences: PropertyDiff[];
}

export type ContentDiff = 'properties' | 'collections';
export const getPublishDiff = (client: AxiosInstance, appId: string, stage: Stage, content: ContentDiff) => {
  return getPublishDiffWithType(client, appId, stage, content).then((response) => response.data);
};

export const getPublishDiffWithType = (client: AxiosInstance, appId: string, stage: Stage, content: ContentDiff) => {
  if (content === 'properties') {
    return client.get<PropertyDiffResponse>(`/publish/${appId}/diff`, {
      params: { stage, content },
    });
  } else if (content === 'collections') {
    return client.get<CollectionDiffResponse>(`/publish/${appId}/diff`, {
      params: { stage, content },
    });
  }
  return client.get(`publish/${appId}/diff`, {
    params: { stage, content },
  });
};
