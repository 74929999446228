import { MouseEventHandler, useMemo } from 'react';
import styled from 'styled-components';

import { APP_FONT_12PX_MEDIUM, APP_FONT_18PX_MEDIUM } from 'mockup-font';
import { OVERFLOW_ELLIPSIS } from 'font';
import { useAppCollectionContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';

const StyledTitle = styled.h2<{ onClick?: MouseEventHandler; $color: string }>`
  ${APP_FONT_18PX_MEDIUM};
  ${OVERFLOW_ELLIPSIS};
  margin-bottom: 0 !important;
  color: ${({ $color }) => $color && `${$color} !important`};
  max-width: calc(100% - 50px); //Never overlap with View All
`;
const ViewAll = styled.div<{ onClick?: CollectionTitleProps['onClick']; $color: string }>`
  ${APP_FONT_12PX_MEDIUM};
  color: ${({ $color }) => $color && `${$color} !important`};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
`;
const TitleRow = styled.div<CustomStyles>`
  pointer-events: none;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom || '12px'};
  padding: ${({ padding }) => padding || '0px'};
  cursor: auto;
`;

interface CustomStyles {
  marginBottom?: string;
  padding?: string;
  color?: string;
}

interface CollectionTitleProps extends CustomStyles {
  title: string;
  hideViewAll?: boolean;
  onClick?: () => void;
}

export const CollectionTitle = ({ hideViewAll, title, onClick, ...props }: CollectionTitleProps) => {
  const { isDetailsView, collection } = useAppCollectionContext();
  const { getDesignProperty } = useAppTheme();

  const showViewAll = useMemo(() => {
    // Hide always hides
    // No items will show (for empty blocks)
    // At least 4 items will show
    return !hideViewAll && (collection?.Items.length ?? 4) > 3;
  }, [collection, hideViewAll]);

  // TODO - Implement the View All button onClick

  // On details view the title is in the navbar instead
  return !isDetailsView ? (
    <TitleRow {...props}>
      <StyledTitle $color={getDesignProperty('primaryItemHEX') as string} onClick={onClick}>
        {title}
      </StyledTitle>
      {/* onClick should be undefined if no id, so that the cursor is correct */}
      {showViewAll && <ViewAll $color={getDesignProperty('primaryItemHEX') as string}> View All</ViewAll>}
    </TitleRow>
  ) : null;
};
