import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

import { CustomButton, DragHandleDots } from 'components';
import { Filter } from 'api';
import {
  NEUTRAL_7_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_10_COLOUR,
  HIGHLIGHT_SECONDARY_COLOUR,
  NEUTRAL_4_COLOUR,
} from 'theme';
import { PenIcon } from 'icons';
import { FONT_10PX_REGULAR, FONT_14PX_REGULAR } from 'font';

const FilterRow = styled.div`
  &&&& {
    width: 100%;
    background-color: #fff;
    padding: 16px 8px 16px 16px;
    border: 1px solid ${NEUTRAL_5_COLOUR};
    border-radius: 8px;
    margin-bottom: 24px;
    position: relative;

    .edit-button {
      display: none;
    }

    :hover {
      .edit-button {
        display: flex;
      }
    }
  }
`;

const Header = styled.div`
  width: 100%;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
`;

const Name = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_REGULAR};
`;

const SourceLabel = styled.div`
  margin-left: 12px;
  color: ${NEUTRAL_7_COLOUR};
  font-size: 12px;
  line-height: 15px;
`;

const Placeholder = styled.span`
  color: ${NEUTRAL_7_COLOUR};
  font-style: italic;
`;

const EditButton = styled(CustomButton)`
  &&&& {
    margin-left: auto;
  }
`;

const FilterOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 74px;
`;

const FilterOption = styled.span`
  margin: 10px 10px 0 0;
  height: 18px;
  border-radius: 13px;
  padding: 0 8px;
  color: ${NEUTRAL_10_COLOUR};
  background-color: ${HIGHLIGHT_SECONDARY_COLOUR};
  ${FONT_10PX_REGULAR};
  display: flex;
  align-items: center;
`;

const DisabledFilterOption = styled(FilterOption)`
  background-color: ${NEUTRAL_4_COLOUR};
`;

const DragHandle = styled(DragHandleDots)<{ $isHovering?: boolean }>`
  &&&& {
    opacity: ${({ $isHovering }) => ($isHovering ? 1 : 0)};
    position: absolute;
    left: -26px;
    top: 12px;
    padding: 2px !important;
    height: 100%;
  }
`;

export interface FilterContainerProps {
  filter: Filter;
  idx: number;
  hasExternalSources: boolean;
  editFilter: (id: number) => void;
}

export const FilterContainer = ({ filter, idx, hasExternalSources, editFilter }: FilterContainerProps) => {
  const { Filter, FilterOptions, Id, Source } = filter;
  const [isHovering, setIsHovering] = useState(false);

  const handleEdit = useCallback(() => {
    editFilter(Id);
  }, [editFilter]);

  return (
    <Draggable draggableId={Id.toString()} index={idx}>
      {(provided, snapshot) => (
        <FilterRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          onMouseOver={() => {
            setIsHovering(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
        >
          <DragHandle
            $isHovering={isHovering || snapshot.isDragging}
            $color={NEUTRAL_7_COLOUR}
            {...provided.dragHandleProps}
          />
          <Header>
            <Name data-testid="filter-name">{Filter || <Placeholder>Category Title</Placeholder>}</Name>
            {hasExternalSources && <SourceLabel data-testid="external-sources">{Source}</SourceLabel>}
            <EditButton
              className="edit-button"
              tertiaryHighlight
              medium
              onClick={handleEdit}
              icon={<PenIcon />}
              data-testid="filter-edit-button"
            >
              Edit
            </EditButton>
          </Header>
          <FilterOptionsContainer>
            {FilterOptions.map((option) => {
              if (option.Option === '') {
                return;
              }
              return (
                <FilterOption key={option.Id} data-testid="filter-option">
                  {option.Option}
                </FilterOption>
              );
            })}
          </FilterOptionsContainer>
        </FilterRow>
      )}
    </Draggable>
  );
};

export const DisabledFilterContainer = ({ filter, hasExternalSources, editFilter }: FilterContainerProps) => {
  const { Filter, FilterOptions, Id, Source } = filter;

  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();
      editFilter(Id);
    },
    [editFilter],
  );

  return (
    <FilterRow>
      <Header>
        <Name data-testid="filter-name">{Filter || <Placeholder>Category Title</Placeholder>}</Name>
        {hasExternalSources && <SourceLabel data-testid="external-sources">{Source}</SourceLabel>}
        <EditButton
          className="edit-button"
          tertiaryHighlight
          medium
          onClick={handleEdit}
          icon={<PenIcon />}
          data-testid="filter-edit-button"
        >
          Edit
        </EditButton>
      </Header>
      <FilterOptionsContainer>
        {FilterOptions.map((option) => {
          if (option.Option === '') {
            return;
          }
          return <DisabledFilterOption key={option.Id}>{option.Option}</DisabledFilterOption>;
        })}
      </FilterOptionsContainer>
    </FilterRow>
  );
};
