import { AddAccess, addAccount, CreateAccount, useAxiosInstance } from '../../../../../api';
import { useMutation, useQueryClient } from 'react-query';
import { useAppBeingEdited } from 'app-context';
import { AxiosError } from 'axios';

export const useAddAccount = () => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError, AddAccess | CreateAccount>(
    async (payload) => {
      await addAccount(client, appId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['app_accounts', appId]);
      },
    },
  );
};
