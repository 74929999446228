import { useEffect, useState } from 'react';

import { ContentResources, Segment } from 'components';
import { BuilderVideo, useContent, useSaveContext } from 'providers';
import { ITEM_TYPE_VIDEO, Resource } from 'api';

export const VideoResources = ({ video }: { video: BuilderVideo }) => {
  const { setVideoValue } = useContent();
  const { setVideoValueToSave } = useSaveContext();
  const [resources, setResources] = useState<Resource[]>(video.Resources ?? []);

  useEffect(() => {
    setVideoValue(video.VideoId, 'Resources', resources);
    setVideoValueToSave(video.VideoId, 'Resources', resources);
  }, [resources]);

  return (
    <Segment $marginBottom="8px">
      <ContentResources
        isInDrawer
        existingResources={resources}
        onAddResource={(resource) => setResources((existing) => [...existing, resource])}
        onRemoveResource={(resourceId) =>
          setResources((existing) => [...existing].filter((r) => r.ResourceId !== resourceId))
        }
        onTitleChange={(title, resourceId) =>
          setResources((existing) =>
            [...existing].map((r) => (r.ResourceId === resourceId ? { ...r, Title: title } : r)),
          )
        }
        parentType={ITEM_TYPE_VIDEO}
        parentId={video.VideoId.toString()}
      />
    </Segment>
  );
};
