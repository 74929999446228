import { useCallback, useRef, useState } from 'react';
import { Form } from 'antd';
import styled, { css } from 'styled-components';

import { FileBlankIcon, PlusIcon, UploadIcon } from 'icons';
import { CustomButton, FileUploader, SettingsAlternateThumbnailInput, SettingsThumbnailInput } from 'components';
import { DEFAULT_MAX_FILE_SIZE } from 'utils';
import { DANGER_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { FONT_10PX_REGULAR } from 'font';

import { getFileTitle, getFileType } from 'app/modules/build-dragdrop/Builder/util';

import {
  BlueButton,
  ScrollView,
  ViewCheckboxInput,
  ViewContainer,
  ViewHeaderRow,
  ViewHeading,
  ViewSection,
  ViewSectionHeading,
} from './ViewComponents';

import { FileBlock as ViewFile } from 'app/modules/build-dragdrop/Builder/drawer/components';

const { useForm } = Form;

const UploadButton = styled(CustomButton)`
  #react-app && {
    width: 100%;
  }
`;
const HiddenFileUploader = styled(FileUploader)`
  display: none;
`;
const SizeError = styled.div`
  ${FONT_10PX_REGULAR};
  color: ${DANGER_COLOUR};
  line-height: 18px;
  margin-top: 6px;
  text-align: center;
`;

const ICON_STYLE = css`
  color: ${NEUTRAL_7_COLOUR};
  font-size: 12px;
  margin-right: 2px;
`;

const StyledFileBlankIcon = styled(FileBlankIcon)`
  #react-app && {
    ${ICON_STYLE};
  }
`;

const StyledUploadIcon = styled(UploadIcon)`
  #react-app && {
    ${ICON_STYLE};
  }
`;

const TitleHeading = styled(ViewHeading)`
  margin-bottom: 13px;
`;

const Requirements = styled.div`
  color: ${NEUTRAL_7_COLOUR};
  ${FONT_10PX_REGULAR};
  margin-top: 8px;
`;

export interface VidAppFile {
  title?: string;
  type?: string;
  thumbnail?: string;
  squareThumbnail?: string;
  portraitThumbnail?: string;
  hideFromSearch?: boolean;
  filename?: string;
  isError?: boolean;
}

interface FileProps {
  onCreate: (file: VidAppFile) => void;
}

const validateForm = (file: VidAppFile): boolean => {
  if (file.isError) {
    return false;
  }
  return true;
};

export const FileView = ({ onCreate }: FileProps) => {
  const [form] = useForm();
  const [file, setFile] = useState<VidAppFile>({ thumbnail: '', squareThumbnail: '', portraitThumbnail: '' });
  const [isFileSizeError, setIsFileSizeError] = useState(false);
  const overrideRef = useRef<HTMLInputElement>(null);

  const handleClear = () => {
    form.resetFields();
    setFile({});
  };

  const handleUploadClick = useCallback(() => {
    overrideRef.current?.click(); //Forward click to the file input
  }, [overrideRef]);

  const handleFileSelected = useCallback(
    (file: File) => {
      setIsFileSizeError(false);
      // Set a pending state
      setFile({
        title: getFileTitle(file.name),
        filename: 'Uploading ...',
        type: getFileType(file.name),
      });
    },
    [setIsFileSizeError, setFile],
  );
  const handleFileUploaded = (filename: string) => {
    setFile((f) => ({ ...f, filename }));
  };

  const handleError = useCallback(() => {
    setFile((f) => ({ ...f, isError: true }));
  }, [setFile]);

  return (
    <ViewContainer>
      <Form form={form} style={{ height: 'inherit' }}>
        <ViewHeaderRow>
          <ViewHeading>Add File</ViewHeading>
          <BlueButton
            medium
            tertiary
            icon={<PlusIcon />}
            disabled={!file?.filename || file?.isError === true}
            onClick={() => {
              if (validateForm(file)) {
                onCreate(file);
                handleClear(); // Clear form after adding to the collection
              }
            }}
          >
            Add
          </BlueButton>
        </ViewHeaderRow>
        <ScrollView>
          <ViewSection>
            {file?.filename ? (
              <>
                <TitleHeading>Title</TitleHeading>
                <ViewFile
                  id="File"
                  icon={file.filename === 'Uploading ...' ? <StyledUploadIcon /> : <StyledFileBlankIcon />}
                  url={file.filename}
                  type={file.type}
                  title={file.title}
                  onTitleChange={(title) => setFile((f) => ({ ...f, title }))}
                  onDelete={() => setFile({ filename: '' })}
                  isError={file.isError}
                />
              </>
            ) : (
              <>
                <UploadButton
                  secondary
                  small
                  icon={<UploadIcon />}
                  onClick={handleUploadClick}
                  disabled={file.filename === 'Uploading ...'}
                >
                  Upload a file
                </UploadButton>
                <Requirements>Max file size of 25MB. PDF, PNG, JPEG, JPG</Requirements>
              </>
            )}
            {isFileSizeError && (
              <SizeError>{`The file you are trying to upload exceeds our ${DEFAULT_MAX_FILE_SIZE}MB limit.`}</SizeError>
            )}
            <HiddenFileUploader
              overrideRef={overrideRef}
              onFileSelected={handleFileSelected}
              onFilenameChange={handleFileUploaded}
              useOriginalFilename
              acceptedFileTypes="application/pdf, image/png, image/jpeg"
              handleError={handleError}
              handleMaxSizeExceeded={() => setIsFileSizeError(true)}
            />
          </ViewSection>
          <ViewSection>
            <SettingsThumbnailInput
              $marginTop="0px"
              filename={file.thumbnail}
              isRevertable={false}
              handleThumbnailChange={(filename) => {
                setFile((f) => {
                  return { ...f, thumbnail: filename };
                });
              }}
              width="172px"
              height="96px"
              uploaderProps={{
                maxDimensions: { maxWidth: 1920, maxHeight: 1080 },
                acceptedFileTypes: 'image/png, image/jpeg',
                scaleSpinner: 80,
              }}
            />
            <SettingsAlternateThumbnailInput
              squareFilename={file?.squareThumbnail}
              portraitFilename={file?.portraitThumbnail}
              handleSquareThumbnailChange={(filename) => {
                setFile((f) => {
                  return { ...f, squareThumbnail: filename };
                });
              }}
              handlePortraitThumbnailChange={(filename) => {
                setFile((f) => {
                  return { ...f, portraitThumbnail: filename };
                });
              }}
              squareWidth="123px"
              portraitWidth="123px"
              portraitHeight="184px"
              orientation="column"
            />
          </ViewSection>
          <ViewSection>
            <ViewSectionHeading>Other</ViewSectionHeading>
            <ViewCheckboxInput
              name="hideFromSearch"
              label="Exclude from search results in the app"
              defaultChecked={file.hideFromSearch}
              onChange={(e) => {
                setFile((f) => {
                  return { ...f, hideFromSearch: e.target.checked };
                });
              }}
            />
          </ViewSection>
        </ScrollView>
      </Form>
    </ViewContainer>
  );
};
