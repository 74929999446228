import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { banCommunityMember, useAxiosInstance } from 'api';
import { useRecordAction } from 'hooks';

export const useBanCommunityMember = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, { communityId: string; userId: string }>(
    ({ communityId, userId }) => {
      return banCommunityMember(client, appId, communityId, userId);
    },
    {
      onSuccess: async (_response, { communityId, userId }) => {
        // Invalidate all queries for community users
        await queryClient.invalidateQueries(['community', 'members']);
        recordAction.mutate({ action: 'Ban Community Member', detail: { UserId: userId, CommunityId: communityId } });
      },
    },
  );
};
