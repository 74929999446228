import { useCallback, useEffect, useMemo, useState } from 'react';
import { customAlphabet } from 'nanoid';
import { AxiosResponse } from 'axios';

import { SaveFailed } from 'components';
import { useUnsavedChanges } from 'providers';
import {
  COLLECTION_TYPE_COLLECTION,
  Filter,
  FilterToSave,
  SOURCE_TYPE_COLLECTION,
  SOURCE_VIDAPP,
  TaggedItemToSave,
} from 'api';
import {
  useCollections,
  useFilters,
  useSaveFilter,
  useSaveTaggedItems,
  useTaggedCollections,
  useTaggedVideos,
  useVideos,
} from 'hooks';

import { ContentItem, ContentItemChild } from '../ContentTagging';

const nanoid = customAlphabet('123456789', 16);

export interface ItemToSave {
  type: 'collection' | 'video';
  filters: Record<string, string[]>;
}

export const useContentTagging = () => {
  const {
    data: filtersData,
    isError: filtersIsError,
    isLoading: filtersIsLoading,
    getFilterByFilterOptionId,
    getFilterOptionById,
    getFilterOptionIdByName,
  } = useFilters(Infinity, 'always');
  const {
    data: collectionsData,
    isError: collectionsIsError,
    isLoading: collectionsIsLoading,
    getCollectionsByDataSource,
    getCollectionById,
    isCollection,
  } = useCollections(Infinity, 'always');
  const {
    data: taggedCollections,
    isError: taggedCollectionsIsError,
    isLoading: taggedCollectionsIsLoading,
    getFilterOptionsByCollectionId,
  } = useTaggedCollections(Infinity);
  const {
    data: taggedVideos,
    isError: taggedVideosIsError,
    isLoading: taggedVideosIsLoading,
    getFilterOptionsByVideoId,
  } = useTaggedVideos(Infinity);
  const {
    data: videosData,
    isError: videosIsError,
    isLoading: videoIsLoading,
    getVideoById,
  } = useVideos(Infinity, 'always');
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const saveFilter = useSaveFilter();
  const saveTaggedItems = useSaveTaggedItems();

  const [isInitialized, setIsInitialized] = useState(false);
  const [itemsToSave, setItemsToSave] = useState<Record<number, ItemToSave>>({});
  const [filtersToSave, setFiltersToSave] = useState<Record<string, Filter>>({});
  const [isSaving, setIsSaving] = useState(false);

  const filters = useMemo(
    () => filtersData?.filters.sort((a: Filter, b: Filter) => a.Position - b.Position),
    [filtersData],
  );

  useEffect(() => {
    if (!unsavedChanges && !isSaving && Object.entries(itemsToSave).length > 0) {
      setUnsavedChanges(true);
    }
  }, [itemsToSave, unsavedChanges, isSaving, setUnsavedChanges]);

  const products = useMemo(
    () => collectionsData?.collections.filter((collection) => collection.SourceType === 'Product'),
    [collectionsData],
  );

  const collections = useMemo(
    () =>
      getCollectionsByDataSource(SOURCE_VIDAPP)
        .filter(
          (collection) =>
            collection.Type === COLLECTION_TYPE_COLLECTION && collection.SourceType === SOURCE_TYPE_COLLECTION,
        )
        .sort((a, b) => a.Name.localeCompare(b.Name)),
    [getCollectionsByDataSource],
  );

  const videos = useMemo(() => videosData?.videos.sort((a, b) => a.Title.localeCompare(b.Title)), [videosData]);

  const getTags = useCallback(
    (itemId: number, type: 'collection' | 'video') => {
      const filterOptionCells: Record<string, string[]> = {};

      const filterOptionIds =
        type === 'collection' ? getFilterOptionsByCollectionId(itemId) : getFilterOptionsByVideoId(itemId);

      filterOptionIds.forEach((id) => {
        const filter = getFilterByFilterOptionId(id);
        const filterOption = getFilterOptionById(id);
        if (filter && filterOption) {
          const arr = filterOptionCells[filter.Filter] ?? [];
          arr.push(filterOption.Option);
          filterOptionCells[filter.Filter] = arr;
        }
      });

      return filterOptionCells;
    },
    [getFilterByFilterOptionId, getFilterOptionById, getFilterOptionsByCollectionId, getFilterOptionsByVideoId],
  );

  const getChildren = useCallback(
    (children: ContentItemChild[]) => {
      const childArr: ContentItem[] = [];
      children.forEach(({ ChildId, Type }) => {
        const item = getCollectionById(ChildId) ?? getVideoById(ChildId);
        if (item) {
          if (isCollection(item)) {
            childArr.push({
              Name: item.Name,
              Type: Type,
              Id: item.TabId,
              SourceId: item.SourceId,
              Tags: getTags(item.TabId, 'collection'),
              key: nanoid(),
            });
          } else {
            childArr.push({
              Name: item.Title,
              Type: 'lesson',
              Id: item.VideoId,
              SourceId: item.SourceId,
              Tags: getTags(item.VideoId, 'video'),
              key: nanoid(),
            });
          }
        }
      });
      return childArr;
    },
    [getCollectionById, getVideoById, getTags],
  );

  const reset = useCallback(() => {
    setIsSaving(false);
    setItemsToSave({});
    setFiltersToSave({});
    setIsInitialized(false);
    setUnsavedChanges(false);
  }, [setIsSaving, setUnsavedChanges, setItemsToSave, setFiltersToSave, setIsInitialized]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    const updatedFilters: Record<string, Filter> = {};

    const saveFilterRequests: Promise<AxiosResponse<FilterToSave>>[] = [];

    Object.values(filtersToSave).forEach(async (filter) => {
      const filterToSave: FilterToSave = { ...filter, FilterOptions: [...filter.FilterOptions] };
      filterToSave.FilterOptions.forEach((option) => {
        if (option.Id === 0) {
          delete option.Id;
        }
      });
      saveFilterRequests.push(saveFilter.mutateAsync(filterToSave)); // Save any new FilterOptions
    });

    const saveFilterResponses = await Promise.all(saveFilterRequests).catch((error) => {
      SaveFailed(error);
      setIsSaving(false);
      setUnsavedChanges(true);
    });

    if (typeof saveFilterResponses !== 'undefined') {
      saveFilterResponses.forEach(({ data }) => {
        updatedFilters[data.Filter] = data as Filter; // saveFilter response will contain updated Filter, so store this in updatedFilters to access IDs of newly created FilterOptions
      });

      const taggedItemsToSave: TaggedItemToSave[] = [];

      for (const [key, itemToSave] of Object.entries(itemsToSave)) {
        const filterOptionIds: Set<number> = new Set();

        for (const [key, tags] of Object.entries(itemToSave.filters)) {
          tags.forEach((tag) => {
            const filterOptionId =
              // If getFilterOptionIdByName returns null, this is a new FilterOption, so get the new ID from updatedFilters
              getFilterOptionIdByName(key, tag) ??
              updatedFilters[key].FilterOptions.find((option) => option.Option === tag)?.Id;
            if (filterOptionId) {
              filterOptionIds.add(filterOptionId);
            }
          });
        }

        taggedItemsToSave.push({
          ItemId: parseInt(key),
          ItemType: itemToSave.type,
          FilterOptionIds: Array.from(filterOptionIds),
        });
      }

      const requests: Promise<unknown>[] = [];

      requests.push(saveTaggedItems.mutateAsync(taggedItemsToSave));

      const responses = await Promise.all(requests).catch((error) => {
        SaveFailed(error);
        setIsSaving(false);
        setUnsavedChanges(true);
      });

      if (typeof responses !== 'undefined') {
        // Save Successful
        reset();
      }
    }
  }, [itemsToSave, saveTaggedItems, getFilterOptionIdByName, reset, setIsSaving, setUnsavedChanges]);

  return {
    filters,
    products,
    collections,
    videos,
    isDataLoaded:
      filtersData &&
      !filtersIsLoading &&
      collectionsData &&
      !collectionsIsLoading &&
      videosData &&
      !videoIsLoading &&
      taggedCollections &&
      !taggedCollectionsIsLoading &&
      taggedVideos &&
      !taggedVideosIsLoading,
    isError: filtersIsError || collectionsIsError || videosIsError || taggedCollectionsIsError || taggedVideosIsError,
    isInitialized,
    unsavedChanges,
    isSaving,
    filtersToSave,
    getTags,
    getChildren,
    setIsInitialized,
    handleSave,
    setItemsToSave,
    setFiltersToSave,
    reset,
  };
};
