import { useEffect } from 'react';

import { Segment } from 'components';
import { SOURCE_VHX, VIDEO_STATUS_FREE, VIDEO_STATUS_LOCKED } from 'api';
import { BuilderVideo, useContent, useSaveContext } from 'providers';
import { ViewRadioInput } from 'app/modules/build-dragdrop/Builder/customise-items/components';

const accessOptions = {
  options: [
    { name: 'Free for everyone', value: '0' },
    { name: 'Requires access', value: '-1' },
  ],
  default: '-1',
};

export const VideoPurchasePrice = ({
  VideoId,
  ProductId,
  DataSource,
}: Pick<BuilderVideo, 'VideoId' | 'ProductId' | 'DataSource'>) => {
  const { setVideoValue } = useContent();
  const { setVideoValueToSave } = useSaveContext();

  const handleChange = (value: string) => {
    setVideoValue(VideoId, 'ProductId', value);
    setVideoValueToSave(VideoId, 'ProductId', value);
  };

  useEffect(() => {
    // Unexpected ProductId will be automatically set to locked
    if (![VIDEO_STATUS_LOCKED, VIDEO_STATUS_FREE].includes(ProductId || '')) {
      handleChange(VIDEO_STATUS_LOCKED);
    }
  }, []);

  return (
    <Segment header="Content Access">
      <ViewRadioInput
        value={ProductId}
        defaultVal={accessOptions.default}
        options={accessOptions.options}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        disabled={DataSource === SOURCE_VHX}
      />
    </Segment>
  );
};
