import styled from 'styled-components';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR } from 'theme';

const Wrapper = styled.div<{ $height?: string }>`
  height: ${({ $height }) => $height || '188px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const Desc = styled.div<{ $width?: string }>`
  width: ${({ $width }) => $width || '145px'};
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
`;
const Title = styled(Desc)`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
  margin-bottom: 4px;
`;

export const Empty = ({
  title,
  description,
  $height,
  ...props
}: {
  title?: string;
  description?: string;
  $height?: string;
  $width?: string;
}) => (
  <Wrapper $height={$height}>
    <Title {...props}>{title ?? 'No Content'}</Title>
    <Desc {...props}>{description ?? 'No data available.'}</Desc>
  </Wrapper>
);
