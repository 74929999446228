import { useEffect, useCallback, useRef, CSSProperties, ChangeEvent, SetStateAction, Dispatch } from 'react';
import styled from 'styled-components';
import { Drawer, InputRef } from 'antd';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { NAV_BAR_OFFSET, NEUTRAL_5_COLOUR, DANGER_COLOUR } from 'theme';
import {
  DrawerHeading,
  DrawerSection,
  DragHandleDots,
  RemovableInput,
  Toggle,
  CustomButton,
  SettingsTextInput,
} from 'components';
import { Filter, FilterOption } from 'api';
import { PlusIcon, TrashIcon } from 'icons';
import { BUILDER_DRAWER_WIDTH } from 'app/modules/build-dragdrop/Builder/const';

const DrawerContent = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 28px;

  display: flex;
  flex-direction: column;
`;

const DeleteFilterButton = styled(CustomButton)`
  #react-app && {
    margin-top: auto;
  }
`;

const RemovableInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ErrorMessage = styled.div`
  min-height: 12px;
  padding: 4px;
  font-size: 12px;
  line-height: 14px;
  color: ${DANGER_COLOUR};
`;

interface FiltersDrawerProps {
  title: string;
  visible: boolean;
  enabled: boolean;
  externalSource: boolean;
  toggleFilterEnabled: (id: number) => void;
  activeFilterId: number;
  activeFilter?: Filter;
  filters: Filter[] | undefined;
  setFilters: Dispatch<SetStateAction<Filter[] | undefined>>;
  closeDrawer: () => void;
  deleteFilter: (id: number) => void;
  addOption: (id: number) => void;
  updateOptionPositions: (filter: Filter) => Filter;
  isValidating: boolean;
}

export const FiltersDrawer = ({
  title,
  visible,
  enabled,
  externalSource,
  toggleFilterEnabled,
  activeFilterId,
  activeFilter,
  filters,
  setFilters,
  closeDrawer,
  deleteFilter,
  addOption,
  updateOptionPositions,
  isValidating,
}: FiltersDrawerProps) => {
  const nameInput = useRef<InputRef>(null);
  const optionInput = useRef<InputRef>(null);

  const focusOnNameInput = useCallback(() => {
    nameInput.current?.focus();
  }, [nameInput]);

  useEffect(() => {
    focusOnNameInput();
  }, [visible]);

  const handleDeleteFilter = useCallback(() => {
    deleteFilter(activeFilterId);
  }, [deleteFilter]);

  const drawerStyles: CSSProperties = {
    position: 'fixed',
    top: NAV_BAR_OFFSET,
    height: `calc(100% - ${NAV_BAR_OFFSET})`,
    zIndex: 120,
    transform: 'none',
  };

  const drawerBodyStyles: CSSProperties = {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `1px solid ${NEUTRAL_5_COLOUR}`,
  };

  const handleAddOption = useCallback(async () => {
    await addOption(activeFilterId);
    optionInput.current?.focus();
  }, [addOption]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;
      if (!destination || destination.index === source.index) {
        return;
      }

      if (activeFilter?.FilterOptions) {
        const updatedOptions = [...activeFilter.FilterOptions];
        const draggedOption = updatedOptions.find((option: FilterOption) => option.Id.toString() === draggableId);
        updatedOptions.splice(source.index, 1);
        if (draggedOption) {
          updatedOptions.splice(destination.index, 0, draggedOption);
        }
        const updatedFilters = filters?.map((filter: Filter) => {
          if (filter.Id === activeFilterId) {
            return updateOptionPositions({ ...filter, FilterOptions: updatedOptions });
          }
          return filter;
        });
        setFilters(updatedFilters);
      }
    },
    [setFilters, filters, activeFilter],
  );

  const handleFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const updatedFilters = filters?.map((filter: Filter) => {
        if (filter.Id === activeFilterId) {
          return { ...filter, Filter: e.target.value };
        }
        return filter;
      });
      setFilters(updatedFilters);
    },
    [filters, setFilters, activeFilterId],
  );

  const handleOptionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const updatedFilters = filters?.map((filter: Filter) => {
        if (filter.Id === activeFilterId) {
          const updatedOptions = filter.FilterOptions.map((filterOption: FilterOption) => {
            if (filterOption.Id.toString() === e.target.id) {
              return { ...filterOption, Option: e.target.value };
            }
            return filterOption;
          });
          return { ...filter, FilterOptions: updatedOptions };
        }
        return filter;
      });
      setFilters(updatedFilters);
    },
    [filters, setFilters, activeFilterId],
  );

  const handleOptionRemove = useCallback(
    (id: number) => {
      const updatedFilters = filters?.map((filter: Filter) => {
        if (filter.Id === activeFilterId) {
          const optionIndex = filter.FilterOptions.findIndex((filterOption: FilterOption) => filterOption.Id == id);
          filter.FilterOptions.splice(optionIndex, 1);
          return updateOptionPositions(filter);
        }
        return filter;
      });
      setFilters(updatedFilters);
      nameInput.current?.focus();
    },
    [filters, setFilters, activeFilterId, updateOptionPositions],
  );

  const handleEnabledToggle = useCallback(() => {
    toggleFilterEnabled(activeFilterId);
  }, [toggleFilterEnabled]);

  const filterNameBorderStyle =
    isValidating && activeFilter?.Filter.length === 0 ? '1px solid #ff4242' : `1px solid ${NEUTRAL_5_COLOUR}`;

  return (
    <Drawer
      placement="right"
      closable
      mask={false}
      width={BUILDER_DRAWER_WIDTH}
      destroyOnClose
      open={visible}
      key="right"
      onClose={closeDrawer}
      getContainer={false}
      style={drawerStyles}
      bodyStyle={drawerBodyStyles}
      data-testid="filters-drawer"
    >
      <DrawerHeading title={title}></DrawerHeading>
      <DrawerContent>
        <DrawerSection heading="Title" subheading="This title will appear in the Filter view.">
          <SettingsTextInput
            size="middle"
            value={activeFilter?.Filter}
            onChange={handleFilterChange}
            disabled={externalSource}
            style={{ border: filterNameBorderStyle }}
          />
          <ErrorMessage>
            {isValidating && activeFilter?.Filter.length === 0 && 'You have unconfirmed changes'}
          </ErrorMessage>
        </DrawerSection>
        <DrawerSection heading="Tags" $marginBottom="88px">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="optionsDroppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {activeFilter?.FilterOptions?.map((option: FilterOption, idx: number) => {
                    if (!option.Id) {
                      return null;
                    }
                    return (
                      <Draggable draggableId={option.Id.toString()} index={idx} key={option.Id}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <RemovableInputWrapper>
                              {!externalSource && (
                                <DragHandleDots $size="16px" $padding="0" {...provided.dragHandleProps} />
                              )}
                              <RemovableInput
                                id={option.Id}
                                value={option.Option}
                                onChange={handleOptionChange}
                                handleRemove={handleOptionRemove}
                                ref={optionInput}
                                error={isValidating && option.Option.length === 0}
                                disabled={externalSource}
                              />
                            </RemovableInputWrapper>
                            <ErrorMessage>
                              {isValidating && option.Option.length === 0 && 'You have unconfirmed changes'}
                            </ErrorMessage>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!externalSource && (
            <CustomButton tertiaryHighlight medium onClick={handleAddOption} icon={<PlusIcon />}>
              Add New Tag
            </CustomButton>
          )}
        </DrawerSection>
        <DrawerSection heading="Enable / Disable Category">
          <Toggle
            text="Enabling / disabling this category will toggle it’s visibility in your app’s Filter view."
            enabled={enabled}
            onChange={handleEnabledToggle}
          />
        </DrawerSection>
        {!externalSource && (
          <DeleteFilterButton secondary danger medium onClick={handleDeleteFilter} icon={<TrashIcon />}>
            Delete Category
          </DeleteFilterButton>
        )}
      </DrawerContent>
    </Drawer>
  );
};
