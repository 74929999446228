import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { HIGHLIGHT_PRIMARY_COLOUR } from 'theme';

interface SpinnerProps {
  fontSize?: string;
}

export const LoadingSpinner = ({ fontSize }: SpinnerProps) => (
  <Spin indicator={<LoadingOutlined style={{ fontSize: fontSize ?? 30, color: HIGHLIGHT_PRIMARY_COLOUR }} spin />} />
);
