import { useCallback, useEffect, useState } from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';

import { OldRightArrowIcon } from 'icons';
import { NEUTRAL_9_COLOUR } from 'theme';
import { useAppProperties, useSaveAppProperties } from 'hooks';
import { BUILDER_IMAGES_DOMAIN } from 'utils';

import { BackButton, Footer, NextButton, SplitPanel, StepPanel } from './index';
import { FONT_12PX_MEDIUM, FONT_14PX_SEMIBOLD } from '../font';
import { COURSE_DD_NO_THUMBNAILS, COURSE_DD_THUMBNAILS } from 'api';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

const RightFooter = styled(Footer)`
  justify-content: space-between;
`;
const RightBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 40%;
  height: 100%;
  align-items: center;
`;
const StyledImage = styled.img`
  height: 360px;
  object-fit: contain;
`;
const StyledRadio = styled(Radio)`
  #react-app && {
    margin-top: 20px;
  }

  #react-app && span {
    ${FONT_14PX_SEMIBOLD};
  }
`;
const Caption = styled.div`
  margin-top: 8px;
  text-align: center;

  color: ${NEUTRAL_9_COLOUR};
  ${FONT_12PX_MEDIUM};
`;

type DisplayType = 'Thumbnails' | 'NoThumbnails';
const Underline = styled.span`
  text-decoration: underline;
  font-weight: inherit;
`;

export const DisplayTypeStep = () => {
  const [type, setType] = useState<DisplayType | undefined>();
  const saveAppProperties = useSaveAppProperties();
  const { data: appProperties } = useAppProperties();
  const { navigateForward } = useWizardNavigation();

  // Pull in initial value if set previously
  useEffect(() => {
    if (!type && appProperties?.CourseTemplateDesign) {
      setType(appProperties.CourseTemplateDesign as DisplayType);
    }
  }, [appProperties]);

  const handleChange = useCallback(
    (e) => {
      setType(e.target.value);
    },
    [setType],
  );

  const mode = appProperties?.ColorScheme.toLowerCase() ?? 'light';

  const handleContinue = useCallback(async () => {
    if (type) {
      await saveAppProperties.mutateAsync([{ Name: 'CourseTemplateDesign', Value: type }]);
      navigateForward();
    }
  }, [appProperties, navigateForward, type]);

  return (
    <SplitPanel
      leftContent={
        <StepPanel
          header="Select how your Kajabi Courses will display."
          body={
            <>
              Do all your posts have thumbnails?
              <br />
              Select the display option that works best for your courses.
            </>
          }
          icon={<img src={`${BUILDER_IMAGES_DOMAIN}/onboarding/wizard_icons/phone.webp`} alt="user icon" />}
        />
      }
      rightContent={
        <>
          <Radio.Group value={type} onChange={handleChange}>
            <RightBody>
              <Column>
                <StyledImage src={`${BUILDER_IMAGES_DOMAIN}/onboarding/${mode}_thumbnails.webp`} />
                <StyledRadio value={COURSE_DD_THUMBNAILS}>Thumbnails</StyledRadio>
                <Caption>
                  Select this if the majority of your Kajabi posts <Underline>have</Underline> thumbnails
                </Caption>
              </Column>
              <Column>
                <StyledImage src={`${BUILDER_IMAGES_DOMAIN}/onboarding/${mode}_no_thumbnails.webp`} />
                <StyledRadio value={COURSE_DD_NO_THUMBNAILS}>Icons</StyledRadio>
                <Caption>
                  Select this if the majority of your Kajabi posts <Underline>{"don't"}</Underline> have thumbnails.
                </Caption>
              </Column>
            </RightBody>
          </Radio.Group>
          <RightFooter>
            <BackButton />
            <NextButton
              disabled={!type}
              onClick={handleContinue}
              loading={saveAppProperties.isLoading}
              icon={type && <OldRightArrowIcon />}
            >
              Continue
            </NextButton>
          </RightFooter>
        </>
      }
    />
  );
};
