import { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import { Input, InputRef } from 'antd';
import styled from 'styled-components';

import { INPUT_HEIGHT_MIDDLE, NEUTRAL_5_COLOUR } from 'theme';
import { CustomButton } from 'components/Buttons/CustomButton/CustomButton';
import { TrashIcon } from 'icons';
import { FONT_14PX_REGULAR } from 'font';

const InputContainer = styled.div`
  width: 100%;
  height: ${INPUT_HEIGHT_MIDDLE};
  display: flex;
  align-items: center;
`;

const InputField = styled(Input)`
  #react-app && {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    ${FONT_14PX_REGULAR};
    margin-left: 4px;

    &[disabled] {
      color: #767676;
    }
  }
`;

export interface RemovableInputProps {
  id: number;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleRemove: (id: number) => void;
  error: boolean;
  disabled?: boolean;
}

export const RemovableInput = forwardRef(
  ({ id, value, onChange, handleRemove, error, disabled }: RemovableInputProps, ref: ForwardedRef<InputRef>) => {
    const borderStyle = error ? '1px solid #ff4242' : `1px solid ${NEUTRAL_5_COLOUR}`;

    return (
      <InputContainer data-testid="input-container">
        <InputField
          id={id.toString()}
          value={value}
          onChange={onChange}
          style={{ border: borderStyle }}
          ref={ref}
          disabled={disabled}
          data-testid="removable-input-field"
        />
        {!disabled && (
          <CustomButton
            tertiary
            danger
            medium
            icon={<TrashIcon />}
            onClick={() => handleRemove(id)}
            data-testid="removable-input-button"
          />
        )}
      </InputContainer>
    );
  },
);

RemovableInput.displayName = 'RemovableInput';
