import { useCallback } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

import { PRIMARY_ORANGE_COLOUR, STYLING_ID } from 'theme';
import { FONT_16PX_SEMIBOLD } from 'app/modules/self-onboarding/OnboardingWizard/font';

const Field = styled.div`
  width: 100%;
  height: 52px;
  margin-bottom: 24px;
  padding-left: 24px;
  line-height: 52px;
  border-radius: 8px;
  background-color: #eeeeee; //TODO Replace

  position: relative;
`;

const StyledCopy = styled.div`
  position: absolute;
  top: 14px;
  right: 16px;
  cursor: pointer;
  ${FONT_16PX_SEMIBOLD};
  color: ${PRIMARY_ORANGE_COLOUR};
`;

interface FieldProps {
  children: string;
}

export const CopyField = ({ children }: FieldProps) => {
  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(children);
  }, [children]);

  return (
    <Field>
      {children}
      <Tooltip
        title="Copied"
        trigger="click"
        placement="right"
        getPopupContainer={() => document.getElementById(STYLING_ID) || document.body}
        arrowPointAtCenter
      >
        <StyledCopy onClick={handleClick}>Copy</StyledCopy>
      </Tooltip>
    </Field>
  );
};
