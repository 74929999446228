import { AxiosInstance } from 'axios';
import { getUserEmail } from 'utils';

export const login = async (client: AxiosInstance, email: string, password: string) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);

  const response = await client.post('auth/token', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    withCredentials: true,
  });

  if (!response || !response?.data) {
    return;
  }

  console.log('Successfully logged in');
  const { AppId, Email, Name, access_token } = response.data || {};
  localStorage.setItem('user_email', Email);
  localStorage.setItem('user_name', Name);
  localStorage.setItem('appBeingEdited', AppId);
  localStorage.setItem('jwt', access_token);
  location.reload();
};

export const authenticate = async (client: AxiosInstance, email: string, password: string) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);

  const response = await client
    .post('auth/token', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
    })
    .catch((e) => {
      if (e.response.status == 401) {
        throw new Error('Incorrect password, please try again');
      } else if (e.response.status == 429) {
        throw new Error(e.response.data?.detail || 'Too many login attempts, try again in 10 minutes.');
      }
      throw e;
    });
  return response && response.data;
};

export const updatePassword = (client: AxiosInstance, newPassword: string) => {
  return client.post('auth/reset-password', { Email: getUserEmail(), Password: newPassword });
};

export const logout = async (client: AxiosInstance) => {
  await client.post('auth/logout', {}, { withCredentials: true });
  localStorage.removeItem('user_email');
  localStorage.removeItem('user_name');
  localStorage.removeItem('appBeingEdited');
  localStorage.removeItem('jwt');
  window.location.href = window.location.origin;
  const loginPanel = document.getElementById('login-panel');
  const reactPage = document.getElementById('reactPage');
  if (reactPage) {
    reactPage.style.display = 'none';
  }
  if (loginPanel) {
    loginPanel.style.display = 'block';
  }
};
