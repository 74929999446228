import { SettingsFileInput } from 'components';
import { useAppBeingEdited } from 'app-context';

import { Filename } from '../../hooks/useAdminSettings';

interface FontInputProps {
  property: string;
  filenames: Record<string, Filename>;
  onFilenameChange: (key: string, _filename: string) => void;
  heading: string;
}

export const FontInput = ({ property, filenames, onFilenameChange, ...props }: FontInputProps) => {
  const appId = useAppBeingEdited();

  return (
    <SettingsFileInput
      filename={filenames[property]?.value}
      useOriginalFilename
      filePath={`${appId}/fonts/`}
      acceptedFileTypes="font/ttf, font/otf"
      onFilenameChange={(filename) => onFilenameChange(property, filename)}
      {...props}
    />
  );
};
