import { Dispatch, ReactNode, SetStateAction, createContext, useContext } from 'react';

import { AppBasicInfo, StringAppBasicInfo } from 'api';

type SetBasicInfoValue = (name: keyof StringAppBasicInfo, value: string) => void;

interface ContextValue {
  basicInfo: AppBasicInfo;
  setBasicInfo: Dispatch<SetStateAction<AppBasicInfo>>;
  setBasicInfoValue: SetBasicInfoValue;
}

interface ProviderProps {
  children: ReactNode | ReactNode[];
  basicInfo: ContextValue['basicInfo'];
  setBasicInfo: ContextValue['setBasicInfo'];
}

const defaultFunction = () => {
  console.warn('Unexpected function call local-app-basic-info-provider');
};

const LocalAppBasicInfoContext = createContext<ContextValue>({
  basicInfo: {} as AppBasicInfo,
  setBasicInfo: defaultFunction,
  setBasicInfoValue: defaultFunction,
});

const LocalAppBasicInfoProvider = ({ children, basicInfo, setBasicInfo }: ProviderProps) => {
  const setBasicInfoValue: SetBasicInfoValue = (name, value) => {
    setBasicInfo((prev) => {
      const newObj: AppBasicInfo = { ...prev };
      newObj[name] = value; // Currently only supports updating app basic info of string type
      return newObj;
    });
  };

  return (
    <LocalAppBasicInfoContext.Provider value={{ basicInfo, setBasicInfo, setBasicInfoValue }}>
      {children}
    </LocalAppBasicInfoContext.Provider>
  );
};

const useLocalAppBasicInfo = () => {
  const context = useContext(LocalAppBasicInfoContext);
  if (context === undefined) {
    throw new Error('useLocalAppBasicInfo must be used within a LocalAppBasicInfoProvider');
  }
  return context;
};

export { LocalAppBasicInfoProvider, useLocalAppBasicInfo };
