import { CSSProperties, ImgHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import { ThumbnailSize, ThumbnailType } from 'api';
import { useAppBeingEdited } from 'app-context';
import { DEFAULT_THUMBNAIL, getContentThumbnailUrl, ThumbnailUrlProps } from 'utils';
import { APP_FONT_12PX_MEDIUM } from 'mockup-font';
import { PreviewVideoIcon } from 'icons';
import { FONT_12PX_REGULAR } from 'font';

import { THUMBNAIL_RADIUS } from 'app/modules/build-dragdrop/Builder/const';
import { useAppTheme } from '../mockup/hooks';

interface ContentThumbnailProps extends ImgHTMLAttributes<unknown> {
  thumbnail: ThumbnailUrlProps;
  size?: ThumbnailSize;
  type?: ThumbnailType;

  overlayText?: string;
  overlayBottom?: string; // optional override for adjusting vertical position of overlay text
  overlayRight?: string; // optional override for adjusting the horizontal position ..
  showPreviewTag?: boolean;

  overrideRadius?: string;

  // Add a border around the default image if it is used
  // Useful when using the thumbnail on a white background
  defaultBorder?: CSSProperties['border'];
}

const Container = styled.div`
  position: relative;
  height: 40px;
  width: 71px;
`;

interface StyledImageProps {
  onClick?: ContentThumbnailProps['onClick'];
  $overrideBorder?: CSSProperties['border'];
  $overrideRadius?: string;
  $type?: ThumbnailType;
}

const getThumbnailRatio = (type?: ThumbnailType) => {
  if (type === 'square') {
    return 1;
  } else if (type === 'portrait') {
    return 2 / 3;
  }
  return 16 / 9;
};

const StyledImage = styled.img<StyledImageProps>`
  width: inherit;
  height: inherit;
  aspect-ratio: ${({ $type }) => getThumbnailRatio($type)};
  object-fit: cover;
  border-radius: ${({ $overrideRadius }) => ($overrideRadius ? $overrideRadius : THUMBNAIL_RADIUS)};
  border: ${({ $overrideBorder }) => ($overrideBorder ? `${$overrideBorder} !important` : undefined)};
  cursor: ${({ onClick }) => onClick && 'pointer'};
`;

const PreviewTag = styled.div<{ $backgroundColor: string }>`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 85px;
  height: 25px;
  ${FONT_12PX_REGULAR};
  color: #fff;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPreviewVideoIcon = styled(PreviewVideoIcon)`
  &&&& {
    font-size: 14px;
    margin-left: 8px;
  }
`;

const TextOverlay = styled.div<{ $bottom?: string; $right?: string }>`
  position: absolute;
  right: ${({ $right }) => $right || '8px'};
  bottom: ${({ $bottom }) => $bottom || '8px'};

  ${APP_FONT_12PX_MEDIUM};
  color: white;
  background-color: black;

  opacity: 0.75;
  padding: 2px 6px;
  border-radius: 3px;
  z-index: 8;
  pointer-events: none;
`;

export const ContentThumbnail = ({
  thumbnail,
  size = 'medium',
  type = 'landscape',
  defaultBorder,
  overlayText,
  overlayBottom,
  overlayRight,
  overrideRadius,
  showPreviewTag,
  ...props
}: ContentThumbnailProps) => {
  const [isError, setIsError] = useState(false);
  const appId = useAppBeingEdited();
  const url = getContentThumbnailUrl(appId, thumbnail, size, type);
  const { getDesignProperty } = useAppTheme();

  return (
    <Container {...props}>
      <StyledImage
        src={isError ? DEFAULT_THUMBNAIL : url}
        loading="lazy"
        $overrideBorder={isError || url === DEFAULT_THUMBNAIL ? defaultBorder : undefined}
        $overrideRadius={overrideRadius}
        $type={type}
        onError={() => setIsError(true)}
      />
      {showPreviewTag && (
        <PreviewTag $backgroundColor={getDesignProperty('highlightHEX') as string}>
          PREVIEW <StyledPreviewVideoIcon />
        </PreviewTag>
      )}
      {overlayText && (
        <TextOverlay $bottom={overlayBottom} $right={overlayRight}>
          {overlayText}
        </TextOverlay>
      )}
    </Container>
  );
};
