import styled from 'styled-components';
import { FONT_12PX_SEMIBOLD, FONT_18PX_SEMIBOLD } from 'font';
import { ReactNode } from 'react';
import { NEUTRAL_7_COLOUR } from 'theme';

export const Heading = styled.h2<{ hideGutter?: boolean }>`
  &&&& {
    ${FONT_18PX_SEMIBOLD};
    color: inherit;
    padding-bottom: 0;
    margin-bottom: ${({ hideGutter }) => (hideGutter ? '0px !important' : '7px')};
  }
`;

const HeaderRow = styled.div`
  padding: 52px 28px 16px 28px;
`;

const TypeTextWrapper = styled.div`
  height: 18px;
  margin-bottom: 8px;
`;

const TypeText = styled.div`
  color: ${NEUTRAL_7_COLOUR};
  ${FONT_12PX_SEMIBOLD};
`;

interface DrawerHeadingProps {
  heading?: string;
  type?: string | ReactNode;
  children?: ReactNode | ReactNode[];
}

export const DrawerHeading = ({ heading, type, children }: DrawerHeadingProps) => {
  return (
    <HeaderRow>
      <TypeTextWrapper>{type && <TypeText>{type}</TypeText>}</TypeTextWrapper>
      <Heading hideGutter={!children}>{heading}</Heading>
      {children}
    </HeaderRow>
  );
};
