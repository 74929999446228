import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { deleteVideo, useAxiosInstance, Video } from 'api';
import { useRecordAction } from 'hooks';

export const useDeleteVideo = (invalidateQuery = true, refresh = true) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, number>(
    (videoId: Video['VideoId']) => {
      return deleteVideo(client, appId, videoId, refresh);
    },
    {
      onSuccess: async (_response, videoId) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['videos', appId]);
        }
        recordAction.mutate({ action: 'Delete Video', detail: { Video: videoId } });
      },
    },
  );
};
