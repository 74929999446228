import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { BuilderCollection, BuilderCollectionItem, useContent, useDataSource, useSaveContext } from 'providers';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR } from 'theme';
import { useAppProperties, useTemplates } from 'hooks';

import { SOURCE_KAJABI, Template } from 'api';
import { FONT_14PX_MEDIUM } from 'font';

import { DrawerNavHeading, DrawerScrollContainer } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { getExcludedTemplates, getFormattedTemplateName, groupTemplates } from '../../../util';
import { DisplayPreview } from 'app/modules/build-dragdrop/Builder/components';
import { STANDALONE_PROPERTY } from 'app/modules/build-dragdrop/Builder/const';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup';

const TemplateGroupWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const TemplateGroupTitle = styled.div`
  width: 100%;
  ${FONT_14PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
  margin-bottom: 12px;
`;

const StyledDisplayPreview = styled(DisplayPreview)`
  width: calc(50% - 6px);
  cursor: pointer;
`;

const getTemplateDisplayName = (t: Template) => {
  const nameObj = getFormattedTemplateName(t);
  if (nameObj.variant) {
    return `${nameObj.name} (${nameObj.variant})`;
  }
  return nameObj.name;
};

interface CollectionTemplateDrawerProps {
  collection?: BuilderCollection;
  item?: BuilderCollectionItem;
}

export const CollectionTemplateDrawer = ({ collection, item }: CollectionTemplateDrawerProps) => {
  const { getTemplateOptionsByType } = useTemplates();
  const { setSectionTemplate, setCollectionTemplate } = useContent();
  const { setCollectionItemsToSave, setCollectionValueToSave } = useSaveContext();
  const datasource = useDataSource();
  const { data } = useAppProperties();
  const { drawerContext } = useBuilderContext();
  const { currentPage } = useMockupContext();
  const groupedTemplates = useMemo(() => {
    const templates = getTemplateOptionsByType(item ? 'block' : 'collection');
    const isSubtabDrawer = !drawerContext?.drawerType && !currentPage?.itemId;

    if (templates) {
      return groupTemplates(
        templates,
        getExcludedTemplates({
          standalone: data?.[STANDALONE_PROPERTY] === '1',
          allowCourseView: (data?.RolloutCourseView === '1' || datasource === SOURCE_KAJABI) && !isSubtabDrawer,
        }),
      );
    }

    return [];
  }, [getTemplateOptionsByType]);

  const selectedTemplate = item?.TemplateId || collection?.TemplateId;

  const handleTemplateClick = useCallback(
    (templateId: number) => {
      if (item) {
        setSectionTemplate(item, templateId);
        setCollectionItemsToSave(item.TabId);
      } else if (collection) {
        setCollectionTemplate(collection, templateId);
        setCollectionValueToSave(collection.TabId, 'TemplateId', templateId);
      }
    },
    [setSectionTemplate, setCollectionItemsToSave, setCollectionTemplate, setCollectionValueToSave, item, collection],
  );

  return (
    <>
      <DrawerNavHeading heading="Display" />
      <DrawerScrollContainer>
        {groupedTemplates.map(([groupName, templates]) => {
          if (templates.length === 0) {
            // Don't render title of empty groups of templates
            return null;
          }
          return (
            <>
              <TemplateGroupTitle>{groupName}</TemplateGroupTitle>
              <TemplateGroupWrapper key={groupName}>
                {templates.map((t) => {
                  return (
                    <StyledDisplayPreview
                      key={t.TemplateId}
                      name={getTemplateDisplayName(t)}
                      template={t}
                      isPage={!item}
                      onClick={() => {
                        handleTemplateClick(t.TemplateId);
                      }}
                      style={{
                        outline:
                          selectedTemplate === t.TemplateId
                            ? `${HIGHLIGHT_PRIMARY_COLOUR} 1px solid`
                            : 'transparent 1px solid',
                        borderColor: selectedTemplate === t.TemplateId ? HIGHLIGHT_PRIMARY_COLOUR : NEUTRAL_5_COLOUR,
                      }}
                    />
                  );
                })}
              </TemplateGroupWrapper>
            </>
          );
        })}
      </DrawerScrollContainer>
    </>
  );
};
