import styled, { keyframes } from 'styled-components';
import { NEUTRAL_7_COLOUR } from 'theme';

const bounce = keyframes`
 0% { transform: scale(0)}
 40% { transform: scale(1)}
 80% { transform: scale(0)}
 100% { transform: scale(0)}
`;

const Wrapper = styled.div`
  width: fit-content;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${NEUTRAL_7_COLOUR};
  margin-right: 2px;

  border-radius: 100%;
  display: inline-block;
  animation: ${bounce} 1.2s infinite ease-in-out both;
`;

const Dot1 = styled(Dot)`
  &&& {
    animation-delay: -0.32s;
  }
`;

const Dot2 = styled(Dot)`
  &&& {
    animation-delay: -0.16s;
  }
`;

export const LoadingDots = () => (
  <Wrapper>
    <Dot1 />
    <Dot2 />
    <Dot />
  </Wrapper>
);
