import { useCallback } from 'react';

import { useSaveContext, useLocalProducts, BuilderProduct } from 'providers';
import { SettingsSelectInput, SettingsSegment } from 'components';

const options = [
  {
    name: 'Subscription',
    value: 'subscription',
  },
  {
    name: 'Buy to own',
    value: 'purchase',
  },
  {
    name: 'Email capture (free)',
    value: 'email-capture',
  },
];

export const PurchaseType = ({ Id, ProductType }: Pick<BuilderProduct, 'Id' | 'ProductType'>) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();

  const handleSelectChange = useCallback(
    (value: string) => {
      setProductValue(Id, 'ProductType', value);
      setProductValueToSave(Id, 'ProductType', value);
    },
    [setProductValue, setProductValueToSave, Id],
  );

  return (
    <SettingsSegment title="Purchase Type">
      <SettingsSelectInput options={options} defaultVal={ProductType} onChange={handleSelectChange} />
    </SettingsSegment>
  );
};
