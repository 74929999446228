import styled, { css } from 'styled-components';
import { FONT_16PX_MEDIUM } from 'font';
import {
  HIGHLIGHT_PRIMARY_ORANGE_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_7_COLOUR,
  PRIMARY_ORANGE_COLOUR,
} from 'theme';

// Separated out to a new file to avoid import looping between TreeVideo/TreeCollection
export const TreeRowContent = styled.div<{ $disabled?: boolean; $selected?: boolean; $hover: boolean }>`
  width: calc(100% - 16px);
  height: 100%;
  display: flex;
  padding: 6px 8px 6px 0;
  border-radius: 6px;

  position: relative;
  justify-content: flex-start;
  align-items: center;
  ${FONT_16PX_MEDIUM};

  ${({ $disabled, $selected, $hover }) => {
    if ($disabled) {
      return css`
        color: ${NEUTRAL_7_COLOUR};
        background-color: ${$hover ? NEUTRAL_3_COLOUR : 'none'};
        cursor: not-allowed;
      `;
    }
    if ($selected) {
      return css`
        color: ${PRIMARY_ORANGE_COLOUR};
        background-color: ${HIGHLIGHT_PRIMARY_ORANGE_COLOUR};
        cursor: pointer;
      `;
    }
    return css`
      color: ${NEUTRAL_10_COLOUR};
      background-color: ${$hover ? NEUTRAL_3_COLOUR : 'none'};
      cursor: pointer;
    `;
  }};
`;
