import styled from 'styled-components';
import { Form } from 'antd';

import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR } from 'theme';
import { FONT_10PX_REGULAR, FONT_12PX_REGULAR, FONT_14PX_SEMIBOLD } from 'font';
import { CustomButton, SettingsCheckboxInput, SettingsRadioInput, SettingsTextInput } from 'components';

export const ViewContainer = styled.div`
  padding-top: 25px;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;
export const ViewHeading = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
`;
export const ViewHeaderRow = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;
export const ScrollView = styled.div`
  overflow-y: auto;
  max-height: calc(100% - 55px); // 55px= Top padding + Heading
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 20px 0 20px;
`;

export const BlueButton = styled(CustomButton)<{ disabled: boolean }>`
  &&&& {
    color: ${({ disabled }) => !disabled && HIGHLIGHT_PRIMARY_COLOUR};
  }
`;

export const ViewSection = styled.div`
  :not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const ViewSectionHeading = styled(ViewHeading)`
  margin-bottom: 12px;
`;

export const ViewTextInput = styled(SettingsTextInput)`
  &&.ant-input-affix-wrapper {
    height: 32px !important;
  }

  &&&&.ant-input {
    ${FONT_12PX_REGULAR};
  }
`;

export const ViewRadioInput = styled(SettingsRadioInput)`
  #react-app && {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    margin: 0;

    label {
      margin-bottom: 10px;

      span {
        color: ${NEUTRAL_10_COLOUR};
      }
    }
  }
`;

export const ViewCheckboxInput = styled(SettingsCheckboxInput)`
  #react-app &&&&.ant-checkbox-wrapper {
    margin-top: 0;
    margin-bottom: 0;

    .ant-checkbox-inner {
      border-width: 2px;
      border-radius: 3px;
    }
  }
`;

export const ViewFormItem = styled(Form.Item)`
  &&&& {
    margin-bottom: 0px;

    .ant-form-item-explain {
      margin-top: 8px;
      ${FONT_10PX_REGULAR};
    }
  }
`;
