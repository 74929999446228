import { AxiosInstance } from 'axios';

export interface AccountDetails {
  FirstName: string;
  LastName: string;
  UserId: number;
  Email: string;
  StripeId: string;
}

export interface AccountUpdate {
  FirstName?: string;
  LastName?: string;
  Email?: string;
  StripeId?: string;
}

export interface AccountInfo {
  UserId: number;
  Name: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Role: string;
}

export interface Accounts {
  Accounts: AccountInfo[];
}

export interface CreateAccount {
  Email: string;
  FirstName: string;
  LastName: string;
}

export interface AddAccess {
  Email: string;
}

export interface AccountExists {
  FirstName: string;
  LastName: string;
}

export const getAccountDetails = (client: AxiosInstance) => {
  return client.get<AccountDetails>(`account/details`).then(({ data }) => data);
};

export const putAccountDetails = (client: AxiosInstance, payload: AccountUpdate) => {
  return client.put('account/details', payload);
};

export interface StripeCustomer {
  StripeId?: string;
  Last4Digits?: string;
}

export const getStripeCustomer = (client: AxiosInstance, email: string) => {
  return client.get<StripeCustomer>(`misc/stripe?email=${email}`).then(({ data }) => data);
};

export const getAppAccounts = (client: AxiosInstance, appId: string) => {
  return client.get<Accounts>(`account?app_id=${appId}`).then(({ data }) => data);
};

export const deleteAccount = (client: AxiosInstance, appId: string, userId: number) => {
  return client.delete(`account/${userId}/${appId}`);
};

export const addAccount = (client: AxiosInstance, appId: string, payload: CreateAccount | AddAccess) => {
  return client.post(`account/${appId}/create`, payload);
};

export const getAccountExists = (client: AxiosInstance, email: string) => {
  return client.get<AccountExists | null>(`account/exists?email=${email}`).then(({ data }) => data);
};
