import { ReactNode } from 'react';

import { ErrorPage } from 'components';
import { useFeatureFlags } from './hooks/useFeatureFlags';

export const FeatureFlagged = ({ children }: { children: ReactNode }) => {
  const { hasAccess, isLoading } = useFeatureFlags();

  if (isLoading) {
    return null;
  }

  if (!hasAccess()) {
    return <ErrorPage code={403} />;
  }

  return <>{children}</>;
};
