import styled from 'styled-components';

import { PRIMARY_ORANGE_COLOUR, VIDAPP_GRADIENT } from 'theme';

const RADIUS = '50%';

const Circle = styled.div`
  height: 24px;
  width: 24px;
  min-width: 24px;

  border-radius: ${RADIUS};
  background: ${VIDAPP_GRADIENT};
  margin-top: 24px;

  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

const InnerCircle = styled.div`
  transform: scale(0.8);
  border-radius: ${RADIUS};
  background: white;

  color: ${PRIMARY_ORANGE_COLOUR};
  font-weight: 700;
`;

interface DotProps {
  children: string;
  outlined?: boolean;
}

export const Dot = ({ children, outlined, ...props }: DotProps) => {
  return <Circle {...props}>{outlined ? <InnerCircle>{children}</InnerCircle> : children}</Circle>;
};
