import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { Checkbox } from 'antd';

import { getKajabiProducts, SECURE_APP_PROPERTIES, useAxiosInstance, VIDAPP_HELP_URL } from 'api';
import { useSecureAppProperties } from 'hooks';
import { DANGER_COLOUR, PRIMARY_ORANGE_COLOUR, SUCCESS_COLOUR } from 'theme';
import { useAppBeingEdited } from 'app-context';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { CheckIcon } from 'icons';

import { BackButton, Footer, NeedHelp, SplitPanel, StepPanel, ValidateCredentials } from '../index';
import { FONT_12PX_MEDIUM, FONT_12PX_SEMIBOLD } from '../../font';
import { useValidate } from '../../hooks';
import { generateSecurePassword, getButtonText, getButtonType } from '../../util';
import {
  BlackLink,
  FlexRow,
  InstructionText,
  OrangeLink,
  StyledLink,
  ValidateButton,
  WrappedDot,
  Wrapper,
} from './Components';
import { useVidAppProperties } from 'hooks/useVidAppProperties';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

export const ErrorText = styled.div`
  position: absolute;
  top: 60px;
  left: 0;

  width: 100%;
  height: 36px;
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;

  color: ${DANGER_COLOUR};
  ${FONT_12PX_SEMIBOLD};
`;

const RoadblockText = styled.div`
  color: ${SUCCESS_COLOUR};
  ${FONT_12PX_MEDIUM};
  width: 100%;
  text-align: center;
`;

const ConfirmationBox = styled(Checkbox)`
  .ant-checkbox + span {
    line-height: 0;
  }
`;

const ConfirmationText = styled.span`
  ${FONT_12PX_MEDIUM};

  a {
    ${FONT_12PX_MEDIUM};
  }
`;

const RightFooter = styled(Footer)`
  justify-content: space-between;
`;

export interface Credentials {
  name: string;
  email: string;
  password: string;
}

const getErrorContent = (errorCount: number, status?: number) => {
  if (status === 401) {
    if (errorCount < 3) {
      return (
        <>
          Validation failed. Please double-check you have used the <br />
          Name, Email and Password provided below.
        </>
      );
    }
    return (
      <>
        Could not validate the assistant account. Please{' '}
        <StyledLink rel="noreferrer" target="_blank" href={VIDAPP_HELP_URL}>
          book in a call
        </StyledLink>
        <br />
        with the VidApp Support Team.
      </>
    );
  }
  return null;
};
export const KajabiValidateStep = () => {
  const [confirmed, setConfirmed] = useState(false);
  const [errorCount, setErrorCount] = useState(0);

  const validateAccount = useValidate();
  const axiosInstance = useAxiosInstance();
  const appBeingEdited = useAppBeingEdited();
  const queryClient = useQueryClient();
  const { data: properties } = useSecureAppProperties();
  const { data: vproperties } = useVidAppProperties();
  const { navigateForward } = useWizardNavigation();

  const isKajabiBlock = vproperties?.Properties.KajabiWizardBlock === '1';
  const isBlockTriggered = validateAccount.isError && isKajabiBlock;

  const credentials: Credentials = useMemo(() => {
    // Use existing assistant values if available, otherwise generate them
    const { KajabiAssistantEmail, KajabiAssistantPassword } = SECURE_APP_PROPERTIES;
    const email = properties?.[KajabiAssistantEmail] || `${appBeingEdited}@vidapp.com`;
    const password = properties?.[KajabiAssistantPassword] || generateSecurePassword();
    return {
      name: 'VidApp',
      email,
      password,
    };
  }, [properties]);

  const handleValidate = useCallback(() => {
    validateAccount.mutate(
      { Username: credentials.email, Password: credentials.password },
      {
        onSuccess: async () => {
          const prefetch = queryClient.prefetchQuery(['kajabi_products', appBeingEdited], () =>
            getKajabiProducts(axiosInstance, appBeingEdited),
          );
          setTimeout(async () => {
            // After a delay move to the next page,
            await prefetch;
            navigateForward();
          }, 2000);
        },
        onError: (err) => {
          if (err.response?.status === 401) {
            setErrorCount((count) => count + 1);
          }
        },
      },
    );
  }, [validateAccount, credentials, appBeingEdited, navigateForward, setErrorCount]);

  return (
    <SplitPanel
      leftContent={
        <>
          <StepPanel
            header={<>Add a new Assistant Account to your Kajabi.</>}
            icon={<img src={`${BUILDER_IMAGES_DOMAIN}/onboarding/wizard_icons/user.webp`} alt="user icon" />}
          >
            <Wrapper>
              <FlexRow>
                <WrappedDot>1</WrappedDot>
                <InstructionText>
                  Go to{' '}
                  <OrangeLink target="_blank" rel="noreferrer" href="https://app.kajabi.com/admin/settings/users">
                    {'Kajabi > Settings > Account Users'}
                  </OrangeLink>
                </InstructionText>
              </FlexRow>
              <FlexRow>
                <WrappedDot>2</WrappedDot>
                <InstructionText>
                  Add a new assistant with the Name, Email, and Password from the right 👉🏾
                </InstructionText>
              </FlexRow>
              <FlexRow>
                <WrappedDot>3</WrappedDot>
                <InstructionText>{"Once you've done this, click validate"}</InstructionText>
              </FlexRow>
            </Wrapper>
          </StepPanel>
          <Footer>
            <NeedHelp
              $color={PRIMARY_ORANGE_COLOUR}
              overrideText="Kajabi Help Article - How to Add Account Users"
              overrideUrl="https://help.kajabi.com/hc/en-us/articles/360036862774-How-to-Add-Account-Users"
            />
          </Footer>
        </>
      }
      rightContent={
        <>
          {validateAccount.error && !isBlockTriggered && (
            <ErrorText>
              {getErrorContent(errorCount, validateAccount.error?.response?.status) ?? (
                <>
                  Hmm something went wrong and loading timed out. <br /> Try again or{' '}
                  <BlackLink target="_blank" rel="noreferrer" href={VIDAPP_HELP_URL}>
                    Get in Touch.
                  </BlackLink>
                </>
              )}
            </ErrorText>
          )}
          <ValidateCredentials credentials={credentials} />
          <ConfirmationBox checked={confirmed} onClick={() => setConfirmed(!confirmed)}>
            <ConfirmationText>
              I acknowledge that VidApp will use this assistant account to connect my branded apps to my Kajabi Courses,
              Offers and People. Read more{' '}
              <BlackLink target="_blank" rel="noreferrer" href="https://vidapp.com/terms-and-conditions">
                here
              </BlackLink>
              .
            </ConfirmationText>
          </ConfirmationBox>
          {isBlockTriggered ? (
            <Footer>
              <RoadblockText>
                Thanks for starting your app building journey. Our team will be in touch shortly once we’ve validated
                your account.
              </RoadblockText>
            </Footer>
          ) : (
            <RightFooter>
              <BackButton />
              <ValidateButton
                onClick={handleValidate}
                loading={validateAccount.isLoading}
                disabled={!confirmed || validateAccount.isSuccess}
                buttonType={getButtonType(validateAccount.status)}
                icon={validateAccount.isSuccess && <CheckIcon />}
              >
                {getButtonText(validateAccount.status)}
              </ValidateButton>
            </RightFooter>
          )}
        </>
      }
    />
  );
};
