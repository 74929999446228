import styled from 'styled-components';
import { Radio } from 'antd';
import { INPUT_HEIGHT_LARGE, NEUTRAL_6_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from '../../theme';

export interface CustomRadioButtonsGroupProps {
  height?: string;
  centered?: boolean;
}

export const CustomRadioGroup = styled(Radio.Group)`
  #react-app && {
    width: 100%;
    height: ${INPUT_HEIGHT_LARGE};
    color: ${NEUTRAL_9_COLOUR};
    display: flex;
    align-items: center;

    .ant-radio-inner {
      border-width: 2px;
    }

    .ant-radio:not(.ant-radio-checked, .ant-radio-disabled) {
      .ant-radio-inner {
        border-color: ${NEUTRAL_7_COLOUR};
      }
    }

    .ant-radio-disabled .ant-radio-inner {
      border-color: ${NEUTRAL_6_COLOUR};
      :hover {
        border-color: ${NEUTRAL_6_COLOUR};
      }
    }
  }
`;

export const CustomRadioButtonsGroup = styled(CustomRadioGroup)<CustomRadioButtonsGroupProps>`
  #react-app && {
    height: ${(props) => props.height || 'auto'};
    display: flex;
    justify-content: ${(props) => (props.centered ? 'center' : 'space-between')};
  }

  #react-app && .ant-radio-button-wrapper {
    height: 100%;
    border-radius: 8px;
    border-width: 2px;
    background-color: #f7f8f9;
  }

  #react-app && .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    border-color: #f7f8f9;
  }

  #react-app && .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  #react-app && .ant-radio-button-wrapper-checked:not(.react-app.react-container .ant-radio-button-wrapper-disabled) {
    background-color: #f7f8f9;
  }

  #react-app
    &&
    .ant-radio-button-wrapper-checked:not(.react-app.react-container .ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }
`;
