import styled from 'styled-components';

import { useLocalAppProperties } from 'providers';
import { CustomColor, getImageUrl, useCustomColor } from 'utils';
import { APP_FONT_14PX_MEDIUM, APP_FONT_20PX_MEDIUM } from 'mockup-font';

import { useAppTheme } from '../hooks';
import { useAppBeingEdited } from 'app-context';

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 13;
`;

const SideMenu = styled.div<CustomColor>`
  height: calc(100% + 2px);
  transform: translate(-1px, -1px); // Stop NavBar/TabBar bleeding through the edges of SideMenu
  width: 80%;
  background-color: ${useCustomColor};
  border-radius: 40px 0 0 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 45px 0 65px;
`;

const HeaderLogoWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
`;
const Logo = styled.img`
  height: 100%;
`;

const SideMenuItem = styled.div<CustomColor>`
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  padding-left: 18px;
  margin-bottom: 18px;
  ${APP_FONT_20PX_MEDIUM};
  color: ${useCustomColor};
`;

const SignOutBtn = styled.div<CustomColor>`
  width: 204px;
  height: 46px;
  color: ${useCustomColor};
  border-color: ${useCustomColor};
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${APP_FONT_14PX_MEDIUM};
  margin: 0 auto;
`;

export const AppSideMenu = () => {
  const appBeingEdited = useAppBeingEdited();
  const { properties } = useLocalAppProperties();
  const {
    HideSidebarProfile,
    HideSidebarFAQ,
    HideSidebarSupport,
    CustomProfileLabel,
    CustomFAQLabel,
    CustomSupportLabel,
  } = properties;
  const { getDesignProperty } = useAppTheme();
  const backgroundHEX = getDesignProperty('backgroundHEX') as string;
  const primaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const higlightHEX = getDesignProperty('highlightHEX') as string;
  const NavBarTitleImage = getDesignProperty('NavBarTitleImage');

  const headerLogoUrl = NavBarTitleImage ? getImageUrl(appBeingEdited, NavBarTitleImage as string) : undefined;

  return (
    <Overlay>
      <SideMenu $color={backgroundHEX}>
        <div>
          <HeaderLogoWrapper>{headerLogoUrl && <Logo src={headerLogoUrl} alt="app header logo" />}</HeaderLogoWrapper>
          {HideSidebarProfile !== '1' && <SideMenuItem $color={primaryItemHEX}>{CustomProfileLabel}</SideMenuItem>}
          <SideMenuItem $color={primaryItemHEX}>Manage My Subscription</SideMenuItem>
          {HideSidebarFAQ !== '1' && <SideMenuItem $color={primaryItemHEX}>{CustomFAQLabel}</SideMenuItem>}
          {HideSidebarSupport !== '1' && <SideMenuItem $color={primaryItemHEX}>{CustomSupportLabel}</SideMenuItem>}
        </div>
        <SignOutBtn $color={higlightHEX}>Sign Out</SignOutBtn>
      </SideMenu>
    </Overlay>
  );
};
