import { Resource } from 'api';
import styled, { css } from 'styled-components';
import { CloudDownloadIcon, FileIcon, MediaIcon, MusicIcon } from 'icons';
import { APP_FONT_12PX_REGULAR, APP_FONT_14PX_MEDIUM } from 'mockup-font';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';

const ResourceRow = styled.div<{ $color: string }>`
  display: flex;
  width: 100%;
  max-width: 100%;
  word-break: break-word;
  margin-bottom: 24px;
  align-items: center;
  color: ${({ $color }) => $color};

  :first-child {
    margin-top: 8px;
  }
`;

const IconCss = css`
  color: inherit;
  font-size: 34px;
  flex-grow: 0;
  margin-right: 16px;
`;

const ResourceIcon = styled(FileIcon)`
  ${IconCss};
`;
const StyledMediaIcon = styled(MediaIcon)`
  ${IconCss};
`;
const StyledMusicIcon = styled(MusicIcon)`
  ${IconCss};
`;
const StyledCloudDownloadIcon = styled(CloudDownloadIcon)`
  ${IconCss};
  margin-right: 0;
  margin-left: 16px;
`;
const StyledFlexDiv = styled.div`
  flex-grow: 1;
`;

const ResourceText = styled.div`
  ${APP_FONT_14PX_MEDIUM};
  color: inherit;
  max-width: inherit;
`;
const ResourceExtension = styled.div<{ $color: string }>`
  margin-top: 4px;
  flex-grow: 0;
  ${APP_FONT_12PX_REGULAR};
  text-transform: uppercase;
  color: ${({ $color }) => $color};
`;

const getIcon = (filetype?: string) => {
  if (filetype) {
    if (['mp4', 'mov', 'mkv', 'webm'].includes(filetype?.toLowerCase())) {
      return <StyledMediaIcon />;
    } else if (['wav', 'mp3', 'aac', 'm4a'].includes(filetype?.toLowerCase())) {
      return <StyledMusicIcon />;
    }
  }
  return <ResourceIcon />;
};

export const CollectionResource = ({ Type, Title }: Resource) => {
  const { getDesignProperty } = useAppTheme();
  return (
    <ResourceRow $color={getDesignProperty('primaryItemHEX') as string}>
      {getIcon(Type)}
      <StyledFlexDiv>
        <ResourceText>{Title}</ResourceText>
        <ResourceExtension $color={getDesignProperty('secondaryItemHEX') as string}>{Type}</ResourceExtension>
      </StyledFlexDiv>
      {['wav', 'mp3', 'aac', 'm4a'].includes(Type?.toLowerCase()) && <StyledCloudDownloadIcon />}
    </ResourceRow>
  );
};
