import { useCallback, ChangeEvent } from 'react';

import { useSaveContext, useLocalProducts, BuilderProduct } from 'providers';
import { SettingsTextInput, SettingsSegment } from 'components';

export const SubscriptionGroup = ({ Id, SubscriptionGroup }: Pick<BuilderProduct, 'Id' | 'SubscriptionGroup'>) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setProductValue(Id, 'SubscriptionGroup', value);
      setProductValueToSave(Id, 'SubscriptionGroup', value);
    },
    [setProductValue, setProductValueToSave, Id],
  );

  return (
    <SettingsSegment title="Subscription Group">
      <SettingsTextInput value={SubscriptionGroup} onChange={handleChange} $marginTop="0" />
    </SettingsSegment>
  );
};
