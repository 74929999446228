import styled from 'styled-components';
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import { SettingsInputWrapper } from 'components';
import { InputSize, NEUTRAL_10_COLOUR, INPUT_STYLES } from 'theme';
import { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { ChevronDownIcon } from 'icons';
import { ReactNode } from 'react';

const { Option } = AntdSelect;

interface SelectProps {
  width?: string;
  disabled?: boolean;
  size: InputSize;
  $backgroundColor?: string;
}

const Select = styled(AntdSelect)<SelectProps>`
  #react-app && {
    width: ${(props) => props.width || '100%'};
    color: ${NEUTRAL_10_COLOUR};
  }

  #react-app && .ant-select-selector {
    ${({ size }) => INPUT_STYLES[size]};
    background-color: ${({ disabled, $backgroundColor }) => (disabled ? '#f5f5f5' : $backgroundColor || 'unset')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  #react-app && .ant-select-arrow {
    color: ${NEUTRAL_10_COLOUR};
    font-size: 10px;
  }

  #react-app && .ant-select-selector,
  #react-app && .ant-select-arrow {
    display: flex;
    align-items: center;
  }
`;

const ChevronDown = styled(ChevronDownIcon)`
  &&&& {
    font-size: 16px;
    color: ${NEUTRAL_10_COLOUR};
  }
`;

// The ValueType for the select props can be anything
/* eslint-disable @typescript-eslint/no-explicit-any */
interface SettingsSelectInputProps
  extends Pick<
    AntdSelectProps<any, BaseOptionType | DefaultOptionType>,
    | 'getPopupContainer'
    | 'showSearch'
    | 'onSearch'
    | 'filterOption'
    | 'onChange'
    | 'disabled'
    | 'defaultActiveFirstOption'
    | 'placeholder'
  > {
  label?: string;
  tooltip?: string;
  defaultVal?: string | number;
  options: SelectOption[];
  value?: string | number;
  width?: string;
  wrapperWidth?: string;
  size?: InputSize;
  $marginBottom?: string;
  $backgroundColor?: string;
}

export interface SelectOption {
  name: string;
  value: string | number;
  label?: ReactNode;
  disabled?: boolean;
}

export const SettingsSelectInput = ({
  label,
  tooltip,
  defaultVal,
  options,
  wrapperWidth,
  disabled,
  size = 'large',
  $marginBottom,
  defaultActiveFirstOption,
  ...props
}: SettingsSelectInputProps) => {
  const firstOption = !defaultActiveFirstOption ? undefined : options[0].value;
  return (
    <SettingsInputWrapper label={label} tooltip={tooltip} width={wrapperWidth} $marginBottom={$marginBottom}>
      <Select
        defaultValue={defaultVal ?? firstOption}
        getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
        disabled={disabled}
        size={size}
        suffixIcon={<ChevronDown />}
        defaultActiveFirstOption={defaultActiveFirstOption}
        {...props}
      >
        {options.map((option) => (
          <Option key={option.name} value={option.value} disabled={option.disabled}>
            {option.label ?? option.name}
          </Option>
        ))}
      </Select>
    </SettingsInputWrapper>
  );
};
