import styled from 'styled-components';

import { NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { FONT_16PX_SEMIBOLD } from '../../font';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

const StyledLink = styled.span`
  #react-app && {
    cursor: pointer;
    color: ${NEUTRAL_7_COLOUR};
    ${FONT_16PX_SEMIBOLD};
    line-height: 48px;

    :hover {
      color: ${NEUTRAL_9_COLOUR};
    }
  }
`;
export const BackButton = () => {
  const { navigateBackward } = useWizardNavigation();
  return <StyledLink onClick={navigateBackward}>Back</StyledLink>;
};
