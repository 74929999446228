import { useState } from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { CustomButton, Segment, SegmentTitle } from 'components';
import { useSaveContext } from 'providers';
import { PlusIcon, XIcon } from 'icons';

import { CollectionDetailedItem } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { CustomiseContentV2, ModalContextProvider } from 'app/modules/build-dragdrop/Builder/customise-items';
import { BuilderCollection, BuilderCollectionItem, useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { updateCollectionPositions } from 'app/modules/build-dragdrop/Builder/util';

const NoContentSelected = styled.div`
  width: 100%;
  padding: 102px 58px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Heading = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
  margin-bottom: 4px;
`;
const Body = styled.div`
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
  margin-bottom: 24px;
`;
const CenteredRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DeleteIcon = styled(XIcon)`
  color: ${NEUTRAL_10_COLOUR};
  font-size: 20px;
`;

const UnpaddedCollectionDetailed = styled(CollectionDetailedItem)`
  padding: 0;
`;

interface CustomItemListProps {
  collection: BuilderCollection;
}

/**
 * Used for custom sections created in DragDrop (Batman Collections)
 * Used for VidApp groups (Standalone Apps)
 * Allows the items to be adjusted
 */
export const CollectionCustomItemList = ({ collection }: CustomItemListProps) => {
  // CustomiseContent modal
  const [visible, setVisible] = useState(false);

  const { collections, setCollections } = useContent();
  const { setCollectionItemsToSave } = useSaveContext();

  const handleDeleteItem = (item: BuilderCollectionItem) => {
    const newCollections = { ...collections };
    const newCollection = newCollections[collection.TabId];

    const idx = newCollection.Items.findIndex((i) => i.TabItemId === item.TabItemId);
    if (idx > -1) {
      newCollection.Items.splice(idx, 1);
      newCollection.Items = [...newCollection.Items]; // Updates simulator immediately
      updateCollectionPositions(newCollection);
      setCollections(newCollections);
      setCollectionItemsToSave(collection.TabId);
    } else {
      console.error('Unable to delete section with details', { tabId: collection.TabId, tabItemId: item.TabItemId });
    }
  };

  return (
    <>
      {collection.Items && collection.Items.length > 0 ? (
        <Segment
          header={
            <SpacedRow>
              <SegmentTitle title="Included Content" />
              <CustomButton medium tertiaryHighlight icon={<PlusIcon />} onClick={() => setVisible(true)}>
                Add
              </CustomButton>
            </SpacedRow>
          }
        >
          <Droppable droppableId={`${collection.TabId.toString()}|custom-section`} type="details">
            {(provided) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {collection.Items.map((item, idx) => {
                    return (
                      <UnpaddedCollectionDetailed item={item} idx={idx} key={item.TabItemId} context="SectionList">
                        <DeleteIcon onClick={() => handleDeleteItem(item)} />
                      </UnpaddedCollectionDetailed>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </Segment>
      ) : (
        <NoContentSelected>
          <Heading>No Content</Heading>
          <Body>You haven't added any content yet.</Body>
          <CenteredRow>
            <CustomButton tertiaryHighlight medium icon={<PlusIcon />} onClick={() => setVisible(true)}>
              Add Content
            </CustomButton>
          </CenteredRow>
        </NoContentSelected>
      )}
      {visible && (
        <ModalContextProvider collection={collection}>
          <CustomiseContentV2 collection={collection} modalVisible={visible} setModalVisible={setVisible} />
        </ModalContextProvider>
      )}
    </>
  );
};
