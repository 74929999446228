import { ReactNode } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from 'antd';
import { NEUTRAL_6_COLOUR, PRIMARY_ORANGE_COLOUR, SUCCESS_COLOUR, VIDAPP_GRADIENT } from 'theme';

export type WizardButtonType = 'outlined' | 'success' | 'loading';

export interface WizardButtonProps extends ButtonProps {
  $height?: string;
  $width?: string;
  $fontSize?: string;
  $fontWeight?: string;
  $loadingMargin?: string;
  $type?: string;
  $defaultColor?: string;
  icon?: ReactNode;
}

const UnstyledButton = (props: WizardButtonProps) => {
  return (
    <Button type="primary" shape="round" {...props}>
      {props?.children}
    </Button>
  );
};

const getBackground = (isDisabled?: boolean, type?: string, defaultColor?: string) => {
  const backgroundMap = {
    outlined: 'none',
    success: SUCCESS_COLOUR,
  } as Record<string, string>;

  // Background based on type, supersedes disabled
  if (type && backgroundMap.hasOwnProperty(type)) {
    return backgroundMap[type];
  }

  // Grey out button when disabled
  if (isDisabled) {
    return NEUTRAL_6_COLOUR;
  }

  // Default background
  return defaultColor ?? VIDAPP_GRADIENT;
};

export const WizardButton = styled(UnstyledButton)`
  width: ${(props) => props.$width || '100%'};

  #react-app &&& {
    height: ${(props) => props.$height || '68px'};
    background: ${(props) => getBackground(props.disabled, props.$type, props.$defaultColor)};
    border: ${(props) => (props.$type === 'outlined' ? `${PRIMARY_ORANGE_COLOUR} 1px solid` : 'none')};

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    padding-left: 30px;
    padding-right: 30px;

    > span {
      font-size: ${(props) => props.$fontSize || '24px'};
      font-weight: ${(props) => props.$fontWeight || '500'};
      color: ${(props) => (props.$type === 'outlined' ? PRIMARY_ORANGE_COLOUR : 'white')};
    }

    .ant-btn-loading-icon {
      margin-left: ${(props) => (props.$loadingMargin || '8px') + ' !important'};

      .anticon-spin {
        padding-right: 0 !important;
      }
    }

    .anticon {
      margin-left: 8px;
    }
  }
`;
