import { useCallback, useState, CSSProperties, ChangeEvent, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Drawer } from 'antd';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { customAlphabet } from 'nanoid';

import { NAV_BAR_OFFSET, NEUTRAL_5_COLOUR, DANGER_COLOUR } from 'theme';
import { DrawerSection, RemovableInput, CustomButton, SaveFailed, DragHandleDots, SettingsTextInput } from 'components';
import { useUnsavedChanges } from 'providers';
import { useSaveFilter } from 'hooks';
import { Filter, FilterOption } from 'api';
import { FONT_16PX_SEMIBOLD } from 'font';
import { BUILDER_DRAWER_WIDTH } from 'app/modules/build-dragdrop/Builder/const';
import { PlusIcon } from 'icons';

const nanoid = customAlphabet('123456789', 16);

const DrawerContent = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 28px;

  display: flex;
  flex-direction: column;
`;

const DrawerHeader = styled.div`
  ${FONT_16PX_SEMIBOLD};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 54px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const CancelButton = styled(CustomButton)`
  &&& {
    margin-right: 10px;
  }
`;

const RemovableInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ErrorMessage = styled.div`
  min-height: 16px;
  padding: 4px;
  font-size: 12px;
  line-height: 14px;
  color: ${DANGER_COLOUR};
`;

const drawerStyles: CSSProperties = {
  position: 'fixed',
  top: NAV_BAR_OFFSET,
  height: `calc(100% - ${NAV_BAR_OFFSET})`,
  zIndex: 120,
  transform: 'none',
};

const drawerBodyStyles: CSSProperties = {
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `1px solid ${NEUTRAL_5_COLOUR}`,
};

const updateOptionPositions = (filter: Filter) => {
  for (let i = 0; i < filter.FilterOptions.length; i++) {
    filter.FilterOptions[i].Position = i + 1;
  }
  return filter;
};

interface ContentTaggingDrawerProps {
  activeFilter: Filter;
  setActiveFilter: Dispatch<SetStateAction<Filter | undefined>>;
  resetTable: () => void;
}

export const ContentTaggingDrawer = ({ activeFilter, setActiveFilter, resetTable }: ContentTaggingDrawerProps) => {
  const saveFilter = useSaveFilter();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const [filter, setFilter] = useState<Filter>(activeFilter);
  const [isValidating, setIsValidating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const filterIsEdited = useMemo(() => JSON.stringify(activeFilter) !== JSON.stringify(filter), [activeFilter, filter]);

  const emptyFields = useMemo(() => {
    return filter.Filter.length === 0 || filter.FilterOptions.some((option) => option.Option.length === 0);
  }, [filter]);

  useEffect(() => {
    if (!unsavedChanges && !isSaving && filterIsEdited) {
      setUnsavedChanges(true);
    }
  }, [filterIsEdited, unsavedChanges, isSaving, setUnsavedChanges]);

  const handleAddOption = useCallback(() => {
    const newState = JSON.parse(JSON.stringify(filter));
    const updatedOptions = newState.FilterOptions;
    updatedOptions.push({
      Position: updatedOptions.length + 1,
      Enabled: true,
      Id: parseInt(nanoid()),
      IsDefault: false,
      Option: '',
      SourceTag: null,
    });

    setFilter({ ...newState, FilterOptions: updatedOptions });
  }, [filter, setFilter]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;
      if (!destination || destination.index === source.index) {
        return;
      }

      setFilter((prevState) => {
        const updatedOptions = [...prevState.FilterOptions];
        const draggedOption = updatedOptions.find((option: FilterOption) => option.Id.toString() === draggableId);
        updatedOptions.splice(source.index, 1);
        if (draggedOption) {
          updatedOptions.splice(destination.index, 0, draggedOption);
        }

        return updateOptionPositions({ ...prevState, FilterOptions: updatedOptions });
      });
    },
    [setFilter],
  );

  const handleFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFilter((prevState) => ({ ...prevState, Filter: e.target.value }));
    },
    [setFilter],
  );

  const handleOptionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFilter((prevState) => {
        const updatedOptions = prevState.FilterOptions.map((filterOption: FilterOption) => {
          if (filterOption.Id.toString() === e.target.id) {
            return { ...filterOption, Option: e.target.value };
          }
          return filterOption;
        });
        return { ...prevState, FilterOptions: updatedOptions };
      });
    },
    [setFilter],
  );

  const handleOptionRemove = useCallback(
    (id: number) => {
      const newState = JSON.parse(JSON.stringify(filter));
      const optionIndex = newState.FilterOptions.findIndex((filterOption: FilterOption) => filterOption.Id == id);
      newState.FilterOptions.splice(optionIndex, 1);
      setFilter(updateOptionPositions(newState));
    },
    [filter, setFilter],
  );

  const handleSave = useCallback(async () => {
    if (emptyFields) {
      setIsValidating(true);
    } else {
      setIsValidating(false);
      setIsSaving(true);
      const saveFilterResponse = await Promise.resolve(saveFilter.mutateAsync(filter)).catch((error) => {
        SaveFailed(error);
        setIsSaving(false);
        setUnsavedChanges(true);
      });

      if (typeof saveFilterResponse !== 'undefined') {
        setIsSaving(false);
        setUnsavedChanges(false);
        resetTable();
        setActiveFilter(undefined);
      }
    }
  }, [filter, saveFilter, emptyFields, setIsValidating, resetTable, setActiveFilter, setIsSaving, SaveFailed]);

  const filterNameBorderStyle =
    isValidating && activeFilter?.Filter.length === 0 ? '1px solid #ff4242' : `1px solid ${NEUTRAL_5_COLOUR}`;

  return (
    <Drawer
      placement="right"
      closable={false}
      width={BUILDER_DRAWER_WIDTH}
      destroyOnClose
      maskStyle={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}
      open={!!activeFilter}
      key="right"
      getContainer={false}
      style={drawerStyles}
      bodyStyle={drawerBodyStyles}
      data-testid="filters-drawer"
    >
      <DrawerContent>
        <DrawerHeader>
          Edit Category
          <ButtonContainer>
            <CancelButton
              tertiary
              medium
              onClick={() => {
                setUnsavedChanges(false);
                setActiveFilter(undefined);
              }}
            >
              Cancel
            </CancelButton>
            <CustomButton medium disabled={!unsavedChanges} loading={isSaving} onClick={handleSave}>
              Save
            </CustomButton>
          </ButtonContainer>
        </DrawerHeader>
        <DrawerSection heading="Title" subheading="This title will appear in the Filter view.">
          <SettingsTextInput
            size="middle"
            value={filter.Filter}
            onChange={handleFilterChange}
            style={{ border: filterNameBorderStyle }}
          />
          <ErrorMessage>{isValidating && filter.Filter.length === 0 && 'You have unconfirmed changes'}</ErrorMessage>
        </DrawerSection>
        <DrawerSection heading="Tags" subheading="Add tags relating to this category.">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="optionsDroppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {filter.FilterOptions.map((option: FilterOption, idx: number) => (
                    <Draggable draggableId={option.Id.toString()} index={idx} key={option.Id}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <RemovableInputWrapper>
                            <DragHandleDots $size="16px" $padding="0" {...provided.dragHandleProps} />
                            <RemovableInput
                              id={option.Id}
                              value={option.Option}
                              onChange={handleOptionChange}
                              handleRemove={handleOptionRemove}
                              error={isValidating && option.Option.length === 0}
                            />
                          </RemovableInputWrapper>
                          <ErrorMessage>
                            {isValidating && option.Option.length === 0 && 'You have unconfirmed changes'}
                          </ErrorMessage>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <CustomButton tertiaryHighlight medium onClick={handleAddOption} icon={<PlusIcon />}>
            Add New Tag
          </CustomButton>
        </DrawerSection>
      </DrawerContent>
    </Drawer>
  );
};
