import styled from 'styled-components';
import { Switch, SwitchProps, Typography, Tooltip as AntdTooltip } from 'antd';

import { SegmentTitle, SettingsFormItem } from 'components';
import { FONT_14PX_REGULAR } from 'font';
import { NEUTRAL_8_COLOUR, NEUTRAL_6_COLOUR } from 'theme';

interface ContainerProps {
  $marginBottom?: string;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  margin-bottom: ${(props) => props.$marginBottom || '16px'};
`;

export const ToggleWrapper = styled.div<{
  $showLeftToRight?: boolean;
  $justifyContent?: string;
}>`
  display: flex;
  justify-content: ${({ $justifyContent, $showLeftToRight }) =>
    $justifyContent ? $justifyContent : $showLeftToRight ? 'flex-end' : 'flex-start'};

  flex-direction: ${({ $showLeftToRight }) => $showLeftToRight && 'row-reverse'};

  align-items: center;

  #react-app && .ant-switch-checked {
    background-color: #35b535;
  }

  #react-app && .ant-switch-handle {
    width: 16px;
    height: 16px;
  }

  #react-app && .ant-switch-checked > .ant-switch-handle {
    left: calc(100% - 16px - 2px);
  }

  #react-app && .ant-switch-inner {
    font-size: 8px;
    line-height: 100%;
    margin: 0 30px 0 5px;
  }
`;

export const StyledToggle = styled(Switch)<{ $margin?: string }>`
  &&&& {
    min-width: unset;
    width: 37px;
    height: 20px;
    margin: ${({ $margin }) => $margin || '0'};
  }
`;

const Description = styled.div<{ $disabled?: boolean }>`
  ${FONT_14PX_REGULAR};
  color: ${({ $disabled }) => ($disabled ? NEUTRAL_6_COLOUR : NEUTRAL_8_COLOUR)};
`;

const ExpandableDescription = styled(Typography.Paragraph)<{ $disabled?: boolean }>`
  #react-app && {
    ${FONT_14PX_REGULAR};
    color: ${({ $disabled }) => ($disabled ? NEUTRAL_6_COLOUR : NEUTRAL_8_COLOUR)};
    white-space: pre-wrap;
    margin-bottom: 0;
  }
`;

interface SettingsCheckboxInputProps {
  name?: string;
  label: string;
  description?: string | React.ReactNode;
  expandableDescription?: boolean;
  marginBottom?: string;
  onInputChange?: (checked: boolean) => void;
  checkedChildren?: SwitchProps['checkedChildren'];
  defaultChecked?: SwitchProps['defaultChecked'];
  showLeftToRight?: boolean; // Label on the left, spaced from toggle on the right
  justifyContent?: string;
  disabled?: boolean;
  tooltip?: string;
}

export const SettingsToggleInput = ({
  name,
  label,
  description,
  expandableDescription,
  marginBottom,
  onInputChange,
  checkedChildren,
  defaultChecked,
  showLeftToRight,
  justifyContent,
  disabled,
  tooltip,
}: SettingsCheckboxInputProps) => (
  <Container $marginBottom={marginBottom}>
    <ToggleWrapper $showLeftToRight={showLeftToRight} $justifyContent={justifyContent}>
      <AntdTooltip
        getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
        arrowPointAtCenter
        placement="right"
        title={tooltip}
        trigger="hover"
      >
        <SettingsFormItem name={name ?? ''} valuePropName="checked" width="fit-content" marginBottom="0">
          <StyledToggle
            defaultChecked={defaultChecked}
            checkedChildren={checkedChildren ?? 'ON'}
            onChange={onInputChange}
            disabled={disabled}
            $margin={showLeftToRight ? '0 0 0 8px' : '0 8px 0 0'}
          />
        </SettingsFormItem>
      </AntdTooltip>
      <SegmentTitle title={label} $marginBottom="0" disabled={disabled} />
    </ToggleWrapper>
    {description && expandableDescription ? (
      <ExpandableDescription $disabled={disabled} ellipsis={{ rows: 2, expandable: true, symbol: 'See More' }}>
        {description}
      </ExpandableDescription>
    ) : description ? (
      <Description $disabled={disabled}>{description}</Description>
    ) : null}
  </Container>
);
