import styled from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import { UISyncIcon } from 'icons';

import { CollectionBasicInfo, syncKajabiProduct, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR, PRIMARY_ORANGE_COLOUR } from 'theme';

import { BackButton, Footer, NeedHelp, NextButton, Product, SplitPanel, StepPanel } from '../index';
import { FONT_16PX_MEDIUM, FONT_24PX_BOLD } from '../../font';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { useListCollections } from '../../hooks/useListCollections';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

const Header = styled.div`
  margin-top: 25px;
  ${FONT_24PX_BOLD};
  color: ${NEUTRAL_9_COLOUR};
`;
const Subtitle = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_7_COLOUR};
  margin-top: 8px;
`;
const ProductsWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RightFooter = styled(Footer)`
  justify-content: space-between;
`;

const padProducts = (products: CollectionBasicInfo[]) => {
  if (products.length < 6) {
    products = products.concat(Array(6 - products.length).fill({}));
  }
  return products;
};

const SESSION_STORAGE_KEY = 'wizard-synced-items';

export const KajabiProductsStep = () => {
  const appBeingEdited = useAppBeingEdited();
  const axiosInstance = useAxiosInstance();
  const [syncing, setSyncing] = useState<boolean>(false);
  const { data: products } = useListCollections(6);
  const { navigateForward } = useWizardNavigation();

  const handleSync = useCallback(async () => {
    if (products == null) {
      return;
    }
    setSyncing(true);

    const syncedItems = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) || '[]');
    for (let i = 0; i < products.slice(0, 6).length; i++) {
      const product = products[i];
      if (!syncedItems.includes(product?.URI)) {
        // Trigger the products 1 second apart
        setTimeout(() => {
          console.info(`Triggering sync for Kajabi Product ${product?.URI}`);
          syncKajabiProduct(axiosInstance, appBeingEdited, product.URI, {
            syncToApp: true,
            isOnboarding: true,
          });
        }, 1000 * i);

        syncedItems.push(product?.URI);
      }
    }
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(syncedItems));

    // Let the syncs run async and continue on without them
    setTimeout(() => {
      navigateForward();
    }, 2000);
  }, [navigateForward, setSyncing, products]);

  // Pad products out to 6 products if there are less than that to maintain the correct layout
  const paddedProducts = useMemo(() => (products ? padProducts(products) : []), products);

  return (
    <SplitPanel
      leftContent={
        <>
          <StepPanel
            header="Sync your Kajabi Courses with VidApp."
            body={
              <>
                Automatically sync the categories,
                <br /> sub-categories, posts, and resources into your branded apps.
              </>
            }
            icon={<img src={`${BUILDER_IMAGES_DOMAIN}/onboarding/wizard_icons/sync.webp`} alt="user icon" />}
          />
          <Footer>
            <NeedHelp $color={PRIMARY_ORANGE_COLOUR} />
          </Footer>
        </>
      }
      rightContent={
        <>
          <Header>Sync your first 6 courses</Header>
          <Subtitle>*You will be able to sync your entire library of courses on a paid plan.</Subtitle>
          <ProductsWrapper>
            {paddedProducts?.slice(0, 6).map((product) => (
              <Product key={product.URI} {...product} />
            ))}
          </ProductsWrapper>
          <RightFooter>
            <BackButton />
            <NextButton
              onClick={handleSync}
              $type={syncing ? 'success' : undefined}
              disabled={!products}
              loading={syncing}
              icon={!syncing && <UISyncIcon style={{ fontSize: '20px', height: '20px' }} />}
            >
              {!syncing ? 'Sync' : 'Syncing'}
            </NextButton>
          </RightFooter>
        </>
      }
    />
  );
};
