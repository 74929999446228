import { useCallback } from 'react';

import { ContentPreviewVideo } from 'components';
import { useSaveContext, BuilderCollection, useContent } from 'providers';
import { SOURCE_VHX } from 'api';

export const CollectionPreviewVideo = ({ TabId }: Pick<BuilderCollection, 'TabId'>) => {
  const { setCollectionValue, collections } = useContent();
  const { setCollectionValueToSave } = useSaveContext();

  const handlePreviewVideoChange = useCallback(
    (previewVideoId: number) => {
      setCollectionValue(TabId, 'PreviewVideoId', previewVideoId);
      setCollectionValueToSave(TabId, 'PreviewVideoId', previewVideoId);
    },
    [setCollectionValueToSave, setCollectionValue, TabId],
  );

  return (
    <ContentPreviewVideo
      defaultValue={
        !collections[TabId].PreviewVideoId || collections[TabId].PreviewVideoId === -1
          ? 0
          : collections[TabId].PreviewVideoId
      }
      handleChange={handlePreviewVideoChange}
      disabled={collections[TabId].DataSource === SOURCE_VHX}
    />
  );
};
