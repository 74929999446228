import { BuilderCollectionItem, useContent } from 'providers';
import { CourseViewItem } from 'app/modules/build-dragdrop/Builder/mockup/components/CollectionViews/CourseView/CourseViewItem';
import styled from 'styled-components';
import { ITEM_TYPE_VIDEO, Video } from 'api';
import { isResource, isSectionHeaderItem } from 'utils';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';

export const RowPadding = styled.div<{ $isSectionHeader?: boolean }>`
  margin: ${({ $isSectionHeader }) => ($isSectionHeader ? '0 0 0 16px' : '16px 16px 28px 16px')};
`;

interface LessonProps {
  lesson: BuilderCollectionItem;
}

export const Lesson = ({ lesson }: LessonProps) => {
  const { getContentForItem } = useContent();
  const { setCurrentPage } = useMockupContext();

  const handleItemClick = (item: BuilderCollectionItem) => {
    const video = getContentForItem(item) as Video;

    if (video && item.Type === ITEM_TYPE_VIDEO && !isResource(video)) {
      setCurrentPage({
        itemType: ITEM_TYPE_VIDEO,
        tabItemId: item.TabItemId,
        itemId: item.ChildId,
        parentCollectionId: item.TabId,
      });
    }
  };

  return (
    <RowPadding $isSectionHeader={isSectionHeaderItem(lesson)}>
      <CourseViewItem key={lesson.TabItemId} item={lesson} onClick={handleItemClick} />
    </RowPadding>
  );
};
