import { Form } from 'antd';

import { PageContainer, SettingsGroup, SettingsFormItem, SettingsTextInput, CustomButton } from 'components';

import { useTestingScreen } from '../hooks/useTestingScreen';

const FORM_ITEM_WIDTH = '49%';

export interface PropertyType {
  type: 'appProperty' | 'secureAppProperty';
  stripWhitespace?: boolean;
}

// Array of all secure app properties editable in the Testing Screen form
const PROPERTIES: Record<string, PropertyType> = {
  // App Test User
  AppTestUserEmail: { type: 'secureAppProperty', stripWhitespace: true },
  AppTestUserPassword: { type: 'secureAppProperty', stripWhitespace: true },
  // iOS Review Test User
  iOSTestUserEmail: { type: 'secureAppProperty', stripWhitespace: true },
  iOSTestUserPassword: { type: 'secureAppProperty', stripWhitespace: true },
};

export const TestingScreen = () => {
  const { form, unsavedChanges, isSaving, isLoading, isError, initialValues, onValuesChange, saveForm } =
    useTestingScreen(PROPERTIES);

  return (
    <PageContainer
      heading="Testing"
      isLoading={isLoading}
      isError={isError}
      headingButton={
        <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
          Save
        </CustomButton>
      }
    >
      <>
        <Form
          name="settings-form"
          onValuesChange={onValuesChange}
          onFinish={saveForm}
          autoComplete="off"
          form={form}
          initialValues={initialValues}
        >
          <SettingsGroup title="App Test User" wrapRow>
            <SettingsFormItem name="AppTestUserEmail" width={FORM_ITEM_WIDTH}>
              <SettingsTextInput label="Email Address" autoComplete="off" />
            </SettingsFormItem>
            <SettingsFormItem name="AppTestUserPassword" width={FORM_ITEM_WIDTH}>
              <SettingsTextInput label="Password" autoComplete="off" />
            </SettingsFormItem>
          </SettingsGroup>
          <SettingsGroup title="iOS Review Test User" wrapRow>
            <SettingsFormItem name="iOSTestUserEmail" width={FORM_ITEM_WIDTH}>
              <SettingsTextInput label="Email Address" autoComplete="off" />
            </SettingsFormItem>
            <SettingsFormItem name="iOSTestUserPassword" width={FORM_ITEM_WIDTH}>
              <SettingsTextInput label="Password" autoComplete="off" />
            </SettingsFormItem>
          </SettingsGroup>
        </Form>
      </>
    </PageContainer>
  );
};
