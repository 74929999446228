import { AxiosInstance } from 'axios';
import { builderAPI } from 'api/axios';

export const refreshContentCache = (client: AxiosInstance, appId: string) => {
  return client
    .get(`${builderAPI.contentApiURL}/refresh/${appId}/builder`, {
      headers: {
        accept: 'application/json',
      },
    })
    .then(({ data }) => {
      console.info(data);
    })
    .catch((err) => console.error(err));
};
