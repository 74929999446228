import styled, { css } from 'styled-components';

import { FONT_10PX_MEDIUM, FONT_16PX_SEMIBOLD } from 'font';
import { HIGHLIGHT_PRIMARY_COLOUR, HIGHLIGHT_SECONDARY_HOVER_COLOUR, NEUTRAL_8_COLOUR } from 'theme';

interface BetaTagProps {
  small?: boolean;
  isSideNav?: boolean;
  isAdmin?: boolean;
}

const getSizeStyles = ({ small }: BetaTagProps) => {
  if (small) {
    return css`
      ${FONT_10PX_MEDIUM};
      border-radius: 3px;
      padding: 2px 4px;
    `;
  } else {
    return css`
      ${FONT_16PX_SEMIBOLD};
      border-radius: 8px;
      padding: 2px 6px;
    `;
  }
};

const Tag = styled.div<BetaTagProps>`
  margin-left: 8px;
  color: ${({ isAdmin }) => (isAdmin ? '#fff' : HIGHLIGHT_PRIMARY_COLOUR)};
  background-color: ${({ isAdmin, isSideNav }) =>
    isAdmin ? NEUTRAL_8_COLOUR : isSideNav ? '#263747' : HIGHLIGHT_SECONDARY_HOVER_COLOUR};

  ${(props) => getSizeStyles(props)}
`;

export const BetaTag = ({ isAdmin, ...props }: BetaTagProps) => (
  <Tag isAdmin={isAdmin} {...props}>
    {isAdmin ? 'ADMIN' : 'BETA'}
  </Tag>
);
