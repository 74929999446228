import {
  ColumnModal,
  ContentSourceTitle,
  Segment,
  SettingsVideoThumbnail,
  VideoPreviewVideo,
  VideoTitle,
} from 'components';
import { useContent } from 'providers';
import styled from 'styled-components';
import { getVideoName } from 'utils';

import { ExerciseSpecificsInput } from '../content-tree/components';
import { useModalContext } from './providers/modal-provider';

const ScrollContainer = styled.div`
  max-height: 65vh;
  overflow: auto;
  padding: 0 22px 0 24px;
  margin-right: 2px;
`;

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

export const ExerciseConfigModal = () => {
  const { modalExerciseId, setModalExerciseId } = useModalContext();
  const { videos } = useContent();

  const video = modalExerciseId ? videos[modalExerciseId] : undefined;

  return (
    <ColumnModal
      title={video ? getVideoName(video) : ' '}
      cols={3}
      align="right"
      onConfirm={() => setModalExerciseId(undefined)}
      open={!!video}
      onOpenChange={(open) => {
        if (!open) {
          setModalExerciseId(undefined);
        }
      }}
      primaryText="Done"
      hideClose
      hideSecondary
      hideDialogActionsBorder
      contentPadding="0"
      maxHeight="100%"
    >
      {video && (
        <ScrollContainer>
          {/* When modal is opened, first input is automatically selected. Using hidden input to remove that behaviour  */}
          <HiddenInput />
          <VideoTitle
            VideoId={video.VideoId}
            Title={video.Title}
            placeholder={video.SourceTitle}
            label="VidApp Title"
            size="middle"
            $marginTop="0"
            $marginBottom="24px"
          />
          <ExerciseSpecificsInput videoId={video.VideoId} />
          <SettingsVideoThumbnail
            hideAdditionalThumbnails
            video={video}
            width="182px"
            height="102px"
            $marginTop="0"
            $marginBottom="28px"
          />
          <Segment header="Instructional Configuration" $marginTop="0" $marginBottom="28px">
            <VideoPreviewVideo isInstructionalVideo VideoId={video.VideoId} />
          </Segment>
          <ContentSourceTitle size="middle" DataSource={video.DataSource} SourceName={video.SourceTitle} />
        </ScrollContainer>
      )}
    </ColumnModal>
  );
};
