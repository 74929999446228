import styled from 'styled-components';

import { BuilderCollectionItem } from 'providers';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { EllipsisIcon } from 'icons';
import { CustomColor, useCustomColor } from 'utils';
import { APP_FONT_16PX_MEDIUM } from 'mockup-font';

import { Lesson } from './Lesson';

const StyledEllipsisIcon = styled(EllipsisIcon)<CustomColor>`
  color: ${useCustomColor};
  font-size: 24px;
`;

const SubcategoryTitle = styled.div<{ $color: string }>`
  width: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;

  ${APP_FONT_16PX_MEDIUM};
  color: ${({ $color }) => $color};
  margin-top: 16px;
  margin-bottom: 4px;
`;

export interface SimpleModule {
  id: string;
  title: string;
  lessons: BuilderCollectionItem[];
}

interface SubCategoryProps {
  subcategory: SimpleModule;
}

export const SubCategory = ({ subcategory }: SubCategoryProps) => {
  const { getDesignProperty } = useAppTheme();
  const $color = getDesignProperty('primaryItemHEX') as string;

  return (
    <>
      <SubcategoryTitle $color={$color}>
        <div>{subcategory.title}</div>
        <StyledEllipsisIcon $color={$color} />
      </SubcategoryTitle>
      {subcategory.lessons.map((lesson) => {
        return <Lesson lesson={lesson} key={lesson.TabItemId} />;
      })}
    </>
  );
};
