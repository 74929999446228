import { useState, useCallback, useEffect } from 'react';
import { Form } from 'antd';

import { SaveFailed } from 'components';
import { useUnsavedChanges } from 'providers';

import { SecureAppPropertyToSave } from 'api';
import { useSecureAppProperties, useSaveSecureAppProperties } from 'hooks';

import { PropertyType } from '../TestingScreen/TestingScreen';

export const useTestingScreen = (PROPERTIES: Record<string, PropertyType>) => {
  const {
    data: secureAppProperties,
    isLoading: secureAppPropertiesIsLoading,
    isError: secureAppPropertiesIsError,
  } = useSecureAppProperties();
  const saveSecureAppProperties = useSaveSecureAppProperties();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<Record<string, unknown>>();

  useEffect(() => {
    if (secureAppProperties) {
      const values: Record<string, unknown> = {};

      for (const key in PROPERTIES) {
        values[key] = secureAppProperties[key];
      }
      setInitialValues(values);
    }
  }, [secureAppProperties]);

  useEffect(() => {
    !isSaving && form.resetFields();
  }, [initialValues, isSaving]);

  const onValuesChange = useCallback(() => {
    if (!unsavedChanges) {
      setUnsavedChanges(true);
    }
  }, [unsavedChanges, setUnsavedChanges]);

  const getValueToSave = useCallback(
    (field: string, stripWhitespace?: boolean) => {
      const value = form.getFieldValue(field);
      return stripWhitespace ? value.replace(/\s+/g, '') : value;
    },
    [form],
  );

  const saveForm = useCallback(async () => {
    setIsSaving(true);
    const fieldsToSave = form.getFieldsValue(true, (meta) => meta.touched);

    const secureAppPropertiesToSave: SecureAppPropertyToSave[] = [];

    for (const field in fieldsToSave) {
      const value = getValueToSave(field, PROPERTIES[field].stripWhitespace);

      secureAppPropertiesToSave.push({ name: field, value: value });
    }

    if (secureAppPropertiesToSave.length > 0) {
      try {
        await saveSecureAppProperties.mutateAsync(secureAppPropertiesToSave);
      } catch (error) {
        SaveFailed(error as Error);
        setUnsavedChanges(true);
        setIsSaving(false);
      }
    }

    setIsSaving(false);
    setUnsavedChanges(false);
    form.resetFields();
  }, [setIsSaving, setUnsavedChanges, form, saveSecureAppProperties]);

  return {
    form,
    unsavedChanges,
    isSaving,
    isLoading: secureAppPropertiesIsLoading,
    isError: secureAppPropertiesIsError,
    initialValues,
    onValuesChange,
    saveForm,
  };
};
