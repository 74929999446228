import { useAxiosInstance } from 'api';
import { useQuery } from 'react-query';
import { getVidAppProperty } from 'api/vidapp-property';

export const useVidAppProperties = () => {
  const client = useAxiosInstance();
  return useQuery(
    ['vidapp_properties'],
    () => {
      return getVidAppProperty(client);
    },
    {},
  );
};
