import { useState, useCallback, useEffect } from 'react';
import { Form } from 'antd';

import { SaveFailed } from 'components';
import { useUnsavedChanges } from 'providers';
import { AppPropertyToSave, SecureAppPropertyToSave } from 'api';
import { useAppProperties, useSecureAppProperties, useSaveAppProperties, useSaveSecureAppProperties } from 'hooks';

import { PropertyType } from '../Integrations/const';

interface Filename {
  value: string | undefined;
  touched: boolean;
}

const getPropertyValue = (value: unknown, valueType?: 'string' | 'binary') => {
  if (valueType === 'binary') {
    return value === '1';
  }
  return value;
};

export const useIntegrations = (PROPERTIES: Record<string, PropertyType>) => {
  const { data: appProperties, isLoading: appPropertiesIsLoading, isError: appPropertiesIsError } = useAppProperties();
  const {
    data: secureAppProperties,
    isLoading: secureAppPropertiesIsLoading,
    isError: secureAppPropertiesIsError,
  } = useSecureAppProperties();
  const saveAppProperties = useSaveAppProperties();
  const saveSecureAppProperties = useSaveSecureAppProperties();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<Record<string, unknown>>();
  const [filenames, setFilenames] = useState<Record<string, Filename>>({});

  useEffect(() => {
    if (appProperties && secureAppProperties) {
      const values: Record<string, unknown> = {};

      for (const key in PROPERTIES) {
        const { type, valueType, isFile } = PROPERTIES[key];
        if (isFile) {
          setFilenames((prev) => ({
            ...prev,
            [key]: {
              value: type === 'secureAppProperty' ? secureAppProperties[key] : appProperties[key],
              touched: false,
            },
          }));
        } else if (type === 'appProperty') {
          values[key] = getPropertyValue(appProperties[key], valueType);
        } else {
          values[key] = getPropertyValue(secureAppProperties[key], valueType);
        }
      }
      setInitialValues(values);
    }
  }, [appProperties, secureAppProperties, setFilenames]);

  useEffect(() => {
    !isSaving && form.resetFields();
  }, [initialValues, isSaving]);

  const onValuesChange = useCallback(() => {
    if (!unsavedChanges) {
      setUnsavedChanges(true);
    }
  }, [unsavedChanges, setUnsavedChanges]);

  const onFilenameChange = useCallback(
    (key: string, _filename: string) => {
      setFilenames((prev) => ({ ...prev, [key]: { value: _filename, touched: true } }));
      onValuesChange();
    },
    [setFilenames, onValuesChange],
  );

  const getValueToSave = useCallback(
    (field: string, stripWhitespace?: boolean) => {
      const valueType = PROPERTIES[field].valueType;
      if (valueType === 'binary') {
        return form.getFieldValue(field) ? '1' : '0';
      } else {
        const value = form.getFieldValue(field);
        return stripWhitespace ? value.replace(/\s+/g, '') : value;
      }
    },
    [form],
  );

  const saveForm = useCallback(async () => {
    setIsSaving(true);
    setUnsavedChanges(false);
    const fieldsToSave = form.getFieldsValue(true, (meta) => meta.touched);

    const appPropertiesToSave: AppPropertyToSave[] = [];
    const secureAppPropertiesToSave: SecureAppPropertyToSave[] = [];

    for (const field in fieldsToSave) {
      const value = getValueToSave(field, PROPERTIES[field].stripWhitespace);
      if (PROPERTIES[field].type === 'appProperty') {
        appPropertiesToSave.push({ Name: field, Value: value });
      } else if (PROPERTIES[field].type === 'secureAppProperty') {
        secureAppPropertiesToSave.push({ name: field, value: value });
      }
    }

    // Add filenames to be saved if they have been touched
    for (const [key, { touched, value }] of Object.entries(filenames)) {
      const type = PROPERTIES[key].type;
      if (touched) {
        if (type === 'appProperty') {
          appPropertiesToSave.push({ Name: key, Value: value ?? '' });

          // If a Font property is being updated, the equivalent Android property should also be updated
          if (key.includes('CustomFont')) {
            appPropertiesToSave.push({ Name: key.replace('Apple', 'Android'), Value: value ?? '' });
          }
        } else if (type === 'secureAppProperty') {
          secureAppPropertiesToSave.push({ name: key, value: value ?? '' });
        }
      }
    }

    if (appPropertiesToSave.length > 0) {
      try {
        await saveAppProperties.mutateAsync(appPropertiesToSave);
      } catch (error) {
        SaveFailed(error as Error);
        setUnsavedChanges(true);
        setIsSaving(false);
      }
    }

    if (secureAppPropertiesToSave.length > 0) {
      try {
        await saveSecureAppProperties.mutateAsync(secureAppPropertiesToSave);
      } catch (error) {
        SaveFailed(error as Error);
        setUnsavedChanges(true);
        setIsSaving(false);
      }
    }

    setIsSaving(false);
    form.resetFields();
  }, [setIsSaving, setUnsavedChanges, form, appProperties, saveAppProperties, saveSecureAppProperties]);

  return {
    appProperties,
    form,
    unsavedChanges,
    isSaving,
    isLoading: appPropertiesIsLoading || secureAppPropertiesIsLoading,
    isError: appPropertiesIsError || secureAppPropertiesIsError,
    initialValues,
    filenames,
    onFilenameChange,
    onValuesChange,
    saveForm,
  };
};
