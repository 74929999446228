import { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useLocalAppProperties } from 'providers';
import {
  APP_FONT_11PX_MEDIUM,
  APP_FONT_13PX_MEDIUM,
  APP_FONT_13PX_REGULAR,
  APP_FONT_16PX_MEDIUM,
  APP_FONT_16PX_REGULAR,
  APP_FONT_17PX_REGULAR,
} from 'mockup-font';
import {
  CalendarCompleteIcon,
  CalendarMissedIcon,
  CalendarScheduledIcon,
  AppChevronLeftIcon,
  AppChevronRightIcon,
  CalendarAppIcon,
} from 'icons';
import { CustomColor, useCustomColor } from 'utils';

import { useAppTheme } from '../../hooks';

const Wrapper = styled.div<{ $backgroundColor: string } & CustomColor>`
  width: 100%;
  min-height: 100%;
  cursor: default;
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

const Calendar = styled.div<CustomColor>`
  width: 100%;
  padding: 11px 15px 13px;
  background-color: ${useCustomColor};
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
`;

const CalendarNav = styled.div`
  width: 100%;
  height: 22px;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${APP_FONT_16PX_MEDIUM};
`;
const ChevronCss = css`
  font-size: 24px;
  svg {
    fill: none !important;
  }
`;
const StyledChevronLeft = styled(AppChevronLeftIcon)`
  ${ChevronCss};
`;
const StyledChevronRight = styled(AppChevronRightIcon)`
  ${ChevronCss};
`;

const CalendarFooter = styled.div<CustomColor>`
  width: 100%;
  text-align: center;
  ${APP_FONT_13PX_MEDIUM};
  color: ${useCustomColor};
`;

const Grid = styled.div<{ rows: number; rowHeight: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: ${({ rows, rowHeight }) => `repeat(${rows}, ${rowHeight})`};
`;

const Cell = styled.div`
  ${APP_FONT_11PX_MEDIUM}
  margin: 0 auto;
`;

const Date = styled.div<{ $backgroundColor: string } & CustomColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  ${APP_FONT_16PX_REGULAR};
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 50%;
  position: relative;
`;

const CalendarMark = styled.div<CustomColor>`
  position: absolute;
  top: -2px;
  right: -5px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  color: #fff;
  background-color: ${useCustomColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MissedIcon = styled(CalendarMissedIcon)`
  font-size: 7px;
`;
const WatchedIcon = styled(CalendarCompleteIcon)`
  font-size: 8px;
`;
const ScheduledIcon = styled(CalendarScheduledIcon)`
  font-size: 7px;
  padding-bottom: 2px;
`;

const JournalMark = styled(CalendarMark)`
  border-radius: 0;
  width: 66%;
  left: 16%;
  top: unset;
  bottom: 2px;
  height: 2px;
`;

const BottomSection = styled.div<CustomColor>`
  width: 100%;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${useCustomColor};
`;

const BottomSectionDate = styled.div`
  ${APP_FONT_17PX_REGULAR};
  margin-bottom: 52px;
`;

const StyledCalendarIcon = styled(CalendarAppIcon)`
  font-size: 84px;
`;

const ScheduleLabel = styled.div`
  ${APP_FONT_17PX_REGULAR};
  margin: 17px 0 10px;
`;
const ScheduleDescription = styled.div<CustomColor>`
  ${APP_FONT_13PX_REGULAR};
  color: ${useCustomColor};
  text-align: center;
  width: 270px;
`;

const dayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const dateArr = Array.from({ length: 31 }, (_, i) => i + 1);
dateArr.push(...dateArr.slice(0, 4));

export const CalendarView = () => {
  const { properties } = useLocalAppProperties();
  const { getDesignProperty } = useAppTheme();
  const HighlightHEX = getDesignProperty('highlightHEX') as string;
  const PrimaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const SecondaryItemHEX = getDesignProperty('secondaryItemHEX') as string;
  const DisabledItemHEX = getDesignProperty('disabledItemHEX') as string;
  const BackgroundHEX = getDesignProperty('backgroundHEX') as string;
  const PrimaryElevationHEX = getDesignProperty('primaryElevationHEX') as string;

  const isMonthView = properties.CalendarInitialViewOnLoad === 'Month';

  const visibleDates = isMonthView ? dateArr : dateArr.slice(21, 28);

  const getMark = useCallback(
    (n: number) => {
      switch (n) {
        case 23:
          return properties.ShowJournal === '1' ? <JournalMark $color={properties.JournalHEX ?? '#ffffff'} /> : null;
        case 24:
          return (
            <CalendarMark $color={properties.MissedHEX ?? '#FF4242'}>
              <MissedIcon />
            </CalendarMark>
          );
        case 25:
          return (
            <CalendarMark $color={properties.WatchedHEX ?? '#76C282'}>
              <WatchedIcon />
            </CalendarMark>
          );
        case 27:
          return (
            <CalendarMark $color={properties.ScheduledHEX ?? '#518CFD'}>
              <ScheduledIcon />
            </CalendarMark>
          );
      }
      return null;
    },
    [properties],
  );

  return (
    <Wrapper $color={PrimaryItemHEX} $backgroundColor={BackgroundHEX}>
      <Calendar $color={PrimaryElevationHEX}>
        <CalendarNav>
          <StyledChevronLeft />
          January
          <StyledChevronRight />
        </CalendarNav>
        <Grid rows={1} rowHeight="24px">
          {dayArr.map((d) => (
            <Cell key={d}>{d}</Cell>
          ))}
        </Grid>
        <Grid rows={isMonthView ? 5 : 1} rowHeight="44px">
          {visibleDates.map((n, idx) => (
            <Cell key={idx}>
              <Date
                $color={n === 25 ? '#FEFEFE' : idx > 30 ? DisabledItemHEX : PrimaryItemHEX}
                $backgroundColor={n === 25 ? HighlightHEX : 'transparent'}
              >
                {n}
                {getMark(n)}
              </Date>
            </Cell>
          ))}
        </Grid>
        <CalendarFooter $color={HighlightHEX}>{`Show ${isMonthView ? 'week' : 'month'}`}</CalendarFooter>
      </Calendar>
      <BottomSection $color={PrimaryItemHEX}>
        <BottomSectionDate>25 January</BottomSectionDate>
        <StyledCalendarIcon />
        <ScheduleLabel>Schedule</ScheduleLabel>
        <ScheduleDescription $color={SecondaryItemHEX}>
          To schedule a video, tap the calendar icon on the video thumbnail
        </ScheduleDescription>
      </BottomSection>
    </Wrapper>
  );
};
