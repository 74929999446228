import { putAccountDetails, useAxiosInstance } from '../../../../../api';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

export const useUpdateAccount = () => {
  const client = useAxiosInstance();
  return useMutation<unknown, AxiosError, { FirstName: string; LastName: string }>(async (payload) => {
    await putAccountDetails(client, payload);
  });
};
