import styled, { css } from 'styled-components';
import {
  CALENDAR_TEMPLATE,
  COMMUNITY_TEMPLATE,
  DEFAULT_TAB_ICONS,
  DEFAULT_TAB_NAMES,
  FILTER_TEMPLATE,
  MIXED_TEMPLATE,
  MY_LISTS_TEMPLATE,
  MY_PLAYLISTS_TEMPLATE,
  PROFILE_TEMPLATE,
  TAB_ICON_URL,
  WEBSITE_TEMPLATE,
} from 'api';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_8_COLOUR,
} from 'theme';
import { FONT_12PX_REGULAR, FONT_14PX_SEMIBOLD } from 'font';

const Card = styled.div<{ $disabled?: boolean; $selected?: boolean }>`
  padding: 16px;
  width: 285px;
  height: 124px;

  border-radius: 8px;
  border: 1px solid ${NEUTRAL_5_COLOUR};
  cursor: pointer;

  :hover {
    background-color: ${NEUTRAL_3_COLOUR};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
      border-width: 2px;
      cursor: default;

      :hover {
        background-color: unset;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background: ${NEUTRAL_3_COLOUR}; // Disabled
      opacity: 0.6;
      cursor: default;
      :hover {
        background-color: ${NEUTRAL_3_COLOUR};
      }
    `}
`;
const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-bottom: 12px;
`;
const Title = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
  margin-bottom: 4px;
`;
const Desc = styled.div`
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_12PX_REGULAR};
`;

interface AddTabOptionProps {
  templateId: number;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
}

const getIconUrl = (templateId: number) => {
  return `${TAB_ICON_URL}${DEFAULT_TAB_ICONS[templateId]}`;
};

const DESCS: Record<number, string> = {
  [MIXED_TEMPLATE]: 'Create a customized layout from your content.',
  [WEBSITE_TEMPLATE]: 'Link a tab to any webpage via its URL and have it display in your app.',
  [MY_PLAYLISTS_TEMPLATE]: 'Users can create custom playlists to save favorite posts, lessons and videos.',
  [MY_LISTS_TEMPLATE]: 'Users can save, favorite and download post, lessons and videos.',
  [CALENDAR_TEMPLATE]: 'In-app calendar for users to schedule and track days they complete a lesson.',
  [FILTER_TEMPLATE]: 'Allow users to search and filter your content.',
  [PROFILE_TEMPLATE]: 'Allow users to manage their profile and account settings.',
  [COMMUNITY_TEMPLATE]: 'Build a thriving community with a global feed of posts, comments and likes.',
};

export const AddTabOption = ({ templateId, onClick, selected, disabled, ...props }: AddTabOptionProps) => {
  return (
    <Card onClick={disabled ? undefined : onClick} $selected={selected} $disabled={disabled} {...props}>
      <Icon src={getIconUrl(templateId)} />
      <Title>{DEFAULT_TAB_NAMES[templateId]}</Title>
      <Desc>{DESCS[templateId]}</Desc>
    </Card>
  );
};
