import { QueryClient, QueryClientProvider } from 'react-query';
import styled from 'styled-components';
import { AxiosInstance } from 'axios';

import { AppBeingEditedProvider } from 'app-context';
import { AxiosProvider } from 'api';
import { NEUTRAL_3_COLOUR } from 'theme';

const queryClient = new QueryClient();

const Container = styled.div`
  background-color: ${NEUTRAL_3_COLOUR};
`;

interface StorybookPageProps {
  appBeingEdited?: string;
  mockAxiosInstance: AxiosInstance;
  children: React.ReactNode;
}

export const StorybookPage = ({
  appBeingEdited = 'DaveTestApp',
  mockAxiosInstance,
  children,
  ...props
}: StorybookPageProps) => (
  <QueryClientProvider client={queryClient}>
    <Container id="react-app" className="react-app react-container" {...props}>
      <AppBeingEditedProvider appBeingEdited={appBeingEdited}>
        <AxiosProvider axiosInstance={mockAxiosInstance}>{children}</AxiosProvider>
      </AppBeingEditedProvider>
    </Container>
  </QueryClientProvider>
);
