import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';

import { Modal, SettingsFormItem, SettingsTextInput, CustomButton, SettingsGroup } from 'components';
import { DANGER_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_9_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { FONT_10PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { validateEmail } from 'utils';
import { PlusIcon } from 'icons';

import { useAccountExists } from '../hooks/useAccountExists';
import { useAddAccount } from '../hooks/useAddAccount';
import { useAppAccounts } from '../hooks/useAppAccounts';

const ModalHeader = styled.div`
  padding: 24px 36px 20px 36px;
  display: flex;
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
`;
const Heading = styled.span`
  ${FONT_16PX_MEDIUM};
  line-height: 32px; // match actions height
  color: ${NEUTRAL_9_COLOUR};
`;
const ModalForm = styled(Form)`
  &&&.ant-form {
    padding: 36px 36px 10px 36px;
    width: 100%;
  }
`;
const ModalActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 36px 28px 36px;
`;

const SubtitleWrapper = styled.div`
  height: 20px;
`;

const Subtitle = styled.div`
  color: ${NEUTRAL_7_COLOUR};
  ${FONT_10PX_REGULAR};
`;
const ErrorSubtitle = styled(Subtitle)`
  color: ${DANGER_COLOUR};
`;
const SpacedButton = styled(CustomButton)`
  &&& {
    margin-right: 8px;
  }
`;

const validatorForEmail = (_: unknown, value: string) => {
  if (value && value.includes('@vidapp.com')) {
    return Promise.reject('VidApp employees can’t be added to specific apps.');
  }
  return Promise.resolve();
};

interface FormValues {
  FirstName: string;
  LastName: string;
  Email: string;
}

export const Add = () => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm<FormValues>();

  const [email, setEmail] = useState<string | undefined>(undefined);
  const { data: accountExists } = useAccountExists(email);
  const addAccount = useAddAccount();
  const { data } = useAppAccounts();

  const handleClose = () => {
    form.resetFields();
    setEmail(undefined);
    setVisible(false);
  };

  useEffect(() => {
    if (accountExists) {
      form.setFieldsValue(accountExists);
    }
  }, [!!accountExists]);

  const handleValueChange = (values: FormValues) => {
    if (values.Email) {
      // Track Email in a state-variable to update check on whether the account exists
      setEmail(values.Email);
    }
  };

  const handleSubmit = (values: unknown) => {
    // Workaround for TS issue
    const { Email, FirstName, LastName } = values as FormValues;
    if (!validateEmail(Email)) {
      form.setFields([
        {
          name: 'Email',
          errors: ['Please enter a valid email address'],
        },
      ]);
      return;
    }

    const existing = data?.Accounts.find((account) => account.Email === Email);
    if (existing) {
      form.setFields([
        {
          name: 'Email',
          errors: ['A user with this email already exists in this app.'],
        },
      ]);
      return;
    }

    if (!!accountExists && Email) {
      console.info('Adding access to existing account');
      addAccount.mutate({ Email }, { onSuccess: handleClose });
    } else {
      console.info('Adding new account');
      addAccount.mutate({ Email, FirstName, LastName }, { onSuccess: handleClose });
    }
  };

  return (
    <>
      <CustomButton medium icon={<PlusIcon />} onClick={() => setVisible(true)}>
        Add Team Member
      </CustomButton>
      <Modal
        open={visible}
        onCancel={handleClose}
        $height="fit-content"
        $padding="0"
        maskClosable={false}
        width="fit-content"
      >
        <ModalHeader>
          <Heading>Add Team Member</Heading>
        </ModalHeader>
        <ModalForm form={form} onValuesChange={handleValueChange} onFinish={handleSubmit}>
          <SettingsGroup title="Details" wrapRow>
            <SettingsFormItem
              name="Email"
              marginBottom="30px"
              validateTrigger="onChange"
              rules={[
                {
                  validator: validatorForEmail,
                },
                {
                  required: true,
                  message: 'Enter email address',
                },
              ]}
            >
              <SettingsTextInput placeholder="Email" label="Email" />
            </SettingsFormItem>
            <SettingsFormItem name="FirstName" width="49%" rules={[{ required: true, message: 'Enter first name' }]}>
              <SettingsTextInput placeholder="First Name" label="First Name" disabled={!!accountExists} />
            </SettingsFormItem>
            <SettingsFormItem name="LastName" width="49%" rules={[{ required: true, message: 'Enter last name' }]}>
              <SettingsTextInput placeholder="Last Name" label="Last Name" disabled={!!accountExists} />
            </SettingsFormItem>
          </SettingsGroup>
          <SubtitleWrapper>
            {addAccount.error?.response ? (
              <ErrorSubtitle>{addAccount.error.response.data ?? 'An unexpected error has occurred'}</ErrorSubtitle>
            ) : (
              accountExists && (
                <Subtitle>{"This email already exists in our system. Click 'Add' to continue."}</Subtitle>
              )
            )}
          </SubtitleWrapper>
        </ModalForm>
        <ModalActions>
          <SpacedButton tertiary onClick={handleClose}>
            Cancel
          </SpacedButton>
          <CustomButton iconPosition="right" onClick={() => form.submit()} loading={addAccount.isLoading}>
            Add
          </CustomButton>
        </ModalActions>
      </Modal>
    </>
  );
};
