import { createContext, ReactNode, useContext } from 'react';
import { DragUpdate } from 'react-beautiful-dnd';

const DragContext = createContext<ContextValue | undefined>(undefined);

interface ContextValue {
  dragUpdate?: DragUpdate;
}

interface DragContextProviderProps extends ContextValue {
  children: ReactNode | ReactNode[];
}

const DragContextProvider = ({ children, dragUpdate }: DragContextProviderProps) => {
  return <DragContext.Provider value={{ dragUpdate }}>{children}</DragContext.Provider>;
};

const useDragContext = () => {
  const context = useContext(DragContext);
  if (context === undefined) {
    throw new Error('useDragContext must be used within a DragContextProvider');
  }

  return context as ContextValue;
};

export { DragContextProvider, useDragContext };
