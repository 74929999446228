import { useCallback } from 'react';

import { useSaveContext, useLocalProducts, BuilderProduct } from 'providers';
import { SettingsSelectInput, SettingsTextInput, SettingsSegment } from 'components';
import {
  SOURCE_ACTIVEMEMBER360,
  SOURCE_CUSTOMAPI,
  SOURCE_JWPLAYER,
  SOURCE_KAJABI,
  SOURCE_MEMBERMOUSE,
  SOURCE_MEMBERPRESS,
  SOURCE_SHOPIFY,
  SOURCE_TEACHABLE,
  SOURCE_THINKIFIC,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_WISHLIST,
  SOURCE_WOOCOMMERCEMEMBERSHIPS,
  SOURCE_WOOCOMMERCESUBSCRIPTIONS,
} from 'api';
import { getDataSourceDisplayName } from 'utils';

const options = [
  SOURCE_VIDAPP,
  SOURCE_VHX,
  SOURCE_MEMBERPRESS,
  SOURCE_USCREEN,
  SOURCE_JWPLAYER,
  SOURCE_SHOPIFY,
  SOURCE_TEACHABLE,
  SOURCE_THINKIFIC,
  SOURCE_ACTIVEMEMBER360,
  SOURCE_WISHLIST,
  SOURCE_WOOCOMMERCESUBSCRIPTIONS,
  SOURCE_WOOCOMMERCEMEMBERSHIPS,
  SOURCE_MEMBERMOUSE,
  SOURCE_CUSTOMAPI,
];

export const OfferDataSource = ({ Id, DataSource }: Pick<BuilderProduct, 'Id' | 'DataSource'>) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();

  const handleSelectChange = useCallback(
    (value: string) => {
      setProductValue(Id, 'DataSource', value);
      setProductValueToSave(Id, 'DataSource', value);
    },
    [setProductValue, setProductValueToSave, Id],
  );

  return (
    <SettingsSegment title="Data Source">
      {DataSource === SOURCE_KAJABI ? (
        <SettingsTextInput value={DataSource} $marginTop="0" disabled />
      ) : (
        <SettingsSelectInput
          options={options.map((dataSource) => ({ name: getDataSourceDisplayName(dataSource), value: dataSource }))}
          defaultVal={DataSource}
          onChange={handleSelectChange}
        />
      )}
    </SettingsSegment>
  );
};
