import { useCallback } from 'react';
import { Form } from 'antd';

import { MY_PLAYLISTS_TEMPLATE, APP_PROPERTY_DEFAULTS } from 'api';

import { useTabSettings } from 'app/modules/build-screens/hooks';

import { NavigationHeader, TabBar } from '../components';
import { getPageName } from '../utils';
import {
  ContentAdvancedSettings,
  Segment,
  SettingsCheckboxInput,
  SettingsFormItem,
  DrawerSettingsGroup,
  SettingsTextInput,
} from 'components';
import { PLAYLIST_MAX } from '../const';
import { DrawerSeparator } from './components/DrawerSeparator';
import { useContent } from 'providers';
import { TabDrawer } from '../components/Common/TabDrawer';

const PLAYLISTS = [
  {
    heading: APP_PROPERTY_DEFAULTS['CustomFavoritesLabel'],
    checkboxName: 'HideSidebarFavourites',
    name: 'Favorites',
  },
  {
    heading: APP_PROPERTY_DEFAULTS['CustomWatchlistLabel'],
    name: 'Watchlist',
  },
  {
    heading: APP_PROPERTY_DEFAULTS['CustomDownloadsLabel'],
    name: 'Downloads',
  },
  {
    heading: APP_PROPERTY_DEFAULTS['CustomAudioDownloadsLabel'],
    name: 'AudioDownloads',
  },
];

interface MyListsTabDrawerProps {
  tabId: number;
}

export const MyListsTabDrawer = ({ tabId }: MyListsTabDrawerProps) => {
  const [form] = Form.useForm();
  const { collections } = useContent();
  const tab = collections[tabId];

  // The declared variables are managed in useTabSettings
  const { onValuesChange } = useTabSettings(
    {
      NavBarTitleText: { type: 'collection', valueType: 'string' },
      Name: { type: 'collection', valueType: 'string' },
      LockedUntilSignIn: { type: 'collectionProperty', valueType: 'binary' },
      Icon: { type: 'collection', valueType: 'string' },
      HideSidebarFavourites: { type: 'app', valueType: 'inverseBinary' },
      CustomFavoritesLabel: {
        type: 'app',
        valueType: 'string',
        defaultVal: APP_PROPERTY_DEFAULTS['CustomFavoritesLabel'],
      },
      HideSidebarWatchlist: { type: 'app', valueType: 'inverseBinary' },
      CustomWatchlistLabel: {
        type: 'app',
        valueType: 'string',
        defaultVal: APP_PROPERTY_DEFAULTS['CustomWatchlistLabel'],
      },
      HideSidebarDownloads: { type: 'app', valueType: 'inverseBinary' },
      CustomDownloadsLabel: {
        type: 'app',
        valueType: 'string',
        defaultVal: APP_PROPERTY_DEFAULTS['CustomDownloadsLabel'],
      },
      HideSidebarAudioDownloads: { type: 'app', valueType: 'inverseBinary' },
      CustomAudioDownloadsLabel: {
        type: 'app',
        valueType: 'string',
        defaultVal: APP_PROPERTY_DEFAULTS['CustomAudioDownloadsLabel'],
      },
      CustomRecentlyWatchedLabel: {
        type: 'app',
        valueType: 'string',
        defaultVal: APP_PROPERTY_DEFAULTS['CustomRecentlyWatchedLabel'],
      },
      CustomMyPlaylistsLabel: {
        type: 'app',
        valueType: 'string',
        defaultVal: APP_PROPERTY_DEFAULTS['CustomMyPlaylistsLabel'],
      },
    },
    tabId,
    form,
  );

  const onTabIconFilenameChange = useCallback(
    (_filename) => {
      onValuesChange({ Icon: _filename }, {}, true);
    },
    [onValuesChange],
  );

  return (
    <TabDrawer tabId={tabId}>
      <Form
        name="settings-form"
        onValuesChange={(changedValues) => {
          onValuesChange(changedValues);
        }}
        autoComplete="off"
        form={form}
      >
        <NavigationHeader tabId={tabId} placeholder={getPageName(tab)} />
        <DrawerSeparator />
        <Segment header="List Titles">
          {PLAYLISTS.map(({ name, heading, checkboxName }) => (
            <DrawerSettingsGroup key={name} heading={heading}>
              <SettingsCheckboxInput label={`Show ${heading}`} name={checkboxName ?? `HideSidebar${name}`} />
              <SettingsFormItem name={`Custom${name}Label`}>
                <SettingsTextInput size="middle" maxLength={PLAYLIST_MAX} placeholder={heading} />
              </SettingsFormItem>
            </DrawerSettingsGroup>
          ))}
          {tab.TemplateId === MY_PLAYLISTS_TEMPLATE && (
            <>
              <DrawerSettingsGroup heading="Recently Watched">
                <SettingsFormItem name="CustomRecentlyWatchedLabel">
                  <SettingsTextInput
                    size="middle"
                    maxLength={PLAYLIST_MAX}
                    placeholder={APP_PROPERTY_DEFAULTS['CustomRecentlyWatchedLabel']}
                  />
                </SettingsFormItem>
              </DrawerSettingsGroup>
              <DrawerSettingsGroup heading="My Playlists">
                <SettingsFormItem name="CustomMyPlaylistsLabel">
                  <SettingsTextInput
                    size="middle"
                    maxLength={PLAYLIST_MAX}
                    placeholder={APP_PROPERTY_DEFAULTS['CustomMyPlaylistsLabel']}
                  />
                </SettingsFormItem>
              </DrawerSettingsGroup>
            </>
          )}
        </Segment>
        <DrawerSeparator />
        <ContentAdvancedSettings collection={tab} isDrawer isTab />
        <TabBar filename={tab.Icon} placeholder={getPageName(tab)} onFilenameChange={onTabIconFilenameChange} />
      </Form>
    </TabDrawer>
  );
};
