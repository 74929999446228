import { useCallback, useMemo } from 'react';

import { useSaveContext, useLocalProducts, BuilderProduct } from 'providers';
import { SettingsSelectInput, SettingsSegment } from 'components';
import { useApplePriceTiers } from 'hooks/useApplePriceTiers';

interface SubscriptionPriceProps {
  title?: string;
}

export const SubscriptionPrice = ({
  Id,
  PriceTier,
  title,
}: Pick<BuilderProduct, 'Id' | 'PriceTier'> & SubscriptionPriceProps) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();
  const { data: priceTiers } = useApplePriceTiers();

  const options = useMemo(() => {
    const tierOptions = priceTiers
      ? priceTiers.map(({ USD, Tier }) => ({ name: `$${parseFloat(USD)}`, value: Tier.toString() }))
      : [];

    return [
      {
        name: 'Not for individual purchase',
        value: '-1',
      },
      {
        name: 'Free',
        value: '0',
      },
      ...tierOptions,
    ];
  }, [priceTiers]);

  const handleSelectChange = useCallback(
    (value: string) => {
      setProductValue(Id, 'PriceTier', value);
      setProductValueToSave(Id, 'PriceTier', value);
    },
    [setProductValue, setProductValueToSave, Id],
  );

  return (
    <SettingsSegment title={title ?? 'Subscription Price'}>
      <SettingsSelectInput options={options} defaultVal={PriceTier} onChange={handleSelectChange} />
    </SettingsSegment>
  );
};
