import styled from 'styled-components';
import { SettingsInputWrapper } from './SettingsInputWrapper';
import { FONT_12PX_REGULAR, FONT_12PX_SEMIBOLD } from '../../font';
import { NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from '../../theme';
import { CustomRadioButtonsGroup } from './CustomRadioButtonsGroup';
import { Radio } from 'antd';
import { SettingsRadioInputProps } from './SettingsRadioInput';
import { ReactNode } from 'react';

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioButtonLabel = styled.span`
  padding-bottom: 4px;
  ${FONT_12PX_SEMIBOLD};
  color: ${NEUTRAL_9_COLOUR};
`;
const RadioButtonSubtitle = styled.div`
  ${FONT_12PX_REGULAR};
  color: ${NEUTRAL_7_COLOUR};
`;

const RadioText = styled.div<{ itemWidth?: string; itemPadding?: string }>`
  #react-app && {
    width: ${(props) => props.itemWidth || '153px'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: ${({ itemPadding }) => itemPadding || '12px'};
  }
`;

interface CustomOption {
  name: string;
  subtitle: string;
  value: string | number;
  node: ReactNode | ReactNode[];
}

interface CustomRadioProps extends SettingsRadioInputProps {
  itemWidth?: string;
  itemPadding?: string;
  options: CustomOption[];
  value: string;
  width?: string;
}

export const CustomRadio = ({ options, itemWidth, itemPadding, width, ...props }: CustomRadioProps) => {
  return (
    <SettingsInputWrapper width={width}>
      <CustomRadioButtonsGroup defaultValue={options[0].value} {...props}>
        {options.map((option) => (
          <RadioWrapper key={option.name}>
            {option.node}
            <RadioText itemWidth={itemWidth} itemPadding={itemPadding}>
              <Radio value={option.value}>
                <RadioButtonLabel>{option.name}</RadioButtonLabel>
              </Radio>
              <RadioButtonSubtitle>{option.subtitle}</RadioButtonSubtitle>
            </RadioText>
          </RadioWrapper>
        ))}
      </CustomRadioButtonsGroup>
    </SettingsInputWrapper>
  );
};
