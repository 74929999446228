import { Credentials } from '../app/modules/self-onboarding/OnboardingWizard/components';
import { AxiosInstance } from 'axios';

import { getUserEmail } from 'utils';
import { ETL_URL } from './const';
import { SyncOptions } from './onboarding';

export const validateKajabiAccount = (axiosInstance: AxiosInstance, appId: string, credentials: Credentials) => {
  return axiosInstance.post(`tunnel/${appId}`, {
    HttpPath: `app/${appId}/validate-account`,
    HttpMethod: 'POST',
    HttpPayload: credentials,
    ServiceName: 'kajabi_integration',
  });
};

export interface KajabiProduct {
  position: number;
  id: number;
  title: string;
  image: string; // URL of the product thumbnail
}

export const getKajabiProducts = (axiosInstance: AxiosInstance, appId: string) => {
  return axiosInstance
    .post(`tunnel/${appId}`, {
      HttpPath: `app/${appId}/products`,
      HttpMethod: 'GET',
      ServiceName: 'kajabi_integration',
    })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const syncKajabiProduct = (
  axiosInstance: AxiosInstance,
  appId: string,
  productId: string,
  { themeId, syncToApp, isOnboarding }: SyncOptions,
) => {
  // Post call to extract Kajabi product
  const payload: {
    AppId: string;
    SourceId: string;
    DataSource: string;
    Initiator: string;
    TaskID?: string;
    SourceType?: string;
    KajabiDataThemeId?: number;
    SyncToApp: boolean;
    IsOnboarding: boolean;
  } = {
    AppId: appId,
    SourceId: productId,
    DataSource: 'Kajabi',
    Initiator: getUserEmail(),
    SyncToApp: syncToApp ?? false,
    IsOnboarding: isOnboarding ?? false,
  };
  return axiosInstance
    .get(`sync-tracing/${appId}/uuid`)
    .then((response) => {
      const taskID = response.data;
      if (taskID) {
        payload.TaskID = taskID;
        payload.SourceType = 'Product';
      }
      if (themeId) {
        payload.KajabiDataThemeId = themeId;
      }
      return axiosInstance.post(`${ETL_URL}/extract-data-for-app`, payload).then((response) => response.data);
    })
    .catch((err) => {
      console.error(err);
      return;
    });
};
