import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR, PRIMARY_ORANGE_COLOUR } from 'theme';
import { CollectionBasicInfo } from 'api';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { UISyncIcon } from 'icons';
import { useSyncApp } from 'hooks';

import { FONT_16PX_MEDIUM, FONT_24PX_BOLD } from '../../font';
import { BackButton, Footer, NeedHelp, NextButton } from '../Common';
import { SplitPanel, StepPanel } from '../Panels';
import { Product } from './Product';
import { useListCollections } from '../../hooks/useListCollections';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

const Header = styled.span`
  margin-top: 5px;
  ${FONT_24PX_BOLD};
  color: ${NEUTRAL_9_COLOUR};
`;
const Subtitle = styled.span`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_7_COLOUR};
  margin-left: 8px;
`;
const ProductsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RightFooter = styled(Footer)`
  justify-content: space-between;
`;

const padProducts = (products: CollectionBasicInfo[]) => {
  if (products.length < 9) {
    products = products.concat(Array(9 - products.length).fill({}));
  }
  return products;
};

export const VimeoOTTProductsStep = () => {
  const { navigateForward } = useWizardNavigation();
  const [syncing, setSyncing] = useState<boolean>(false);
  const LIMIT = 100;
  const { data: products } = useListCollections(LIMIT);
  const syncApp = useSyncApp({ syncToApp: true, isOnboarding: true });

  const numProducts = Math.min(products?.length || 0, 9);
  const totalProducts = products?.length === LIMIT ? `${LIMIT}+` : products?.length ?? '0';

  const handleSync = useCallback(async () => {
    setSyncing(true);
    syncApp.mutate({});
    // Let the syncs run async and continue on without them
    setTimeout(() => {
      navigateForward();
    }, 2000);
  }, [navigateForward, setSyncing, products]);

  // Pad products out to 9 products if there are less than that to maintain the correct layout
  const paddedProducts = useMemo(() => (products ? padProducts(products) : []), products);

  return (
    <SplitPanel
      leftContent={
        <>
          <StepPanel
            header="Sync your Vimeo OTT content."
            body={
              <>
                Automatically sync your Vimeo OTT
                <br />
                content with VidApp.
              </>
            }
            icon={<img src={`${BUILDER_IMAGES_DOMAIN}/onboarding/wizard_icons/sync.webp`} alt="user icon" />}
          />
          <Footer>
            <NeedHelp $color={PRIMARY_ORANGE_COLOUR} />
          </Footer>
        </>
      }
      rightContent={
        <>
          <div>
            <Header>Collections</Header>
            <Subtitle>
              (Displaying {numProducts} of {totalProducts})
            </Subtitle>
          </div>
          <ProductsWrapper>
            {paddedProducts?.slice(0, 9).map((product) => (
              <Product key={product.URI} {...product} />
            ))}
          </ProductsWrapper>
          <RightFooter>
            <BackButton />
            <NextButton
              onClick={handleSync}
              $type={syncing ? 'success' : undefined}
              loading={syncing}
              icon={!syncing && <UISyncIcon style={{ fontSize: '20px', height: '20px' }} />}
            >
              {!syncing ? 'Sync' : 'Syncing'}
            </NextButton>
          </RightFooter>
        </>
      }
    />
  );
};
