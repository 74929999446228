import { useCallback } from 'react';

import { SegmentProps, SettingsAlternateThumbnailInput, SettingsThumbnailInput } from 'components';
import { BuilderVideo, useContent, useSaveContext } from 'providers';

interface SettingsVideoThumbnailProps extends SegmentProps {
  video: BuilderVideo;
  width?: string;
  height?: string;
  alternateOrientation?: 'row' | 'column';
  squareWidth?: string;
  portraitWidth?: string;
  portraitHeight?: string;
  hideAdditionalThumbnails?: boolean;
}

export const SettingsVideoThumbnail = ({
  video,
  alternateOrientation,
  width,
  height,
  portraitHeight,
  portraitWidth,
  squareWidth,
  hideAdditionalThumbnails,
  ...props
}: SettingsVideoThumbnailProps) => {
  const { setVideoValue } = useContent();
  const { setVideoValueToSave } = useSaveContext();

  const isRevertable = video?.ThumbnailSource && video?.ThumbnailSource != video?.SourceThumbnailSource;
  const isSquareRevertable =
    video?.SquareThumbnailSource && video?.SquareThumbnailSource != video?.SourceSquareThumbnailSource;
  const isPortraitRevertable =
    video?.PortraitThumbnailSource && video?.PortraitThumbnailSource != video?.SourcePortraitThumbnailSource;

  const filename = video.Thumbnails?.large || video.ThumbnailSource || video.SourceThumbnailSource;
  const squareFilename =
    video.SquareThumbnails?.large || video.SquareThumbnailSource || video.SourceSquareThumbnailSource;
  const portraitFilename =
    video.PortraitThumbnails?.large || video.PortraitThumbnailSource || video.SourcePortraitThumbnailSource;

  const handleThumbnailChange = useCallback(
    (filename: string) => {
      if (video) {
        setVideoValue(video.VideoId, 'ThumbnailSource', filename);
        setVideoValue(video.VideoId, 'Thumbnails', { source: filename });
        setVideoValueToSave(video.VideoId, 'ThumbnailSource', filename);
      }
    },
    [setVideoValue, setVideoValueToSave, video],
  );
  const handleSquareThumbnailChange = useCallback(
    (f: string) => {
      if (video) {
        setVideoValue(video.VideoId, 'SquareThumbnailSource', f);
        setVideoValue(video.VideoId, 'SquareThumbnails', { source: f });
        setVideoValueToSave(video.VideoId, 'SquareThumbnailSource', f);
      }
    },
    [setVideoValue, setVideoValueToSave, video],
  );
  const handlePortraitThumbnailChange = useCallback(
    (f: string) => {
      if (video) {
        setVideoValue(video.VideoId, 'PortraitThumbnailSource', f);
        setVideoValue(video.VideoId, 'PortraitThumbnails', { source: f });
        setVideoValueToSave(video.VideoId, 'PortraitThumbnailSource', f);
      }
    },
    [setVideoValue, setVideoValueToSave, video],
  );

  const handleRevertThumbnail = useCallback(() => {
    if (video) {
      setVideoValue(video.VideoId, 'ThumbnailSource', '');
      setVideoValue(video.VideoId, 'Thumbnails', {});
      setVideoValueToSave(video.VideoId, 'ThumbnailSource', '');
    }
  }, [setVideoValue, setVideoValueToSave, video]);
  const handleRevertSquare = useCallback(() => {
    if (video) {
      setVideoValue(video.VideoId, 'SquareThumbnailSource', '');
      setVideoValue(video.VideoId, 'SquareThumbnails', {});
      setVideoValueToSave(video.VideoId, 'SquareThumbnailSource', '');
    }
  }, [setVideoValue, setVideoValueToSave, video]);
  const handleRevertPortrait = useCallback(() => {
    if (video) {
      setVideoValue(video.VideoId, 'PortraitThumbnailSource', '');
      setVideoValue(video.VideoId, 'PortraitThumbnails', {});
      setVideoValueToSave(video.VideoId, 'PortraitThumbnailSource', '');
    }
  }, [setVideoValue, setVideoValueToSave, video]);

  return (
    <>
      <SettingsThumbnailInput
        filename={filename}
        isRevertable={isRevertable}
        handleThumbnailChange={handleThumbnailChange}
        handleRevertThumbnail={handleRevertThumbnail}
        width={width}
        height={height}
        overrideHeader={hideAdditionalThumbnails ? 'Thumbnail' : undefined}
        $marginTop="0"
        {...props}
      />
      {!hideAdditionalThumbnails && (
        <SettingsAlternateThumbnailInput
          squareFilename={squareFilename}
          isSquareRevertable={isSquareRevertable}
          handleSquareThumbnailChange={handleSquareThumbnailChange}
          handleRevertSquareThumbnail={handleRevertSquare}
          portraitFilename={portraitFilename}
          isPortraitRevertable={isPortraitRevertable}
          handlePortraitThumbnailChange={handlePortraitThumbnailChange}
          handleRevertPortraitThumbnail={handleRevertPortrait}
          orientation={alternateOrientation ?? 'row'}
          portraitWidth={portraitWidth}
          portraitHeight={portraitHeight}
          squareWidth={squareWidth}
          {...props}
        />
      )}
    </>
  );
};
