import { BuilderCollection } from 'providers';
import styled from 'styled-components';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { NEUTRAL_7_COLOUR } from 'theme';

const Flex = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${NEUTRAL_7_COLOUR};

  padding-bottom: 100px;
`;
const Head = styled.div`
  margin-top: 12px;
  ${FONT_16PX_MEDIUM};
`;
const Sub = styled.div`
  margin-top: 4px;
  ${FONT_14PX_REGULAR};
  padding-left: 100px;
  padding-right: 100px;
`;

export const CommunityView = ({}: { tab?: BuilderCollection }) => {
  return (
    <Flex>
      <div>
        <Head>Community</Head>
        <Sub>Your community will display here in your live app.</Sub>
      </div>
    </Flex>
  );
};
