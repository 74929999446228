import { Content, List, Root, Trigger } from '@radix-ui/react-tabs';
import styled from 'styled-components';

import { FONT_14PX_MEDIUM, FONT_16PX_SEMIBOLD, FONT_24PX_SEMIBOLD } from 'font';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_SECONDARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_6_COLOUR,
  NEUTRAL_7_COLOUR,
} from 'theme';

const StyledList = styled(List)<{ $hideUnderline?: boolean }>`
  margin-bottom: 32px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${({ $hideUnderline }) => ($hideUnderline ? 'transparent' : NEUTRAL_5_COLOUR)};
`;

const StyledTrigger = styled(Trigger)<{ $large?: boolean; $hideUnderline?: boolean }>`
  &&& {
    /* Reset */
    all: unset;

    /* Tab styles */
    ${({ $large }) => ($large ? FONT_24PX_SEMIBOLD : FONT_16PX_SEMIBOLD)}
    margin-right: ${({ $large }) => ($large ? '20px' : '16px')};
    padding-bottom: 8px;
    transition: 0.3s ease color;
    cursor: pointer;

    /* Unselected tab */
    color: ${NEUTRAL_7_COLOUR};

    /* Selected tab */
    &[data-state='active'] {
      color: ${NEUTRAL_10_COLOUR};

      :after {
        content: '';
        display: ${({ $hideUnderline }) => ($hideUnderline ? 'none' : 'block')};
        width: 100%;
        border-bottom: 2px solid ${NEUTRAL_10_COLOUR};
        transform: translateY(10px);
      }
    }
  }
`;

const PillTrigger = styled(Trigger)`
  &&& {
    /* Reset */
    all: unset;

    /* Tab styles */
    height: 30px;
    ${FONT_14PX_MEDIUM};
    padding: 0 13px;
    transition-property: color, border;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    border-width: 1px;
    border-style: solid;
    border-radius: 15px;
    cursor: pointer;

    :not(:last-child) {
      margin-right: 8px;
    }

    /* Unselected tab */
    color: ${NEUTRAL_10_COLOUR};
    border-color: ${NEUTRAL_6_COLOUR};

    /* Selected tab */
    &[data-state='active'] {
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
      border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
      background-color: ${HIGHLIGHT_SECONDARY_COLOUR};
    }
  }
`;

export const Tabs = {
  Root,
  List: StyledList,
  Trigger: StyledTrigger,
  PillTrigger,
  PillList: List,
  Content,
};
