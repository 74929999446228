import styled from 'styled-components';
import { CUSTOM_SCROLLBAR } from 'theme';

/**
 * DrawerScrollContainer
 */
export const DrawerScrollContainer = styled.div<{ paddingBottom?: string; paddingLeft?: string }>`
  width: calc(100% - 4px);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding-top: 20px;
  padding-right: 14px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '40px'};
  padding-left: ${({ paddingLeft }) => paddingLeft || '28px'};
  margin-right: 4px;
  ${CUSTOM_SCROLLBAR};

  display: flex;
  flex-direction: column;
  // Grow to the available space, should be used inside Flex Column
  flex-grow: 1;
`;
