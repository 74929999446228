import styled from 'styled-components';

import { Segment, SegmentProps, SettingsRadioInput, SettingsTextInput } from 'components';
import { FONT_10PX_REGULAR, FONT_14PX_REGULAR } from 'font';
import { BuilderCollection } from 'providers';
import { NEUTRAL_7_COLOUR } from 'theme';

const StyledRadioInput = styled(SettingsRadioInput)`
  #react-app && {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    margin-top: 0;
    margin-bottom: 4px;

    label {
      margin-bottom: 8px;

      span {
        ${FONT_14PX_REGULAR};
        color: ${NEUTRAL_7_COLOUR};
      }
    }
  }
`;
const DripDescription = styled.div`
  color: #6f7783;
  ${FONT_10PX_REGULAR};
`;

interface CollectionDripProps {
  collection: BuilderCollection;
}

export const CollectionDrip = ({ collection, ...props }: CollectionDripProps & SegmentProps) => {
  return (
    <Segment header="Drip" {...props}>
      <StyledRadioInput
        disabled
        defaultVal={collection.Drip ?? 0}
        options={[
          { name: 'Published', value: 0 },
          { name: 'Drip', value: 1 },
        ]}
      />
      <DripDescription>
        This category will become available for your members on a specified number of days after they acquire the
        product.
      </DripDescription>
      {collection.Drip === 1 && (
        <Segment header="Number of days" {...props} $marginTop="20px">
          <SettingsTextInput
            size="middle"
            disabled
            value={collection.DripDays ? collection.DripDays.toString() : '0'}
          />
        </Segment>
      )}
    </Segment>
  );
};
