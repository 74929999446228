import { pad2 } from '../index';

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Get day based on date in short format
 * - e.g. (1, 9, 2020) => "Thu 1st Oct"
 * @param day - exact day
 * @param month - month of year (january = 0)
 * @param year
 */
export const getFormattedDate = (day: number, month: number, year: number, format = 'en-NZ') => {
  const dayShort = new Intl.DateTimeFormat(format, { weekday: 'short' }).format(new Date(year, month, day));
  const dayOrdinal = `${day}${getOrdinalOfDay(day)}`;
  const monthShort = new Intl.DateTimeFormat(format, { month: 'short' }).format(new Date(year, month, day));
  return `${dayShort} ${dayOrdinal} ${monthShort}`;
};

/**
 * Get the ordinal value if a day number (e.g. 1 -> '1st')
 * @param day
 */
export const getOrdinalOfDay = (day: number) => {
  switch (day) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

/**
 * Get number of days within month of selected year
 * @param month - month of year (january = 0)
 * @param year
 */
export const getDaysInMonth = (month: number, year: number = new Date().getFullYear()) =>
  new Date(year, month + 1, 0).getDate();

/**
 * Convert no. of seconds to time in format HH:MM:SS
 * @param seconds - no. of seconds
 */
export const secondsToTime = (seconds: number, options?: { hideHours?: boolean; format?: 'h_min' }) => {
  const h = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0');
  const m = options?.hideHours
    ? Math.floor(seconds / 60)
        .toString()
        .padStart(2, '0')
    : Math.floor((seconds % 3600) / 60)
        .toString()
        .padStart(2, '0');
  const s = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');

  if (options?.format === 'h_min') {
    if (h === '00') {
      return `${m} min`;
    }
    return `${h} h ${m} min`;
  } else if (options?.hideHours || h === '00') {
    return `${m}:${s}`;
  }

  return `${h}:${m}:${s}`;
};

export const formatDateToDDMMYYYYHHMM = (date: Date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year}, ${hours}:${minutes}`;
};

export const formatNiceDate = (date: Date) => {
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  const day = pad2(date.getDate());
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};
