import styled, { css } from 'styled-components';
import { Typography } from 'antd';

import { APP_FONT_12PX_MEDIUM, APP_FONT_14PX_MEDIUM, APP_FONT_16PX_MEDIUM } from 'mockup-font';
import { OVERFLOW_ELLIPSIS } from 'font';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_1_COLOUR, NEUTRAL_4_COLOUR } from 'theme';
import {
  contentHasPreviewVideo,
  getCollectionName,
  getContentName,
  getContentOverlay,
  isSectionHeader,
  usePointerOnClick,
} from 'utils';
import { Collection, CollectionItem, Video } from 'api';
import { ChevronRightIcon, EllipsisIcon, ForwardIcon } from 'icons';
import { useAppProperties } from 'hooks';

import { THUMBNAIL_RADIUS, UNTITLED_BLOCK_NAME, UNTITLED_CONTENT_NAME } from 'app/modules/build-dragdrop/Builder/const';
import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import {
  BuilderCollection,
  BuilderCollectionItem,
  BuilderVideo,
  useContent,
} from 'app/modules/build-dragdrop/Builder/providers';
import { CollectionTitle, SectionHeaderItem, ShowMore } from 'app/modules/build-dragdrop/Builder/mockup/components';
import { useAppCollectionContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { useAppTheme, useCappedCollectionItems } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { isItemClickable } from 'app/modules/build-dragdrop/Builder/util';

const SMALL_THUMBNAIL_HEIGHT = '80px';

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const Item = styled.div<{ hasButton?: boolean }>`
  width: 100%;
  height: fit-content;
  position: relative;

  :not(:last-child) {
    margin-bottom: ${({ hasButton }) => !hasButton && '16px'};
  }

  // Large margins for list with button
  margin-bottom: ${({ hasButton }) => hasButton && '31px'};

  :last-child {
    margin-bottom: ${({ hasButton }) => hasButton && '15px'};
  }
`;
const DummyThumbnail = styled.div<{ isSmall?: boolean }>`
  width: ${({ isSmall }) => (isSmall ? '142px' : '100%')};
  height: ${({ isSmall }) => (isSmall ? SMALL_THUMBNAIL_HEIGHT : 'unset')};
  aspect-ratio: 16/9;
  background-color: ${NEUTRAL_4_COLOUR};
  border-radius: ${THUMBNAIL_RADIUS};
`;
// Large List
const StyledThumbnail = styled(ContentThumbnail)`
  width: 100%;
  height: unset;
  cursor: ${usePointerOnClick};
`;

const getOverlayStyle = (overlay?: boolean) => {
  if (overlay) {
    return css`
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;

      position: absolute;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(4px);
      color: white;
      height: 51px;
      width: 100%;
      border-bottom-left-radius: ${THUMBNAIL_RADIUS};
      border-bottom-right-radius: ${THUMBNAIL_RADIUS};
      pointer-events: none;
    `;
  }
  return css``;
};

interface TitleRowProps {
  $color: string;
  $overlay?: boolean;
}

const TitleRow = styled.div<TitleRowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  color: ${({ $color }) => $color};
  max-width: 100%;
  ${({ $overlay }) => getOverlayStyle($overlay)};
`;

const ItemTitle = styled.div`
  width: 100%;
  ${APP_FONT_16PX_MEDIUM};
  ${OVERFLOW_ELLIPSIS};
  color: inherit;
`;

const StyledForwardIcon = styled(ForwardIcon)<{ $overlay?: boolean }>`
  font-size: 24px;
  margin-right: ${({ $overlay }) => $overlay && '-12px'};
`;

// Small List
const SmallRow = styled.div<TitleRowProps>`
  display: flex;
  height: ${SMALL_THUMBNAIL_HEIGHT};
  color: ${({ $color }) => $color};
`;

const CenterColumn = styled.div`
  display: flex;
  align-items: center;
`;
const SmallTitle = styled(Typography.Paragraph)`
  &&&& {
    padding: 0 12px;
    width: 175px;
    ${APP_FONT_14PX_MEDIUM};
    max-height: 36px; // 2 lines
    color: inherit;
    margin-bottom: 0;
  }
`;
const StyledSmallThumbnail = styled(ContentThumbnail)`
  width: 142px;
  height: ${SMALL_THUMBNAIL_HEIGHT};
  cursor: ${usePointerOnClick};
`;

const StyledDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 40px;
  height: inherit;
`;

const OverlayButton = styled.div<{ bgColor?: string; color?: string }>`
  height: 30px;
  width: 30px;
  border-radius: 20px;
  background-color: ${({ bgColor }) => bgColor || HIGHLIGHT_PRIMARY_COLOUR};
  color: ${({ color }) => color || NEUTRAL_1_COLOUR};
  font-size: 28px;
  padding-left: 2px; // Icon looked slightly off-center

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  position: absolute;
  right: 15px;
  bottom: -15px;
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
  row-gap: 2px;
`;
const AlternateOverlayText = styled.div`
  width: fit-content;
  ${APP_FONT_12PX_MEDIUM};
  ${OVERFLOW_ELLIPSIS};
  color: inherit;
`;

interface CollectionListItemProps {
  item: BuilderCollectionItem | CollectionItem;
  content: BuilderVideo | Video | BuilderCollection | Collection;
  templateId: number;
  onClick?: () => void;
}

export const CollectionListItem = ({ item, content, templateId, onClick }: CollectionListItemProps) => {
  const { getDesignProperty } = useAppTheme();
  const { data: appProperties } = useAppProperties();

  const $overlay = templateId === 1;
  const $color = $overlay ? 'white' : (getDesignProperty('primaryItemHEX') as string);
  const HighlightHEX = getDesignProperty('highlightHEX') as string;
  const overlayText = getContentOverlay(content, appProperties);
  const hasButton = templateId === 36;

  return (
    <Item hasButton={hasButton}>
      {[2, 12].includes(templateId) ? (
        // List Small
        <SmallRow $color={$color}>
          <StyledSmallThumbnail size="small" thumbnail={content} onClick={onClick} overlayText={overlayText} />
          <CenterColumn>
            <SmallTitle ellipsis={{ rows: 2 }}>{getContentName(content, item.Type)}</SmallTitle>
          </CenterColumn>
          <StyledDots>
            <EllipsisIcon />
          </StyledDots>
        </SmallRow>
      ) : (
        // List Large
        <>
          <StyledThumbnail
            size="medium"
            thumbnail={content}
            onClick={onClick}
            overlayText={$overlay ? undefined : overlayText} //$overlay manages its own count/duration
            overlayBottom={hasButton ? '18px' : undefined}
            showPreviewTag={contentHasPreviewVideo(content)}
          />
          {templateId === 36 ? (
            <OverlayButton bgColor={HighlightHEX} onClick={onClick}>
              <ChevronRightIcon />
            </OverlayButton>
          ) : (
            <TitleRow $color={$color} $overlay={$overlay}>
              <TitleBlock>
                <ItemTitle>{getContentName(content, item.Type)}</ItemTitle>
                {$overlay && <AlternateOverlayText>{overlayText}</AlternateOverlayText>}
              </TitleBlock>
              <StyledForwardIcon $overlay={$overlay} />
            </TitleRow>
          )}
        </>
      )}
    </Item>
  );
};

const DummyItem = ({ templateId }: { templateId: number }) => {
  const { getDesignProperty } = useAppTheme();
  const $overlay = templateId === 1;
  const $color = $overlay ? 'white' : (getDesignProperty('primaryItemHEX') as string);
  const HighlightHEX = getDesignProperty('highlightHEX') as string;

  return (
    <Item hasButton={templateId === 36}>
      {[2, 12].includes(templateId) ? (
        // List Small
        <SmallRow $color={$color}>
          <DummyThumbnail isSmall />
          <CenterColumn>
            <SmallTitle>{UNTITLED_CONTENT_NAME}</SmallTitle>
          </CenterColumn>
          <StyledDots>
            <EllipsisIcon />
          </StyledDots>
        </SmallRow>
      ) : (
        // List Large
        <>
          <DummyThumbnail />
          {templateId === 36 ? (
            <OverlayButton bgColor={HighlightHEX}>
              <ChevronRightIcon />
            </OverlayButton>
          ) : (
            <TitleRow $color={$color} $overlay={$overlay}>
              <ItemTitle>{UNTITLED_CONTENT_NAME}</ItemTitle>
              <StyledForwardIcon />
            </TitleRow>
          )}
        </>
      )}
    </Item>
  );
};

export const CollectionListView = ({ hideHeader }: { hideHeader?: boolean }) => {
  const { getContentForItem } = useContent();
  const { collection, onItemClick, templateId } = useAppCollectionContext();
  const { items, showMore, canShowMore, showMoreApplies } = useCappedCollectionItems(collection);

  return (
    <Container>
      {!hideHeader && <CollectionTitle title={collection ? getCollectionName(collection) : UNTITLED_BLOCK_NAME} />}
      {items.length ? (
        items.map((item) => {
          const content = getContentForItem(item);
          if (!content) {
            return null;
          }
          const handleItemClick = onItemClick && isItemClickable(content, item) ? () => onItemClick(item) : undefined;

          return isSectionHeader(content) ? (
            <SectionHeaderItem key={`${item.TabItemId}|${item.Position}`} content={content as BuilderVideo} />
          ) : (
            <CollectionListItem
              key={`${item.TabItemId}|${item.Position}`}
              item={item}
              content={content}
              onClick={handleItemClick}
              templateId={templateId}
            />
          );
        })
      ) : (
        <DummyItem templateId={templateId} />
      )}
      {showMoreApplies && canShowMore && <ShowMore onClick={showMore} />}
    </Container>
  );
};
