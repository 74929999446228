import styled, { css } from 'styled-components';

import { CollectionItem, Video } from 'api';
import { getVideoName } from 'utils/video-util';
import { APP_FONT_17PX_BOLD } from 'mockup-font';
import { OVERFLOW_ELLIPSIS } from 'font';
import { CSSProperties, RefObject } from 'react';
import { BuilderCollectionItem, BuilderVideo } from 'app/modules/build-dragdrop/Builder/providers';

const StyledTitle = styled.h2<{ onClick?: unknown }>`
  ${APP_FONT_17PX_BOLD};
  ${OVERFLOW_ELLIPSIS};
  margin-bottom: 0 !important;
  cursor: ${({ onClick }) => onClick && 'pointer'};
`;

const getVisibilityCss = (visibility?: CSSProperties['visibility']) => {
  if (visibility === 'hidden') {
    return css`
      visibility: hidden;
      padding: 0;
      margin: 0;
      height: 0;
    `;
  }
  return undefined;
};

const VideoWrapper = styled.div<{ visibility?: CSSProperties['visibility'] }>`
  width: 100%;
  margin-top: 6px;
  margin-bottom: 16px;
  padding: 8px 20px;
  overflow-x: hidden;
  ${(props) => getVisibilityCss(props.visibility)}
`;

export interface AppVideoProps {
  video?: Video | BuilderVideo;
  item: CollectionItem | BuilderCollectionItem;
  id?: string;
  onClick?: (item: CollectionItem | BuilderCollectionItem, video?: Video | BuilderVideo) => void;
  visibility?: CSSProperties['visibility'];
  videoRef?: RefObject<HTMLDivElement>;
}

/**
 * A mockup render of a video from the Content API
 * Specifically the view of the collection in a Context-Mixed View
 */
export const AppVideo = ({ video, item, onClick, videoRef, ...props }: AppVideoProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick(item, video);
    }
  };
  return (
    <VideoWrapper ref={videoRef} {...props}>
      <StyledTitle onClick={onClick && handleClick}>Video: {video ? getVideoName(video) : 'Unknown video'}</StyledTitle>
    </VideoWrapper>
  );
};
