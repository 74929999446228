import { useCallback, useEffect } from 'react';
import { Router, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import styled from 'styled-components';

import { PRIMARY_ORANGE_COLOUR } from 'theme';
import { Modal } from 'components';
import { useDataSource } from 'providers';

import { usePrefetchSecureAppProperties } from 'hooks';
import { isUserAdmin } from 'utils';

import {
  BrandStep,
  DisplayTypeStep,
  FinalStep,
  KajabiProductsStep,
  KajabiValidateStep,
  LoadingStep,
  ModeStep,
  StartStep,
  VimeoOTTProductsStep,
  VimeoOTTValidateStep,
} from './components';
import { getNextPath, WIZARD_HEIGHT, WIZARD_RADIUS } from './const';

const history = createMemoryHistory();

export interface OnboardingProps {
  onCancel: () => void;
}

const StyledModal = styled(Modal)`
  #react-app & .ant-modal-close-icon {
    color: ${PRIMARY_ORANGE_COLOUR};
    margin-top: 30px;
    margin-right: 30px;
    font-size: 24px;

    border-radius: 50%;
    padding: 12px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }

  #react-app & .ant-modal-close-x {
    height: unset;
    width: unset;
  }
`;
const getValidationStep = (dataSource: string) => {
  return (
    {
      Kajabi: <KajabiValidateStep />,
      VHX: <VimeoOTTValidateStep />,
    }[dataSource] ?? <KajabiValidateStep />
  );
};

const getProductsStep = (dataSource: string) => {
  return (
    {
      Kajabi: <KajabiProductsStep />,
      VHX: <VimeoOTTProductsStep />,
    }[dataSource] ?? <KajabiProductsStep />
  );
};

export const OnboardingWizard = ({ onCancel }: OnboardingProps) => {
  const adminHandleCancel = useCallback(() => {
    if (isUserAdmin()) {
      onCancel(); // Close the wizard
    }
  }, [onCancel]);

  const handleUserKeyPress = useCallback(
    (e: KeyboardEvent) => {
      const { pathname } = history.location;
      if (isUserAdmin() && e.code === 'Tab') {
        // Let admins tab through the steps
        const nextPath = getNextPath(dataSource, pathname);
        if (nextPath) {
          history.push(nextPath);
        }
      }
    },
    [history],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const dataSource = useDataSource();

  usePrefetchSecureAppProperties();

  return (
    <StyledModal
      open={true}
      onCancel={adminHandleCancel}
      $padding="0px"
      width={1111}
      closable={false} // X button in top right to close
      maskClosable={false} // Can't click outside to close
      $radius={WIZARD_RADIUS}
      $height={WIZARD_HEIGHT}
    >
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <StartStep />
          </Route>
          <Route exact path="/1">
            {getValidationStep(dataSource)}
          </Route>
          <Route exact path="/2">
            {getProductsStep(dataSource)}
          </Route>
          <Route exact path="/3">
            <ModeStep />
          </Route>
          <Route exact path="/4">
            <DisplayTypeStep />
          </Route>
          <Route exact path="/5">
            <BrandStep />
          </Route>
          <Route exact path="/6">
            <LoadingStep />
          </Route>
          <Route exact path="/7">
            <FinalStep />
          </Route>
        </Switch>
      </Router>
    </StyledModal>
  );
};
