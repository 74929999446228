import { useCallback, useRef } from 'react';
import styled from 'styled-components';

import { CustomButton, ImageUploader, ImageUploaderProps, Segment, SegmentProps } from 'components';
import { DEFAULT_THUMBNAIL } from 'utils';
import { UploadIcon } from 'icons';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { FONT_10PX_REGULAR } from 'font';
import { useAppProperties } from 'hooks';

const DEFAULT_WIDTH = '308px';
const DEFAULT_HEIGHT = '173px';

const Description = styled.div`
  margin-top: 4px;
  ${FONT_10PX_REGULAR};
  color: ${NEUTRAL_7_COLOUR};
`;

const UploadButton = styled(CustomButton)`
  #react-app && {
    color: ${HIGHLIGHT_PRIMARY_COLOUR};
    margin-left: -4px;
    font-weight: 500;
  }
`;

const Row = styled.div`
  display: flex;
`;
const RightColumn = styled.div`
  margin-left: 8px;
`;

const Medium = styled.div`
  font-weight: 500;
`;

interface SettingsThumbnailInputProps extends SegmentProps {
  filename?: string;
  isRevertable?: boolean | '';
  handleThumbnailChange: (filename: string) => void;
  handleRevertThumbnail?: () => void;
  width?: string;
  height?: string;
  uploaderProps?: Partial<ImageUploaderProps>;
  overrideHeader?: string;
}

export const SettingsThumbnailInput = ({
  filename,
  isRevertable,
  handleThumbnailChange,
  handleRevertThumbnail,
  width,
  height,
  uploaderProps,
  overrideHeader,
  ...props
}: SettingsThumbnailInputProps) => {
  const overrideRef = useRef<HTMLInputElement>(null);
  const { data } = useAppProperties();

  const handleUploadClick = useCallback(() => {
    overrideRef.current?.click(); //Forward click to the file input
  }, [overrideRef]);

  const thumbnailHeader = data?.RolloutAlternateThumbnails === '1' ? 'Primary Thumbnail' : 'Thumbnail';

  return (
    <Segment header={overrideHeader || thumbnailHeader} {...props}>
      <Row>
        <ImageUploader
          overrideRef={overrideRef}
          backgroundImage={DEFAULT_THUMBNAIL}
          borderRadius="4px"
          width={width || DEFAULT_WIDTH}
          height={height || DEFAULT_HEIGHT}
          filename={filename}
          onFilenameChange={handleThumbnailChange}
          hideUploadButton
          borderColor={filename === '' ? NEUTRAL_5_COLOUR : undefined}
          scaleSpinner={75}
          showCopyButton
          showRevertButton={!!isRevertable}
          onRevert={handleRevertThumbnail}
          {...uploaderProps}
        />
        <RightColumn>
          <UploadButton tertiary small icon={<UploadIcon />} onClick={handleUploadClick}>
            {filename !== '' ? 'Replace' : 'Upload'}
          </UploadButton>
          <Description>
            <Medium>Landscape</Medium>
            16:9 ratio image <br />
            1920 x 1080
          </Description>
        </RightColumn>
      </Row>
    </Segment>
  );
};
