import { AxiosInstance } from 'axios';

export interface CodePushDeployment {
  Id: number;
  Version: string;
  AppleKey: string;
  AndroidKey: string;
  Description: string;
}
export const getCodePushKeys = (client: AxiosInstance) => {
  return client.get<CodePushDeployment[]>(`code_push/keys`).then(({ data }) => data);
};
