import styled from 'styled-components';

import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';

const ShowMoreRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

interface ShowMoreProps {
  onClick: () => void;
}

const Button = styled.button<{ $color: string }>`
  &&& {
    color: ${({ $color }) => $color};
    border-color: ${({ $color }) => $color};
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;

    padding: 6px 12px;
    background-color: transparent;
    cursor: pointer;
    font-weight: 500;
  }
`;

export const ShowMore = ({ onClick }: ShowMoreProps) => {
  const { getDesignProperty } = useAppTheme();
  return (
    <ShowMoreRow>
      <Button onClick={onClick} $color={getDesignProperty('primaryItemHEX') as string}>
        Show more
      </Button>
    </ShowMoreRow>
  );
};
