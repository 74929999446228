import { EventType } from './Webhooks/components';

export const getTypeLabel = (type: EventType) => {
  const labelMap: Record<EventType, string> = {
    UserPurchase: 'User Purchased',
    UserTransaction: 'Payment Successful',
    UserCancellation: 'Subscription Cancelled',
    UserExpiry: 'Subscription Expired',
  };

  return labelMap[type];
};
