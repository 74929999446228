import { ReactNode } from 'react';
import styled from 'styled-components';

import { useDataSource } from 'providers';
import { NEUTRAL_9_COLOUR, PRIMARY_ORANGE_COLOUR } from 'theme';

import { GraphicPanel, Header, SplitPanel } from '../index';
import { WizardButton } from '../Common';
import { FONT_16PX_MEDIUM, FONT_20PX_NORMAL } from '../../font';
import { KajabiSteps } from './KajabiSteps';
import { VimeoOTTSteps } from './VimeoOTTSteps';
import { useLogout } from 'hooks/useLogout';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

const WelcomeWrapper = styled.div`
  margin-top: -10px;
`;

const Subtitle = styled.div`
  margin-top: 32px;
  color: ${NEUTRAL_9_COLOUR};
  ${FONT_20PX_NORMAL};
`;

const Wrapper = styled.div`
  padding-top: 1px;
`;

const GetStartedButton = styled(WizardButton)`
  margin-top: 40px !important;
`;
const SignOutText = styled.div`
  margin-top: 12px;
  color: ${PRIMARY_ORANGE_COLOUR};
  cursor: pointer;
  text-align: center;

  ${FONT_16PX_MEDIUM};
`;

const getMainSteps = (dataSource: string) => {
  const stepMap: Record<string, ReactNode> = {
    Kajabi: <KajabiSteps />,
    VHX: <VimeoOTTSteps />,
  };
  return stepMap[dataSource] ?? <KajabiSteps />;
};

export const StartStep = () => {
  const dataSource = useDataSource();
  const logout = useLogout();
  const { navigateForward } = useWizardNavigation();

  return (
    <SplitPanel
      leftContent={<GraphicPanel />}
      disableLeftPadding
      rightContent={
        <WelcomeWrapper>
          <Header>
            {'Welcome! 👋🏼'}
            <br />
            {'Let’s set up your free test app.'}
          </Header>
          <Subtitle>{'The main steps are:'}</Subtitle>
          <Wrapper>{getMainSteps(dataSource)}</Wrapper>
          <GetStartedButton onClick={navigateForward} $height="48px" $fontSize="17.5px">
            Get Started
          </GetStartedButton>
          <SignOutText
            onClick={() => {
              logout.mutate();
            }}
          >
            Sign Out
          </SignOutText>
        </WelcomeWrapper>
      }
    />
  );
};
