import React, { createContext, useContext } from 'react';

const AppBeingEditedContext = createContext<string | undefined>(undefined);

interface ProviderProps {
  appBeingEdited: string;
  children: React.ReactNode;
}

const AppBeingEditedProvider = ({ children, appBeingEdited }: ProviderProps) => {
  return <AppBeingEditedContext.Provider value={appBeingEdited}>{children}</AppBeingEditedContext.Provider>;
};
const useAppBeingEdited = () => {
  const context = useContext(AppBeingEditedContext);
  if (context === undefined) {
    throw new Error('useAppBeingEdited must be used within an AppBeingEditedProvider');
  }
  return context;
};

export { AppBeingEditedProvider, useAppBeingEdited };
