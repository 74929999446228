import { AxiosInstance } from 'axios';

interface PriceTier {
  Id: number;
  Tier: number;
  USD: string;
}

export const getApplePriceTiers = (client: AxiosInstance) => {
  return client.get<PriceTier[]>(`misc/price-tiers-apple`).then(({ data }) => data);
};
