import { ReactNode } from 'react';
import styled from 'styled-components';

import { FONT_14PX_MEDIUM, FONT_16PX_REGULAR, FONT_28PX_BOLD } from 'font';
import { AppBackIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { Breadcrumb, BreadcrumbProps } from 'components';
import { useSaveContext, useUnsavedChanges } from 'providers';
import { Thumbnail } from '../../../components/Thumbnail/Thumbnail';

import { BetaTag } from 'components/BetaTag/BetaTag';
import { CustomButton } from 'components/Buttons/CustomButton/CustomButton';

const HeadingWrapper = styled.div<{ $padding?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 28px;
  ${({ $padding }) => $padding && `padding: ${$padding}`};
`;

const BreadcrumbWrapper = styled.div`
  margin-bottom: 36px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  flex-grow: 1;
`;

const HeadingRow = styled.div<{ $marginBottom: string }>`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
`;

const PageHeading = styled.div`
  ${FONT_28PX_BOLD};
  color: ${NEUTRAL_10_COLOUR};
  text-align: start;
  display: flex;
  align-items: center;
`;

const PageSubheading = styled.div`
  ${FONT_16PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
  text-align: start;
`;

const StyledThumbnail = styled(Thumbnail)`
  margin-right: 25px;
`;

const BackButton = styled(CustomButton)`
  &&& {
    ${FONT_14PX_MEDIUM};
    padding: 4px 5px 4px 4px;
    height: 30px;
    margin-bottom: 30px;
    transform: translateX(-8px);

    .anticon,
    span {
      margin-left: 0 !important;
      margin-right: 2px !important;
      font-weight: 600 !important;
    }
  }
`;

const StyledChevronLeft = styled(AppBackIcon)`
  #react-app && {
    font-size: 21px;
  }
`;

export interface HeadingProps {
  heading?: string;
  subheading?: string | React.ReactNode;
  thumbnailUrl?: string | null;
  isBeta?: boolean;
  headingButton?: 'save' | ReactNode; // 'save' will display the default Save button which uses SaveProvider and UnsavedChangesProvider
  breadcrumbs?: BreadcrumbProps;
  $padding?: string;
}

export const Heading = ({
  heading,
  subheading,
  thumbnailUrl,
  isBeta,
  headingButton,
  breadcrumbs,
  ...props
}: HeadingProps) => {
  const { isSaving, saveChanges } = useSaveContext();
  const { unsavedChanges } = useUnsavedChanges();

  return (
    <HeadingWrapper {...props}>
      {breadcrumbs && (
        <BreadcrumbWrapper>
          <Breadcrumb {...breadcrumbs} />
        </BreadcrumbWrapper>
      )}
      <FlexContainer>
        {thumbnailUrl !== undefined && (
          <StyledThumbnail
            url={thumbnailUrl}
            width="118px"
            height="68px"
            border={thumbnailUrl ? 'none' : undefined}
            borderRadius="6px"
          />
        )}
        <MainContent>
          <HeadingRow $marginBottom={subheading ? '16px' : '0px'}>
            <PageHeading data-testid="page-heading">
              {heading}
              {isBeta && <BetaTag />}
            </PageHeading>
            {headingButton === 'save' ? (
              <CustomButton
                medium
                onClick={saveChanges}
                loading={isSaving}
                disabled={!unsavedChanges}
                id="Heading__Save"
              >
                Save
              </CustomButton>
            ) : (
              headingButton
            )}
          </HeadingRow>
          {subheading && <PageSubheading data-testid="page-subheading">{subheading}</PageSubheading>}
        </MainContent>
      </FlexContainer>
    </HeadingWrapper>
  );
};

export interface HeadingBackButtonProps {
  text: string;
  onClick: () => void;
}

export const HeadingBackButton = ({ text, ...props }: HeadingBackButtonProps) => (
  <BackButton tertiary icon={<StyledChevronLeft />} {...props}>
    {text}
  </BackButton>
);
