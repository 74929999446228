import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { deleteComment, useAxiosInstance } from 'api';
import { useRecordAction } from 'hooks';

export const useDeleteComment = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, number>(
    (commentId: number) => {
      return deleteComment(client, appId, commentId);
    },
    {
      onSuccess: async (_response, commentId) => {
        await queryClient.invalidateQueries(['comments']);
        recordAction.mutate({ action: 'Delete Comment', detail: { CommentId: commentId } });
      },
    },
  );
};
