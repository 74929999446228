import { css } from 'styled-components';

// Figma Fonts

// Large Header
export const FONT_45PX_BOLD = css`
  font-size: 45px;
  font-weight: 700;
  line-height: normal;
`;
export const FONT_30PX_SEMIBOLD = css`
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
`;
export const FONT_30PX_REGULAR = css`
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
`;

// Header
export const FONT_28PX_BOLD = css`
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
`;

export const FONT_24PX_SEMIBOLD = css`
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
`;
export const FONT_24PX_MEDIUM = css`
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
`;
export const FONT_24PX_REGULAR = css`
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
`;
export const FONT_20PX_MEDIUM = css`
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
`;
export const FONT_20PX_REGULAR = css`
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
`;
export const FONT_18PX_SEMIBOLD = css`
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
`;
export const FONT_18PX_MEDIUM = css`
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
`;

// Body
export const FONT_16PX_SEMIBOLD = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
export const FONT_16PX_MEDIUM = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
export const FONT_16PX_REGULAR = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const FONT_14PX_SEMIBOLD = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const FONT_14PX_MEDIUM = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const FONT_14PX_REGULAR = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

// Captions
export const FONT_12PX_SEMIBOLD = css`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
`;

export const FONT_12PX_MEDIUM = css`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

export const FONT_12PX_REGULAR = css`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
`;

// Subtitles
export const FONT_10PX_SEMIBOLD = css`
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
`;
export const FONT_10PX_MEDIUM = css`
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
`;
export const FONT_10PX_REGULAR = css`
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
`;

// Unexpected Fonts

export const FONT_11PX_REGULAR_DEPRECATED = css`
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
`;

export const OVERFLOW_ELLIPSIS = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
