import { useQuery } from 'react-query';
import { getUserEmail } from 'utils';
import { getStripeCustomer, useAxiosInstance } from '../../../../../api';

export const useStripeCustomer = () => {
  const client = useAxiosInstance();
  const email = getUserEmail();
  return useQuery(['stripe', email], () => {
    return getStripeCustomer(client, email);
  });
};
