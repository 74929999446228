import { DataSourceProvider } from 'providers';

import { ContentNavigationProvider } from '../providers';
import { ContentInternal } from './ContentInternal';

export const Content = () => (
  <DataSourceProvider>
    <ContentNavigationProvider>
      <ContentInternal />
    </ContentNavigationProvider>
  </DataSourceProvider>
);
