import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styled from 'styled-components';

import { SOURCE_VIDAPP } from 'api';
import { CustomButton } from 'components';
import { PenIcon, TrashIcon } from 'icons';
import { useContent, useSaveContext } from 'providers';
import { NEUTRAL_5_COLOUR, NEUTRAL_6_COLOUR } from 'theme';

import { AddContentButton, CircuitRowConfig, ExerciseRowConfig, TreeRowButton } from '.';
import { TreeItem } from '../ContentCollectionTree';

const Controls = styled.div<{ dropdownOpen?: boolean }>`
  display: ${({ dropdownOpen }) => (dropdownOpen ? 'flex' : 'none')};
  justify-content: flex-end;
  align-items: center;
  padding-left: 6px;
`;

const IconButton = styled(CustomButton)`
  &&&& {
    margin: 0;

    :hover {
      background-color: ${NEUTRAL_5_COLOUR};
    }

    :active {
      background-color: ${NEUTRAL_6_COLOUR};
    }
  }
`;

const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface TreeRowControlsProps {
  item: TreeItem;
  parentTabId: string | number;
  parentDataSource: string;
  setActiveKeys: Dispatch<SetStateAction<(string | number)[]>>;
  setIsEditingTitle: Dispatch<SetStateAction<boolean>>;
}

export const TreeRowControls = ({ setIsEditingTitle, ...props }: TreeRowControlsProps) => {
  const { item, parentTabId, parentDataSource, setActiveKeys } = props;
  const { setCollectionItems, collections } = useContent();
  const { setCollectionItemsToSave } = useSaveContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { type, itemId, dataSource } = item;

  const removeItem = useCallback(() => {
    const updatedItems = [...collections[parentTabId].Items]
      .filter((colItem) => colItem.ChildId !== itemId)
      .map((colItem, idx) => ({ ...colItem, Position: idx + 1 }));
    setCollectionItems(parentTabId, updatedItems);
    setCollectionItemsToSave(parentTabId);
  }, [itemId, parentTabId, collections, setCollectionItems, setCollectionItemsToSave]);

  return (
    <Container>
      {type === 'circuit' ? (
        <CircuitRowConfig {...props} />
      ) : type === 'exercise' ? (
        <ExerciseRowConfig {...props} />
      ) : (
        <Controls dropdownOpen={dropdownOpen} className="tree-row-controls">
          {parentDataSource === SOURCE_VIDAPP && (
            <IconButton
              tertiary
              danger
              small
              icon={<TrashIcon />}
              onClick={(e) => {
                e.stopPropagation();
                removeItem();
              }}
            />
          )}
          {type === 'collection' && dataSource === SOURCE_VIDAPP && (
            <>
              <TreeRowButton
                icon={<PenIcon />}
                small
                tertiary
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditingTitle(true);
                }}
              >
                Edit
              </TreeRowButton>
              <AddContentButton
                collectionId={itemId}
                isTreeRow
                onOpenChange={(open) => setDropdownOpen(open)}
                onAction={() =>
                  setActiveKeys((prevState) => {
                    const newState = [...prevState];
                    if (newState.includes(itemId)) {
                      return newState;
                    } else {
                      return [...newState, itemId];
                    }
                  })
                }
              />
            </>
          )}
        </Controls>
      )}
    </Container>
  );
};
