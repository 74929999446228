import { ReactNode } from 'react';

import { BuilderVideo, useContent, useSaveContext } from 'providers';

import { getContentResourceType, getVideoName } from 'utils';
import { FileBlankIcon } from 'icons';
import { Segment } from 'components';
import {
  DrawerHeading,
  DrawerScrollContainer,
  FileBlock,
  VideoThumbnail,
} from 'app/modules/build-dragdrop/Builder/drawer/components';
import styled, { css } from 'styled-components';
import { NEUTRAL_7_COLOUR } from 'theme';

const ICON_STYLE = css`
  color: ${NEUTRAL_7_COLOUR};
  font-size: 12px;
  margin-right: 2px;
`;

const StyledFileBlankIcon = styled(FileBlankIcon)`
  #react-app && {
    ${ICON_STYLE};
  }
`;

interface FileDrawerProps {
  file: BuilderVideo;
  type?: ReactNode;
}

export const FileDrawer = ({ file, type }: FileDrawerProps) => {
  const { setVideoValue } = useContent();
  const { setVideoValueToSave } = useSaveContext();
  return (
    <>
      <DrawerHeading heading={getVideoName(file)} type={type ?? 'File'} />
      <DrawerScrollContainer>
        <Segment header="File" $marginTop="0">
          <FileBlock
            id="FileDrawer"
            icon={<StyledFileBlankIcon />}
            onTitleChange={(title) => {
              setVideoValue(file.VideoId, 'Title', title);
              setVideoValueToSave(file.VideoId, 'Title', title);
            }}
            title={file?.Title}
            url={file?.OriginalFilename}
            type={getContentResourceType(file, 'video')}
            marginBottom="0"
          />
        </Segment>
        <VideoThumbnail video={file} />
      </DrawerScrollContainer>
    </>
  );
};
