import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { CustomButton } from 'components';
import { PenIcon, PlusIcon, TrashIcon } from 'icons';
import { useContent, useSaveContext } from 'providers';
import { NEUTRAL_5_COLOUR, NEUTRAL_6_COLOUR } from 'theme';

import { useModalContext } from 'app/modules/content/Content/cms-modal/providers/modal-provider';
import { TreeItem } from '../ContentCollectionTree';

const Container = styled.div`
  opacity: 0;
  display: flex;
`;

const IconButton = styled(CustomButton)`
  &&&& {
    margin: 0;

    :hover {
      background-color: ${NEUTRAL_5_COLOUR};
    }

    :active {
      background-color: ${NEUTRAL_6_COLOUR};
    }
  }
`;

interface CircuitRowButtonsProps {
  item: TreeItem;
  parentTabId: string | number;
  setActiveKeys: Dispatch<SetStateAction<(string | number)[]>>;
}

export const CircuitRowButtons = ({ item, parentTabId, setActiveKeys }: CircuitRowButtonsProps) => {
  const { setCollectionItems, collections } = useContent();
  const { setCollectionItemsToSave } = useSaveContext();
  const { type, itemId } = item;
  const { setModalCollectionId, setModalCircuitId, setModalExerciseId } = useModalContext();

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const titleInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditingTitle) {
      titleInput.current?.select();
    }
  }, [isEditingTitle]);

  useEffect(() => {
    if (isEditingTitle) {
      titleInput.current?.addEventListener('keyup', ({ key }) => {
        if (key === 'Enter') {
          setIsEditingTitle(false);
        }
      });
    }
    return () =>
      titleInput.current?.removeEventListener('keyup', ({ key }) => {
        if (key === 'Enter') {
          setIsEditingTitle(false);
        }
      });
  }, [isEditingTitle]);

  const removeItem = useCallback(() => {
    const updatedItems = [...collections[parentTabId].Items]
      .filter((colItem) => colItem.ChildId !== itemId)
      .map((colItem, idx) => ({ ...colItem, Position: idx + 1 }));
    setCollectionItems(parentTabId, updatedItems);
    setCollectionItemsToSave(parentTabId);
  }, [itemId, parentTabId, collections, setCollectionItems, setCollectionItemsToSave]);

  return (
    <Container className="circuit-row-buttons">
      <IconButton
        tertiary
        danger
        medium
        icon={<TrashIcon />}
        onClick={(e) => {
          e.stopPropagation();
          removeItem();
        }}
      />
      <IconButton
        tertiary
        medium
        icon={<PenIcon />}
        onClick={(e) => {
          e.stopPropagation();
          if (type === 'circuit') {
            setModalCircuitId(itemId);
          } else if (type === 'exercise') {
            setModalExerciseId(itemId);
          }
        }}
      />
      {type === 'circuit' && (
        <IconButton
          tertiaryHighlight
          medium
          icon={<PlusIcon />}
          onClick={(e) => {
            e.stopPropagation();
            setActiveKeys((prevState) => {
              const newState = [...prevState];
              if (newState.includes(itemId)) {
                return newState;
              } else {
                return [...newState, itemId];
              }
            });
            setModalCollectionId(itemId);
          }}
        />
      )}
    </Container>
  );
};
