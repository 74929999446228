import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { deleteCommunityPost, useAxiosInstance } from 'api';
import { useRecordAction } from 'hooks';

export const useDeletePost = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, string>(
    (postId: string) => {
      return deleteCommunityPost(client, appId, postId);
    },
    {
      onSuccess: async (_response, postId) => {
        // Invalidate all queries for community posts
        await queryClient.invalidateQueries(['community', 'posts']);
        recordAction.mutate({ action: 'Delete Community Post', detail: { PostId: postId } });
      },
    },
  );
};
