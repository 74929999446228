import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { deleteCommunityComment, useAxiosInstance } from 'api';
import { useRecordAction } from 'hooks';

export const useDeleteComment = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, string>(
    (commentId: string) => {
      return deleteCommunityComment(client, appId, commentId);
    },
    {
      onSuccess: async (_response, commentId) => {
        // Invalidate all queries for community comments, just visible on posts right now
        await queryClient.invalidateQueries(['community', 'posts']);
        recordAction.mutate({ action: 'Delete Community Comment', detail: { CommentId: commentId } });
      },
    },
  );
};
