import { useCallback } from 'react';

import { ITEM_TYPE_COLLECTION, Resource, SOURCE_VIDAPP } from 'api';
import { Segment, SegmentProps, ContentResources } from 'components';
import { useAppProperties } from 'hooks';
import { BuilderCollection, useContent, useSaveContext } from 'providers';

import { ItemResources } from '.';

interface CollectionResourcesProps {
  collection: BuilderCollection;
}

export const CollectionResources = ({ collection, ...props }: CollectionResourcesProps & SegmentProps) => {
  const { setCollectionValue } = useContent();
  const { setCollectionValueToSave } = useSaveContext();
  const { data: appProperties } = useAppProperties();

  const isEditable = collection.DataSource === SOURCE_VIDAPP && appProperties?.DisplaySimpleCMS === '1';

  const updateResources = useCallback(
    (newValue: Resource[]) => {
      setCollectionValue(collection.TabId, 'Resources', newValue);
      setCollectionValueToSave(collection.TabId, 'Resources', newValue);
    },
    [collection, setCollectionValue, setCollectionValueToSave],
  );

  const handleAddResource = useCallback(
    (resource: Resource) => {
      const updatedResources = [...(collection.Resources ?? [])];
      updatedResources.push(resource);
      updateResources(updatedResources);
    },
    [collection, updateResources],
  );

  const handleRemoveResource = useCallback(
    (resourceId: string | number) => {
      const existingResources = [...(collection.Resources ?? [])];
      const updatedResources = existingResources.filter((r) => r.ResourceId !== resourceId);
      updateResources(updatedResources);
    },
    [collection, updateResources],
  );

  const handleResourcesTitleChange = useCallback(
    (title: string, resourceId: string | number) => {
      const existingResources = [...(collection.Resources ?? [])];
      const updatedResources = existingResources.map((r) => (r.ResourceId === resourceId ? { ...r, Title: title } : r));
      updateResources(updatedResources);
    },
    [collection, updateResources],
  );

  return isEditable ? (
    <ContentResources
      existingResources={collection.Resources ?? []}
      onAddResource={handleAddResource}
      onRemoveResource={handleRemoveResource}
      onTitleChange={handleResourcesTitleChange}
      parentType={ITEM_TYPE_COLLECTION}
      parentId={collection.TabId.toString()}
      isInCMS
    />
  ) : collection.Resources && collection.Resources.length > 0 ? (
    <Segment header="Resources" {...props}>
      <ItemResources resources={collection.Resources} />
    </Segment>
  ) : null;
};
