import { useCallback, useMemo } from 'react';

import { Segment, SegmentProps, SelectOption, SettingsCheckboxInput, SettingsSelectInput } from 'components';
import { useTemplates } from 'hooks';
import { BuilderCollection, useContent, useSaveContext } from 'providers';

interface CollectionTVSettingsProps {
  collection: BuilderCollection;
}

export const CollectionTVSettings = ({ collection, ...props }: CollectionTVSettingsProps & SegmentProps) => {
  const { setCollectionValue, setCollectionProperty } = useContent();
  const { setCollectionValueToSave, setCollectionPropertyToSave } = useSaveContext();
  const { getTVTemplateOptions, getTemplateById } = useTemplates();

  const tvTemplateOptions: SelectOption[] | undefined = useMemo(() => {
    const templates = getTVTemplateOptions();
    if (templates) {
      const options = templates.map(({ DisplayName, TemplateId }) => ({
        name: DisplayName,
        value: TemplateId.toString(),
      }));
      options.unshift({ name: 'Default Display', value: '' });
      return options;
    }
    return undefined;
  }, [getTVTemplateOptions]);

  const handleSelectChange = useCallback(
    (value: string) => {
      if (collection) {
        setCollectionProperty(collection.TabId, 'tvTemplateId', value);
        setCollectionPropertyToSave(collection.TabId, 'tvTemplateId', value);
      }
    },
    [collection, setCollectionProperty, setCollectionPropertyToSave],
  );

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      setCollectionValue(collection.TabId, 'DisplayInTVApp', checked ? 1 : 0);
      setCollectionValueToSave(collection.TabId, 'DisplayInTVApp', checked ? 1 : 0);
    },
    [collection, setCollectionValue, setCollectionValueToSave],
  );

  return tvTemplateOptions && getTemplateById(collection.TemplateId)?.AvailableOnTV === 1 ? (
    <Segment header="TV" {...props}>
      <SettingsSelectInput
        options={tvTemplateOptions}
        defaultVal={collection.Properties?.tvTemplateId ?? ''}
        $marginBottom="12px"
        onChange={handleSelectChange}
        size="middle"
      />
      <SettingsCheckboxInput
        label="Display in TV apps"
        checked={collection.DisplayInTVApp === 1}
        onChange={(e) => handleCheckboxChange(e.target.checked)}
      />
    </Segment>
  ) : null;
};
