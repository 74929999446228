import { useState } from 'react';

import { PhoneMockUpContainerV2 } from 'app/modules/build-screens/components/Common/PhoneMockupContainerV2';
import { AppMockup, MockupContextProvider, PageContext } from 'app/modules/build-dragdrop';
import { DEFAULT_MOCKUP_HEIGHT_2 } from 'app/modules/build-screens/const';

interface BuildMyAppMockupProps {
  tabId?: number | string;
}

export const BuildMyAppMockup = ({ tabId }: BuildMyAppMockupProps) => {
  const [currentPage, setCurrentPage] = useState<PageContext | undefined>(undefined);

  return (
    <PhoneMockUpContainerV2 tabId={tabId}>
      <MockupContextProvider
        key={tabId}
        currentTabId={tabId}
        setCurrentTabId={() => {
          console.debug('Ignoring tab change in Build My App');
        }}
        currentPage={currentPage}
        // Only allow navigation to subtabs
        setCurrentPage={(page) => {
          if (page?.subTabItemId) {
            setCurrentPage(page);
          }
        }}
        screenHeight={`${DEFAULT_MOCKUP_HEIGHT_2}px`}
        disableDragDrop
        navigationEnabled={false}
      >
        <AppMockup disableMargin disableMaxHeight isLoading={!tabId} />
      </MockupContextProvider>
    </PhoneMockUpContainerV2>
  );
};
