import { useQuery, useQueryClient } from 'react-query';

import { BuilderCollection, useContent } from 'providers';
import { useAppBeingEdited } from 'app-context';
import { useCallback } from 'react';

// This hook is dependent on useContent and therefore must be used inside a ContentProvider
export const useContentByDataSource = () => {
  const appId = useAppBeingEdited();
  const { collections } = useContent();

  const query = useQuery([appId, 'collections_by_data_source'], () => {
    // Return one to many collections for a single source id (due to multiple possible data sources)
    const col_by_datasource: Record<string, BuilderCollection[]> = {};
    const collectionsArray = Object.values(collections);

    for (const c of collectionsArray) {
      const { DataSource } = c;
      const existing = col_by_datasource[DataSource];
      if (existing === undefined) {
        col_by_datasource[DataSource] = [];
      }
      col_by_datasource[DataSource].push(c);
    }

    return col_by_datasource;
  });

  const getCollectionsByDataSource = useCallback(
    (dataSource: string) => {
      if (query.data) {
        const col_by_datasource = query.data;
        return col_by_datasource[dataSource];
      }
      return undefined;
    },
    [query.data],
  );

  return { ...query, getCollectionsByDataSource };
};

export const useInvalidateContentByDataSource = () => {
  const appId = useAppBeingEdited();
  const queryClient = useQueryClient();
  return () => {
    return queryClient.invalidateQueries([appId, 'collections_by_data_source']);
  };
};
