import { APP_PROPERTY_DEFAULTS, AppProperties, AppPropertyDefaultKey, AppPropertyToSave } from 'api';
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext } from 'react';

type SetAppProperty = (name: keyof AppProperties, value: AppPropertyToSave['Value']) => void;

interface ContextValue {
  properties: AppProperties;
  setProperties: Dispatch<SetStateAction<AppProperties>>;
  setAppProperty: SetAppProperty;
  getPropertyOrDefault: (key: AppPropertyDefaultKey) => string;
}

interface ProviderProps {
  children: ReactNode | ReactNode[];
  properties: ContextValue['properties'];
  setProperties: ContextValue['setProperties'];
}

const defaultFunction = () => {
  console.warn('Unexpected function call local-app-properties-provider');
};

const LocalAppPropertiesContext = createContext<ContextValue>({
  properties: {} as AppProperties,
  setProperties: defaultFunction,
  setAppProperty: defaultFunction,
  getPropertyOrDefault: () => {
    defaultFunction();
    return '';
  },
});

const LocalAppPropertiesProvider = ({ children, properties, setProperties }: ProviderProps) => {
  const setAppProperty: SetAppProperty = (name, value) => {
    setProperties((prev) => {
      const newProperties = { ...prev };
      newProperties[name] = value as string; // May be string/number/OnboardingScreen[]
      return newProperties;
    });
  };

  const getPropertyOrDefault = useCallback(
    (key: AppPropertyDefaultKey) => properties[key] ?? APP_PROPERTY_DEFAULTS[key],
    [properties],
  );

  return (
    <LocalAppPropertiesContext.Provider value={{ properties, setProperties, setAppProperty, getPropertyOrDefault }}>
      {children}
    </LocalAppPropertiesContext.Provider>
  );
};

const useLocalAppProperties = () => {
  const context = useContext(LocalAppPropertiesContext);
  if (context === undefined) {
    throw new Error('useLocalAppProperties must be used within a LocalAppPropertiesProvider');
  }
  return context;
};

export { LocalAppPropertiesProvider, useLocalAppProperties };
