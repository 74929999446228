import styled from 'styled-components';

interface WizardIconProps {
  icon: JSX.Element | null;
}

const Circle = styled.div`
  height: 48px;
  min-height: 48px; // Occupy space while image is loading
  width: 48px;
  margin-bottom: 10px;

  // Use parent container dimensions
  img {
    height: 100%;
    width: 100%;
  }
`;

export const WizardIcon = ({ icon }: WizardIconProps) => {
  return <Circle>{icon}</Circle>;
};
