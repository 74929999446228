import { useQuery } from 'react-query';

import { CollectionBasicInfo, listCollections, useAxiosInstance } from 'api';
import { useDataSource } from 'providers';
import { useAppBeingEdited } from 'app-context';

export const useListCollections = (limit: number) => {
  const client = useAxiosInstance();
  const dataSource = useDataSource();
  const appBeingEdited = useAppBeingEdited();
  return useQuery<CollectionBasicInfo[]>(['list_collections', appBeingEdited, dataSource], () =>
    listCollections(client, appBeingEdited, dataSource, limit),
  );
};
