import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { authenticate, updatePassword, useAxiosInstance } from 'api';
import { getUserEmail } from 'utils';

export const useUpdatePassword = () => {
  const client = useAxiosInstance();

  return useMutation<unknown, AxiosError, { CurrentPassword: string; NewPassword: string }>(
    async ({ CurrentPassword, NewPassword }) => {
      const authenticated = await authenticate(client, getUserEmail(), CurrentPassword);
      if (authenticated) {
        await updatePassword(client, NewPassword);
      }
    },
    {
      onSuccess: () => {
        console.info('Successfully updated password');
      },
    },
  );
};
