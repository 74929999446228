import { syncWordPress, useAxiosInstance } from '../api';
import { useAppBeingEdited } from '../app-context';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

export const useSyncWordPress = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();

  return useMutation<unknown, AxiosError, unknown>(async () => {
    const response = await syncWordPress(client, appId);
    return response?.data;
  });
};
