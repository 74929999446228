import { ReactNode, useCallback } from 'react';
import { Form } from 'antd';

import { useTabSettings } from 'app/modules/build-screens/hooks';
import { NavigationHeader, TabBar } from 'app/modules/build-screens/components';
import { getPageName, PropertyMap } from 'app/modules/build-screens/utils';
import { useContent } from 'providers';
import { ContentAdvancedSettings } from 'components';
import { DrawerSeparator } from './components/DrawerSeparator';

export interface DefaultTabDrawerProps {
  tabId: number;
  children?: ReactNode;
  childProperties?: Record<string, PropertyMap>;
}

export const DefaultTabDrawer = ({ tabId, children, childProperties }: DefaultTabDrawerProps) => {
  const { collections } = useContent();
  const tab = collections[tabId];

  const [form] = Form.useForm();

  // The declared variables are managed in useTabSettings
  const { onValuesChange } = useTabSettings(
    {
      NavBarTitleText: { type: 'collection', valueType: 'string' },
      Name: { type: 'collection', valueType: 'string' },
      LockedUntilSignIn: { type: 'collectionProperty', valueType: 'binary' },
      Icon: { type: 'collection', valueType: 'string' },
      ...childProperties,
    },
    tabId,
    form,
  );

  const onTabIconFilenameChange = useCallback(
    (_filename) => {
      onValuesChange({ Icon: _filename }, {}, true);
    },
    [onValuesChange],
  );

  return (
    <Form
      name="settings-form"
      onValuesChange={(changedValues) => {
        onValuesChange(changedValues);
      }}
      autoComplete="off"
      form={form}
    >
      <NavigationHeader tabId={tabId} placeholder={getPageName(tab)} />
      <DrawerSeparator />
      <ContentAdvancedSettings collection={tab} isDrawer isTab />
      <TabBar filename={tab.Icon} placeholder={getPageName(tab)} onFilenameChange={onTabIconFilenameChange} />
      {children}
    </Form>
  );
};
