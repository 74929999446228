import { useLocation } from 'react-router-dom';

import { ErrorPage } from 'components';

import { AppStoreAndroidSettings, AppStoreAppleTVSettings, AppStoreIOSSettings, AppStoreRokuSettings } from './';
import { FeatureFlagged } from 'app/modules/feature-flagged';

export const AppStoreScreens = () => {
  const location = useLocation();
  const screenKey = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  switch (screenKey) {
    case 'ios':
      return <AppStoreIOSSettings />;
    case 'android':
      return <AppStoreAndroidSettings />;
    case 'apple-tv':
      return (
        <FeatureFlagged>
          <AppStoreAppleTVSettings />
        </FeatureFlagged>
      );
    case 'roku':
      return (
        <FeatureFlagged>
          <AppStoreRokuSettings />
        </FeatureFlagged>
      );
    default:
      return <ErrorPage code={404} />;
  }
};
