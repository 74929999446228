import { useEffect } from 'react';
import styled from 'styled-components';
import { Row } from 'antd';

import { WIZARD_RADIUS } from '../const';
import { NEUTRAL_9_COLOUR } from 'theme';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

const FullPageRow = styled(Row)`
  height: 100%;
  padding-top: 150px;
  border-radius: ${WIZARD_RADIUS} !important;
  font-family: Inter, serif;
  display: flex;

  &&&& {
    min-width: 960px;
    flex-direction: column;
    align-items: center;
  }
`;

const Text = styled.div`
  text-align: center;
  width: 450px;
  color: ${NEUTRAL_9_COLOUR};
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
`;

export const LoadingStep = () => {
  const { navigateForward } = useWizardNavigation();

  useEffect(() => {
    setTimeout(() => {
      navigateForward();
    }, 6000);
  }, [navigateForward]);

  return (
    <FullPageRow>
      <img
        height="300px"
        width="300px"
        src={`${BUILDER_IMAGES_DOMAIN}/onboarding/building.webp`}
        alt="Building your app animation"
      />
      <Text>{'Nice work! App building magic now in progress... 🧙'}</Text>
    </FullPageRow>
  );
};
