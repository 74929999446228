import { CollectionItem, ITEM_TYPE_VIDEO, MIXED_TEMPLATE, SOURCE_VIDAPP } from 'api';
import {
  AppCollection,
  AppCollectionProps,
  AppVideo,
  EditCollection,
  DraggableVideo,
  SectionHeaderItem,
} from 'app/modules/build-dragdrop/Builder/mockup/components';
import { BuilderCollectionItem, useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { useMockupContext } from '../providers';
import { isSectionHeaderItem } from 'utils';

interface ContentSectionProps {
  item: BuilderCollectionItem | CollectionItem;
  idx: number;
  overrideTemplateId?: number;
  isSourceContent?: boolean;
}

/**
 * Render the specified collection item
 * Specifically as it would be viewed in a Context-Mixed View
 * Index position is required for Draggable items
 * Override used for Homogenous views (all items are displayed the same)
 */
export const ContentSection = ({ item, idx, overrideTemplateId, isSourceContent }: ContentSectionProps) => {
  const { collections, videos } = useContent();
  const { disableDragDrop } = useMockupContext();

  const parentCollection = collections[item.TabId];
  const collection = collections[item.ChildId];

  const isEditable = (overrideTemplateId && parentCollection?.DataSource === SOURCE_VIDAPP) || !overrideTemplateId;
  const isContentMixed = parentCollection?.TemplateId === MIXED_TEMPLATE;
  const isDraggable = !disableDragDrop && isEditable;

  const itemType = item.Type;

  if (isSectionHeaderItem(item)) {
    const video = videos[item.ChildId];
    return <SectionHeaderItem content={video} />;
  }

  /* These should not exist yet, videos shouldn't appear in Context Mixed collections */
  /* For now we will render them as invisible, this reflects how the app behaves */
  if (itemType === ITEM_TYPE_VIDEO) {
    const video = videos[item.ChildId];
    return isDraggable ? (
      <DraggableVideo item={item} video={video} idx={idx} visibility="hidden" />
    ) : (
      <AppVideo video={video} item={item} visibility="hidden" />
    );
  }
  const templateId = overrideTemplateId ?? (item.TemplateId || collection?.TemplateId) ?? 0;

  const collectionProps: AppCollectionProps = {
    collection,
    templateId,
  };

  // Homogenous view with override template always disables dragdrop
  return isDraggable ? (
    <EditCollection
      item={item}
      idx={idx}
      isSourceContent={isSourceContent}
      disableAddButtons={!isContentMixed}
      {...collectionProps}
    />
  ) : (
    <AppCollection {...collectionProps} />
  );
};
