import styled from 'styled-components';
import { SegmentTitle } from 'components';

interface WrapperProps {
  width?: string;
  $marginBottom?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || 0};
`;

export interface SettingsInputWrapperProps {
  label?: string;
  tooltip?: string;
  width?: string;
  $marginBottom?: string;
  children: JSX.Element;
}

export const SettingsInputWrapper = ({ label, tooltip, width, children, $marginBottom }: SettingsInputWrapperProps) => {
  return (
    <Wrapper width={width} $marginBottom={$marginBottom}>
      {label && <SegmentTitle title={label} tooltip={tooltip} />}
      {children}
    </Wrapper>
  );
};
