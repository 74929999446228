import styled from 'styled-components';
import { SettingsTextInput } from 'components';
import { NEUTRAL_7_COLOUR } from 'theme';
import { SearchIcon } from 'icons';

export const SearchBox = styled(SettingsTextInput)`
  &&&.ant-input-affix-wrapper {
    margin-bottom: 28px !important;
  }

  color: ${NEUTRAL_7_COLOUR};
  flex-shrink: 0;
`;
export const StyledSearchIcon = styled(SearchIcon)`
  font-size: 16px;
  color: ${NEUTRAL_7_COLOUR} !important;
`;
