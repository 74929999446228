import styled, { css } from 'styled-components';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_SECONDARY_ACTIVE_COLOUR,
  HIGHLIGHT_SECONDARY_HOVER_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_6_COLOUR,
} from 'theme';
import { TOOLBOX_PAGE_ADD, TOOLBOX_PAGE_TREE } from 'app/modules/build-dragdrop/Builder/const';
import { ListAltIcon } from 'icons';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${NEUTRAL_10_COLOUR};
  background-color: #fff;

  width: 56px;
  height: 100%;
  max-height: inherit;
`;

const Tab = styled.div<{ $selected?: boolean }>`
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;

  :hover {
    background-color: ${NEUTRAL_3_COLOUR};
    border-right: 2px ${NEUTRAL_6_COLOUR} solid;
  }

  ${({ $selected }) =>
    $selected &&
    css`
      cursor: auto;
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
      background-color: ${HIGHLIGHT_SECONDARY_ACTIVE_COLOUR};
      border-right: 2px ${HIGHLIGHT_PRIMARY_COLOUR} solid;

      :hover {
        background-color: ${HIGHLIGHT_SECONDARY_HOVER_COLOUR};
        border-right: 2px ${HIGHLIGHT_PRIMARY_COLOUR} solid;
      }
    `}
`;

const OPTIONS = [TOOLBOX_PAGE_TREE, TOOLBOX_PAGE_ADD];

const getTabIcon = (page: string) => {
  switch (page) {
    case TOOLBOX_PAGE_TREE:
      return <ListAltIcon />;
    default:
      return <>Icon</>;
  }
};

const OPTION_IDS: Record<string, string> = {
  [TOOLBOX_PAGE_TREE]: 'ToolboxTree',
};

export const ToolboxTabs = () => {
  const { toolboxPage, setToolboxPage } = useBuilderContext();
  return (
    <Container>
      {OPTIONS.map((option) => {
        return (
          <Tab
            key={OPTION_IDS[option]}
            id={OPTION_IDS[option]}
            $selected={toolboxPage === option}
            onClick={() => setToolboxPage(option)}
          >
            {getTabIcon(option)}
          </Tab>
        );
      })}
    </Container>
  );
};
