import { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import {
  DANGER_COLOUR,
  DANGER_HOVER_COLOUR,
  HIGHLIGHT_PRIMARY_ACTIVE_COLOUR,
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_PRIMARY_HOVER_COLOUR,
  HIGHLIGHT_SECONDARY_COLOUR,
  HIGHLIGHT_SECONDARY_HOVER_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_1_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_4_COLOUR,
  NEUTRAL_6_COLOUR,
  SUCCESS_ACTIVE_COLOUR,
  SUCCESS_COLOUR,
  SUCCESS_HOVER_COLOUR,
} from 'theme';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM, FONT_16PX_MEDIUM } from 'font';
import { LoadingSpinner } from 'icons';
import { Tooltip } from 'components';
import { Link, LinkProps } from 'react-router-dom';

// Styled text link
export const CustomLink = styled(Link)`
  &&&& {
    color: ${HIGHLIGHT_PRIMARY_COLOUR};
    text-decoration: underline;
    text-underline-offset: 3px;

    :hover {
      color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
    }

    :active {
      color: ${HIGHLIGHT_PRIMARY_ACTIVE_COLOUR};
    }
  }
`;

// Can be extended to include more preconfigured button types
const getCustomStyles = (props: InternalProps) => {
  if (props?.$danger) {
    if (props?.$secondary) {
      // Danger secondary
      return css`
        background-color: ${NEUTRAL_1_COLOUR};
        color: ${DANGER_COLOUR};
        border-color: ${DANGER_COLOUR};

        :hover,
        :focus-visible {
          color: ${DANGER_HOVER_COLOUR};
          border-color: ${DANGER_HOVER_COLOUR};
        }

        :disabled {
          background-color: ${NEUTRAL_1_COLOUR};
          color: ${NEUTRAL_6_COLOUR};
          border-color: ${NEUTRAL_6_COLOUR};
        }
      `;
    } else if (props?.$tertiary) {
      // Danger tertiary
      return css`
        background: none;
        color: ${DANGER_COLOUR};
        border: none;

        :hover,
        :focus-visible {
          color: ${DANGER_HOVER_COLOUR};
          border-color: ${DANGER_HOVER_COLOUR};
        }
      `;
    }

    // Danger primary
    return css`
      background-color: ${DANGER_COLOUR};
      color: ${NEUTRAL_1_COLOUR};
      border-color: ${DANGER_COLOUR};

      :hover,
      :focus-visible {
        background-color: ${DANGER_HOVER_COLOUR};
        color: ${NEUTRAL_1_COLOUR};
        border-color: ${DANGER_HOVER_COLOUR};
      }
    `;
  } else if (props?.$secondary) {
    return css`
      background-color: ${NEUTRAL_1_COLOUR};
      color: ${NEUTRAL_10_COLOUR};
      border-color: ${NEUTRAL_6_COLOUR};

      :hover,
      :focus-visible {
        background-color: ${HIGHLIGHT_SECONDARY_HOVER_COLOUR};
        color: ${HIGHLIGHT_PRIMARY_COLOUR};
        border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
      }

      :active {
        background-color: ${HIGHLIGHT_SECONDARY_HOVER_COLOUR};
        color: ${HIGHLIGHT_PRIMARY_ACTIVE_COLOUR};
        border-color: ${HIGHLIGHT_PRIMARY_ACTIVE_COLOUR};
      }

      :disabled {
        background-color: ${NEUTRAL_1_COLOUR};
        color: ${NEUTRAL_6_COLOUR};
        border-color: ${NEUTRAL_6_COLOUR};
      }
    `;
  } else if (props?.$tertiary) {
    return css`
      background: none;
      color: ${NEUTRAL_10_COLOUR};
      border: none;

      :hover,
      :focus-visible {
        background-color: ${NEUTRAL_3_COLOUR};
        color: ${NEUTRAL_10_COLOUR};
      }

      :active {
        background-color: ${NEUTRAL_4_COLOUR};
        color: ${NEUTRAL_10_COLOUR};
      }

      :disabled {
        background: none;
        color: ${NEUTRAL_6_COLOUR};
      }
    `;
  } else if (props?.$tertiaryHighlight) {
    return css`
      background: none;
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
      border: none;

      :hover,
      :focus-visible {
        background-color: ${HIGHLIGHT_SECONDARY_COLOUR};
        color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
      }

      :active {
        background-color: ${HIGHLIGHT_SECONDARY_HOVER_COLOUR};
        color: ${HIGHLIGHT_PRIMARY_ACTIVE_COLOUR};
      }

      :disabled {
        background: none;
        color: ${NEUTRAL_6_COLOUR};
      }
    `;
  } else if (props?.$success) {
    return css`
      background-color: ${SUCCESS_COLOUR};
      color: #fff;
      border-color: ${SUCCESS_COLOUR};

      :hover,
      :focus-visible {
        background-color: ${SUCCESS_HOVER_COLOUR};
        color: #fff;
        border-color: ${SUCCESS_HOVER_COLOUR};
      }

      :active {
        background-color: ${SUCCESS_ACTIVE_COLOUR};
        color: #fff;
        border-color: ${SUCCESS_ACTIVE_COLOUR};
      }

      :disabled {
        background-color: ${NEUTRAL_6_COLOUR};
        color: ${NEUTRAL_1_COLOUR};
        border-color: ${NEUTRAL_6_COLOUR};
      }
    `;
  } else {
    // primary (default) styles
    return css`
      background-color: ${HIGHLIGHT_PRIMARY_COLOUR};
      color: #fff;
      border-color: ${HIGHLIGHT_PRIMARY_COLOUR};

      :hover,
      :focus-visible {
        background-color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
        color: #fff;
        border-color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
      }

      :active {
        background-color: ${HIGHLIGHT_PRIMARY_ACTIVE_COLOUR};
        color: #fff;
        border-color: ${HIGHLIGHT_PRIMARY_ACTIVE_COLOUR};
      }

      :disabled {
        background-color: ${NEUTRAL_6_COLOUR};
        color: ${NEUTRAL_1_COLOUR};
        border-color: ${NEUTRAL_6_COLOUR};
      }
    `;
  }
};

const getCustomSize = (props: InternalProps) => {
  if (props?.$medium) {
    return css`
      height: 30px;
      ${FONT_14PX_MEDIUM};
      border-radius: 6px;
      padding: 5px 7px;
    `;
  } else if (props?.$small) {
    return css`
      height: 22px;
      ${FONT_12PX_MEDIUM};
      border-radius: 4px;
      padding: 2px 5px;
    `;
  } else {
    // large (default) styles
    return css`
      height: 36px;
      ${FONT_16PX_MEDIUM};
      border-radius: 6px;
      padding: 6px 8px;
    `;
  }
};
const getIconSize = (props: InternalProps) => {
  if (props?.$small) {
    return css`
      font-size: 14px;
    `;
  } else if (props?.$medium) {
    return css`
      font-size: 16px;
    `;
  }
  return css`
    font-size: 20px;
  `;
};
const getIconPosition = (props: InternalProps) => {
  const iconMargin = props?.$hasChildren ? '4px' : '0px';

  if (props?.$iconPosition === 'right') {
    return css`
      flex-direction: row-reverse;

      .anticon {
        margin-left: ${iconMargin} !important;
      }

      span:not(.anticon) {
        margin-left: 0;
      }

      .anticon-loading {
        margin-right: 0 !important;
      }
    `;
  } else {
    // left (default) styles
    return css`
      flex-direction: row;

      .anticon {
        margin-right: ${iconMargin} !important;
      }

      span {
        margin-left: 0 !important;
      }
    `;
  }
};

const sharedStyles = css<InternalProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'fit-content')};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  transition: 0.3s ease all;
  white-space: nowrap;
  cursor: pointer;

  :disabled {
    cursor: default;
  }

  ${(props) => getCustomStyles(props)}
  ${(props) => getCustomSize(props)}
      // Resize the icon based on button size
    .anticon {
    display: flex;
    ${(props) => getIconSize(props)};
  }

  // Remove extra padding from the loading anticon
  .anticon-loading {
    padding-right: 0;
    padding-left: 0;
  }

  ${(props) => getIconPosition(props)}
  #react-app && .button-tooltip {
    ${(props) => getCustomStyles(props)}
    ${(props) => getIconSize(props)};
  }
`;

const StyledButton = styled.button<InternalProps>`
  &&& {
    ${sharedStyles};
  }
` as FunctionComponent<InternalProps>;

const StyledLink = styled(Link)<InternalProps>`
  &&& {
    /* Reset */
    all: unset;
    box-sizing: border-box;

    ${sharedStyles};
  }
` as FunctionComponent<InternalProps>;

// This is necessary as props exclusively for styling should not be passed through to the DOM
// $ prefix means used only in React
interface InternalProps {
  $primary?: CustomButtonProps['primary'];
  $secondary?: CustomButtonProps['secondary'];
  $tertiary?: CustomButtonProps['tertiary'];
  $tertiaryHighlight?: CustomButtonProps['tertiaryHighlight'];
  $success?: CustomButtonProps['success'];
  $large?: CustomButtonProps['large'];
  $medium?: CustomButtonProps['medium'];
  $small?: CustomButtonProps['small'];
  $danger?: CustomButtonProps['danger'];
  $iconPosition?: CustomButtonProps['iconPosition'];
  $fullWidth?: CustomButtonProps['fullWidth'];
  $hasChildren?: boolean;
}

export interface CustomButtonProps extends Partial<Omit<HTMLButtonElement, 'children'>> {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  tertiaryHighlight?: boolean;
  success?: boolean;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  danger?: boolean;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  tooltip?: string;
  link?: LinkProps;
  children?: ReactNode;
  loading?: boolean;
  $loadingMargin?: string;
  onClick?: MouseEventHandler<HTMLElement> | (() => void);
  fullWidth?: boolean;
}

export const CustomButton = ({
  primary,
  secondary,
  tertiary,
  tertiaryHighlight,
  success,
  large,
  medium,
  small,
  danger,
  icon,
  iconPosition,
  tooltip,
  link,
  children,
  loading,
  fullWidth,
  ...props
}: CustomButtonProps) => {
  if (link) {
    return (
      <StyledLink
        {...link}
        $primary={primary}
        $secondary={secondary}
        $tertiary={tertiary}
        $tertiaryHighlight={tertiaryHighlight}
        $success={success}
        $large={large}
        $medium={medium}
        $small={small}
        $danger={danger}
        $iconPosition={iconPosition}
        $fullWidth={fullWidth}
        $hasChildren={!!children}
        type="button"
        {...props}
      >
        {tooltip && <Tooltip title={tooltip} className="button-tooltip" />}
        {loading ? <LoadingSpinner /> : icon}
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      $primary={primary}
      $secondary={secondary}
      $tertiary={tertiary}
      $tertiaryHighlight={tertiaryHighlight}
      $success={success}
      $large={large}
      $medium={medium}
      $small={small}
      $danger={danger}
      $iconPosition={iconPosition}
      $fullWidth={fullWidth}
      $hasChildren={!!children}
      type="button"
      {...props}
    >
      {tooltip && <Tooltip title={tooltip} className="button-tooltip" />}
      {loading ? <LoadingSpinner /> : icon}
      {children}
    </StyledButton>
  );
};
