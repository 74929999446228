import styled from 'styled-components';

import { ContentSection } from 'app/modules/build-dragdrop/Builder/mockup/components/ContentSection';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import {
  BuilderCollection,
  BuilderCollectionItem,
  useBuilderContext,
} from 'app/modules/build-dragdrop/Builder/providers';
import { Collection, CollectionItem, SOURCE_VIDAPP } from 'api';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { NEUTRAL_7_COLOUR } from 'theme';
import { ExclamationCircleIcon, PlusIcon } from 'icons';
import { CustomButton } from 'components';
import { DRAWER_TYPE_ADD } from 'app/modules/build-dragdrop/Builder/const';

interface MixedViewProps {
  collection: Collection | BuilderCollection;
}

/* Display all collection items in their respective views */

const MixedViewWrapped = styled.div`
  padding: 12px 0 32px;
  flex-grow: 1;
  overflow-x: clip;
  max-width: 100%;
`;

const EmptyState = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 40%;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  flex-direction: column;
`;
const EmptyMain = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_7_COLOUR};
  margin-bottom: 4px;
`;
const EmptySecondary = styled.div`
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_7_COLOUR};
`;
const StyledExclamation = styled(ExclamationCircleIcon)`
  &&&& {
    color: ${NEUTRAL_7_COLOUR};
    font-size: 33px;
    margin-bottom: 12px;
  }
`;
const AddButton = styled(CustomButton)`
  &&&& {
    margin-top: 20px;
    border-radius: 6px;
  }
`;

const EmptyMixedView = ({ showAddButton }: { showAddButton?: boolean }) => {
  const { setDrawerContext } = useBuilderContext();
  const handleClick = () => {
    setDrawerContext({ drawerType: DRAWER_TYPE_ADD });
  };

  return (
    <EmptyState>
      <StyledExclamation />
      <EmptyMain>Nothing to display</EmptyMain>
      <EmptySecondary>Add content or sections.</EmptySecondary>
      {showAddButton && (
        <AddButton id="Simulator__Add" tertiaryHighlight medium icon={<PlusIcon />} onClick={handleClick}>
          Add
        </AddButton>
      )}
    </EmptyState>
  );
};

export const MixedView = ({ collection }: MixedViewProps) => {
  const { disableDragDrop } = useMockupContext();

  const hasItems = collection.Items?.length > 0;

  return (
    <MixedViewWrapped>
      {hasItems ? (
        collection.Items.map((item: CollectionItem | BuilderCollectionItem, idx: number) => {
          return (
            <ContentSection
              key={`MixedView_${item.TabItemId}`}
              item={item}
              idx={idx}
              isSourceContent={collection.DataSource !== SOURCE_VIDAPP}
            />
          );
        })
      ) : (
        <EmptyMixedView showAddButton={!disableDragDrop} />
      )}
    </MixedViewWrapped>
  );
};
