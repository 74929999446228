import { useQuery } from 'react-query';
import { useAxiosInstance, getAppleCategories } from 'api';
import { useAppBeingEdited } from '../app-context';

export const useAppleCategories = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  return useQuery(['appleCategories', appId], () => getAppleCategories(client), {
    staleTime: Infinity,
    refetchOnMount: 'always',
  });
};
