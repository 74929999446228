import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosInstance, getAppBasicInfo } from 'api';
import { useAppBeingEdited } from '../app-context';

export const useAppBasicInfo = (
  staleTime?: UseQueryOptions['staleTime'],
  refetchOnMount?: UseQueryOptions['refetchOnMount'],
) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const query = useQuery(['appBasicInfo', appId], () => getAppBasicInfo(client, appId), {
    staleTime: staleTime ?? 30000,
    refetchOnMount: refetchOnMount ?? true,
    enabled: !!appId, // Don't call fetcher with empty AppId
  });

  const isMigratedLegacy = query?.data?.Type === 2;

  return {
    ...query,
    isMigratedLegacy,
  };
};
