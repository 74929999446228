import { createContext, ReactNode, useContext, useMemo } from 'react';

import { Collection, CollectionItem } from 'api';
import { BuilderCollection, BuilderCollectionItem } from 'app/modules/build-dragdrop/Builder/providers';

interface ContextValue {
  onClick?: () => void;
  onItemClick?: (item: CollectionItem | BuilderCollectionItem) => void;
  templateId: number;
  collection?: Collection | BuilderCollection;
  isDetailsView?: boolean;
}

const AppCollectionContext = createContext<ContextValue>({ templateId: 0 });

interface ProviderProps extends Omit<ContextValue, 'onClick'> {
  children?: ReactNode;
  onClick?: () => void;
}

const AppCollectionContextProvider = ({ children, onClick, collection, ...props }: ProviderProps) => {
  // Ensure items is in the dependencies here otherwise rearranging may not trigger some re-renders
  const tidiedCollection = useMemo(() => {
    return { ...collection, Items: collection?.Items || [] } as BuilderCollection;
  }, [collection, collection?.Items]);

  return (
    <AppCollectionContext.Provider
      value={{ ...props, onClick: onClick ? onClick : undefined, collection: tidiedCollection }}
    >
      {children}
    </AppCollectionContext.Provider>
  );
};
const useAppCollectionContext = () => {
  const context = useContext(AppCollectionContext);
  if (context === undefined) {
    throw new Error('useAppCollectionContext must be used within a AppCollectionContextProvider');
  }
  return context;
};

export { AppCollectionContextProvider, useAppCollectionContext };
