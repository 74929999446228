import { useCallback } from 'react';
import { UseQueryOptions } from 'react-query';
import { CollectionItem, ThumbnailSize } from 'api';
import { useVideos, useCollections } from 'hooks';
import { BuilderCollectionItem } from 'providers';

interface CollectionItemDetails {
  id: number | string;
  type: 'video' | 'collection';
  detailedType: string;
  title: string;
  thumbnailUrl: string | null;
  duration?: number;
}

export const useCollectionItems = (
  staleTime?: UseQueryOptions['staleTime'],
  refetchOnMount?: UseQueryOptions['refetchOnMount'],
) => {
  const { getVideoThumbnailUrl, getVideoById } = useVideos(staleTime, refetchOnMount);
  const { getCollectionThumbnailUrl, getCollectionById } = useCollections(staleTime, refetchOnMount);

  const getCollectionItemDetails = useCallback(
    (item: CollectionItem | BuilderCollectionItem, thumbnailSize: ThumbnailSize) => {
      const details: CollectionItemDetails = {
        id: item.ChildId,
        type: 'video',
        detailedType: 'video',
        title: '',
        thumbnailUrl: null,
      };

      if (item.Type === 'video') {
        const video = getVideoById(item.ChildId as number);
        if (video) {
          details.type = 'video';
          details.detailedType = video.Type;
          details.title = video.Title;
          details.thumbnailUrl = getVideoThumbnailUrl(item.ChildId as number, thumbnailSize);
          details.duration =
            video.DurationSeconds && video.DurationSeconds !== '' ? parseInt(video.DurationSeconds) : undefined;
        }
      } else if (item.Type === 'collection') {
        const collection = getCollectionById(item.ChildId as number);
        if (collection) {
          details.type = 'collection';
          details.detailedType = collection.SourceType !== '' ? collection.SourceType : 'collection';
          details.title = collection.Name;
          details.thumbnailUrl = getCollectionThumbnailUrl(item.ChildId as number, thumbnailSize);
        }
      }

      return details;
    },

    [getVideoById, getCollectionById, getVideoThumbnailUrl, getCollectionThumbnailUrl],
  );

  return { getCollectionItemDetails };
};
