import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { ContentDescription, SegmentProps } from 'components';
import { COLLECTION_PROPERTY_HTMLPAGE_DESC } from 'api';

interface HtmlPageDescriptionProps extends SegmentProps {
  height?: number;
}

export const HtmlPageDescription = ({
  TabId,
  Description,
  ...props
}: Pick<BuilderCollection, 'TabId' | 'Description'> & HtmlPageDescriptionProps) => {
  const { setCollectionProperty } = useContent();
  const { setCollectionPropertyToSave } = useSaveContext();

  const handleDescChange = (description: string) => {
    setCollectionProperty(TabId, COLLECTION_PROPERTY_HTMLPAGE_DESC, description);
    setCollectionPropertyToSave(TabId, COLLECTION_PROPERTY_HTMLPAGE_DESC, description);
  };

  return (
    <ContentDescription description={Description} sourceDescription="" onDescChange={handleDescChange} {...props} />
  );
};
