import { useCollectionDiff } from 'app/modules/publish-diffs/PublishDiff/hooks/usePublishDiff';
import { Heading } from 'app/modules/publish-diffs/PublishDiff/components/Heading';
import { CollectionDiffDisplay } from 'app/modules/publish-diffs/PublishDiff/components/CollectionDiffDisplay';
import { CollectionSummaryDisplay } from 'app/modules/publish-diffs/PublishDiff/components/CollectionSummaryDisplay';
import { FONT_16PX_SEMIBOLD } from 'font';
import styled from 'styled-components';

interface CollectionDiffsProps {
  stage: 'Preview' | 'Live';
}

const Subheading = styled.div`
  ${FONT_16PX_SEMIBOLD};
  margin-bottom: 8px;
`;
const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
`;

export const CollectionDiffs = ({ stage }: CollectionDiffsProps) => {
  const { data } = useCollectionDiff(stage);

  return (
    <>
      <Heading>Collection Changes</Heading>
      {data?.NewCollections && data?.NewCollections?.length > 0 && (
        <>
          <Subheading>New Collections</Subheading>
          <FlexBox>
            {data?.NewCollections?.map((c) => {
              return <CollectionSummaryDisplay key={c.TabId} {...c} />;
            })}
          </FlexBox>
        </>
      )}
      {data?.DeletedCollections && data?.DeletedCollections?.length > 0 && (
        <>
          <Subheading>Deleted Collections</Subheading>
          <FlexBox>
            {data?.DeletedCollections?.map((c) => {
              return <CollectionSummaryDisplay key={c.TabId} {...c} />;
            })}
          </FlexBox>
        </>
      )}
      {data?.ModifiedCollections && data?.ModifiedCollections?.length > 0 && (
        <>
          <Subheading>Modified</Subheading>
          <FlexBox>
            {data?.ModifiedCollections?.map((c) => {
              return <CollectionDiffDisplay {...c} key={c.TabId} />;
            })}
          </FlexBox>
        </>
      )}
    </>
  );
};
