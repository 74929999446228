import { getAppAccounts, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useQuery } from 'react-query';

export const useAppAccounts = () => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const query = useQuery(['app_accounts', appId], () => getAppAccounts(client, appId));
  const getAccountById = (userId: number) => {
    return query.data?.Accounts.find((account) => account.UserId === userId);
  };
  return { ...query, getAccountById };
};
