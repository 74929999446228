import { useCallback } from 'react';

import { ContentPreviewVideo } from 'components';
import { useSaveContext, BuilderVideo, useContent } from 'providers';

interface VideoPreviewVideoProps {
  isInstructionalVideo?: boolean; // Functions the same as PreviewVideo, but saves the 'InstructionalVideoId' property instead of 'PreviewVideoId'
}

export const VideoPreviewVideo = ({
  VideoId,
  isInstructionalVideo,
}: Pick<BuilderVideo, 'VideoId'> & VideoPreviewVideoProps) => {
  const { setVideoValue, videos } = useContent();
  const { setVideoValueToSave } = useSaveContext();

  const key = isInstructionalVideo ? 'InstructionalVideoId' : 'PreviewVideoId';

  const handlePreviewVideoChange = useCallback(
    (previewVideoId: number) => {
      const value = previewVideoId.toString();
      setVideoValue(VideoId, key, value);
      setVideoValueToSave(VideoId, key, value);
    },
    [setVideoValueToSave, setVideoValue, VideoId],
  );

  const getDefaultValue = () => {
    const videoId = videos[VideoId][key];
    if (!videoId || videoId === '-1') {
      return 0;
    }

    return parseInt(videoId);
  };

  return <ContentPreviewVideo defaultValue={getDefaultValue()} handleChange={handlePreviewVideoChange} />;
};
