import styled from 'styled-components';

import { contentHasPreviewVideo, getContentOverlay } from 'utils';
import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { VIDEO_DETAILS_RESOURCES } from 'api';
import { useAppProperties } from 'hooks';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { ChevronDownIcon, ChevronLeftIcon } from 'icons';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { BuilderVideo } from '../../../providers';

const HeroImage = styled(ContentThumbnail)`
  width: 100%;
  height: unset;
  aspect-ratio: 16/9;
  border-radius: 0;
`;

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
`;

const PlayIcon = styled.div<{ $color?: string }>`
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 25px 0 25px 50px;
  position: absolute;
  top: calc(50% - 25px);
  right: calc(50% - 25px);
  border-color: ${({ $color }) =>
    $color ? `transparent transparent transparent #${$color}` : 'transparent transparent transparent white'};
`;

const StyledBackButton = styled(ChevronDownIcon)`
  &&&& {
    color: white;
    position: absolute;
    font-size: 28px;
    top: 12px;
    left: 12px;
  }
`;

// The ResourceBelow Video details screen uses a different back button for some reason
const AlternateBackButton = styled.div<{ $backgroundColor: string; $color: string }>`
  position: absolute;
  top: 16px;
  left: 16px;
  height: 32px;
  width: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ $color }) => $color};
  font-size: 32px;

  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding-right: 2px; // Chevron is slightly off center
`;

const BackRow = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
`;

export const VideoPlayer = ({ video }: { video: BuilderVideo }) => {
  const { data: appProperties } = useAppProperties();
  const { goBack } = useMockupContext();
  const { getDesignProperty } = useAppTheme();

  const backgroundHex = getDesignProperty('backgroundHEX') as string;
  const primaryItemHex = getDesignProperty('primaryItemHEX') as string;

  const isResourcesBelow = appProperties?.ItemDetailsDesign === VIDEO_DETAILS_RESOURCES;

  return (
    <HeroContainer>
      <HeroImage
        thumbnail={video}
        defaultBorder="2px solid whitesmoke"
        overlayText={!isResourcesBelow ? getContentOverlay(video, appProperties) : ''}
        showPreviewTag={contentHasPreviewVideo(video)}
        overrideRadius="0px"
      />
      {video.Type === 'video' && <PlayIcon $color={appProperties?.CustomPlayButtonColor} />}
      {isResourcesBelow ? (
        <AlternateBackButton onClick={goBack} $backgroundColor={backgroundHex} $color={primaryItemHex}>
          <ChevronLeftIcon />
        </AlternateBackButton>
      ) : (
        <BackRow>
          <StyledBackButton onClick={goBack} />
        </BackRow>
      )}
    </HeroContainer>
  );
};
