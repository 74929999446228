import { useMemo } from 'react';
import styled from 'styled-components';

import {
  CollectionDescriptionPreview,
  CollectionTitle,
  ContentAdvancedSettings,
  ContentSourceTitle,
  DevTag,
} from 'components';
import { getCollectionName, isUserDev, isUserAdmin } from 'utils';
import { hasHeroImage, isHorizontalScroll, ITEM_TYPE_COLLECTION, ITEM_TYPE_TAB, SOURCE_VIDAPP } from 'api';
import { useContent } from 'providers';

import {
  CollectionCustomItemList,
  CollectionDisplayType,
  CollectionItemList,
  CollectionItemsDrawer,
  CollectionSource,
  CollectionTemplateDrawer,
  CollectionThumbnail,
  ContentResourceList,
  DrawerDeleteButton,
  DrawerFooter,
  DrawerHeading,
  DrawerScrollContainer,
} from 'app/modules/build-dragdrop/Builder/drawer/components';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup';

const DrawerFlex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubTabDrawer = () => {
  const { collections } = useContent();
  const { drawerContext, visiblePageId } = useBuilderContext();
  const { currentTabId, currentPage } = useMockupContext();

  const imJustATab = visiblePageId === currentTabId;

  // The Collection linked to this subtab
  const collection = useMemo(() => {
    if (!currentTabId) {
      return undefined;
    }
    if (currentPage?.subItemId) {
      return collections[currentPage.subItemId];
    }
    return collections[currentTabId];
  }, [currentPage?.subItemId, collections]);

  const item = useMemo(() => {
    if (imJustATab || !currentTabId) {
      return undefined;
    }
    const parentCollection = collections[currentTabId];
    return parentCollection.Items.find((item) => item.TabItemId === currentPage?.subTabItemId);
  }, [currentPage?.subTabItemId, collections, currentTabId]);

  const lastManStanding = useMemo(() => {
    if (imJustATab) {
      return true;
    }
    const parentCollection = currentTabId ? collections[currentTabId] : undefined;
    return parentCollection?.Items.length === 1;
  }, [collections, currentTabId]);

  /**
   * All Subtabs have a similar drawer now
   * If the collection is set to have a hero image -> Show Thumbnail/Description/Resources
   */

  const suffix = isUserDev() ? (
    <div>
      <DevTag>{collection?.TemplateId}</DevTag>
      <DevTag>{collection?.DataSource}</DevTag>
      <DevTag>{collection?.TabId}</DevTag>
    </div>
  ) : isUserAdmin() ? (
    <div>
      <DevTag label="V">{collection?.TabId}</DevTag>
      <DevTag label="S">{collection?.SourceId}</DevTag>
    </div>
  ) : null;

  const hasHeroImageIncludingFallback = useMemo(() => {
    if (!collection) {
      return false;
    }
    // A horizontal scroll can have a hero image if its a playlist and falls back to List Large Hero
    const hasCollections = collection?.Items?.some((i) => i.Type === ITEM_TYPE_COLLECTION || i.Type === ITEM_TYPE_TAB);
    return (!hasCollections && isHorizontalScroll(collection.TemplateId)) || hasHeroImage(collection?.TemplateId);
  }, [collection, collection?.Items]);

  return drawerContext?.showItemsView && collection ? (
    <CollectionItemsDrawer collection={collection} />
  ) : drawerContext?.showCollectionDisplayView && collection ? (
    <CollectionTemplateDrawer collection={collection} />
  ) : (
    <DrawerFlex>
      {collection && (
        <>
          <DrawerHeading
            heading={getCollectionName(collection)}
            type={
              <SpaceBetween>
                <div>Sub-Tab</div>
                {suffix}
              </SpaceBetween>
            }
          />
          <DrawerScrollContainer>
            <CollectionTitle
              size="middle"
              TabId={collection.TabId}
              Name={collection.Name}
              $marginTop="0"
              showDeepLink
              disabled={imJustATab}
            />
            <CollectionSource
              SourceId={collection.SourceId}
              DataSource={collection.DataSource}
              SourceType={collection.SourceType}
            />
            <CollectionDisplayType title="Sub-Tab Layout" collection={collection} />
            {hasHeroImageIncludingFallback && <CollectionThumbnail collection={collection} />}

            {collection.DataSource === SOURCE_VIDAPP ? (
              <CollectionCustomItemList collection={collection} />
            ) : (
              <CollectionItemList Items={collection?.Items} />
            )}
            {hasHeroImage(collection.TemplateId) && <CollectionDescriptionPreview collection={collection} />}
            {hasHeroImage(collection.TemplateId) && <ContentResourceList Resources={collection?.Resources} />}
            <ContentSourceTitle size="middle" DataSource={collection.DataSource} SourceName={collection.SourceName} />
            <ContentAdvancedSettings collection={collection} isDrawer isSubTab />
          </DrawerScrollContainer>
          <DrawerFooter>{!lastManStanding && item && <DrawerDeleteButton item={item} isDeleteSubTab />}</DrawerFooter>
        </>
      )}
    </DrawerFlex>
  );
};
