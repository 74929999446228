import { WizardButton, WizardButtonProps } from './WizardButton';
import { SUCCESS_COLOUR } from 'theme';

export interface NextButtonProps extends WizardButtonProps {
  // Pass through props as $ will not propagate
  buttonType?: WizardButtonProps['$type'];
}

export const NextButton = ({ buttonType, ...props }: NextButtonProps) => {
  return (
    <WizardButton
      $fontSize="16px"
      $height="48px"
      $width="fit-content"
      $fontWeight="600"
      $type={buttonType}
      $defaultColor={SUCCESS_COLOUR}
      {...props}
    />
  );
};
