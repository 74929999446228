import { useMemo } from 'react';
import styled from 'styled-components';

import { BuilderCollection, BuilderVideo, useContent, useLocalAppProperties } from 'providers';
import { APP_FONT_15PX_REGULAR, APP_FONT_17PX_MEDIUM, APP_FONT_18PX_MEDIUM } from 'mockup-font';
import {
  DownloadCloudIcon,
  HeadphonesIcon,
  HistoryClockIcon,
  WatchlistIcon,
  ChevronRightIcon,
  AddToPlaylistIcon,
} from 'icons';
import { CustomColor, useCustomColor } from 'utils';
import { DEFAULT_COLLECTION, DEFAULT_COLLECTION_ITEM } from 'api';

import { useAppTheme } from '../../hooks';
import { AppCollection } from '../AppCollection';

const Wrapper = styled.div<{ $backgroundColor: string } & CustomColor>`
  width: 100%;
  min-height: 100%;
  cursor: default;
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 10px 0 0;
`;

const FavoritesWrapper = styled.div`
  margin-bottom: 11px;
`;

const Divider = styled.div`
  border-top: 1px solid #cecece;
  margin: 4px 17px;
`;

const List = styled.div`
  padding: 0 18px;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
  margin: 22px 0;
  ${APP_FONT_17PX_MEDIUM};
`;

const Label = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 26px;
    margin-right: 17px;
    fill: none;
  }
`;

const Chevron = styled(ChevronRightIcon)`
  &&&& {
    font-size: 30px;
  }
`;

const MyPlaylists = styled.div`
  padding: 22px 18px 0 18px;
`;

const MyPlaylistsTitle = styled.div`
  ${APP_FONT_18PX_MEDIUM};
`;

const MyPlaylistsSection = styled.div<CustomColor>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 82px;
  color: ${useCustomColor};
`;

const AddIcon = styled(AddToPlaylistIcon)`
  &&&& {
    font-size: 67px;
  }
`;

const AddText = styled.div`
  ${APP_FONT_15PX_REGULAR};
  width: 177px;
  text-align: center;
  margin-top: 13px;
`;

const getListItem = (icon: JSX.Element, label: string, visible = true) => {
  if (visible) {
    return (
      <ListItem>
        <Label>
          {icon}
          {label}
        </Label>
        <Chevron />
      </ListItem>
    );
  }
  return null;
};

export const MyPlaylistsView = () => {
  const { videos } = useContent();
  const { properties, getPropertyOrDefault } = useLocalAppProperties();
  const { getDesignProperty } = useAppTheme();
  const PrimaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const SecondaryItemHEX = getDesignProperty('secondaryItemHEX') as string;
  const BackgroundHEX = getDesignProperty('backgroundHEX') as string;

  const randomVideos = useMemo(() => {
    if (videos) {
      const arr: BuilderVideo[] = [];

      const videoArray = Object.values(videos);
      const videosWithThumbnails = videoArray.filter((video) => video.Thumbnails.medium); // Only include videos with thumbnails
      const videosToDisplay = Math.min(videosWithThumbnails.length, 4); // Return 4 videos, unless there are less than 4 videos with thumbnails

      const indexes = new Set<number>(); // Generate up to 4 random indexes
      while (indexes.size !== videosToDisplay) {
        indexes.add(Math.floor(Math.random() * videosWithThumbnails.length));
      }

      indexes.forEach((i) => arr.push(videosWithThumbnails[i]));

      return arr;
    }
    return [];
  }, [videos]);

  const favoritesCollection: BuilderCollection = useMemo(
    () => ({
      ...DEFAULT_COLLECTION,
      Name: getPropertyOrDefault('CustomFavoritesLabel'),
      Items: randomVideos.map((v, idx) => ({
        ...DEFAULT_COLLECTION_ITEM,
        TabId: 1,
        ChildId: v.VideoId,
        AppId: '',
        Position: idx + 1,
        Type: 'video',
        SubType: 'video',
      })),
      TabId: 1,
      AppId: '',
      TemplateId: 3,
      Thumbnails: {},
    }),
    [randomVideos, getPropertyOrDefault],
  );

  return (
    <Wrapper $color={PrimaryItemHEX} $backgroundColor={BackgroundHEX}>
      {properties.HideSidebarFavourites !== '1' && (
        <>
          <FavoritesWrapper>
            <AppCollection collection={favoritesCollection} templateId={3} />
          </FavoritesWrapper>
          <Divider />
        </>
      )}
      <List>
        {getListItem(
          <DownloadCloudIcon />,
          getPropertyOrDefault('CustomDownloadsLabel'),
          properties.HideSidebarDownloads !== '1',
        )}
        {getListItem(
          <HeadphonesIcon />,
          getPropertyOrDefault('CustomAudioDownloadsLabel'),
          properties.HideSidebarAudioDownloads !== '1',
        )}
        {getListItem(<HistoryClockIcon />, getPropertyOrDefault('CustomRecentlyWatchedLabel'))}
        {getListItem(
          <WatchlistIcon />,
          getPropertyOrDefault('CustomWatchlistLabel'),
          properties.HideSidebarWatchlist !== '1',
        )}
      </List>
      <Divider />
      <MyPlaylists>
        <MyPlaylistsTitle>{properties.CustomMyPlaylistsLabel ?? 'My Playlists'}</MyPlaylistsTitle>
        <MyPlaylistsSection $color={SecondaryItemHEX}>
          <AddIcon />
          <AddText>Looks like you haven't added any playlists yet.</AddText>
        </MyPlaylistsSection>
      </MyPlaylists>
    </Wrapper>
  );
};
