import { FlexRow, InstructionText, WrappedDot } from './Components';

export const KajabiSteps = () => {
  return (
    <>
      <FlexRow>
        <WrappedDot outlined>1</WrappedDot>
        <InstructionText>Create a Kajabi assistant account</InstructionText>
      </FlexRow>
      <FlexRow>
        <WrappedDot outlined>2</WrappedDot>
        <InstructionText>Sync your Kajabi Courses</InstructionText>
      </FlexRow>
      <FlexRow>
        <WrappedDot outlined>3</WrappedDot>
        <InstructionText>Customize your color scheme and branding</InstructionText>
      </FlexRow>
    </>
  );
};
