import { getAccountExists, useAxiosInstance } from 'api';
import { useQuery } from 'react-query';
import { validateEmail } from 'utils';

// Determine whether this email is valid to check for
const isEnabledEmail = (email?: string) => {
  if (!email || email.includes('@vidapp.com')) {
    return false;
  }
  return validateEmail(email);
};

export const useAccountExists = (email?: string) => {
  const client = useAxiosInstance();
  return useQuery(
    ['account/exists', email],
    () => {
      return email ? getAccountExists(client, email) : null;
    },
    {
      enabled: isEnabledEmail(email),
    },
  );
};
