import { VideoDescriptionPreview } from 'components';
import { useContent } from 'providers';

import { DrawerHeading, DrawerScrollContainer } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { useBuilderContext } from '../../../providers';
import { useMemo } from 'react';

export const SectionHeaderDrawer = () => {
  const { videos } = useContent();
  const { drawerContext } = useBuilderContext();

  const sectionHeader = useMemo(() => {
    if (drawerContext?.itemId) {
      return videos[parseInt(drawerContext.itemId)];
    }
    return undefined;
  }, [drawerContext?.itemId, videos]);

  return sectionHeader ? (
    <>
      <DrawerHeading heading={sectionHeader.Description} type="Text" />
      <DrawerScrollContainer>
        <VideoDescriptionPreview video={sectionHeader} title="Text" placeholder="" disabled />
      </DrawerScrollContainer>
    </>
  ) : null;
};
