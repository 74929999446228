import styled from 'styled-components';
import { DumbbellIcon } from 'icons';
import { NEUTRAL_7_COLOUR } from 'theme';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';

const Flex = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${NEUTRAL_7_COLOUR};

  padding-bottom: 100px;
`;
const StyledDumbbell = styled(DumbbellIcon)`
  font-size: 33px;
`;
const Head = styled.div`
  margin-top: 12px;
  ${FONT_16PX_MEDIUM};
`;
const Sub = styled.div`
  margin-top: 4px;
  ${FONT_14PX_REGULAR};
  padding-left: 100px;
  padding-right: 100px;
`;
export const WorkoutView = () => {
  return (
    <Flex>
      <StyledDumbbell />
      <Head>Workout</Head>
      <Sub>Your workout will display here in your live app.</Sub>
    </Flex>
  );
};
