import { useMemo, useState } from 'react';

import { BuilderProduct, useLocalProducts } from 'providers';

import { OfferContentTable } from './OfferContentTable';
import { ModalContextProvider } from './OfferContentModal/customise-items/providers/modal-provider';
import { OfferContentModal } from './OfferContentModal/customise-items';

interface OfferContentProps {
  productId: number | string;
}

export const OfferContent = ({ productId }: OfferContentProps) => {
  const { products } = useLocalProducts();
  const [modalVisible, setModalVisible] = useState(false);

  const product = useMemo(() => products?.get(productId) as BuilderProduct, [products, productId]);

  return (
    <>
      <OfferContentTable product={product} setModalVisible={setModalVisible} />
      {modalVisible && (
        <ModalContextProvider>
          <OfferContentModal product={product} modalVisible={modalVisible} setModalVisible={setModalVisible} />
        </ModalContextProvider>
      )}
    </>
  );
};
