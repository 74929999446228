import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { DragDropContext, DraggableProvidedDragHandleProps, DropResult, Droppable } from 'react-beautiful-dnd';

import { Collapse } from 'components';

import { TreeItem, renderItems } from '../ContentCollectionTree';
import { TreeRow } from '.';
import { useContentNavigationContext } from 'app/modules/content/providers';

const StyledCollapse = styled(Collapse)`
  .radix-collapse-content {
    padding-left: 24px;
  }
`;

const ItemsWrapper = styled.div`
  padding-top: 14px;
`;

interface TreeCollectionProps {
  collection: TreeItem;
  parentTabId: string | number;
  parentDataSource: string;
  activeKeys: (string | number)[];
  setActiveKeys: Dispatch<SetStateAction<(string | number)[]>>;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  handleDragEnd?: (DropResult: DropResult) => void;
  isDragging?: boolean;
}

export const TreeCollection = ({
  collection,
  parentTabId,
  parentDataSource,
  activeKeys,
  setActiveKeys,
  dragHandleProps,
  handleDragEnd,
  isDragging,
}: TreeCollectionProps) => {
  const { collectionIsEditable } = useContentNavigationContext();

  const { itemId, dataSource, sourceType } = collection;
  const isEmpty = !collection.items || collection.items.length === 0;
  const isOpen = activeKeys.includes(itemId);

  const draggable = collectionIsEditable(dataSource, sourceType);

  const handleTriggerClick = () => {
    setActiveKeys((prevState) => {
      const newState = [...prevState];
      if (newState.includes(itemId)) {
        return newState.filter((key) => key !== itemId);
      } else {
        return [...newState, itemId];
      }
    });
  };

  return (
    <StyledCollapse
      treeStyle
      onChange={() => handleTriggerClick()}
      isOpen={activeKeys.includes(itemId)}
      rowContent={
        <TreeRow
          item={collection}
          parentTabId={parentTabId}
          parentDataSource={parentDataSource}
          setActiveKeys={setActiveKeys}
          dragHandleProps={dragHandleProps}
          isEmpty={isEmpty}
          isOpen={isOpen}
          isDragging={isDragging}
          handleTriggerClick={handleTriggerClick}
        />
      }
      hideTrigger
    >
      {collection.items && !isEmpty && (
        <>
          {draggable && handleDragEnd ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={`${parentTabId}|${collection.itemId}`}>
                {(provided) => {
                  return (
                    <ItemsWrapper ref={provided.innerRef} {...provided.droppableProps}>
                      {renderItems({
                        items: collection.items as TreeItem[],
                        activeKeys,
                        setActiveKeys,
                        parentTabId: collection.itemId,
                        parentDataSource: collection.dataSource ?? '',
                        draggable,
                        handleDragEnd,
                      })}
                      {provided.placeholder}
                    </ItemsWrapper>
                  );
                }}
              </Droppable>
            </DragDropContext>
          ) : (
            <ItemsWrapper>
              {renderItems({
                items: collection.items,
                activeKeys,
                setActiveKeys,
                parentTabId: collection.itemId,
                parentDataSource: collection.dataSource ?? '',
              })}
            </ItemsWrapper>
          )}
        </>
      )}
    </StyledCollapse>
  );
};
