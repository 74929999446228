import { useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { useAppProperties, useTemplates } from 'hooks';
import { FONT_16PX_MEDIUM } from 'font';

import { groupTemplates } from 'app/modules/build-dragdrop/Builder/util';
import { AddTemplate, DrawerScrollContainer } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { useContent, useDataSource } from 'providers';

import { SOURCE_KAJABI, SOURCE_VHX, Template } from 'api';
import {
  FEATURE_TEMPLATE_ALLCOURSES,
  FEATURE_TEMPLATE_MYCOURSES,
  FEATURE_TEMPLATE_TVOD,
} from 'app/modules/build-dragdrop/Builder/const';

const TemplateGroupWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;
const TemplateGroupTitle = styled.div`
  ${FONT_16PX_MEDIUM};
  color: inherit;
  margin-bottom: 16px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Col = styled.div`
  width: calc(50% - 8px);
`;

// Context Mixed

const getExcludedTemplates = (dataSource: string, options?: { rolloutCourseView: boolean }) => {
  if (dataSource === SOURCE_KAJABI || options?.rolloutCourseView) {
    return [];
  }
  return [44];
};
const getFeatureTemplates = (groupedTemplates: [string, Template[]][]) => {
  const { 1: templates } = groupedTemplates.find(([name]) => name === 'Feature') || { 1: [] as Template[] };
  return templates;
};

export const AddSection = () => {
  const { getTemplateOptionsByType } = useTemplates();
  const dataSource = useDataSource();
  const { data: appProperties } = useAppProperties();
  const { collections } = useContent();
  const groupedTemplates = useMemo(() => {
    const templates = getTemplateOptionsByType('block');
    const groupedTemplates = templates
      ? groupTemplates(
          templates,
          getExcludedTemplates(dataSource, { rolloutCourseView: appProperties?.RolloutCourseView === '1' }),
        )
      : [];

    if (dataSource === SOURCE_KAJABI) {
      const templates = getFeatureTemplates(groupedTemplates);
      templates?.push({ TemplateId: FEATURE_TEMPLATE_MYCOURSES, DisplayName: 'My Courses' } as Template);
      templates?.push({ TemplateId: FEATURE_TEMPLATE_ALLCOURSES, DisplayName: 'All Courses' } as Template);
    } else if (dataSource === SOURCE_VHX) {
      const myProductsCollection = Object.values(collections).find((c) => c.SourceId == 'MyProducts');
      // If they don't have any TVOD content the collection won't sync
      if (myProductsCollection) {
        const templates = getFeatureTemplates(groupedTemplates);
        templates?.push({ TemplateId: FEATURE_TEMPLATE_TVOD, DisplayName: 'TVOD' } as Template);
      }
    }
    return groupedTemplates;
  }, [getTemplateOptionsByType, appProperties]);

  return (
    <DrawerScrollContainer>
      {groupedTemplates.map(([groupName, templates]) => {
        if (templates.length === 0) {
          // Don't render title of empty groups of templates
          return null;
        }
        return (
          <TemplateGroupWrapper key={groupName}>
            <TemplateGroupTitle>{groupName}</TemplateGroupTitle>
            <Row>
              <Droppable droppableId={`block_${groupName}`} isDropDisabled={true}>
                {(provided) => (
                  <Col {...provided.droppableProps} ref={provided.innerRef}>
                    {templates.map((t, idx) => {
                      if (idx % 2 === 1) {
                        return null; // Render every second template (even)
                      }
                      return <AddTemplate template={t} key={t.TemplateId} prefix={groupName} idx={idx} />;
                    })}
                  </Col>
                )}
              </Droppable>
              <Droppable droppableId={`block_${groupName}`} isDropDisabled={true}>
                {(provided) => (
                  <Col {...provided.droppableProps} ref={provided.innerRef}>
                    {templates.map((t, idx) => {
                      if (idx % 2 === 0) {
                        return null; // Render every second template (odd)
                      }
                      return <AddTemplate template={t} key={t.TemplateId} prefix={groupName} idx={idx} />;
                    })}
                  </Col>
                )}
              </Droppable>
            </Row>
          </TemplateGroupWrapper>
        );
      })}
    </DrawerScrollContainer>
  );
};
