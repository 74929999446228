import styled from 'styled-components';
import { Checkbox } from 'antd';
import { NEUTRAL_9_COLOUR, HIGHLIGHT_PRIMARY_HOVER_COLOUR, HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_6_COLOUR } from 'theme';
import { SettingsFormItem } from 'components';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

interface StyledCheckboxProps {
  margin?: string;
  reverseOrder?: boolean;
}

const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  #react-app && {
    color: ${NEUTRAL_9_COLOUR};
    margin: ${(props) => props.margin ?? '0 0 8px 0'};
    flex-direction: ${({ reverseOrder }) => (reverseOrder ? 'row-reverse' : 'row')};

    .ant-checkbox {
      top: 4px;

      .ant-checkbox-inner {
        border-radius: 4px;
        border-width: 2px;
        width: 18px;
        height: 18px;
      }

      :hover {
        .ant-checkbox-inner {
          border-color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
          background-color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
        }

        ::after {
          visibility: hidden;
        }
      }

      :not(.ant-checkbox-checked) {
        :hover {
          .ant-checkbox-inner {
            border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
            background: none !important;
          }
        }

        .ant-checkbox-inner {
          border-color: ${NEUTRAL_6_COLOUR};
        }
      }
    }
  }
`;

interface SettingsCheckboxInputProps {
  label: string | JSX.Element;
  name?: string;
  margin?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  width?: string;
  reverseOrder?: boolean;
}

export const SettingsCheckboxInput = ({ label, name, width, ...props }: SettingsCheckboxInputProps) => (
  <SettingsFormItem name={name ?? ''} valuePropName={name ? 'checked' : undefined} width={width}>
    <StyledCheckbox {...props}>{label}</StyledCheckbox>
  </SettingsFormItem>
);
