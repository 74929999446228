import { ReactNode } from 'react';
import styled from 'styled-components';

import { NAV_HEADER_MAX } from '../const';
import { SettingsFormItem, SettingsTextInput, Segment, SegmentTitle, CustomButton, Description } from 'components';
import { useDeepLink } from 'hooks/useDeepLink';
import { LinkIcon } from 'icons';

const Row = styled.div<{ $height: string }>`
  display: flex;
  justify-content: space-between;
  height: ${({ $height }) => $height};
`;

interface NavigationHeaderProps {
  tabId: number;
  placeholder: string;
  inputLabel?: string;
  description?: string;
  children?: ReactNode;
}

export const NavigationHeader = ({ tabId, placeholder, inputLabel, description, children }: NavigationHeaderProps) => {
  const { domain, copyDomainToClipboard } = useDeepLink({ itemType: 'collection', itemId: tabId });

  return (
    <Segment $marginTop="0">
      <Row $height={description ? '30px' : '38px'}>
        <SegmentTitle title="Header" />
        {!!domain && (
          <CustomButton
            medium
            tertiaryHighlight
            icon={<LinkIcon />}
            iconPosition="right"
            onClick={copyDomainToClipboard}
          >
            Copy Deep Link
          </CustomButton>
        )}
      </Row>
      {description && <Description>{description}</Description>}
      {inputLabel && <SegmentTitle title={inputLabel} />}
      <SettingsFormItem name="NavBarTitleText">
        <SettingsTextInput size="middle" maxLength={NAV_HEADER_MAX} placeholder={placeholder} />
      </SettingsFormItem>
      {children}
    </Segment>
  );
};
