import styled from 'styled-components';

import { APP_FONT_18PX_SEMIBOLD } from 'mockup-font';
import { NEUTRAL_10_COLOUR } from 'theme';

const DrawerHeadingContainer = styled.div`
  ${APP_FONT_18PX_SEMIBOLD};
  color: ${NEUTRAL_10_COLOUR};
  padding: 0 28px 8px;
`;

export interface DrawerHeadingProps {
  title: string;
}

export const DrawerHeading = ({ title }: DrawerHeadingProps) => (
  <DrawerHeadingContainer>{title}</DrawerHeadingContainer>
);
