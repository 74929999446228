import styled from 'styled-components';
import { DragIcon, PadlockIcon } from 'icons';
import { NEUTRAL_6_COLOUR, NEUTRAL_7_COLOUR } from 'theme';

export const DragHandleDots = styled(DragIcon)<{ $size?: string; $padding?: string; $color?: string }>`
  #react-app && {
    font-size: ${({ $size }) => $size || '20px'};
    padding: ${({ $padding }) => $padding || '5px'};
    color: ${({ $color }) => $color || NEUTRAL_6_COLOUR};
  }
`;

export const DragHandleLocked = styled(PadlockIcon)`
  #react-app && {
    font-size: 20px;
    color: ${NEUTRAL_7_COLOUR};
    padding: 5px;
  }
`;
