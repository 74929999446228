import React from 'react';
import ReactDOM from 'react-dom';

import { App } from 'app/App';
import './index.less';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { LoginPanel } from 'app/modules/login';
import { AxiosProvider } from 'api';
import { getAppBeingEdited, getUserEmail } from 'utils';

export interface AppProps {
  elementId: string;
  appBeingEdited?: string;
}

const appBeingEdited = getAppBeingEdited() ?? '';
const renderApp = ({ elementId, appBeingEdited }: { elementId: string; appBeingEdited: string }) => {
  ReactDOM.render(
    <React.StrictMode>
      <App elementId={elementId} appBeingEdited={appBeingEdited} />
    </React.StrictMode>,
    document.getElementById(elementId),
  );
};
const renderLogin = (elementId: string) => {
  ReactDOM.render(
    <React.StrictMode>
      <AxiosProvider>
        <LoginPanel />
      </AxiosProvider>
    </React.StrictMode>,
    document.getElementById(elementId),
  );
};

const pickAnApp = () => {
  const email = getUserEmail();
  if (email) {
    const loginPanel = document.getElementById('login-panel');
    if (loginPanel) {
      loginPanel.style.display = 'none';
    }
    renderApp({ elementId: 'react-app', appBeingEdited });
    renderLogin('login-panel');
  } else {
    const reactPage = document.getElementById('reactPage');
    if (reactPage) {
      reactPage.style.display = 'none'; // Hide app during login
    }
    renderLogin('login-panel');
  }
};
pickAnApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
