import { ReactNode } from 'react';

import { TabDrawer } from 'app/modules/build-screens/components/Common/TabDrawer';
import { DefaultTabDrawer, DefaultTabDrawerProps } from 'app/modules/build-screens';

interface DefaultTabWrapperProps {
  tabId: number;
  drawerExtension?: ReactNode;
  drawerExtensionProperties?: DefaultTabDrawerProps['childProperties'];
}

export const DefaultTabWrapper = ({ tabId, drawerExtension, drawerExtensionProperties }: DefaultTabWrapperProps) => (
  <TabDrawer tabId={tabId} key={tabId}>
    <DefaultTabDrawer tabId={tabId} childProperties={drawerExtensionProperties}>
      {drawerExtension}
    </DefaultTabDrawer>
  </TabDrawer>
);
