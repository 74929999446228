import { RefObject } from 'react';

/* Scrolls the mockup smoothly to be centered on the Section specified by the ref */
export const scrollToContent = (ref: RefObject<HTMLDivElement>) => {
  const content = document.getElementById('Builder__Content');
  if (content) {
    content.style.scrollBehavior = 'smooth';
    ref.current?.scrollIntoView({ block: 'center' });
    content.style.scrollBehavior = 'unset';
  }
};
