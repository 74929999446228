// convert array of strings to a string literal
import { AxiosInstance } from 'axios';
import { SOURCE_USCREEN, SOURCE_WORDPRESS, SOURCE_VIDAPP } from 'api/index';

const FILTER_DATA_SOURCES = [SOURCE_USCREEN, SOURCE_WORDPRESS, SOURCE_VIDAPP] as const;
// type representing a union of the string array above e.g) a | b | c
export type FilterDataSource = (typeof FILTER_DATA_SOURCES)[number];

export interface GetFiltersResponse {
  filters: Filter[];
  filter_by_filter_option_id: Record<string, Filter>;
  filter_option_by_id: Record<string, FilterOption>;
  filter_option_id_by_name: Record<string, Record<string, number>>;
}

export interface Filter {
  Filter: string; // The name of the filter
  Position: number;
  Enabled: boolean;
  Source: FilterDataSource | null;
  FilterOptions: FilterOption[];
  Id: number;
}

export interface FilterOption {
  Option: string; // The name of the filter option
  Position: number;
  Enabled: boolean;
  Id: number;
  IsDefault: boolean;
  SourceTag: string | null;
}

export interface FilterToSave extends Omit<Filter, 'Id' | 'FilterOptions'> {
  Id?: number; // If an ID is provided, an existing filter will be updated, otherwise a new filter will be created
  FilterOptions: FilterOptionToSave[];
}

export interface FilterOptionToSave extends Omit<FilterOption, 'Id'> {
  Id?: number; // If an ID is provided, an existing filter option will be updated, otherwise a new filter option will be created
}

export const getFilters = (client: AxiosInstance, appBeingEdited: string) => {
  return client
    .post<Filter[]>(`tunnel/${appBeingEdited}`, {
      HttpPath: `app/${appBeingEdited}/filters?IncludeDisabled=yes`,
      HttpMethod: 'GET',
      HttpPayload: {},
    })
    .then(({ data }) => {
      const FILTER_BY_FILTER_OPTION_ID: Record<string, Filter> = {};
      const FILTER_OPTION_BY_ID: Record<number, FilterOption> = {};
      const FILTER_OPTION_ID_BY_NAME: Record<string, Record<string, number>> = {};
      data.forEach((filter) => {
        FILTER_OPTION_ID_BY_NAME[filter.Filter] = {};
        if (filter.FilterOptions) {
          for (const option of filter.FilterOptions) {
            FILTER_BY_FILTER_OPTION_ID[option.Id] = filter;
            FILTER_OPTION_BY_ID[option.Id] = option;
            FILTER_OPTION_ID_BY_NAME[filter.Filter][option.Option] = option.Id;
          }
        }
      });
      return {
        filters: data,
        filter_by_filter_option_id: FILTER_BY_FILTER_OPTION_ID,
        filter_option_by_id: FILTER_OPTION_BY_ID,
        filter_option_id_by_name: FILTER_OPTION_ID_BY_NAME,
      };
    });
};

export const saveFilter = async (client: AxiosInstance, appBeingEdited: string, filter: FilterToSave) => {
  return client.put(`filter/${appBeingEdited}`, JSON.stringify(filter));
};

export const deleteFilter = async (client: AxiosInstance, appBeingEdited: string, filterId: number) => {
  return client.delete(`filter/${appBeingEdited}/${filterId}`);
};
