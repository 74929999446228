import { AxiosInstance } from 'axios';

export interface GetCommentsResponse {
  comments: Comment[];
}

export interface Comment {
  CommentId: number;
  SourceParentCommentId: number;
  CommenterName: string;
  Comment: string;
  Date: string;
  SourceCustomerId: string;
  Email: string;
  DataSource: string;
  ItemId: string;
  SourceItemId: string;
  ItemType: string;
  ItemTitle: string;
}

export const getComments = (client: AxiosInstance, appId: string, page = 1) => {
  return client
    .get<GetCommentsResponse>(`/comments/${appId}?page=${page}&page_size=100`)
    .then(({ data }) => ({ page, comments: data.comments }));
};

export const deleteComment = (client: AxiosInstance, appId: string, commentId: number) => {
  return client.delete(`/comments/${appId}/${commentId}`);
};
