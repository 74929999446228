import axios from 'axios';

export interface VidAppJWT {
  issuer: string;
  exp: number;
  uid: number;
  admin: boolean;
  app_roles: unknown;
}

export interface JWTService {
  jwt: () => string;
  isAdmin: () => boolean;
  isExpired: () => boolean;
  refreshAccessToken: () => Promise<void>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queueRequest: (resolve: (value: unknown) => void, originalRequest: any) => void;
}

const builderEnv: Record<string, Record<string, string>> = {
  'builder.vidapp.com': {
    // prod
    URL: 'https://builder-api.vidapp.com/api/v1',
    serverlessDataExtractURL: 'https://etl.vidapp.com',
    serverlessBackendURL: 'https://ixq3yp5cwh.execute-api.us-west-2.amazonaws.com/prod',
    serverlessPurchasingURL: 'https://jvfmty79c0.execute-api.us-west-2.amazonaws.com/prod',
    phpBackendURL: 'https://vidapp.com',
    contentApiURL: 'https://content.vidapp.com/api/v2',
    appstoreApiURL: 'https://appstore.vidapp.com',
  },
  'uat.vidapp.com': {
    // uat
    URL: 'https://builder-api-uat.vidapp.com/api/v1',
    serverlessDataExtractURL: 'https://etl.vidapp.com',
    serverlessBackendURL: 'https://ixq3yp5cwh.execute-api.us-west-2.amazonaws.com/prod',
    serverlessPurchasingURL: 'https://jvfmty79c0.execute-api.us-west-2.amazonaws.com/prod',
    phpBackendURL: 'https://vidapp.com',
    contentApiURL: 'https://content.vidapp.com/api/v2',
    appstoreApiURL: 'https://appstore.vidapp.com',
  },
  'builder-staging.vidapp.com': {
    // staging (connecting rds-staging.vidapp.com)
    URL: 'https://builder-api-staging.vidapp.com/api/v1',
    serverlessDataExtractURL: 'https://etl.vidapp.com',
    serverlessBackendURL: 'https://4y3s66fnt6.execute-api.us-west-2.amazonaws.com/staging',
    serverlessPurchasingURL: 'https://7vcm31ocx2.execute-api.us-west-2.amazonaws.com/staging',
    phpBackendURL: 'https://php-api-staging.vidapp.com',
    contentApiURL: 'https://content-staging.vidapp.com/api/v2',
    appstoreApiURL: '',
  },
  dev: {
    // dev (fallback)
    URL: 'https://builder-api-uat.vidapp.com/api/v1',
    serverlessDataExtractURL: 'https://etl.vidapp.com',
    serverlessBackendURL: 'https://ixq3yp5cwh.execute-api.us-west-2.amazonaws.com/prod',
    serverlessPurchasingURL: 'https://jvfmty79c0.execute-api.us-west-2.amazonaws.com/prod',
    phpBackendURL: 'https://vidapp.com',
    contentApiURL: 'https://content.vidapp.com/api/v2',
    appstoreApiURL: 'https://appstore.vidapp.com',
  },
};
export const builderAPI = builderEnv[window.location.host] ?? builderEnv.dev;
export const axiosReact = axios.create({
  baseURL: `${builderAPI.URL}/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// https://github.com/axios/axios#interceptors
// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token

/**
 * Request interceptor for API calls
 * - Add access token to auth header to/for secure builder API calls
 */

// Don't send a JWT token to URLs outside of Builder-API or the following
const JWT_URLS = [builderAPI.contentApiURL, builderAPI.appstoreApiURL, builderAPI.serverlessBackendURL];
axiosReact.interceptors.request.use(
  (config) => {
    // Do not override auth header for `auth/token/refresh` endpoint as that API call is made using httponly refresh cookie
    if (config.url === 'auth/token/refresh') return config;
    if (JWT_URLS.find((url) => config.url?.startsWith(url))) {
      // Specified domains need JWT
    } else if (config.url?.startsWith('https://') || config.url?.startsWith('http://')) {
      // Other External APIs don't need JWT
      return config;
    }

    config.headers['Authorization'] = `Bearer ${window.jwtService.jwt()}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

const MAX_RETRY = 3;
window.immediateRetries = {};
/**
 * Response interceptor for API calls
 * - Retry requests upon token expiry
 * - Re-retrieve access token from refresh token
 */
axiosReact.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Handle expired access token, use refresh cookie to re-retrieve access token and retry the original request
    const originalRequest = error.config;

    // Ignore error handling from defined routes
    const ignoreRouteList = ['auth/token/refresh', 'auth/token', 'auth/logout'];
    if (ignoreRouteList.includes(originalRequest.url)) return Promise.reject(error);

    // Retry requests which need Auth
    if (error.response?.status === 401 || error.response?.status === 403) {
      // Check if access token is expired, to avoid multiple refreshes
      if (window.jwtService.isExpired()) {
        // Queue the request to be resolved when the token is refreshed
        const requestPromise = new Promise((resolve) => window.jwtService.queueRequest(resolve, originalRequest));
        window.jwtService.refreshAccessToken();
        return requestPromise;
      } else {
        const url = originalRequest.url;
        const immediateRetries = window.immediateRetries;

        if (url.endsWith('validate-account')) {
          // Don't auto retry validation attempts for onboarding wizard
          return Promise.reject(error);
        }

        // Token is not expired, retry the request immediately
        // limit the number of retries like this as we may actually be unauthorized
        if (!immediateRetries[url]) {
          immediateRetries[url] = 1;
        } else if (immediateRetries[url] < MAX_RETRY) {
          immediateRetries[url] += 1;
        } else {
          console.error(`Failed all retries to ${url}`);
          return Promise.reject(error);
        }

        console.info(`Immediately retrying request to ${url}`);
        return axiosReact(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

/**
 * Prefetch an up-to-date JWT token so that it doesn't expire while you are active
 */
export const setupPrefetching = () => {
  let lastMouseMoveTime = new Date().getTime();

  function hasUserMoved() {
    const currentTime = new Date().getTime();
    const elapsedTimeSinceMouseMove = (currentTime - lastMouseMoveTime) / 1000; // convert to seconds
    return elapsedTimeSinceMouseMove < 60 * 60 * 6;
  }

  function refreshToken() {
    if (hasUserMoved()) {
      window.jwtService.refreshAccessToken();
    }
    // schedule next token refresh after 25 minutes
    setTimeout(refreshToken, 25 * 60 * 1000);
  }

  document.addEventListener(
    'mousemove',
    function () {
      lastMouseMoveTime = new Date().getTime();
    },
    { passive: true },
  );
  // start token refresh on page load
  refreshToken();
};
