import { useCallback } from 'react';
import styled from 'styled-components';

import { Collapse, SegmentProps, SettingsAlternateThumbnailInput, SettingsThumbnailInput } from 'components';
import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { NEUTRAL_10_COLOUR } from 'theme';
import { FONT_14PX_SEMIBOLD } from 'font';

const CollapseTitle = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
`;

interface SettingsCollectionThumbnailProps extends SegmentProps {
  collection: BuilderCollection;
  width?: string;
  height?: string;
  alternateOrientation?: 'row' | 'column';
  squareWidth?: string;
  portraitWidth?: string;
  portraitHeight?: string;
  collapsible?: boolean; // Additional screenshots section will be collapsible
}

export const SettingsCollectionThumbnail = ({
  collection,
  alternateOrientation,
  width,
  height,
  portraitWidth,
  portraitHeight,
  squareWidth,
  collapsible,
  ...props
}: SettingsCollectionThumbnailProps) => {
  const { setCollectionValue } = useContent();
  const { setCollectionValueToSave } = useSaveContext();

  const isRevertable = collection?.ThumbnailSource && collection?.ThumbnailSource != collection?.SourceThumbnailSource;
  const isSquareRevertable =
    collection?.SquareThumbnailSource && collection?.SquareThumbnailSource != collection?.SourceSquareThumbnailSource;
  const isPortraitRevertable =
    collection?.PortraitThumbnailSource &&
    collection?.PortraitThumbnailSource != collection?.SourcePortraitThumbnailSource;

  const filename = collection.Thumbnails?.large || collection.ThumbnailSource || collection.SourceThumbnailSource;
  const squareFilename =
    collection.SquareThumbnails?.large || collection.SquareThumbnailSource || collection.SourceSquareThumbnailSource;
  const portraitFilename =
    collection.PortraitThumbnails?.large ||
    collection.PortraitThumbnailSource ||
    collection.SourcePortraitThumbnailSource;

  const handleThumbnailChange = useCallback(
    (f: string) => {
      if (collection) {
        setCollectionValue(collection.TabId, 'ThumbnailSource', f);
        setCollectionValue(collection.TabId, 'Thumbnails', { source: f });
        setCollectionValueToSave(collection.TabId, 'ThumbnailSource', f);
      }
    },
    [setCollectionValue, setCollectionValueToSave, collection],
  );
  const handleSquareThumbnailChange = useCallback(
    (f: string) => {
      if (collection) {
        setCollectionValue(collection.TabId, 'SquareThumbnailSource', f);
        setCollectionValue(collection.TabId, 'SquareThumbnails', { source: f });
        setCollectionValueToSave(collection.TabId, 'SquareThumbnailSource', f);
      }
    },
    [setCollectionValue, setCollectionValueToSave, collection],
  );
  const handlePortraitThumbnailChange = useCallback(
    (f: string) => {
      if (collection) {
        setCollectionValue(collection.TabId, 'PortraitThumbnailSource', f);
        setCollectionValue(collection.TabId, 'PortraitThumbnails', { source: f });
        setCollectionValueToSave(collection.TabId, 'PortraitThumbnailSource', f);
      }
    },
    [setCollectionValue, setCollectionValueToSave, collection],
  );

  const handleRevertThumbnail = useCallback(() => {
    if (collection) {
      setCollectionValue(collection.TabId, 'ThumbnailSource', '');
      setCollectionValue(collection.TabId, 'Thumbnails', {});
      setCollectionValueToSave(collection.TabId, 'ThumbnailSource', '');
    }
  }, [setCollectionValue, setCollectionValueToSave, collection]);
  const handleRevertSquare = useCallback(() => {
    if (collection) {
      setCollectionValue(collection.TabId, 'SquareThumbnailSource', '');
      setCollectionValue(collection.TabId, 'SquareThumbnails', {});
      setCollectionValueToSave(collection.TabId, 'SquareThumbnailSource', '');
    }
  }, [setCollectionValue, setCollectionValueToSave, collection]);
  const handleRevertPortrait = useCallback(() => {
    if (collection) {
      setCollectionValue(collection.TabId, 'PortraitThumbnailSource', '');
      setCollectionValue(collection.TabId, 'PortraitThumbnails', {});
      setCollectionValueToSave(collection.TabId, 'PortraitThumbnailSource', '');
    }
  }, [setCollectionValue, setCollectionValueToSave, collection]);

  const alternateInputProps = {
    squareFilename: squareFilename,
    isSquareRevertable: isSquareRevertable,
    handleSquareThumbnailChange: handleSquareThumbnailChange,
    handleRevertSquareThumbnail: handleRevertSquare,
    portraitFilename: portraitFilename,
    isPortraitRevertable: isPortraitRevertable,
    handlePortraitThumbnailChange: handlePortraitThumbnailChange,
    handleRevertPortraitThumbnail: handleRevertPortrait,
    orientation: alternateOrientation ?? 'row',
    portraitWidth: portraitWidth,
    portraitHeight: portraitHeight,
    squareWidth: squareWidth,
    ...props,
  };

  return (
    <>
      <SettingsThumbnailInput
        filename={filename}
        isRevertable={isRevertable}
        handleThumbnailChange={handleThumbnailChange}
        handleRevertThumbnail={handleRevertThumbnail}
        width={width}
        height={height}
        {...props}
      />
      {collapsible ? (
        <Collapse triggerContent={<CollapseTitle>Additional Thumbnails</CollapseTitle>} iconAtEnd>
          <SettingsAlternateThumbnailInput
            {...alternateInputProps}
            header={undefined}
            $marginTop="13px"
            $marginBottom="0"
          />
        </Collapse>
      ) : (
        <SettingsAlternateThumbnailInput {...alternateInputProps} />
      )}
    </>
  );
};
