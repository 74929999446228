import { useQuery } from 'react-query';

import { useAxiosInstance, getOneSignalNotifications } from 'api';
import { useSecureAppProperties } from 'hooks';

export const useOneSignalNotifications = () => {
  const client = useAxiosInstance();
  const { data: secureAppProperties } = useSecureAppProperties();
  return useQuery(
    ['oneSignalNotifications', secureAppProperties?.OneSignalAppId],
    () => getOneSignalNotifications(client, secureAppProperties?.OneSignalAppId, secureAppProperties?.OneSignalApiKey),
    {
      refetchOnMount: 'always',
    },
  );
};
