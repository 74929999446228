import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { getVideos, GetVideosResponse, S3_BUCKET, ThumbnailSize, useAxiosInstance, Video } from 'api';
import { useAppBeingEdited } from 'app-context';

const httpRegex = RegExp(/https?:\/\//);

export const useVideos = (
  staleTime?: UseQueryOptions['staleTime'],
  refetchOnMount?: UseQueryOptions['refetchOnMount'],
  options?: UseQueryOptions<GetVideosResponse>,
) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const query = useQuery<GetVideosResponse>(['videos', appId], () => getVideos(client, appId), {
    staleTime: staleTime ?? 30000,
    refetchOnMount: refetchOnMount ?? true,
    enabled: !!appId, // Don't call fetcher with empty AppId
    ...options,
  });

  const { data } = query;

  const getVideoById = useCallback(
    (videoId: number) => {
      const indexedVideo = data?.video_by_id[videoId];
      if (indexedVideo) {
        return indexedVideo;
      }
      if (data?.video_by_id) {
        // Only throw this error if videos have finished loading
        console.error(`Can't retrieve video with id ${videoId}`);
      }
      return null;
    },
    [data],
  );

  const getVideosByDataSource = useCallback(
    (dataSource: string) => {
      if (data?.videos) {
        return data.videos.filter((video) => video.DataSource === dataSource);
      }
      return [];
    },
    [data],
  );

  const getVideoThumbnailUrl = useCallback(
    (videoId: number, size: ThumbnailSize) => {
      const imageFolder = `${S3_BUCKET}${appId}/images/`;

      const buildUrl = (thumbnailFile: string) => {
        if (httpRegex.test(thumbnailFile)) {
          return thumbnailFile;
        } else {
          return imageFolder + thumbnailFile;
        }
      };

      const getThumbnailBySize = (video: Video, size: ThumbnailSize) => {
        const thumbnail = video.Thumbnails[size];
        if (thumbnail && thumbnail.length > 0 && !thumbnail.includes('Thumbnails are being processed')) {
          return buildUrl(thumbnail);
        }
        return undefined;
      };

      const video = getVideoById(videoId);
      if (video) {
        const sourceUrl =
          video.ThumbnailSource && video.ThumbnailSource.length > 0
            ? video.ThumbnailSource
            : video.SourceThumbnailSource;
        if (size === 'source') {
          return sourceUrl;
        }

        // Check if the requested size is available
        // else check if medium size is available
        // else return source
        return getThumbnailBySize(video, size) ?? getThumbnailBySize(video, 'medium') ?? sourceUrl;
      }
      return null;
    },
    [getVideoById],
  );

  return { ...query, getVideoById, getVideosByDataSource, getVideoThumbnailUrl };
};

export const usePrefetchVideos = async ({ enabled = false }: { enabled?: boolean }) => {
  const queryClient = useQueryClient();
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  if (appId && enabled) {
    await queryClient.prefetchQuery(['videos', appId], () => getVideos(client, appId));
  }
};
