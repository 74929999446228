import { Select } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { HeaderContainer } from 'components';
import { FONT_12PX_REGULAR } from 'font';
import { ChevronDownIcon, ChevronUpIcon, ImpersonateIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_13_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { isUserAdmin } from 'utils';

import { AccountDropdown, AppSelector, BranchModal, PublishButton, useAdminActions } from '..';

const ButtonWrapper = styled.div`
  display: flex;
`;

const ImpersonateButton = styled.div<{ isActive?: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isActive }) => (isActive ? NEUTRAL_10_COLOUR : NEUTRAL_5_COLOUR)};
  background-color: ${({ isActive }) => (isActive ? NEUTRAL_10_COLOUR : '#fff')};
  color: ${({ isActive }) => (isActive ? '#fff' : NEUTRAL_10_COLOUR)};
  cursor: pointer;

  :hover:not(:active) {
    background-color: ${({ isActive }) => (isActive ? NEUTRAL_13_COLOUR : NEUTRAL_3_COLOUR)};
  }
`;

const StyledImpersonateIcon = styled(ImpersonateIcon)`
  font-size: 24px;
`;

const StyledSelect = styled(Select)`
  &&&& {
    color: ${NEUTRAL_10_COLOUR};
    border: 1px solid ${NEUTRAL_5_COLOUR};
    border-radius: 6px;
    margin-right: 12px;
    ${FONT_12PX_REGULAR};
    height: 30px;
    display: flex;
    align-items: center;

    .ant-select-selection-search-input {
      height: 100% !important;
    }

    input {
      border: none;
      cursor: pointer;
    }

    .ant-select-selection-item,
    .ant-select-selection-search,
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item span {
      display: none;
    }

    .ant-select-selection-placeholder {
      color: ${NEUTRAL_10_COLOUR};
    }
  }
`;

const AdminSelect = styled(StyledSelect)`
  &&&& {
    width: 110px;
  }
`;

const ChevronDown = styled(ChevronDownIcon)`
  &&&& {
    font-size: 16px;
    color: ${NEUTRAL_9_COLOUR};
    pointer-events: none !important; // Make icon clickable (AntD bug workaround)
  }
`;

const ChevronUp = styled(ChevronUpIcon)`
  &&&& {
    font-size: 16px;
    color: ${NEUTRAL_9_COLOUR};
    pointer-events: none !important; // Make icon clickable (AntD bug workaround)
  }
`;

export const NavHeader = ({ hideAccountDropdown }: { hideAccountDropdown: boolean }) => {
  const { ADMIN_ACTIONS, branchModalAction, setBranchModalAction, handleAdminAction } = useAdminActions();

  const [adminSelectOpen, setAdminSelectOpen] = useState(false);

  return (
    <HeaderContainer>
      <div>
        <AppSelector />
      </div>
      <ButtonWrapper>
        {isUserAdmin({ overrideImpersonation: true }) && (
          <ImpersonateButton
            isActive={!isUserAdmin()}
            onClick={() => {
              isUserAdmin()
                ? localStorage.setItem('ImpersonationMode', 'true')
                : localStorage.removeItem('ImpersonationMode');
              window.location.reload();
            }}
          >
            <StyledImpersonateIcon />
          </ImpersonateButton>
        )}
        {isUserAdmin() && (
          <AdminSelect
            value={{ label: 'Admin Only' }}
            dropdownMatchSelectWidth={false}
            listHeight={1000}
            suffixIcon={adminSelectOpen ? <ChevronUp /> : <ChevronDown />}
            options={ADMIN_ACTIONS}
            onDropdownVisibleChange={(open) => setAdminSelectOpen(open)}
            onChange={(value) => {
              handleAdminAction(value as string);
            }}
            getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
          />
        )}
        <PublishButton />
        {!hideAccountDropdown && <AccountDropdown />}
      </ButtonWrapper>
      <BranchModal action={branchModalAction} setAction={setBranchModalAction} />
    </HeaderContainer>
  );
};
