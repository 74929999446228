import styled from 'styled-components';
import { NEUTRAL_10_COLOUR, NEUTRAL_6_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { FONT_14PX_SEMIBOLD, FONT_14PX_REGULAR, FONT_16PX_SEMIBOLD } from 'font';
import { BetaTag, Tooltip } from 'components/index';
import { InfoCircleIcon } from 'icons';

export const Title = styled.div<{ $marginBottom: string; isGroupTitle?: boolean; disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? NEUTRAL_6_COLOUR : NEUTRAL_10_COLOUR)};
  ${({ isGroupTitle }) => (isGroupTitle ? FONT_16PX_SEMIBOLD : FONT_14PX_SEMIBOLD)}
  height: 30px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  position: relative;
  display: flex;
  align-items: center;
`;
export const Description = styled.div`
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_14PX_REGULAR};
  margin-bottom: 20px;
`;

const StyledInfoIcon = styled(InfoCircleIcon)`
  font-size: 16px;
  margin-left: 4px;
`;

export interface SegmentTitleProps {
  title: string;
  tooltip?: string;
  description?: string;
  isBeta?: boolean;
  disabled?: boolean;
  $marginBottom?: string;
}

// This is called PageSegmentTitle in the design system
export const SegmentTitle = ({ title, tooltip, description, isBeta, disabled, $marginBottom }: SegmentTitleProps) => (
  <>
    <Title
      $marginBottom={$marginBottom || (description ? '0px' : '8px')}
      disabled={disabled}
      data-testid="segment-title"
    >
      {title}
      {tooltip && (
        <Tooltip title={tooltip} icon={<StyledInfoIcon />} placement="right" overlayStyle={{ maxWidth: '280px' }} />
      )}
      {isBeta && <BetaTag small />}
    </Title>
    {description && <Description>{description}</Description>}
  </>
);

export const GroupTitle = ({ title, tooltip, description, isBeta, $marginBottom }: SegmentTitleProps) => (
  <>
    <Title $marginBottom={$marginBottom || (description ? '0px' : '12px')} isGroupTitle>
      {title}
      {tooltip && (
        <Tooltip title={tooltip} icon={<StyledInfoIcon />} placement="right" overlayStyle={{ maxWidth: '280px' }} />
      )}
      {isBeta && <BetaTag small />}
    </Title>
    {description && <Description>{description}</Description>}
  </>
);
