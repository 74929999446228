import { useRef } from 'react';

import { AppVideo, AppVideoProps } from 'app/modules/build-dragdrop/Builder/mockup/components';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { ContentPositionProvider } from 'app/modules/build-dragdrop/Builder/mockup/providers/content-position-provider';

interface DraggableVideoProps extends AppVideoProps {
  idx: number;
}

/**
 * A wrapper for AppVideo to make it selectable and draggable
 * If this video is selected it will be highlighted in blue and a drag handle is rendered
 */
export const DraggableVideo = ({ video, item, idx, ...props }: DraggableVideoProps) => {
  const { setDrawerContext, drawerContext } = useBuilderContext();

  const elementId = `AppVideo_${item.TabItemId?.toString()}`;
  const videoRef = useRef<HTMLDivElement>(null);
  const selected = !!drawerContext && drawerContext.tabItemId === item.TabItemId?.toString();

  const handleClick = () => {
    setDrawerContext({
      itemId: video?.VideoId.toString(),
      tabItemId: item.TabItemId?.toString() ?? 'unknown',
    });
  };

  return (
    <div>
      <AppVideo video={video} item={item} id={elementId} onClick={handleClick} {...props} />
      <ContentPositionProvider contentRef={videoRef} selected={selected || false} idx={idx}></ContentPositionProvider>
    </div>
  );
};
