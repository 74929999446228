import { css } from 'styled-components';

export const FONT_12PX_BOLD = css`
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
`;

export const FONT_12PX_SEMIBOLD = css`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
`;

export const FONT_12PX_MEDIUM = css`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

export const FONT_14PX_NORMAL = css`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`;
export const FONT_14PX_SEMIBOLD = css`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`;

export const FONT_16PX_SEMIBOLD = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const FONT_16PX_MEDIUM = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const FONT_16PX_MEDIUM_SHORT = css`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`;

export const FONT_20PX_NORMAL = css`
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
`;
export const FONT_24PX_BOLD = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;
