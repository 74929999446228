import { AxiosInstance } from 'axios';

// Format in which each is returned by the API
interface SecureAppProperty {
  Name: string;
  Value: string;
}

export interface SecureAppPropertyToSave {
  name: string;
  value: string;
}

export const SECURE_APP_PROPERTIES = {
  KajabiAssistantEmail: 'KajabiAdminEmail',
  KajabiAssistantPassword: 'KajabiAdminPassword',
};

export const getSecureAppProperties = (client: AxiosInstance, appId: string) => {
  return client
    .get<{ results: SecureAppProperty[] }>(`secure-app-property/${appId}`)
    .then((response) => {
      const { results } = response.data;
      return results.reduce(
        (acc: Record<string, string>, secureAppProperty: SecureAppProperty) => ({
          ...acc,
          [secureAppProperty.Name]: secureAppProperty.Value,
        }),
        {},
      );
    })
    .catch((err) => {
      console.error('GET SecureAppProperty', err);
    });
};

export const saveSecureAppProperties = async (
  client: AxiosInstance,
  appId: string,
  secureAppPropertiesToSave: SecureAppPropertyToSave[],
) => {
  return client.put(`secure-app-property/${appId}`, { data: secureAppPropertiesToSave });
};
