import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import * as amplitude from '@amplitude/analytics-browser';

import {
  HIGHLIGHT_PRIMARY_ACTIVE_COLOUR,
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_PRIMARY_HOVER_COLOUR,
  HIGHLIGHT_SECONDARY_ACTIVE_COLOUR,
} from 'theme';
import { useAppBeingEdited } from 'app-context';
import { PlusIcon } from 'icons';

import {
  BuilderCollection,
  BuilderCollectionItem,
  useBuilderContext,
} from 'app/modules/build-dragdrop/Builder/providers';
import { ContentPositionProvider } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import {
  AppCollection,
  AppCollectionProps,
  EditCollectionMenu,
} from 'app/modules/build-dragdrop/Builder/mockup/components';
import { scrollToContent } from 'app/modules/build-dragdrop/Builder/mockup/util';
import { DRAWER_TYPE_ADD } from '../../../const';

const getSelectedStyles = (selected: boolean, isHovering: boolean, templateId: number) => {
  if (templateId === 39) {
    return;
  }
  if (selected) {
    return css`
      border: ${HIGHLIGHT_PRIMARY_COLOUR} solid 2px;
    `;
  }
  if (isHovering) {
    return css`
      border: ${HIGHLIGHT_SECONDARY_ACTIVE_COLOUR} solid 2px;
    `;
  }
  return css`
    border: transparent solid 2px;
  `;
};

const CollectionWrapper = styled.div<{ templateId: number; selected?: boolean; isHovering: boolean }>`
  position: relative;
  width: 100%;

  ${({ selected, isHovering, templateId }) => getSelectedStyles(selected ?? false, isHovering, templateId)};
  border-radius: ${({ templateId }) => (templateId === 39 ? '0px' : '12px')};

  .add-button {
    display: ${({ isHovering }) => (isHovering ? 'flex' : 'none')};
  }
`;

const AddButton = styled.div`
  position: absolute;
  left: calc(50% - 12px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${HIGHLIGHT_PRIMARY_COLOUR};
  color: #fff;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;

  :hover {
    background-color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
  }

  :active {
    background-color: ${HIGHLIGHT_PRIMARY_ACTIVE_COLOUR};
  }
`;

const TopAddButton = styled(AddButton)`
  top: -14px;
`;

const BottomAddButton = styled(AddButton)`
  bottom: -14px;
`;

interface DraggableCollectionProps extends AppCollectionProps {
  item: BuilderCollectionItem;
  collection?: BuilderCollection;
  idx: number;
  isSourceContent?: boolean;
  disableAddButtons?: boolean;
}

/**
 * A Draggable Collection for use within the DragDrop builder
 * A wrapper for AppCollection to make it selectable and draggable
 * If this collection is selected it will be highlighted in blue and a drag handle is rendered
 */

export const EditCollection = ({
  collection,
  item,
  idx,
  templateId,
  isSourceContent,
  disableAddButtons,
  ...props
}: DraggableCollectionProps) => {
  const { drawerContext, setSectionDrawer, setDrawerContext } = useBuilderContext();
  const appId = useAppBeingEdited();
  const [isHovering, setIsHovering] = useState(false);

  const tabItemIdString = `AppCollection_${item.TabItemId?.toString()}`;
  const collectionRef = useRef<HTMLDivElement>(null);
  const selected = !!drawerContext && drawerContext.tabItemId === item.TabItemId?.toString();

  useEffect(() => {
    if (selected) {
      scrollToContent(collectionRef);
    }
  }, [selected]);

  const handleAddClick = useCallback(
    (index: number) => {
      amplitude.track('Add section button clicked (simulator)', { appId });
      setDrawerContext({ drawerType: DRAWER_TYPE_ADD, index: index });
    },
    [appId, setDrawerContext],
  );

  // Set a collection to selected in the drawer when it is clicked
  const handleClick = () => {
    const tabItemId = item.TabItemId?.toString();
    if (tabItemId) {
      setSectionDrawer(tabItemId, collection?.TabId.toString());
    } else {
      console.error('This section has no tabItemId so is unable to be edited');
    }
  };
  return (
    <div>
      <CollectionWrapper
        templateId={templateId}
        selected={selected}
        isHovering={isHovering}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        {!isSourceContent && !disableAddButtons && (
          <TopAddButton className="add-button" onClick={() => handleAddClick(idx)}>
            <PlusIcon />
          </TopAddButton>
        )}
        <AppCollection
          key={item.ChildId}
          collection={collection}
          id={tabItemIdString} // Used by DragHandle and SelectedCollection
          templateId={templateId}
          collectionRef={collectionRef}
          isDragDrop
          onClick={handleClick}
          {...props}
        />
        {!isSourceContent && !disableAddButtons && (
          <BottomAddButton className="add-button" onClick={() => handleAddClick(idx + 1)}>
            <PlusIcon />
          </BottomAddButton>
        )}
      </CollectionWrapper>
      <ContentPositionProvider contentRef={collectionRef} selected={selected || false} idx={idx}>
        <EditCollectionMenu
          onClick={handleClick}
          setIsHovering={setIsHovering}
          hideDelete={isSourceContent}
          {...{ collection, item, idx }}
        />
      </ContentPositionProvider>
    </div>
  );
};
