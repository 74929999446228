import { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';

import { SettingsNumberInput } from 'components';
import { FONT_12PX_MEDIUM, FONT_12PX_REGULAR } from 'font';
import { useContent, useSaveContext } from 'providers';
import { useAppProperties } from 'hooks';
import { CircuitRowButtons } from './CircuitRowButtons';
import { TreeItem } from '../ContentCollectionTree';

const StyledNumberInput = styled(SettingsNumberInput)`
  &&&& {
    width: 39px;
    height: 30px;
    margin: 0 12px 0 8px;
    padding: 0;
    display: flex;
    align-items: center;
    ${FONT_12PX_REGULAR};

    .ant-input-number {
      margin: 1px 7px 0;
      height: 26px;
    }
  }
`;

const Label = styled.div`
  ${FONT_12PX_MEDIUM};
  text-wrap: nowrap;
`;

interface ExerciseRowConfigProps {
  item: TreeItem;
  parentTabId: string | number;
  setActiveKeys: Dispatch<SetStateAction<(string | number)[]>>;
}

export const ExerciseRowConfig = ({ item, parentTabId, setActiveKeys }: ExerciseRowConfigProps) => {
  const videoId = item.itemId;
  const { collections, setCollectionItems } = useContent();
  const { setCollectionItemsToSave } = useSaveContext();
  const { data: appProperties } = useAppProperties();

  const circuit = collections[parentTabId];
  const exerciseItem = circuit.Items.find((item) => item.ChildId === videoId);

  const countInDurationDefault = appProperties?.PreExerciseCountInDefault ?? '10';
  const countInDuration =
    exerciseItem?.PreExerciseCountInDuration || exerciseItem?.PreExerciseCountInDuration === 0
      ? exerciseItem.PreExerciseCountInDuration.toString()
      : countInDurationDefault;

  const allottedDurationDefault = '30';
  const allottedDuration = exerciseItem?.AlottedDuration
    ? exerciseItem.AlottedDuration.toString()
    : allottedDurationDefault;

  const repNumberDefault = '1';
  const repNumber = exerciseItem?.RepNumber ? exerciseItem.RepNumber.toString() : repNumberDefault;

  const handleChange = useCallback(
    (key: string, value: number) => {
      if (!!value || value === 0) {
        const updatedItems = [...collections[parentTabId].Items].map((item) => {
          if (item.ChildId === videoId) {
            return { ...item, [key]: value };
          }
          return item;
        });

        setCollectionItems(parentTabId, updatedItems);
        setCollectionItemsToSave(parentTabId);
      }
    },
    [collections, setCollectionItems, setCollectionItemsToSave],
  );

  return (
    <>
      <Label>Count-In</Label>
      <StyledNumberInput
        autoComplete="off"
        value={countInDuration}
        placeholder={countInDurationDefault}
        onChange={(value) => handleChange('PreExerciseCountInDuration', value as number)}
        onFocus={(event) => event.target.select()}
        min={0}
        max={999}
        maxLength={3}
        prefix=""
      />
      <>
        {circuit.Properties?.CircuitType === 'time-based' ? (
          <>
            <Label>Time</Label>
            <StyledNumberInput
              autoComplete="off"
              value={allottedDuration}
              placeholder={allottedDurationDefault}
              onChange={(value) => handleChange('AlottedDuration', value as number)}
              onFocus={(event) => event.target.select()}
              min={1}
              max={999}
              maxLength={3}
              prefix=""
            />
          </>
        ) : (
          <>
            <Label>Reps</Label>
            <StyledNumberInput
              autoComplete="off"
              value={repNumber}
              placeholder={repNumberDefault}
              onChange={(value) => handleChange('RepNumber', value as number)}
              onFocus={(event) => event.target.select()}
              min={1}
              max={999}
              maxLength={3}
              prefix=""
            />
          </>
        )}
      </>
      <CircuitRowButtons item={item} parentTabId={parentTabId} setActiveKeys={setActiveKeys} />
    </>
  );
};
