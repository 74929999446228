import styled from 'styled-components';
import { useCallback, useState } from 'react';

import { ImageUploader, SquareColorPicker } from 'components';

import { NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { CheckIcon } from 'icons';

import { useUploadBrandAssets } from '../hooks/useUploadBrandAssets';
import { BackButton, Footer, NextButton, SplitPanel, StepPanel } from './index';
import { WizardButtonType } from './Common';
import { FONT_12PX_SEMIBOLD, FONT_16PX_SEMIBOLD } from '../font';
import { useWizardNavigation } from 'app/modules/self-onboarding/OnboardingWizard/hooks/useWizardNavigation';

const getButtonType = (loading: boolean, success: boolean): WizardButtonType | undefined => {
  if (loading) {
    return 'loading';
  } else if (success) {
    return 'success';
  }
  return undefined;
};

const RightBody = styled.div``;
const RightFooter = styled(Footer)`
  justify-content: space-between;
`;
const FlexRow = styled.div`
  display: flex;
`;
const Spacer = styled.div`
  height: 33px;
`;
const Wrapper = styled.div`
  margin-right: 52px;
  max-width: 210px;
  width: 210px;
`;

const Title = styled.div`
  ${FONT_16PX_SEMIBOLD};
  color: ${NEUTRAL_9_COLOUR};
`;
const Subtitle = styled.div`
  margin-top: 8px;
  ${FONT_12PX_SEMIBOLD};
  color: ${NEUTRAL_7_COLOUR};
`;

export const BrandStep = () => {
  const [brandColor, setBrandColor] = useState<string | undefined>();
  const [iconBgColor, setIconBgColor] = useState<string | undefined>();
  const [filename, setFilename] = useState(undefined);
  const brandAssets = useUploadBrandAssets();
  const { navigateForward } = useWizardNavigation();
  const onFilenameChange = useCallback(
    (_filename) => {
      setFilename(_filename);
    },
    [setFilename],
  );

  // Criteria to proceed
  // Colors must be selected and an app icon uploaded
  const isReady = brandColor && iconBgColor && filename;

  const handleComplete = useCallback(async () => {
    if (isReady) {
      await brandAssets.mutateAsync({ AppIconHex: iconBgColor, BrandHex: brandColor, AppIconFilename: filename });
      setTimeout(async () => {
        // After a delay move to the next page,
        navigateForward();
      }, 2000);
    }
  }, [navigateForward, isReady]);

  return (
    <SplitPanel
      leftContent={
        <StepPanel
          header="Customize your colors and logo."
          body="Almost there! The final step is to set your brand’s color and logo. Don’t stress though, these can be changed at anytime. "
          icon={<img src={`${BUILDER_IMAGES_DOMAIN}/onboarding/wizard_icons/upload.webp`} alt="user icon" />}
        />
      }
      rightContent={
        <>
          <RightBody>
            <FlexRow>
              <Wrapper>
                <Title>
                  PRIMARY <br />
                  BRAND COLOR
                </Title>
                <Subtitle>
                  This will be applied to <br /> accents & icons in your app.
                </Subtitle>
              </Wrapper>
              <SquareColorPicker
                color={brandColor}
                onColorChange={(color) => {
                  setBrandColor(color.hex);
                }}
              />
            </FlexRow>
            <Spacer />
            <FlexRow>
              <Wrapper>
                <Title>
                  APP ICON <br />
                  BACKGROUND COLOR
                </Title>
                <Subtitle>This will be used as the background for your app icon and splash screen.</Subtitle>
              </Wrapper>
              <SquareColorPicker
                color={iconBgColor}
                onColorChange={(color) => {
                  setIconBgColor(color.hex);
                }}
              />
            </FlexRow>
            <Spacer />
            <FlexRow>
              <Wrapper>
                <Title>APP ICON</Title>
                <Subtitle>
                  PNG file <br />
                  We recommend 900 x 900 pixels
                </Subtitle>
                <Subtitle>
                  This will be used for your app <br /> icon and splash screen.
                </Subtitle>
              </Wrapper>
              <ImageUploader
                acceptedFileTypes="image/png"
                height="120px"
                width="120px"
                scale={84}
                filename={filename}
                onFilenameChange={onFilenameChange}
                backgroundColor={iconBgColor}
              />
            </FlexRow>
          </RightBody>

          <RightFooter>
            <BackButton />
            <NextButton
              onClick={handleComplete}
              disabled={!isReady}
              loading={brandAssets.isLoading}
              icon={brandAssets.isSuccess && <CheckIcon />}
              $type={getButtonType(brandAssets.isLoading, brandAssets.isSuccess)}
            >
              {brandAssets.isSuccess ? 'Completed' : 'Complete'}
            </NextButton>
          </RightFooter>
        </>
      }
    />
  );
};
