import { FlexRow, InstructionText, WrappedDot } from './Components';

export const VimeoOTTSteps = () => {
  return (
    <>
      <FlexRow>
        <WrappedDot outlined>1</WrappedDot>
        <InstructionText>Create a new API key</InstructionText>
      </FlexRow>
      <FlexRow>
        <WrappedDot outlined>2</WrappedDot>
        <InstructionText>Sync your Vimeo OTT content</InstructionText>
      </FlexRow>
      <FlexRow>
        <WrappedDot outlined>3</WrappedDot>
        <InstructionText>Customize your color scheme and branding</InstructionText>
      </FlexRow>
    </>
  );
};
