import styled from 'styled-components';
import { FONT_10PX_REGULAR, FONT_12PX_MEDIUM } from 'font';
import { NEUTRAL_3_COLOUR } from 'theme';

export const Card = styled.div`
  width: 420px;
  padding: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  border-radius: 6px;

  background-color: ${NEUTRAL_3_COLOUR};

  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const Title = styled.div`
  ${FONT_12PX_MEDIUM};
  margin-left: 8px;
  width: 200px;
  max-width: 200px;
  overflow: hidden;
`;

export const Subtitle = styled.div`
  padding-left: 16px;
  ${FONT_10PX_REGULAR};
  max-width: 160px;
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
`;
