import { Collection, Video } from 'api';
import { Segment } from 'components';

import { ContentResource } from 'app/modules/build-dragdrop/Builder/drawer/components/CommonContent/ContentResource';

export const ContentResourceList = ({ Resources = [] }: Pick<Collection, 'Resources'> | Pick<Video, 'Resources'>) => {
  return (
    <>
      {Resources && Resources?.length > 0 && (
        <Segment header="Resources" $marginBottom="2px">
          {Resources.map((resource) => {
            return <ContentResource resource={resource} key={resource.ResourceId} />;
          })}
        </Segment>
      )}
    </>
  );
};
