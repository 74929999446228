import { Collection, isHorizontalScroll } from 'api';

import { AppCollection, ContentSection } from 'app/modules/build-dragdrop/Builder/mockup/components';
import { BuilderCollection, BuilderCollectionItem } from 'app/modules/build-dragdrop/Builder/providers';

interface HomogeneousViewProps {
  collection: Collection | BuilderCollection;
}

/* Display all collection items in the same view, determined by the Collection Template */
export const HomogeneousView = ({ collection }: HomogeneousViewProps) => {
  if (!collection) {
    return <>Collection does not exist</>;
  }

  return (
    <>
      {/* This template shows one section for each item*/}
      {isHorizontalScroll(collection.TemplateId) ? (
        <div>
          {collection.Items?.map((item: BuilderCollectionItem, idx: number) => {
            return (
              <ContentSection
                key={`${item.ChildId}|${item.TabItemId}`}
                item={item}
                idx={idx}
                overrideTemplateId={collection.TemplateId}
              />
            );
          })}
        </div>
      ) : (
        /* Show the items of this collection as a single section */
        <AppCollection templateId={collection.TemplateId} collection={collection} isDetailsView />
      )}
    </>
  );
};
