import { DrawerSeparator } from 'app/modules/build-screens/TabDrawers/components/DrawerSeparator';
import { Segment, SettingsFormItem, SettingsTextInput } from 'components';

export const COMMUNITY_EXTENSION_PROPERTIES = {
  CommunityID: { type: 'collectionProperty', valueType: 'string' },
} as const;

export const CommunityExtension = () => (
  <>
    <DrawerSeparator />
    <Segment header="Admin Only">
      <SettingsFormItem name="CommunityID">
        <SettingsTextInput label="Amity Community ID" name="CommunityID" />
      </SettingsFormItem>
    </Segment>
  </>
);
