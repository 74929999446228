import styled from 'styled-components';

import { CustomButton, HeaderContainer } from 'components';
import { ChevronLeftIcon } from 'icons';
import { DEFAULT_PAGE_PADDING_SMALL } from 'theme';

import { PublishButton } from '../components/PublishButton';

const StyledHeaderContainer = styled(HeaderContainer)`
  &&&& {
    padding: ${DEFAULT_PAGE_PADDING_SMALL};
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const SaveButton = styled(CustomButton)`
  &&&& {
    margin-right: 12px;
  }
`;

const BackButton = styled(CustomButton)`
  &&&& {
    padding-left: 0;
  }
`;

const SaveButtonPortal = styled.div`
  margin-right: 12px;
`;

interface NavHeaderSimpleProps {
  handleSave?: (() => Promise<void>) | (() => void);
  handleBack: () => void;
  isSaving?: boolean;
  saveDisabled?: boolean;
}

export const NavHeaderSimple = ({ handleSave, handleBack, isSaving, saveDisabled }: NavHeaderSimpleProps) => (
  <StyledHeaderContainer>
    <BackButton small tertiary icon={<ChevronLeftIcon />} disabled={isSaving} onClick={handleBack}>
      Back
    </BackButton>
    <ButtonWrapper>
      {handleSave ? (
        <SaveButton medium onClick={handleSave} loading={isSaving} disabled={saveDisabled}>
          Save
        </SaveButton>
      ) : (
        // Save button can be rendered via child component through this portal, e.g. BuildMyAppSaveButton
        <SaveButtonPortal id="save-button-portal" />
      )}
      <PublishButton />
    </ButtonWrapper>
  </StyledHeaderContainer>
);
