import styled from 'styled-components';

import { PRIMARY_ORANGE_COLOUR, NEUTRAL_9_COLOUR } from 'theme';

import { Dot, NextButton } from '../Common';
import { FONT_12PX_BOLD, FONT_20PX_NORMAL } from '../../font';

export const FlexRow = styled.div`
  display: flex;
`;

export const WrappedDot = styled(Dot)`
  margin-right: 12px;
`;

export const StyledLink = styled.a`
  #react-app && {
    text-decoration: underline;
    color: inherit;
    ${FONT_12PX_BOLD};
  }
`;

export const OrangeLink = styled(StyledLink)`
  #react-app && {
    color: ${PRIMARY_ORANGE_COLOUR};
    ${FONT_20PX_NORMAL};
  }
`;

export const BlackLink = styled(StyledLink)`
  #react-app && {
    color: ${NEUTRAL_9_COLOUR};
  }
`;
export const Wrapper = styled.div`
  padding-top: 16px;
`;

export const InstructionText = styled.div`
  ${FONT_20PX_NORMAL};
  margin-top: 24px;
  margin-right: -8px; // Allow instructions to seep into the padding
`;

export const ValidateButton = styled(NextButton)`
  #react-app & {
    min-width: 112px;
    font-weight: 600;
  }

  > span {
    font-weight: 600;
  }
`;
