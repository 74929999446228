import styled from 'styled-components';
import { FONT_10PX_SEMIBOLD } from 'font';
import {
  NEUTRAL_3_COLOUR,
  PRIMARY_ORANGE_COLOUR,
  PRIMARY_ORANGE_HOVER_COLOUR,
  SECONDARY_SUCCESS_COLOUR,
  SUCCESS_COLOUR,
  SUCCESS_HOVER_COLOUR,
} from 'theme';
import { isUserDev } from 'utils';

const Type = styled.span<{ isDev?: boolean }>`
  color: ${({ isDev }) => (isDev ? PRIMARY_ORANGE_COLOUR : SUCCESS_COLOUR)};
  background-color: ${({ isDev }) => (isDev ? '#fff' : SECONDARY_SUCCESS_COLOUR)};
  ${FONT_10PX_SEMIBOLD};
  text-transform: uppercase;
  padding: 2px 4px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isDev }) => (isDev ? PRIMARY_ORANGE_HOVER_COLOUR : SECONDARY_SUCCESS_COLOUR)};
  white-space: nowrap;
  cursor: pointer;

  :hover:not(:active) {
    background-color: ${({ isDev }) => (isDev ? NEUTRAL_3_COLOUR : SECONDARY_SUCCESS_COLOUR)};
    color: ${({ isDev }) => (isDev ? PRIMARY_ORANGE_COLOUR : SUCCESS_HOVER_COLOUR)};
  }

  :not(:last-child) {
    margin-right: 4px;
  }
`;
export const DevTag = ({ label, children }: { label?: string; children?: string | number }) => {
  const handleClick = () => {
    // Clickable, copy the text to clipboard
    if (children) {
      navigator.clipboard.writeText(children?.toString() ?? 'undefined');
    }
  };
  return (
    <Type onClick={handleClick} isDev={!!isUserDev()}>
      {label && <span>{`${label}: `}</span>}
      {children}
    </Type>
  );
};
