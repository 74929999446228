import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { useAxiosInstance, updateAppleIAPs } from 'api';
import { useRecordAction } from 'hooks';

export const useUpdateAppleIAPs = () => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, null>(
    () => {
      return updateAppleIAPs(client, appId);
    },
    {
      onSuccess: () => {
        recordAction.mutate({ action: 'Update Apple IAPs' });
      },
    },
  );
};
