import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import styled from 'styled-components';

import { NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { FONT_14PX_MEDIUM } from 'font';

const StyledAntBreadcrumb = styled(AntBreadcrumb)`
  #react-app && {
    li {
      list-style: none;
      cursor: default;
      user-select: none;

      // Navigation items
      span {
        color: ${NEUTRAL_8_COLOUR};
        ${FONT_14PX_MEDIUM};
      }

      :not(:last-child) {
        .Builder__Breadcrumb {
          cursor: pointer;
          transition: color 0.2s;

          :hover {
            color: ${NEUTRAL_10_COLOUR};
          }
        }
      }

      // Current screen
      :last-child {
        span {
          color: ${NEUTRAL_10_COLOUR};
        }

        .ant-breadcrumb-separator {
          display: none;
        }
      }
    }
  }
`;

interface BreadcrumbLocation {
  path: string;
  key: string;
}

export interface BreadcrumbItem {
  label: string;
  handleClick?: () => void;
  location?: BreadcrumbLocation;
}

export interface BreadcrumbProps {
  items: BreadcrumbItem[];
  currentScreen: string;
}

export const Breadcrumb = ({ items, currentScreen }: BreadcrumbProps) => {
  const history = useHistory();
  const { goBack } = useHistory();

  const onClick = useCallback(
    ({ handleClick, location }: BreadcrumbItem) => {
      if (handleClick) {
        handleClick();
      } else if (location) {
        history.push(location.path, { key: location.key });
      } else {
        goBack();
      }
    },
    [history, goBack],
  );

  return (
    <StyledAntBreadcrumb>
      {items.map((item) => (
        <AntBreadcrumb.Item key={item.label} onClick={() => onClick(item)} className="Builder__Breadcrumb">
          {item.label}
        </AntBreadcrumb.Item>
      ))}
      <AntBreadcrumb.Item>{currentScreen}</AntBreadcrumb.Item>
    </StyledAntBreadcrumb>
  );
};
