import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Modal } from 'antd';
import styled from 'styled-components';

import { useLocalAppProperties, useUnsavedChanges } from 'providers';
import {
  Heading,
  CustomButton,
  SettingsSelectInput,
  SegmentTitle,
  SettingsTextInput,
  SettingsFormItem,
  SettingsRadioInput,
  Dropdown,
  SettingsGroup,
} from 'components';
import { EllipsisIcon, PlusIcon, TrashIcon } from 'icons';
import { NEUTRAL_10_COLOUR } from 'theme';
import { FONT_14PX_REGULAR } from 'font';
import { useSaveAppProperties } from 'hooks';
import { isHttps } from 'utils';

import { EventType, WebhookPropertyItem } from './WebhooksInternal';
import { getTypeLabel } from '../../utils';
import { EVENT_TYPES } from '../../const';

const HeadingButtons = styled.div`
  display: flex;
`;

const SaveButton = styled(CustomButton)`
  &&&& {
    margin-left: 8px;
  }
`;

const StyledRadioInput = styled(SettingsRadioInput)`
  #react-app && {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;

    label {
      margin-bottom: 12px;

      span {
        ${FONT_14PX_REGULAR};
        color: ${NEUTRAL_10_COLOUR};
      }
    }
  }
`;

interface WebhookDetailsProps {
  existingType?: EventType;
  handleBack: () => void;
}

export const WebhookDetails = ({ existingType, handleBack }: WebhookDetailsProps) => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const saveAppProperties = useSaveAppProperties();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [activeType, setActiveType] = useState(existingType);
  const [urlDisabled, setUrlDisabled] = useState(false);

  const screenTitle = activeType ? getTypeLabel(activeType) : 'New Webhook';

  const existingWebhooks: Partial<Record<EventType, WebhookPropertyItem>> = useMemo(() => {
    if (properties.EventPublishingConfig) {
      return JSON.parse(properties.EventPublishingConfig);
    }
    return {};
  }, [properties, activeType]);

  const typeOptions = useMemo(
    () =>
      EVENT_TYPES.map((type) => ({
        name: getTypeLabel(type),
        value: type,
        disabled: !!existingWebhooks[type] && type !== existingType,
      })),
    [existingWebhooks, existingType],
  );

  useEffect(() => {
    if (existingType && existingWebhooks) {
      setUrlDisabled(existingWebhooks[existingType]?.Destination !== 'CustomUrl');
    }
  }, [existingType && existingWebhooks]);

  const onValuesChange = useCallback(() => {
    if (!unsavedChanges) {
      setUnsavedChanges(true);
    }
  }, [unsavedChanges, setUnsavedChanges]);

  const handleSave = useCallback(
    (updatedProperty: string) => {
      setIsSaving(true);
      saveAppProperties.mutate([{ Name: 'EventPublishingConfig', Value: updatedProperty }], {
        onSuccess: () => {
          setAppProperty('EventPublishingConfig', updatedProperty);
          setIsSaving(false);
          setUnsavedChanges(false);
          handleBack();
        },
      });
    },
    [setAppProperty, saveAppProperties, setIsSaving, setUnsavedChanges, handleBack],
  );

  const handleUpdateProperty = useCallback(() => {
    if (activeType) {
      const updatedProperty = { ...existingWebhooks };
      if (existingType) {
        delete updatedProperty[existingType];
      }
      const destination = form.getFieldValue('Destination');
      const destinationUrl = form.getFieldValue('DestinationUrl');

      const newValue: WebhookPropertyItem = {
        Format: 'VidApp',
        Destination: destination,
        DestinationUrl: destination === 'CustomUrl' ? destinationUrl : undefined,
      };

      updatedProperty[activeType] = newValue;

      handleSave(JSON.stringify(updatedProperty));
    }
  }, [form, existingWebhooks, existingType, activeType, handleSave]);

  const handleDelete = useCallback(() => {
    if (activeType) {
      const updatedProperty = { ...existingWebhooks };
      delete updatedProperty[activeType];

      handleSave(JSON.stringify(updatedProperty));
    }
  }, [activeType, existingWebhooks, handleSave]);

  const handleSelectChange = useCallback(
    (value: EventType) => {
      onValuesChange();
      setActiveType(value);
    },
    [onValuesChange, setActiveType],
  );

  return (
    <>
      <Heading
        heading={screenTitle}
        headingButton={
          existingType && (
            <HeadingButtons>
              <Dropdown
                trigger={<CustomButton medium icon={<EllipsisIcon />} tertiary />}
                options={[
                  {
                    label: 'Delete',
                    icon: <TrashIcon />,
                    onClick: handleDelete,
                  },
                ]}
              />
              <SaveButton medium onClick={() => form.submit()} loading={isSaving} disabled={!unsavedChanges}>
                Save
              </SaveButton>
            </HeadingButtons>
          )
        }
        breadcrumbs={{
          items: [
            {
              label: 'Webhooks',
              handleClick: () => {
                if (unsavedChanges) {
                  Modal.confirm({
                    title: 'Unsaved Changes',
                    content: 'You have unsaved changes. If you leave without saving your changes will be lost.',
                    getContainer: '#react-app',
                    okText: 'Continue Editing',
                    cancelText: 'Discard Changes',
                    onCancel: () => {
                      setUnsavedChanges(false);
                      handleBack();
                      Modal.destroyAll();
                    },
                    onOk: () => {
                      return false;
                    },
                  });
                } else {
                  handleBack();
                }
              },
            },
          ],
          currentScreen: screenTitle,
        }}
      />
      <Form
        name="webhook-form"
        form={form}
        onFinish={handleUpdateProperty}
        onValuesChange={onValuesChange}
        initialValues={{
          Destination: existingType ? existingWebhooks[existingType]?.Destination : 'CustomUrl',
          DestinationUrl: existingType ? existingWebhooks[existingType]?.DestinationUrl : undefined,
        }}
      >
        <SettingsGroup>
          <SegmentTitle title="Event Type" />
          <SettingsSelectInput
            options={typeOptions}
            defaultVal={activeType}
            $marginBottom="28px"
            onChange={handleSelectChange}
            defaultActiveFirstOption={false}
            placeholder="New Webhook"
          />
          <SegmentTitle title="Destination" />
          <SettingsFormItem name="Destination">
            <StyledRadioInput
              options={[
                { name: 'Google Analytics', value: 'GoogleAnalytics' },
                { name: 'Custom URL', value: 'CustomUrl' },
              ]}
              onChange={(event) => setUrlDisabled(event.target.value !== 'CustomUrl')}
            />
          </SettingsFormItem>
          <SettingsFormItem
            name="DestinationUrl"
            rules={[
              {
                validator: (_, value) => {
                  if (!urlDisabled && (!value || !isHttps(value))) {
                    return Promise.reject(new Error('Please enter a valid URL starting with https://'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <SettingsTextInput placeholder="Enter a URL" disabled={urlDisabled} />
          </SettingsFormItem>
        </SettingsGroup>
      </Form>
      {!existingType && (
        <CustomButton
          medium
          icon={<PlusIcon />}
          onClick={() => form.submit()}
          disabled={!activeType}
          loading={isSaving}
        >
          Create Webhook
        </CustomButton>
      )}
    </>
  );
};
