import { ReactNode } from 'react';
import { CollectionTitle } from 'components';
import { getCollectionName } from 'utils';
import { BuilderCollection } from 'providers';
import {
  CollectionThumbnail,
  DrawerHeading,
  DrawerScrollContainer,
  HtmlPageDescription,
} from 'app/modules/build-dragdrop/Builder/drawer/components';
import { COLLECTION_PROPERTY_HTMLPAGE_DESC } from 'api';

interface HTMLPageDrawerProps {
  collection: BuilderCollection;
  type?: ReactNode;
}

// Text Image Screen aka Page HTML Screen Drawer
export const HTMLPageDrawer = ({ collection, type }: HTMLPageDrawerProps) => {
  return (
    <>
      <DrawerHeading heading={getCollectionName(collection)} type={type ?? 'Page (HTML)'} />
      <DrawerScrollContainer paddingBottom="0">
        <CollectionTitle
          size="middle"
          TabId={collection.TabId}
          Name={collection.Name}
          $marginTop="0"
          $marginBottom="20px"
          showDeepLink
        />
        <CollectionThumbnail collection={collection} />
        <HtmlPageDescription
          TabId={collection.TabId}
          Description={(collection.Properties ?? {})[COLLECTION_PROPERTY_HTMLPAGE_DESC] ?? ''}
          height={500}
        />
      </DrawerScrollContainer>
    </>
  );
};
