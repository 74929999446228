import { useState } from 'react';
import styled from 'styled-components';

import { CustomButton } from 'components';
import { FONT_14PX_REGULAR } from 'font';
import { DANGER_COLOUR, NEUTRAL_8_COLOUR } from 'theme';

import { useCreateCommunityTab } from '../hooks/useCreateCommunityTab';

const Container = styled.div`
  margin-bottom: 16px;
`;

const Description = styled.div`
  margin-top: 4px;
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
`;
const Error = styled(Description)`
  color: ${DANGER_COLOUR};
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
`;
export const AddCommunityTab = () => {
  const createC = useCreateCommunityTab();
  const [err, setErr] = useState<string>('');
  const handleClick = () => {
    createC.mutate(undefined, {
      onError: (error) => {
        const detail = error?.response?.data?.detail;
        if (typeof detail === 'string') {
          setErr(detail);
        } else {
          setErr('Unexpected error');
        }
      },
      onSuccess: () => {
        setErr('');
      },
    });
  };

  return (
    <Container>
      <CustomButton medium onClick={handleClick} loading={createC.isLoading}>
        Create Community Main Tab
      </CustomButton>
      {err && <Error>{err.toString()}</Error>}
      <Description>
        Requires Amity Admin Token and Amity Region to be set up in Integrations tab. <br />
        This will generate a Community Main Tab and hook up an Amity Community to it.
      </Description>
    </Container>
  );
};
