import { useEffect, useMemo } from 'react';

import { CollectionItem, MIXED_TEMPLATE } from 'api';
import { getContentName } from 'utils';

import { BuilderCollectionItem, useBuilderContext, useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { CollectionDetails, MixedView } from 'app/modules/build-dragdrop/Builder/mockup/components';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { SubNavDisplay } from 'app/modules/build-dragdrop/Builder/mockup/components/SubNavDisplay';

export const SubNavView = () => {
  const { collections, getContentForItem } = useContent();
  const { currentPage, currentTabId, setCurrentPage, disableDragDrop } = useMockupContext();
  const { setDrawerContext } = useBuilderContext(); // Only available in DragDropBuilder context
  const currentTab = currentTabId ? collections[currentTabId] : undefined;

  useEffect(() => {
    if (!currentPage?.subItemId) {
      if (currentTab?.Items && currentTab?.Items[0].ChildId) {
        const { ChildId, TabItemId } = currentTab?.Items[0];
        setCurrentPage({ subItemId: ChildId, subTabItemId: TabItemId });
      }
    }
  }, [currentPage]);

  const handleSubNavClick = (index: number) => {
    if (currentTab) {
      const item = currentTab.Items[index];
      setCurrentPage({ subItemId: item.ChildId, subTabItemId: item.TabItemId });
      if (!disableDragDrop) {
        setDrawerContext(undefined);
      }
    }
  };

  const getTemplateFromCollection = (item: CollectionItem | BuilderCollectionItem) => {
    const collection = collections[item.ChildId];
    return collection.TemplateId;
  };

  const selectedIndex = useMemo(() => {
    const idx = currentTab?.Items.findIndex(
      (item) => currentPage?.subTabItemId?.toString() === item.TabItemId?.toString(),
    );
    return idx === -1 ? 0 : idx;
  }, [currentTab?.Items, currentPage]);

  if (currentTab?.Items.length === 0) {
    return null;
  }

  return (
    <SubNavDisplay
      sticky
      overrideSelected={selectedIndex}
      onChange={(index) => handleSubNavClick(index)}
      subNav={currentTab}
      items={currentTab?.Items.map((item, idx) => {
        const content = getContentForItem(item);

        return {
          name: (content && getContentName(content, item.Type)) ?? 'Untitled',
          component: content ? (
            <>
              {getTemplateFromCollection(item) === MIXED_TEMPLATE ? (
                <MixedView collection={collections[item.ChildId]} />
              ) : (
                <CollectionDetails collection={collections[item.ChildId]} />
              )}
            </>
          ) : null,
          id: `${item.TabItemId?.toString()}|${idx}`,
        };
      })}
    />
  );
};
