import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext } from 'react';
import { Collection, CollectionItem, ProductItem, Resource, Video } from 'api';
import { getCollectionName } from 'utils';

export interface BuilderCollection extends Omit<Collection, 'TabId' | 'Items'> {
  TabId: string | number;
  Items: BuilderCollectionItem[];
}

export interface BuilderVideo extends Omit<Video, 'VideoId'> {
  VideoId: string | number;
}

export type BuilderCollections = Record<number | string, BuilderCollection>;
export type BuilderVideos = Record<number | string, BuilderVideo>;

export interface BuilderCollectionItem extends Omit<CollectionItem, 'TabId' | 'ChildId' | 'TabItemId'> {
  TabId: string | number;
  ChildId: string | number;
  TabItemId?: string | number;
}

interface ContextValue {
  setCollections: Dispatch<SetStateAction<BuilderCollections>>;
  collections: BuilderCollections;
  videos: BuilderVideos;
  setVideos: Dispatch<SetStateAction<BuilderVideos>>;
  getContentForItem: (item: BuilderCollectionItem | ProductItem) => BuilderCollection | BuilderVideo | undefined;
  setCollectionValue: (
    collectionId: number | string,
    name: keyof Collection,
    value: string | number | Record<string, string> | Resource[],
  ) => void;
  setCollectionProperty: (collectionId: number | string, name: string, value: string) => void;
  setNewCollection: (
    collectionId: number | string,
    newCol: BuilderCollection,
    newItems?: BuilderCollectionItem[],
  ) => void;
  setNewVideo: (videoId: number | string, newVideo: BuilderVideo) => void;
  setCollectionItems: (collectionId: number | string, items: BuilderCollectionItem[]) => void;
  setVideoValue: (
    videoId: number | string,
    name: keyof Video,
    value: string | number | Record<string, string> | Resource[],
  ) => void;
  setVideoProperty: (videoId: number | string, name: string, value: string) => void;
  deleteCollection: (collectionId: number | string) => void;
  setSectionTemplate: (item: BuilderCollectionItem, templateId: number) => void;
  setCollectionTemplate: (collection: BuilderCollection, templateId: number) => void;
}

const defaultFunction = () => {
  console.warn('Unexpected function call content-provider');
};

const ContentContext = createContext<ContextValue>({
  setCollections: defaultFunction,
  setVideos: defaultFunction,
  collections: {},
  videos: {},
  getContentForItem: () => {
    return undefined;
  },
  setCollectionValue: defaultFunction,
  setCollectionProperty: defaultFunction,
  setNewCollection: defaultFunction,
  setNewVideo: defaultFunction,
  setCollectionItems: defaultFunction,
  setVideoValue: defaultFunction,
  setVideoProperty: defaultFunction,
  deleteCollection: defaultFunction,
  setSectionTemplate: defaultFunction,
  setCollectionTemplate: defaultFunction,
});

interface ProviderProps
  extends Omit<
    ContextValue,
    | 'getContentForItem'
    | 'setCollectionValue'
    | 'setCollectionProperty'
    | 'setNewCollection'
    | 'setNewVideo'
    | 'setCollectionItems'
    | 'setVideoValue'
    | 'setVideoProperty'
    | 'deleteCollection'
    | 'setSectionTemplate'
    | 'setCollectionTemplate'
  > {
  children: ReactNode[] | ReactNode;
}

const ContentProvider = ({ collections, setCollections, children, videos, setVideos }: ProviderProps) => {
  const getContentForItem = useCallback(
    (item: BuilderCollectionItem | ProductItem) => {
      if (item.Type === 'collection' || item.Type === 'tab') {
        const c = collections[item.ChildId];
        if (!c) {
          console.debug('Collection does not exist', item.ChildId);
        }
        return c;
      }
      const v = videos[item.ChildId];
      if (!v) {
        console.debug('Video does not exist', item.ChildId);
      }
      return v;
    },
    [collections, videos],
  );

  const setCollectionValue = (
    collectionId: number | string,
    name: keyof BuilderCollection,
    value: string | number | Record<string, string> | Resource[],
  ) => {
    setCollections((collections) => {
      const newCollections = { ...collections };
      const newCollection = newCollections[collectionId];
      if (newCollection) {
        newCollections[collectionId] = { ...newCollection, [name]: value };
      }
      return newCollections;
    });
  };
  const setCollectionProperty = (collectionId: number | string, name: string, value: string) => {
    setCollections((collections) => {
      const newCollections = { ...collections };
      const newCollection = newCollections[collectionId];
      if (newCollection) {
        newCollections[collectionId] = { ...newCollection, Properties: { ...newCollection.Properties, [name]: value } };
      }
      return newCollections;
    });
  };
  const deleteCollection = (collectionId: number | string) => {
    setCollections((collections) => {
      const newCollections = { ...collections };
      delete newCollections[collectionId];
      return newCollections;
    });
  };

  const setNewCollection = (
    collectionId: number | string,
    newCol: BuilderCollection,
    newItems?: BuilderCollectionItem[],
  ) => {
    setCollections((prevState) => {
      const newCollections = { ...prevState };
      const newC = { ...newCol, TabId: collectionId } as BuilderCollection;
      if (newItems) {
        newC.Items = [...newItems];
      }
      newCollections[collectionId] = newC;
      return newCollections;
    });
  };

  const setNewVideo = (videoId: number | string, newVideo: BuilderVideo) => {
    setVideos((prevState) => {
      const newVideos = { ...prevState };
      newVideos[videoId] = newVideo;
      return newVideos;
    });
  };

  const setCollectionItems = (collectionId: number | string, items: BuilderCollectionItem[]) => {
    setCollections((prevState) => {
      const newCollections = { ...prevState };
      const newC = { ...newCollections[collectionId] };
      newC.Items = [...items];
      newCollections[collectionId] = newC;
      return newCollections;
    });
  };

  const setVideoValue = (
    videoId: number | string,
    name: keyof Video,
    value: number | string | Record<string, string> | Resource[],
  ) => {
    setVideos((videos) => {
      const newVideos = { ...videos };
      const newVideo = newVideos[videoId];
      if (newVideo) {
        newVideos[videoId] = { ...newVideo, [name]: value };
      }
      return newVideos;
    });
  };

  const setVideoProperty = (videoId: number | string, name: string, value: string) => {
    setVideos((videos) => {
      const newVideos = { ...videos };
      const newVideo = newVideos[videoId];
      if (newVideo) {
        newVideos[videoId] = { ...newVideo, Properties: { ...newVideo.Properties, [name]: value } };
      }
      return newVideos;
    });
  };

  const setSectionTemplate = (item: BuilderCollectionItem, templateId: number) => {
    setCollections((oldCollections) => {
      const newCollections = { ...oldCollections };

      // Setting display type for a Block
      const newItem = newCollections[item.TabId].Items.find(
        (i) => i.TabItemId?.toString() === item.TabItemId?.toString(),
      );
      if (newItem) {
        newItem.TemplateId = templateId;
      } else {
        console.warn(
          `No item found in ${getCollectionName(newCollections[item.TabId] as Collection)}(${
            item.TabId
          }) with TabItemId ${item.TabItemId}`,
        );
      }

      return newCollections;
    });
  };

  const setCollectionTemplate = (collection: BuilderCollection, templateId: number) => {
    setCollections((oldCollections) => {
      const newCollections = { ...oldCollections };

      // Setting display type for a collection
      const newCollection = newCollections[collection.TabId];
      newCollection.TemplateId = templateId;

      return newCollections;
    });
  };

  return (
    <ContentContext.Provider
      value={{
        setCollections,
        collections,
        videos,
        setVideos,
        getContentForItem,
        setCollectionValue,
        setCollectionProperty,
        setNewCollection,
        setNewVideo,
        setCollectionItems,
        deleteCollection,
        setVideoValue,
        setVideoProperty,
        setSectionTemplate,
        setCollectionTemplate,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

const useContent = () => {
  const context = useContext(ContentContext);
  if (context === undefined) {
    throw new Error('useContent must be used within an ContentProvider');
  }
  return context;
};

export { ContentProvider, useContent };
