import React from 'react';
import { StateProps, StyledInput } from '../TagsCsvUploadPage';

interface CsvUploadWrapperProps {
  appBeingEdited: string;
  state: StateProps;
}

export const CsvUploadWrapper = ({
  appBeingEdited,
  state: { appId, setAppId, setCsvFileText },
}: CsvUploadWrapperProps) => {
  const onFileSelection = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      const file = e.target.files.item(0);
      if (!file) return;
      if (!appBeingEdited) {
        const fileName = file.name.split('.').slice(0, -1).join('.');
        setAppId(fileName);
      }
      const csvFileText = await file.text();
      setCsvFileText(csvFileText);
    }
  };
  return (
    <div>
      <form>
        <label htmlFor="csv-upload">Upload CSV File</label>
        <br />
        <StyledInput type="file" name="csv-upload" accept=".csv" onChange={onFileSelection} />
      </form>
      <form>
        <label>App ID: {appId}</label>
        {!appBeingEdited && <input value={appId} onChange={(e) => setAppId(e.target.value)} />}
      </form>
    </div>
  );
};
