import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { securelyUploadFile, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';
import { UploadOptions, pad2, makeFilenameSafe, makeFilenameSafeForAndroid } from 'utils';

export const useUploadFile = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const recordAction = useRecordAction();

  return useMutation<string, AxiosError, { file: File; options?: UploadOptions }>(
    async ({ file, options }) => {
      const { filePath, useOriginalFilename } = options ?? {};

      // Set up parameters and 'filename', which is used later to update the database with the new filename
      const d = new Date();
      const time = `${pad2(d.getHours())}${pad2(d.getMinutes())}${pad2(d.getSeconds())}`;
      const formattedDate = `${d.getFullYear()}${pad2(d.getMonth() + 1)}${pad2(d.getDate())}_${time}`;

      const filename = useOriginalFilename
        ? makeFilenameSafe(file.name)
        : `file_${formattedDate}_${makeFilenameSafeForAndroid(file.name)}`;
      const key = filePath ? `${filePath}${filename}` : `${appId}/${filename}`;
      console.info(`Attempting upload of file to S3 key:${key}`);

      await securelyUploadFile(client, key, file);
      return filename;
    },
    {
      onSuccess: async (_response, payload) => {
        recordAction.mutate({ action: 'Upload File', detail: { filename: payload.file.name } });
      },
    },
  );
};
