import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { useAppBasicInfo, useAppProperties, useSaveAppProperties } from 'hooks';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_6_COLOUR,
  NEUTRAL_9_COLOUR,
  NEUTRAL_7_COLOUR,
} from 'theme';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { CheckCircleIcon, ForwardIcon } from 'icons';

interface ItemProps {
  $isDisabled?: boolean;
  $isChecked?: boolean;
  $hasAction?: boolean;
  $locked?: boolean;
}

const Item = styled.li<ItemProps>`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 28px;
  transition: 0.25s ease background-color;

  :hover {
    background-color: ${(props) => (props.$isDisabled || !props.$hasAction ? 'unset' : NEUTRAL_3_COLOUR)} !important;
  }
`;

const ClickableArea = styled.div<ItemProps>`
  cursor: ${(props) => (props.$isDisabled || !props.$hasAction ? 'unset' : 'pointer')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 24px 0;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled.div<ItemProps>`
  ${FONT_16PX_MEDIUM};
  font-weight: ${(props) => (props.$isDisabled ? 400 : 500)};
  color: ${(props) => (props.$isDisabled ? NEUTRAL_7_COLOUR : NEUTRAL_9_COLOUR)};
`;

const ItemDescription = styled.div<ItemProps>`
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_7_COLOUR};
`;

const Chevron = styled(ForwardIcon)<ItemProps>`
  &&&& {
    font-size: 20px;
    color: ${(props) => (props.$isDisabled ? NEUTRAL_6_COLOUR : HIGHLIGHT_PRIMARY_COLOUR)};
  }
`;

const CheckBox = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckIcon = styled(CheckCircleIcon)<ItemProps>`
  &&&& {
    font-size: 20px;
    color: ${(props) =>
      props.$isDisabled ? NEUTRAL_7_COLOUR : props.$isChecked ? HIGHLIGHT_PRIMARY_COLOUR : NEUTRAL_9_COLOUR};
    border-radius: 50%;
    cursor: ${(props) => (props.$isDisabled || props.$locked ? 'unset' : 'pointer')};
  }
`;

interface ChecklistItemProps {
  id?: string;
  title: string;
  description: string;
  property: string;
  value?: boolean; // If not an app property, just pass a value in read only
  isDisabled?: boolean;
  locked?: boolean;
  handleClick?: () => void;
}

export const ChecklistItem = ({
  title,
  description,
  property,
  isDisabled,
  locked,
  handleClick,
  id,
}: ChecklistItemProps) => {
  const { data: appProperties } = useAppProperties();
  const saveAppProperties = useSaveAppProperties();
  const { isMigratedLegacy } = useAppBasicInfo();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (appProperties) {
      setIsChecked(appProperties[property] === '1');
    }
  }, [appProperties, setIsChecked]);

  const handleCheck = useCallback(() => {
    if (!locked && !isMigratedLegacy) {
      const newValue = !isChecked;
      setIsChecked(newValue);
      saveAppProperties.mutate([{ Name: property, Value: newValue ? '1' : '0' }]);
    }
  }, [locked, isMigratedLegacy, saveAppProperties, isChecked, setIsChecked]);

  const disabled = isDisabled && !isMigratedLegacy;

  const onClick = useCallback(() => {
    if (!disabled && handleClick) {
      handleClick();
    }
  }, [disabled, handleClick]);

  return (
    <Item id={id} $isDisabled={disabled} $hasAction={!!handleClick}>
      <CheckBox onClick={handleCheck}>
        <CheckIcon $isChecked={isMigratedLegacy || isChecked} $isDisabled={disabled} $locked={locked} />
      </CheckBox>
      <ClickableArea $isDisabled={disabled} $hasAction={!!handleClick} onClick={onClick}>
        <DetailsWrapper>
          <ItemTitle $isDisabled={disabled}>{title}</ItemTitle>
          <ItemDescription $isDisabled={disabled}>{description}</ItemDescription>
        </DetailsWrapper>
        {handleClick && <Chevron $isDisabled={disabled} />}
      </ClickableArea>
    </Item>
  );
};
