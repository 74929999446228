import { AxiosInstance } from 'axios';
import { builderAPI } from 'api/axios';

export type PublishedType = 1 | 0;

export interface CalendarType {
  [key: string]: CalendarMonth;
}

export interface CalendarMonth {
  [key: string]: CalendarDay;
}

export interface CalendarDay {
  Published: PublishedType;
  Quote?: string;
  Items: CalendarItem[];
}

export interface CalendarItem {
  Type: string;
  Id: number;
}

interface CalendarMonthStatus {
  Month: string;
  Published: PublishedType;
}

export const getCalendar = (client: AxiosInstance, appBeingEdited: string) => {
  return client
    .get(`${builderAPI.contentApiURL}/data/${appBeingEdited}/builder/calendar`, {
      headers: {
        accept: 'application/json',
      },
    })
    .then(({ data }) => data);
};

export const saveCalendar = async (client: AxiosInstance, appBeingEdited: string, data: CalendarMonth) => {
  return client.put(`/content/calendar/${appBeingEdited}`, data);
};

export const saveCalendarMonthStatus = async (
  client: AxiosInstance,
  appBeingEdited: string,
  data: CalendarMonthStatus,
) => {
  return client.put(`/content/calendar/${appBeingEdited}/status`, data);
};
