import styled from 'styled-components';

import { VIDAPP_GRADIENT } from 'theme';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { useDataSource } from 'providers';

const LeftBackground = styled.div`
  background: ${VIDAPP_GRADIENT};
  display: flex;
  align-content: center;
  height: 100%;
`;
const StyledImageWrapper = styled.img`
  height: 650px;
  width: 100%;
`;

const getImageUrl = (dataSource: string) => {
  const map: Record<string, string> = {
    VHX: 'onboarding/getting_started_vhx.webp',
    Kajabi: 'onboarding/getting_started.webp',
  };
  return `${BUILDER_IMAGES_DOMAIN}/${map[dataSource] ?? 'onboarding/getting_started.webp'}`;
};

export const GraphicPanel = () => {
  const dataSource = useDataSource();
  return (
    <LeftBackground>
      <StyledImageWrapper src={getImageUrl(dataSource)} />
    </LeftBackground>
  );
};
