import styled from 'styled-components';

import { SettingsGroup, SettingsImageInput, CustomButton, SettingsSegmentDivider } from 'components';
import { useLocalAppBasicInfo, useLocalAppProperties } from 'providers';
import { useAppBeingEdited } from 'app-context';

import { useOnboardingScreens } from '../hooks/useOnboardingScreens';

interface WrapperProps {
  width: string;
  marginRight?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: ${(props) => props.width};
  margin-right: ${(props) => props.marginRight};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 16px;
`;

const ScreensContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 20px;
`;

interface ScreenProps {
  heading: string;
  tooltip?: string;
  filename?: string;
  appId: string;
  id: string | number;
  width?: string;
  marginRight?: string;
  handleRemove?: () => void;
  handleFilenameChange: (id: string | number, filename: string) => void;
}

const Screen = ({
  heading,
  tooltip,
  filename,
  appId,
  id,
  width = '310px',
  marginRight,
  handleRemove,
  handleFilenameChange,
}: ScreenProps) => (
  <Wrapper width={width} marginRight={marginRight}>
    <SettingsImageInput
      heading={heading}
      subheading="JPG/PNG. 2200px by 2200px"
      tooltip={tooltip}
      hideUpload
      hideDelete
      handleRemove={handleRemove}
      downloadable
      verticalGuide={`${parseInt(width) / 2.2}px`}
      filePath={`${appId}/images/`}
      width={width}
      height={width}
      requiredDimensions={{
        requiredWidth: 2200,
        requiredHeight: 2200,
      }}
      acceptedFileTypes="image/png, image/jpeg"
      filename={filename}
      onFilenameChange={(filename) => handleFilenameChange(id, filename)}
      scaleSpinner={20}
    />
  </Wrapper>
);

export const OnboardingScreensSettings = () => {
  const { onboardingScreens, addOnboardingScreen, removeOnboardingScreen, handleFilenameChange } =
    useOnboardingScreens();
  const { properties } = useLocalAppProperties();
  const { basicInfo } = useLocalAppBasicInfo();
  const appId = useAppBeingEdited();

  return (
    <>
      <SettingsGroup title="Splash Screen">
        <Screen
          heading="Splash Screen Image"
          tooltip="Displayed while your app is loading. We recommend keeping important content between the grey dotted lines (1000 pixels) as the sides of the images will be cropped to suit each device."
          filename={basicInfo.SplashScreenImage}
          appId={appId}
          id="SplashScreenImage"
          handleFilenameChange={handleFilenameChange}
        />
      </SettingsGroup>
      <SettingsSegmentDivider />
      <SettingsGroup title="Onboarding">
        <Screen
          heading="Background Screen"
          tooltip="Your onboarding background image will be displayed behind your other onboarding screens (if they have transparent backgrounds). It's also displayed behind login and account creation screens during onboarding."
          filename={properties.OnboardingScreenBackground}
          appId={appId}
          id="OnboardingScreenBackground"
          handleFilenameChange={handleFilenameChange}
        />
        <ButtonWrapper>
          <CustomButton medium onClick={addOnboardingScreen} disabled={onboardingScreens.length > 9}>
            Add an Onboarding Screen
          </CustomButton>
        </ButtonWrapper>
        <ScreensContainer>
          {onboardingScreens?.map((screen, idx) => (
            <Screen
              key={screen.id}
              heading={`Onboarding Screen ${idx + 1}`}
              filename={screen.ImageName}
              appId={appId}
              id={screen.id as number}
              width="206px"
              marginRight={(idx + 1) % 3 === 0 ? '0px' : '14px'}
              handleRemove={() => removeOnboardingScreen(screen.id as number)}
              handleFilenameChange={handleFilenameChange}
            />
          ))}
        </ScreensContainer>
      </SettingsGroup>
    </>
  );
};
