import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

import { PRIMARY_ORANGE_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { GradientButton, LoadingStyles } from '../../GradientButton/GradientButton';

const StyledModal = styled(AntdModal)`
  #login-panel && {
    .ant-modal-body {
      padding: 40px;
      font-family: Inter, serif;
      font-weight: 500;
      text-align: center;
    }

    .ant-modal-content {
      border-radius: 20px;
    }
  }
`;

const Header = styled.div`
  font-size: 24px;
`;
const BodyText = styled.div`
  font-size: 16px;
  padding-top: 20px;
  color: ${NEUTRAL_7_COLOUR};
`;
const ResetButton = styled(GradientButton)`
  margin-top: 36px !important;

  ${LoadingStyles}
  .ant-btn-loading-icon {
    margin-left: 8px !important;
  }
`;
const CancelButton = styled.div`
  margin: 15px auto 0;
  color: ${PRIMARY_ORANGE_COLOUR};
  font-size: 16px;
  cursor: pointer;
`;

export interface ModalProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const LoginModal = ({ visible, onCancel, onSubmit }: ModalProps) => {
  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]); // Disable background scroll when modal is open

  const [loading, setLoading] = useState(false);
  const [hasReset, setHasReset] = useState(false);
  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
    if (!hasReset) {
      console.info('Password has been reset');
      setHasReset(true);
    } else {
      console.info('Password reset has been sent again');
      setHasReset(false); // Resets for next use of modal
      onCancel(); // Closes modal
    }
  }, [onCancel, onSubmit, hasReset]);

  return (
    <StyledModal
      open={visible}
      width={420}
      centered
      closable={hasReset}
      onCancel={onCancel}
      maskClosable={true}
      footer={null}
      getContainer="#login-panel"
    >
      {hasReset ? (
        <>
          <Header>{'New Password Sent'}</Header>
          <BodyText>
            {
              "We have sent you an email with your new password. If you haven't received an email, check your Spam folder or tap 'Resend Email'."
            }
          </BodyText>
          <ResetButton $height="43px" $fontSize="16px" onClick={handleSubmit} loading={loading}>
            Resend Email
          </ResetButton>
        </>
      ) : (
        <>
          <Header>{'Reset Password'}</Header>
          <BodyText>
            {
              "Don't worry, happens to the best of us! To reset your password tap 'Confirm' below and we will email you a new password."
            }
          </BodyText>
          <ResetButton $height="43px" $fontSize="16px" onClick={handleSubmit} loading={loading}>
            Confirm
          </ResetButton>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
        </>
      )}
    </StyledModal>
  );
};
