import { Col, Row } from 'antd';
import styled from 'styled-components';
import { WIZARD_RADIUS } from '../../const';

interface SplitPanelProps {
  leftContent?: JSX.Element;
  rightContent?: JSX.Element;
  disableLeftPadding?: boolean;
  disableRightPadding?: boolean;
}

interface WrapperProps {
  $disablePadding?: boolean;
  children?: string | JSX.Element;
}

const FullPageRow = styled(Row)`
  &&&& {
    height: 100%;
    min-width: 960px;
    font-family: Inter, serif;
    border-radius: ${WIZARD_RADIUS};
  }
`;
const StyledCol = styled(Col)`
  min-width: 550px;
`;

const GreyCol = styled(StyledCol)`
  background-color: #eeeeee; //TODO Replace
  border-top-left-radius: ${WIZARD_RADIUS};
  border-bottom-left-radius: ${WIZARD_RADIUS};
  overflow: hidden;
`;

const UnstyledWrapper = ({ children, ...props }: WrapperProps) => {
  return <div {...props}>{children}</div>;
};

const Wrapper = styled(UnstyledWrapper)`
  height: 100%;
  padding: ${(props) => (props.$disablePadding ? '0px' : '75px 70px 50px 70px')};
  display: flex;
  flex-direction: column;
`;

export const SplitPanel = ({ leftContent, rightContent, disableLeftPadding, disableRightPadding }: SplitPanelProps) => {
  return (
    <FullPageRow>
      <GreyCol span={12}>
        <Wrapper $disablePadding={disableLeftPadding}>{leftContent}</Wrapper>
      </GreyCol>
      <StyledCol span={12}>
        <Wrapper $disablePadding={disableRightPadding}>{rightContent}</Wrapper>
      </StyledCol>
    </FullPageRow>
  );
};
