import styled from 'styled-components';

import { NEUTRAL_7_COLOUR } from 'theme';

import { Credentials } from './KajabiValidateStep';
import { CopyField } from './CopyField';
import { FONT_16PX_MEDIUM, FONT_16PX_MEDIUM_SHORT } from '../../font';

const Wrapper = styled.div`
  margin-top: 50px;
  ${FONT_16PX_MEDIUM};
`;

const Label = styled.div`
  margin-bottom: 16px;
  ${FONT_16PX_MEDIUM_SHORT};
  color: ${NEUTRAL_7_COLOUR};
`;

interface ValidateCredentialsProps {
  credentials: Credentials;
}

export const ValidateCredentials = ({ credentials }: ValidateCredentialsProps) => {
  return (
    <Wrapper>
      <Label>Name</Label>
      <CopyField>{credentials.name}</CopyField>
      <Label>Email</Label>
      <CopyField>{credentials.email}</CopyField>
      <Label>Password</Label>
      <CopyField>{credentials.password}</CopyField>
    </Wrapper>
  );
};
