import { useCallback } from 'react';

import { SettingsRadioInput, SettingsGroup } from 'components';
import { useLocalAppProperties, useSaveContext } from 'providers';

import { AppPropertyColorInput } from 'app/modules/design-screens';
import { useTheme } from 'app/modules/self-onboarding/OnboardingWizard/hooks';

const colorSchemeOptions = {
  options: [
    { name: 'Light', value: 'Light' },
    { name: 'Dark', value: 'Dark' },
  ],
  default: 'Light',
};

export const ColorSchemeSettings = () => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();
  const { getThemeDefaults } = useTheme(Infinity, 'always');

  const handleColorSchemeChange = useCallback(
    (value: 'Light' | 'Dark') => {
      setAppProperty('ColorScheme', value);
      setAppPropertyToSave('ColorScheme', value);

      // When Color Scheme is changed, set these properties to the default values for that Color Scheme if they haven't been edited by the user
      const currentDefaults = getThemeDefaults(value === 'Light' ? 'Dark' : 'Light'); // The default values of the previously selected color scheme
      const newDefaults = getThemeDefaults(value); // The default values of the newly selected color scheme

      currentDefaults.forEach(({ Name, Value }) => {
        const currentValue = properties[Name].toLowerCase();
        const currentDefault = Value.toLowerCase();
        if (currentValue === currentDefault) {
          // If the field's value equals the previous scheme's default, update it to the new scheme's default
          const newValue = newDefaults.find((def) => def.Name === Name);
          if (newValue) {
            setAppProperty(Name, newValue.Value);
            setAppPropertyToSave(Name, newValue.Value);
          }
        }
      });
    },
    [properties, getThemeDefaults, setAppProperty, setAppPropertyToSave],
  );

  return (
    <>
      <SettingsGroup title="Header" wrapRow>
        <AppPropertyColorInput label="Background" property="NavBarBackgroundHEX" />
        <AppPropertyColorInput label="Highlight" property="NavBarTitleHEX" />
        <AppPropertyColorInput label="Sub-Nav Underline" property="SubNavUnderlineHEX" />
      </SettingsGroup>
      <SettingsGroup title="General" wrapRow>
        <SettingsRadioInput
          label="Light or Dark Mode"
          value={properties?.ColorScheme}
          onChange={(e) => handleColorSchemeChange(e.target.value)}
          defaultVal={colorSchemeOptions.default}
          options={colorSchemeOptions.options}
          width="34%"
        />
        <AppPropertyColorInput
          label="Brand Color"
          property="HighlightHEX"
          tooltip="This is used to highlight important elements in your app (like buttons)."
          $marginRight="auto"
        />
      </SettingsGroup>
      <SettingsGroup title="Tab Bar" wrapRow $marginBottom="210px">
        <AppPropertyColorInput label="Background" property="TabBarBackgroundHEX" />
        <AppPropertyColorInput label="Selected Tab" property="TabBarTintHEX" />
        <AppPropertyColorInput label="Unselected Tab" property="TabIconNotSelectedHEX" />
      </SettingsGroup>
    </>
  );
};
