import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { BuilderCollection, BuilderVideo } from 'providers';

interface ContextValue {
  treeCollectionId: BuilderCollection['TabId'];
  confirmDisabled: boolean;
  setConfirmDisabled: Dispatch<SetStateAction<boolean>>;
  modalCollectionId: BuilderCollection['TabId'] | undefined;
  setModalCollectionId: Dispatch<SetStateAction<BuilderCollection['TabId'] | undefined>>;
  modalCircuitId: BuilderCollection['TabId'] | undefined;
  setModalCircuitId: Dispatch<SetStateAction<BuilderCollection['TabId'] | undefined>>;
  modalExerciseId: BuilderVideo['VideoId'] | undefined;
  setModalExerciseId: Dispatch<SetStateAction<BuilderVideo['VideoId'] | undefined>>;
}

const ModalContext = createContext<ContextValue | undefined>(undefined);

interface ProviderProps {
  treeCollectionId: ContextValue['treeCollectionId'];
  children?: ReactNode;
}

const ModalContextProvider = ({ children, treeCollectionId }: ProviderProps) => {
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [modalCollectionId, setModalCollectionId] = useState<BuilderCollection['TabId']>();
  const [modalCircuitId, setModalCircuitId] = useState<BuilderCollection['TabId']>();
  const [modalExerciseId, setModalExerciseId] = useState<BuilderVideo['VideoId']>();

  return (
    <ModalContext.Provider
      value={{
        confirmDisabled,
        setConfirmDisabled,
        modalCollectionId,
        setModalCollectionId,
        modalCircuitId,
        setModalCircuitId,
        modalExerciseId,
        setModalExerciseId,
        treeCollectionId,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalContextProvider');
  }
  return context;
};

export { ModalContextProvider, useModalContext };
