import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useState } from 'react';
import { BuilderCollection, BuilderCollectionItem } from 'providers';

interface ContextValue {
  confirmDisabled: boolean;
  setConfirmDisabled: Dispatch<SetStateAction<boolean>>;
  runPendingSaves: () => void;
  addPendingSave: (pendingFn: PendingFn) => void;
  addItem: (item: BuilderCollectionItem) => void;
  removeItem: (idx: number) => void;
  editedItems: BuilderCollectionItem[];
  setEditedItems: Dispatch<SetStateAction<BuilderCollectionItem[]>>;
}

const ModalContext = createContext<ContextValue | undefined>(undefined);

export type PendingFn = () => void;
type PendingSaves = PendingFn[];

interface ProviderProps {
  children?: ReactNode;
  collection: BuilderCollection;
}

const ModalContextProvider = ({ children, collection }: ProviderProps) => {
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [pendingSaves, setPendingSaves] = useState<PendingSaves>([]);
  const [editedItems, setEditedItems] = useState<BuilderCollectionItem[]>(collection.Items || []);

  const addPendingSave = useCallback(
    (pendingFn: PendingFn) => {
      setPendingSaves((current) => {
        return [...current, pendingFn];
      });
    },
    [setPendingSaves],
  );
  const runPendingSaves = useCallback(() => {
    if (pendingSaves) {
      for (const fn of pendingSaves) {
        fn();
      }
      setPendingSaves([]);
    }
  }, [pendingSaves, setPendingSaves]);
  const addItem = useCallback(
    (item: BuilderCollectionItem) => {
      setEditedItems((current) => {
        return [...current, item];
      });
    },
    [setEditedItems],
  );
  const removeItem = useCallback(
    (idx: number) => {
      setEditedItems((currentItems) => {
        const updatedItems = [...currentItems];
        updatedItems.splice(idx, 1);
        return updatedItems;
      });
    },
    [setEditedItems],
  );

  return (
    <ModalContext.Provider
      value={{
        confirmDisabled,
        setConfirmDisabled,
        addPendingSave,
        runPendingSaves,
        addItem,
        removeItem,
        setEditedItems,
        editedItems,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalContextProvider');
  }
  return context;
};

export { ModalContextProvider, useModalContext };
