// Its useful to have this as a wrapper as sometimes
// we don't want the component to even mount or run any hooks
// unless you are an admin
import { isUserAdmin } from 'utils';
import { ReactNode } from 'react';

export const AdminOnlyWrapper = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const isAdmin = isUserAdmin();

  if (!isAdmin) {
    return null;
  }
  return <>{children}</>;
};
