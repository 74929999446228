import styled from 'styled-components';

import { SettingsImageInput } from 'components';

import { Device } from '../hooks/useAppStore';

export const ScreensContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 20px;
`;

interface WrapperProps {
  $width: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: ${({ $width }) => `calc(14px + ${$width})`};
`;

interface ScreenProps {
  heading: string;
  subheading?: string;
  filename?: string;
  id: number;
  width: string;
  height: string;
  requiredDimensions?: { requiredWidth: number; requiredHeight: number };
  maxDimensions?: { maxWidth: number; maxHeight: number };
  maxFileSize?: number;
  device: Device;
  handleRemove?: () => void;
  handleFilenameChange: (id: number, filename: string, device: Device) => void;
}

export const ScreenshotInput = ({
  subheading,
  filename,
  id,
  width,
  height,
  requiredDimensions,
  device,
  handleFilenameChange,
  ...props
}: ScreenProps) => (
  <Wrapper $width={width}>
    <SettingsImageInput
      subheading={
        subheading || `JPG. ${requiredDimensions?.requiredWidth}px by ${requiredDimensions?.requiredHeight}px`
      }
      hideUpload
      hideDelete
      downloadable
      width={width}
      height={height}
      requiredDimensions={requiredDimensions}
      acceptedFileTypes="image/jpeg"
      filename={filename}
      onFilenameChange={(filename) => handleFilenameChange(id, filename, device)}
      scaleSpinner={20}
      {...props}
    />
  </Wrapper>
);
