import { CollectionSummary } from 'api';

import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { useContent } from 'providers';

import { Card, Title, List, Subtitle } from './Misc';

const getSubtitles = (diff: CollectionSummary) => {
  const subtitles = [];
  if (diff.SourceId) {
    subtitles.push(`SourceId:${diff.SourceId}`);
  }
  if (diff.TabId) {
    subtitles.push(`TabId:${diff.TabId}`);
  }
  return subtitles;
};

export const CollectionSummaryDisplay = (summary: CollectionSummary) => {
  const { collections } = useContent();
  const subtitles = getSubtitles(summary);
  const collection = collections[summary.TabId];

  return (
    <Card key={summary.TabId}>
      <ContentThumbnail thumbnail={collection ?? {}} size="small" />
      <Title>{summary.Name}</Title>
      <List>
        {subtitles.map((sub) => (
          <Subtitle key={sub}>{sub}</Subtitle>
        ))}
      </List>
    </Card>
  );
};
