import { Form } from 'antd';

import {
  CustomButton,
  PageContainer,
  SettingsCheckboxInput,
  SettingsFileInput,
  SettingsFormItem,
  SettingsGroup,
  SettingsSegmentDivider,
  SettingsSelectInput,
  SettingsTextInput,
} from 'components';

import { useDataSource } from 'providers';

import { useIntegrations } from '../hooks/useIntegrations';
import {
  AMITY_FIELDS,
  CUSTOM_API_FIELDS,
  DATA_SOURCE_FIELDS,
  DEEP_LINKING_FIELDS,
  FACEBOOK_FIELDS,
  Field,
  FIREBASE_FIELDS,
  JWPLAYER_FIELDS,
  PROPERTIES,
  SENTRY_FIELDS,
  VIMEO_FIELDS,
  WISTIA_FIELDS,
  WORDPRESS_FIELDS,
  YOUTUBE_FIELDS,
} from './const';
import { SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';

const getFields = (fieldArr: Field[]) =>
  fieldArr.map(({ type, name, width, label, options }) => {
    if (type === 'checkbox') {
      return <SettingsCheckboxInput key={name} label={label} name={name} />;
    }
    if (type === 'select' && options) {
      return (
        <SettingsFormItem key={name} name={name} width={width || '49%'} $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}>
          <SettingsSelectInput label={label} options={options} />
        </SettingsFormItem>
      );
    }
    // Default type is 'text'
    return (
      <SettingsFormItem key={name} name={name} width={width || '49%'} $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}>
        <SettingsTextInput label={label} autoComplete="off" />
      </SettingsFormItem>
    );
  });

export const Integrations = () => {
  const {
    form,
    appProperties,
    unsavedChanges,
    isSaving,
    isLoading,
    isError,
    initialValues,
    filenames,
    onFilenameChange,
    onValuesChange,
    saveForm,
  } = useIntegrations(PROPERTIES);
  const dataSource = useDataSource();
  const { YouTubeJsonFilename, iOSFirebaseConfigFile, AndroidFirebaseConfigFile, tvOSFirebaseConfigFile } = filenames;

  return (
    <PageContainer
      heading="Integrations"
      subheading="Update settings and include API keys to integrate your app with other online platforms."
      isLoading={isLoading}
      isError={isError}
      headingButton={
        <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
          Save
        </CustomButton>
      }
    >
      <>
        <Form
          name="settings-form"
          onValuesChange={onValuesChange}
          onFinish={saveForm}
          autoComplete="off"
          form={form}
          initialValues={initialValues}
        >
          {Object.keys(DATA_SOURCE_FIELDS).includes(dataSource) && (
            <>
              <SettingsGroup title={DATA_SOURCE_FIELDS[dataSource].heading ?? dataSource} wrapRow $marginBottom="32px">
                {getFields(DATA_SOURCE_FIELDS[dataSource].fields)}
              </SettingsGroup>
              <SettingsSegmentDivider />
            </>
          )}
          <SettingsGroup title="WordPress" wrapRow $marginBottom="32px">
            {getFields(WORDPRESS_FIELDS)}
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Vimeo" wrapRow $marginBottom="0">
            {getFields(VIMEO_FIELDS)}
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="JW Player" wrapRow $marginBottom="0">
            {getFields(JWPLAYER_FIELDS)}
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Custom API" wrapRow $marginBottom="0">
            {getFields(CUSTOM_API_FIELDS)}
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Amity" wrapRow $marginBottom="0">
            {getFields(AMITY_FIELDS)}
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="YouTube" wrapRow>
            {getFields(YOUTUBE_FIELDS)}
            <SettingsFileInput
              heading="YouTube API JSON (Optional)"
              filename={YouTubeJsonFilename?.value}
              useOriginalFilename
              acceptedFileTypes="application/json"
              onFilenameChange={(filename) => onFilenameChange('YouTubeJsonFilename', filename)}
            />
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Wistia" wrapRow $marginBottom="0">
            {getFields(WISTIA_FIELDS)}
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Sentry" $marginBottom="0">
            {getFields(SENTRY_FIELDS)}
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Firebase" wrapRow>
            <SettingsFileInput
              heading="iOS Firebase Config File"
              filename={iOSFirebaseConfigFile?.value}
              useOriginalFilename
              acceptedFileTypes=".plist"
              onFilenameChange={(filename) => onFilenameChange('iOSFirebaseConfigFile', filename)}
              downloadable
            />
            <SettingsFileInput
              heading="Android Firebase Config File"
              filename={AndroidFirebaseConfigFile?.value}
              useOriginalFilename
              acceptedFileTypes="application/json"
              onFilenameChange={(filename) => onFilenameChange('AndroidFirebaseConfigFile', filename)}
              downloadable
            />
            <SettingsFileInput
              heading="tvOS Firebase Config File"
              filename={tvOSFirebaseConfigFile?.value}
              useOriginalFilename
              acceptedFileTypes=".plist"
              onFilenameChange={(filename) => onFilenameChange('tvOSFirebaseConfigFile', filename)}
              downloadable
            />
          </SettingsGroup>
          <SettingsGroup wrapRow $marginBottom="0">
            {getFields(FIREBASE_FIELDS)}
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Facebook" wrapRow $marginBottom="0">
            {getFields(FACEBOOK_FIELDS)}
          </SettingsGroup>
          <SettingsSegmentDivider />
          {appProperties?.DisplayDeepLinking === '1' && (
            <SettingsGroup title="Deep Linking" wrapRow>
              {getFields(DEEP_LINKING_FIELDS)}
            </SettingsGroup>
          )}
        </Form>
      </>
    </PageContainer>
  );
};
