import { SOURCE_KAJABI, SOURCE_VHX } from 'api';

export const WIZARD_RADIUS = '17px';
export const WIZARD_HEIGHT = '665px';

export const PATHS_BY_DATASOURCE: Record<string, number[]> = {
  [SOURCE_KAJABI]: [1, 2, 3, 4, 5, 6, 7],
  [SOURCE_VHX]: [1, 2, 3, 5, 6, 7],
  default: [3, 5, 6, 7],
};
const getPaths = (dataSource: string) => {
  const keys = Object.keys(PATHS_BY_DATASOURCE);
  if (keys.includes(dataSource)) {
    return PATHS_BY_DATASOURCE[dataSource];
  }
  return PATHS_BY_DATASOURCE['default'];
};
export const getNextPath = (datasource: string, pathname: string) => {
  const paths = getPaths(datasource);
  if (pathname === '/') {
    return `/${paths[0]}`;
  }
  const currentPathNumber = Number.parseInt(pathname.substring(1));

  const index = paths.indexOf(currentPathNumber) + 1;
  if (index === paths.length) {
    return null; // At last path
  }
  return `/${paths[index]}`;
};
export const getPreviousPath = (datasource: string, pathname: string) => {
  const paths = getPaths(datasource);
  if (pathname === '/') {
    return null;
  }
  const currentPathNumber = Number.parseInt(pathname.substring(1));

  const index = paths.indexOf(currentPathNumber) - 1;
  if (index === -1) {
    return '/'; // Back to first path
  }
  return `/${paths[index]}`;
};
