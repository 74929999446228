import { Collection } from 'api';

export const getCommunityIdFromCollections = (collections: Collection[]) => {
  const allTabs = collections.filter((c) => c.IsMainTab === 1 || (c.Position ?? 0) > 0);
  const mainTabs = allTabs.filter((t) => t.IsMainTab === 1);

  const communityTab = mainTabs.find((t) => !!t.Properties?.CommunityID);
  if (communityTab) {
    return communityTab.Properties?.CommunityID;
  }

  const inactiveTabs = allTabs.filter((t) => t.IsMainTab !== 1);
  const inactiveCommunityTab = inactiveTabs.find((t) => !!t.Properties?.CommunityID);
  if (inactiveCommunityTab) {
    return inactiveCommunityTab.Properties?.CommunityID;
  }

  return null;
};
