import { useCallback } from 'react';
import styled from 'styled-components';

import { SettingsRadioInput } from 'components';
import { FONT_12PX_MEDIUM, FONT_14PX_REGULAR } from 'font';
import { useContent, useSaveContext } from 'providers';

const StyledRadioInput = styled(SettingsRadioInput)<{ $inModal?: boolean }>`
  &&&& {
    margin: 0 !important;
    height: 20px !important;

    .ant-radio-wrapper {
      ${({ $inModal }) => ($inModal ? FONT_14PX_REGULAR : FONT_12PX_MEDIUM)};
      flex-direction: ${({ $inModal }) => ($inModal ? 'row' : 'row-reverse')};
      margin-right: ${({ $inModal }) => ($inModal ? '20px' : '8px')};
      align-items: center;

      span {
        padding: 0;
      }

      .ant-radio {
        margin-left: ${({ $inModal }) => ($inModal ? '0' : '4px')};
        margin-right: ${({ $inModal }) => ($inModal ? '4px' : '0')};
        height: 22px;
      }
    }
  }
`;

interface CircuitTypeRadioProps {
  tabId: string | number;
  inModal?: boolean;
}

export const CircuitTypeRadio = ({ tabId, inModal }: CircuitTypeRadioProps) => {
  const { collections, setCollectionProperty } = useContent();
  const { setCollectionPropertyToSave } = useSaveContext();

  const circuit = collections[tabId];

  const changeCircuitType = useCallback(
    (value: string) => {
      setCollectionProperty(tabId, 'CircuitType', value);
      setCollectionPropertyToSave(tabId, 'CircuitType', value);
    },
    [setCollectionProperty, setCollectionPropertyToSave],
  );

  return (
    <StyledRadioInput
      value={circuit.Properties?.CircuitType}
      defaultVal="time-based"
      options={[
        { name: 'Time', value: 'time-based' },
        { name: 'Reps', value: 'rep-based' },
      ]}
      onChange={(e) => {
        changeCircuitType(e.target.value);
      }}
      $inModal={inModal}
    />
  );
};
