import { BuilderCollectionItem } from 'app/modules/build-dragdrop/Builder/providers';
import { Draggable } from 'react-beautiful-dnd';
import { CollectionItemDisplay } from 'app/modules/build-dragdrop/Builder/drawer/components/CollectionContent/CollectionItem';
import { DragIcon, TrashIcon } from 'icons';
import styled from 'styled-components';
import { DANGER_COLOUR, NEUTRAL_6_COLOUR } from 'theme';

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 52px;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 12px;
`;
const StyledHandle = styled(DragIcon)`
  &&&& {
    font-size: 16px;
    color: ${NEUTRAL_6_COLOUR};
    cursor: grab !important;
  }
`;
const HandleWrapper = styled.div`
  padding-right: 4px;
  flex-grow: 0;
`;
const StyledTrash = styled(TrashIcon)`
  &&&& {
    font-size: 16px;
    color: ${DANGER_COLOUR};
  }
`;
const TrashWrapper = styled.div`
  padding-left: 14px;
  padding-right: 2px;
  flex-grow: 0;
`;

const StyledCollectionItem = styled(CollectionItemDisplay)`
  overflow: clip;
  margin-bottom: 0;
  flex-grow: 1;
`;

interface SectionCustomItemProps {
  item: BuilderCollectionItem;
  idx: number;
  onDelete?: () => void;
}

export const SectionCustomItem = ({ item, idx, onDelete }: SectionCustomItemProps) => {
  if (!item.TabItemId) {
    console.debug('Ignoring SectionCustomItem due to missing TabItemId');
    return null;
  }
  const draggableId = `${item.ChildId}|${item.TabItemId}`;

  return (
    <Draggable draggableId={draggableId} index={idx}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <Row>
              <HandleWrapper>
                <StyledHandle {...provided.dragHandleProps} />
              </HandleWrapper>
              <StyledCollectionItem item={item} />
              <TrashWrapper>
                <StyledTrash onClick={onDelete} />
              </TrashWrapper>
            </Row>
          </div>
        );
      }}
    </Draggable>
  );
};
