import { refreshContent, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export const useRefreshContent = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();

  return useMutation<unknown, AxiosError, { ids: number[]; content: 'videos' | 'collections' }>(({ ids, content }) => {
    console.debug(`Refreshing cache for ${content} ${ids.join(',')}`);
    return refreshContent(client, appId, content, ids);
  });
};
