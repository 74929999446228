import { useAppBeingEdited } from 'app-context';
import { clearCommunityPost, useAxiosInstance } from 'api';
import { useMutation, useQueryClient } from 'react-query';
import { useRecordAction } from 'hooks';
import { AxiosError, AxiosResponse } from 'axios';

export const useClearPost = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, string>(
    (postId) => {
      return clearCommunityPost(client, appId, postId);
    },
    {
      onSuccess: async (_response, postId) => {
        // Invalidate all queries for community users
        await queryClient.invalidateQueries(['community', 'posts']);
        recordAction.mutate({ action: 'Clear Flags on Community Post', detail: { PostId: postId } });
      },
    },
  );
};
