import { AppMockup, MockupContextProvider, PageContext } from 'app/modules/build-dragdrop';
import { DEFAULT_MOCKUP_HEIGHT_2 } from 'app/modules/build-screens/const';

interface CMSMockupProps {
  currentPage?: PageContext;
  isLoading?: boolean;
}

export const CMSMockup = ({ currentPage, isLoading }: CMSMockupProps) => {
  return (
    <MockupContextProvider
      key={currentPage?.itemId}
      currentPage={currentPage}
      setCurrentTabId={() => {
        console.debug('Ignoring tab change in CMS');
      }}
      setCurrentPage={() => {
        console.debug('Ignoring page change in CMS');
      }}
      screenHeight={`${DEFAULT_MOCKUP_HEIGHT_2}px`}
      disableDragDrop
      navigationEnabled={false}
    >
      <AppMockup isLoading={isLoading} disableMargin disableMaxHeight />
    </MockupContextProvider>
  );
};
