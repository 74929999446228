import { useQuery } from 'react-query';

import { getPublishDiff, useAxiosInstance, Stage, CollectionDiffResponse, PropertyDiffResponse } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useCollectionDiff = (stage: Stage) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery<CollectionDiffResponse>([appId, stage, 'diff', 'collections'], () =>
    getPublishDiff(client, appId, stage, 'collections'),
  );
};

export const usePropertyDiff = (stage: Stage) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery<PropertyDiffResponse>([appId, stage, 'diff', 'properties'], () =>
    getPublishDiff(client, appId, stage, 'properties'),
  );
};
