import styled from 'styled-components';
import { FONT_16PX_MEDIUM } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { FONT_14PX_NORMAL } from 'app/modules/self-onboarding/OnboardingWizard/font';

const NoContentSelected = styled.div`
  width: 100%;
  padding: 80px 30px 30px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Heading = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
  margin-bottom: 4px;
`;
const Body = styled.div`
  ${FONT_14PX_NORMAL};
  color: ${NEUTRAL_7_COLOUR};
  margin-bottom: 24px;
`;

/**
 * Displays when the drawer is open to a Section(TabItem)
 * where no ChildId is linked
 * or the referenced ChildId is not available in Content API
 */
export const NoCollection = () => {
  return (
    <NoContentSelected>
      <Heading>Missing Content</Heading>
      <Body>Uh-oh looks like the content for this section can't be found.</Body>
    </NoContentSelected>
  );
};
