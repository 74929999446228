import { ColumnModal, CustomButton } from 'components';
import styled from 'styled-components';
import { FONT_16PX_REGULAR } from 'font';
import { Comment } from 'api';
import { useDeleteComment } from 'hooks';

const PostBody = styled.div`
  ${FONT_16PX_REGULAR};
  min-height: 214px;
  height: fit-content;
  max-height: 642px;

  @media (max-height: 900px) {
    max-height: 50vh;
  }

  padding: 0 22px 0 24px;
  margin-right: 2px;
  overflow: auto;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

interface CommentsModeratorModalProps {
  data: Comment;
  open?: boolean;
  closeModal?: () => void;
}

export const CommentsModeratorModal = ({ data, open, closeModal }: CommentsModeratorModalProps) => {
  const deleteComment = useDeleteComment();

  return (
    <ColumnModal
      title={`Comment by ${data.CommenterName}`}
      cols={3}
      align="right"
      open={open}
      onOpenChange={closeModal}
      hideTrigger
      onCancel={closeModal}
      maxHeight="786px"
      contentPadding="0"
      customActions={
        <Row>
          <CustomButton
            danger
            medium
            secondary
            loading={deleteComment.isLoading}
            disabled={deleteComment.isLoading}
            onClick={() => deleteComment.mutate(data.CommentId, { onSuccess: closeModal })}
          >
            Delete Comment
          </CustomButton>
          <CustomButton medium onClick={closeModal}>
            Done
          </CustomButton>
        </Row>
      }
    >
      <PostBody>{data.Comment}</PostBody>
    </ColumnModal>
  );
};
