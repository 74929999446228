import styled from 'styled-components';
import { SettingsTextInput } from '../../Settings/SettingsTextInput';
import { NEUTRAL_5_COLOUR } from 'theme';
import { FONT_12PX_REGULAR } from 'font';

const StyledSearch = styled(SettingsTextInput)`
  &&&.ant-input-affix-wrapper {
    border: 1px solid ${NEUTRAL_5_COLOUR};
    border-radius: 6px;
    height: 32px;
    padding-left: 8px;
    padding-right: 8px;
    ${FONT_12PX_REGULAR};
  }

  &&&.ant-input {
    line-height: 22px;
  }
`;

interface SearchBoxProps {
  onChange: (query: string) => void;
}

export const ModalSearchBox = ({ onChange }: SearchBoxProps) => {
  return <StyledSearch placeholder="Search" onChange={(e) => onChange(e.target.value)} width="100%" />;
};
