import { useContent, useDataSource } from 'providers';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { NAV_BAR_OFFSET, NEUTRAL_10_COLOUR } from 'theme';

import { BUILDER_DRAWER_WIDTH } from 'app/modules/build-dragdrop/Builder/const';
import { DrawerHeading, DrawerScrollContainer } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { DevTag } from 'components';
import { isUserAdmin, isUserDev } from 'utils';
import { getPageName } from '../../utils';

interface TabDrawerProps {
  tabId: number;
  children: ReactNode;
}

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${`${BUILDER_DRAWER_WIDTH}px`};
  background: #fff;
  height: ${`calc(100vh - ${NAV_BAR_OFFSET})`};
  color: ${NEUTRAL_10_COLOUR};
  display: flex;
  flex-direction: column;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabDrawer = ({ tabId, children }: TabDrawerProps) => {
  const dataSource = useDataSource();
  const { collections } = useContent();
  const tab = collections[tabId];

  const suffix = isUserDev() ? (
    <div>
      <DevTag>{tab?.TemplateId}</DevTag>
      <DevTag>{tab?.DataSource}</DevTag>
      <DevTag>{tab?.TabId}</DevTag>
    </div>
  ) : isUserAdmin() ? (
    <div>
      <DevTag label="V">{tab?.TabId}</DevTag>
      <DevTag label="S">{tab?.SourceId}</DevTag>
    </div>
  ) : null;

  return (
    <Container>
      <DrawerHeading
        heading={tab.Name || getPageName(tab)}
        type={
          <SpaceBetween>
            <div>Tab: {getPageName(tab, dataSource)}</div>
            {suffix}
          </SpaceBetween>
        }
      />
      <DrawerScrollContainer id="tab-drawer-content" paddingBottom="50px">
        {children}
      </DrawerScrollContainer>
    </Container>
  );
};
