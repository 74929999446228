import styled from 'styled-components';
import { ReactNode } from 'react';

import { SegmentTitle } from 'components/Text/SegmentTitle';

const DrawerSectionContainer = styled.div<{ $marginBottom?: string }>`
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '40px'};
`;

export interface DrawerSectionProps {
  heading?: string;
  subheading?: string;
  children?: ReactNode;
  $marginBottom?: string;
}

export const DrawerSection = ({ heading, subheading, children, ...props }: DrawerSectionProps) => (
  <DrawerSectionContainer {...props}>
    {heading && <SegmentTitle title={heading} description={subheading} />}
    {children}
  </DrawerSectionContainer>
);
