import { useEffect, useState } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';

import { CustomButton, SettingsAlternateThumbnailInput, SettingsTextArea, SettingsThumbnailInput } from 'components';
import { PlusIcon } from 'icons';

import { useModalContext } from '../providers/modal-provider';
import {
  BlueButton,
  ScrollView,
  ViewContainer,
  ViewFormItem,
  ViewHeaderRow,
  ViewHeading,
  ViewSection,
  ViewSectionHeading,
  ViewTextInput,
} from './ViewComponents';

const { useForm } = Form;

const Flex = styled.div`
  display: flex;
`;

const StyledTextArea = styled(SettingsTextArea)`
  border-radius: 6px !important;

  &&&& {
    min-height: 34px;
    line-height: 24px;
  }
`;

interface Website {
  title?: string;
  url?: string;
  thumbnail?: string;
  squareThumbnail?: string;
  portraitThumbnail?: string;
}

interface WebsiteViewProps {
  existingWebsite?: Website;
  onCreate: (website: Website) => void;
}

export const WebsiteView = ({ existingWebsite, onCreate }: WebsiteViewProps) => {
  const [website, setWebsite] = useState<Website>(
    existingWebsite ?? {
      squareThumbnail: '',
      portraitThumbnail: '',
      thumbnail: '',
    },
  );
  const [editing, setEditing] = useState<boolean>(false);
  const { setConfirmDisabled } = useModalContext();
  const [form] = useForm();

  const isClear = !existingWebsite && !editing; // Is the form already empty

  useEffect(() => {
    // While editing, you can't quit the modal, you would have to clear it first
    setConfirmDisabled(editing);
  }, [editing]);

  const handleEdit = () => {
    setEditing(true);
  };
  const handleClear = () => {
    form.resetFields();
    setWebsite((w) => ({ ...w, thumbnail: '', squareThumbnail: '', portraitThumbnail: '' }));
    setEditing(false);
  };
  const validateForm = (website: Website): boolean => {
    form.setFields([
      { name: 'Title', errors: [] },
      { name: 'URL', errors: [] },
    ]);
    if (!website.title) {
      form.setFields([
        {
          name: 'Title',
          errors: ['Please enter a title'],
        },
      ]);
      return false;
    }
    if (!website.url?.startsWith('https://')) {
      form.setFields([
        {
          name: 'URL',
          errors: ['Please enter a valid URL starting with https://'],
        },
      ]);
      return false;
    }

    return true;
  };

  return (
    <ViewContainer>
      <Form form={form} style={{ height: 'inherit' }}>
        <ViewHeaderRow>
          <ViewHeading>Create Website</ViewHeading>
          <Flex>
            <CustomButton medium tertiary onClick={handleClear} disabled={isClear}>
              Clear
            </CustomButton>
            <BlueButton
              medium
              tertiary
              icon={<PlusIcon />}
              disabled={!editing}
              onClick={() => {
                if (validateForm(website)) {
                  onCreate(website);
                  handleClear(); // Clear form after adding to the collection
                }
              }}
            >
              Add
            </BlueButton>
          </Flex>
        </ViewHeaderRow>
        <ScrollView>
          <ViewSection>
            <ViewSectionHeading>Title</ViewSectionHeading>
            <ViewFormItem name="Title">
              <ViewTextInput
                value={website?.title}
                placeholder="Enter a title"
                onChange={(e) => {
                  handleEdit();
                  setWebsite((w) => {
                    return { ...w, title: e.target.value };
                  });
                }}
              />
            </ViewFormItem>
          </ViewSection>
          <ViewSection>
            <ViewSectionHeading>Website URL</ViewSectionHeading>
            <ViewFormItem name="URL">
              <StyledTextArea
                value={website?.url}
                placeholder="Enter a URL"
                onChange={(e) => {
                  handleEdit();
                  setWebsite((w) => {
                    return { ...w, url: e.target.value };
                  });
                }}
                autoSize={{ minRows: 1, maxRows: 6 }}
                style={{ fontSize: '12px' }}
              />
            </ViewFormItem>
          </ViewSection>
          <ViewSection>
            <SettingsThumbnailInput
              $marginTop="0px"
              filename={website.thumbnail}
              isRevertable={false}
              handleThumbnailChange={(filename) => {
                handleEdit();
                setWebsite((w) => {
                  return { ...w, thumbnail: filename };
                });
              }}
              width="172px"
              height="96px"
              uploaderProps={{
                maxDimensions: { maxWidth: 1920, maxHeight: 1080 },
                acceptedFileTypes: 'image/png, image/jpeg',
                scaleSpinner: 80,
              }}
            />
            <SettingsAlternateThumbnailInput
              squareFilename={website?.squareThumbnail}
              portraitFilename={website?.portraitThumbnail}
              handleSquareThumbnailChange={(filename) => {
                handleEdit();
                setWebsite((w) => {
                  return { ...w, squareThumbnail: filename };
                });
              }}
              handlePortraitThumbnailChange={(filename) => {
                handleEdit();
                setWebsite((w) => {
                  return { ...w, portraitThumbnail: filename };
                });
              }}
              squareWidth="123px"
              portraitWidth="123px"
              portraitHeight="184px"
              orientation="column"
            />
          </ViewSection>
        </ScrollView>
      </Form>
    </ViewContainer>
  );
};
