import styled from 'styled-components';
import {
  NEUTRAL_5_COLOUR,
  NAV_BAR_OFFSET,
  DEFAULT_PAGE_PADDING_SMALL,
  DEFAULT_PAGE_PADDING,
  BREAKPOINT_1,
} from 'theme';

export const HeaderContainer = styled.div`
  width: 100%;
  height: ${NAV_BAR_OFFSET};
  background: #fff;
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};

  transition: padding 0.2s linear;
  padding: ${DEFAULT_PAGE_PADDING_SMALL};
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: ${BREAKPOINT_1.minWidth}) {
    padding: ${DEFAULT_PAGE_PADDING};
    padding-top: 0;
    padding-bottom: 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
