import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { deleteAccount, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useRemoveAccount = () => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError, number>(
    async (userId) => {
      await deleteAccount(client, appId, userId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['app_accounts', appId]);
      },
    },
  );
};
