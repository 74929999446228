import { useMemo } from 'react';
import { BuilderCollectionItem, useContent } from 'providers';
import { DEFAULT_COLLECTION_ITEM } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useInProgressItems = (tabId: string | number | undefined, enabled?: boolean) => {
  const appId = useAppBeingEdited();
  const { collections } = useContent();

  const products = useMemo(() => {
    if (enabled === false) {
      return []; // Allow us to dynamically skip this processing when not needed
    }
    return Object.values(collections).filter((c) => c.TemplateId === 39);
  }, [collections, enabled]);

  const items = useMemo(() => {
    // We pick a random set of Kajabi Products using Course view to display in the InProgress
    return products
      .sort(() => Math.random() - 0.5)
      .map((product, idx) => {
        return {
          ...DEFAULT_COLLECTION_ITEM,
          AppId: appId,
          Position: idx + 1,
          TabItemId: -1 * idx,
          TabId: tabId,
          ChildId: product.TabId,
          Type: 'collection',
        } as BuilderCollectionItem;
      });
  }, [tabId, products.length]);

  return { items };
};
